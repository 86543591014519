import arrowMergeSVG from '@/assets/arrow-merge.svg'

export function ArrowMergeIcon({ width, height, className }: { width?: number; height?: number; className?: string }) {
  return (
    <img
      width={width}
      height={height}
      className={className}
      src={arrowMergeSVG}
      alt="Arrow that is pointing to the right. Two arrows are merged from left to right."
    />
  )
}
