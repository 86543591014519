const ContactFieldToLabelNameMapping: Record<string, string> = {
  FirstName: 'First name',
  LastName: 'Last name',
  Phone: 'Phone',
  Title: 'Title',
  Company: 'Company',
  Linkedin: 'LinkedIn',
  Country: 'Country',
  State: 'State',
  City: 'City',
  Body: 'Body',
}

const ContactFieldToSentenceTextMapping: Record<string, string> = {
  FirstName: 'first name',
  LastName: 'last name',
  Phone: 'phone number',
  Title: 'title',
  Company: 'company',
  Linkedin: 'LinkedIn',
  Country: 'country',
  State: 'state',
  City: 'city',
  Body: 'body',
}

export function ContactFieldToLabelName(fieldName: string): string {
  return (
    ContactFieldToLabelNameMapping[fieldName] || fieldName.charAt(0).toUpperCase() + fieldName.slice(1).toLowerCase()
  )
}

export function ContactFieldToSentenceText(fieldName: string): string {
  return ContactFieldToSentenceTextMapping[fieldName] || fieldName
}
