import { Card, Text } from '@/ui'

export function Finished({ message, title }: { message: string; title?: string }) {
  return (
    <Card className="mt-6 flex flex-col gap-2 rounded-lg py-10 text-center">
      <Text variant="subtitle" className="text-dark">
        {title || 'All caught up'}
      </Text>
      <Text className="text-dusk">{message}</Text>
    </Card>
  )
}
