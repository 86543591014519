import { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { CaretDown, CaretLeft, CaretUp } from '@phosphor-icons/react'
import { DonePtr } from '@/api/core/models/DonePtr'
import { Button, Card } from '@/ui'
import { doneURl } from './utilities'

export function SingleStep({ children }: PropsWithChildren) {
  return <div className="grid grid-cols-[256px_1fr_256px] p-8">{children}</div>
}

function Main({ children, className }: PropsWithChildren<{ className?: string }>) {
  return <div className={twMerge('m-auto flex w-full max-w-4xl flex-col ', className)}>{children}</div>
}

function UICard({
  children,
  className,
  focused = true,
  onClick,
}: PropsWithChildren<{ className?: string; onClick?: () => void; focused?: boolean }>) {
  return (
    <Card
      className={twMerge('w-full ', focused && 'border border-accent bg-white shadow-blue', className)}
      {...(onClick && { onClick: onClick })}
    >
      {children}
    </Card>
  )
}

type NavigationProps = {
  prev: string | null | DonePtr
  next: string | null | DonePtr
  url: string
}

function Navigation({ prev, next, url }: NavigationProps) {
  const navigate = useNavigate()

  function handleClick(emailId: string | null | DonePtr) {
    if (emailId && typeof emailId === 'string') {
      navigate(`${url}${emailId}`)
    } else if (emailId && typeof emailId === 'object') {
      navigate(doneURl(emailId.type, emailId.id))
    }
  }

  return (
    <div className="flex gap-2">
      <Button variant="basic" onClick={() => navigate(url)} className="bg-extra-light p-2">
        <CaretLeft className="size-5 text-medium" />
      </Button>
      <Button variant="basic" onClick={() => handleClick(prev)} disabled={!prev} className="bg-extra-light p-2">
        <CaretUp className={twMerge('size-5 text-medium', !prev && 'text-light')} />{' '}
        <span className={twMerge('ml-2 text-sm text-dark', !prev && 'text-medium')}>Prev</span>
      </Button>
      <Button variant="basic" onClick={() => handleClick(next)} disabled={!next} className="bg-extra-light p-2">
        <CaretDown className={twMerge('size-5 text-medium', !next && 'text-light')} />{' '}
        <span className={twMerge('ml-2 text-sm text-dark', !next && 'text-medium')}>Next</span>
      </Button>
    </div>
  )
}

SingleStep.Main = Main
SingleStep.Card = UICard
SingleStep.Navigation = Navigation
