import { useRef } from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useCampaignsDashboard } from '@/api/campaigns'
import { Text } from '@/ui'

export function EmailVolumeGraph() {
  const { data, status } = useCampaignsDashboard()

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)

  // TODO: Add skeleton
  if (status === 'error' || status === 'pending') return <></>

  const options: Highcharts.Options = {
    chart: {
      type: 'column',
      style: {
        fontFamily: 'Monument, sans-serif',
        fontSize: '16px',
      },
    },

    xAxis: {
      type: 'category',

      categories: data.data.emails.scheduled.map((scheduled) =>
        scheduled.date.substring(scheduled.date.indexOf('-') + 1),
      ),
      crosshair: false,
      accessibility: {
        description: 'Dates',
      },
    },

    yAxis: {
      title: {
        text: 'Emails scheduled',
      },
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    title: {
      text: '',
    },
    series: [
      {
        type: 'column',
        name: 'Running Sequences',
        data: data.data.emails.scheduled.map((scheduled) => scheduled.total),
        color: '#3489F2',
      },
    ],
    tooltip: {
      formatter: formatTooltip,
      shared: true,
      useHTML: true,
      style: {
        fontSize: '14px',
        fontFamily: 'Monument, sans-serif',
        lineHeight: '21px',
      },
    },
    credits: {
      enabled: false,
    },
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  function formatTooltip(tooltip, y = this.y) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const index = this.point.index
    const scheduledEmails = data?.data.emails.scheduled[index].campaigns
    const sequenceDate = data?.data.emails.scheduled[index].date

    //TODO: Get this working for date header
    // DateTime.fromJSDate(new Date(x)).toFormat('EEE, MMM d')

    return `<span style="font-weight:500; width:100%; display:flex; margin-bottom:5px"><span>${sequenceDate}</span> <span style="margin-left:auto">Total: ${y}</span></span>${scheduledEmails
      ?.map((sequence) => `${sequence.name}: ${sequence.total}`)
      .join('<br/>')}`
  }

  return (
    <div>
      <Text variant="subtitle" className="mb-4">
        Daily email volume
      </Text>
      <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
    </div>
  )
}
