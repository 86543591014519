import { PropsWithChildren } from 'react'
import { useUserPermissions } from '@/containers/ComponentDisplay'
import { FeatureFlag } from '@/containers/FeatureFlag'
import { Nav } from '@/ui'

export function SettingsUpdates({ children }: PropsWithChildren) {
  const { showSmtpReplyToHeader } = useUserPermissions()
  return (
    <section className="flex">
      <Nav className="h-[calc(100vh_-_88px)] w-64 bg-extra-light">
        <Nav.Title>Settings</Nav.Title>
        <Nav.Group>
          <Nav.TitleItem>Account</Nav.TitleItem>
          <Nav.LinkItem to="/settings/profile">Profile</Nav.LinkItem>
          <Nav.LinkItem to="/settings/signatures">Signature</Nav.LinkItem>
          <Nav.LinkItem to="/settings/availability">Availability</Nav.LinkItem>
          <Nav.LinkItem to="/settings/security">Security</Nav.LinkItem>
          <Nav.LinkItem to="/settings/linked-accounts">Linked accounts</Nav.LinkItem>
        </Nav.Group>
        <Nav.Group>
          <Nav.TitleItem>Organization</Nav.TitleItem>
          <Nav.LinkItem to="/settings/company">Company</Nav.LinkItem>
          <Nav.LinkItem to="/settings/members">Members</Nav.LinkItem>
          <Nav.LinkItem to="/settings/senders">Senders</Nav.LinkItem>
          {showSmtpReplyToHeader && <Nav.LinkItem to="/settings/replyto">Reply-to</Nav.LinkItem>}
          <FeatureFlag>
            <Nav.LinkItem to="/settings/phone-numbers">Phone numbers</Nav.LinkItem>
          </FeatureFlag>
          <Nav.LinkItem to="/settings/no-send-dates">No-send dates</Nav.LinkItem>
          <Nav.LinkItem to="/settings/integrations">Integrations</Nav.LinkItem>
        </Nav.Group>
      </Nav>
      <div className="mx-auto w-full max-w-lg">{children}</div>
    </section>
  )
}
