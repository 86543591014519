import clsx from 'clsx'
import { WarningCircle } from '@phosphor-icons/react'
import { Text, TextVariants } from './'

type ErrorTextProps = {
  exclamationIconWidth?: number
  className?: string
  errorMessage: string
  textVariant?: TextVariants
}
export function ErrorText({
  exclamationIconWidth = 20,
  className,
  errorMessage,
  textVariant = 'text',
}: ErrorTextProps) {
  return (
    <div className={clsx('flex text-alert', className)}>
      <WarningCircle width={exclamationIconWidth} height={exclamationIconWidth} className="mr-1 " />
      <Text variant={textVariant}>{errorMessage}</Text>
    </div>
  )
}
