import { Fragment } from 'react'
import clsx from 'clsx'
import { Listbox, Transition } from '@headlessui/react'
import { CaretUpDown, Check } from '@phosphor-icons/react'

type SelectProps<T> = {
  defaultValue?: T | null
  onChange: (value: T) => void
  value: T | null
  options: T[]
  maxMenuHeight?: number
  className?: string
  dropdownClassName?: string
}

export const Select = <T extends { label: string; value: string | null; disabled?: boolean }>(
  props: SelectProps<T>,
) => {
  return (
    <Listbox value={props.value} onChange={(v) => props.onChange(props.options.find((opt) => opt.value === v) as T)}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button
              className={clsx(
                'relative min-h-[36px] w-full rounded-lg border border-light bg-white pl-3 pr-9 text-left text-sm ',
                props.className,
              )}
            >
              <span className="block truncate">{props.value?.label ?? props?.defaultValue?.label}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <CaretUpDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={
                  props.dropdownClassName
                    ? props.dropdownClassName
                    : clsx(
                        'absolute z-10 mt-1 w-fit overflow-auto bg-white py-1 text-base shadow-lg   focus:outline-none sm:text-sm',
                        props.maxMenuHeight ? `max-h-[${props.maxMenuHeight}px]` : 'max-h-60',
                      )
                }
              >
                {props.options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      clsx(
                        'relative select-none px-4 py-2 text-dusk',
                        active && 'bg-light-blue',
                        option.disabled && '!text-medium hover:cursor-not-allowed',
                      )
                    }
                    value={option.value}
                    {...(option.disabled && { disabled: true })}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={clsx(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {option.label}
                        </span>

                        {selected ? (
                          <span
                            className={clsx(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5',
                            )}
                          >
                            <Check className="size-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
