import { createContext, PropsWithChildren, useContext } from 'react'

type DescendantContextValue = { itemId: string }
const DescendantContext = createContext<DescendantContextValue | null>(null)
DescendantContext.displayName = 'DescendantContext'

export function useDescendantContext() {
  const context = useContext(DescendantContext)
  if (context === null) {
    throw new Error(`component is missing a parent <Tree /> component.`)
  }
  return context
}

type DescendantProviderProps = {
  itemId: string
}

export function DescendantProvider(props: PropsWithChildren<DescendantProviderProps>) {
  const { itemId, children } = props
  return <DescendantContext.Provider value={{ itemId }}>{children}</DescendantContext.Provider>
}
