import { GearSix } from '@phosphor-icons/react'
import { Permission } from '@/api/core'
import { Card } from '@/ui'
import { Button } from '@/ui/Button/v2'
import { Text } from '@/ui/Text'

type ConnectionRowProps = {
  connected: boolean
  onDisconnect: () => void
  onConnect: () => void
  onSettings: () => void
  connectionName: 'Hubspot' | 'Salesforce'
  Logo: JSX.Element
  editPermission: Permission
}

export function ConnectionRow({
  connected,
  onDisconnect,
  onConnect,
  onSettings,
  connectionName,
  Logo,
  editPermission,
}: ConnectionRowProps) {
  const buttons = () =>
    connected ? (
      <>
        <Button
          type="submit"
          variant="icon"
          icon={GearSix}
          onClick={onSettings}
          className="mr-2 duration-500 hover:rotate-90"
        />
        <Button type="submit" variant="basic" onClick={onDisconnect}>
          Disconnect
        </Button>
      </>
    ) : (
      <Button type="submit" variant="text" className="ml-auto font-medium" onClick={onConnect}>
        Connect
      </Button>
    )

  return (
    <Card className="mb-3 flex items-center rounded-lg">
      <div className="flex h-12 w-12 items-center justify-center">{Logo}</div>
      <div className="grow pl-4">
        <Text variant="text" className={!editPermission.deny ? 'font-medium' : ''}>
          {!editPermission.deny
            ? // TODO: move to backend permission messages
              `Sync your rift contacts with ${connectionName}`
            : `Contact your admin to sync your rift contacts with ${connectionName}`}
        </Text>
      </div>
      {!editPermission.deny && buttons()}
    </Card>
  )
}

export function ConnectionSkeleton() {
  return (
    <div className="mt-5 flex">
      <div className="skeleton h-[64px] w-[64px]" />
      <div className="w-3/4 pl-4">
        <div className="skeleton h-[18px] w-1/5"></div>
        <div className="skeleton my-1 h-[24px] w-3/4"></div>
        <div className="skeleton h-[24px] w-3/4"></div>
      </div>
      <div className="skeleton h-[56px] w-[200px]"></div>
    </div>
  )
}
