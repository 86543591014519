import { ElementType, Ref } from 'react'
import { Props } from '@headlessui/react/dist/types'
import { useSyncRefs } from '@/hooks/useSyncRefs'
import { uirender } from '@/ui/render'
import { useData } from './Context'

export const DEFAULT_LIST_TAG = 'div'
export interface ListRenderPropArg {
  currentIndex: number
}

export type StepListProps<TTag extends ElementType> = Props<TTag, ListRenderPropArg>

export function List<TTag extends ElementType = typeof DEFAULT_LIST_TAG>(
  props: StepListProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { currentIndex } = useData('Step.List')
  const slot = { currentIndex }
  const listRef = useSyncRefs(ref)
  const ourProps = { ref: listRef }
  return uirender({ ourProps, theirProps: props, slot, defaultTag: DEFAULT_LIST_TAG })
}
