import { SequenceStatus } from '@/api/core'

export const SequenceStatusToLabel: Record<SequenceStatus, string> = {
  [SequenceStatus.ACTIVE]: 'Active',
  [SequenceStatus.CATCH_ALL]: 'Catch-all',
  [SequenceStatus.INACTIVE]: 'Inactive',
  [SequenceStatus.INVALID]: 'Invalid',
  [SequenceStatus.NOT_INTERESTED]: 'Not interested',
  [SequenceStatus.MISSING_FIELDS]: 'Missing fields',
  [SequenceStatus.SCHEDULED]: 'Scheduled',
  [SequenceStatus.UNSUBSCRIBED]: 'Unsubscribed',
}

export const SequenceStatusToTableSubheader: Record<SequenceStatus, string> = {
  ...SequenceStatusToLabel,
  [SequenceStatus.MISSING_FIELDS]: 'Not sending - Missing fields',
}
