import { useState } from 'react'
import { ForgotPasswordConfirmation } from '@/containers/ForgotPassword/Confirmation'
import { ForgotPasswordForm } from '@/containers/ForgotPassword/Form'

export function ForgotPassword() {
  const [showForm, setShowForm] = useState<boolean>(true)

  const handleSuccess = () => {
    setShowForm(false)
  }

  return <div>{showForm ? <ForgotPasswordForm onSuccess={handleSuccess} /> : <ForgotPasswordConfirmation />}</div>
}
