export type CircleCountdownProps = {
  from: number
  current: number
  colorSteps?: {
    [key: number]: string
  }
}

const defaultColor = '#348af3'

export function CircleCountdown(props: CircleCountdownProps) {
  const { from, current, colorSteps = { 0: defaultColor } } = props
  const pct = Math.floor((current / from) * 100)
  const step = Object.keys(colorSteps)
    .map(Number)
    .sort((a, b) => b - a)
    .find((step) => current >= step)
  const color = step !== undefined ? colorSteps[step] : defaultColor

  const left = current.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
  return (
    <div className="flex h-fit w-10 items-center rounded-xl bg-gray-100 px-1">
      <div
        className="flex h-4 w-4 items-center justify-center rounded-full"
        style={{ background: `conic-gradient(${color} ${pct}%,#D1D5DB 0)` }}
      >
        <div className="h-2.5 w-2.5 rounded-full bg-gray-100" />
      </div>
      <span className="ml-0.5 text-xs">{left}</span>
    </div>
  )
}
