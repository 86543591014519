import { ElementType, PropsWithChildren } from 'react'
import clsx from 'clsx'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { Popover } from '@headlessui/react'
import { Props } from '@headlessui/react/dist/types'
import {
  BellSlash,
  BracketsCurly,
  CalendarBlank,
  Code,
  CodeBlock,
  Image,
  LinkSimple,
  LinkSimpleBreak,
  ListBullets,
  ListNumbers,
  Minus,
  Palette,
  PencilSlash,
  Signature,
  Smiley,
  TextAlignCenter,
  TextAlignJustify,
  TextAlignLeft,
  TextAlignRight,
  TextB,
  TextItalic,
  TextStrikethrough,
  TextUnderline,
} from '@phosphor-icons/react'
import { Editor } from '@tiptap/react'
import { DropdownMenu, OpacityTransition, Switch } from '@/ui'
import { uirender } from '@/ui/render'
import { EditorMode } from '../reducer'
import { Toolbar as HeadlessToolbar } from '../Toolbar'

function itemClass(active: boolean) {
  return clsx(
    'h-6 w-6 cursor-default rounded-md p-1',
    active ? 'bg-black text-white hover:bg-gray-600' : 'bg-transparent text-gray-400 hover:bg-gray-100',
  )
}

function ToolbarFn(props: PropsWithChildren) {
  const { children } = props
  return (
    <HeadlessToolbar>
      {({ mode }) => (
        <div className="group flex w-full flex-wrap items-center space-x-0.5" aria-expanded={mode !== 'subject'}>
          {children}
        </div>
      )}
    </HeadlessToolbar>
  )
}

function DividerItemFn() {
  return (
    <HeadlessToolbar.Custom>
      {({ preview }) => (
        <>
          {!preview && (
            <div
              className="inline-flex h-6 w-[1px] px-1 group-aria-[expanded=false]:hidden"
              style={{
                background: 'linear-gradient(#E5E7EC,#E5E7EC) no-repeat center/1px 100%',
              }}
            />
          )}
        </>
      )}
    </HeadlessToolbar.Custom>
  )
}

function BoldItemFn() {
  return <HeadlessToolbar.Bold>{({ active }) => <TextB className={itemClass(active)} />}</HeadlessToolbar.Bold>
}

function ItalicItemFn() {
  return <HeadlessToolbar.Italic>{({ active }) => <TextItalic className={itemClass(active)} />}</HeadlessToolbar.Italic>
}

function UnderlineItemFn() {
  return (
    <HeadlessToolbar.Underline>
      {({ active }) => <TextUnderline className={itemClass(active)} />}
    </HeadlessToolbar.Underline>
  )
}

function StrikethroughItemFn() {
  return (
    <HeadlessToolbar.TextStrikethrough>
      {({ active }) => <TextStrikethrough className={itemClass(active)} />}
    </HeadlessToolbar.TextStrikethrough>
  )
}

function CodeItemFn() {
  return <HeadlessToolbar.Code>{({ active }) => <Code className={itemClass(active)} />}</HeadlessToolbar.Code>
}

function CodeBlockItemFn() {
  return (
    <HeadlessToolbar.CodeBlock>{({ active }) => <CodeBlock className={itemClass(active)} />}</HeadlessToolbar.CodeBlock>
  )
}
function AlignLeftItemFn() {
  return (
    <HeadlessToolbar.AlignLeft>
      {({ active }) => <TextAlignLeft className={itemClass(active)} />}
    </HeadlessToolbar.AlignLeft>
  )
}
function AlignCenterItemFn() {
  return (
    <HeadlessToolbar.AlignCenter>
      {({ active }) => <TextAlignCenter className={itemClass(active)} />}
    </HeadlessToolbar.AlignCenter>
  )
}
function AlignRightItemFn() {
  return (
    <HeadlessToolbar.AlignRight>
      {({ active }) => <TextAlignRight className={itemClass(active)} />}
    </HeadlessToolbar.AlignRight>
  )
}
function AlignJustifyItemFn() {
  return (
    <HeadlessToolbar.AlignJustify>
      {({ active }) => <TextAlignJustify className={itemClass(active)} />}
    </HeadlessToolbar.AlignJustify>
  )
}
function ListOrderedItemFn() {
  return (
    <HeadlessToolbar.ListOrdered>
      {({ active }) => <ListNumbers className={itemClass(active)} />}
    </HeadlessToolbar.ListOrdered>
  )
}
function ListUnorderedItemFn() {
  return (
    <HeadlessToolbar.ListUnordered>
      {({ active }) => <ListBullets className={itemClass(active)} />}
    </HeadlessToolbar.ListUnordered>
  )
}
function SeparatorItemFn() {
  return (
    <HeadlessToolbar.Separator>{({ active }) => <Minus className={itemClass(active)} />}</HeadlessToolbar.Separator>
  )
}
function PaletteItemFn() {
  return <HeadlessToolbar.Palette>{({ active }) => <Palette className={itemClass(active)} />}</HeadlessToolbar.Palette>
}
function ImageItemFn() {
  return <HeadlessToolbar.Image>{({ active }) => <Image className={itemClass(active)} />}</HeadlessToolbar.Image>
}
function FormatClearItemFn() {
  return (
    <HeadlessToolbar.FormatClear>
      {({ active }) => <PencilSlash className={itemClass(active)} />}
    </HeadlessToolbar.FormatClear>
  )
}
function LinkItemFn() {
  return <HeadlessToolbar.Link>{({ active }) => <LinkSimple className={itemClass(active)} />}</HeadlessToolbar.Link>
}
function UnlinkItemFn() {
  return (
    <HeadlessToolbar.Unlink>{({ active }) => <LinkSimpleBreak className={itemClass(active)} />}</HeadlessToolbar.Unlink>
  )
}
function UnsubscribeItemFn() {
  return (
    <HeadlessToolbar.UnsubscribeLink>
      {({ active }) => <BellSlash className={itemClass(active)} />}
    </HeadlessToolbar.UnsubscribeLink>
  )
}

export const DEFAULT_CUSTOM_ITEM_TAG = 'button'
export type CustomItemRenderPropArg = {
  editor: Editor | null
  editorMode: EditorMode
  preview: boolean
  itemClassName: (active: boolean) => string
}
export type CustomItemProps<TTag extends ElementType> = Props<TTag, CustomItemRenderPropArg, never>

function CustomItemFn<TTag extends ElementType = typeof DEFAULT_CUSTOM_ITEM_TAG>(props: CustomItemProps<TTag>) {
  const { ...theirProps } = props
  const ourProps = {}
  return (
    <HeadlessToolbar.Custom>
      {({ editor, editorMode, preview }) => (
        <>
          {uirender({
            ourProps,
            theirProps,
            slot: { editor, editorMode, preview, itemClassName: itemClass },
            defaultTag: DEFAULT_CUSTOM_ITEM_TAG,
          })}
        </>
      )}
    </HeadlessToolbar.Custom>
  )
}

function CalendarLinksItemFn() {
  return (
    <HeadlessToolbar.CalendarLinks>
      {({ insert, calendarLinks }) => {
        return (
          <DropdownMenu>
            <DropdownMenu.Button
              className={({ open }) =>
                clsx(
                  'h-6 w-6 cursor-pointer rounded-md bg-transparent p-1',
                  open ? 'bg-black text-white hover:bg-gray-600' : 'bg-transparent text-gray-400 hover:bg-gray-100',
                )
              }
            >
              <CalendarBlank />
            </DropdownMenu.Button>
            <DropdownMenu.Items>
              {calendarLinks.map((item) => (
                <DropdownMenu.Item key={item.url} onClick={() => insert(item)}>
                  {item.description}
                </DropdownMenu.Item>
              ))}
            </DropdownMenu.Items>
          </DropdownMenu>
        )
      }}
    </HeadlessToolbar.CalendarLinks>
  )
}

function VariableItemFn() {
  return (
    <HeadlessToolbar.Variable>
      {({ insert, variables }) => (
        <DropdownMenu>
          <DropdownMenu.Button as="button" className={({ open }) => itemClass(open)}>
            <BracketsCurly />
          </DropdownMenu.Button>
          <DropdownMenu.Items>
            {variables.map((v: string) => (
              <DropdownMenu.Item key={v} onClick={() => insert(v)}>
                {v}
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Items>
        </DropdownMenu>
      )}
    </HeadlessToolbar.Variable>
  )
}

function EmojiItemFn() {
  return (
    <HeadlessToolbar.Emoji>
      {({ insert }) => (
        <Popover className="relative">
          <Popover.Button type="button" className={({ open }) => itemClass(open)}>
            <Smiley />
          </Popover.Button>

          <OpacityTransition>
            <Popover.Panel className="absolute left-1/2 z-10">
              {({ close }) => (
                <Picker
                  theme="light"
                  data={data}
                  onEmojiSelect={(e: { native: string }) => {
                    insert(e.native)
                    close()
                  }}
                />
              )}
            </Popover.Panel>
          </OpacityTransition>
        </Popover>
      )}
    </HeadlessToolbar.Emoji>
  )
}

function PreviewSwitchItemFn() {
  return (
    <HeadlessToolbar.Custom>
      {({ preview, togglePreview }) => (
        <div className="!ml-auto flex items-center gap-2 py-1 text-sm text-dark">
          Preview <Switch checked={preview} onChange={togglePreview} />
        </div>
      )}
    </HeadlessToolbar.Custom>
  )
}

function SignatureItemFn() {
  return (
    <HeadlessToolbar.Custom>
      {({ editor, editorMode, preview }) => (
        <>
          {!preview && editorMode === 'body' && (
            <button
              type="button"
              className={itemClass(false)}
              onClick={() =>
                editor?.commands.insertContent(
                  `<span data-type="field-suggestions" class="field-suggestion" data-id="SenderSignature}}" data-string="SenderSignature">{{SenderSignature}}</span>`,
                )
              }
            >
              <Signature />
            </button>
          )}
        </>
      )}
    </HeadlessToolbar.Custom>
  )
}

function Full() {
  return (
    <>
      <SignatureItemFn />
      <VariableItemFn />
      <EmojiItemFn />
      <SeparatorItemFn />
      <UnsubscribeItemFn />
      <DividerItemFn />
      <PaletteItemFn />
      <BoldItemFn />
      <UnderlineItemFn />
      <ItalicItemFn />
      <StrikethroughItemFn />
      <CodeItemFn />
      <DividerItemFn />
      <AlignLeftItemFn />
      <AlignCenterItemFn />
      <AlignRightItemFn />
      <AlignJustifyItemFn />
      <DividerItemFn />
      <ListOrderedItemFn />
      <ListUnorderedItemFn />
      <CodeBlockItemFn />
      <DividerItemFn />
      <LinkItemFn />
      <UnlinkItemFn />
      <ImageItemFn />
      <DividerItemFn />
      <FormatClearItemFn />
    </>
  )
}
function Light() {
  return (
    <>
      <VariableItemFn />
      <CalendarLinksItemFn />
      <EmojiItemFn />
      <PaletteItemFn />
      <BoldItemFn />
      <UnderlineItemFn />
      <ItalicItemFn />
      <StrikethroughItemFn />
      <SeparatorItemFn />
      <DividerItemFn />
      <LinkItemFn />
      <UnlinkItemFn />
    </>
  )
}

export const Toolbar = Object.assign(ToolbarFn, {
  // NOTE: those are predefined toolbars, you can use them as is:
  Full: Full,
  Light: Light,

  // custom
  Signature: SignatureItemFn,

  // but here are also the individual buttons, in case you want to make your own toolbar
  Divider: DividerItemFn,
  Bold: BoldItemFn,
  Italic: ItalicItemFn,
  Underline: UnderlineItemFn,
  Strikethrough: StrikethroughItemFn,
  CalendarLinks: CalendarLinksItemFn,
  Code: CodeItemFn,
  CodeBlock: CodeBlockItemFn,
  AlignLeft: AlignLeftItemFn,
  AlignCenter: AlignCenterItemFn,
  AlignRight: AlignRightItemFn,
  AlignJustify: AlignJustifyItemFn,
  ListOrdered: ListOrderedItemFn,
  ListUnordered: ListUnorderedItemFn,
  Separator: SeparatorItemFn,
  Palette: PaletteItemFn,
  Image: ImageItemFn,
  FormatClear: FormatClearItemFn,
  Link: LinkItemFn,
  Unlink: UnlinkItemFn,
  Unsubscribe: UnsubscribeItemFn,
  Emoji: EmojiItemFn,
  Variable: VariableItemFn,
  Custom: CustomItemFn,
  PreviewSwitch: PreviewSwitchItemFn,
})
