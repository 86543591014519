import { Fragment, useState } from 'react'
import { Plus } from '@phosphor-icons/react'
import { useCompanyAPIKeys } from '@/api'
import { ApiKeyType, CompanyApiKeys } from '@/api/core'
import { RiftLogoBlackBG } from '@/icons/RiftLogoBlackBG'
import { ZappierLogo } from '@/icons/ZappierLogo'
import { Button, Text } from '@/ui'
import { Card } from '@/ui/Card'
import { CreateKey } from './CreateKey'
import { DeleteKey } from './DeleteKey'

type Key = {
  emptyTitle: string
  editDenyTitle: string
  name: string
  logo: JSX.Element
  create: () => void
  type: ApiKeyType
  data: CompanyApiKeys
}

enum KeyNames {
  Rift = 'rift',
  Zapier = 'Zapier',
}

export function Keys({ canEdit }: { canEdit: boolean }) {
  const [openCreateKeyModal, setOpenCreateModal] = useState<null | KeyNames>(null)
  const { data } = useCompanyAPIKeys()
  const zapierAPIKeys = data?.data.filter((key) => key.type === ApiKeyType.ZAPIER) || []
  const riftAPIKeys = data?.data.filter((key) => key.type === ApiKeyType.RIFT) || []

  const APIKeys: Key[] = [
    {
      emptyTitle: 'Build custom workflows with Zapier',
      editDenyTitle: 'Contact your admin to build custom workflows with Zapier',
      name: KeyNames.Zapier,
      logo: <ZappierLogo className="rounded-lg" />,
      create: () => setOpenCreateModal(KeyNames.Zapier),
      type: ApiKeyType.ZAPIER,
      data: zapierAPIKeys,
    },
    {
      emptyTitle: `Integrate your own tools with rift’s API`,
      editDenyTitle: 'Contact your admin to build custom workflows with Zapier',
      name: KeyNames.Rift,
      logo: <RiftLogoBlackBG className="rounded-lg" />,
      create: () => setOpenCreateModal(KeyNames.Rift),
      type: ApiKeyType.RIFT,
      data: riftAPIKeys,
    },
  ]

  return (
    <>
      {APIKeys.map((apiKey) => (
        <Fragment key={apiKey.name}>
          <DisplayKey apiKey={apiKey} canEdit={canEdit} />
          {openCreateKeyModal === apiKey.name && (
            <CreateKey companyName={apiKey.name} type={apiKey.type} close={() => setOpenCreateModal(null)} />
          )}
        </Fragment>
      ))}
    </>
  )
}

function DisplayKey({ apiKey, canEdit }: { apiKey: Key; canEdit: boolean }) {
  const [deleteKeyId, setDeleteKeyId] = useState<null | string>(null)
  const title = apiKey.data.length ? `Your ${apiKey.name} API keys` : apiKey.emptyTitle
  return (
    <Card className="mb-3 rounded-lg">
      <div className="flex items-center ">
        <div className="flex h-12 w-12 items-center justify-center">{apiKey.logo}</div>
        <div className="stretch pl-4">
          <Text variant="text" className={canEdit ? 'font-medium' : ''}>
            {canEdit ? title : apiKey.editDenyTitle}
          </Text>
        </div>
        {canEdit && (
          <Button variant="text" className="ml-auto" onClick={apiKey.create}>
            {apiKey.data.length ? <Plus className="h-5 w-5 text-dark" /> : 'Connect'}
          </Button>
        )}
      </div>
      {canEdit &&
        apiKey.data.map((connectionKey) => (
          <div className="flex items-center py-2" key={connectionKey.id}>
            <Text className="ml-16">{connectionKey.name}</Text>
            <Button variant="basic" className="ml-auto" onClick={() => setDeleteKeyId(connectionKey.id)}>
              Delete
            </Button>
            <DeleteKey
              isOpen={deleteKeyId === connectionKey.id}
              close={() => setDeleteKeyId(null)}
              keyId={connectionKey.id}
              keyName={connectionKey.name}
            />
          </div>
        ))}
    </Card>
  )
}
