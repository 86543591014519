import { Fragment, useState } from 'react'
import clsx from 'clsx'
import { useDebounce } from 'use-debounce'
import { Combobox, Transition } from '@headlessui/react'
import { UsersThree } from '@phosphor-icons/react'
import { useChameleonSearch } from '@/api'
import { SearchBar, Text } from '@/ui'

type SearchInputProps = {
  onCreate: (email: string) => void
}

export function SearchInput(props: SearchInputProps) {
  const { onCreate } = props
  const [query, setQuery] = useState('')
  const [queryD] = useDebounce(query, 300)
  const { data: search } = useChameleonSearch(queryD)
  const users = search?.data || []

  const onChange = (email: string | null) => {
    if (email) {
      onCreate(email)
    }
  }

  return (
    <Combobox onChange={onChange}>
      <div className="relative">
        <Combobox.Input as="div">
          <SearchBar
            className="w-full rounded-lg px-8 py-2 text-sm leading-5 "
            value={query}
            onChange={(query) => setQuery(query.trim())}
            autoFocus
          />
        </Combobox.Input>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg">
            {users.length === 0 ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">No user found.</div>
            ) : (
              users.map((user) => (
                <Combobox.Option
                  key={user.email}
                  className={({ active }) =>
                    clsx(
                      'relative cursor-default select-none py-2 pl-10 pr-4',
                      active ? 'bg-gray-100 text-rift-blue-600' : 'text-gray-900',
                    )
                  }
                  value={user.email}
                >
                  <Text variant="subtitle">
                    {user.email} <span className="text-medium">({user.role})</span>
                  </Text>
                  <Text variant="subtext" className="text-medium">
                    {user.organization} {user.isSalesCustomer && <UsersThree className="inline size-4" />}
                  </Text>
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}
