import { NavLink } from 'react-router-dom'
import { Popover } from '@headlessui/react'
import { logoutHandler } from '@/containers/Logout'
import { UserStatus, UserType } from '@/providers/User/context'
import { OpacityTransition } from '@/ui/Transition/OpacityTransition'
import { useUserPermissions } from '../ComponentDisplay'
import { useFlags } from '../FeatureFlag/useFlags'

export function NavigationMenu(props: { user: UserStatus }) {
  const logoutFn = logoutHandler()
  const { showPasswordVault } = useUserPermissions()
  const { showFeatureFlagRaw } = useFlags()
  if (props.user.type !== UserType.Authenticated) return null

  const { first_name = '', last_name = '', email } = props.user.value.traits
  const c = first_name.charAt(0) || email.charAt(0) || 'U' // unknown

  const items = [
    {
      type: 'link',
      to: '/settings',
      label: 'Settings',
    },
    ...(showPasswordVault
      ? [
          {
            type: 'link',
            to: '/vault',
            label: 'Password vault',
          },
        ]
      : []),
    ...(showFeatureFlagRaw
      ? [
          {
            type: 'link',
            to: '/superadmin',
            label: 'rift super admin',
          },
        ]
      : []),
    { type: 'divider' },
    {
      type: 'link',
      to: '/',
      onClick: () => logoutFn(),
      label: 'Sign Out',
      dataTestId: 'signout-btn',
    },
  ] as const

  return (
    <div>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 focus-visible:outline-none"
              data-testid="user-menu"
            >
              <div className="rounded-full border-[1px] p-1 hover:bg-gray-100">
                <span className="inline-flex h-[28px] w-[28px] items-center justify-center rounded-full bg-gray-500 ">
                  <span className="font-medium leading-none text-white">{c.toUpperCase()}</span>
                </span>
              </div>
            </Popover.Button>
            <OpacityTransition show={open}>
              <Popover.Panel className="absolute left-1/2 mt-3.5 flex w-screen max-w-min px-4">
                {({ close }) => (
                  <div className="absolute w-56 shrink -translate-x-[211px] rounded-[10px] bg-white pt-[10px] text-md leading-6 text-dark shadow-lg ring-1 ring-gray-900/5">
                    <p className="m-1 px-4 pt-1.5 font-medium">
                      {first_name} {last_name}
                    </p>
                    <p className="m-1 max-w-[280px] overflow-hidden text-ellipsis px-4 pb-1.5">{email}</p>
                    {items.map((item, i) =>
                      item.type === 'link' ? (
                        <NavLink
                          key={i}
                          onClick={() => {
                            ;(item as any)?.onClick?.()
                            close()
                          }}
                          to={item.to}
                          data-testid={(item as any)?.dataTestId}
                          className="m-1 block px-4 py-2 hover:rounded-lg hover:bg-gray-100"
                        >
                          {item.label}
                        </NavLink>
                      ) : (
                        <hr key={i} className="border-t-1 mx-1 border-solid border-tertiary" />
                      ),
                    )}
                  </div>
                )}
              </Popover.Panel>
            </OpacityTransition>
          </>
        )}
      </Popover>
    </div>
  )
}
