/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SequenceSendMode {
    AUTOMATIC_EMAIL = 'AUTOMATIC_EMAIL',
    MANUAL_EMAIL = 'MANUAL_EMAIL',
    MANUAL_CALL = 'MANUAL_CALL',
    LINKEDIN_MESSAGE = 'LINKEDIN_MESSAGE',
}
