import hubspotlogo from '@/assets/hubspot-logo.png'
import hubspotlogoSimple from '@/assets/hubspot-logo-simple.webp'

export function HubspotLogo({
  width,
  className,
  simple = true,
}: React.ImgHTMLAttributes<HTMLImageElement> & { simple?: boolean }) {
  return <img width={width} className={className} src={simple ? hubspotlogoSimple : hubspotlogo} alt="hubspot logo" />
}
