import {
  createContext,
  ElementType,
  Fragment,
  KeyboardEvent,
  Ref,
  RefObject,
  useContext,
  useId,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Props } from '@headlessui/react/dist/types'
import { HasDisplayName, RefProp } from '@headlessui/react/dist/utils/render'
import { useEvent } from '@/hooks/useEvent'
import { useSyncRefs } from '@/hooks/useSyncRefs'
import { useTreeData } from '@/hooks/useTreeData'
import { forwardRefWithAs, uirender } from '@/ui/render'
import { DescendantProvider, useDescendantContext } from './DescendantProvider'

const rootItemId = ''

interface StateDefinition {
  tree: ReturnType<typeof useTreeData<TreeData>>
  labelRefs: Record<string, RefObject<HTMLElement>>
}

const TreeActionsContext = createContext<{
  addItem(itemId: string, parentItemId: string): () => void
  openItem(itemId: string): void
  closeItem(itemId: string): void
  toggleItem(itemId: string): void
  nextItem(itemId: string): TreeData | null
  prevItem(itemId: string): TreeData | null
  addLabel(itemId: string, ref: Ref<HTMLElement | ElementType>): void
} | null>(null)
TreeActionsContext.displayName = 'TreeActionsContext'

function useActions() {
  const context = useContext(TreeActionsContext)
  if (context === null) {
    throw new Error(`component is missing a parent <Tree /> component.`)
  }
  return context
}

const TreeDataContext = createContext<StateDefinition | null>(null)
TreeDataContext.displayName = 'TreeDataContext'

function useData() {
  const context = useContext(TreeDataContext)
  if (context === null) {
    throw new Error(`component is missing a parent <Tree /> component.`)
  }
  return context
}

export const DEFAULT_TREE_TAG = Fragment
export interface TreeRenderPropArg {}
export type TreeProps<TTag extends ElementType> = Props<TTag, TreeRenderPropArg>

type TreeData = {
  id: string
  open: boolean
}

function TreeFn<TTag extends ElementType = typeof DEFAULT_TREE_TAG>(props: TreeProps<TTag>, ref: Ref<HTMLElement>) {
  const internalTreeRef = useRef<HTMLElement | null>(null)
  const treeRef = useSyncRefs(internalTreeRef, ref)

  const tree = useTreeData<TreeData>()
  const [labelRefs, setLabelRefs] = useState<Record<string, RefObject<HTMLElement>>>({})

  const addItem = useEvent((itemId: string, parentItemId: string) => {
    tree.append(parentItemId === '' ? null : parentItemId, {
      id: itemId,
      open: true,
    })
    return () => {
      if (parentItemId !== '') tree.remove(itemId)
    }
  })

  const toggleItem = useEvent((itemId: string) => {
    tree.update(itemId, (value) => ({ ...value, open: !value.open }))
  })

  const openItem = useEvent((itemId: string) => {
    tree.update(itemId, (value) => ({ ...value, open: true }))
  })
  const closeItem = useEvent((itemId: string) => {
    tree.update(itemId, (value) => ({ ...value, open: false }))
  })

  const nextItem = useEvent((itemId: string) => tree.next(itemId)?.value ?? null)
  const prevItem = useEvent((itemId: string) => tree.prev(itemId)?.value ?? null)
  const addLabel = useEvent((itemId: string, ref: RefObject<HTMLElement>) =>
    setLabelRefs((prev) => ({ ...prev, [itemId]: ref })),
  )
  const treeActions = useMemo(() => ({ addItem, openItem, closeItem, toggleItem, nextItem, prevItem, addLabel }), [])

  const { ...theirProps } = props
  const ourProps = {
    ref: treeRef,
  }
  const slot = {}
  return (
    <TreeActionsContext.Provider value={treeActions}>
      <TreeDataContext.Provider value={{ tree, labelRefs }}>
        <DescendantProvider itemId={rootItemId}>
          {uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_TREE_TAG })}
        </DescendantProvider>
      </TreeDataContext.Provider>
    </TreeActionsContext.Provider>
  )
}

export const DEFAULT_ITEMS_TAG = 'ul'
export interface ItemsRenderPropArg {}
export type TreeItemsProps<TTag extends ElementType> = Props<TTag, ItemsRenderPropArg>
function ItemsFn<TTag extends ElementType = typeof DEFAULT_ITEMS_TAG>(
  props: TreeItemsProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { ...theirProps } = props
  const slot = {}
  const ourProps = { ref }
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_ITEMS_TAG })
}

// ---

export const DEFAULT_ITEM_TAG = 'li'
export interface TreeItemRenderPropArg {
  open: boolean
  hasChildren: boolean
  close: () => void
}

export type TreeItemProps<TTag extends ElementType> = Props<TTag, TreeItemRenderPropArg, never>
function ItemFn<TTag extends ElementType = typeof DEFAULT_ITEM_TAG>(props: TreeItemProps<TTag>, ref: Ref<HTMLElement>) {
  const { ...theirProps } = props
  const internalItemRef = useRef<HTMLElement | null>(null)
  const itemRef = useSyncRefs(internalItemRef, ref)
  const { itemId: parentItemId } = useDescendantContext()
  const { tree } = useData()
  const actions = useActions()
  const [inserted, setInserted] = useState(false)
  const itemId = useId()

  useLayoutEffect(() => {
    const remove = actions.addItem(itemId, parentItemId)
    setInserted(true)
    return () => {
      remove()
      setInserted(false)
    }
  }, [])

  const parentItem = tree.getItem(parentItemId)

  const item = tree.getItem(itemId)
  const slot = useMemo(
    () => ({
      open: item?.value.open ?? false,
      close: () => actions.closeItem(itemId),
      hasChildren: !!item?.children.length,
    }),
    [item],
  )

  const ourProps = { ref: itemRef }

  if (!inserted || (parentItemId != '' && !parentItem?.value.open)) {
    return null
  }

  return (
    <DescendantProvider itemId={itemId}>
      {uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_ITEM_TAG })}
    </DescendantProvider>
  )
}

// ---

export const DEFAULT_TOGGLE_TAG = 'button'
export interface TreeToggleRenderPropArg {
  open: boolean
}
export type TreeToggleProps<TTag extends ElementType> = Props<TTag, TreeToggleRenderPropArg>
function ToggleFn<TTag extends ElementType = typeof DEFAULT_TOGGLE_TAG>(
  props: TreeToggleProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { itemId } = useDescendantContext()
  const actions = useActions()
  const onClick = useEvent(() => {
    actions.toggleItem(itemId)
  })
  const { tree } = useData()

  const item = tree.getItem(itemId)
  const slot = useMemo(
    () => ({
      open: item?.value.open || false,
    }),
    [item],
  )

  const ourProps = {
    ref,
    onClick,
  }

  return uirender({ ourProps, theirProps: props, slot, defaultTag: DEFAULT_TOGGLE_TAG })
}

// ---

export const DEFAULT_LABEL_TAG = 'span'
export interface TreeLabelRenderPropArg {}
export type TreeLabelPropsWeControl = 'aria-expanded'
export type TreeLabelProps<TTag extends ElementType> = Props<TTag, TreeLabelRenderPropArg, TreeLabelPropsWeControl>
function LabelFn<TTag extends ElementType = typeof DEFAULT_LABEL_TAG>(
  props: TreeLabelProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const internalLabelRef = useRef<HTMLElement | null>(null)
  const labelRef = useSyncRefs(internalLabelRef, ref)
  const { itemId } = useDescendantContext()
  const actions = useActions()
  const { tree, labelRefs } = useData()
  useLayoutEffect(() => {
    actions.addLabel(itemId, internalLabelRef)
  }, [internalLabelRef.current])

  const item = tree.getItem(itemId)

  const onKeyDown = useEvent((event: KeyboardEvent<TTag>) => {
    switch (event.code) {
      case 'Space':
        event.preventDefault()
        event.stopPropagation()
        actions.toggleItem(itemId)
        break
      case 'ArrowUp':
        const prev = actions.prevItem(itemId)
        if (prev) {
          labelRefs[prev.id]?.current?.focus()
        }
        break
      case 'ArrowDown':
        const next = actions.nextItem(itemId)
        if (next) {
          labelRefs[next.id]?.current?.focus()
        }
        break
    }
  })

  const ourProps = {
    ref: labelRef,
    onKeyDown,
    // Note: to make div focusable, we need to add a tabIndex
    // as is set to undefined when not provided, so we need to check for that as well
    ...(props.as === 'div' || props.as === undefined ? { tabIndex: 0 } : {}),
    'aria-expanded': item?.value.open ?? false,
  }
  return uirender({ ourProps, theirProps: props, slot: {}, defaultTag: DEFAULT_LABEL_TAG })
}

// ---

interface ComponentTree extends HasDisplayName {
  <TTag extends ElementType = typeof DEFAULT_TREE_TAG>(props: TreeProps<TTag> & RefProp<typeof TreeFn>): JSX.Element
}

interface ComponentItems extends HasDisplayName {
  <TTag extends ElementType = typeof DEFAULT_ITEMS_TAG>(
    props: TreeItemsProps<TTag> & RefProp<typeof ItemsFn>,
  ): JSX.Element
}

interface ComponentItem extends HasDisplayName {
  <TTag extends ElementType = typeof DEFAULT_ITEM_TAG>(props: TreeItemProps<TTag> & RefProp<typeof ItemFn>): JSX.Element
}

interface ComponentToggle extends HasDisplayName {
  <TTag extends ElementType = typeof DEFAULT_TOGGLE_TAG>(
    props: TreeToggleProps<TTag> & RefProp<typeof ToggleFn>,
  ): JSX.Element
}

interface ComponentLabel extends HasDisplayName {
  <TTag extends ElementType = typeof DEFAULT_LABEL_TAG>(
    props: TreeLabelProps<TTag> & RefProp<typeof LabelFn>,
  ): JSX.Element
}

const TreeRoot = forwardRefWithAs(TreeFn) as unknown as ComponentTree
const Items = forwardRefWithAs(ItemsFn) as unknown as ComponentItems
const Item = forwardRefWithAs(ItemFn) as unknown as ComponentItem
const Toggle = forwardRefWithAs(ToggleFn) as unknown as ComponentToggle
const Label = forwardRefWithAs(LabelFn) as unknown as ComponentLabel

export const Tree = Object.assign(TreeRoot, {
  Items,
  Item,
  Toggle,
  Label,
})
