import { MeetingCategory } from '@/api/core'

export const MeetingCategoryToText: Record<MeetingCategory, string> = {
  [MeetingCategory.PERSONAL]: 'Personal',
  [MeetingCategory.ROUND_ROBIN]: 'Round-robin',
}

export const TextToMeetingCategory: Record<string, MeetingCategory> = Object.fromEntries(
  Object.entries(MeetingCategory).map(([key, value]) => [value, key as MeetingCategory]),
)

export const MeetingCategoryLabels: { label: string; value: MeetingCategory }[] = Object.entries(
  MeetingCategoryToText,
).map(([value, label]) => ({
  label: label,
  value: value as MeetingCategory,
}))

export type MeetingCategoryLabel = (typeof MeetingCategoryLabels)[number]
