import clsx from 'clsx'
import { Dropdown, DropdownOption } from '@/components/Dropdown'
import { Input, Text } from '@/ui'
import { Button } from '@/ui/Button/v2'
import { ErrorText } from '@/ui/Text/Error'
import { Action, ContactObject, errorMessage, header, isInvalid, State, stdHeadersToSelect } from '../reducer'

export type MappingProps = {
  onCancel: () => void
  onImport: () => void
  importBtnText: 'Next' | 'Import'
  state: State
  dispatch: React.Dispatch<Action>
  createList?: boolean
  defaultListName?: string
  setListName: (l: string) => void
}

export function Mapping(props: MappingProps) {
  const { onCancel, onImport, state, dispatch, createList, defaultListName, importBtnText, setListName } = props
  const errorMsg = errorMessage(state)

  return (
    <>
      <div className="flex grow flex-col overflow-auto rounded border border-dashed border-light p-2">
        {createList && (
          <div className="mt-4 flex flex-col border-b pb-6">
            <Text variant="subtext" className="text-xs">
              Step 1
            </Text>
            <Text variant="subtitle" className="pb-2">
              Contact list name
            </Text>
            <input
              className="mt-1 block w-full appearance-none rounded-lg border border-gray-300 p-2 text-sm placeholder-gray-400 shadow-sm transition-colors hover:bg-gray-50 focus:border-primary focus:outline-none focus:ring-primary"
              value={defaultListName}
              onChange={(e) => setListName(e.target.value)}
            />
          </div>
        )}
        <section className={clsx(createList && 'py-6')}>
          {createList && (
            <div>
              <Text variant="subtext" className="text-xs">
                Step 2
              </Text>
              <Text variant="subtitle" className="pb-4">
                Review import mapping
              </Text>
            </div>
          )}
          <div className="flex bg-extra-light py-2">
            <Text variant="subtitle" className="w-1/4">
              Attribute
            </Text>
            <Text variant="subtitle" className="w-1/2">
              Example
            </Text>
            <Text variant="subtitle" className="w-1/4">
              Assigned to
            </Text>
          </div>

          {state.headers.map((heading, index: number) => (
            <AttributesRow
              key={index}
              selectable={stdHeadersToSelect(state)}
              header={heading}
              example={state.contacts?.[0]?.data[heading.from] ?? ''}
              setSkipHeader={() => {
                dispatch({ type: 'skip_header', headerNo: index })
              }}
              setCustomHeader={(toHeader: string) => {
                dispatch({
                  type: 'custom_header',
                  headerNo: index,
                  toHeader,
                })
              }}
              setStandardHeader={(toHeader: keyof ContactObject) => {
                dispatch({
                  type: 'map_header',
                  headerNo: index,
                  toHeader,
                })
              }}
            />
          ))}
        </section>
      </div>
      <section className="mt-4 flex justify-between">
        <Button variant="basic" onClick={onCancel}>
          Cancel
        </Button>
        {errorMsg ? (
          <ErrorText className="absolute bottom-0 right-0 px-8 pb-8" errorMessage={errorMsg} />
        ) : (
          <Button variant="accent" onClick={() => onImport()}>
            {importBtnText}
          </Button>
        )}
      </section>
    </>
  )
}

const defaultOptions = [
  { label: 'Skip field', value: null },
  { label: 'Custom field', value: '' },
]

type AttributesRowProps = {
  selectable: string[]
  header: header
  example: string
  setSkipHeader: () => void
  setCustomHeader: (toHeader: string) => void
  setStandardHeader: (toHeader: keyof ContactObject) => void
}

export function AttributesRow(props: AttributesRowProps) {
  const { selectable, header, example, setSkipHeader, setCustomHeader, setStandardHeader } = props

  const options = [...selectable.map((s) => ({ label: s, value: s })), ...defaultOptions]
  const label = header.type === 'standard' ? header.to : header.type === 'custom' ? 'Custom field' : 'Skip field'
  const value = { label: label, value: header.type === 'skip' ? undefined : header.to }

  function handleSelection(selected: DropdownOption) {
    if (selected.label === 'Skip field') {
      setSkipHeader()
    } else if (selected.label === 'Custom field') {
      setCustomHeader(props.header.from)
    } else {
      setStandardHeader(selected.id as keyof ContactObject)
    }
  }

  return (
    <div className="flex py-4">
      <Text className="flex w-1/4 items-center">{header.from}</Text>
      <Text className="flex w-1/2 items-center">{example || '--'}</Text>
      <div className="w-1/4">
        <Dropdown
          selected={{ id: value.value as string, label: value.label as string }}
          handleSelection={handleSelection}
          options={options.map((option) => ({ id: option.value as string, label: option.label }))}
        />
        {value.label === 'Custom field' && (
          <div className="mt-4">
            <Input
              placeholder="Field name"
              className="h-9 w-full border border-gray-300 px-2 py-2"
              value={value.value || ''}
              onChange={(e) => setCustomHeader(e.target.value)}
            />
            {isInvalid(header) && (
              <ErrorText
                exclamationIconWidth={0}
                className="text-xs"
                textVariant="subtext"
                errorMessage="Custom field name must start with a capital letter and only have letters, numbers and underscores."
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
