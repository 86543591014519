import { useLocation, useNavigate } from 'react-router-dom'
import { UsersThree } from '@phosphor-icons/react'
import { useChameleon, useChameleonCreate, useChameleonDelete } from '@/api'
import { FeatureFlag } from '@/containers/FeatureFlag'
import { useCopyToClipboard } from '@/hooks'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Text } from '@/ui'
import { Banner } from '@/ui/Banner'
import { DangerButton } from '@/ui/Button/v2'
import { SearchInput } from './SearchInput'

export function ChameleonBanner() {
  const navigate = useNavigate()
  const location = useLocation()

  const toast = useToast()
  const { data, status } = useChameleon()
  const chameleonCreate = useChameleonCreate()
  const chameleonDelete = useChameleonDelete()
  const { copy } = useCopyToClipboard()

  const onChameleonDelete = () => {
    navigate('/')
    chameleonDelete.mutate(undefined, {
      onSuccess: () => window.location.reload(),
    })
  }
  const onChameleonCreate = (email: string) => {
    if (location.pathname.includes('/sequences/')) {
      // before invalidateQueries, navigate to home page
      // because if someone is in a campaign details page,
      // he will be auto chameleon back to user before.
      navigate('/')
    }

    chameleonCreate.mutate(email, {
      onSuccess: () => window.location.reload(),
      onError: () => toast.createToast({ message: 'User not found', error: true }),
    })
  }

  const copyToClipboard = () => {
    if (data?.data.email) {
      copy(data.data.email)
    }
  }

  if (status !== 'success' || data.data.email === '') {
    return null
  }

  return (
    <FeatureFlag className="border-none">
      <Banner severity="warning" className="m-2">
        <div className="flex justify-between">
          <hgroup>
            <Banner.Title>
              Chameleoned as{' '}
              <span onClick={copyToClipboard} className="cursor-pointer hover:text-yellow-800">
                {data.data.email}
              </span>
            </Banner.Title>
            <Text>
              {data.data.organization} ({data.data.role})
              {data.data.isSalesCustomer && <UsersThree className="ml-2 inline h-6 w-6" />}
            </Text>
          </hgroup>

          <div className="flex items-center gap-2">
            <div className="w-80">
              <SearchInput onCreate={onChameleonCreate} />
            </div>
            <DangerButton variant="accent" onClick={onChameleonDelete}>
              Reset identity
            </DangerButton>
          </div>
        </div>
      </Banner>
    </FeatureFlag>
  )
}
