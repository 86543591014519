import { Link } from 'react-router-dom'
import { ManualStepLinkedinMessageItemStatus } from '@/api/core'
import { useManualLinkedinMessages } from '@/api/manual_step'
import { Text } from '@/ui'
import { AllSteps } from '../AllSteps'
import { Finished } from '../Finished'
import { AllStepsSkeleton } from '../Skeletons'

export function LinkedinMessages() {
  const { data, status } = useManualLinkedinMessages()

  if (status === 'error' || status === 'pending') {
    return <AllStepsSkeleton />
  }

  return (
    <section>
      {data.data.length > 0 ? (
        <AllSteps>
          {data?.data.map(({ sequence, messages }) => (
            <AllSteps.Group key={sequence.id} title={sequence.name}>
              {messages.map(({ id, contact, status }) => (
                <AllSteps.Item key={id}>
                  <Link to={`/inbox/linkedin-messages/${id}`} className="cursor-default" data-testid="linkedin-step">
                    <Text variant="text" className="font-medium text-dark">
                      {status === ManualStepLinkedinMessageItemStatus.DRAFT && (
                        <>
                          <span className="text-success">Draft</span> to
                        </>
                      )}
                      {` `}
                      {contact.name}
                    </Text>
                    <Text className="text-dusk">
                      {contact.title} {contact.title && contact.company && 'at'} {contact.company}
                    </Text>
                  </Link>
                </AllSteps.Item>
              ))}
            </AllSteps.Group>
          ))}
        </AllSteps>
      ) : (
        <Finished message="No Linkedin messages to send" />
      )}
    </section>
  )
}
