import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Spinner } from '@/ui'

type DialogProps = {
  show: boolean
}

const noop = () => {}

export function LoadingDialog(props: DialogProps) {
  return (
    <Transition
      appear
      show={props.show}
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog onClose={noop} className="relative">
        <>
          <div className="fixed inset-0 bg-black bg-opacity-25" />
          <div className="fixed inset-0 z-20 overflow-y-auto">
            <div className="flex min-h-full w-full flex-col items-center justify-center text-center">
              <Dialog.Panel className="flex flex-col items-center justify-center rounded-lg bg-white p-12">
                <span className="mb-2 text-gray-900">
                  Please do not close this window <br /> until the action is complete.
                </span>
                <Spinner size="xl" />
              </Dialog.Panel>
            </div>
          </div>
        </>
      </Dialog>
    </Transition>
  )
}
