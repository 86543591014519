import { useNavigate } from 'react-router-dom'
import { useDeleteBlueprint } from '@/api/sequence_generation'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button } from '@/ui'

export function DeleteBlueprintButton({ id }: { id: string }) {
  const deleteBlueprint = useDeleteBlueprint(id)
  const navigate = useNavigate()
  const toast = useToast()

  const onClick = () => {
    deleteBlueprint.mutate(undefined, {
      onSuccess: () => {
        toast.createToast({ message: 'Blueprint deleted' })
        navigate('/account-sequences/blueprints')
      },
      onError: () => {
        toast.createToast({ message: 'There was an error deleting the blueprint', error: true })
        navigate('/account-sequences/blueprints')
      },
    })
    navigate('/account-sequences/blueprints')
  }

  return <Button onClick={onClick}>Delete</Button>
}
