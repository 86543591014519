import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { UseFormReset } from 'react-hook-form'
import { SettingsFlow } from '@ory/client'
import { kratos } from '@/config/kratos'
import { reportSentryErrorWithEmail } from '@/sentry'

type useCreateSettingsFlowProps = {
  setFlow: (val: SettingsFlow | null) => void
  reset: UseFormReset<{
    email: string
    firstName: string
    lastName: string
    company: string
  }>
}
export function useCreateSettingsFlow({ setFlow, reset }: useCreateSettingsFlowProps) {
  const navigate = useNavigate()
  return useCallback(() => {
    kratos
      .createBrowserSettingsFlow()
      .then(({ data: flow }) => {
        const { first_name, last_name, company_name } = flow.identity.traits
        reset({ ...flow.identity.traits, firstName: first_name, lastName: last_name, company: company_name })
        setFlow(flow)
      })
      .catch((error) => {
        reportSentryErrorWithEmail('[KRATOS] Settings initializeSelfServiceSettingsFlowForBrowsers failed', {
          extra: {
            error,
          },
        })
        navigate('/settings', { replace: true })
      })
  }, [])
}
