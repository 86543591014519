import { Chart } from './Chart'
import { Table } from './Table'

export function Graph() {
  return (
    <section className="my-6 rounded-lg border bg-white p-3">
      <Chart />
      <Table />
    </section>
  )
}
