import { useOnboardingReset } from '@/api/onboarding'
import { FeatureFlag } from '@/containers/FeatureFlag'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Text } from '@/ui'

export function Reset() {
  const toast = useToast()
  const reset = useOnboardingReset()
  const onClick = () => {
    reset.mutate(undefined, {
      onError: (err) =>
        toast.createToast({ message: (err as any)?.body?.message || 'failed to reset onboarding', error: true }),
    })
  }
  return (
    <FeatureFlag>
      <div className="flex flex-col items-center justify-center py-2">
        <Button variant="basic" onClick={onClick}>
          Reset onboarding
        </Button>
        <Text variant="subtext">Start the onboarding process from the domain setup step.</Text>
      </div>
    </FeatureFlag>
  )
}
