import * as Sentry from '@sentry/react'
import { UserType, useUserContext } from '@/providers/User/context'
import { ProtectedRoutes } from '@/routes/protected'
import { PublicRoutes } from '@/routes/public'

export function AppRoutes() {
  const { user } = useUserContext()
  Sentry.setUser({ ...user.value?.traits })

  return (
    <>
      {user.type === UserType.Authenticated ? (
        <ProtectedRoutes />
      ) : user.type === UserType.Unknown ? (
        <></>
      ) : (
        <PublicRoutes />
      )}
    </>
  )
}
