import React, { SetStateAction, useRef, useState } from 'react'
import { clsx } from 'clsx'
import { format } from 'date-fns'
import { CaretLeft, CaretRight, FunnelSimple } from '@phosphor-icons/react'
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Campaign, CampaignContactsRecentlyAdded, SequenceContact, SequenceContacts, SequenceStatus } from '@/api/core'
import { ContactFieldToSentenceText } from '@/api/text/contact_fields'
import { SequenceStatusToLabel, SequenceStatusToTableSubheader } from '@/api/text/sequence_status'
import { useQueryParamState } from '@/hooks/useQueryParamState'
import { dateOrTimeFormat } from '@/lib/date'
import { ContactDetails } from '@/pages/Contacts/ContactDetails'
import { Button, Checkbox, IndeterminateCheckbox, MouseTrap, Popover, SearchBar, Text, Tooltip } from '@/ui'
import { isDraft } from '../utility'
import { ContactsFloatingAppBar } from './FloatingAppBar'

const PageMax = 50
type ContactTableProps = {
  addContactsButton?: JSX.Element | null
  contacts: SequenceContacts
  campaign: Campaign
  contactsRecentlyAdded: CampaignContactsRecentlyAdded | undefined
}

const filterOptions: SequenceStatus[] = [
  SequenceStatus.MISSING_FIELDS,
  SequenceStatus.SCHEDULED,
  SequenceStatus.ACTIVE,
  SequenceStatus.INACTIVE,
  SequenceStatus.UNSUBSCRIBED,
  SequenceStatus.CATCH_ALL,
  SequenceStatus.INVALID,
]

type StatusFilters = Array<{ id: 'status'; value: Array<SequenceStatus> }>

const initialFilter: StatusFilters = [{ id: 'status', value: [] }]

export function ContactsTable({ addContactsButton, contacts, campaign, contactsRecentlyAdded }: ContactTableProps) {
  const [searchText, setSearchText] = useState('')
  const [pageIndex, setPageIndex] = useState(0)
  const [rowSelection, setRowSelection] = useState<{ [key: number]: boolean }>({})
  const [contactId, setContactId] = useQueryParamState('contactId')
  const [statusFilters, setStatusFilters] = useState<StatusFilters>(initialFilter)

  const tableRef = useRef(null)

  const initialCounts: Record<SequenceStatus, number> = {
    [SequenceStatus.MISSING_FIELDS]: 0,
    [SequenceStatus.SCHEDULED]: 0,
    [SequenceStatus.ACTIVE]: 0,
    [SequenceStatus.INACTIVE]: 0,
    [SequenceStatus.NOT_INTERESTED]: 0,
    [SequenceStatus.UNSUBSCRIBED]: 0,
    [SequenceStatus.INVALID]: 0,
    [SequenceStatus.CATCH_ALL]: 0,
  }

  const columnHelper = createColumnHelper<SequenceContact>()

  function Separator() {
    return <span className="text-medium">&nbsp;&nbsp;•&nbsp;&nbsp;</span>
  }

  const columns = [
    columnHelper.display({
      id: 'select',
      header: '',
      cell: ({ row }) => (
        <div className="mr-6 w-[18px] text-sm">
          <MouseTrap preventDefault>
            <IndeterminateCheckbox
              className="mr-2"
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </MouseTrap>
        </div>
      ),
      meta: {
        checkboxRow: true,
      },
    }),
    columnHelper.accessor((row) => [row.first_name, row.last_name, row.email, row.company].join(' '), {
      id: 'contact-info',
      cell: (info) => {
        return (
          <div className="w-full overflow-hidden text-ellipsis text-left text-sm">
            <span className="font-medium" data-testid="contact-name">
              {[info.row.original.first_name, info.row.original.last_name].join(' ').trim() || '–'}
            </span>
            <span className="font-normal text-dusk">
              {info.row.original.company && (
                <>
                  <Separator />
                  {info.row.original.company}
                </>
              )}
              <Separator />
              {info.row.original.email}
            </span>
          </div>
        )
      },
    }),

    columnHelper.accessor(() => '', {
      id: 'dates-or-missing-fields',
      cell: ({ row: { original } }) => {
        if (original.missingFields) {
          return (
            <div className="mr-3 pl-2 text-sm">
              <span className="text-alert">
                Missing {original.missingFields.map(ContactFieldToSentenceText).join(', ')}
              </span>
            </div>
          )
        } else if (original.scheduledFrom && original.scheduledTo) {
          return (
            <div className="mr-3 flex pl-2 text-sm font-normal">
              <div className="w-11 text-center text-dusk">
                {format(new Date(Date.parse(original.scheduledFrom)), 'LLL d')}
              </div>
              &nbsp;&nbsp;<span className="text-medium-light">&mdash;</span>&nbsp;&nbsp;
              <div className="w-11 text-center text-dusk">
                {format(new Date(Date.parse(original.scheduledTo)), 'LLL d')}
              </div>
            </div>
          )
        }
        return null
      },
      enableColumnFilter: false,
    }),
    // Dummy column to allow client-side status filtering
    columnHelper.accessor((row) => row.sequenceStatus, {
      id: 'status',
      cell: () => null,
      filterFn: (row, _, value: Array<SequenceStatus>) =>
        value.length > 0 ? value.includes(row.original.sequenceStatus) : true,
    }),
  ]

  const table = useReactTable<SequenceContact>({
    data: contacts,
    columns: columns as ColumnDef<SequenceContact, any>[],
    state: {
      rowSelection,
      pagination: { pageSize: 50, pageIndex: pageIndex },
      globalFilter: searchText,
      // columnFilters need to be set to a specific format of Array<{id: COL_NAME, value: THINGS TO FILTER }>
      // and thus statusFilters are from a useState with type Array<{ id: 'status'; value: Array<SequenceStatus> }>
      // Additionally, we add custom `filterFn` for the status column that allows us to use to multiple statuses at once
      columnFilters: statusFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  const allContactsCountByStatus = contacts.reduce(
    (accum: Record<SequenceStatus, number>, c) => {
      accum[c.sequenceStatus]++
      return accum
    },
    { ...initialCounts },
  )

  const filteredContactsCountByStatus = table.getFilteredRowModel().rows.reduce(
    (accum: Record<SequenceStatus, number>, c) => {
      accum[c.original.sequenceStatus]++
      return accum
    },
    { ...initialCounts },
  )

  const tableRows = table.getRowModel().rows

  // we need to set the page index to 0 when beginning a new search so users can see the beginning of the
  // search results. Before doing this some users would not be able to see search results since they were on page
  // 5 but search results were only on pages 1-3
  function handleSearchTable(query: SetStateAction<string>) {
    if (searchText === '' && query !== '') {
      setPageIndex(0)
    }

    setSearchText(query)
  }

  function handleFilterChange(filter: SequenceStatus) {
    setPageIndex(0)
    if (statusFilters[0].value.includes(filter)) {
      setStatusFilters([{ id: 'status', value: statusFilters[0].value.filter((v) => v !== filter) }])
    } else {
      setStatusFilters([{ id: 'status', value: [...statusFilters[0].value, filter] }])
    }
  }

  function TableRowSubHeader({ status }: { status: SequenceStatus }) {
    return (
      <div className="sticky -top-px flex items-center bg-white py-2 text-left">
        <Text className="font-medium">{SequenceStatusToTableSubheader[status]}</Text>
        <Text className="ml-2 text-xs text-medium">{filteredContactsCountByStatus[status]}</Text>
      </div>
    )
  }

  return (
    <section className="relative min-w-full overflow-y-scroll px-6" ref={tableRef}>
      <div className="flex items-baseline justify-between py-6">
        {/* NOTE wrap it in a div to prevent flex from stretching if btn is empty*/}
        <div className="flex items-center gap-4">
          <Tooltip placement="bottom">
            <Tooltip.Trigger>
              <IndeterminateCheckbox
                aria-label="Select all"
                checked={table.getIsAllRowsSelected()}
                indeterminate={table.getIsSomeRowsSelected()}
                onChange={table.toggleAllRowsSelected}
              />
            </Tooltip.Trigger>
            <Tooltip.Panel>
              <Text>Select all</Text>
            </Tooltip.Panel>
          </Tooltip>
          {addContactsButton}
        </div>

        <div className="flex gap-4">
          <SearchBar value={searchText} className="ml-auto w-52" onChange={(query) => handleSearchTable(query)} />
          <Popover>
            <Popover.Button variant="basic" className="relative p-2" data-testid="contacts-filter-btn">
              <FunnelSimple width={20} height={20} className="m-auto text-dark" />
              {statusFilters[0].value.length > 0 && (
                <span className="absolute right-1 top-1 h-1.5 w-1.5 rounded-full bg-accent" />
              )}
            </Popover.Button>
            <Popover.Panel className="absolute right-0 z-10 mt-2 w-fit whitespace-nowrap rounded-lg border border-light bg-white p-3 text-sm shadow-sm">
              {filterOptions.map((filter) => (
                <div key={filter} className="my-1 flex justify-between gap-2">
                  <div className="flex items-center gap-2">
                    <Checkbox
                      id={`${filter}-filter`}
                      className="h-4 w-4 focus:ring-0"
                      checked={statusFilters[0].value.includes(filter)}
                      onChange={() => handleFilterChange(filter)}
                      data-testid={`${filter}-checkbox`}
                    />
                    <label className="flex justify-between" htmlFor={`${filter}-filter`}>
                      <span>{SequenceStatusToLabel[filter]}</span>
                    </label>
                  </div>

                  <span className="text-right text-xs text-gray-500" data-testid={`${filter}-filter-count`}>
                    {allContactsCountByStatus[filter]}
                  </span>
                </div>
              ))}
              <Button variant="basic" className="mt-2 w-full" onClick={() => setStatusFilters(initialFilter)}>
                Clear filters
              </Button>
            </Popover.Panel>
          </Popover>
        </div>
      </div>
      <ContactsFloatingAppBar
        data={contacts}
        selectedRows={rowSelection}
        setRowSelection={() => setRowSelection({})}
        isDraft={isDraft(campaign)}
      />
      {tableRows.map((row, index) => {
        return (
          <React.Fragment key={row.original.id}>
            {(index === 0 || row.original.sequenceStatus !== tableRows[index - 1].original.sequenceStatus) && (
              <TableRowSubHeader status={row.original.sequenceStatus} />
            )}
            <div
              key={row.original.id}
              className="flex items-center whitespace-nowrap border-b border-light py-2.5 leading-5 hover:cursor-pointer hover:overflow-visible hover:bg-extra-light"
              onClick={() => setContactId(row.original.id)}
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <React.Fragment key={row.original.id + '_' + cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </React.Fragment>
                )
              })}
            </div>
          </React.Fragment>
        )
      })}

      {contactId && (
        <ContactDetails
          contactId={contactId}
          onClose={() => setContactId(null)}
          missingFields={contacts.find((c) => c.id === contactId)?.missingFields}
        />
      )}
      <nav
        aria-label="Table Pagination"
        className="sticky bottom-0 flex h-14 items-center justify-between bg-extra-light px-8 py-3"
      >
        <div>
          {contactsRecentlyAdded && contactsRecentlyAdded.contactsNumber && (
            <Text variant="subtext" className="font-normal text-medium">
              {`${contactsRecentlyAdded.contactsNumber} new contacts added `}
              {contactsRecentlyAdded.lastAddedAt &&
                dateOrTimeFormat(contactsRecentlyAdded.lastAddedAt ?? 0, { datePrefix: 'on ', timeSuffix: ' ago' })}
            </Text>
          )}
        </div>
        <div className="flex">
          <Text>
            {`${pageIndex * PageMax} - ${
              pageIndex * PageMax + PageMax < table.getFilteredRowModel().rows.length
                ? pageIndex * PageMax + PageMax
                : table.getFilteredRowModel().rows.length
            } of `}
            <span data-testid="total-contacts-count">{table.getFilteredRowModel().rows.length}</span>
          </Text>
          <Text className="ml-0.5 mr-6 text-medium">
            {filteredContactsCountByStatus[SequenceStatus.INVALID] +
              filteredContactsCountByStatus[SequenceStatus.CATCH_ALL] >
              0 && ` - ${filteredContactsCountByStatus[SequenceStatus.ACTIVE]} active`}
            {filteredContactsCountByStatus[SequenceStatus.INVALID] > 0 &&
              `, ${filteredContactsCountByStatus[SequenceStatus.INVALID]} invalid`}
            {filteredContactsCountByStatus[SequenceStatus.CATCH_ALL] > 0 &&
              `, ${filteredContactsCountByStatus[SequenceStatus.CATCH_ALL]} catch-all`}
          </Text>
          <button className="mr-6" onClick={() => setPageIndex((index) => index - 1)} disabled={pageIndex < 1}>
            <CaretLeft width={20} className={clsx('text-dark', pageIndex < 1 && 'text-medium-light')} />
          </button>
          <button
            onClick={() => setPageIndex((index) => index + 1)}
            disabled={pageIndex >= Math.ceil(table.getFilteredRowModel().rows.length / PageMax) - 1}
          >
            <CaretRight
              width={20}
              className={clsx(
                'text-dark',
                pageIndex >= Math.ceil(table.getFilteredRowModel().rows.length / PageMax) - 1 && 'text-medium-light',
              )}
            />
          </button>
        </div>
      </nav>
    </section>
  )
}
