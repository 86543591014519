import { forwardRef, Fragment, MutableRefObject, PropsWithChildren, Ref } from 'react'
import clsx from 'clsx'
import { Dialog as HeadlessDialog, DialogPanelProps, Transition } from '@headlessui/react'

type DialogProps = {
  open?: boolean
  onClose(value: boolean): void
  initialFocus?: MutableRefObject<HTMLElement | null>
}

function DialogRoot(props: PropsWithChildren<DialogProps>) {
  const { children, ...rest } = props
  return (
    <>
      <Transition show={rest.open} as={Fragment} appear>
        <HeadlessDialog as="div" className="relative z-10" {...rest}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/20" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                {children}
              </Transition.Child>
            </div>
          </div>
        </HeadlessDialog>
      </Transition>
    </>
  )
}

function Panel(props: DialogPanelProps<'div'>, ref: Ref<HTMLDivElement>) {
  const { className, children, ...rest } = props
  return (
    <HeadlessDialog.Panel
      as="div"
      className={clsx('w-full transform overflow-hidden rounded-md bg-white p-4 shadow-rift transition-all', className)}
      ref={ref}
      {...rest}
    >
      {children}
    </HeadlessDialog.Panel>
  )
}

export const Dialog = Object.assign(DialogRoot, {
  Panel: forwardRef(Panel),
})
