export function Skeleton() {
  return (
    <div className="w-7xl mt-3">
      <div className="sr-only">Loading...</div>
      {Array(8)
        .fill('')
        .map((_, index) => (
          <div key={index} className="skeleton mt-2 h-20 w-full"></div>
        ))}
    </div>
  )
}
