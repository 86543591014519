import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { differenceInDays, isWithinInterval } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useCompanyHolidays } from '@/api/company_holidays'
import { useCompanyOffDays } from '@/api/company_off_days'
import { Campaign, CompanyHolidays, CompanyOffDays } from '@/api/core'
import { HolidayNames } from '@/pages/SettingsUpdates/Sections/NoSendDates/Holidays'
import { Text } from '@/ui'

export function NoSendDatesBanner({ campaign }: { campaign: Campaign }) {
  const { data: holidays } = useCompanyHolidays()
  const { data: offDays } = useCompanyOffDays()

  const daysWithin48hrs = useMemo(() => {
    const recentHolidays: string[] = []

    if (holidays?.data) {
      const holidaysInRange = getHolidaysInRange(holidays.data, campaign.timezone)
      recentHolidays.push(...holidaysInRange)
    }

    if (offDays?.data) {
      const offDaysInRange = getCompanyOffDaysInRange(offDays.data, campaign.timezone)
      recentHolidays.push(...offDaysInRange)
    }

    return recentHolidays
  }, [holidays, offDays])

  const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' })

  if (!daysWithin48hrs.length) return <></>

  return (
    <section className="col-span-2 px-8 py-6">
      <div className=" rounded-lg bg-light px-4 py-3 text-dark">
        <Text variant="subtitle" className="text-sm">
          Upcoming no-send dates
        </Text>
        <Text>
          This sequence won't run on {formatter.format(daysWithin48hrs)}.{' '}
          {
            <Link to="/settings/no-send-dates">
              <span className="underline">Settings</span>
            </Link>
          }
        </Text>
      </div>
    </section>
  )
}

function getCompanyOffDaysInRange(companyDays: CompanyOffDays, timezone: string) {
  const today = utcToZonedTime(Date.now(), timezone)

  return companyDays
    .filter((companyDay) => {
      const inInterval = isWithinInterval(today, {
        start: new Date(companyDay.startDate),
        end: new Date(companyDay.endDate || companyDay.startDate),
      })

      // show company off day 24 hours before
      const daysFromStart = differenceInDays(new Date(companyDay.startDate), Date.now())

      return inInterval || (daysFromStart < 2 && daysFromStart >= 0)
    })
    .map((companyDays) => companyDays.name)
}

function getHolidaysInRange(holidays: CompanyHolidays, timezone: string) {
  const holidaysInRange: string[] = []
  const today = utcToZonedTime(Date.now(), timezone)

  for (const holiday in holidays) {
    if (!holiday.includes('Date') && holidays[holiday as keyof CompanyHolidays]) {
      const selectedHolidayDate = holidays[`${holiday}Date` as keyof CompanyHolidays]

      const daysApart = differenceInDays(new Date(selectedHolidayDate as string), today)

      // Only show holiday for 48 hours
      if (daysApart < 2 && daysApart >= 0) {
        holidaysInRange.push(HolidayNames[holiday as keyof typeof HolidayNames])
      }
    }
  }

  return holidaysInRange
}
