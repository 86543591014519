import { useCallback } from 'react'
import { UseFormReset } from 'react-hook-form'
import { SettingsFlow } from '@ory/client'
import { kratos } from '@/config/kratos'
import { reportSentryErrorWithEmail } from '@/sentry'

type useCreateSettingsFlowProps = {
  setFlow: (val: SettingsFlow | null) => void
  reset: UseFormReset<{
    email: string
    firstName: string
    lastName: string
    company: string
  }>
}
export function useGetSettingsFlow({ setFlow, reset }: useCreateSettingsFlowProps) {
  return useCallback(
    (flowId: string) =>
      kratos
        .getSettingsFlow({ id: flowId })
        .then(({ data: flow }) => {
          setFlow(flow)
          const { first_name, last_name, company_name } = flow.identity.traits
          reset({ ...flow.identity.traits, firstName: first_name, lastName: last_name, company: company_name })
        })
        .catch((error) => {
          reportSentryErrorWithEmail('[KRATOS] Settings getSelfServiceSettingsFlow failed', {
            extra: {
              error,
            },
          })
          return error
        }),
    [],
  )
}
