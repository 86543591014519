import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ApiError, CompanyHolidays, DefaultService, PatchCompanyHolidays } from './core'

export const queryKey = {
  base: () => ['holidays'] as const,
}

export function useCompanyHolidays() {
  return useQuery<{ data: CompanyHolidays }, ApiError>({
    queryKey: queryKey.base(),
    queryFn: () => DefaultService.getCompanyHolidays(),
  })
}
export function useCompanyHolidaysUpdate() {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: PatchCompanyHolidays) => DefaultService.patchCompanyHolidays(body),
  })
}
