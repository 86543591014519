import { useEffect, useState } from 'react'
import { SHOW_FF } from '@/config'
import { UserType, useUserContext } from '@/providers/User/context'

type FlagType = {
  showFeatureFlag: boolean
  showFeatureFlagRaw: boolean
}

const hideFeatureFlagKey = 'hide_feature_flag_components'

function getHideFeatureFlag() {
  return localStorage.getItem(hideFeatureFlagKey) === 'true'
}

function saveHideFeatureFlag(hide: boolean) {
  localStorage.setItem(hideFeatureFlagKey, hide.toString())
}

export function useHideFeatureFlag(): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
  const [hideFeatureFlag, setHideFeatureFlag] = useState<boolean>(getHideFeatureFlag)
  useEffect(() => {
    saveHideFeatureFlag(hideFeatureFlag)
  }, [hideFeatureFlag])

  return [hideFeatureFlag, setHideFeatureFlag]
}

export function useFlags(): FlagType {
  const alwaysShowFF = SHOW_FF

  const { user } = useUserContext()
  if (user.type !== UserType.Authenticated) {
    return {
      showFeatureFlag: false,
      showFeatureFlagRaw: false,
    }
  }

  const ff =
    alwaysShowFF ||
    ((user?.value?.traits?.email?.endsWith('@getrift.com') || user?.value?.traits?.email?.endsWith('@rift.com')) &&
      (user?.value?.metadata_public?.show_feature_flag as boolean))

  return {
    showFeatureFlag: !getHideFeatureFlag() && ff,
    showFeatureFlagRaw: ff,
  }
}
