import clsx from 'clsx'
import { FieldDefinitions, SingularMappings } from '@/api/core'
import { mappingSetterToSingularMappings, mappingsFromSingularMappings, SingularMappingForm } from './SingularMapping'

type SalesforceStageMappingFormProps = {
  disabled?: boolean
  crmToRiftDirection: boolean
  riftStages: FieldDefinitions
  crmStages: FieldDefinitions
  defaultStageMappings: SingularMappings
  mapping: SingularMappings
  setMapping: (mapping: SingularMappings) => void
}

export function SalesforceStageMappingForm({
  disabled = false,
  crmToRiftDirection = false,
  riftStages,
  crmStages,
  defaultStageMappings,
  mapping,
  setMapping,
}: SalesforceStageMappingFormProps) {
  const defaults = mappingsFromSingularMappings(defaultStageMappings, crmToRiftDirection)
  const mappings = mappingsFromSingularMappings(mapping, crmToRiftDirection)
  const leftFields = crmToRiftDirection ? crmStages : riftStages
  const rightFields = crmToRiftDirection ? riftStages : crmStages
  const onSetMapping = mappingSetterToSingularMappings(setMapping, crmToRiftDirection)
  return (
    <div className={clsx(disabled && 'pointer-events-none opacity-30')}>
      <SingularMappingForm
        title={
          crmToRiftDirection
            ? 'Map contact stages from Salesforce to rift'
            : 'Map contact stages from rift to Salesforce'
        }
        subtitle="Select how you’d like to sync data between rift and Salesforce"
        leftColumnTitle={crmToRiftDirection ? 'Salesforce' : 'Rift'}
        rightColumnTitle={crmToRiftDirection ? 'Rift' : 'Salesforce'}
        leftFields={leftFields}
        rightFields={rightFields}
        defaultFieldMapping={defaults}
        mapping={mappings}
        multipleRightOptions
        setMapping={onSetMapping}
      />
    </div>
  )
}
