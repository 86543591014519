import { useEffect, useReducer } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { DateTime } from 'luxon'
import { ArrowLeft } from '@phosphor-icons/react'
import { ApiError, MeetingSlot, MeetingSlots, PostMeeting } from '@/api/core'
import { useAvailability, useMeetingCreate, useMeetingUpdate } from '@/api/meetings'
import riftLogoSimple from '@/assets/rift-logo-simple.svg'
import { useIsMobile } from '@/hooks'
import { reportSentryError } from '@/sentry'
import { Button } from '@/ui/Button/v2'
import { Input } from '@/ui/Input/v2'
import { Calendar } from './Calendar'
import { NotFound } from './NotFound'
import { TimeSelect } from './Timeselect'
import './index.css'

dayjs.extend(customParseFormat)

type Host = {
  name: string
  memberId: string
}

type State = {
  host: Host
  selectedDate?: string
  selectedTime?: MeetingSlot
  currentStage: number
  error?: string
}

function formatCalendarEvents(events: MeetingSlots | undefined) {
  const groupedDates = new Map<string, MeetingSlots>()
  if (!events) {
    return groupedDates
  }

  // filter out weekends and past events
  const filteredEvents = events.filter((event) => {
    const eventDate = new Date(event.start_time)
    return eventDate.getDay() !== 0 && eventDate.getDay() !== 6 && eventDate.getTime() > Date.now()
  })

  // Loop through the dates and times and group them by date.
  for (const event of filteredEvents) {
    const startTime = new Date(event.start_time)

    const date = startTime.toDateString()

    if (!groupedDates.has(date)) {
      groupedDates.set(date, [])
    }

    groupedDates.get(date)?.push(event)
  }

  // Sort each group of dates by time.
  for (const date in groupedDates) {
    groupedDates.get(date)?.sort((a, b) => {
      const date1 = new Date(a.start_time).getTime()
      const date2 = new Date(b.start_time).getTime()

      return date1 - date2
    })
  }

  return groupedDates
}

type Action =
  | { type: 'goToStage'; stage: number }
  | { type: 'updateDate'; date: string }
  | { type: 'updateHost'; host: Host }
  | { type: 'updateTime'; time?: MeetingSlot }
  | { type: 'error'; errorMsg: string }
function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'goToStage': {
      return { ...state, currentStage: action.stage }
    }
    case 'updateDate': {
      return { ...state, selectedDate: action.date }
    }
    case 'updateTime': {
      return { ...state, selectedTime: action.time }
    }
    case 'error': {
      return { ...state, error: action.errorMsg }
    }
    case 'updateHost': {
      if (state.currentStage == 1) {
        return { ...state, host: action.host }
      } else return state
    }
    default: {
      const _never: never = action
      return _never
    }
  }
}

const PersonalDetailsSection: React.FC<{
  hostMemberId: string
  slot?: MeetingSlot
  submit: (fields: PostMeeting) => void
}> = ({ submit, slot, hostMemberId: hostMemberId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PostMeeting>({
    defaultValues: {
      start_time: slot?.start_time,
      end_time: slot?.end_time,
      first_name: '',
      last_name: '',
      email: '',
      company: '',
      hostMemberId,
    },
  })
  return (
    <form className="form pt-9" onSubmit={handleSubmit(submit)}>
      <p className="pb-3 text-md">Enter your details</p>
      <Input
        key={'firstName'}
        label="First Name"
        className="my-3"
        registration={register('first_name', { required: 'Required' })}
        error={errors.first_name?.message?.toString()}
      />
      <Input
        key={'lastName'}
        label="Last Name"
        className="my-3"
        registration={register('last_name', { required: 'Required' })}
        error={errors.last_name?.message?.toString()}
      />
      <Input
        key={'company'}
        label="Company"
        className="my-3"
        registration={register('company', { required: 'Required' })}
        error={errors.company?.message?.toString()}
      />
      <Input
        key={'email'}
        label="Email"
        className="my-3"
        registration={register('email', {
          required: 'Required',
          pattern: { value: /^\S+@\S+\.\S+$/, message: 'Enter valid email' },
          setValueAs: (value) => value?.trim(),
        })}
        error={errors.email?.message?.toString()}
      />
      <Button variant="accent" className="my-3">
        Schedule meeting
      </Button>
    </form>
  )
}

export function MeetingsPortal() {
  const [state, dispatch] = useReducer(reducer, {
    currentStage: 1,
    host: { name: '', memberId: '' },
  })
  const { company = '', meeting_type = '' } = useParams<'company' | 'meeting_type'>()
  const [searchParams] = useSearchParams()
  const rescheduleMeetingId = searchParams.get('rescheduleMeetingId')
  const isRescheduling = rescheduleMeetingId
  const { data: availability, error } = useAvailability(company, meeting_type)
  const meetingTypeNotFound = (error as ApiError)?.status === 404
  const meetingCreate = useMeetingCreate(company, meeting_type)
  const meetingUpdate = useMeetingUpdate(rescheduleMeetingId ?? '')
  const isMobile = useIsMobile()
  const slots = formatCalendarEvents(availability?.data?.available_slots)
  useEffect(() => {
    if (availability?.data?.available_slots && !isMobile) {
      const date = dayjs(availability?.data?.available_slots[0]?.start_time).toDate().toDateString()
      dispatch({ type: 'updateDate', date: date })
    }
    if (availability?.data?.member) {
      dispatch({
        type: 'updateHost',
        host: {
          name: `${availability?.data?.member.firstName} ${availability?.data?.member.lastName}`,
          memberId: availability?.data?.member.id,
        },
      })
    }
  }, [availability])

  if (meetingTypeNotFound) {
    return <NotFound />
  }

  const buttonClasses = `next-button ${!(state.selectedDate && state.selectedTime) ? 'invisible' : ''}`

  function handleDateSelection(date: string) {
    dispatch({ type: 'updateDate', date })
    // reset time
    dispatch({ type: 'updateTime', time: undefined })
    if (isMobile) {
      dispatch({ type: 'goToStage', stage: 2 })
    }
  }

  function handleTimeSelection(slot: MeetingSlot) {
    dispatch({ type: 'updateTime', time: slot })
    if (isMobile) {
      if (isRescheduling) {
        rescheduleSubmit()
      } else {
        dispatch({ type: 'goToStage', stage: 3 })
      }
    }
  }

  function rescheduleSubmit() {
    dispatch({ type: 'goToStage', stage: 4 })
    meetingUpdate.mutate(
      { start_time: state.selectedTime?.start_time ?? '', end_time: state.selectedTime?.end_time ?? '' },
      {
        onError: (error: Error) => {
          const bookingError = new Error(
            `Error rescheduling meeting for ${state.selectedTime?.start_time} to ${state.selectedTime?.end_time}`,
          )
          reportSentryError(bookingError, { extra: { selectedTime: state.selectedTime, srcError: error } })
        },
      },
    )
  }

  const DateSelection = () => {
    let actionButton: React.ReactNode
    if (isMobile) {
      actionButton = ''
    } else if (isRescheduling) {
      actionButton = (
        <Button variant="accent" className={buttonClasses} disabled={!state.selectedTime} onClick={rescheduleSubmit}>
          Reschedule
        </Button>
      )
    } else {
      actionButton = (
        <Button variant="accent" className={buttonClasses} onClick={handleNextClick}>
          Next: Your details
        </Button>
      )
    }
    return (
      <>
        <section className="date-selection stage-1 pt-9">
          <p className="date-title text-md">
            Select a date {!isMobile && `and time (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
          </p>
          <Calendar
            className="date-calendar"
            timeSlots={slots}
            handleDateSelection={handleDateSelection}
            selectedDate={dayjs(state.selectedDate)}
          />
          {!isMobile && (
            <TimeSelect
              className="date-time-select snap-py-0 mt-5 max-h-72 space-y-1 overflow-auto"
              slots={slots.get(state.selectedDate ?? '') ?? []}
              handleTimeSelection={handleTimeSelection}
              selected={state.selectedTime}
            />
          )}
        </section>
        {actionButton}
      </>
    )
  }

  const TimeSelectSection = () => {
    return (
      <section className="date-selection stage-2 pt-9">
        <p className="date-title text-md">Select a time (US Pacific)</p>
        <TimeSelect
          className="flex flex-col gap-4 pt-3 text-[20px]"
          slots={slots.get(state.selectedDate ?? '') ?? []}
          handleTimeSelection={handleTimeSelection}
          selected={state.selectedTime}
          slotClassName="text-md p-5"
        />
      </section>
    )
  }

  function handleNextClick() {
    if (state.currentStage === 1) {
      dispatch({ type: 'goToStage', stage: isMobile ? 2 : 3 })
    }
  }

  const currentSection = () => {
    switch (state.currentStage) {
      case 1: {
        return <DateSelection />
      }
      case 2: {
        return <TimeSelectSection />
      }
      case 3: {
        return (
          <PersonalDetailsSection
            slot={state.selectedTime}
            hostMemberId={state.host.memberId}
            submit={(form) => {
              dispatch({ type: 'goToStage', stage: 4 })
              meetingCreate.mutate(
                { ...form },
                {
                  onError: (error) => {
                    const bookingError = new Error(
                      `Error booking meeting for ${form.email} (${form.first_name} ${form.last_name} at ${form.company}) for ${form.start_time} to ${form.end_time}`,
                    )
                    reportSentryError(bookingError, { extra: { form, srcError: error } })
                  },
                },
              )
            }}
          />
        )
      }
      case 4: {
        return (
          <p className={'content-md'}>
            {isRescheduling
              ? 'Rescheduled! A calendar invitation has been sent to your email address.'
              : 'Confirmed! A calendar invitation has been sent to your email address.'}
          </p>
        )
      }
    }
  }

  function handleBackClick() {
    if (state.currentStage === 3) {
      dispatch({ type: 'goToStage', stage: isMobile ? 2 : 1 })
    } else if (state.currentStage === 2) {
      dispatch({ type: 'goToStage', stage: 1 })
    }
  }

  let meetingDuration = 30
  if (availability?.data?.duration) {
    // get meeting duration by finding slot interval in minutes
    meetingDuration = availability?.data?.duration
  }

  return (
    <div className="portal-wrapper h-screen w-screen">
      <div className="icon-heading">
        {state.currentStage > 1 && state.currentStage < 3 ? (
          <ArrowLeft className="hw-auto mx-[10px] h-[22px] w-[22px] rounded" onClick={handleBackClick} />
        ) : (
          <img className="hw-auto mx-[10px] h-[22px] w-[22px] rounded" src={riftLogoSimple} alt="rift" />
        )}
      </div>
      <section className="portal-section grid place-content-center">
        <hgroup className="heading">
          <h1 className="py-1 text-lg font-medium">Call with {state.host.name}</h1>
          <p className="text-base">
            {meetingDuration} min •&nbsp;
            {state.selectedDate && DateTime.fromJSDate(new Date(state.selectedDate ?? '')).toFormat('ccc, LLLL d')}
            &nbsp;
            {state.selectedTime &&
              DateTime.fromJSDate(new Date(state?.selectedTime?.start_time ?? '')).toFormat('h:mm a')}
          </p>
        </hgroup>
        {currentSection()}
      </section>
    </div>
  )
}
