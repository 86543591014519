import Handlebars from 'handlebars'
import * as yup from 'yup'
import { parse } from '@handlebars/parser'

class ValidationError extends Error {
  content: string
  line: number
  firstColumn: number
  lastColumn: number

  constructor(message: string, content: string, line: number, firstColumn: number, lastColumn: number) {
    super(message)
    Object.setPrototypeOf(this, ValidationError.prototype)

    this.content = content
    this.line = line
    this.firstColumn = firstColumn
    this.lastColumn = lastColumn
  }
}

export function validate(template: string): Error | null {
  try {
    if (/http:\/\//.test(template)) {
      return new Error('Use https instead of http in links')
    }

    const program = parse(template)

    for (const node of program.body) {
      if (node.type === 'MustacheStatement' && (node as any)?.path.type === 'PathExpression') {
        const varname = (node as any).path.original
        if (/^[a-z]/.test(varname)) {
          const { end, start } = (node as any).path.loc

          return new ValidationError(
            `Parse error on line ${start.line}, column ${start.column} - variable {{${varname}}} must start with uppercase letter`,
            varname,
            start.line,
            start.column,
            end.column,
          )
        }
      }
    }

    Handlebars.compile(template)({})
  } catch (err: any) {
    if ((err?.message as string).includes('Missing helper')) {
      return Error("Error: Variables can't have spaces")
    } else if (err?.hash?.loc?.first_column && err?.hash?.loc?.last_column) {
      const { first_line: line, first_column: firstColumn, last_column: lastColumn } = err.hash.loc

      const content = template.slice(firstColumn, lastColumn)
      return new ValidationError(
        `Parse error on line ${line}, column ${firstColumn} near ${content}`,
        content,
        line,
        firstColumn,
        lastColumn,
      )
    }
    return err
  }
  return null
}

export function yupValidate(value: string, context: yup.TestContext) {
  const { path, createError } = context
  const err = validate(value || '')
  return err ? createError({ path, message: err.message }) : true
}
