import { useState } from 'react'
import * as yup from 'yup'
import { Dialog } from '@headlessui/react'
import { WorkspaceMemberRoleLabels } from '@/api/text/workspace'
import { useWorkspaceMembersCreate } from '@/api/workspace'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Input, Select, Text } from '@/ui'

export function AddMemberModal({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (val: boolean) => void }) {
  const emailSchema = yup.array().of(yup.string().email())

  const [role, setRole] = useState(WorkspaceMemberRoleLabels[0])
  const [emails, setEmails] = useState('')
  const [emailsError, setEmailsError] = useState('')
  const workspaceUserCreate = useWorkspaceMembersCreate()
  const toast = useToast()

  function submit() {
    if (emails === '') {
      setEmailsError('Please enter an email')
    } else if (emailsError === '') {
      workspaceUserCreate.mutate(
        { role: role.value, emails: emails.split(',').map((email) => email.trim()) },
        {
          onSuccess: () => {
            toast.createToast({ message: 'Users invited to workspace' })

            setIsOpen(false)
          },
          //   TODO: In future have react hook form check for errors instead
          onError: () =>
            toast.createToast({
              message: (workspaceUserCreate.error as any)?.body?.message || 'Failed to invite users to workspace',
              error: true,
            }),
        },
      )
    }
  }

  function validateEmails() {
    if (!emailSchema.isValidSync(emails.split(',').map((email) => email.trim()))) {
      setEmailsError('An email you entered is invalid')
    } else {
      setEmailsError('')
    }
  }

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
      <div className="fixed inset-0 bg-white/70" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="mx-auto w-fit rounded-lg border border-light bg-white p-6 shadow-sm">
          <Dialog.Title as="div">
            <Text variant="subtitle">Add members</Text>
          </Dialog.Title>

          <div className="flex-col space-y-5 pt-2">
            <div className="flex items-center gap-3">
              <Input
                placeholder="Emails, comma separated"
                className="w-96"
                onChange={(v) => {
                  setEmails(v.target.value)
                }}
                {...(emailsError !== '' && { error: emailsError })}
                onBlur={validateEmails}
              />

              <Select
                options={WorkspaceMemberRoleLabels}
                defaultValue={role}
                value={role}
                onChange={(v) => {
                  setRole(v)
                }}
              />
            </div>

            <div className="flex justify-end gap-3">
              <Button variant="text" onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button variant="accent" onClick={submit}>
                Invite
              </Button>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
