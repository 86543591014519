import { useState } from 'react'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSequenceGenerateEnrichAccount } from '@/api/sequence_generation'
import { ButtonLoading } from '@/ui/Button/v2'
import { Input } from '@/ui/Input/v2'
import { ApproachForm } from './ApproachForm'

export function AccountSequences() {
  return (
    <section className="m-auto max-w-2xl pb-5">
      <BlueprintEditor />
    </section>
  )
}

function BlueprintEditor() {
  const [domainName, setDomainName] = useState('')
  const [jobTitles, setJobTitles] = useState('')

  const schema = yup.object().shape({
    accountDomainName: yup.string().required('domain name required'),
    jobTitles: yup.string().required('Job titles required'),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ accountDomainName: string; jobTitles: string }>({
    resolver: yupResolver(schema),
    defaultValues: {
      accountDomainName: 'example.com',
      jobTitles: '',
    },
  })

  const { data, isLoading, isSuccess, isError } = useSequenceGenerateEnrichAccount(domainName, jobTitles)

  function onEnrich({ accountDomainName, jobTitles }: { accountDomainName: string; jobTitles: string }) {
    setDomainName(accountDomainName)
    setJobTitles(jobTitles)
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onEnrich)}>
        <div className="flex flex-col items-center justify-end">
          <div className="w-full">
            <Input
              name="accountDomainName"
              id="accountDomainName"
              type="text"
              label="Domain name"
              placeholder="example.com"
              error={errors['accountDomainName']}
              registration={register('accountDomainName')}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="w-full">
          <Input
            name="jobTitles"
            id="jobTitles"
            type="text"
            label="Job titles"
            placeholder="Job titles"
            error={errors['jobTitles']}
            registration={register('jobTitles')}
          />
        </div>
        <div className="box-border flex w-full justify-end gap-2 bg-white py-4">
          <ButtonLoading type="submit" variant="accent" isLoading={isLoading}>
            Enrich Account Data
          </ButtonLoading>
        </div>
      </form>
      <div>
        {data && isSuccess && (
          <dl>
            <dt className="font-semibold">Account Name:</dt>
            <dd>{data?.data.account.name}</dd>
            <dt className="font-semibold">Account Description:</dt>
            <dd>{data?.data.account.description}</dd>
            <br />
            {data?.data.contacts.map((contact) => (
              <React.Fragment key={contact.email}>
                <dt className="font-semibold">Contact Name:</dt>
                <dd>
                  {contact.firstName} {contact.lastName}
                </dd>
                <dt className="font-semibold">Email:</dt>
                <dd>{contact.email}</dd>
                <dt className="font-semibold">Job Title:</dt>
                <dd>{contact.title}</dd>
                <dt className="font-semibold">Photo URL:</dt>
                <dd>{contact.photoUrl}</dd>
                <br />
              </React.Fragment>
            ))}
          </dl>
        )}
        {isError && <div>Error retrieving account enrichment data</div>}
      </div>

      <div>{data && data.data.contacts.length > 0 && <ApproachForm enrichmentData={data.data} />}</div>
    </div>
  )
}
