import { Weekday } from '@/api/core'

export const WeekdayToText: Record<Weekday, string> = {
  [Weekday.MONDAY]: 'Monday',
  [Weekday.TUESDAY]: 'Tuesday',
  [Weekday.WEDNESDAY]: 'Wednesday',
  [Weekday.THURSDAY]: 'Thursday',
  [Weekday.FRIDAY]: 'Friday',
  [Weekday.SATURDAY]: 'Saturday',
  [Weekday.SUNDAY]: 'Sunday',
}

export const WeekdayToShortText: Record<Weekday, string> = {
  [Weekday.MONDAY]: 'Mon',
  [Weekday.TUESDAY]: 'Tue',
  [Weekday.WEDNESDAY]: 'Wed',
  [Weekday.THURSDAY]: 'Thu',
  [Weekday.FRIDAY]: 'Fri',
  [Weekday.SATURDAY]: 'Sat',
  [Weekday.SUNDAY]: 'Sun',
}

export const WeekdayToNumber: Record<Weekday, number> = {
  [Weekday.SUNDAY]: 0,
  [Weekday.MONDAY]: 1,
  [Weekday.TUESDAY]: 2,
  [Weekday.WEDNESDAY]: 3,
  [Weekday.THURSDAY]: 4,
  [Weekday.FRIDAY]: 5,
  [Weekday.SATURDAY]: 6,
}

export const TextToWeekday: Record<string, Weekday> = Object.fromEntries(
  Object.entries(WeekdayToText).map(([key, value]) => [value, key as Weekday]),
)

export const ShortTextToWeekday: Record<string, Weekday> = Object.fromEntries(
  Object.entries(WeekdayToShortText).map(([key, value]) => [value, key as Weekday]),
)

export const NumberToWeekday: Record<number, Weekday> = Object.fromEntries(
  Object.entries(WeekdayToNumber).map(([key, value]) => [value, key as Weekday]),
)

export const WeekdayLabels: { label: string; value: Weekday }[] = Object.entries(WeekdayToText).map(
  ([value, label]) => ({ label, value: value as Weekday }),
)
