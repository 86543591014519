import { forwardRef, Ref, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react'
import clsx from 'clsx'

export type ContextMenuProps = {
  items: Array<string>
  command: (v: { id: string }) => void
}

function ContextMenuFn(props: ContextMenuProps, ref: Ref<any>) {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const buttonRef = useRef<HTMLButtonElement>(null)

  useLayoutEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.click()
    }
  }, [buttonRef.current])

  const selectItem = (index: number) => {
    const item = props.items[index]
    if (item) {
      props.command({ id: item })
    }
  }

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: React.KeyboardEvent }) => {
      if (event.key === 'ArrowUp') {
        setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
        return true
      }

      if (event.key === 'ArrowDown') {
        setSelectedIndex((selectedIndex + 1) % props.items.length)
        return true
      }

      if (event.key === 'Enter') {
        selectItem(selectedIndex)
        return true
      }

      return false
    },
  }))

  return (
    <div className="relative">
      <div className="left absolute z-10 origin-top-right rounded-md bg-white p-1 shadow-rift">
        {props.items.map((item, index) => (
          <button
            type="button"
            key={item}
            className={clsx(
              'flex w-full cursor-default rounded-md px-4 py-2 text-left hover:bg-accent-light',
              index === selectedIndex && 'bg-accent-light',
            )}
            onClick={() => selectItem(index)}
          >
            {item.replace(/\}\}$/g, '')}
          </button>
        ))}
      </div>
    </div>
  )
}

export const ContextMenu = forwardRef(ContextMenuFn)
