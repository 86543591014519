import { PostContact } from '@/api/core'
import { contact, ContactObject, FieldFixes, header, State } from './reducer'

function stateContactToContact(c: contact, headers: header[], fieldCleanings: FieldFixes): PostContact {
  const contact: ContactObject = {
    Email: '',
    FirstName: '',
    LastName: '',
    Company: '',
    Phone: '',
    Title: '',
    Country: '',
    State: '',
    City: '',
    Linkedin: '',
    Timezone: null,
    Body: '',
    OwnerEmail: '',
    CustomFields: {},
  }

  headers.forEach((header) => {
    switch (header.type) {
      case 'standard':
        const subs = fieldCleanings[header.to as keyof ContactObject]
        let fromValue = c.data[header.from]
        const subsitution = subs?.find((s) => s.from === c.data[header.from] && s.accepted)
        if (subsitution) {
          fromValue = subsitution.to
        }
        contact[header.to as keyof Omit<ContactObject, 'CustomFields'>] = fromValue
        break
      case 'custom':
        contact.CustomFields[header.to as string] = c.data[header.from].trim()
        break
      default: // skip
        break
    }
  })
  return {
    email: contact.Email,
    first_name: contact.FirstName,
    last_name: contact.LastName,
    company: contact.Company,
    phone: contact.Phone,
    title: contact.Title,
    body: contact.Body,
    country: contact.Country,
    state: contact.State,
    city: contact.City,
    linkedin: contact.Linkedin,
    timezone: contact.Timezone,
    owner_email: contact.OwnerEmail,
    custom_fields: contact.CustomFields,
  }
}

export function contactsToUpload(state: State): PostContact[] {
  return state.contacts.map((c) => stateContactToContact(c, state.headers, state.fieldCleanings))
}
