import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

type DrawerProps = {
  open: boolean
  onClose: () => void
  position?: 'left' | 'right'
}

export function Drawer(props: React.PropsWithChildren<DrawerProps>) {
  const { open, onClose, position = 'right', children } = props
  const posClass = position === 'right' ? 'right-0' : 'left-0'

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className={`${posClass} fixed h-full overflow-y-auto`}>
            <div className="h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="h-full min-h-full w-[min(50vw,480px)] transform overflow-auto bg-white p-4 transition-all">
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
