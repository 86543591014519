import React from 'react'
import { createPortal } from 'react-dom'
import { Transition } from '@headlessui/react'
import { X } from '@phosphor-icons/react'
import { Button } from '@/ui/Button/v2'

type FloatingAppBarProps = {
  open: boolean
  posXRef?: React.RefObject<HTMLDivElement>
}

function FloatingAppBarFn(props: React.PropsWithChildren<FloatingAppBarProps>) {
  const { open, children } = props

  return createPortal(
    <div className="fixed bottom-8 ml-[50%] -translate-x-2/4">
      <Transition
        appear
        show={open}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-80"
        leave="transition ease-in duration-100"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-50"
      >
        <div className="flex h-16 items-center gap-2 rounded-lg border border-light bg-white px-6 py-4 shadow-rift">
          {children}
        </div>
      </Transition>
    </div>,
    document.body,
  )
}

function Action(props: React.PropsWithChildren<React.ComponentPropsWithoutRef<'button'>>) {
  const { children, ...rest } = props
  return (
    <Button variant="basic" {...rest}>
      {children}
    </Button>
  )
}

function Close(props: React.ComponentPropsWithoutRef<'button'> & { onClose: () => void }) {
  const { onClose, ...rest } = props

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    rest && rest.onClick?.(e)
    if (e.defaultPrevented) return
    onClose()
  }

  return (
    <button {...rest} onClick={onClick}>
      <X className="size-6 rounded-full p-0.5 text-dusk hover:bg-gray-100" />
    </button>
  )
}

export const FloatingAppBar = Object.assign(FloatingAppBarFn, { Action, Close })
