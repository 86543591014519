import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Disclosure } from '@headlessui/react'
import { CaretDown, CaretRight, DotsThree } from '@phosphor-icons/react'
import { useCompany } from '@/api'
import { useCompanyHolidays, useCompanyHolidaysUpdate } from '@/api/company_holidays'
import { useCompanyOffDayDelete, useCompanyOffDayEnable, useCompanyOffDays } from '@/api/company_off_days'
import { Company, CompanyHolidays, CompanyOffDay, CompanyOffDays, PatchCompanyHolidays } from '@/api/core'
import { useDisclosureV2 } from '@/hooks/useDisclosure'
import { dateFormat, parseDate } from '@/lib/date'
import { shallowEqual } from '@/lib/utils'
import { ConfirmationDialog } from '@/pages/Contacts/ConfirmationDialog'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Checkbox, DropdownMenu, Text } from '@/ui'
import { HolidayNames } from './Holidays'
import { OffDayForm } from './OffDayForm'

function NoSendDatesPage({ company }: { company: Company }) {
  const canEdit = company.permissions.edit.deny === false
  const { data: holidays, status: holidaysStatus } = useCompanyHolidays()
  const { data: offDays, status: offDaysStatus } = useCompanyOffDays()
  const [offDateFormOpen, onOffDateFormOpen, onOffDateFormClose] = useDisclosureV2(false)
  return (
    <section className="flex flex-col gap-6">
      <div>
        <Text variant="title" className="pb-2">
          No-send dates
        </Text>
        <Text>
          Dates on which sequences won’t send. We recommend not sending on holidays because they see lower engagement
          and poor performance.
        </Text>
      </div>
      {holidaysStatus === 'success' && <HolidaysForm holidays={holidays.data} canEdit={canEdit} />}
      {offDaysStatus === 'success' && <OffDaysList offDays={offDays.data} canEdit={canEdit} />}

      {canEdit && (
        <div>
          {offDateFormOpen ? (
            <OffDayForm edit={false} onClose={onOffDateFormClose} />
          ) : (
            <Button variant="basic" onClick={onOffDateFormOpen}>
              Add a date
            </Button>
          )}
        </div>
      )}
    </section>
  )
}

type HolidaysFormProps = {
  holidays: CompanyHolidays
  canEdit: boolean
}

function HolidaysForm(props: HolidaysFormProps) {
  const { holidays, canEdit } = props
  const holidaysUpdate = useCompanyHolidaysUpdate()
  const toast = useToast()
  const { register, watch, setValue } = useForm<PatchCompanyHolidays>({
    defaultValues: {
      newYear: holidays.newYear,
      mlkDay: holidays.mlkDay,
      memorialDay: holidays.memorialDay,
      juneteenth: holidays.juneteenth,
      independenceDay: holidays.independenceDay,
      laborDay: holidays.laborDay,
      veteransDay: holidays.veteransDay,
      thanksgivingDay: holidays.thanksgivingDay,
      christmasDay: holidays.christmasDay,
    },
  })

  const holidaysValues = watch()
  const onUSHolidaysChange = (checked: boolean) => {
    Object.keys(holidaysValues).forEach((key) => {
      setValue(key as keyof PatchCompanyHolidays, checked)
    })
  }

  const [prevHolidaysValues, setPrevHolidaysValues] = useState(holidaysValues)
  useEffect(() => {
    if (shallowEqual(prevHolidaysValues, holidaysValues)) return
    setPrevHolidaysValues(holidaysValues)
    holidaysUpdate.mutate(holidaysValues, {
      onError: (err) =>
        toast.createToast({ message: (err as any)?.body?.message || 'Failed to update holidays', error: true }),
    })
  }, [holidaysValues])

  const [holidaysAllChecked] = Object.entries(holidaysValues)
    .filter(([, checked]) => typeof checked === 'boolean')
    .reduce(
      ([allChecked, indeterminate], [, checked]) => {
        return [allChecked && checked, indeterminate || checked]
      },
      [true, false],
    )

  return (
    <form>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button>
              <div className="flex items-center">
                <span className="mr-4.5">
                  {open ? <CaretDown className="h-4.5 w-4.5" /> : <CaretRight className="h-4.5 w-4.5" />}
                </span>

                <Checkbox
                  checked={holidaysAllChecked}
                  disabled={!canEdit}
                  onChange={(e) => {
                    e.stopPropagation()
                    onUSHolidaysChange((e.target as any).checked)
                  }}
                  onClick={(e) => e.stopPropagation()}
                  className="mr-4"
                />
                <Text className="font-medium">US Holidays</Text>
              </div>
            </Disclosure.Button>
            <Disclosure.Panel>
              <div className="ml-18 flex flex-col">
                <div className="flex py-1">
                  <Checkbox id="newYear" className="mr-4" {...register('newYear')} disabled={!canEdit} />
                  <label htmlFor="newYear" className="text-sm">
                    {HolidayNames.newYear}
                  </label>
                  <span className="ml-2 text-sm text-medium">{dateFormat(parseDate(holidays.newYearDate))}</span>
                </div>
                <div className="flex py-1">
                  <Checkbox id="mlkDay" className="mr-4" {...register('mlkDay')} disabled={!canEdit} />
                  <label htmlFor="mlkDay" className="text-sm">
                    {HolidayNames.mlkDay}
                  </label>
                  <span className="ml-2 text-sm text-medium">{dateFormat(parseDate(holidays.mlkDayDate))}</span>
                </div>
                <div className="flex py-1">
                  <Checkbox id="memorialDay" className="mr-4" {...register('memorialDay')} disabled={!canEdit} />
                  <label htmlFor="memorialDay" className="text-sm">
                    {HolidayNames.mlkDay}
                  </label>
                  <span className="ml-2 text-sm text-medium">{dateFormat(parseDate(holidays.memorialDayDate))}</span>
                </div>
                <div className="flex py-1">
                  <Checkbox id="juneteenth" className="mr-4" {...register('juneteenth')} disabled={!canEdit} />
                  <label htmlFor="juneteenth" className="text-sm">
                    {HolidayNames.juneteenth}
                  </label>
                  <span className="ml-2 text-sm text-medium">{dateFormat(parseDate(holidays.juneteenthDate))}</span>
                </div>
                <div className="flex py-1">
                  <Checkbox
                    id="independenceDay"
                    className="mr-4"
                    {...register('independenceDay')}
                    disabled={!canEdit}
                  />
                  <label htmlFor="independenceDay" className="text-sm">
                    {HolidayNames.independenceDay}
                  </label>
                  <span className="ml-2 text-sm text-medium">
                    {dateFormat(parseDate(holidays.independenceDayDate))}
                  </span>
                </div>
                <div className="flex py-1">
                  <Checkbox id="laborDay" className="mr-4" {...register('laborDay')} disabled={!canEdit} />
                  <label htmlFor="laborDay" className="text-sm">
                    {HolidayNames.laborDay}
                  </label>
                  <span className="ml-2 text-sm text-medium">{dateFormat(parseDate(holidays.laborDayDate))}</span>
                </div>
                <div className="flex py-1">
                  <Checkbox id="veteransDay" className="mr-4" {...register('veteransDay')} disabled={!canEdit} />
                  <label htmlFor="veteransDay" className="text-sm">
                    {HolidayNames.veteransDay}
                  </label>
                  <span className="ml-2 text-sm text-medium">{dateFormat(parseDate(holidays.veteransDayDate))}</span>
                </div>
                <div className="flex py-1">
                  <Checkbox
                    className="mr-4"
                    id="thanksgivingDay"
                    {...register('thanksgivingDay')}
                    disabled={!canEdit}
                  />
                  <label htmlFor="thanksgivingDay" className="text-sm">
                    {HolidayNames.thanksgivingDay}
                  </label>
                  <span className="ml-2 text-sm text-medium">
                    {dateFormat(parseDate(holidays.thanksgivingDayDate))}
                  </span>
                </div>
                <div className="flex py-1">
                  <Checkbox className="mr-4" id="christmasDay" {...register('christmasDay')} disabled={!canEdit} />
                  <label htmlFor="christmasDay" className="text-sm">
                    {HolidayNames.christmasDay}
                  </label>
                  <span className="ml-2 text-sm text-medium">{dateFormat(parseDate(holidays.christmasDayDate))}</span>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </form>
  )
}

type OffDaysListProps = {
  offDays: CompanyOffDays
  canEdit: boolean
}

function OffDaysList(props: OffDaysListProps) {
  const { offDays, canEdit } = props
  const [editOffDayId, setEditOffDayId] = useState<string | null>(null)
  const [deleteOffDay, setDeleteOffDay] = useState<CompanyOffDay | null>(null)
  const [confirmationDialogOpen, onConfirmationDialogOpen, onConfirmationDialogClose] = useDisclosureV2(false)
  const deleteOffDayMut = useCompanyOffDayDelete()
  const enableOffDayMut = useCompanyOffDayEnable()
  const toast = useToast()

  const onEnable = (id: string, enabled: boolean) => {
    enableOffDayMut.mutate(
      { id, enabled },
      {
        onError: (err) =>
          toast.createToast({ message: (err as any)?.body?.message || 'Failed to enable off day', error: true }),
      },
    )
  }

  const onDelete = () => {
    if (!deleteOffDay) return
    deleteOffDayMut.mutate(deleteOffDay.id, {
      onSuccess: () => setDeleteOffDay(null),
      onError: (err) =>
        toast.createToast({ message: (err as any)?.body?.message || 'Failed to delete off day', error: true }),
      onSettled: () => onConfirmationDialogClose(),
    })
  }

  return (
    <div>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={onConfirmationDialogClose}
        onConfirm={onDelete}
        message={`You are about to delete ${deleteOffDay?.name}?`}
      />
      <div className="w-full text-sm">
        {offDays.map((offDay) => (
          <div key={offDay.id}>
            {editOffDayId === offDay.id ? (
              <OffDayForm edit offDay={offDay} onClose={() => setEditOffDayId(null)} />
            ) : (
              <div className="mb-1 flex w-full items-center justify-between">
                <div className="flex items-center">
                  <Checkbox
                    className="ml-9 mr-4"
                    checked={offDay.enabled}
                    onChange={(e) => {
                      e.stopPropagation()
                      onEnable(offDay.id, e.target.checked)
                    }}
                    disabled={!canEdit}
                  />
                  <span className="font-medium">{offDay.name}</span>
                  <span className="ml-4 text-medium">
                    {dateFormat(parseDate(offDay.startDate))}
                    {offDay.endDate && <> — {dateFormat(parseDate(offDay.endDate))}</>}
                  </span>
                </div>
                {canEdit && (
                  <DropdownMenu>
                    <DropdownMenu.Button>
                      <DotsThree className="h-4.5 w-4.5" />
                    </DropdownMenu.Button>
                    <DropdownMenu.Items className="-right-1/2">
                      <DropdownMenu.Item onClick={() => setEditOffDayId(offDay.id)}>Edit</DropdownMenu.Item>
                      <DropdownMenu.Item
                        onClick={() => {
                          setDeleteOffDay(offDay)
                          onConfirmationDialogOpen()
                        }}
                      >
                        Delete
                      </DropdownMenu.Item>
                    </DropdownMenu.Items>
                  </DropdownMenu>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

function NoSendDatesWrapper() {
  const { data, status } = useCompany()
  if (status !== 'success') {
    return null
  }

  return <NoSendDatesPage company={data.data} />
}

export const NoSendDates = NoSendDatesWrapper
