import React, { useContext, useReducer } from 'react'
import { createContext } from 'react'
import { Action, reducer } from './reducer'
import { SequenceAnalyticsContextType } from './types'

const SequenceAnalyticsContext = createContext<{
  state: SequenceAnalyticsContextType
  dispatch: React.Dispatch<Action>
}>({
  state: { analytics: undefined, from: undefined, to: undefined },
  dispatch: () => null,
})

const SequenceAnalyticsDispatchContext = createContext<{ dispatch: React.Dispatch<Action> } | null>(null)

export function SequenceAnalyticsProvider({ children }: React.PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, { analytics: undefined, from: undefined, to: undefined })

  return (
    <SequenceAnalyticsContext.Provider value={{ state: state, dispatch: dispatch }}>
      {children}
    </SequenceAnalyticsContext.Provider>
  )
}

export function useSequenceAnalyticsContext() {
  const sequenceAnalyticsContext = useContext(SequenceAnalyticsContext)
  if (sequenceAnalyticsContext === undefined) {
    throw new Error('useSequenceAnalyticsContext must be inside a SequenceAnalyticsProvider')
  }
  return sequenceAnalyticsContext
}

export function useSequenceAnalyticsDispatchContext() {
  const sequenceAnalyticsDispatchContext = useContext(SequenceAnalyticsDispatchContext)
  if (sequenceAnalyticsDispatchContext === undefined) {
    throw new Error('useSequenceAnalyticsDispatchContext must be inside a SequenceAnalyticsProvider')
  }
  return sequenceAnalyticsDispatchContext
}
