import { Link } from 'react-router-dom'
import { useContactActivities } from '@/api/contacts'
import {
  Event,
  EventSequence,
  EventTypeCallAnswer,
  EventTypeCallReceive,
  EventTypeContactAdd,
  EventTypeContactStageChange,
  EventTypeEmailAutorespond,
  EventTypeEmailBounce,
  EventTypeEmailOpen,
  EventTypeEmailReply,
  EventTypeEmailSend,
  EventTypeEmailUnsubscribe,
  EventTypeMeetingBook,
} from '@/api/core'
import { ContactStageToText } from '@/api/text'
import { timeAgo } from '@/lib/date'
import { Text } from '@/ui'

export function ActivityHistory({ contactId }: { contactId: string }) {
  return (
    <div className="pt-6">
      <Text variant="subtitle" className="text-dark">
        Activity
      </Text>
      <ContactActivityFeed contactId={contactId} />
    </div>
  )
}

type ContactActivityFeedProps = {
  contactId: string
}

// TODO: Make this a reusable component
function ContactActivityFeed(props: ContactActivityFeedProps) {
  const { data: activitiesData, status } = useContactActivities(props.contactId)

  const activities = activitiesData?.data?.events

  if (status === 'pending' || status === 'error') {
    return <div className="skeleton mb-2 h-48 w-full"></div>
  }

  if (!activities || activities.length === 0) {
    return <Text className="text-medium">No interaction history</Text>
  }

  return (
    <>
      {activities?.map((activity) => {
        return (
          <div key={activity.id}>
            <div className="flex justify-between text-sm">
              <Text className="text-medium">{renderActivity(activity)}</Text>
              <Text className="text-medium">{timeAgo(activity.occurredAt)}</Text>
            </div>
          </div>
        )
      })}
    </>
  )
}

function renderEntity(name: string) {
  return <span className="text-dark">{name}</span>
}

function sequenceNameLink(sequence: EventSequence) {
  return (
    <Link className="text-sm text-dark underline" to={`/sequences/${sequence.id}`}>
      {sequence.name}
    </Link>
  )
}

function renderActivity(a: Event) {
  switch (a.type) {
    case EventTypeCallAnswer.CALL_ANSWER:
      return <>{renderEntity(a.actorName)} called</>
    case EventTypeCallReceive.CALL_RECEIVE:
      return <>Call from {renderEntity(a.actorName)} was answered</>
    case EventTypeContactAdd.CONTACT_ADD:
      return <>{renderEntity(a.actorName)} added contact</>
    case EventTypeContactStageChange.CONTACT_STAGE_CHANGE:
      return (
        <>
          {renderEntity(a.actorName)} updated status from{' '}
          {renderEntity(ContactStageToText[a.stageChange.previousStage])} to{' '}
          {renderEntity(ContactStageToText[a.stageChange.newStage])}
        </>
      )
    case EventTypeEmailAutorespond.EMAIL_AUTORESPOND:
      return (
        <>
          Email address {renderEntity(a.contact.email)} autoresponded&ensp;•&ensp;Step {a.stepNo}
          &ensp;•&ensp;{sequenceNameLink(a.sequence)}
        </>
      )
    case EventTypeEmailBounce.EMAIL_BOUNCE:
      return (
        <>
          Email address {renderEntity(a.contact.email)} bounced&ensp;•&ensp;Step {a.stepNo}
          &ensp;•&ensp;{sequenceNameLink(a.sequence)}
        </>
      )
    case EventTypeEmailOpen.EMAIL_OPEN:
      return (
        <>
          Opened Step {a.stepNo} email in {sequenceNameLink(a.sequence)}
        </>
      )
    case EventTypeEmailReply.EMAIL_REPLY:
      return (
        <>
          Replied to Step {a.stepNo} email in {sequenceNameLink(a.sequence)}
        </>
      )
    case EventTypeEmailSend.EMAIL_SEND:
      return (
        <>
          {renderEntity(a.actorName)} sent email&ensp;•&ensp;Step {a.stepNo}&ensp;•&ensp;
          {sequenceNameLink(a.sequence)}
        </>
      )
    case EventTypeEmailUnsubscribe.EMAIL_UNSUBSCRIBE:
      return (
        <>
          Unsubscribed&ensp;{a.sequence && <>•&ensp;{sequenceNameLink(a.sequence)}&ensp</>}•&ensp;
          {timeAgo(a.occurredAt)}
        </>
      )
    case EventTypeMeetingBook.MEETING_BOOK:
      return (
        <>
          Booked a meeting with {renderEntity(a.actorName)}&ensp;
          {a.sequence && <>•&ensp;{sequenceNameLink(a.sequence)}&ensp</>}
          •&ensp;{timeAgo(a.occurredAt)}
        </>
      )
    default:
      return ''
  }
}
