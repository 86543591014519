import React, { InputHTMLAttributes, useCallback, useState } from 'react'
import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'
import { Eye, EyeSlash, Icon } from '@phosphor-icons/react'
import { FieldWrapper, FieldWrapperPassThroughProps } from '@/ui/FieldWrapper'

const Variants = {
  basic:
    'w-full block h-9 label rounded-lg border border-light outline-2 outline-accent py-2 px-3 disabled:bg-extra-light disabled:text-medium transition-all duration-100 hover:duration-200 ease-in hover:ring-1 hover:ease-out active:duration-200 active:ease-out',
  'leading-icon':
    'w-full block h-9 label rounded-lg border border-light outline-2 outline-accent pr-3 pl-9 py-2 disabled:bg-extra-light disabled:text-medium transition-all duration-100 hover:duration-200 ease-in hover:ring-1 hover:ease-out active:duration-200 active:ease-out',
}

export type InputProps = {
  onIconClick?: () => void
  icon?: Icon
  label?: string
  helperText?: string
  error?: FieldError | string
  registration?: Partial<UseFormRegisterReturn>
  containerClassName?: string
  iconClassName?: string
  iconButtonClassName?: string
  labelClassName?: string
  boldLabel?: boolean
}

export function Input(props: InputHTMLAttributes<HTMLInputElement> & InputProps) {
  const variant = props.icon ? 'leading-icon' : 'basic'
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    onIconClick,
    label,
    helperText,
    error,
    registration,
    containerClassName,
    labelClassName,
    iconClassName,
    iconButtonClassName,
    boldLabel = true,
    ...rest
  } = props

  return (
    <div className={twMerge('relative my-1.5', containerClassName)}>
      {label && (
        <label
          htmlFor={props.id}
          className={twMerge('label block pb-1', labelClassName, boldLabel ? 'font-medium' : '')}
        >
          {label}
        </label>
      )}
      {props.icon &&
        (onIconClick ? (
          <button
            className={twMerge('absolute left-2 top-2 h-5 w-5', iconButtonClassName)}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              onIconClick?.()
            }}
          >
            <props.icon className={twMerge('h-5 w-5 fill-medium', iconClassName)} />
          </button>
        ) : (
          <props.icon
            className={twMerge('pointer-events-none absolute left-2 top-2 h-5 w-5 fill-medium', iconClassName)}
          />
        ))}
      <input
        {...rest}
        className={twMerge(Variants[variant], props.className, error !== undefined && 'border-alert outline-alert')}
        {...registration}
      />
      {helperText && <p className="label text-dusk">{helperText}</p>}
      {error && <p className="label text-alert">{typeof error === 'string' ? error : error?.message}</p>}
    </div>
  )
}

export function InputPassword(props: InputHTMLAttributes<HTMLInputElement> & InputProps) {
  const [inputType, setInputType] = useState<'password' | 'text'>('password')
  const togglePassword = useCallback(() => setInputType((prev) => (prev === 'password' ? 'text' : 'password')), [])

  return (
    <Input
      type={inputType}
      icon={inputType === 'password' ? EyeSlash : Eye}
      onIconClick={togglePassword}
      iconButtonClassName="top-8"
      {...props}
    />
  )
}

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password' | 'tel' | 'hidden' | 'number'
  className?: string
  placeholder?: string
  registration?: Partial<UseFormRegisterReturn>
  readonly?: boolean
  disabled?: boolean
  name?: string
  isOptional?: boolean
  value?: string | number
  error?: FieldError | FieldError[] | string | undefined
  readOnly?: boolean
  description?: string
  id: string
  node?: any
  required?: boolean
  extra?: React.ReactNode
  noStyles?: boolean
  autoFocus?: boolean
  min?: number
}

export function InputField(props: InputFieldProps) {
  return (
    <FieldWrapper
      label={props.label}
      error={props.error}
      name={props.name}
      nodeError={props.node}
      required={props.required}
      extra={props.extra}
    >
      <Input
        id={props.id}
        autoFocus={props.autoFocus}
        value={props.value}
        name={props.name}
        type={props.type}
        placeholder={props.placeholder}
        className={'w-full'}
        min={props.min}
        readOnly={props.readonly}
        disabled={props.disabled}
        registration={props.registration}
      />
    </FieldWrapper>
  )
}
export function InputPasswordField(props: InputFieldProps) {
  const [inputType, setInputType] = useState<string>('password')

  const { label, error, extra, name, required, id, className, placeholder, disabled, registration, ...rest } = props

  const togglePassword = () => {
    if (inputType === 'password') {
      setInputType('text')
      return
    }
    setInputType('password')
  }

  return (
    <FieldWrapper label={label} error={error} name={name} required={required} extra={extra}>
      <div className="relative flex w-full items-center">
        <Input
          registration={registration}
          id={id}
          name={name}
          placeholder={placeholder}
          type={inputType}
          className={className}
          disabled={disabled}
          icon={inputType === 'password' ? EyeSlash : Eye}
          onIconClick={togglePassword}
          {...rest}
        />
      </div>
    </FieldWrapper>
  )
}
