import { Button } from '@/ui'
import { Variant } from '@/ui/Button/v2'
import { useActions, useData } from '../Context'

export function PreviewButton({ className, variant = 'text' }: { className?: string; variant?: Variant }) {
  const { preview } = useData()
  const { togglePreview } = useActions()

  return (
    <Button variant={variant} type="button" className={className} onClick={togglePreview}>
      {preview ? 'Edit' : 'Preview'}
    </Button>
  )
}
