import { Fragment, useEffect, useReducer } from 'react'
import { useDebounce } from 'use-debounce'
import { useCampaignStepAppend, useCampaignUpdate } from '@/api/campaigns'
import { Campaign } from '@/api/core/models/Campaign'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button } from '@/ui/Button/v2'
import { reducer } from './reducer'
import { Step } from './Step'
import { TopMenu } from './TopMenu'

export function Email({ campaign }: { campaign: Campaign }) {
  return (
    <section className="no-scrollbar mt-6 h-full overflow-y-scroll ">
      <TopMenu campaign={campaign} />
      <Emails campaign={campaign} />
    </section>
  )
}

function Emails({ campaign }: { campaign: Campaign }) {
  const toast = useToast()
  const updateCampaign = useCampaignUpdate(campaign.id)
  const appendStep = useCampaignStepAppend(campaign.id)

  const [state, dispatch] = useReducer(reducer, { sequences: campaign.sequences })
  const [debouncedSave] = useDebounce(state, 500)

  const addStep = () => {
    appendStep.mutate(undefined, {
      onSuccess: (step) => {
        dispatch({ type: 'add_step', step: step.data })
      },
      onError: (err: unknown) => {
        toast.createToast({ message: (err as any)?.body?.message || "Can't add a step", error: true })
      },
    })
  }

  useEffect(() => {
    // check to see if there is only one than that it is valid
    // else all of them just need a valid body
    // unless it is reply in new thread then it needs a subject and body
    updateCampaign.mutate(
      { sequences: state.sequences },
      {
        onError: (err: unknown) => {
          toast.createToast({ message: (err as any)?.body?.message || "Can't update the step", error: true })
        },
      },
    )
  }, [debouncedSave])

  return (
    <>
      {state.sequences.map((step, i) => (
        <Step
          campaign={campaign}
          step={step}
          index={i}
          key={step.id}
          dispatch={dispatch}
          // Plaintext template needs to come from the Campaigns API response, since it is generated server-side
          // and does not get updated in the client-side state. This is why we pass it as a prop here.
          plainText={campaign.sequences[i] ? campaign.sequences[i].plain_text_template : ''}
          canDelete={state.sequences.length > 1}
          hasABTest={step.ab_test || (i + 1 < state.sequences.length && !!state.sequences[i + 1].ab_test)}
          stepNumber={i - state.sequences.filter((s, j) => j < i && s.ab_test).length + 1}
        />
      ))}
      {!campaign.permissions.add_remove_sequences.deny && (
        <section className="mb-6 rounded border border-light bg-white px-8 py-6">
          <Button variant="text" className="p-0 text-sm" onClick={addStep}>
            Add step
          </Button>
        </section>
      )}
    </>
  )
}
