import { useState } from 'react'
import { CaretUpDown, X } from '@phosphor-icons/react'
import { useCampaignShare, useCampaignSharees, useCampaignShareesRevoke } from '@/api/campaigns'
import { CampaignShareAccess, CampaignSharee, CampaignSharees } from '@/api/core'
import { Checkbox, Dialog, Listbox, Text } from '@/ui'

type ShareDialogProps = {
  campaignId: string
  open: boolean
  onClose: () => void
}

export function ShareDialog(props: ShareDialogProps) {
  const { data, status } = useCampaignSharees(props.campaignId, { enabled: props.open })
  if (status !== 'success') {
    return null
  }

  return <ShareDialogForm sharees={data.data} {...props} />
}

type ShareDialogFormProps = {
  campaignId: string
  sharees: CampaignSharees
  open: boolean
  onClose: () => void
}

function ShareDialogForm(props: ShareDialogFormProps) {
  const { sharees, open, onClose } = props

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Panel className="max-w-sm">
        <div className="mb-5 flex justify-between">
          <Text variant="subtitle">Share sequence</Text>
          <X className="size-5 cursor-default" aria-hidden="true" onClick={onClose} />
        </div>

        <div className="flex flex-col gap-2">
          {sharees.map((sharee) => (
            <ShareeRowForm key={sharee.memberId} campaignId={props.campaignId} sharee={sharee} />
          ))}
        </div>
      </Dialog.Panel>
    </Dialog>
  )
}

const accessText: Record<CampaignShareAccess, string> = {
  [CampaignShareAccess.NONE]: '',
  [CampaignShareAccess.EDIT]: 'Can edit',
  [CampaignShareAccess.VIEW]: 'Can view',
}

type ShareeRowFormProps = {
  campaignId: string
  sharee: CampaignSharee
}

function ShareeRowForm(props: ShareeRowFormProps) {
  const { campaignId, sharee } = props
  const revoke = useCampaignShareesRevoke(campaignId)
  const share = useCampaignShare(campaignId)
  const [access, setAccess] = useState(sharee.access)

  const onRevoke = () => {
    revoke.mutate(sharee.memberId)
  }

  const onShare = (access: CampaignShareAccess) => {
    share.mutate({ memberId: sharee.memberId, access: access })
  }

  const onChange = (checked: boolean, access: CampaignShareAccess = CampaignShareAccess.VIEW) => {
    if (checked) {
      onShare(access)
      setAccess(access)
    } else {
      onRevoke()
      setAccess(CampaignShareAccess.NONE)
    }
  }

  if (sharee.isOwner) {
    return (
      <div key={sharee.memberId} className="flex min-h-9 items-center justify-between">
        <Text className="text-dark">{sharee.name}</Text>
        <Text>Owner</Text>
      </div>
    )
  }

  const granted = sharee.access !== CampaignShareAccess.NONE
  return (
    <div key={sharee.memberId} className="flex min-h-9 justify-between">
      <div className="flex items-center gap-2">
        <Checkbox checked={granted} onChange={(e) => onChange(e.target.checked)} />
        <Text className="text-dark">{sharee.name}</Text>
      </div>
      {granted && (
        <div>
          <Listbox value={access} onChange={(a) => onChange(true, a)}>
            {
              // stop propagation to prevent the listbox from closing and
              // selecting a sequence in the sequence list view
            }
            <Listbox.Button onClick={(e) => e.stopPropagation()} className="w-28 cursor-default items-start">
              {accessText[access]}
              <CaretUpDown className="ml-2 size-4 text-medium" />
            </Listbox.Button>
            <Listbox.Options className="-rigt-1/2">
              <Listbox.Option value={CampaignShareAccess.EDIT}>{accessText[CampaignShareAccess.EDIT]}</Listbox.Option>
              <Listbox.Option value={CampaignShareAccess.VIEW}>{accessText[CampaignShareAccess.VIEW]}</Listbox.Option>
            </Listbox.Options>
          </Listbox>
        </div>
      )}
    </div>
  )
}
