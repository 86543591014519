import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { CaretLeft, CaretRight, SortAscending, SortDescending } from '@phosphor-icons/react'
import { ColumnDef, flexRender, getCoreRowModel, Row, useReactTable } from '@tanstack/react-table'
import { CampaignList, CampaignSortField, CampaignStatus } from '@/api/core'
import { Button, Checkbox, MouseTrap, Text } from '@/ui'
import { DisplayStatusName } from '../types'
import { SequenceFloatingAppBar } from './FloatingAppBar'

export type TableComponentProps = {
  data: CampaignList[]
  columns: ColumnDef<CampaignList, any>[]
  curPage: number
  sortBy: CampaignSortField
  totalPages: number
  setSortBy: (sortBy: CampaignSortField) => void
  setNextPage: () => void
  setPrevPage: () => void
  onRowClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: CampaignList) => void
}

export function SequencesTable({
  data,
  columns,
  curPage,
  totalPages,
  sortBy,
  setSortBy,
  setNextPage,
  setPrevPage,
  onRowClick,
}: TableComponentProps) {
  const dataById = data.reduce(
    (acc, row) => {
      acc[row.id] = row
      return acc
    },
    {} as Record<string, CampaignList>,
  )

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})
  const tableRef = useRef(null)

  const table = useReactTable({
    data,
    columns,
    manualPagination: true,
    manualSorting: true,
    enableSorting: true,
    state: {
      rowSelection,
    },
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
  })

  const onSelectAllRowsOfType = (status: CampaignStatus, select: boolean) => {
    if (!select) {
      setRowSelection({})
      return
    }

    setRowSelection(
      data.reduce(
        (acc, row) => {
          if (DisplayStatusName[row.status] === DisplayStatusName[status]) {
            acc[row.id] = true
          }
          return acc
        },
        {} as Record<string, boolean>,
      ),
    )
  }

  function selectRow(row: Row<CampaignList>) {
    setRowSelection((prev) => {
      // when a new row is selected, then remove all other rows
      // that are selected and have a different type
      const selected = !row.getIsSelected()
      const rowStatus = DisplayStatusName[row.original.status]
      const prevState = { ...prev }
      if (selected) {
        for (const id in prevState) {
          prevState[id] = prevState[id] && DisplayStatusName[dataById[id]?.status] === rowStatus
        }
      }
      prevState[row.original.id] = selected
      return prevState
    })
  }

  const sortColumn = sortBy.replace(/^-/, '')
  const sortOrder = sortBy.startsWith('-') ? 'desc' : 'asc'

  const onSortClick = (column: string) => {
    const field = column === 'name' ? CampaignSortField.NAME : CampaignSortField.START_DATE

    if (sortOrder === 'asc') {
      setSortBy(`-${field}` as CampaignSortField)
    } else {
      setSortBy(field)
    }
  }

  return (
    <div className="mt-2 inline-block min-w-full align-middle">
      <SequenceFloatingAppBar setRowSelection={setRowSelection} selectedRows={rowSelection} dataById={dataById} />
      <table className="min-w-full divide-y divide-light" ref={tableRef}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={`header-row-${headerGroup.id}`}>
              {headerGroup.headers.map((header) => (
                <th
                  key={`header-${header.id}`}
                  scope="col"
                  className={twMerge(
                    'px-3 py-3.5 text-left text-sm font-medium',
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    header.column.columnDef?.meta?.headingClassName,
                  )}
                  onClick={() => header.column.getCanSort() && onSortClick(header.column.id)}
                >
                  <span className="flex items-center">
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.id === sortColumn &&
                      (sortOrder === 'asc' ? (
                        <SortAscending className="ml-1 h-4 w-4 text-medium" />
                      ) : (
                        <SortDescending className="ml-1 h-4 w-4 text-medium" />
                      ))}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            const displayHeader: boolean =
              row.index === 0 ||
              DisplayStatusName[row.original.status] !==
                DisplayStatusName[table.getRowModel().rows[row.index - 1].original.status]

            return (
              <React.Fragment key={row.id}>
                {displayHeader && (
                  <TableRowSubHeader
                    onSelectAllRowsOfType={onSelectAllRowsOfType}
                    status={row.original.status}
                    selectedRows={rowSelection}
                    dataById={dataById}
                  />
                )}
                <tr
                  className="h-11 hover:bg-extra-light"
                  onClick={(e) => onRowClick(e, row.original)}
                  onMouseUp={(e) => onRowClick(e, row.original)}
                >
                  {row.getVisibleCells().map((cell) => {
                    // TODO: (Ange) remove ts-ignore and use this https://github.com/TanStack/table/discussions/4157
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (cell.column.columnDef?.meta?.checkboxRow) {
                      return (
                        <td key={cell.id} className="px-3 py-1 text-sm">
                          <MouseTrap>
                            <Checkbox
                              checked={row.getIsSelected()}
                              disabled={!row.getCanSelect()}
                              onChange={() => selectRow(row)}
                            />
                          </MouseTrap>
                        </td>
                      )
                    }

                    return (
                      <td
                        key={cell.id}
                        className={clsx(
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          cell.column.columnDef?.meta?.classname,
                          'px-3 py-1 text-sm',
                        )}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              </React.Fragment>
            )
          })}
        </tbody>
      </table>

      {totalPages > 1 && (
        <div className="mt-4 flex items-center justify-end gap-2">
          <Button
            variant="text"
            onClick={setPrevPage}
            disabled={curPage <= 1}
            className={clsx('focus:ring-0', curPage <= 1 && 'invisible')}
          >
            <CaretLeft className="size-4" />
          </Button>
          <span className="flex items-center gap-2 text-xs">
            <div>Page</div>
            <div className="font-bold">
              {curPage} / {totalPages}
            </div>
          </span>
          <Button
            variant="text"
            onClick={setNextPage}
            disabled={curPage >= totalPages}
            className={clsx('focus:ring-0', curPage >= totalPages && 'invisible')}
          >
            <CaretRight className="size-4" />
          </Button>
        </div>
      )}
    </div>
  )
}

type TableRowSubHeaderProps = {
  dataById: Record<string, CampaignList>
  status: CampaignStatus
  onSelectAllRowsOfType: (status: CampaignStatus, select: boolean) => void
  selectedRows: Record<string, boolean>
}

function TableRowSubHeader(props: TableRowSubHeaderProps) {
  const { status, onSelectAllRowsOfType, selectedRows, dataById } = props
  const [selected, setSelected] = useState<boolean>(false)

  function handleHeaderCheckboxSelection() {
    const newSelectedStatus = !selected
    setSelected(newSelectedStatus)
    onSelectAllRowsOfType(status, newSelectedStatus)
  }

  useEffect(() => {
    if (!Object.keys(selectedRows).length) {
      setSelected(false)
    } else {
      let newSelectedState = false
      for (const id in selectedRows) {
        if (selectedRows[id] && DisplayStatusName[dataById[id]?.status] === DisplayStatusName[status]) {
          newSelectedState = true
          break
        }
      }
      setSelected(newSelectedState)
    }
  }, [selectedRows])

  return (
    <tr className="border-y border-light bg-gray-50 py-2 pl-4 pr-3 ">
      <td className="px-3 py-1">
        <Checkbox checked={selected} onChange={handleHeaderCheckboxSelection} />
      </td>
      <th colSpan={13} scope="colgroup" className="text-left">
        <Text variant="subtitle">{DisplayStatusName[status]}</Text>
      </th>
    </tr>
  )
}
