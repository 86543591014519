import React, { createContext, Dispatch, Fragment, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { DayPicker } from 'react-day-picker'
import { Duration, formatDuration } from 'date-fns'
import { Dialog } from '@headlessui/react'
import { CaretDown, X } from '@phosphor-icons/react'
import { ContactStage, Member, MemberRole } from '@/api/core'
import { ContactStageToText } from '@/api/text'
import { dateFormat } from '@/lib/date'
import { randomId } from '@/lib/random'
import { Button, Listbox } from '@/ui'
import { OpacityTransition } from '@/ui/Transition/OpacityTransition'

type contactStageKey = keyof typeof ContactStage

type cmp = 'more than' | 'less than' | 'after' | 'before'

type DateEntry = {
  id: string
  duration?: Duration
  date?: Date
  cmp: cmp
}

type StateDefinition = {
  stages: {
    stage: contactStageKey
    checked: boolean
    label: string
  }[]
  owners: (Member & { checked: boolean })[]
} & {
  [key in DateFilter]: { dates: DateEntry[] }
}

enum ActionTypes {
  ToggleStatus,
  ClearStatues,
  ToggleOwner,
  ClearOwners,
  AddDate,
  RemoveDate,
  UpdateDate,
  UpdateDateCmp,
}

type Actions =
  | { type: ActionTypes.ToggleStatus; stage: contactStageKey }
  | { type: ActionTypes.ClearStatues }
  | { type: ActionTypes.ToggleOwner; owner: Member }
  | { type: ActionTypes.ClearOwners }
  | { type: ActionTypes.AddDate; field: DateFilter; duration?: Duration; date?: Date }
  | { type: ActionTypes.RemoveDate; field: DateFilter; id: string }
  | {
      type: ActionTypes.UpdateDate
      id: string
      duration?: Duration
      date?: Date
      field: DateFilter
    }
  | {
      type: ActionTypes.UpdateDateCmp
      id: string
      cmp: cmp
      field: DateFilter
    }

const reducers: {
  [P in ActionTypes]: (state: StateDefinition, action: Extract<Actions, { type: P }>) => StateDefinition
} = {
  [ActionTypes.ToggleStatus]: (state, action) => {
    return {
      ...state,
      stages: state.stages.map((status) => {
        if (status.stage === action.stage) {
          return {
            ...status,
            checked: !status.checked,
          }
        }
        return status
      }),
    }
  },
  [ActionTypes.ClearStatues]: (state) => {
    return {
      ...state,
      stages: state.stages.map((status) => ({
        ...status,
        checked: false,
      })),
    }
  },
  [ActionTypes.ToggleOwner]: (state, action) => {
    return {
      ...state,
      owners: state.owners.map((owner) => {
        if (owner.id === action.owner.id) {
          return {
            ...owner,
            checked: !owner.checked,
          }
        }
        return owner
      }),
    }
  },
  [ActionTypes.ClearOwners]: (state) => {
    return {
      ...state,
      owners: state.owners.map((owner) => ({
        ...owner,
        checked: false,
      })),
    }
  },
  [ActionTypes.AddDate]: (state, action) => {
    return {
      ...state,
      [action.field]: {
        dates: [
          ...state[action.field].dates,
          {
            id: randomId(),
            date: action.date,
            duration: action.duration,
            cmp: action.date ? 'after' : 'less than',
          },
        ],
      },
    }
  },
  [ActionTypes.RemoveDate]: (state, action) => {
    return {
      ...state,
      [action.field]: {
        dates: state[action.field].dates.filter((d) => d.id !== action.id),
      },
    }
  },
  [ActionTypes.UpdateDate]: (state, action) => {
    return {
      ...state,
      [action.field]: {
        dates: state[action.field].dates.map((d) => {
          if (d.id === action.id) {
            let cmp = d.cmp
            if (d.date && action.duration) {
              if (cmp === 'after') cmp = 'more than'
              if (cmp === 'before') cmp = 'less than'
            }
            if (d.duration && action.date) {
              if (cmp === 'more than') cmp = 'after'
              if (cmp === 'less than') cmp = 'before'
            }

            return {
              ...d,
              duration: action.duration,
              date: action.date,
              cmp,
            }
          }
          return d
        }),
      },
    }
  },
  [ActionTypes.UpdateDateCmp]: (state, action) => {
    return {
      ...state,
      [action.field]: {
        dates: state[action.field].dates.map((d) => {
          if (d.id === action.id) {
            return {
              ...d,
              cmp: action.cmp,
            }
          }
          return d
        }),
      },
    }
  },
}

function reducer(state: StateDefinition, action: Actions) {
  return reducers[action.type](state, action as any)
}

const FilterContext = createContext<
  [StateDefinition, Dispatch<Actions>, (field: DateFilter, id?: string) => void] | null
>(null)
FilterContext.displayName = 'FilterContext'

function useFilterContext(component: string) {
  const context = useContext(FilterContext)
  if (context === null) {
    const err = new Error(`<${component} /> is missing a parent <Filter /> component.`)
    if (Error.captureStackTrace) Error.captureStackTrace(err, useFilterContext)
    throw err
  }
  return context
}

export type ContactFilters = {
  stages: ContactStage[]
  owners: Member[]
} & DateFilterChangeParam

type DateFilterChangeParam = {
  [key in DateFilter]?: { start: Date | null; end: Date | null }
}

type FilterProps = {
  owners: Member[]
  onChange: (filter: ContactFilters) => void
}

function FilterFn(props: React.PropsWithChildren<FilterProps>) {
  const { owners } = props
  const [state, dispatch] = useReducer(reducer, {
    stages: (Object.keys(ContactStage) as contactStageKey[]).map((stage) => ({
      stage,
      checked: false,
      label: ContactStageToText[stage],
    })),
    created_at: { dates: [] },
    last_contacted_at: { dates: [] },
    owners: owners.map((owner) => ({ ...owner, checked: false })),
  } as StateDefinition)

  const [dayPickerOpen, setDayPickerOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState<{ field: DateFilter; dateId?: string }>({
    field: DateFilterLabels[0].field,
  })

  const onDayPickerOpen = (field: DateFilter, dateId?: string) => {
    setSelectedDate({ field, dateId })
    setDayPickerOpen(true)
  }

  const onDaySelect = (date: Date) => {
    const { field } = selectedDate
    setSelectedDate({ field })
    if (selectedDate.dateId) {
      dispatch({ type: ActionTypes.UpdateDate, field, id: selectedDate.dateId, date })
    } else {
      dispatch({ type: ActionTypes.AddDate, field, date })
    }
  }

  useEffect(() => {
    const dateFilters = DateFilterLabels.map(({ field }) => ({ field, ...calculateDateRange(state[field].dates) }))
    const changeParam: ContactFilters = {
      stages: state.stages.filter((s) => s.checked).map((s) => s.stage as ContactStage),
      owners: state.owners.filter((o) => o.checked).map((o) => o as Member),
    }
    for (const dateFilter of dateFilters) {
      changeParam[dateFilter.field] = { start: dateFilter.startDate, end: dateFilter.endDate }
    }

    props.onChange(changeParam)
  }, [state])

  return (
    <FilterContext.Provider value={[state, dispatch, onDayPickerOpen]}>
      <DayPickerDialog show={dayPickerOpen} onClose={() => setDayPickerOpen(false)} onSelect={onDaySelect} />
      {props.children}
    </FilterContext.Provider>
  )
}

function calculateDateRange(dates: DateEntry[]): { startDate: Date | null; endDate: Date | null } {
  let startDate: Date | null = null
  let endDate: Date | null = null

  for (const d of dates) {
    if (d.duration) {
      const targetDate = new Date()
      if (d.cmp === 'more than') {
        targetDate.setDate(targetDate.getDate() - (d.duration?.days || 0))
        targetDate.setDate(targetDate.getDate() - 7 * (d.duration?.weeks || 0))
        targetDate.setMonth(targetDate.getMonth() - (d.duration?.months || 0))
        if (!endDate || targetDate < endDate) {
          endDate = targetDate
        }
      } else if (d.cmp === 'less than') {
        targetDate.setDate(targetDate.getDate() - (d.duration?.days || 0))
        targetDate.setDate(targetDate.getDate() - 7 * (d.duration?.weeks || 0))
        targetDate.setMonth(targetDate.getMonth() - (d.duration?.months || 0))
        if (!startDate || targetDate > startDate) {
          startDate = targetDate
        }
      }
    } else if (d.date) {
      if (d.cmp === 'after') {
        if (!startDate || d.date > startDate) {
          startDate = d.date
        }
      } else if (d.cmp === 'before') {
        if (!endDate || d.date < endDate) {
          endDate = d.date
        }
      }
    }
  }

  if (startDate && endDate && startDate > endDate) {
    // Reset start and end dates to null if they don't overlap
    startDate = null
    endDate = null
  }

  return { startDate, endDate }
}

function StatusOptions() {
  const [state, dispatch] = useFilterContext('Filter.StatusOptions')
  return (
    <Listbox.Options>
      {state.stages.map((stage) => (
        <Listbox.Option
          key={stage.stage}
          value={stage}
          onClick={() => dispatch({ type: ActionTypes.ToggleStatus, stage: stage.stage })}
        >
          <span className="cursor-default text-sm leading-5 tracking-[0.28px] text-dark">{stage.label}</span>
        </Listbox.Option>
      ))}
    </Listbox.Options>
  )
}

function OwnerOptions() {
  const [state, dispatch] = useFilterContext('Filter.OwnerOptions')
  return (
    <Listbox.Options>
      {state.owners.map((owner) => (
        <Listbox.Option
          key={owner.id}
          value={owner}
          onClick={() => dispatch({ type: ActionTypes.ToggleOwner, owner: owner })}
          className="cursor-default"
        >
          <p className="text-sm leading-5 tracking-[0.28px] text-dark">{owner.name}</p>
          {owner.role === MemberRole.PHANTOM && (
            <p className="text-xs leading-4 tracking-[0.36px] text-medium">Not a member</p>
          )}
        </Listbox.Option>
      ))}
    </Listbox.Options>
  )
}

function DateFilterOptions({ id, field }: { id?: string; field: DateFilter }) {
  const [, dispatch, onDayPickerOpen] = useFilterContext('Filter.DateFilterOptions')

  const onDurationClick = (duration: Duration) => {
    if (id) {
      return dispatch({ type: ActionTypes.UpdateDate, field, id, duration, date: undefined })
    }
    return dispatch({ type: ActionTypes.AddDate, field, duration })
  }

  const durations = [
    { duration: { days: 1 } as Duration },
    { duration: { days: 3 } as Duration },
    { duration: { weeks: 1 } as Duration },
    { duration: { weeks: 2 } as Duration },
    { duration: { months: 1 } as Duration },
    { duration: { months: 3 } as Duration },
  ]

  return (
    <Listbox.Options>
      {durations.map((d, idx) => (
        <Listbox.Option key={idx} value={d.duration} onClick={() => onDurationClick(d.duration)}>
          <span className="cursor-default">{formatDuration(d.duration)} ago</span>
        </Listbox.Option>
      ))}
      <Listbox.Option value={'custom'} onClick={() => onDayPickerOpen(field, id)}>
        <span className="cursor-default">Custom...</span>
      </Listbox.Option>
    </Listbox.Options>
  )
}

type DayPickerDialogProps = {
  show: boolean
  onClose: () => void
  onSelect: (date: Date) => void
}

function DayPickerDialog(props: DayPickerDialogProps) {
  const prevMonth = useMemo(() => {
    const d = new Date()
    d.setMonth(d.getMonth() - 1)
    return d
  }, [])
  const onSelect = (date: Date | undefined) => {
    date && props.onSelect(date)
    props.onClose()
  }

  return (
    <OpacityTransition show={props.show}>
      <Dialog onClose={props.onClose} className="relative">
        <>
          <div className="fixed inset-0 bg-black bg-opacity-25" />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full min-w-full flex-col items-center justify-center p-4 text-center">
              <Dialog.Panel className="rounded-md bg-white p-2">
                <DayPicker
                  numberOfMonths={2}
                  toDate={new Date()}
                  defaultMonth={prevMonth}
                  onSelect={onSelect}
                  fixedWeeks
                  mode="single"
                />
              </Dialog.Panel>
            </div>
          </div>
        </>
      </Dialog>
    </OpacityTransition>
  )
}

type DateFilter = (typeof DateFilterLabels)[number]['field']

const DateFilterLabels = [
  {
    field: 'created_at',
    label: 'Date added',
  },
  {
    field: 'last_contacted_at',
    label: 'Last contacted',
  },
] as const

function Add() {
  const [state] = useFilterContext('Filter.Add')

  const selectedstages = state.stages.filter((s) => s.checked)
  const selectedOwners = state.owners.filter((o) => o.checked)

  return (
    <>
      <div className="flex gap-2">
        <div className="relative">
          <Listbox multiple value={selectedstages}>
            <Listbox.Button>
              Contact stage
              <CaretDown className="ml-2 size-4" />
            </Listbox.Button>
            <StatusOptions />
          </Listbox>
        </div>
        {DateFilterLabels.map((filter) => (
          <div className="relative" key={filter.field}>
            <Listbox>
              <Listbox.Button>
                {filter.label}
                <CaretDown className="ml-2 size-4" />
              </Listbox.Button>
              <DateFilterOptions field={filter.field} />
            </Listbox>
          </div>
        ))}
        <div className="relative">
          <Listbox multiple value={selectedOwners}>
            <Listbox.Button>
              Owner
              <CaretDown className="ml-2 size-4" />
            </Listbox.Button>
            <OwnerOptions />
          </Listbox>
        </div>
      </div>
    </>
  )
}

function Labels() {
  const [state, dispatch] = useFilterContext('Filter.Labels')

  const selectedstages = state.stages.filter((s) => s.checked)
  const selectedOwners = state.owners.filter((o) => o.checked)

  return (
    <>
      <div className="mb-2 flex min-h-full flex-wrap gap-3">
        {selectedstages.length > 0 && (
          <div className="mb-2 inline-flex min-h-full gap-0.5 text-gray-900">
            <Button className="pointer-events-none rounded-r-none hover:rounded-r-none">Contact stage</Button>

            <Button className="pointer-events-none rounded-none hover:rounded-none">
              {selectedstages.length === 1 ? 'equals' : 'is any of'}
            </Button>

            <div className="relative">
              <Listbox multiple value={selectedstages}>
                <Listbox.Button className="rounded-none hover:rounded-none">
                  {selectedstages.length === 1
                    ? state.stages.find((s) => s.checked)?.label
                    : `${selectedstages.length} stages`}
                </Listbox.Button>
                <StatusOptions />
              </Listbox>
            </div>
            <Button
              className="rounded-l-none hover:rounded-l-none"
              onClick={() => dispatch({ type: ActionTypes.ClearStatues })}
            >
              <X className="szie-4" />
            </Button>
          </div>
        )}
        {DateFilterLabels.map(({ field, label }) => {
          return state[field].dates.map((d) => (
            <div className="mb-2 inline-flex min-h-full gap-0.5 text-gray-900" key={d.id}>
              <Button className="pointer-events-none rounded-r-none hover:rounded-r-none">{label}</Button>

              <div className="relative">
                <Listbox value={d.id + d.cmp}>
                  <Listbox.Button className="rounded-none hover:rounded-none">{d.cmp}</Listbox.Button>
                  {d.duration && (
                    <Listbox.Options>
                      <Listbox.Option
                        value={d.id + 'less than'}
                        onClick={() => dispatch({ type: ActionTypes.UpdateDateCmp, field, id: d.id, cmp: 'less than' })}
                      >
                        less than
                      </Listbox.Option>
                      <Listbox.Option
                        value={d.id + 'more than'}
                        onClick={() => dispatch({ type: ActionTypes.UpdateDateCmp, field, id: d.id, cmp: 'more than' })}
                      >
                        more than
                      </Listbox.Option>
                    </Listbox.Options>
                  )}
                  {d.date && (
                    <Listbox.Options>
                      <Listbox.Option
                        value={d.id + 'after'}
                        onClick={() => dispatch({ type: ActionTypes.UpdateDateCmp, field, id: d.id, cmp: 'after' })}
                      >
                        after
                      </Listbox.Option>
                      <Listbox.Option
                        value={d.id + 'before'}
                        onClick={() => dispatch({ type: ActionTypes.UpdateDateCmp, field, id: d.id, cmp: 'before' })}
                      >
                        before
                      </Listbox.Option>
                    </Listbox.Options>
                  )}
                </Listbox>
              </div>

              <div className="relative">
                <Listbox>
                  <Listbox.Button className="rounded-none hover:rounded-none">
                    {d.duration && `${formatDuration(d.duration)} ago`}
                    {d.date && dateFormat(d.date)}
                  </Listbox.Button>
                  <DateFilterOptions field={field} id={d.id} />
                </Listbox>
              </div>
              <Button
                className="rounded-l-none hover:rounded-l-none"
                onClick={() => dispatch({ type: ActionTypes.RemoveDate, field, id: d.id })}
              >
                <X className="szie-4" />
              </Button>
            </div>
          ))
        })}
        {selectedOwners.length > 0 && (
          <div className="mb-2 inline-flex min-h-full gap-0.5 text-gray-900">
            <Button className="pointer-events-none rounded-r-none hover:rounded-r-none">Contact owner</Button>

            <Button className="pointer-events-none rounded-none hover:rounded-none">
              {selectedOwners.length === 1 ? 'equals' : 'is any of'}
            </Button>

            <div className="relative">
              <Listbox multiple value={selectedOwners}>
                <Listbox.Button className="rounded-none hover:rounded-none">
                  {selectedOwners.length === 1
                    ? state.owners.find((o) => o.checked)?.name
                    : `${selectedOwners.length} owners`}
                </Listbox.Button>
                <OwnerOptions />
              </Listbox>
            </div>
            <Button
              className="rounded-l-none hover:rounded-l-none"
              onClick={() => dispatch({ type: ActionTypes.ClearOwners })}
            >
              <X className="szie-4" />
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

export const Filter = Object.assign(FilterFn, { Add, Labels })
