import React, { Fragment } from 'react'
import { Transition } from '@headlessui/react'

export function OpacityTransition(props: React.PropsWithChildren<{ show?: boolean }>) {
  return (
    <Transition
      appear
      show={props.show}
      as={Fragment}
      enter="ease-out duration-100"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {props.children}
    </Transition>
  )
}
