import { useDatetimeCountdown } from '@/hooks/useDatetimeCountdown'
import { CircleCountdown, Text } from '@/ui'

type PasswordTabProps = {
  otpCode: string
  expiresAt: number
}

export function OneTimePassword(props: PasswordTabProps) {
  const { otpCode, expiresAt } = props
  const [count] = useDatetimeCountdown(expiresAt)
  return (
    <>
      <Text variant="subtext" className="font-semibold">
        one-time password:
      </Text>
      <div className="flex items-center gap-2">
        {otpCode.slice(0, 3)}-{otpCode.slice(3, 6)}
        <CircleCountdown
          from={30}
          current={count}
          colorSteps={{
            10: '#348af3',
            0: '#f3355e',
          }}
        />
      </div>
    </>
  )
}
