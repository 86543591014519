import { useEffect, useState } from 'react'
import { useContactListRemoveContacts } from '@/api/contact_lists'
import { useContactsExport, useContactsUnenroll, useContactsUnsubscribe } from '@/api/contacts'
import { SequenceContacts } from '@/api/core'
import { pluralize } from '@/lib/strings'
import { ConfirmationDialog } from '@/pages/Contacts/ConfirmationDialog'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Text } from '@/ui'
import { FloatingAppBar } from '@/ui/FloatingAppBar'
import { useLoadSequence } from '../../hooks/useLoadSequence'

type ContactsFloatingAppBarProps = {
  selectedRows: {
    [key: number]: boolean
  }
  data: SequenceContacts
  setRowSelection: () => void
  isDraft: boolean
}
export function ContactsFloatingAppBar({ selectedRows, data, setRowSelection, isDraft }: ContactsFloatingAppBarProps) {
  const [floatingAppBarOpen, setFloatingAppBarOpen] = useState(false)

  const selectedContactIds = Object.keys(selectedRows)
    .filter((key) => selectedRows[Number(key)])
    .map((selectedIndex) => data[Number(selectedIndex)].id)

  useEffect(() => {
    setFloatingAppBarOpen(selectedContactIds.length > 0)
  }, [selectedContactIds])

  const onClose = () => {
    setRowSelection()
    setFloatingAppBarOpen(false)
  }

  return (
    <CreateContactsFloatingAppBar
      isDraft={isDraft}
      contactIds={selectedContactIds}
      open={floatingAppBarOpen}
      onClose={onClose}
      selected={Object.keys(selectedContactIds).length}
    />
  )
}

type CreateContactsFloatingAppBarProps = {
  open: boolean
  onClose: () => void
  selected: number
  contactIds: string[]
  isDraft: boolean
}

function CreateContactsFloatingAppBar({
  open,
  onClose,
  selected,
  contactIds,
  isDraft,
}: CreateContactsFloatingAppBarProps) {
  const contactsExport = useContactsExport()
  const [removeContacts, setRemoveContacts] = useState(false)
  const [unenrollContacts, setUnenrollContacts] = useState(false)
  const [unsubscribeContacts, setUnsubscribeContacts] = useState(false)
  const contactListRemoveContacts = useContactListRemoveContacts()
  const contactsUnenroll = useContactsUnenroll()
  const contactsUnsubscribe = useContactsUnsubscribe()
  const { data } = useLoadSequence()
  const toast = useToast()

  const onContactListRemoveContacts = () => {
    contactListRemoveContacts.mutate(
      { contactListId: data?.contact_list_ids[0] as string, contact_ids: contactIds },
      {
        onSuccess: () => {
          toast.createToast({ message: `${pluralize(contactIds.length, 'Contact')} deleted` })
          window.location.reload() // TODO (Ange): Do this without page reload
        },
        onError: (err) => {
          toast.createToast({
            message:
              (err as any)?.body?.message || `Failed to delete ${pluralize(contactIds.length, 'contact', 's', false)}`,
            error: true,
          })
        },
      },
    )
  }

  const onUnsubscribeContacts = () => {
    contactsUnsubscribe.mutate(
      { ids: contactIds },
      {
        onSuccess: () => {
          toast.createToast({ message: `${pluralize(contactIds.length, 'Contact')} unsubscribed` })
        },
        onError: (err) => {
          toast.createToast({
            message:
              (err as any)?.body?.message ||
              `Failed to unsubscribe ${pluralize(contactIds.length, 'contact', 's', false)}`,
            error: true,
          })
        },
      },
    )
  }

  const onUnenrollContacts = () => {
    contactsUnenroll.mutate(
      { ids: contactIds },
      {
        onSuccess: () => {
          toast.createToast({ message: `${pluralize(contactIds.length, 'Contact')} unenrolled` })
        },
        onError: (err) => {
          toast.createToast({
            message:
              (err as any)?.body?.message ||
              `Failed to unenroll ${pluralize(contactIds.length, 'contact', 's', false)}`,
            error: true,
          })
        },
      },
    )
  }

  const onExport = async () => {
    contactsExport.mutate(
      { ids: contactIds },
      {
        onSuccess: (b: Blob) => {
          const date = new Date().toLocaleDateString()
          const url = window.URL.createObjectURL(new Blob([b], { type: 'text/csv;charset=utf-8;' }))
          const tempLink = document.createElement('a')

          tempLink.href = url
          tempLink.setAttribute('download', `contacts_${date}.csv`)
          tempLink.click()
        },
        onError: (err) => {
          toast.createToast({ message: (err as any)?.body?.message || 'Failed to export contacts', error: true })
        },
      },
    )
  }

  return (
    <>
      <ConfirmationDialog
        open={removeContacts}
        onClose={() => setRemoveContacts(false)}
        onConfirm={onContactListRemoveContacts}
        message={`You are about to remove  ${selected} contacts from contact list.`}
      />
      <ConfirmationDialog
        open={unenrollContacts}
        onClose={() => setUnenrollContacts(false)}
        onConfirm={onUnenrollContacts}
        message={`You are about to unenroll ${selected} contacts.`}
        confirmBtn="Unenroll"
      />
      <ConfirmationDialog
        open={unsubscribeContacts}
        onClose={() => setUnsubscribeContacts(false)}
        onConfirm={onUnsubscribeContacts}
        message={`You are about to unsubscribe ${selected} contacts.`}
        confirmBtn="Unsubscribe"
      />
      <FloatingAppBar open={open}>
        <FloatingAppBar.Close onClose={onClose} />
        <Text variant="subtitle" className="self-center text-sm">
          {selected} Contacts
        </Text>
        <div className="ml-auto flex gap-2 self-center">
          {!isDraft && (
            <FloatingAppBar.Action onClick={() => setUnenrollContacts(true)}>Unenroll</FloatingAppBar.Action>
          )}
          {!isDraft && (
            <FloatingAppBar.Action onClick={() => setUnsubscribeContacts(true)}>Unsubscribe</FloatingAppBar.Action>
          )}
          {isDraft && <FloatingAppBar.Action onClick={() => setRemoveContacts(true)}>Remove</FloatingAppBar.Action>}

          <FloatingAppBar.Action onClick={onExport}>Export</FloatingAppBar.Action>
        </div>
      </FloatingAppBar>
    </>
  )
}
