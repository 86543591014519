import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCompany, useCompanyUpdate } from '@/api'
import { Company as CompanyType, PatchCompany } from '@/api/core'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Input, Text } from '@/ui'

export function Company() {
  const { data, status } = useCompany()
  if (status !== 'success') {
    return null
  }

  return <CompanySettings company={data.data} />
}

function CompanySettings({ company }: { company: CompanyType }) {
  const toast = useToast()
  const canEdit = company.permissions.edit.deny === false

  const validationSchema = yup.object().shape({
    name: yup.string().required('name is required'),
    short_name: yup
      .string()
      .trim()
      .min(3, 'Short name must be at least 3 characters')
      .matches(
        /^[\w\d][\w\d_-]{2,}$/,
        'short_name must be at least 3 characters long and contain only lowercase letters, numbers, underscores and dashes',
      )
      .default(''),
    hubspot_bcc_address: yup.string().nullable().email('Hubspot BCC is invalid').default(''),
  })

  const update = useCompanyUpdate()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isDirty },
  } = useForm({
    defaultValues: company,
    resolver: yupResolver(validationSchema) as any,
  })

  const onSubmit = (data: PatchCompany) =>
    update.mutate(data, {
      onSuccess: () => {
        toast.createToast({ message: 'Company updated' })
        reset(data)
      },
      onError: (err) =>
        toast.createToast({ message: (err as any)?.body?.message || 'Error updating company', error: true }),
    })

  return (
    <section>
      <Text variant="title" className="pb-2">
        Company
      </Text>
      <Text className="pb-6">Details about your company</Text>

      <form
        className={clsx('w-60', isSubmitting && 'pointer-events-none opacity-30 transition-opacity duration-100')}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          name="name"
          label="Company name"
          containerClassName="pb-6"
          registration={register('name')}
          error={errors['name']?.message}
          disabled={company.permissions.edit.deny}
        />
        <div className="pb-6">
          <Input
            name="short_name"
            label="Short name"
            registration={register('short_name')}
            error={errors['short_name']?.message}
            disabled={company.permissions.edit.deny}
          />
          <Text className="text-dusk">Used in meetings links</Text>
        </div>

        {canEdit && (
          <Input
            name="hubspot_bcc_address"
            id="hubspot_bcc_address"
            label="Hubspot BCC address"
            type="email"
            registration={register('hubspot_bcc_address')}
            error={errors['hubspot_bcc_address']?.message}
          />
        )}

        {canEdit && isDirty && (
          <div className="flex gap-2">
            <Button type="button" className="ml-auto mt-4" onClick={() => reset()}>
              Cancel
            </Button>
            <Button type="submit" variant="accent" className="mt-4">
              Save
            </Button>
          </div>
        )}
      </form>
    </section>
  )
}
