import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ApiError, DefaultService, PatchSalesforceConnection } from '@/api/core'
import { errhandler } from './helpers'

export const queryKey = {
  salesforceContactDetails: (contactId: string) => ['salesforceContactDetails', contactId] as const,
  salesforceBase: () => ['salesforceConnection'] as const,
  salesforceOptions: () => ['salesforceConnectionOptions'] as const,
  salesforceDebugData: () => ['salesforceDebugData'] as const,
}

export function useSalesforceConnection() {
  return useQuery({
    queryKey: queryKey.salesforceBase(),
    queryFn: () => DefaultService.getConnectionsSalesforce(),
    refetchInterval: 1000 * 15,
  })
}

export function useSalesforceConnectionOptions() {
  return useQuery({
    queryKey: queryKey.salesforceOptions(),
    queryFn: () => DefaultService.getConnectionsSalesforceOptions(),
  })
}

export function useSalesforceConnectionUpdate() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.salesforceBase() })
    },
    onError: (error: ApiError) => {
      errhandler(error, 'Cannot set salesforce mappings.')
    },
    mutationFn: (data: PatchSalesforceConnection) => {
      return DefaultService.patchConnectionsSalesforce(data)
    },
  })
}

export function useSalesforceContactDetails(contactId: string) {
  return useQuery({
    queryKey: queryKey.salesforceContactDetails(contactId),
    queryFn: () => DefaultService.getConnectionsSalesforceContacts(contactId),
  })
}

export function useSalesforceConnectionDelete() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.salesforceBase() })
    },
    mutationFn: () => DefaultService.deleteConnectionsSalesforce(),
  })
}

export function useSalesforceDebugData() {
  return useQuery({
    queryKey: queryKey.salesforceDebugData(),
    queryFn: () => DefaultService.getConnectionsSalesforceDebugData(),
  })
}
