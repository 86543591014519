import { useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useSequenceAnalyticsContext } from '@/providers/Sequences/SequenceAnalyticsContext'

export function Chart() {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)

  const { state: analyticsContext } = useSequenceAnalyticsContext()

  // TODO skeleton
  if (!analyticsContext.analytics?.steps_graph_data) return <></>

  const graphData = analyticsContext.analytics?.steps_graph_data

  const options: Highcharts.Options = {
    chart: {
      type: 'column',
      style: {
        fontFamily: 'Monument, sans-serif',
        fontSize: '16px',
      },
    },
    legend: {
      enabled: true,
      floating: true,
      verticalAlign: 'top',
      align: 'right',
      y: 0,
      backgroundColor: '#FFF',
    },

    xAxis: {
      type: 'category',
      categories: graphData.map((data) => data.date),
      crosshair: false,
      accessibility: {
        description: 'Dates',
      },
    },
    yAxis: {
      title: {
        text: 'Emails sent',
      },
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    title: {
      text: '',
    },

    series: [
      {
        type: 'column',
        name: 'Sent',
        data: graphData.map((data) => data.sent || null),
        color: '#E5E7EC',
      },
      {
        type: 'column',
        name: 'Opened',
        data: graphData.map((data) => data.opened || null),
        color: '#073874',
      },
      {
        type: 'column',
        name: 'Replied',
        data: graphData.map((data) => data.replied || null),
        color: '#3489F2',
      },
    ],
    tooltip: {
      style: {
        fontSize: '14px',
        fontFamily: 'Monument, sans-serif',
        lineHeight: '21px',
      },
    },
    credits: {
      enabled: false,
    },
  }

  return <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
}
