import { useParams } from 'react-router-dom'
import { useManualStepsEmailDone } from '@/api/manual_step'
import { ActivityHistory } from '../../ActivityHistory'
import { ContactInformation } from '../../ContactInformation'
import { SingleStep } from '../../SingleStep'
import { SingleStepSkeleton } from '../../Skeletons'

export function DoneEmail() {
  const { emailId } = useParams() as { emailId: string }
  const { data, status } = useManualStepsEmailDone(emailId)

  if (status === 'pending' || status === 'error') {
    return <SingleStepSkeleton />
  }

  const { prev, next, contact, body, subject } = data.data

  return (
    <SingleStep>
      <SingleStep.Navigation prev={prev} next={next} url="/inbox/done/" />
      <SingleStep.Main>
        <SingleStep.Card className="text-sm">
          <div className="ProseMirror relative">
            <div dangerouslySetInnerHTML={{ __html: subject }} className="pb-2 font-bold" />
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </div>
        </SingleStep.Card>

        <ActivityHistory contactId={contact.id} />
      </SingleStep.Main>
      <ContactInformation contactId={contact.id} />
    </SingleStep>
  )
}
