import { PropsWithChildren } from 'react'
import clsx from 'clsx'
import {
  Tooltip as HeadlessTooltip,
  TooltipOptions,
  // TODO: replace it with headless-ui when it's ready
} from '@/ui/headless/Tooltip'
import { forwardRefWithAs } from '../render'

function TooltipFn({ children, ...options }: { children: React.ReactNode } & TooltipOptions) {
  return <HeadlessTooltip {...options}>{children}</HeadlessTooltip>
}

function Trigger(
  { children, as = 'div', ...props }: React.HTMLProps<HTMLElement> & { as?: React.ElementType },
  ref: React.Ref<HTMLElement>,
) {
  return (
    <HeadlessTooltip.Trigger {...props} as={as} ref={ref}>
      {children}
    </HeadlessTooltip.Trigger>
  )
}

const widths = {
  xs: 'max-w-32',
  sm: 'max-w-40',
  md: 'max-w-48',
  lg: 'max-w-56',
  xl: 'max-w-64',
  '2xl': 'max-w-72',
  '3xl': 'max-w-80',
  '4xl': 'max-w-96',
}

type PanelProps = {
  children: React.ReactNode
  className?: string
  width?: keyof typeof widths
}

function Panel(props: PanelProps, ref: React.Ref<HTMLDivElement>) {
  const { children, className, width = 'xl' } = props
  return (
    <HeadlessTooltip.Panel
      className={clsx(widths[width], 'z-50 rounded-md border bg-extra-light px-3 py-2 shadow-rift', className)}
      ref={ref}
    >
      {children}
    </HeadlessTooltip.Panel>
  )
}

function Title(props: PropsWithChildren) {
  const { children } = props
  return <p className="text-sm font-medium text-dark">{children}</p>
}

function Content(props: PropsWithChildren) {
  const { children } = props
  return <p className="text-xs font-normal text-dusk">{children}</p>
}

export const Tooltip = Object.assign(TooltipFn, {
  Trigger: forwardRefWithAs(Trigger),
  Panel: forwardRefWithAs(Panel),
  Title: Title,
  Content: Content,
})
