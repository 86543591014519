import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SettingsFlow, UpdateSettingsFlowBody } from '@ory/client'
import { gridStyle, UserSettingsCard, UserSettingsFlowType } from '@ory/elements'
import { kratos } from '@/config/kratos'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { reportSentryErrorWithEmail } from '@/sentry'

export function KratosForm({ settingsFlowType }: { settingsFlowType: string[] }) {
  const toast = useToast()
  const [flow, setFlow] = useState<SettingsFlow | null>(null)
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const createFlow = useCallback(
    () =>
      kratos
        .createBrowserSettingsFlow()
        .then(({ data: flow }) => {
          setFlow(flow)
        })
        .catch((error) => {
          reportSentryErrorWithEmail('[KRATOS] Settings initializeSelfServiceSettingsFlowForBrowsers failed', {
            extra: {
              error,
            },
          })
          navigate('/settings', { replace: true })
        }),
    [],
  )

  const getFlow = useCallback(
    (flowId: string) =>
      kratos
        .getSettingsFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch((error) => {
          reportSentryErrorWithEmail('[KRATOS] Settings getSelfServiceSettingsFlow failed', {
            extra: {
              error,
            },
          })
          return error
        }),
    [],
  )

  const onSubmit = (body: UpdateSettingsFlowBody) => {
    if (!flow) return navigate('/settings', { replace: true })

    kratos
      .updateSettingsFlow({ flow: flow.id, updateSettingsFlowBody: body })
      .then(({ data: flow }) => {
        setFlow(flow)
        toast.createToast({ message: 'Profile Settings updated.' })
      })
      .catch((error) => {
        reportSentryErrorWithEmail('[KRATOS] Settings submitSelfServiceSettingsFlow failed', {
          extra: {
            error,
          },
        })
        switch (error.response.status) {
          case 400:
            setFlow(error.response.data)
            toast.createToast({ error: true, message: 'Please correct the form.' })
            break
          case 403:
            toast.createToast({ error: true, message: error.response.data.error.reason })
            break
          case 401 | 410:
            return navigate('/settings', { replace: true })
          case 404:
          default:
            setFlow(error.response.data)
        }
      })
  }

  useEffect(() => {
    const flowId = searchParams.get('flow')
    if (flowId) {
      getFlow(flowId).catch(createFlow)
      return
    }
    createFlow()
  }, [])

  return flow ? (
    <div className={gridStyle({ gap: 16 })}>
      {(settingsFlowType as UserSettingsFlowType[]).map((flowType: UserSettingsFlowType, index) => (
        <UserSettingsCard
          key={index}
          flow={flow}
          flowType={flowType}
          includeScripts={true}
          onSubmit={({ body }) => onSubmit(body)}
        />
      ))}
    </div>
  ) : (
    <div className="skeleton h-8 w-16" />
  )
}
