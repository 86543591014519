/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SequenceStatus {
    ACTIVE = 'ACTIVE',
    CATCH_ALL = 'CATCH_ALL',
    INACTIVE = 'INACTIVE',
    INVALID = 'INVALID',
    NOT_INTERESTED = 'NOT_INTERESTED',
    MISSING_FIELDS = 'MISSING_FIELDS',
    SCHEDULED = 'SCHEDULED',
    UNSUBSCRIBED = 'UNSUBSCRIBED',
}
