import { useForm } from 'react-hook-form'
import { Dialog } from '@headlessui/react'
import { useContactListCreate } from '@/api/contact_lists'
import { PostContactList } from '@/api/core'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Input, Spinner, Text, Textarea } from '@/ui'

type CreateListDialogProps = {
  open: boolean
  onClose: () => void
}

export function CreateListDialog(props: CreateListDialogProps) {
  const toast = useToast()
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<PostContactList>()
  const create = useContactListCreate()
  const onClose = () => {
    props.onClose()
    reset()
  }

  const onSubmit = (data: PostContactList) => {
    create.mutate(data, {
      onSuccess: () => {
        onClose()
        reset()
        toast.createToast({ message: 'List created' })
      },
      onError: (err) => {
        toast.createToast({ message: (err as any)?.body?.message || 'failed to create contacts list', error: true })
      },
    })
  }

  return (
    <Dialog open={props.open} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-white/70" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="mx-auto w-full max-w-md rounded-lg border border-light bg-white p-6 shadow-sm">
          <Dialog.Title as="div">
            <Text variant="subtitle">Sync your contacts</Text>
          </Dialog.Title>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-4 flex flex-col gap-2">
            <Input
              type="text"
              error={errors.name}
              registration={register('name', { required: 'Name required', minLength: 1 })}
              placeholder="Name"
            />
            <Textarea
              placeholder="Description"
              registration={register('description')}
              error={errors['description']?.message}
              className="no-scrollbar h-24 resize-none overflow-y-scroll"
            />

            <div className="flex justify-end gap-4 pt-4">
              <Button type="button" variant="basic" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="accent" type="submit" disabled={!isValid || create.isPending}>
                {!create.isPending ? 'Create' : <Spinner />}
              </Button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
