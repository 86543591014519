import { FileWithPath, useDropzone } from 'react-dropzone'
import clsx from 'clsx'
import { X } from '@phosphor-icons/react'
import { USE_FS_ACCESS_API } from '@/config'
import { Button, Text } from '@/ui'
import { ErrorText } from '@/ui/Text/Error'

export type UploadProps = {
  onUpload: (files: File[]) => void
  onCancel: () => void
  uploadError?: Error | null
}

const maxFileSize = 20 * 1024 * 1024 // 20MB

// UpdateV2 is a copy-pasted version of the Update step with additional changes to the UX for
// importing. It is currently behind a feature flag and is not yet rolled out to all users.
export function UploadV2(props: UploadProps) {
  const { getRootProps, getInputProps, fileRejections, isDragActive } = useDropzone({
    onDrop: props.onUpload,
    multiple: false,
    maxSize: maxFileSize,
    accept: { 'text/csv': ['.csv'] },
    useFsAccessApi: USE_FS_ACCESS_API,
  })

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={(file as FileWithPath).path}>
      {(file as FileWithPath).path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ))

  return (
    <>
      <div className="absolute left-8 top-8 cursor-pointer" onClick={props.onCancel}>
        <div className="flex h-9 w-9 items-center justify-center rounded-full border border-light text-medium">
          <X className="h-5 w-5" />
        </div>
      </div>
      <section
        {...getRootProps({
          className: clsx(
            'focus:outline-non flex h-full cursor-pointer appearance-none flex-col rounded-md border border-dashed border-medium',
            isDragActive && 'bg-extra-light',
          ),
        })}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className="m-auto text-center">
            <Text variant="title" className="mt-2 text-lg tracking-wide">
              DROP HERE
            </Text>
            <Text variant="subtext" className="text-xs text-medium">
              {`Only `}
              <span className="ml-[1px] font-medium">CSV</span>
              {` with a max file size of `}
              <span className="font-medium">20 MB</span>
            </Text>
          </div>
        ) : (
          <div className="m-auto flex flex-col gap-y-4 text-center leading-5">
            <Text>Drag & drop or</Text>
            <Button>Select file to import</Button>
            <Text className="text-medium">.csv</Text>
          </div>
        )}

        {props.uploadError?.message && <ErrorText errorMessage={props.uploadError.message} />}
        {fileRejectionItems}
      </section>
    </>
  )
}
