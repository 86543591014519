import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DefaultService, PostPaymentCheckoutSession } from './core'
import { queryKey as onboardingQueryKey } from './onboarding'

export function usePaymentCheckoutSession() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: (url) => {
      if (!url) {
        // it means that customer id was set manually and we should continue to the next step
        queryClient.invalidateQueries({ queryKey: onboardingQueryKey.base() })
      } else {
        window.location.href = url
      }
    },
    mutationFn: (body: PostPaymentCheckoutSession) => DefaultService.postPaymentCheckoutSession(body),
  })
}

export function useCustomerPortal() {
  return useMutation({
    mutationFn: () => DefaultService.getPaymentCustomerPortal(),
  })
}
