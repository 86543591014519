import { ApiError } from '@/api/core'
import { useToast } from '@/providers/Toasts/ToastsProvider'

export const defaultErrorMsg = 'Something went wrong. Please contact with our support.'

export function errhandler(error: ApiError, fallbackMessage: string) {
  const toast = useToast()
  if (error.status >= 400 && error.status < 500) {
    toast.createToast({ error: true, message: error.message || fallbackMessage })
    return
  }

  toast.createToast({ error: true, message: defaultErrorMsg })
}
