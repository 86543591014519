import { useEffect, useMemo, useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import clsx from 'clsx'
import Handlebars from 'handlebars'
import { twMerge } from 'tailwind-merge'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Trash } from '@phosphor-icons/react'
import { useCampaignContactsSample, useCampaignStepAddB } from '@/api/campaigns'
import { Campaign, Contact, EmailThreadMode, MeetingType, SequenceSendMode } from '@/api/core'
import { Sequence } from '@/api/core/models/Sequence'
import { useMeetingTypes } from '@/api/meetings'
import { Dropdown, DropdownOption } from '@/components/Dropdown'
import { SendTestEmailDialog } from '@/containers/Campaigns/SendTestEmailDialog'
import { useDebounceValue } from '@/hooks'
import { pluralize } from '@/lib/strings'
import { yupValidate } from '@/lib/template'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Checkbox, Text, Toolbar } from '@/ui'
import { EmailEditor } from '@/ui/EmailEditor'
import { PreviewButton } from '@/ui/EmailEditor/styled/PreviewButton'
import { ErrorText } from '@/ui/Text/Error'
import { isDraft } from '../utility'
import { ModeSelector } from './ModeSelector'
import { Action } from './reducer'
import './email.css'

Handlebars.registerHelper('raw-helper', function (options) {
  return options.fn()
})

const followupDelay: DropdownOption[] = Array(30)
  .fill('')
  .map((_, index) => ({ label: `${index + 1}`, id: index + 1 }))

type StepProps = {
  step: Sequence
  campaign: Campaign
  index: number
  dispatch: React.Dispatch<Action>
  canDelete: boolean
  hasABTest: boolean
  stepNumber: number
  plainText: string
}

export function Step({ step, campaign, index, dispatch, canDelete, hasABTest, stepNumber, plainText }: StepProps) {
  const { data, isSuccess: campaignContactsSampleSuccessful } = useCampaignContactsSample(campaign.id)
  const { data: meetingTypesData, isSuccess: meetingTypesSuccessful } = useMeetingTypes()

  const dataForPreview = useMemo(() => {
    const previewData = data?.data.data

    data?.data.variables.forEach((variable) => {
      if (previewData && previewData[variable] === undefined) {
        previewData[variable] = ''
      }
    })

    return previewData
  }, [data])

  if (!campaignContactsSampleSuccessful || !meetingTypesSuccessful || !dataForPreview) {
    return null
  }

  return (
    <StepWithData
      step={step}
      stepNumber={stepNumber}
      campaign={campaign}
      index={index}
      plainText={plainText}
      dispatch={dispatch}
      canDelete={canDelete}
      hasABTest={hasABTest}
      dataForPreview={dataForPreview}
      calendarLinks={meetingTypesData.data || []}
    />
  )
}

type StepWithDataProps = StepProps & {
  calendarLinks: MeetingType[]
  dataForPreview: Contact
}

function StepWithData({
  step,
  index,
  dispatch,
  canDelete,
  hasABTest,
  calendarLinks,
  campaign,
  stepNumber,
  dataForPreview,
  plainText,
}: StepWithDataProps) {
  const campaignIsDraft = isDraft(campaign)
  const isStepA = hasABTest && !step.ab_test
  const isStepB = hasABTest && step.ab_test
  const formatDisplayValue = (option: DropdownOption) => `Delay ${pluralize(Number(option.label), 'day')}`
  const isNewThreadStep = EmailThreadMode.NEW === step.email_thread_mode
  const isPhoneCall = step.send_mode === SequenceSendMode.MANUAL_CALL
  const isLinkedinMessage = step.send_mode === SequenceSendMode.LINKEDIN_MESSAGE
  const isManualEmail = step.send_mode === SequenceSendMode.MANUAL_EMAIL
  const showABTestBtn = !isManualEmail && !isLinkedinMessage && !isPhoneCall && !hasABTest && campaignIsDraft
  const addBStep = useCampaignStepAddB(campaign.id)
  const toast = useToast()

  const showNewThreadBtn =
    index !== 0 &&
    !isStepB &&
    campaignIsDraft &&
    (step.send_mode === SequenceSendMode.AUTOMATIC_EMAIL || step.send_mode === SequenceSendMode.MANUAL_EMAIL)

  const [showSendTestEmail, setShowTestEmail] = useState(false)
  const [selectedDelay, setSelectedDelay] = useState(
    followupDelay.find((el) => el.id === step.delay?.days) || followupDelay[0],
  )

  const [, debouncedSubject, setSubject] = useDebounceValue(step.email_subject, { delay: 500 })
  const [, debouncedBody, setBody] = useDebounceValue(step.email_template, { delay: 500 })

  const unsubscribeLink = `<br><hr style="margin-top: 0.25rem; margin-bottom: 0.25rem"><p style="text-align: left;margin: 0"><a target="_blank" rel="noopener noreferrer nofollow" style="text-decoration: none" href="/"><span style="color: #d3d3d3">Unsubscribe</span></a></p>`
  const unsubscribePlainTextLink = `<br/> <br/>Unsubscribe ( ${window.location.protocol + '//' + window.location.host + '/unsubscribe'} )`

  type FormValues = { subject: string; body: string }

  const schema: yup.ObjectSchema<FormValues> = yup.object().shape({
    subject: yup.string().required('required').test({ test: yupValidate }),
    body: yup.string().required('').test({ test: yupValidate }),
  })

  const {
    control,
    formState: { errors },
    trigger,
  } = useForm<{ subject: string; body: string }>({
    defaultValues: { subject: step.email_subject, body: step.email_template },
    mode: 'all',
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    // checking editor errors on load except for new steps
    if (step.email_subject !== '' || step.email_template !== '') {
      trigger()
    }
  }, [])

  const {
    field: { onChange: onSubjectChangeForm },
  } = useController({ control, name: 'subject' })

  const {
    field: { onChange: onBodyChangeForm },
  } = useController({ control, name: 'body' })

  useEffect(() => {
    dispatch({ type: 'change_subject', body: { text: debouncedSubject as string, index } })
  }, [debouncedSubject])

  useEffect(() => {
    dispatch({ type: 'change_body', body: { text: debouncedBody as string, index: index } })
  }, [debouncedBody])

  function handleSubjectChange(value: string) {
    setSubject(value)
    onSubjectChangeForm(value)
  }

  function handleBodyChange(value: string) {
    setBody(value)
    onBodyChangeForm(value)
  }

  function handleDelete() {
    dispatch({ type: 'delete_step', index })
  }

  function handleSelection(selected: DropdownOption) {
    dispatch({ type: 'change_delay', body: { delay: Number(selected.id), index: index } })
    setSelectedDelay(selected)
  }

  function handleThreadModeSelection() {
    dispatch({
      type: 'change_thread_mode',
      body: { index: index, mode: isNewThreadStep ? EmailThreadMode.REPLY : EmailThreadMode.NEW },
    })
  }

  function handleAddABTest() {
    addBStep.mutate(step.id, {
      onSuccess: (step) => {
        dispatch({ type: 'add_ab_step', step: step.data, index })
      },
      onError: (err: unknown) => {
        toast.createToast({ message: (err as any)?.body?.message || "Can't add a/b test", error: true })
      },
    })
  }

  function onModeChange(mode: SequenceSendMode) {
    dispatch({ type: 'change_send_mode', body: { sendMode: mode, index: index } })
  }

  return (
    <>
      {!campaign.permissions.add_remove_sequences.deny ? (
        <Dropdown
          options={followupDelay}
          handleSelection={handleSelection}
          selected={selectedDelay}
          className={clsx('my-2 w-36 ', !isNewThreadStep && 'ml-8', (index === 0 || step.ab_test) && 'hidden')}
          formatDisplayValue={formatDisplayValue}
        />
      ) : (
        <p className={clsx('my-2 w-36 text-sm', !isNewThreadStep && 'ml-8', (index === 0 || step.ab_test) && 'hidden')}>
          {formatDisplayValue({ id: step.delay?.days.toString() || '', label: step.delay?.days.toString() || '' })}
        </p>
      )}

      {!isStepB && (
        <ModeSelector
          disabledSelect={campaign.permissions.add_remove_sequences.deny}
          mode={step.send_mode!}
          onChange={onModeChange}
          className={!isNewThreadStep ? 'ml-8' : ''}
          stepNumber={stepNumber}
        />
      )}

      <section
        className={twMerge(
          'mb-2 mt-0 flex flex-col rounded border border-light bg-white',
          !isNewThreadStep && 'ml-8',
          isStepA && 'mb-0',
          isStepB && 'border-t-0',
        )}
      >
        <SendTestEmailDialog
          isOpen={showSendTestEmail}
          close={() => setShowTestEmail(false)}
          subject={
            step.email_subject === undefined || step.email_subject === '<p></p>' || step.email_subject === ''
              ? 'rift test email'
              : step.email_subject
          }
          body={step.email_template || ''}
          contact={dataForPreview || {}}
          campaignId={campaign.id}
        />
        <div
          className={clsx(
            'no-scrollbar relative grid h-full p-6',
            hasABTest && 'grid-cols-[20px_minmax(0,_1fr)] gap-y-5',
          )}
        >
          {hasABTest && <Text>{isStepA ? 'A' : 'B'}</Text>}
          <div className="flex grow flex-col">
            <div className="flex"></div>
            {!isPhoneCall && (
              <div>
                {/* If it is a reply step the delete button will be by the subject line */}
                {!campaign.permissions.edit_steps.deny && canDelete && (
                  <button
                    className="absolute right-2 top-2"
                    onClick={handleDelete}
                    data-testid={`remove-sequence-button-${index}`}
                  >
                    <Trash className="size-5 text-medium" />
                  </button>
                )}
                <EmailEditor as="div" variables={dataForPreview} calendarLinks={calendarLinks}>
                  {({ preview }) => (
                    <div className="flex grow flex-col gap-2">
                      <div className="flex w-full items-center gap-2">
                        {!isLinkedinMessage &&
                          isNewThreadStep &&
                          (!preview ? (
                            <div className="flex w-full">
                              <div className="w-10/12 ">
                                <div className="flex items-center gap-5">
                                  <Text className="font-medium">Subject</Text>
                                  <EmailEditor.Subject
                                    placeholder="Add a subject..."
                                    className="w-full"
                                    content={step.email_subject}
                                    onChange={(s) => handleSubjectChange(s)}
                                    editable={!campaign.permissions.edit_steps.deny}
                                  />
                                </div>
                                {errors?.subject?.message && step.email_thread_mode === EmailThreadMode.NEW && (
                                  <ErrorText errorMessage={errors?.subject?.message} />
                                )}
                              </div>
                            </div>
                          ) : (
                            <EmailEditor.PreviewSubject />
                          ))}
                      </div>
                      <EmailEditor.Body
                        placeholder="Say hello… Hint: use curly brackets to insert variables, e.g. {{FirstName}}"
                        content={step.email_template}
                        onChange={(html) => handleBodyChange(html)}
                        className="min-h-64"
                        editable={!campaign.permissions.edit_steps.deny}
                        data-testid={`editor-email-body-${index}`}
                      />
                      {preview && (
                        <EmailEditor.PreviewBody
                          className="min-h-64"
                          {...(step.email_template?.indexOf('{{UnsubscribeURL}}') === -1 && {
                            unsubscribeLink: campaign.plainTextOnly ? unsubscribePlainTextLink : unsubscribeLink,
                          })}
                          {...(campaign.plainTextOnly && { plainText: plainText })}
                        />
                      )}
                      {errors?.body?.message && <ErrorText errorMessage={errors?.body?.message} />}
                      <div className="border-t">
                        <Toolbar>
                          <Toolbar.Signature />
                          <Toolbar.CalendarLinks />
                          <Toolbar.Variable />
                          <Toolbar.Emoji />
                          <Toolbar.Separator />
                          {!isLinkedinMessage && <Toolbar.Unsubscribe />}
                          <Toolbar.Divider />
                          <Toolbar.Palette />
                          <Toolbar.Bold />
                          <Toolbar.Underline />
                          <Toolbar.Italic />
                          <Toolbar.Strikethrough />
                          <Toolbar.Divider />
                          <Toolbar.AlignLeft />
                          <Toolbar.AlignCenter />
                          <Toolbar.AlignRight />
                          <Toolbar.AlignJustify />
                          <Toolbar.Divider />
                          <Toolbar.ListOrdered />
                          <Toolbar.ListUnordered />
                          <Toolbar.Divider />
                          <Toolbar.Link />
                          <Toolbar.Unlink />
                          <Toolbar.Image />
                          <Toolbar.Divider />
                          <Toolbar.FormatClear />
                        </Toolbar>
                      </div>
                      <div className="flex items-center">
                        <div className="flex gap-4">
                          <PreviewButton className="w-fit" variant="basic" />

                          {!isLinkedinMessage && preview && (
                            <Button variant="basic" className="w-fit" onClick={() => setShowTestEmail(true)}>
                              Send test email
                            </Button>
                          )}
                        </div>
                        <div
                          className={clsx('ml-auto flex w-max items-center gap-2 py-2', !showNewThreadBtn && 'hidden')}
                        >
                          <Checkbox checked={isNewThreadStep} onChange={handleThreadModeSelection} id="new-thread" />
                          <label htmlFor="new-thread" className="text-sm text-medium">
                            New Thread
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </EmailEditor>
              </div>
            )}
          </div>
        </div>

        {showABTestBtn && (
          <div className="border-t border-light px-8 py-6">
            <Button variant="text" className="p-0 text-sm" onClick={handleAddABTest}>
              Add a/b test
            </Button>
          </div>
        )}
      </section>
    </>
  )
}
