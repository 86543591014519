/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum HubspotIntegrationStatus {
    NOT_CONNECTED = 'NOT_CONNECTED',
    CONNECTED = 'CONNECTED',
    DISCONNECTED = 'DISCONNECTED',
    RECONNECT_NEEDED = 'RECONNECT_NEEDED',
}
