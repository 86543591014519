import { useCallback, useState } from 'react'

export function useCopyToClipboard() {
  const [text, setText] = useState<string | null>(null)

  const copy = useCallback(async (text: string) => {
    if (!navigator?.clipboard) {
      return false
    }

    try {
      await navigator.clipboard.writeText(text)
      setText(text)
      return true
    } catch (error) {
      setText(null)
      return false
    }
  }, [])

  return { text, copy }
}
