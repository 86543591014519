import { useState } from 'react'
import { allTimezones, ITimezoneOption, useTimezoneSelect } from 'react-timezone-select'
import { useCampaignUpdate } from '@/api/campaigns'
import { Campaign } from '@/api/core'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Listbox } from '@/ui'

export function TimezoneSelect({ campaign }: { campaign: Campaign }) {
  const updateCampaign = useCampaignUpdate(campaign.id)
  const { options, parseTimezone } = useTimezoneSelect({ timezones: allTimezones })
  const [value, setValue] = useState(parseTimezone(campaign.timezone))
  const toast = useToast()
  const onChange = (timezone: ITimezoneOption) => {
    updateCampaign.mutate(
      { timezone: timezone.value },
      {
        onSuccess: () => setValue(timezone),
        onError: (err) => {
          toast.createToast({ message: (err as any)?.body?.message || 'Cannot update the timezone', error: true })
        },
      },
    )
  }
  return (
    <Listbox value={value} onChange={onChange} disabled={campaign.permissions.change_schedule.deny}>
      {({ disabled }) => (
        <>
          {disabled ? (
            <p className="text-sm">
              <span className="truncate">{value.altName}</span>
            </p>
          ) : (
            <Listbox.Button variant="text" className="h-fit max-w-44 cursor-default whitespace-nowrap px-0 py-0">
              <span className="truncate">{value.altName}</span>
            </Listbox.Button>
          )}
          <Listbox.Options className="right-0">
            {options.map((o) => (
              <Listbox.Option key={o.value} value={o}>
                {o.label}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </>
      )}
    </Listbox>
  )
}
