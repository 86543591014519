/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { PatchCampaign } from '@/api/core'

export type PatchCampaignForm = Omit<PatchCampaign, 'day_time_from' | 'day_time_to' | 'start_date'> & {
  day_time_from?: Date
  day_time_to?: Date
  start_date?: Date
}

export enum DisplayStatusName {
  LAUNCHED = 'Active',
  LAUNCHING = 'Active',
  RESCHEDULING = 'Active',
  CREATED = 'Draft',
  VERIFYING = 'Draft',
  READY_TO_LAUNCH = 'Draft',
  STOPPED = 'Not active',
  FINISHED = 'Not active',
}

export enum CampaignTypes {
  COLD_OUTREACH = 'Cold outreach',
  REENGAGEMENT = 'Reengagement',
  NURTURE = 'Nurture',
  EVENT = 'Event',
}
