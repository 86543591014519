import React from 'react'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { useDebounce } from 'use-debounce'
import { ArrowUpRight, CaretDown } from '@phosphor-icons/react'
import { CaretUp } from '@phosphor-icons/react/dist/ssr'
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { useContactLists } from '@/api/contact_lists'
import { ContactList } from '@/api/core'
import { useObserveSticky } from '@/hooks/useObserveSticky'
import { Unix } from '@/lib/date'
import { ErrorInfo } from '@/ui/ErrorInfo'
import { SearchBar } from '@/ui/SearchBar'
import { ContactListDetails } from './ContactListDetails'

export function ListView() {
  const [search, setSearch] = useState('')
  const [searchD] = useDebounce(search, 200)
  const { data, status } = useContactLists()

  if (status === 'error') {
    return <ErrorInfo />
  }

  return (
    <>
      <div className="mb-4 w-96">
        <SearchBar value={search} onChange={setSearch} />
      </div>
      {status === 'pending' ? (
        <>
          <div className="skeleton mb-2 h-48 w-full" />
          <span className="sr-only">Loading...</span>
        </>
      ) : (
        <ListTable data={data.data} globalFilter={searchD} setGlobalFilter={setSearch} />
      )}
    </>
  )
}

type ListTableProps = {
  data: ContactList[]
  globalFilter: string
  setGlobalFilter: (value: string) => void
}

export function ListTable(props: ListTableProps) {
  const navigate = useNavigate()
  const { data, globalFilter, setGlobalFilter } = props
  const [sorting, setSorting] = useState<SortingState>([{ id: 'name', desc: false }])
  const [openContactListDetails, setOpenContactListDetails] = useState(false)
  const [curContactListId, setCurContactListId] = useState<string | null>(null)

  const columnHelper = createColumnHelper<ContactList>()
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        id: 'name',
        header: () => <span className="cursor-pointer">Name</span>,
        cell: (info) => (
          <p className="space-x-2">
            <button onClick={() => navigate(info.row.original.id)}>{info.row.original.name}</button>
            {info.row.original.hubspotListUrl && (
              <span className="rounded-sm bg-[#f57722] px-1.5 py-0.5 text-white">
                <a href={info.row.original.hubspotListUrl} target="_blank" rel="noreferrer">
                  HubSpot <ArrowUpRight className="inline size-4" />
                </a>
              </span>
            )}
          </p>
        ),
      }),
      columnHelper.accessor((row) => row.description, {
        id: 'description',
        header: () => <span className="cursor-pointer">Description</span>,
        cell: (info) => <span>{info.row.original.description}</span>,
      }),
      columnHelper.accessor((row) => row.created_at, {
        id: 'created_at',
        header: () => <span className="cursor-pointer">Date added</span>,
        cell: (info) => (
          <span className="flex justify-start">
            {Unix(info.row.original.created_at).toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })}
          </span>
        ),
      }),
    ],
    [],
  ) as ColumnDef<ContactList, any>[]

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      sorting,
    },

    enableSorting: true,
    onSortingChange: setSorting,

    enableFilters: true,
    onGlobalFilterChange: setGlobalFilter,

    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  const [tableHeaderRef, tableHeaderColored] = useObserveSticky<HTMLTableRowElement>()

  return (
    <>
      {openContactListDetails && curContactListId !== null && (
        <ContactListDetails
          open={openContactListDetails}
          onClose={() => {
            setOpenContactListDetails(false)
            setCurContactListId(null)
          }}
          contactListId={curContactListId}
        />
      )}
      <table className="min-w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} ref={tableHeaderRef}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    scope="col"
                    className={clsx(
                      'sticky top-0 table-cell border-y border-gray-200 px-3 py-2 text-left text-sm font-semibold text-primary',
                      tableHeaderColored && 'bg-gray-100',
                    )}
                  >
                    <div
                      aria-hidden="true"
                      onClick={header.column.getToggleSortingHandler()}
                      className="flex cursor-pointer select-none items-center"
                    >
                      <div className="relative">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        <div className="pointer-events-none absolute inset-y-0 -right-4 flex items-center">
                          {header.column.getIsSorted() === 'asc' ? (
                            <CaretUp className="h-3 w-3 text-black" />
                          ) : header.column.getIsSorted() === 'desc' ? (
                            <CaretDown className="h-3 w-3 text-black" />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="whitespace-wrap border-y border-gray-200 transition-colors hover:bg-gray-50"
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className="px-3 py-3 text-sm font-medium">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })
          ) : (
            <tr className="justify-center border-gray-300">
              <td
                colSpan={table.getHeaderGroups()[0].headers.length}
                className="w-full py-4 text-center text-sm font-medium text-primary"
              >
                No Contacts Lists
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}
