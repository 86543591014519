import { ElementType, forwardRef, Fragment, Ref, useLayoutEffect, useMemo, useReducer, useRef } from 'react'
import { Props } from '@headlessui/react/dist/types'
import { Editor, EditorContent } from '@tiptap/react'
import { useEvent } from '@/hooks/useEvent'
import { mergeRefs, uirender } from '../render'
import { CalendarLink } from './CalendarLink'
import { EmailEditorActionContext, EmailEditorDataContext, useActions, useData } from './Context'
import { useBodyEditor, useSubjectEditor } from './hooks'
import { PreviewBody, PreviewSubject } from './Preview'
import { ActionType, reducer } from './reducer'

export const DEFAULT_EMAILEDITOR_TAG = Fragment
export interface EmailEditorRenderPropArg {
  preview: boolean
}
export type EmailEditorProps<TTag extends ElementType> = Props<
  TTag,
  EmailEditorRenderPropArg,
  never,
  {
    variables?: Record<string, string> | string[]
    calendarLinks?: CalendarLink[]
  }
>

function EmailEditorFn<TTag extends ElementType>(props: EmailEditorProps<TTag>) {
  const { calendarLinks = [], variables, ...theirProps } = props

  const [state, dispatch] = useReducer(reducer, {
    editorMode: 'subject',
    preview: false,
    editor: null,
    subjectEditor: null,
    bodyEditor: null,
    calendarLinks: calendarLinks,
    variables:
      variables === undefined
        ? {}
        : Array.isArray(variables)
          ? variables.reduce((acc, item) => ({ ...acc, [item]: '' }), {})
          : variables,
  })

  const registerBodyEditor = useEvent((editor: Editor) => {
    dispatch({ type: ActionType.RegisterBodyEditor, editor: editor })
  })
  const registerSubjectEditor = useEvent((editor: Editor) => {
    dispatch({ type: ActionType.RegisterSubjectEditor, editor: editor })
  })
  const changeEditor = useEvent((mode: 'subject' | 'body') => {
    dispatch({ type: ActionType.ChangeEditorMode, editorMode: mode })
  })
  const togglePreview = useEvent(() => {
    dispatch({ type: ActionType.TogglePreview })
  })

  const actions = useMemo(() => ({ registerBodyEditor, registerSubjectEditor, changeEditor, togglePreview }), [])
  const slot = { preview: state.preview }
  const ourProps = {}

  return (
    <EmailEditorActionContext.Provider value={actions}>
      <EmailEditorDataContext.Provider value={state}>
        {uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_EMAILEDITOR_TAG })}
      </EmailEditorDataContext.Provider>
    </EmailEditorActionContext.Provider>
  )
}

type SubjectEditorProps = {
  content?: string
  placeholder?: string
  className?: string
  onChange?: (html: string) => void
  onFocus?: () => void
  onBlur?: () => void
  editable?: boolean
}

function SubjectEditor(props: SubjectEditorProps, ref: Ref<HTMLDivElement>) {
  const { content = '', placeholder, onChange, onFocus, onBlur, className, editable = true } = props
  const actions = useActions()
  const data = useData()

  const subjectEditor = useSubjectEditor({
    content: content,
    placeholder: placeholder,
    variables: Object.keys(data.variables),
    onChange: onChange,
    onFocus: () => {
      actions.changeEditor('subject')
      onFocus?.()
    },
    onBlur: onBlur,
  })
  useLayoutEffect(() => {
    if (subjectEditor === null) return
    actions.registerSubjectEditor(subjectEditor)
  }, [subjectEditor?.state])

  useLayoutEffect(() => {
    if (subjectEditor === null) return
    subjectEditor.setEditable(editable)
  }, [subjectEditor, editable])

  if (data.preview) return null
  return <EditorContent ref={ref} editor={subjectEditor} className={className} />
}

type BodyEditorProps = {
  content?: string
  className?: string
  placeholder?: string
  onChange?: (html: string, text: string) => void
  onFocus?: () => void
  onBlur?: () => void
  autoFocus?: boolean
  editable?: boolean
  'data-testid'?: string
}

function BodyEditor(props: BodyEditorProps, ref: Ref<HTMLDivElement>) {
  const { content = '', placeholder, onChange, onFocus, onBlur, autoFocus, className, editable = true } = props
  const actions = useActions()
  const data = useData()
  const ourRef = useRef<HTMLDivElement>(null)
  const editorRef = mergeRefs([ourRef, ref])

  const bodyEditor = useBodyEditor({
    content: content,
    placeholder: placeholder,
    variables: Object.keys(data.variables),
    onChange: onChange,
    onFocus: () => {
      actions.changeEditor('body')
      onFocus?.()
    },
    onBlur: onBlur,
    'data-testid': props['data-testid'],
  })

  useLayoutEffect(() => {
    if (ourRef.current === null || bodyEditor === null) return
    actions.registerBodyEditor(bodyEditor)
    if (autoFocus) bodyEditor.commands.focus()
  }, [ourRef.current, bodyEditor === null])

  useLayoutEffect(() => {
    if (bodyEditor === null) return
    bodyEditor.setEditable(editable)
  }, [bodyEditor, editable])

  if (data.preview) return null
  return <EditorContent ref={editorRef} editor={bodyEditor} className={className} />
}

export const EmailEditor = Object.assign(EmailEditorFn, {
  Body: forwardRef(BodyEditor),
  Subject: forwardRef(SubjectEditor),
  PreviewSubject: PreviewSubject,
  PreviewBody: PreviewBody,
})
