import { useEffect, useState } from 'react'

// Use the useCounterInterval hook to create a counter that increments every second.
// The hook should return the current count and a function to reset the counter.
export function useCounterInterval() {
  const [time, setTime] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setTime((time) => time + 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [])
  const minutes = Math.floor(time / 60)
  const seconds = time % 60

  const reset = () => setTime(0)

  return {
    minutes,
    seconds,
    reset,
  }
}
