import { forwardRef, InputHTMLAttributes, Ref } from 'react'
import { twMerge } from 'tailwind-merge'

function CheckboxFn(props: InputHTMLAttributes<HTMLInputElement>, ref: Ref<HTMLInputElement>) {
  const { className, ...rest } = props
  return (
    <input
      ref={ref}
      type="checkbox"
      className={twMerge(
        // base
        'background-origin-border flex size-4.5 flex-shrink-0 cursor-pointer select-none appearance-none rounded border border-light',

        // transition
        'transition-all duration-100 ease-in',

        // checked
        'checked:border-accent checked:bg-accent checked:bg-[url("../public/check.svg")] checked:bg-[length:100%_100%] checked:bg-center',

        // active
        'active:duration-200 active:ease-out',

        // disabled
        'disabled:bg-extra-light disabled:checked:bg-accent disabled:checked:opacity-30',

        className,
      )}
      {...rest}
    />
  )
}
export const Checkbox = forwardRef(CheckboxFn)
