import { Navigate, NavLink, useParams } from 'react-router-dom'
import { useSenderEmail } from '@/api'
import { useSenderEmailConnect, useSenderEmailDisconnect, useSenderEmailsRevalidateAuthn } from '@/api/emails'
import { StatisticsUsage } from '@/containers/Email/StatisticsUsage'
import { FeatureFlag } from '@/containers/FeatureFlag'
import { handleHealthChecksStatuseIcon } from '@/pages/Emails/helper'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Tooltip } from '@/ui'

export function Details() {
  const toast = useToast()
  const { senderEmailId } = useParams() as { senderEmailId: string }
  const { data, status } = useSenderEmail(senderEmailId)
  const senderConnect = useSenderEmailConnect()
  const senderDisconnect = useSenderEmailDisconnect()
  const revalidateStatues = useSenderEmailsRevalidateAuthn()

  const onSenderConnect = async () => {
    if (!data?.data?.id) return
    senderConnect.mutate(data.data.id, {
      onSuccess: (data) => {
        window.location.href = data.data.redirect_url
      },
      onError: (err: unknown) => {
        toast.createToast({ message: (err as any)?.body?.message || 'Failed to connect sender', error: true })
      },
    })
  }

  const onRevlidateStatues = async () => {
    revalidateStatues.mutate(undefined)
  }

  const onSenderDisconnect = () => {
    if (!data?.data?.id) return
    senderDisconnect.mutate(data.data.id, {
      onError: (err: unknown) => {
        toast.createToast({ message: (err as any)?.body?.message || 'Failed to disconnect sender', error: true })
      },
    })
  }

  const healtChecksText =
    'is not set up correctly, please consult this document <br/> (link: <a style="text-decoration-line: underline;color: #449DD1" href="https://getrift.notion.site/How-to-set-up-your-email-accounts-to-send-cold-outbound-9f1b8ba1af9d4ccf82ff1a7b56c19a04" target="_blank">document</a>) for info on how to set it up.'
  const healtChecksErrorText = 'There was an error on our side, please rerun health checks.'
  const healtChecks = [
    {
      status: data?.data?.spf_status,
      text: 'SPF',
      description: 'SPF helps you prove that you are sending emails from a list of verified IP addresses.',
      descs: {
        on: 'SPF is set up correctly. No action required.',
        off: `SPF ${healtChecksText}`,
        error: healtChecksErrorText,
      },
    },
    {
      status: data?.data?.dkim_status,
      text: 'DKIM',
      description: 'DKIM acts as a passport, allowing you to verify who you are.',
      descs: {
        on: 'DKIM is set up correctly. No action required.',
        off: `DKIM ${healtChecksText}`,
        error: healtChecksErrorText,
      },
    },
    {
      status: data?.data?.dmarc_status,
      text: 'DMARC',
      description: 'Sets a policy for how to handle unauthorized usage of your emails.',
      descs: {
        on: 'DMARC is set up correctly. No action required.',
        off: `DMARC ${healtChecksText}`,
        error: healtChecksErrorText,
      },
    },
    {
      status: data?.data?.email_app_status,
      text: 'IMAP',
      description: 'Shows whether rift is able to connect to your email via IMAP.',
      descs: {
        on: 'IMAP is turned on. No action required.',
        off: `IMAP app password is invalid`,
        error: healtChecksErrorText,
      },
    },
    {
      status: data?.data?.mx_status,
      text: 'MX',
      description: 'MX records are used to route emails to the correct mail server.',
      descs: {
        on: 'MX records are set up correctly. No action required.',
        off: `MX ${healtChecksText}`,
        error: healtChecksErrorText,
      },
    },
  ]

  if (status === 'error') {
    return <Navigate to="/emails" />
  }

  if (status === 'pending') {
    return <div className="skelton h-32 w-full" />
  }

  return (
    <>
      <div className="overflow-hidden bg-white px-4">
        <div className="flex items-center justify-between py-4">
          <h3 className="text-lg font-medium leading-6 text-primary">Email Account Information</h3>
          <div className="flex gap-2">
            <FeatureFlag>
              <Button type="button" variant="basic" onClick={onRevlidateStatues}>
                Re-validate statues
              </Button>
            </FeatureFlag>
            {!data?.data.oauthConnected ? (
              <FeatureFlag>
                <Button type="button" variant="basic" onClick={onSenderConnect}>
                  Connect
                </Button>
              </FeatureFlag>
            ) : (
              <>
                <FeatureFlag>
                  <Button type="button" variant="basic" onClick={onSenderDisconnect}>
                    Disconnect
                  </Button>
                </FeatureFlag>
              </>
            )}
            <NavLink to="edit">
              <Button type="button" variant="basic">
                Settings
              </Button>
            </NavLink>
          </div>
        </div>

        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-semibold text-gray-500">Email Account</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.data.email}</dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-semibold text-gray-500">First Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.data.first_name}</dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-semibold text-gray-500">Last Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.data.last_name}</dd>
            </div>
            <FeatureFlag>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                <dt className="text-sm font-semibold text-gray-500">Provider</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.data.provider}</dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                <dt className="text-sm font-semibold text-gray-500">Custom Domain</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.data.custom_domain}</dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                <dt className="text-sm font-semibold text-gray-500">Forwarding addresses</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {data?.data.forwardingAddresses.join(', ')}
                </dd>
              </div>
            </FeatureFlag>
          </dl>
        </div>
      </div>

      <div className="flex h-full w-full flex-col gap-4 bg-white px-4 md:mt-8 lg:flex-row">
        <StatisticsUsage
          dailySend={data.data.daily_sent || 0}
          dailyCapacity={data.data.daily_capacity || 0}
          monthlyCapacity={data.data.monthly_capacity || 0}
          monthlySent={data.data.monthly_sent || 0}
          readyToUseInDays={data.data.days_left_to_warmed || 0}
          className="flex w-full flex-col items-center justify-between gap-2 bg-white p-4 lg:w-2/6"
        />
      </div>

      <div className="bg-white p-4">
        <div className="flex w-full flex-col lg:flex-row">
          <div className="inline-block min-w-full align-middle">
            <table className="w-full">
              <thead className="bg-background">
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 bg-opacity-75 p-4 text-left text-sm font-semibold text-primary lg:table-cell"
                  >
                    Health checks
                  </th>

                  <th
                    scope="col"
                    className="sticky top-0 z-10 bg-opacity-75 p-4 text-left text-sm font-semibold text-primary lg:table-cell"
                  >
                    Description
                  </th>

                  <th
                    scope="col"
                    className="sticky top-0 z-10 bg-opacity-75 p-4 text-left text-sm font-semibold text-primary lg:table-cell"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {healtChecks.map((item, index) => (
                  <tr key={index} className="border-b border-gray-300">
                    <td className="flex whitespace-nowrap py-4 text-sm font-medium">
                      <Tooltip>
                        <Tooltip.Trigger className="w-full">
                          <div className="justify-start px-4 text-left text-sm text-primary">{item.text}</div>
                        </Tooltip.Trigger>
                        <Tooltip.Panel>
                          <Tooltip.Content>
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.status === 'OFF'
                                    ? item.descs.off
                                    : item.status === 'ERROR'
                                      ? item.descs.error
                                      : item.descs.on,
                              }}
                            />
                          </Tooltip.Content>
                        </Tooltip.Panel>
                      </Tooltip>
                    </td>
                    <td className="whitespace-nowrap px-4 text-sm font-medium">{item.description || '-'}</td>
                    <td className="px-4">
                      <Tooltip>
                        <Tooltip.Trigger className="w-full">
                          <div className="justify-center">{(() => handleHealthChecksStatuseIcon(item.status))()}</div>
                        </Tooltip.Trigger>
                        <Tooltip.Panel>
                          <Tooltip.Content>
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.status === 'OFF'
                                    ? item.descs.off
                                    : item.status === 'ERROR'
                                      ? item.descs.error
                                      : item.descs.on,
                              }}
                            />
                          </Tooltip.Content>
                        </Tooltip.Panel>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
