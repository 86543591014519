import { PropsWithChildren } from 'react'
import { useManualStepsInfo } from '@/api/manual_step'
import { useUserPermissions } from '@/containers/ComponentDisplay'
import { useFlags } from '@/containers/FeatureFlag/useFlags'
import { Nav, Text } from '@/ui'

export function Inbox({ children }: PropsWithChildren) {
  const { data: manualStepsInfo } = useManualStepsInfo()
  const { showFeatureFlag } = useFlags()
  const { showRecommendations } = useUserPermissions()

  const { emailsToWrite, linkedinMessagesToWrite, callsToMake, repliesToWrite, recommendations } =
    manualStepsInfo?.data ?? {}

  const maybeRecommenationsTab = showRecommendations
    ? [{ label: 'Recommendations', url: '/inbox/recommendations', count: recommendations }]
    : []

  const navItems: { label: string; count?: number; featureFlag?: boolean; url: string }[] = [
    ...maybeRecommenationsTab,
    { label: 'Replies', url: '/inbox/replies', count: repliesToWrite },
    { label: 'Email steps', count: emailsToWrite, url: '/inbox/emails' },
    { label: 'Linkedin steps', count: linkedinMessagesToWrite, url: '/inbox/linkedin-messages' },
    { label: 'Call steps', count: callsToMake, url: '/inbox/calls' },
    { label: 'Done', url: '/inbox/done' },
  ]

  return (
    <section className="m-auto max-w-2xl pb-5">
      {(emailsToWrite || 0) + (linkedinMessagesToWrite || 0) + (callsToMake || 0) === 0 && (
        <hgroup className="flex flex-col gap-2 py-6">
          <Text variant="title" className="text-dark">
            Welcome to inbox
          </Text>
          <Text className="text-dusk">
            Inbox is a place for all of your tasks and notifications, helping you focus on the most important things to
            do.
          </Text>
        </hgroup>
      )}
      <Nav className="p-0">
        <Nav.Group direction="row" className="gap-2">
          {navItems.map(
            ({ label, featureFlag, count, url }) =>
              (!featureFlag || showFeatureFlag) && (
                <Nav.LinkItem key={label} to={url} className={'flex rounded-full px-2 py-2 font-medium'}>
                  {label} {!!count && <Nav.NotificationSubtext>{count}</Nav.NotificationSubtext>}
                </Nav.LinkItem>
              ),
          )}
        </Nav.Group>
      </Nav>
      {children}
    </section>
  )
}
