import { useCallback, useEffect, useState } from 'react'
import { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { flatten } from 'flat'
import { SetValueKey, SetValueType } from '../types/react-hook-form'

export type FormLocalStorageConfig<TFieldValues extends FieldValues = FieldValues> = {
  watch: UseFormWatch<TFieldValues>
  setValue: UseFormSetValue<TFieldValues>
}

export type ClearStorageOptions = {
  destroy?: boolean
}

export type ClearStorage = (options?: ClearStorageOptions) => void

export function useFormLocalStorage<TFieldValues extends FieldValues = FieldValues>(
  name: string,
  config: FormLocalStorageConfig<TFieldValues>,
) {
  const { watch, setValue } = config
  const storage = localStorage
  const [destroy, setDestroy] = useState(false)
  const fieldValues = watch()

  const clearStorage = useCallback((options?: ClearStorageOptions) => {
    const { destroy = true } = options || {}
    setDestroy(destroy)
    storage.removeItem(name)
  }, [])

  useEffect(() => {
    const str = storage.getItem(name)
    if (!str) return

    const obj = JSON.parse(str)
    const values = flatten<TFieldValues, SetValueType>(obj)
    Object.entries(values).forEach(([key, value]) => {
      setValue(key as SetValueKey, value)
    })
  }, [name])

  useEffect(() => {
    // do not save to local storage if destroy is true
    if (destroy) {
      storage.removeItem(name)
      return
    }

    if (Object.entries(fieldValues).length) {
      storage.setItem(name, JSON.stringify(fieldValues))
    }
  }, [fieldValues])

  return {
    clearFormLocalStorage: clearStorage,
  }
}
