import { useQuery } from '@tanstack/react-query'
import { DefaultService } from '@/api/core'

export const queryKey = {
  base: (phantom: boolean | undefined) => ['members', phantom ? 'phantom' : 'real'] as const,
  memberOwnedContactsCount: (memberId: string) => ['members', memberId, 'owner', 'contacts', 'count'] as const,
}

export function useMembers(options?: { phantom?: boolean }) {
  return useQuery({
    queryKey: queryKey.base(options?.phantom),
    queryFn: () => DefaultService.getMembers(options?.phantom),
  })
}

export function useMemberOwnedContactsCount(memberId: string) {
  return useQuery({
    queryKey: queryKey.memberOwnedContactsCount(memberId),
    queryFn: () => DefaultService.getMembersOwnerContactsCount(memberId),
  })
}
