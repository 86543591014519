import { WarningCircle } from '@phosphor-icons/react'
import { Button } from '@/ui/Button/v2'

export type ErrorProps = {
  onCancel: () => void
  error: Error | string | null
}

export function Error(props: ErrorProps) {
  const { onCancel, error } = props
  const errorMessage =
    typeof error === 'string' ? error : (error as any)?.body?.message || error?.message || 'Unknown error'
  return (
    <>
      <div className="flex grow flex-col items-center justify-center">
        <WarningCircle className="h-8 w-8 text-red-500" />
        <span className="my-2 text-lg font-semibold">Error while uploading contacts</span>
        <span className="text-sm">{errorMessage}</span>
      </div>
      <div>
        <Button variant="basic" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </>
  )
}
