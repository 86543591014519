import { useChameleon, useChameleonCreate, useChameleonDelete } from '@/api'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Card, DangerButton, Text } from '@/ui'
import { SearchInput } from './SearchInput'

export function ChameleonSearch() {
  const { data, status } = useChameleon()
  const toast = useToast()
  const chameleonCreate = useChameleonCreate()
  const chameleonDelete = useChameleonDelete()

  const onChameleonCreate = (email: string) => {
    chameleonCreate.mutate(email, {
      onSuccess: () => {
        toast.createToast({ message: 'User identity changed' })
      },
    })
  }
  const onChameleonDelete = () => {
    chameleonDelete.mutate(undefined, {
      onSuccess: () => {
        toast.createToast({ message: 'User identity changed' })
      },
    })
  }

  if (status === 'error') {
    return <Text variant="subtitle">Error loading chameleon data</Text>
  }

  if (status === 'pending') {
    return null
  }

  return (
    <Card>
      <Text variant="subtitle">Chameleon {data.data.email && 'as'}</Text>
      {data.data.email ? (
        <div className="flex items-center justify-between">
          <p>
            {data.data.email} ({data.data.role})
          </p>
          <DangerButton variant="basic" onClick={onChameleonDelete}>
            Reset identity
          </DangerButton>
        </div>
      ) : (
        <div className="w-96">
          <SearchInput onCreate={onChameleonCreate} />
        </div>
      )}
    </Card>
  )
}
