import { Control, useWatch } from 'react-hook-form'
import { MeetingCategory, MeetingMember, PatchMeetingType, PostMeetingType } from '@/api/core'
import { Text } from '@/ui'
import { MembersSelect } from './UserSelect'

type RoundRobinAssignmentProps = {
  control: Control<PostMeetingType | PatchMeetingType, any>
  members: MeetingMember[]
  clearUsersValue: () => void
}

export function RoundRobinAssignment(props: RoundRobinAssignmentProps) {
  const category = useWatch({ name: 'category' })
  if (category !== MeetingCategory.ROUND_ROBIN) {
    props.clearUsersValue()
    return null
  }
  return (
    <>
      <Text className="mb-2 mt-6 font-medium">Set meeting assignment to round robin</Text>
      <Text variant="subtext">
        This option will automatically assign the next available sales
        <br />
        person to take the call. It’s good for distributing incoming leads
      </Text>
      <Text className="my-4 font-medium">Team</Text>
      <MembersSelect members={props.members} control={props.control} />
    </>
  )
}
