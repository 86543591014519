import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import * as yup from 'yup'
import { Disclosure } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { CaretUp } from '@phosphor-icons/react/dist/ssr'
import { PaymentPriceType, PostPaymentCheckoutSession } from '@/api/core'
import { usePaymentCheckoutSession } from '@/api/payment'
import { PaymentPriceTypeLabel, PaymentPriceTypeLabels } from '@/api/text/payment'
import { FeatureFlag } from '@/containers/FeatureFlag'
import { ButtonLoading, Input, Select } from '@/ui'

export function Payment() {
  const schema: yup.ObjectSchema<PostPaymentCheckoutSession> = yup.object().shape({
    price: yup.mixed<PaymentPriceType>().oneOf(Object.values(PaymentPriceType)).required(),
    stripeCustomerId: yup.string().matches(/^cus_.*/, { excludeEmptyString: true }),
    email: yup.string().email(),
    firstName: yup.string(),
    lastName: yup.string(),
    phoneNumber: yup.string(),
  })

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<PostPaymentCheckoutSession>({
    resolver: yupResolver(schema),
    defaultValues: {
      price: PaymentPriceType.STARTER_MONTHLY,
    },
  })

  const price = watch('price')

  const useCheckout = usePaymentCheckoutSession()
  const onCheckout = (data: PostPaymentCheckoutSession) => useCheckout.mutate(data)

  return (
    <form>
      <div>
        <h1 className="mb-6 text-lg font-medium">Choose a plan that's right for you</h1>
        <p>
          We've partnered with Stripe in order to provide you with a<br />
          secure plan selection and checkout process
        </p>

        <Select
          defaultValue={null}
          value={PaymentPriceTypeLabels.find((s) => s.value === price) as PaymentPriceTypeLabel}
          onChange={(selected) => setValue('price', selected.value)}
          options={PaymentPriceTypeLabels}
        />

        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full py-2 text-left text-sm font-medium hover:text-rift-blue-500">
                <span>Different billing address?</span>
                <CaretUp className={clsx('ml-0.5 mt-0.5 h-4 w-4', open && 'rotate-180 transform')} />
              </Disclosure.Button>
              <Disclosure.Panel className="w-full pt-4">
                <Input registration={register('email')} error={errors.email} type="email" label="Email" />
                <Input registration={register('firstName')} error={errors.firstName} label="First name" />
                <Input registration={register('lastName')} error={errors.lastName} label="Last name" />
                <Input registration={register('phoneNumber')} error={errors.phoneNumber} label="Phone number" />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <FeatureFlag>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full py-2 text-left text-sm font-medium hover:text-rift-blue-500">
                  <div>
                    <p>Already paid?</p>
                    <p className="text-xs">set stripe customer id</p>
                  </div>
                  <CaretUp className={clsx('ml-0.5 mt-0.5 h-4 w-4', open && 'rotate-180 transform')} />
                </Disclosure.Button>
                <Disclosure.Panel className="w-full pt-4">
                  <Input
                    registration={register('stripeCustomerId')}
                    error={errors.stripeCustomerId}
                    label="Stripe Customer Id"
                  />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </FeatureFlag>

        <span className={clsx('text-alert', !useCheckout.isError && 'invisible')}>
          Failed to checkout {(useCheckout.error as any)?.body?.message}
        </span>

        <div className="my-4 mt-8 flex justify-end">
          <ButtonLoading
            type="button"
            variant="accent"
            onClick={() => {
              handleSubmit(onCheckout)()
              return false
            }}
            isLoading={useCheckout.isPending}
          >
            Checkout
          </ButtonLoading>
        </div>

        <p className="text-sm">You'll be brought back here when checkout is complete</p>
      </div>
    </form>
  )
}
