import { Link } from 'react-router-dom'
import { FileWithPath, useDropzone } from 'react-dropzone'
import clsx from 'clsx'
import { CloudArrowUp, FileArrowDown } from '@phosphor-icons/react'
import { USE_FS_ACCESS_API } from '@/config'
import { Button, MouseTrap, Text } from '@/ui'
import { ErrorText } from '@/ui/Text/Error'

export type UploadProps = {
  onUpload: (files: File[]) => void
  onCancel: () => void
  uploadError?: Error | null
}

const maxFileSize = 20 * 1024 * 1024 // 20MB

export function Upload(props: UploadProps) {
  const { getRootProps, getInputProps, fileRejections, isDragActive } = useDropzone({
    onDrop: props.onUpload,
    multiple: false,
    maxSize: maxFileSize,
    accept: { 'text/csv': ['.csv'] },
    useFsAccessApi: USE_FS_ACCESS_API,
  })

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={(file as FileWithPath).path}>
      {(file as FileWithPath).path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ))

  return (
    <section
      {...getRootProps({
        className: clsx(
          'focus:outline-non flex h-full cursor-pointer appearance-none flex-col rounded-md border border-dashed border-medium',
          isDragActive && 'bg-extra-light',
        ),
      })}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <div className="m-auto text-center">
          <FileArrowDown className="m-auto size-20 stroke-[0.5px] text-light" />
          <Text variant="title" className="mt-2 text-lg tracking-wide">
            DROP HERE
          </Text>
          <Text variant="subtext" className="text-xs text-medium">
            {`Only `}
            <span className="ml-[1px] font-medium">CSV</span>
            {` with a max file size of `}
            <span className="font-medium">20 MB</span>
          </Text>
        </div>
      ) : (
        <div className="m-auto text-center">
          <CloudArrowUp className="m-auto w-20 stroke-[0.5px] text-light" />
          <Text variant="title">Drag & drop</Text>
          <span className="m-auto flex w-fit items-baseline">
            <Text variant="title">or</Text>
            <Button variant="text" className="px-1 text-lg">
              browse
            </Button>
            <Text variant="title">your files</Text>
          </span>
          <span className="flex  items-baseline">
            <Text variant="subtext" className="text-xs text-medium">
              Upload a csv with your contact data.
            </Text>
            <MouseTrap>
              <Link
                to="/rift_sample_csv.csv"
                target="_blank"
                download="rift_sample_csv.csv"
                className="ml-[1px] text-xs text-medium underline"
              >
                Sample CSV
              </Link>
            </MouseTrap>
          </span>
        </div>
      )}

      {props.uploadError?.message && <ErrorText errorMessage={props.uploadError.message} />}
      {fileRejectionItems}
    </section>
  )
}
