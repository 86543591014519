import { Fragment, useMemo, useState } from 'react'
import clsx from 'clsx'
import { Dialog, Transition } from '@headlessui/react'
import { useContactLists } from '@/api/contact_lists'
import { SearchBar } from '@/ui/SearchBar'
import { CreateListDialog } from './CreateListDialog'

type AddToListDialogProps = {
  open: boolean
  onClose: () => void
  onConfirm: (contactListId: string) => void
}

export function AddToListDialog(props: AddToListDialogProps) {
  const { open, onClose, onConfirm } = props
  const { data } = useContactLists()

  const [search, setSearch] = useState('')
  const [selectedIndex, setSelectedIndex] = useState<string | null>(null)
  const onSearch = (q: string) => {
    setSearch(q)
    setSelectedIndex(null)
  }
  const onSubmit = () => {
    if (selectedIndex === null) return
    onConfirm(selectedIndex as string)
    onClose()
    setSelectedIndex(null)
    setSearch('')
  }

  const filteredData = useMemo(() => {
    return (data?.data || []).filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
  }, [data, search])
  const [openCreateListDialog, setOpenCreateListDialog] = useState(false)

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={props.onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Add to list
                  </Dialog.Title>

                  <div className="mt-4 flex gap-2">
                    <div className="flex-1">
                      <SearchBar value={search} onChange={onSearch} />
                    </div>
                    <CreateListDialog open={openCreateListDialog} onClose={() => setOpenCreateListDialog(false)} />
                    <button type="button" className="btn-v2 px-5 py-1" onClick={() => setOpenCreateListDialog(true)}>
                      New list
                    </button>
                  </div>

                  {filteredData.map((item) => (
                    <button
                      key={item.id}
                      className={clsx(
                        'mt-1 flex w-full cursor-pointer p-1 hover:bg-gray-50',
                        item.id === selectedIndex && 'bg-gray-100 text-rift-blue-600',
                      )}
                      onClick={() => setSelectedIndex(item.id)}
                    >
                      {item.name}
                    </button>
                  ))}

                  <button
                    type="button"
                    disabled={selectedIndex === null}
                    className="btn-v2 btn-v2-wide float-right mt-2 text-rift-blue-600"
                    onClick={onSubmit}
                  >
                    Add
                  </button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
