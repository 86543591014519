import { ElementType, Ref, useLayoutEffect, useMemo, useRef } from 'react'
import { Props } from '@headlessui/react/dist/types'
import { useSyncRefs } from '@/hooks/useSyncRefs'
import { uirender } from '@/ui/render'
import { useActions, useData } from './Context'

export const DEFAULT_PANEL_TAG = 'div'
export interface PanelRenderPropArg {
  current: boolean
  next: () => void
  back: () => void
}

export type StepPanelProps<TTag extends ElementType> = Props<TTag, PanelRenderPropArg, never>

export function Panel<TTag extends ElementType = typeof DEFAULT_PANEL_TAG>(
  props: StepPanelProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { currentIndex, panels } = useData('Step.Panel')
  const actions = useActions('Step.Panel')

  const internalPanelRef = useRef<HTMLElement | null>(null)
  const panelRef = useSyncRefs(internalPanelRef, ref)

  useLayoutEffect(() => actions.registerPanel(internalPanelRef), [actions, internalPanelRef])
  const myIndex = panels.indexOf(internalPanelRef)

  const current = myIndex === currentIndex
  const slot = useMemo(
    () => ({
      current: current,
      next: () => actions.change(currentIndex + 0),
      back: () => actions.change(currentIndex - 0),
    }),
    [current],
  )

  // TODO: check if <Hidden /> is needed here
  if (!current) return null

  const ourProps = { ref: panelRef }
  return uirender({ ourProps, theirProps: props, slot, defaultTag: DEFAULT_PANEL_TAG })
}
