import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FieldPath, FormProvider, useForm, useFormContext } from 'react-hook-form'
import clsx from 'clsx'
import { format } from 'date-fns'
import * as yup from 'yup'
import { Disclosure } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { ArrowUpRight, CaretRight, WarningCircle, X } from '@phosphor-icons/react'
import { useSalesforceConnection, useSalesforceContactDetails } from '@/api'
import { useContact, useContactDelete, useContactUpdate } from '@/api/contacts'
import { ContactStage, ContactV2, CrmObject, HubspotEmail, PatchContact, SalesforceTask } from '@/api/core'
import { useHubspotContact } from '@/api/hubspot'
import { ContactStageLabels } from '@/api/text'
import { ContactFieldToLabelName } from '@/api/text/contact_fields'
import { ContactStageLabel, ContactStageToText } from '@/api/text/contact_stages'
import { FeatureFlag } from '@/containers/FeatureFlag'
import { useFlags } from '@/containers/FeatureFlag/useFlags'
import { Unix } from '@/lib/date'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Drawer, OpacityTransition, Select, Spinner, Text } from '@/ui'
import { ConfirmationDialog } from './ConfirmationDialog'
import { ContactActivityFeed } from './ContactActivityFeed'
import { EmailStatusBadge } from './EmailStatusBadge'
import { KVList } from './KVList'

type ContactDetailsProps = {
  onClose: () => void
  contactId: string
  missingFields?: string[]
}

export function ContactDetails(props: ContactDetailsProps) {
  const { onClose, contactId, missingFields = [] } = props
  const { data, status } = useContact(contactId)
  const { data: salesforceIntegrationData, status: salesforceIntegrationStatus } = useSalesforceConnection()
  const remove = useContactDelete()
  const [editing, setEditing] = useState(false)
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false)
  const { showFeatureFlag } = useFlags()
  const toast = useToast()
  useEffect(() => {
    if (status === 'error') {
      toast.createToast({ message: 'Failed to load contact', error: true })

      onClose()
    }
  }, [status])

  useEffect(() => {
    if (salesforceIntegrationStatus === 'error') {
      toast.createToast({ message: 'Failed to load Salesforce connection info', error: true })
    }
  }, [salesforceIntegrationStatus])

  if (status === 'error') {
    return null
  }

  const onDelete = () => {
    remove.mutate(contactId, {
      onSuccess: () => {
        onClose()
        toast.createToast({ message: 'Contact deleted' })
      },
      onError: (err) =>
        toast.createToast({ message: (err as any)?.body?.message || 'failed to delete contact', error: true }),
    })
  }

  return (
    <Drawer open={true} onClose={onClose}>
      {status === 'pending' ? (
        <div className="skeleton mb-2 h-48 w-full"></div>
      ) : (
        <>
          <ConfirmationDialog
            open={deleteConfirmationDialog}
            onClose={() => setDeleteConfirmationDialog(false)}
            onConfirm={onDelete}
            message={`You are about to delete contact ${data.data.email}.`}
          />
          {editing ? (
            <Edit onClose={onClose} data={data.data} stopEdit={() => setEditing(false)} />
          ) : (
            <>
              <div className="flex justify-between">
                <button onClick={onClose} className="p-0" aria-label="close">
                  <X className="h-5 w-5 text-medium" />
                </button>
                <div className="flex gap-1">
                  <Button variant="basic" onClick={() => setDeleteConfirmationDialog(true)}>
                    {!remove.isPending ? 'Delete' : <Spinner />}
                  </Button>
                  <Button variant="basic" onClick={() => setEditing(true)}>
                    Edit
                  </Button>
                </div>
              </div>

              <Text className="pb-4 pt-6 font-medium text-dusk">Details</Text>
              <KVList
                data={{
                  Stage: (
                    <>
                      {ContactStageToText[data.data.stage]}
                      {data.data.stage === ContactStage.OOO && data.data.ooo_return_date && (
                        <> until {format(new Date(data.data.ooo_return_date), 'LLL dd, yyyy')}</>
                      )}
                    </>
                  ),
                  'First name': data.data.first_name,
                  'Last name': data.data.last_name,
                  Email: <>{data.data.email}</>,
                  'Email status': EmailStatusBadge(data.data.email_status),
                  Company:
                    showFeatureFlag && data.data.account_id ? (
                      <Link className="text-rift-blue-600" to={`/contacts/accounts/${data.data.account_id}`}>
                        {data.data.company}
                      </Link>
                    ) : (
                      data.data.company
                    ),
                  Phone: data.data.phone,
                  Title: data.data.title,
                  Country: data.data.country,
                  State: data.data.state,
                  City: data.data.city,
                  LinkedIn: (
                    <Link to={data.data.linkedin} target="_blank" rel="noopener noreferrer" className="underline">
                      {data.data.linkedin}
                    </Link>
                  ),
                  Timezone: data.data.timezone,
                  Body: data.data.body,
                  'Last contacted': data.data.last_contacted_at
                    ? Unix(data.data.last_contacted_at).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                      })
                    : '',
                }}
                errors={missingFields.map(ContactFieldToLabelName)}
              />

              {Object.keys(data.data.custom_fields).length > 0 && (
                <>
                  <Text className="pb-4 pt-6 font-medium text-dusk">Custom fields</Text>
                  <KVList data={data.data.custom_fields || {}} />
                  {Object.keys(data.data.custom_fields).length === 0 && <p className="text-sm text-gray-500">—</p>}
                </>
              )}
              <div>
                <ContactActivityFeed contactId={contactId} />
              </div>
            </>
          )}
          {salesforceIntegrationData?.data.connected && (
            <div>
              <SalesforceContactDetails contactId={contactId} />
            </div>
          )}
          <FeatureFlag>
            <HubspotContactDetails contactId={contactId} />
          </FeatureFlag>
        </>
      )}
    </Drawer>
  )
}

type EditProps = {
  data: ContactV2
  onClose: () => void
  stopEdit: () => void
}

function Edit(props: EditProps) {
  const { data, onClose, stopEdit } = props
  const update = useContactUpdate()
  const toast = useToast()

  const schema: yup.ObjectSchema<PatchContact> = yup.object().shape({
    stage: yup.mixed<ContactStage>().oneOf(Object.values(ContactStage)),
    email: yup.string().email().trim(),
    first_name: yup.string().trim(),
    last_name: yup.string().trim(),
    company: yup.string().trim(),
    phone: yup.string().trim(),
    title: yup.string().trim(),
    country: yup.string().trim(),
    state: yup.string().trim(),
    city: yup.string().trim(),
    body: yup.string().trim(),
    /* Linkedin regex:
    (?: ... )? is a non-capturing group with a ? quantifier, making the entire LinkedIn profile URL part optional.
    (?:https?:\/\/)? makes the http:// or https:// part optional.
    (?:www\.)? makes the www. part optional.
    (?:in|company) matches either "in" or "company" part of the LinkedIn URL.
    [^\s\/]+ matches one or more characters that are not whitespace or "/" in the username part. 
    \/? makes the "/" at the end of the URL optional.*/
    linkedin: yup
      .string()
      .trim()
      .matches(/^(?:(?:https?:\/\/)?(?:www\.)?linkedin\.com\/(?:in|company)\/[^\s/]+\/?)?$/, 'Invalid Linkedin URL')
      .default(''),
    timezone: yup
      .string()
      .nullable()
      .trim()
      .transform((v) => (v === '' || v === null ? null : v)),
    custom_fields: yup.object().shape({}),
  })
  const methods = useForm<PatchContact>({
    mode: 'onChange',
    defaultValues: {
      stage: data.stage,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      company: data.company,
      phone: data.phone,
      title: data.title,
      linkedin: data.linkedin,
      timezone: data.timezone,
      custom_fields: data.custom_fields,
    },
    resolver: yupResolver(schema),
  })

  const stageValue = methods.watch('stage')
  useEffect(() => {
    methods.register('stage')
  }, [methods.register])

  const onSubmit = (body: PatchContact) => {
    update.mutate(
      { ...body, contactId: data.id },
      {
        onSuccess: () => stopEdit(),
        onError: (err) =>
          toast.createToast({ message: (err as any)?.body?.message || 'failed to update contacts list', error: true }),
      },
    )
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex justify-between">
          <X className="icon-hover size-6" aria-hidden="true" onClick={onClose} />
          <div className="flex gap-1">
            <Button type="button" variant="basic" onClick={stopEdit}>
              Cancel
            </Button>
            <Button variant="basic" disabled={!methods.formState.isValid}>
              {!update.isPending ? 'Save' : <Spinner />}
            </Button>
          </div>
        </div>

        <div className="mt-4 flex flex-col gap-y-4">
          <div className="flex items-center">
            <div className="w-24 min-w-[6rem] text-sm text-gray-500">Stage</div>
            <div className="relative w-full">
              <Select
                options={ContactStageLabels}
                defaultValue={null}
                value={ContactStageLabels.find((s) => s.value === stageValue) as ContactStageLabel}
                onChange={(selected) => methods.setValue('stage', selected.value)}
                className="w-32 rounded-lg border-light"
                dropdownClassName="absolute z-10 mt-1 w-40 overflow-auto bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm"
              />
            </div>
          </div>
          <Input label="First Name" name="first_name" />
          <Input label="Last Name" name="last_name" />
          <Input label="Email" name="email" required />
          <Input label="Company" name="company" />
          <Input label="Phone" name="phone" />
          <Input label="Title" name="title" />
          <Input label="Country" name="country" />
          <Input label="State" name="state" />
          <Input label="City" name="city" />
          <Input label="LinkedIn" name="linkedin" />
          <Input label="Timezone" name="timezone" />

          {Object.keys(data.custom_fields).length > 0 && (
            <>
              <p className="mt-2 text-sm">Custom Fields</p>
              {Object.keys(data.custom_fields).map((key) => (
                <Input key={key} label={key} name={`custom_fields.${key}`} />
              ))}
            </>
          )}
        </div>
      </form>
    </FormProvider>
  )
}

type InputProps = {
  name: FieldPath<PatchContact>
  label: string
  required?: boolean
}

function Input(props: InputProps) {
  const { name, label, required } = props
  const { register, getFieldState } = useFormContext<PatchContact>()
  const fieldState = getFieldState(name)
  return (
    <div className="flex items-center">
      <div className="w-24 min-w-[6rem] text-sm text-gray-500">{label}</div>
      <div className="relative w-full">
        <input
          type="text"
          className="block w-full appearance-none rounded-lg border border-gray-300 p-1 text-sm placeholder-gray-400 shadow-sm transition-colors hover:bg-gray-50 focus:border-primary focus:outline-none focus:ring-primary"
          {...register(name, required ? { required: name + ' required', minLength: 1 } : {})}
        />
        {fieldState.invalid && fieldState.error && (
          <WarningCircle
            className="absolute right-0 top-1/2 h-4 w-4 -translate-x-1/2 -translate-y-1/2 text-red-500"
            aria-hidden="true"
          />
        )}
      </div>
    </div>
  )
}

function SalesforceContactDetails({ contactId }: { contactId: string }) {
  const contact = useSalesforceContactDetails(contactId)
  const data = contact.data?.data
  if (contact.status === 'pending' || !data) {
    return <div className="skeleton mb-2 h-48 w-full"></div>
  }

  return (
    <div>
      <Text className="pb-2 pt-6 font-medium text-dusk">Salesforce data</Text>
      {!data?.lead && !data?.contact && !data?.account && <div>Missing salesforce data</div>}
      {data.lead && <CrmObjectDetails name="Lead" object={data.lead} />}
      {data.contact && <CrmObjectDetails name="Contact" object={data.contact} />}
      {data.account && <CrmObjectDetails name="Account" object={data.account} />}
      {data.tasks && <SalesforceTasks tasks={data.tasks} />}
    </div>
  )
}

function HubspotContactDetails({ contactId }: { contactId: string }) {
  const contact = useHubspotContact(contactId)
  const data = contact.data?.data
  if (contact.status !== 'success' || !data) {
    return <div className="skeleton mb-2 h-48 w-full"></div>
  }

  return (
    <div>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex flex-row items-center">
              <CaretRight className={clsx('ml-auto h-4 w-4 text-medium', open && 'rotate-90 transform')} />
              <Text variant="subtitle">Hubspot data</Text>
            </Disclosure.Button>
            <OpacityTransition>
              <Disclosure.Panel>
                {!data.contact && !data.emails?.length && <div>Missing hubspot data</div>}
                {data.contact && <CrmObjectDetails name="Contact" object={data.contact} />}
                {data.emails && <HubspotEmailsDetails emails={data.emails} />}
              </Disclosure.Panel>
            </OpacityTransition>
          </>
        )}
      </Disclosure>
    </div>
  )
}

function CrmObjectDetails({ name, object }: { name: string; object: CrmObject }) {
  return (
    <div>
      <Link to={object.url} target="_blank" className="flex flex-row">
        <Text className="font-medium">{name}</Text>
        <ArrowUpRight className="size-4 cursor-pointer text-dusk" aria-hidden="true" />
      </Link>
      <FeatureFlag>
        <KVList data={object.data} />
      </FeatureFlag>
    </div>
  )
}

function SalesforceTasks({ tasks }: { tasks: SalesforceTask[] }) {
  return (
    <div>
      <Text variant="subtitle" className="text-sm">
        Tasks
      </Text>
      <div>
        {tasks.map((task, idx) => (
          <div key={idx} className="flex flex-col gap-1">
            <div className="flex justify-between">
              <Text className="text-dusk">{task.type}</Text>
              <Text className="text-medium">{task.date}</Text>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

function HubspotEmailsDetails({ emails }: { emails: HubspotEmail[] }) {
  return (
    <div className="flex flex-col gap-6 pt-6">
      {emails.map((email, idx) => (
        <div key={idx} className="flex flex-col gap-1">
          <div className="justify-between">
            <Text className="font-medium text-dusk">Email message</Text>
            <Text className="text-medium">From: {email.from}</Text>
            <Text className="text-dusk">To: {email.to}</Text>
          </div>
          <div className="flex max-h-[120px] justify-between overflow-auto">
            <KVList data={email.data} />
          </div>
        </div>
      ))}
    </div>
  )
}
