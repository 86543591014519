import { MemberFeatureFlags } from '@/api/core'

export type MemberFeatureFlag = keyof MemberFeatureFlags

export const MemberFeatureFlagToText: Record<MemberFeatureFlag, string> = {
  salesforceIntegration: 'Salesforce Integration',
  hubspotIntegration: 'Hubspot Integration',
  sequenceV2: 'Sequence V2',
  sequenceLabels: 'Sequence Labels',
  sequenceSchedule: 'Sequence Schedule',
  meeting: 'Meeting',
  dialer: 'Dialer',
  importV2: 'Import V2',
  recommendations: 'Recommendations',
  writingAssistant: 'Writing Assistant',
  smtpReplyToHeader: 'SMTP Reply-To Header',
  googleInbox: 'Google Inbox',
}

export const MemberFeatureFlagLabels: { label: string; value: MemberFeatureFlag }[] = Object.entries(
  MemberFeatureFlagToText,
).map(([value, label]) => ({ value: value as MemberFeatureFlag, label: label }))
