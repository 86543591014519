import Papa from 'papaparse'
import { findBestMatch } from '@/lib/strings'

export const customFieldRegExp = new RegExp('^[A-Z][a-zA-Z0-9_]*$')
const EmailKey = 'Email'
const knownFields: Array<keyof ContactObject> = [
  EmailKey,
  'Company',
  'Title',
  'FirstName',
  'LastName',
  'Phone',
  'Linkedin',
  'Body',
  'Timezone',
  'Country',
  'State',
  'City',
  'OwnerEmail',
]

export type header = unskippedHeader | skippedHeader | customFieldHeader

export type unskippedHeader = {
  from: string
  to: keyof ContactObject
  type: 'standard'
}

export type skippedHeader = {
  from: string
  type: 'skip'
}

export type customFieldHeader = {
  from: string
  to: string
  type: 'custom'
}

export type contact = {
  data: Record<string, string>
  invalid: boolean
}

export type FieldFixes = Partial<Record<keyof ContactObject, Array<Transformation>>>

export type Transformation = { from: string; to: string; accepted: boolean }

export type State = {
  filename?: string
  headers: header[]
  contacts: contact[]
  fieldCleanings: FieldFixes
}

export type ContactObject = {
  Email: string
  FirstName: string
  LastName: string
  Company: string
  Phone: string
  Title: string
  Country: string
  State: string
  City: string
  Linkedin: string
  Timezone: string | null
  Body: string
  OwnerEmail: string
  CustomFields: Record<string, string>
}

export const initState: State = {
  headers: [],
  contacts: [],
  fieldCleanings: {},
}

export function errorMessage(state: State): string | undefined {
  if (!hasEmailHeader(state)) {
    return 'No "Email" header found in CSV file.'
  } else if (hasInvalidFields(state)) {
    return 'Custom field names must start with a capital letter and only have letters, numbers and underscores.'
  } else if (hasDuplicateMappings(state)) {
    return 'Two fields from the file map to the same rift field'
  }
}

function hasInvalidFields(state: State) {
  return state.headers.some((h) => h.type === 'custom' && !customFieldRegExp.test(h.to))
}

export type Action =
  | { type: 'set_state'; state: State }
  | { type: 'map_header'; headerNo: number; toHeader: keyof ContactObject }
  | { type: 'custom_header'; headerNo: number; toHeader: string }
  | { type: 'skip_header'; headerNo: number }
  | { type: 'toggle_skip_header'; headerNo: number }
  | { type: 'check_fields'; field: keyof ContactObject; fieldCleanings: { from: string; to: string }[] }
  | { type: 'toggle_substitution'; field: keyof ContactObject; index: number }
  | { type: 'skip_field_substitutions'; field: keyof ContactObject }
  | { type: 'change_to'; field: keyof ContactObject; index: number; newValue: string }

function hasEmailHeader(state: State) {
  return state.headers.some((h) => h.type === 'standard' && h.to === EmailKey)
}

function hasDuplicateMappings(state: State) {
  return state.headers.some((h1) =>
    state.headers.some((h2) => h2 !== h1 && h2.type !== 'skip' && h1.type !== 'skip' && h2.to === h1.to),
  )
}

const duplicativeDataFields: Array<keyof ContactObject> = ['FirstName', 'LastName']

export type DuplicativeData = Array<{ numContacts: number; csvValue: string; csvHeader: string }>
export type MaybeDuplicativeData = DuplicativeData | null

export function duplicativeDataCheck(state: State): MaybeDuplicativeData {
  const result: DuplicativeData = []
  duplicativeDataFields.forEach((field) => {
    const toHeader = state.headers.find((hdr) => hdr.type !== 'skip' && hdr.to === field)
    if (toHeader) {
      const data = state.contacts.map((c) => c.data[toHeader.from])
      const tally = data.reduce(
        (acc, datum) => {
          if (datum !== '') {
            acc[datum] ||= 0
            acc[datum] += 1
          }
          return acc
        },
        {} as Record<string, number>,
      )
      Object.keys(tally).forEach((k) => {
        if (k.match(/^(-|–|—| |[([]?\s*no[ _-–—]name\s*[)\]]?)$/i) !== null) {
          result.push({ numContacts: tally[k], csvValue: k, csvHeader: toHeader.from })
        } else if (tally[k] > 0.1 * state.contacts.length) {
          result.push({ numContacts: tally[k], csvValue: k, csvHeader: toHeader.from })
        }
      })
    }
  })
  if (result.length === 0) {
    return null
  }
  return result
}

export function stdHeadersToSelect(state: State) {
  return knownFields.filter((field) => !state.headers.find((v) => v.type !== 'skip' && v.to === field))
}

export function isInvalid(hdr: header) {
  return hdr.type === 'custom' && !customFieldRegExp.test(hdr.to)
}

export function reducer(state: State, action: Action) {
  let hdr: header
  switch (action.type) {
    case 'set_state':
      return action.state
    case 'skip_header':
      hdr = state.headers[action.headerNo]
      state.headers[action.headerNo] = { from: hdr.from, type: 'skip' }

      return
    case 'toggle_skip_header':
      hdr = state.headers[action.headerNo]
      if (hdr.type === 'skip') {
        const {
          bestMatch: { target: bestMatchHeader },
        } = findBestMatch(hdr.from, stdHeadersToSelect(state))
        state.headers[action.headerNo] = { from: hdr.from, type: 'standard', to: bestMatchHeader }
      } else {
        state.headers[action.headerNo] = { from: hdr.from, type: 'skip' }
      }

      return
    case 'custom_header':
      hdr = state.headers[action.headerNo]

      state.headers[action.headerNo] = {
        ...hdr,
        to: action.toHeader,
        type: 'custom',
      }

      return
    case 'map_header':
      hdr = state.headers[action.headerNo]

      state.headers[action.headerNo] = { ...hdr, to: action.toHeader, type: 'standard' }

      return
    case 'check_fields':
      state.fieldCleanings[action.field] = action.fieldCleanings.map((fc) => ({ ...fc, accepted: true }))

      return
    case 'toggle_substitution':
      const fieldChanges = state.fieldCleanings[action.field]
      if (fieldChanges === undefined) {
        return
      }
      const substitution = fieldChanges[action.index]
      if (!substitution) {
        return
      }
      fieldChanges[action.index] = { ...substitution, accepted: !substitution.accepted }

      return
    case 'skip_field_substitutions':
      const allFieldChanges = state.fieldCleanings[action.field]
      if (allFieldChanges === undefined) {
        return
      }
      state.fieldCleanings[action.field] = allFieldChanges.map((fc) => ({ ...fc, accepted: false }))

      return
    case 'change_to':
      const fieldChanges2 = state.fieldCleanings[action.field]
      if (fieldChanges2 === undefined) {
        return
      }
      const substitution2 = fieldChanges2[action.index]
      if (!substitution2) {
        return
      }
      fieldChanges2[action.index] = { ...substitution2, to: action.newValue }

      return
  }
}

// Very much taken from https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
function b64DecodeUnicode(str: string) {
  function base64ToBytes(base64: string) {
    const binString = atob(base64)
    return Uint8Array.from(binString, (m) => m.codePointAt(0) as number)
  }
  return new TextDecoder().decode(base64ToBytes(str))
}

export function readData(file: string, filename: string): State {
  try {
    const decodedValue = b64DecodeUnicode(file)
    const result = Papa.parse<Record<string, string>>(decodedValue, {
      header: true,
      skipEmptyLines: true,
    })

    if (result.errors.length > 0) {
      if (result.errors[0].row) {
        throw new Error(`Row ${result.errors[0].row}: ${result.errors[0].message}`)
      }
      throw new Error(`${result.errors[0].message}`)
    }

    if (result.data.length === 0) {
      throw new Error(`Empty file`)
    }

    if (result.meta.fields === undefined) {
      throw new Error(`Could not find any headers`)
    }

    const contacts = result.data.map((contact) => {
      return {
        data: contact,
        invalid: false,
      } as contact
    })

    const fileHeaders = result.meta.fields

    const headers = fileHeaders.map((header) => {
      return {
        from: header,
        type: 'skip',
      }
    }) as header[]

    for (const field of knownFields) {
      const idx = fileHeaders.findIndex((f) => f.toLocaleLowerCase() === field.toLowerCase())
      if (idx > -1) {
        const headerIdx = headers.findIndex((h) => h.from === fileHeaders[idx])
        fileHeaders.splice(idx, 1)
        headers[headerIdx] = { from: headers[headerIdx].from, to: field, type: 'standard' }
        continue
      }

      const c = findBestMatch(field, fileHeaders).bestMatch
      if (c && c.rating > 0.5) {
        const idx = fileHeaders.indexOf(c.target)
        const headerIdx = headers.findIndex((h) => h.from === fileHeaders[idx])
        fileHeaders.splice(idx, 1)
        headers[headerIdx] = { from: headers[headerIdx].from, to: field, type: 'standard' }
      }
    }

    // sort headers based on known fields
    headers.sort((a, b) => {
      if (a.type === 'standard' && b.type === 'standard') {
        return knownFields.indexOf(a.to as keyof ContactObject) - knownFields.indexOf(b.to as keyof ContactObject)
      }
      if (a.type === 'standard') {
        return -1
      }
      if (b.type === 'standard') {
        return 1
      }
      return a.from.localeCompare(b.from)
    })

    return {
      filename,
      headers,
      contacts,
      fieldCleanings: {},
    }
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(`Could not read a file: ${e.message}`)
    } else {
      throw new Error(`Could not read a file: ${e}`)
    }
  }
}

export function encodeToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    if (file) {
      const fileReader = new FileReader()

      fileReader.addEventListener(
        'load',
        () => {
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          resolve(fileReader.result.split(',')[1])
        },
        false,
      )

      fileReader.addEventListener('error', reject, false)
      fileReader.readAsDataURL(file)
    } else {
      reject()
    }
  })
}
