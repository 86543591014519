import { DayPicker, DayPickerSingleProps } from 'react-day-picker'
import clsx from 'clsx'
import { Popover } from '@headlessui/react'
import { useCampaignUpdate } from '@/api/campaigns'
import { Campaign } from '@/api/core'
import { WeekdayToNumber } from '@/api/text/weekday'
import { dateFormat, dateOnlyRFC3999Format, parseDate } from '@/lib/date'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button } from '@/ui/Button/v2'
import { OpacityTransition } from '@/ui/Transition/OpacityTransition'

export function StartDateSection({ campaign }: { campaign: Campaign }) {
  const updateCampaign = useCampaignUpdate(campaign.id)
  const toast = useToast()
  function handleDateSelection(date: Date | undefined) {
    if (date) {
      updateCampaign.mutate(
        { start_date: dateOnlyRFC3999Format(date) },
        {
          onError: (err: unknown) => {
            toast.createToast({ message: (err as any)?.body?.message || "Can't update step", error: true })
          },
        },
      )
    }
  }

  return (
    <section className="py-3">
      <label htmlFor="date-selection" className="text-sm font-medium text-dusk">
        Start date
      </label>

      <div id="date-selection" className="py-2">
        <InputDatePicker campaign={campaign} setDate={handleDateSelection} />
      </div>
    </section>
  )
}

// there is almost exactly the same code in src/containers/settings/NoSendDates
// think about better api and making a shared component
type InputDatePickerProps = {
  campaign: Campaign
  setDate: (date: Date | undefined) => void
}

function InputDatePicker(props: InputDatePickerProps) {
  const { campaign, setDate } = props

  // get an array of disabled days and pass it to react-day-picker
  const days = campaign.weekdays.map((day) => WeekdayToNumber[day])
  const disableDays = Object.values(WeekdayToNumber).filter((day) => !days.includes(day))

  const disabled = campaign.permissions.change_schedule.deny
  return (
    <>
      <Popover className="relative">
        <Popover.Button
          as={Button}
          variant="text"
          className={clsx('h-0 p-0 text-sm', disabled && 'font-normal text-dark hover:text-dark disabled:opacity-100')}
          disabled={disabled}
        >
          {campaign.start_date ? dateFormat(parseDate(campaign.start_date)) : ''}
        </Popover.Button>

        <OpacityTransition>
          <Popover.Panel className="absolute right-0 z-50 mt-2 rounded-lg bg-extra-light shadow-lg ring-1 ring-gray-300">
            {({ close }) => (
              <StyledDayPicker
                mode="single"
                onSelect={(d) => {
                  close()
                  setDate(d)
                }}
                disabled={{ dayOfWeek: disableDays }}
                selected={campaign.start_date ? new Date(campaign.start_date) : undefined}
              />
            )}
          </Popover.Panel>
        </OpacityTransition>
      </Popover>
    </>
  )
}

function StyledDayPicker(props: DayPickerSingleProps) {
  return (
    <DayPicker
      {...props}
      fromDate={new Date()}
      fixedWeeks
      className="right-0 rounded-lg bg-extra-light p-5"
      modifiersClassNames={{
        selected: 'bg-accent text-white',
      }}
    />
  )
}
