import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Check } from '@phosphor-icons/react'
import { ManualStepEmailItemStatus } from '@/api/core'
import { useMainInboxes } from '@/api/inbox'
import { useManualStepsReplies, useManualStepsReplyDone } from '@/api/manual_step'
import { timeFromNow } from '@/lib/date'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Banner, Button, Text } from '@/ui'
import { AllSteps } from '../AllSteps'
import { Finished } from '../Finished'
import { AllStepsSkeleton } from '../Skeletons'

export function Replies() {
  const navigate = useNavigate()
  const toast = useToast()
  const { data, status } = useManualStepsReplies()
  const { data: googleMailInbox, status: googleMailInboxStatus } = useMainInboxes()
  const doneReply = useManualStepsReplyDone()
  const [showGoogleMailBanner, setShowGoogleMailBanner] = useState(!localStorage.getItem('Connect-Google-Mail-Replies'))

  if (status !== 'success' || googleMailInboxStatus !== 'success') {
    return <AllStepsSkeleton />
  }

  const hideGoogleMailBanner = () => {
    localStorage.setItem('Connect-Google-Mail-Replies', 'true')
    setShowGoogleMailBanner(false)
  }

  const onDone = (id: string) => {
    doneReply.mutate(id, {
      onSuccess: () => {
        toast.createToast({ message: `Reply marked done` })
      },
      onError: (error) => {
        toast.createToast({ message: (error as any)?.body?.message || 'Failed to mark reply done', error: true })
      },
    })
  }
  return (
    <section>
      {data.data.length > 0 ? (
        <AllSteps>
          {showGoogleMailBanner && googleMailInbox.data === null && (
            <Banner severity="info" className="m-2" onClose={hideGoogleMailBanner}>
              <Banner.Title>Connect Google Mail</Banner.Title>
              <Text>
                Connect to Google Mail to reply to lead responses with your primary email address directly in rift
                without switching to your email client
              </Text>
              <Button
                className="mt-4 border border-dark bg-light"
                onClick={() => navigate('/settings/linked-accounts')}
              >
                Connect
              </Button>
            </Banner>
          )}
          <AllSteps.Group className="py-2">
            {data?.data.map(({ from, subject, sentAt, id, type }) => (
              <AllSteps.Item key={id} className="group flex">
                <Link
                  to={`/inbox/replies/${id}`}
                  className="grid w-full cursor-default grid-cols-[168px_1fr_80px] gap-6"
                >
                  <Text variant="text" className="truncate font-medium text-dark">
                    {type === ManualStepEmailItemStatus.DRAFT && (
                      <>
                        <span className="font-medium text-success">Draft</span> to{' '}
                      </>
                    )}
                    {from.name || from.email}
                  </Text>
                  <Text variant="text" className="line-clamp-1 text-dark">
                    {subject}
                  </Text>
                  <Text className="text-medium group-hover:hidden">{timeFromNow(new Date(sentAt))}</Text>
                  <Button
                    variant="icon"
                    icon={Check}
                    iconclassname="m-0"
                    className="hidden group-hover:block"
                    onClick={(e) => {
                      onDone(id)
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                  />
                </Link>
              </AllSteps.Item>
            ))}
          </AllSteps.Group>
        </AllSteps>
      ) : (
        <Finished message="No Replies messages to view" />
      )}
    </section>
  )
}
