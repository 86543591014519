import { useDropzone } from 'react-dropzone'
import { usePasswordsImport } from '@/api/vault'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button } from '@/ui'

export function ImportPasswordButton() {
  const toast = useToast()
  const passwordImport = usePasswordsImport()
  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      return
    }
    passwordImport.mutate(acceptedFiles[0], {
      onError: (err) => {
        toast.createToast({ message: (err as any)?.body?.message || 'failed to import passwords', error: true })
      },
    })
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    noDrag: true,
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: onDrop,
    maxSize: 2 * 1024 * 1024, // 2MB
    accept: { 'text/csv': ['.csv'] },
  })
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Button variant="accent" onClick={open} className="mb-2 w-full" data-testid="password-vault-import-btn">
        Import passwords
      </Button>
    </div>
  )
}
