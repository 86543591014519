import {
  ContextType,
  createContext,
  createRef,
  ElementType,
  MutableRefObject,
  Ref,
  useContext,
  useEffect,
  useId,
  useMemo,
  useReducer,
  useRef,
} from 'react'
import { Portal } from '@headlessui/react'
import { Props } from '@headlessui/react/dist/types'
import { HasDisplayName, RefProp } from '@headlessui/react/dist/utils/render'
import { useEvent, useSyncRefs } from '@/hooks'
import { forwardRefWithAs, uirender } from '@/ui/render'

enum ModalStates {
  Open,
  Closed,
}

interface StateDefinition {
  titleId: string | null
  panelRef: MutableRefObject<HTMLDivElement | null>
}

enum ActionTypes {
  SetTitleId,
}

type Actions = { type: ActionTypes.SetTitleId; id: string | null }

const reducers: {
  [P in ActionTypes]: (state: StateDefinition, action: Extract<Actions, { type: P }>) => StateDefinition
} = {
  [ActionTypes.SetTitleId](state, action) {
    if (state.titleId === action.id) return state
    return { ...state, titleId: action.id }
  },
}

const ModalContext = createContext<
  | [
      {
        modalState: ModalStates
        close(): void
        setTitleId(id: string | null): void
      },
      StateDefinition,
    ]
  | null
>(null)
ModalContext.displayName = 'ModalContext'

function useModalContext(component: string) {
  const context = useContext(ModalContext)
  if (context === null) {
    throw new Error(`<${component} /> is missing a parent <Modal /> component.`)
  }
  return context
}

function reducer(state: StateDefinition, action: Actions) {
  return reducers[action.type](state, action)
}

// ---

const DEFAULT_MODAL_TAG = 'div' as const
interface ModalRenderPropArg {
  open: boolean
}
type ModalPropsWeControl = 'role' | 'aria-describedby' | 'aria-labelledby' | 'aria-modal'

export type ModalProps<TTag extends ElementType> = Props<
  TTag,
  ModalRenderPropArg,
  ModalPropsWeControl,
  {
    open?: boolean
    onClose?: (value: boolean) => void
  }
>

function ModalFn<TTag extends ElementType = typeof DEFAULT_MODAL_TAG>(
  props: ModalProps<TTag>,
  ref: Ref<HTMLDivElement>,
) {
  const internalId = useId()
  const { id = `headlessui-modal-${internalId}`, open = true, onClose, ...theirProps } = props

  const internalModalRef = useRef<HTMLDivElement | null>(null)
  const modalRef = useSyncRefs(internalModalRef, ref)

  const modalState = open ? ModalStates.Open : ModalStates.Closed

  const [state, dispatch] = useReducer(reducer, {
    titleId: null,
    descriptionId: null,
    panelRef: createRef(),
  } as StateDefinition)

  const close = useEvent(() => onClose?.(false))

  const setTitleId = useEvent((id: string | null) => dispatch({ type: ActionTypes.SetTitleId, id }))

  const contextBag = useMemo<ContextType<typeof ModalContext>>(
    () => [{ modalState, close, setTitleId }, state],
    [modalState, state, close, setTitleId],
  )

  const slot = useMemo<ModalRenderPropArg>(() => ({ open: modalState === ModalStates.Open }), [modalState])

  const ourProps = {
    ref: modalRef,
    id,
    role: 'modal',
    'aria-modal': modalState === ModalStates.Open ? true : undefined,
    'aria-labelledby': state.titleId,
  }

  return (
    <>
      <Portal>
        <ModalContext.Provider value={contextBag}>
          <Portal.Group target={internalModalRef}>
            {uirender({
              ourProps,
              theirProps,
              slot,
              visible: modalState === ModalStates.Open,
              defaultTag: DEFAULT_MODAL_TAG,
            })}
          </Portal.Group>
        </ModalContext.Provider>
      </Portal>
    </>
  )
}

const DEFAULT_PANEL_TAG = 'div' as const
interface PanelRenderPropArg {
  open: boolean
}

export type ModalPanelProps<TTag extends ElementType> = Props<TTag, PanelRenderPropArg>

function PanelFn<TTag extends ElementType = typeof DEFAULT_PANEL_TAG>(
  props: ModalPanelProps<TTag>,
  ref: Ref<HTMLDivElement>,
) {
  const internalId = useId()
  const { id = `headlessui-modal-panel-${internalId}`, ...theirProps } = props
  const [{ modalState }, state] = useModalContext('Modal.Panel')
  const panelRef = useSyncRefs(ref, state.panelRef)

  const slot = useMemo<PanelRenderPropArg>(() => ({ open: modalState === ModalStates.Open }), [modalState])

  const ourProps = {
    ref: panelRef,
    id,
  }

  return uirender({
    ourProps,
    theirProps,
    slot,
    defaultTag: DEFAULT_PANEL_TAG,
  })
}

// ---

const DEFAULT_TITLE_TAG = 'h2' as const
interface TitleRenderPropArg {
  open: boolean
}

export type ModalTitleProps<TTag extends ElementType> = Props<TTag, TitleRenderPropArg>

function TitleFn<TTag extends ElementType = typeof DEFAULT_TITLE_TAG>(
  props: ModalTitleProps<TTag>,
  ref: Ref<HTMLHeadingElement>,
) {
  const internalId = useId()
  const { id = `headlessui-modal-title-${internalId}`, ...theirProps } = props
  const [{ modalState, setTitleId }] = useModalContext('Modal.Title')

  const titleRef = useSyncRefs(ref)

  useEffect(() => {
    setTitleId(id)
    return () => setTitleId(null)
  }, [id, setTitleId])

  const slot = useMemo<TitleRenderPropArg>(() => ({ open: modalState === ModalStates.Open }), [modalState])

  const ourProps = { ref: titleRef, id }

  return uirender({
    ourProps,
    theirProps,
    slot,
    defaultTag: DEFAULT_TITLE_TAG,
  })
}

// ---

interface ComponentModal extends HasDisplayName {
  <TTag extends ElementType = typeof DEFAULT_MODAL_TAG>(props: ModalProps<TTag> & RefProp<typeof ModalFn>): JSX.Element
}

interface ComponentModalPanel extends HasDisplayName {
  <TTag extends ElementType = typeof DEFAULT_PANEL_TAG>(
    props: ModalPanelProps<TTag> & RefProp<typeof PanelFn>,
  ): JSX.Element
}

interface ComponentModalTitle extends HasDisplayName {
  <TTag extends ElementType = typeof DEFAULT_TITLE_TAG>(
    props: ModalTitleProps<TTag> & RefProp<typeof TitleFn>,
  ): JSX.Element
}

const ModalRoot = forwardRefWithAs(ModalFn) as unknown as ComponentModal
const Panel = forwardRefWithAs(PanelFn) as unknown as ComponentModalPanel
const Title = forwardRefWithAs(TitleFn) as unknown as ComponentModalTitle

export const Modal = Object.assign(ModalRoot, {
  Panel,
  Title,
})
