import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useDebounce } from 'use-debounce'
import { useCampaignUpdate } from '@/api/campaigns'
import { Campaign } from '@/api/core'
import { useToast } from '@/providers/Toasts/ToastsProvider'

export function SequenceName({ campaign }: { campaign: Campaign }) {
  const updateCampaign = useCampaignUpdate(campaign.id)
  const toast = useToast()
  const [sequenceName, setSequenceName] = useState(campaign?.name)
  const [sequenceNameDebounced] = useDebounce(sequenceName, 300)

  useEffect(() => {
    if (sequenceName !== campaign.name) {
      updateCampaign.mutate(
        { name: sequenceName },
        {
          onError: (err: unknown) => {
            toast.createToast({ message: (err as any)?.body?.message || "Can't update sequence name", error: true })
          },
        },
      )
    }
  }, [sequenceNameDebounced])

  return (
    <input
      className={clsx(
        'ml-8 w-1/2 border border-transparent bg-transparent text-lg font-medium hover:cursor-pointer hover:border-dashed hover:border-light',
        campaign.permissions.change_name.deny && 'pointer-events-none',
      )}
      placeholder={'Untitled sequence'}
      value={sequenceName}
      disabled={campaign.permissions.change_name.deny}
      onChange={(e) => setSequenceName(e.target.value)}
    />
  )
}
