/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PostSenderTestSend = {
    campaign_id: string;
    to: Array<string>;
    subject: string;
    body: string;
    data: Record<string, any>;
    send_mode: PostSenderTestSend.send_mode;
};

export namespace PostSenderTestSend {

    export enum send_mode {
        STEP = 'step',
        SEQUENCE = 'sequence',
    }


}

