import React, { Fragment } from 'react'
import { Dialog as UIDialog, Transition } from '@headlessui/react'
import { X } from '@phosphor-icons/react'
import { OpacityTransition, Text } from '@/ui'

type DialogProps = {
  open: boolean
  onClose: () => void
  title?: React.ReactNode | undefined
  'data-testid'?: string
}

export function Dialog(props: React.PropsWithChildren<DialogProps>) {
  const { open, onClose, children, title } = props

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <UIDialog as="div" className="relative z-50" onClose={onClose} data-testid={props['data-testid']}>
          <OpacityTransition show={true}>
            <div className="fixed inset-0 bg-extra-light bg-opacity-60" />
          </OpacityTransition>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center px-8 py-6 text-center">
              <OpacityTransition>
                <UIDialog.Panel className="transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-rift transition-all">
                  {title && (
                    <UIDialog.Title as="hgroup" className="mb-6 flex">
                      <Text variant="subtitle">{title}</Text>
                      <button className="focus:outline-non ml-auto" onClick={onClose}>
                        <span className="sr-only">close</span>
                        <X className="size-6" />
                      </button>
                    </UIDialog.Title>
                  )}
                  {children}
                </UIDialog.Panel>
              </OpacityTransition>
            </div>
          </div>
        </UIDialog>
      </Transition>
    </>
  )
}
