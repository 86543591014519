import * as React from 'react'
import { FieldError } from 'react-hook-form'
import clsx from 'clsx'
import { WarningCircle } from '@phosphor-icons/react'

type FieldWrapperProps = {
  name: string
  id?: string
  label?: string | React.ReactNode
  className?: string
  children: React.ReactNode
  error?: FieldError | FieldError[] | any
  extra?: React.ReactNode
  nodeError?: any
  required?: boolean
}

export type FieldWrapperPassThroughProps = Omit<FieldWrapperProps, 'className' | 'children'>

export function FieldWrapper(props: FieldWrapperProps) {
  const { label, name, className, error, children, extra, nodeError, required = false } = props

  const getError = () => {
    if (!nodeError) return

    const errorsArray = nodeError.map((messages: any) => {
      if (messages) {
        const errorItem = messages.messages.find((item: any) => {
          if (item.type === 'error') {
            return item
          }
          return null
        })

        return errorItem?.text
      }
    })

    return errorsArray
  }

  const errorsNode = getError()

  const labelClassNames = 'text-start text-xs font-medium text-primary pt-2'

  return (
    <div className="w-full">
      {label && (
        <>
          <label htmlFor={name} className={clsx('flex flex-row items-center pb-1', className ?? labelClassNames)}>
            {label}
            {required && <span className="pl-1 font-semibold text-red-600">*</span>}
          </label>
          {extra && <div className="mb-2 text-xs">{extra}</div>}
        </>
      )}
      <div className="w-full">{children}</div>

      {error?.message && (
        <div
          role="alert"
          aria-label={error?.message}
          className="flex flex-row items-center gap-2 py-2 text-start text-sm font-medium text-rift-red-500"
        >
          <WarningCircle className="cursor-pointer" width={16} height={16} />

          {error?.message}
        </div>
      )}

      {errorsNode && (
        <div role="alert" className="pt-2 text-start text-sm font-medium text-rift-red-500">
          {errorsNode}
        </div>
      )}
    </div>
  )
}
