import { ReactNode } from 'react'
import { Float, FloatType, Integer, IntegerType } from '@/api/core'
import { Tooltip } from '@/ui/Tooltip'

export function formatPercent(value: Float | number | undefined, places = 2): ReactNode {
  if (typeof value === 'undefined') return '—'
  if (typeof value === 'object') {
    switch (value.type) {
      case FloatType.NONE:
        return ''
      case FloatType.FLOAT:
        value = value.value
        break
      case FloatType.N_A:
        return (
          <Tooltip>
            <Tooltip.Trigger>
              <p className="text-sm font-normal leading-5 text-medium underline decoration-dotted underline-offset-2">
                n/a
              </p>
            </Tooltip.Trigger>
            <Tooltip.Panel>
              <Tooltip.Title>{value.tooltip?.title}</Tooltip.Title>
              <Tooltip.Content>{value.tooltip?.content}</Tooltip.Content>
            </Tooltip.Panel>
          </Tooltip>
        )
      default:
        return '—'
    }
  }

  const n = Math.max(places, 0) + 2
  value = Math.round((value as number) * 10 ** n + Number.EPSILON) / 10 ** places
  return `${value}%`
}

export function formatInteger(value: Integer | undefined): ReactNode {
  if (typeof value === 'undefined') return '—'
  switch (value.type) {
    case IntegerType.NONE:
      return ''
    case IntegerType.INTEGER:
      return `${value.value}`
    case IntegerType.N_A:
      return (
        <Tooltip>
          <Tooltip.Trigger>
            <p className="text-sm font-normal leading-5 text-medium underline decoration-dotted underline-offset-2">
              n/a
            </p>
          </Tooltip.Trigger>
          <Tooltip.Panel>
            <Tooltip.Title>{value.tooltip?.title}</Tooltip.Title>
            <Tooltip.Content>{value.tooltip?.content}</Tooltip.Content>
          </Tooltip.Panel>
        </Tooltip>
      )
    default:
      return '—'
  }
}
