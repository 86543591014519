import { createContext, PropsWithChildren, useContext, useReducer } from 'react'
import { reducer } from './reducer'
import { GlobalBannerContextType } from './types'

export const GlobalBannerContext = createContext<GlobalBannerContextType | null>(null)

export function GlobalBannerProvider({ children }: PropsWithChildren) {
  const [bannersState, bannersDispatch] = useReducer(reducer, { banners: [] })

  const bannerData = { bannersState, bannersDispatch }

  return <GlobalBannerContext.Provider value={bannerData}>{children}</GlobalBannerContext.Provider>
}

export function useGlobalBannerContext() {
  const context = useContext(GlobalBannerContext)

  if (!context) {
    throw new Error('Child components of Global Banner cannot be rendered outside the Global Banner provider!')
  }

  return context
}
