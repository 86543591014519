import React, {
  createElement,
  ElementType,
  forwardRef,
  Fragment,
  LegacyRef,
  MutableRefObject,
  ReactElement,
  Ref,
} from 'react'
import { twMerge } from 'tailwind-merge'
import { Expand, Props } from '@headlessui/react/dist/types'
import { omit } from '@/lib/utils'

export function uirender<TTag extends ElementType, TSlot>({
  ourProps,
  theirProps,
  slot,
  defaultTag,
  visible = true,
}: {
  ourProps: Expand<Props<TTag, TSlot, any> & { ref?: Ref<HTMLElement | ElementType> }>
  theirProps: Expand<Props<TTag, TSlot, any>>
  slot?: TSlot
  defaultTag: ElementType
  visible?: boolean
}) {
  const props = mergeProps(theirProps, ourProps)
  const { as: Component = defaultTag, children, ...rest } = props

  const resolvedChildren = (typeof children === 'function' ? children(slot) : children) as ReactElement | ReactElement[]
  const refRelatedProps = props.ref !== undefined ? { ref: props.ref } : {}
  if (!visible) return null
  return createElement(
    Component,
    Object.assign({}, omit(rest, ['ref']), Component !== Fragment && refRelatedProps),
    resolvedChildren,
  )
}

export function mergeRefs<T = any>(
  refs: Array<MutableRefObject<T> | LegacyRef<T> | undefined | null>,
): React.RefCallback<T> | React.Ref<T> | undefined {
  return refs.every((ref) => ref === null)
    ? undefined
    : (value) => {
        for (const ref of refs) {
          if (ref === null) continue
          if (typeof ref === 'function') ref(value)
          else (ref as React.MutableRefObject<T | null>).current = value
        }
      }
}

export function formatClassName<TSlot>(base: string, className: string | ((bag: TSlot) => string) | undefined) {
  if (typeof className === 'function') return (bag: TSlot) => twMerge(base, className(bag))
  return twMerge(base, className)
}

// copied from headless render utils
function mergeProps(...listOfProps: Props<any, any>[]) {
  if (listOfProps.length === 0) return {}
  if (listOfProps.length === 1) return listOfProps[0]

  const target: Props<any, any> = {}

  const eventHandlers: Record<string, ((event: { defaultPrevented: boolean }, ...args: any[]) => void | undefined)[]> =
    {}

  for (const props of listOfProps) {
    for (const prop in props) {
      // Collect event handlers
      if (prop.startsWith('on') && typeof props[prop] === 'function') {
        eventHandlers[prop] ??= []
        eventHandlers[prop].push(props[prop])
      } else {
        // Override incoming prop
        target[prop] = props[prop]
      }
    }
  }

  // Do not attach any event handlers when there is a `disabled` or `aria-disabled` prop set.
  if (target.disabled || target['aria-disabled']) {
    return Object.assign(
      target,
      // Set all event listeners that we collected to `undefined`. This is
      // important because of the `cloneElement` from above, which merges the
      // existing and new props, they don't just override therefore we have to
      // explicitly nullify them.
      Object.fromEntries(Object.keys(eventHandlers).map((eventName) => [eventName, undefined])),
    )
  }

  // Merge event handlers
  for (const eventName in eventHandlers) {
    Object.assign(target, {
      [eventName](event: { nativeEvent?: Event; defaultPrevented: boolean }, ...args: any[]) {
        const handlers = eventHandlers[eventName]

        for (const handler of handlers) {
          if ((event instanceof Event || event?.nativeEvent instanceof Event) && event.defaultPrevented) {
            return
          }

          handler(event, ...args)
        }
      },
    })
  }

  return target
}

/**
 * This is a hack, but basically we want to keep the full 'API' of the component, but we do want to
 * wrap it in a forwardRef so that we _can_ passthrough the ref
 */
export function forwardRefWithAs<T extends { name: string; displayName?: string }>(
  component: T,
): T & { displayName: string } {
  return Object.assign(forwardRef(component as unknown as any) as any, {
    displayName: component.displayName ?? component.name,
  })
}
