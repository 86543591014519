import clsx from 'clsx'
import { useCampaignParticipantAttach, useCampaignParticipantDetach, useCampaignParticipants } from '@/api/campaigns'
import { Campaign, CampaignParticipant } from '@/api/core'
import { useSet } from '@/hooks'
import { Listbox, Text } from '@/ui'

type FromProps = {
  campaign: Campaign
}

export function From(props: FromProps) {
  const { campaign } = props
  const { data, status } = useCampaignParticipants(campaign.id)

  return (
    <>
      <div className="flex items-center gap-5">
        <Text variant="subtitle" className="text-sm">
          From
        </Text>
      </div>
      <span className="relative px-3 py-2">
        {status === 'success' && <FromForm campaign={campaign} participants={data.data} />}
        {status === 'pending' && <div className="skeleton h-4 w-8" />}
      </span>
    </>
  )
}

type FromFormProps = {
  campaign: Campaign
  participants: CampaignParticipant[]
}

function FromForm(props: FromFormProps) {
  const { campaign, participants } = props
  const attachParticipant = useCampaignParticipantAttach(campaign.id)
  const detachParticipant = useCampaignParticipantDetach(campaign.id)
  // build a set of ids and updating state to prevent selection
  // participants that are currently detached/attached to the sequence
  // this is needed because we don't want allow for fast select/unselect
  // because this could throws errors in the server while making optimistic updates
  // in the app and leaving user with false assurance that all was updated
  // while in the reality it was not. Also we do that because there's no
  // success/error toast displayed and this is why we need such mechanism here
  const [, { has: hasUpdate, add: startUpdate, delete: endUpdate }] = useSet(new Set<string>())

  const onParticipantChange = (participant: CampaignParticipant, attach: boolean) => {
    startUpdate(participant.id)
    if (attach) {
      attachParticipant.mutate(
        { id: participant.id, type: participant.type },
        {
          onSettled: () => endUpdate(participant.id),
        },
      )
    } else {
      detachParticipant.mutate(
        { id: participant.id, type: participant.type },
        {
          onSettled: () => endUpdate(participant.id),
        },
      )
    }
  }

  // build button text as well as ids of selected participants
  // so they can be passed to the Listbox component which is
  // managed statically because all data are fetch from the server
  const v = participants.reduce(
    (acc, curr) => {
      return curr.attached
        ? {
            btnNames:
              acc.ids.length === 0
                ? [curr.name]
                : acc.btnNames.length < 3
                  ? [...acc.btnNames, curr.name]
                  : acc.btnNames,
            ids: [...acc.ids, curr.id],
          }
        : acc
    },
    { btnNames: ['Select sender'], ids: [] as string[] },
  )
  let btnText = v.btnNames.join(', ')
  if (v.ids.length > v.btnNames.length) btnText += ` & ${v.ids.length - v.btnNames.length} more`

  return (
    <>
      <Listbox multiple defaultValue={v.ids} disabled={campaign.permissions.change_participants.deny}>
        {({ disabled }) => (
          <>
            {disabled ? (
              <p className="text-sm">{btnText}</p>
            ) : (
              <Listbox.Button variant="text" className="h-fit cursor-default py-0" data-testid="senders-btn">
                {btnText}
              </Listbox.Button>
            )}
            <Listbox.Options>
              <div
                className={clsx(
                  (attachParticipant.isPending || detachParticipant.isPending) && 'pointer-events-none opacity-30',
                )}
              >
                {participants.map((p) => (
                  <Listbox.Option
                    key={p.id}
                    value={p.id}
                    disabled={(p.permissions.attachable.deny && !p.attached) || hasUpdate(p.id)}
                    onClick={() => {
                      if (p.permissions.attachable.deny && !p.attached) return
                      onParticipantChange(p, !p.attached)
                    }}
                    className={clsx(p.permissions.attachable.deny && 'text-gray-400')}
                  >
                    {p.name}
                    {p.permissions.attachable.deny && <p className="text-sm">{p.permissions.attachable.reason}</p>}
                  </Listbox.Option>
                ))}
              </div>
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </>
  )
}
