/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DKIMStatus {
    UNKNOWN = 'UNKNOWN',
    ON = 'ON',
    OFF = 'OFF',
    ERROR = 'ERROR',
}
