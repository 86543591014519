import { useEffect, useRef } from 'react'
import { X } from '@phosphor-icons/react'
import { Dropdown, DropdownOption } from '@/components/Dropdown'
import { Input } from '../Input/v2'

type mode = 'select' | 'input'
type value = { id: string; label: string }

type EditableSelectProps = {
  selected:
    | {
        mode: 'select'
        value: value
      }
    | {
        mode: 'input'
        value: string
      }
  options: value[]
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange(
    value:
      | {
          mode: 'select'
          value: value
        }
      | {
          mode: 'input'
          value: string
        },
  ): void
  disabled?: boolean
  inputText: string
  inputTextPlaceholder?: string
  id?: string
}

export function EditableSelect(props: EditableSelectProps) {
  const prev = useRef(props.selected)
  useEffect(() => {
    if (props.selected.mode === 'input') {
      return
    }
    prev.current = props.selected
  }, [props.selected])

  if (props.selected.mode === 'input') {
    return (
      <div className="flex">
        <Input
          label="Contact list name"
          type="text"
          disabled={props.disabled}
          className="relative min-h-[38px] w-full rounded-lg border border-gray-300 bg-white py-2 pl-3 pr-9 text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary disabled:text-gray-300 sm:text-sm"
          value={props.selected.value}
          placeholder={props.inputTextPlaceholder}
          onKeyDown={props.onKeyDown}
          onFocus={props.onFocus}
          id={props.id}
          onChange={(e) =>
            props.onChange({
              mode: 'input',
              value: e.target.value,
            })
          }
        />
        <button
          disabled={props.disabled}
          onClick={() => props.onChange(prev.current)}
          className="ml-5 text-gray-500 disabled:text-gray-300"
        >
          <X className="size-5" />
        </button>
      </div>
    )
  }

  function handleDropdownSelection(selected: DropdownOption) {
    if (selected.id === 'input') {
      props.onChange({
        mode: 'input',
        value: '',
      })
    } else {
      props.onChange({
        mode: 'select',
        value: { id: selected.id as string, label: selected.label },
      })
    }
  }

  return (
    <Dropdown
      selected={props.selected.value}
      handleSelection={handleDropdownSelection}
      options={[{ id: 'input', label: props.inputText }, ...props.options]}
      className="static w-72"
      ariaLabel="Select contact list"
    />
  )
}
