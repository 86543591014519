import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { CheckCircle, Phone, PhoneDisconnect } from '@phosphor-icons/react'
import { ApiError, ManualStepCallInfo } from '@/api/core'
import { useManualStepsCall, useManualStepsCallsDeviceToken } from '@/api/manual_step'
import { useCounterInterval } from '@/hooks'
import { Button, Text } from '@/ui'
import { ActivityHistory } from '../ActivityHistory'
import { ContactInformation } from '../ContactInformation'
import { SingleStep } from '../SingleStep'
import { SingleStepSkeleton } from '../Skeletons'
import { useCall, useDevice } from './useDialer'

export function SingleCall() {
  const navigate = useNavigate()
  const { callId } = useParams() as { callId: string }
  const { data, status, error } = useManualStepsCall(callId)
  const { data: token, status: tokenStatus } = useManualStepsCallsDeviceToken(callId)

  useEffect(() => {
    if (status === 'error' && (error as ApiError).status === 404) {
      navigate('/inbox/calls/')
    }
  }, [status])

  return (
    <section>
      {status === 'pending' && <SingleStepSkeleton />}
      {status === 'success' && tokenStatus === 'success' && (
        <SingleCallView call={data.data} token={token.data.token} />
      )}
    </section>
  )
}

type SingleCallViewProps = {
  call: ManualStepCallInfo
  token: string
}

function SingleCallView(prop: SingleCallViewProps) {
  const { call, token } = prop
  const { device, error: deviceError } = useDevice(token)
  const [calling, setCalling] = useState(false)
  const { disconnect, answered, completed } = useCall(device, call.id, calling)

  const onClickDisconnect = () => {
    disconnect()
    setCalling(false)
  }

  const onClickCall = () => {
    setCalling(true)
  }

  const MainSection = () => {
    if (calling) {
      return (
        <div className="flex items-center gap-4">
          <Button variant="accent" className="bg-alert" onClick={onClickDisconnect}>
            <PhoneDisconnect className="mr-2 h-5 w-5" />
            End
          </Button>

          {answered ? (
            <CallInterval />
          ) : (
            <Text variant="text" className="text-dusk">
              Calling...
            </Text>
          )}
        </div>
      )
    }

    if (completed) {
      return (
        <Text className="flex items-center gap-2 text-medium">
          <CheckCircle className="h-5 w-5 fill-success text-white" weight="fill" />
          Call Completed
        </Text>
      )
    }

    return (
      <Button variant="accent" onClick={onClickCall} disabled={deviceError !== null}>
        <Phone className="mr-2 h-5 w-5" />
        Call {call.contact.firstName}
      </Button>
    )
  }

  return (
    <SingleStep>
      <SingleStep.Navigation prev={call.prevCallId} next={call.nextCallId} url="/inbox/calls/" />
      <SingleStep.Main>
        <SingleStep.Card className="p-6">
          <div className="flex items-center justify-between">
            <MainSection />
            <Link to={`/sequences/${call.sequenceId}`} target="_blank" className="text-sm text-medium underline">
              {call.sequenceName}
            </Link>
          </div>
        </SingleStep.Card>

        <ActivityHistory contactId={call.contact.id} />
      </SingleStep.Main>
      <ContactInformation contactId={call.contact.id} />
    </SingleStep>
  )
}

function CallInterval() {
  const { minutes, seconds } = useCounterInterval()

  return (
    <Text variant="text" className="text-dusk">
      {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
    </Text>
  )
}
