import clsx from 'clsx'
import { Tooltip } from '@/ui/Tooltip'

type ReadyToUseTileProps = {
  readyToUseInDays: number
  className?: string
}

const ReadyToUseTile: React.FC<ReadyToUseTileProps> = (props) => {
  return (
    <div className={clsx(props.className)}>
      <Tooltip>
        <Tooltip.Trigger>
          <div className="grid gap-y-5 text-center text-md font-medium text-primary">
            <h1>Days until ready to send emails</h1>
            <div className="w-full text-9xl">{props.readyToUseInDays}</div>
          </div>
        </Tooltip.Trigger>
        <Tooltip.Panel>After this time email will be warmed and ready to send campaign emails</Tooltip.Panel>
      </Tooltip>
    </div>
  )
}

type StatisticsProps = {
  readyToUseInDays: number
  dailySend: number
  dailyCapacity: number
  monthlyCapacity: number
  monthlySent: number
  className?: string
  mode?: string
}

export function StatisticsUsage(props: StatisticsProps) {
  return (
    <div className={clsx(props.className)}>
      <div className="w-full">
        {props.readyToUseInDays > 0 && <ReadyToUseTile readyToUseInDays={props.readyToUseInDays} />}
      </div>
      <div className="w-full">
        <div className="flex w-full flex-row items-center justify-between border-b pb-2 text-md font-medium leading-6 text-primary">
          <Tooltip>
            <Tooltip.Trigger>
              <h1>Total Daily Usage</h1>
            </Tooltip.Trigger>
            <Tooltip.Panel>How many emails you have sent today / How many emails you can send today</Tooltip.Panel>
          </Tooltip>
          <div>
            {props.dailySend || 0}/{props.dailyCapacity || 0}
          </div>
        </div>
        <div className="flex w-full flex-row items-center justify-between text-md font-medium leading-6 text-primary">
          <Tooltip>
            <Tooltip.Trigger>
              <h1>Total Monthly Usage</h1>
            </Tooltip.Trigger>
            <Tooltip.Panel>
              How many emails you have sent this month / How many emails you can send this month
            </Tooltip.Panel>
          </Tooltip>

          <div>
            {props.monthlySent || 0}/{props.monthlyCapacity || 0}
          </div>
        </div>
      </div>
    </div>
  )
}
