import { Dispatch, SetStateAction } from 'react'
import { AxiosError } from 'axios'
import { ToastProps } from '@/ui/Toast/Toast'

// A small function to help us deal with errors coming from fetching a flow.
export function handleGetFlowError<S>(
  navigate: any,
  flowType: 'login' | 'registration' | 'settings' | 'recovery' | 'verification',
  resetFlow: Dispatch<SetStateAction<S | undefined>>,
  createToast: (toast: ToastProps) => void,
) {
  return async (err: AxiosError) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    switch (err.response?.data.error?.id) {
      case 'session_aal2_required':
        // 2FA is enabled and enforced, but user did not perform 2fa yet!
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.location.href = err.response?.data.redirect_browser_to
        return
      case 'session_already_available':
        // User is already signed in, let's redirect them home!
        await navigate('/')
        return
      case 'session_refresh_required':
        // We need to re-authenticate to perform this action
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.location.href = err.response?.data.redirect_browser_to
        return
      case 'self_service_flow_return_to_forbidden':
        // The flow expired, let's request a new one.
        createToast({ error: true, message: 'The return_to address is not allowed.' })
        resetFlow(undefined)
        await navigate('/' + flowType)
        return
      case 'self_service_flow_expired':
        // The flow expired, let's request a new one.
        createToast({ error: true, message: 'Your interaction expired, please fill out the form again.' })
        resetFlow(undefined)
        await navigate('/' + flowType)
        return
      case 'security_csrf_violation':
        // A CSRF violation occurred. Best to just refresh the flow!
        createToast({
          error: true,
          message: 'A security violation was detected, please fill out the form again.',
        })
        resetFlow(undefined)
        await navigate('/' + flowType)
        return
      case 'security_identity_mismatch':
        // The requested item was intended for someone else. Let's request a new flow...
        resetFlow(undefined)
        await navigate('/' + flowType)
        return
      case 'browser_location_change_required':
        // Ory Kratos asked us to point the user to this URL.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.location.href = err?.response?.data.redirect_browser_to
        return
    }

    switch (err.response?.status) {
      case 410:
        // The flow expired, let's request a new one.
        resetFlow(undefined)
        await navigate('/' + flowType)
        return
    }

    // We are not able to handle the error? Return it.
    return Promise.reject(err)
  }
}

// A small function to help us deal with errors coming from initializing a flow.
export const handleFlowError = handleGetFlowError
