import QrScanner from 'qr-scanner'

// totpSecretFromClipboard returns the secret from the clipboard if it's a valid secret, otherwise it returns the clipboard text
export async function totpSecretFromClipboard<T extends Element>(e: React.ClipboardEvent<T>): Promise<string> {
  for (const item of e.clipboardData.items) {
    if (item.type.indexOf('image') === -1) {
      continue
    }
    const file = item.getAsFile()
    if (!file) {
      continue
    }

    const text = await QrScanner.scanImage(file)
    const [secret, valid] = parse(text)
    if (!valid) {
      continue
    }
    return secret
  }

  const text = e.clipboardData.getData('Text').replace(/\s/g, '')
  const [secret, valid] = parse(text)
  if (!valid) {
    return text
  }

  return secret
}

function parse(url: string): [string, boolean] {
  // check if not empty
  if (!url) {
    return [url, false]
  }
  // if this is base64 then it's valid secret
  try {
    window.atob(url)
    return [url, true]
  } catch {
    // maybe it's a url
  }

  try {
    const secret = new URL(url).searchParams.get('secret')
    if (!secret) {
      return [url, false]
    }
    window.atob(secret)
    return [secret, true]
  } catch {
    return [url, false]
  }
}
