import React, { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

type CardProps = {
  className?: string
  onClick?: () => void
}

export function Card(props: PropsWithChildren<CardProps>) {
  const { children, onClick, className } = props

  return (
    <section onClick={onClick} className={twMerge('rounded-md border border-light px-6 py-4', className)}>
      {children}
    </section>
  )
}
