import { CheckCircle, WarningCircle, XCircle } from '@phosphor-icons/react'

enum StatusTypes {
  ON = 'ON',
  OFF = 'OFF',
  ERROR = 'ERROR',
}

export function handleHealthChecksStatuseIcon(status: StatusTypes | string | undefined) {
  if (status === 'OFF') {
    return <XCircle className="text-rift-red-500" width={18} height={18} />
  } else if (status === 'ERROR') {
    return <WarningCircle className="text-rift-yellow-500" width={18} height={18} />
  }

  return <CheckCircle className="text-rift-blue-600" width={18} height={18} />
}
