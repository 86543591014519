import { Campaign } from '@/api/core'
import { From } from './From'

type TopMenuProps = {
  campaign: Campaign
}

export function TopMenu(props: TopMenuProps) {
  const { campaign: campaign } = props
  return (
    <section className="relative flex rounded border border-light px-8 py-6">
      <From campaign={campaign} />
    </section>
  )
}
