import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Dialog } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSenderEmailCreate } from '@/api'
import { PostSenderEmail, SenderEmailProvider } from '@/api/core'
import { SenderEmailProviderLabels } from '@/api/text/sender_email'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Input, Select, Text } from '@/ui'

type ConnectEmailDialogProps = {
  open: boolean
  onClose: () => void
}

export function ConnectEmailDialog(props: ConnectEmailDialogProps) {
  const { open, onClose } = props
  const providerOptions = SenderEmailProviderLabels.filter(
    (provider) => import.meta.env.DEV || (!import.meta.env.DEV && provider.value !== SenderEmailProvider.LOCALDEV),
  )
  const toast = useToast()

  const schema = yup.object().shape({
    first_name: yup.string().required('required'),
    last_name: yup.string().required('required'),
    email: yup.string().required('required').email('Invalid email format'),
    password: yup.string().required('required').length(16, 'Must be exactly 16 digits'),
    provider: yup.string().required('required').oneOf(Object.values(SenderEmailProvider), 'Invalid provider'),
    custom_domain: yup.string().default(''),
  })

  const emailConnect = useSenderEmailCreate()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<PostSenderEmail>({
    resolver: yupResolver(schema),
    defaultValues: {
      provider: providerOptions[0].value,
    },
  })

  const onSubmit = (data: PostSenderEmail) => {
    emailConnect.mutate([data], {
      onSuccess: () => {
        reset()
        onClose()

        toast.createToast({ message: 'Email account created' })
      },
      onError: (err) =>
        toast.createToast({ message: (err as any)?.body.message || 'Failed to connect email', error: true }),
    })
  }

  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="fixed inset-0 bg-white/70" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="mx-auto w-72 rounded-lg border border-light bg-white p-6 shadow-sm">
            <Dialog.Title as="div">
              <Text variant="subtitle">Connect email</Text>
            </Dialog.Title>

            <div className="w-full flex-col space-y-5 pt-2">
              <Input registration={register('first_name')} error={errors.first_name} label="First name" />
              <Input registration={register('last_name')} error={errors.last_name} label="Last name" />
              <Input registration={register('email')} error={errors.email} label="Email" />
              <Input registration={register('custom_domain')} error={errors.custom_domain} label="Custom domain" />
              <Input registration={register('password')} error={errors.password} label="App password" />
              <div>
                <p className="label mb-0.5 block font-medium">Provider</p>
                <Select
                  value={providerOptions.find((p) => p.value === getValues('provider'))!}
                  onChange={(provider) => setValue('provider', provider.value)}
                  options={providerOptions}
                />
              </div>

              <div className="flex justify-end gap-3">
                <Button type="button" variant="text" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit" variant="accent">
                  Connect
                </Button>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </form>
    </Dialog>
  )
}
