/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DomainRecordType {
    A = 'A',
    AAAA = 'AAAA',
    CNAME = 'CNAME',
    MX = 'MX',
    NS = 'NS',
    TXT = 'TXT',
}
