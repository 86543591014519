/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CampaignType } from './CampaignType';

export type CampaignTypeCampaignLabel = {
    type: CampaignTypeCampaignLabel.type;
    campaign_type: CampaignType;
};

export namespace CampaignTypeCampaignLabel {

    export enum type {
        CAMPAIGN_TYPE = 'CAMPAIGN_TYPE',
    }


}

