/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CompanyProductCampaignLabel = {
    type: CompanyProductCampaignLabel.type;
    product: string;
};

export namespace CompanyProductCampaignLabel {

    export enum type {
        PRODUCT = 'PRODUCT',
    }


}

