import { useState } from 'react'
import { Button } from '@/ui/Button/v2'
import { ConfirmationDialog } from '../../ConfirmationDialog'

export function CancelButton({ onCancel, className = '' }: { onCancel: () => void; className?: string }) {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)

  return (
    <Button className={className} onClick={() => setIsConfirmationDialogOpen(true)}>
      Cancel
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        onClose={() => setIsConfirmationDialogOpen(false)}
        onConfirm={onCancel}
        message="Discard changes and cancel import?"
        confirmBtn="Cancel import"
        cancelBtn="Don't cancel"
        showAreYouSureText={false}
      />
    </Button>
  )
}
