import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { ContactList } from '@/api/core'
import { Button, EditableSelect, Text } from '@/ui'

export type ListSelectProps = {
  onCancel: () => void
  onImport: (v: { listName?: string; contactListId?: string }) => void
  contactLists: ContactList[]
}

export function ListSelect(props: ListSelectProps) {
  const { onCancel, onImport, contactLists } = props
  const lists = contactLists.map((l) => ({ label: l.name, id: l.id }))
  const [selected, setSelected] = useState<
    | {
        mode: 'select'
        value: { label: string; id: string }
      }
    | {
        mode: 'input'
        value: string
      }
  >(lists.length > 0 ? { mode: 'select', value: lists[0] } : { mode: 'input', value: '' })

  const [selectedOption, setSelectedOption] = useState<'list' | 'nolist'>('list')

  const onNext = () => {
    if (selectedOption === 'nolist') {
      onImport({})
      return
    }

    if (selected.mode === 'select') {
      onImport({ listName: selected.value.label, contactListId: selected.value.id })
    } else {
      onImport({ listName: selected.value })
    }
  }

  const importDisabled = selectedOption === 'list' && selected.mode === 'input' && selected.value === ''

  return (
    <div className="mb-6 flex grow flex-col overflow-auto rounded-lg border border-solid border-light bg-white p-4">
      <RadioGroup value={selectedOption} onChange={setSelectedOption} className="mb-12 flex flex-col gap-y-3">
        <RadioGroup.Option value={'list'}>
          {({ checked }) => (
            <div className="inline-flex items-center">
              <input type="radio" checked={checked} readOnly className="mr-2 h-4 w-4 border-gray-300 text-accent" />
              <EditableSelect
                selected={selected}
                onKeyDown={(e) => {
                  e.stopPropagation()
                }}
                onChange={setSelected}
                options={lists}
                disabled={selectedOption !== 'list'}
                inputText="Add a new list"
                inputTextPlaceholder="List name"
                id="newContactListName"
              />
            </div>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option value={'nolist'}>
          {({ checked }) => (
            <div className="inline-flex items-center">
              <input type="radio" checked={checked} readOnly className="mr-2 h-4 w-4 border-gray-300 text-accent" />
              <Text variant="text" className="ml-3.5">
                Don't add
              </Text>
            </div>
          )}
        </RadioGroup.Option>
      </RadioGroup>
      <section className="mt-4 flex justify-between">
        <Button variant="basic" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={importDisabled} variant="accent" onClick={onNext}>
          Continue
        </Button>
      </section>
    </div>
  )
}
