import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSalesforceConnection, useSalesforceConnectionOptions, useSalesforceConnectionUpdate } from '@/api'
import {
  CrmStageObject,
  DoubleMappings,
  PatchSalesforceConnection,
  SalesforceConnection,
  SalesforceConnectionOptions,
  SingularMappings,
} from '@/api/core'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button } from '@/ui'
import { Step } from '@/ui/headless'
import { SalesforceFieldMappingForm } from './FieldMappingForm'
import { MappingSkeleton } from './Skeleton'
import { StageFilteringForm } from './StageFilteringForm'
import { SalesforceStageMappingForm } from './StageMappingForm'
import { SalesforceSynchronizationSettings } from './SynchronizationSettings'
import { SalesforceSyncObjectForm } from './SyncObjectForm'
import { SalesforceUserMappingForm } from './UserMappingForm'

export function SalesforceOnboarding() {
  const connection = useSalesforceConnection()
  const options = useSalesforceConnectionOptions()
  if (connection.status === 'pending' || options.status === 'pending' || !connection.data || !options.data) {
    return <MappingSkeleton />
  }
  return <MappingWithData salesforceConnection={connection.data.data} salesforceOptions={options.data.data} />
}

type SalesforceOnboardingProps = {
  salesforceConnection: SalesforceConnection
  salesforceOptions: SalesforceConnectionOptions
}
function MappingWithData({ salesforceConnection, salesforceOptions }: SalesforceOnboardingProps) {
  const navigate = useNavigate()
  const toast = useToast()
  const salesforceUpdate = useSalesforceConnectionUpdate()
  const [mapping, setMapping] = useState<PatchSalesforceConnection>({
    stageMappings: {
      object: salesforceConnection.stageMappings.object,
      fieldId: salesforceConnection.stageMappings.fieldId,
      riftToCrm: salesforceConnection.stageMappings.riftToCrm.stageMappings,
      crmToRift: salesforceConnection.stageMappings.crmToRift.stageMappings,
    },
    isCrmToRiftEnabled: salesforceConnection.stageMappings.crmToRift.isEnabled,
    isRiftToCrmEnabled: salesforceConnection.stageMappings.riftToCrm.isEnabled,
    fieldMappings: salesforceConnection.fieldMappings,
    crmToRiftFilters: salesforceConnection.stageMappings.crmToRift.filters,
    riftToCrmFilters: salesforceConnection.stageMappings.riftToCrm.filters,
  })
  const crmStages = salesforceOptions.crmStages.find(
    (stage) => stage.object === mapping.stageMappings?.object && stage.fieldId === mapping.stageMappings?.fieldId,
  )
  const onMappingSave = () => {
    salesforceUpdate.mutate(mapping, {
      onSuccess: () => {
        toast.createToast({ message: 'Salesforce connected' })
        navigate('/settings/integrations/salesforce')
      },
    })
  }

  const onCancel = () => {
    navigate('/settings/integrations/salesforce')
  }

  const direction =
    mapping.isCrmToRiftEnabled && mapping.isRiftToCrmEnabled
      ? 'both'
      : mapping.isCrmToRiftEnabled
        ? 'crm_to_rift'
        : 'rift_to_crm'
  const bottomNavClassName = 'flex flex-row justify-end gap-4 pb-16 pt-8'

  const onRiftToCrmChange = (isEnabled: boolean) => setMapping({ ...mapping, isRiftToCrmEnabled: isEnabled })
  const onCrmToRiftChange = (isEnabled: boolean) => setMapping({ ...mapping, isCrmToRiftEnabled: isEnabled })
  const onInteractionHistoryChange = (isEnabled: boolean) =>
    setMapping({ ...mapping, isInteractionHistoryEnabled: isEnabled })
  const onRiftToCrmStageMappingUpdate = (newMapping: SingularMappings) =>
    setMapping({
      ...mapping,
      stageMappings: {
        object: mapping.stageMappings?.object || CrmStageObject.LEAD,
        fieldId: mapping.stageMappings?.fieldId || '',
        crmToRift: mapping.stageMappings?.crmToRift || [],
        riftToCrm: newMapping,
      },
    })
  const onCrmToRiftStageMappingUpdate = (newMapping: SingularMappings) =>
    setMapping({
      ...mapping,
      stageMappings: {
        object: mapping.stageMappings?.object || CrmStageObject.LEAD,
        fieldId: mapping.stageMappings?.fieldId || '',
        riftToCrm: mapping.stageMappings?.riftToCrm || [],
        crmToRift: newMapping,
      },
    })

  const riftSyncOptionsPanel = () => (
    <Step.Panel>
      <SalesforceSynchronizationSettings
        riftToCrmEnabled={mapping.isRiftToCrmEnabled || false}
        onRiftToCrmChange={onRiftToCrmChange}
        crmToRiftEnabled={mapping.isCrmToRiftEnabled || false}
        onCrmToRiftChange={onCrmToRiftChange}
        interactionHistoryEnabled={mapping.isInteractionHistoryEnabled || false}
        onInteractionHistoryChange={onInteractionHistoryChange}
      />
      <div className={bottomNavClassName}>
        <Button onClick={onCancel} variant="text">
          Cancel
        </Button>
        {
          <Step.Next as={Button} variant="accent" disabled={!mapping.isRiftToCrmEnabled && !mapping.isCrmToRiftEnabled}>
            Next
          </Step.Next>
        }
      </div>
    </Step.Panel>
  )

  const riftFieldMappingPanel = () => (
    <Step.Panel>
      <SalesforceFieldMappingForm
        direction={direction}
        options={salesforceOptions}
        mapping={mapping.fieldMappings || []}
        setMapping={(newMapping: DoubleMappings) => setMapping({ ...mapping, fieldMappings: newMapping })}
      />
      <div className={bottomNavClassName}>
        <Step.Back as={Button} variant="text">
          Back
        </Step.Back>
        <Step.Next as={Button} variant="accent">
          Next
        </Step.Next>
      </div>
    </Step.Panel>
  )

  const riftUserMappingPanel = () => (
    <Step.Panel>
      <SalesforceUserMappingForm
        options={salesforceOptions}
        mapping={mapping.userMappings || []}
        setMapping={(newMapping: SingularMappings) => setMapping({ ...mapping, userMappings: newMapping })}
      />
      <div className={bottomNavClassName}>
        <Step.Back as={Button} variant="text">
          Back
        </Step.Back>
        <Step.Next as={Button} variant="accent">
          Next
        </Step.Next>
      </div>
    </Step.Panel>
  )

  const stageMappingPickerPanel = () => (
    <Step.Panel>
      <SalesforceSyncObjectForm
        syncObjectsOptions={salesforceOptions.crmStages}
        object={mapping.stageMappings?.object || CrmStageObject.LEAD}
        fieldId={mapping.stageMappings?.fieldId || ''}
        setSyncObject={(object, fieldId) =>
          setMapping({ ...mapping, stageMappings: { object, fieldId, riftToCrm: [], crmToRift: [] } })
        }
      />
      <div className={bottomNavClassName}>
        <Button onClick={onCancel} variant="text">
          Cancel
        </Button>
        <Step.Next as={Button} variant="accent">
          Next
        </Step.Next>
      </div>
    </Step.Panel>
  )

  const riftToCrmStageMappingPanel = () => (
    <Step.Panel>
      <SalesforceStageMappingForm
        crmToRiftDirection={false}
        riftStages={salesforceOptions.riftStages}
        crmStages={crmStages?.values || []}
        defaultStageMappings={salesforceOptions.defaultStageMappings || []}
        mapping={mapping.stageMappings?.riftToCrm || []}
        setMapping={onRiftToCrmStageMappingUpdate}
        disabled={!mapping.isRiftToCrmEnabled}
      />
      <div className={bottomNavClassName}>
        <Step.Back as={Button} variant="text">
          Back
        </Step.Back>
        <Step.Next as={Button} variant="accent">
          Next
        </Step.Next>
      </div>
    </Step.Panel>
  )

  const crmToRiftStageMappingPanel = () => (
    <Step.Panel>
      <SalesforceStageMappingForm
        crmToRiftDirection={true}
        riftStages={salesforceOptions.riftStages}
        crmStages={crmStages?.values || []}
        defaultStageMappings={salesforceOptions.defaultStageMappings || []}
        mapping={mapping.stageMappings?.crmToRift || []}
        setMapping={onCrmToRiftStageMappingUpdate}
        disabled={!mapping.isCrmToRiftEnabled}
      />

      <div className={bottomNavClassName}>
        <Step.Back as={Button} variant="text">
          Back
        </Step.Back>
        <Step.Next as={Button} variant="accent">
          Next
        </Step.Next>
      </div>
    </Step.Panel>
  )

  const filteringPanel = () => (
    <Step.Panel>
      <StageFilteringForm
        riftStages={salesforceOptions.riftStages}
        crmStages={crmStages?.values || []}
        riftFilters={mapping.riftToCrmFilters || []}
        crmFilters={mapping.crmToRiftFilters || []}
        setRiftFilters={(newFilters) => setMapping({ ...mapping, riftToCrmFilters: newFilters })}
        setCrmFilters={(newFilters) => setMapping({ ...mapping, crmToRiftFilters: newFilters })}
      />
      <div className={bottomNavClassName}>
        <Step.Back as={Button} variant="text">
          Back
        </Step.Back>
        <Button onClick={onMappingSave} variant="accent">
          Save
        </Button>
      </div>
    </Step.Panel>
  )

  return (
    <Step.Group as="div" className="row flex pb-24" defaultIndex={0}>
      <Step.List>
        <Step></Step>
        <Step></Step>
        <Step></Step>
        <Step></Step>
        <Step></Step>
        <Step></Step>
        <Step></Step>
      </Step.List>
      <Step.Panels>
        {riftSyncOptionsPanel()}
        {riftUserMappingPanel()}
        {riftFieldMappingPanel()}
        {stageMappingPickerPanel()}
        {riftToCrmStageMappingPanel()}
        {crmToRiftStageMappingPanel()}
        {filteringPanel()}
      </Step.Panels>
    </Step.Group>
  )
}
