import React from 'react'
import { ArrowRight } from '@phosphor-icons/react'
import { ContactFieldToLabelName, ContactFieldToSentenceText } from '@/api/text/contact_fields'
import { Button, Checkbox, Input } from '@/ui'
import { Action, ContactObject, Transformation } from '../reducer'
import { CancelButton } from './CancelButton'

export type FieldCleaningProps = {
  onCancel: () => void
  dispatch: React.Dispatch<Action>
  onComplete: () => void
  field: keyof ContactObject
  cleanings: Transformation[]
}

export function FieldCleaning(props: FieldCleaningProps) {
  const { onComplete, dispatch, onCancel, field, cleanings } = props

  return (
    <>
      <div className="my-6 grid grid-cols-[1fr_20px_1fr] gap-4 overflow-auto rounded-lg border border-light bg-white p-6">
        <div className="text-sm font-medium text-dark">{ContactFieldToLabelName(field)}</div>
        <div></div>
        <div className="text-sm font-medium text-dark">Cleaned {ContactFieldToSentenceText(field)}</div>
        {cleanings.map((substitution, index) => {
          return (
            <React.Fragment key={`${field}----${substitution.from}}`}>
              <label className="flex items-center gap-x-4 text-sm leading-5">
                <Checkbox
                  checked={substitution.accepted}
                  onChange={() => dispatch({ type: 'toggle_substitution', field, index })}
                />
                {substitution.from}
              </label>
              <div className="flex items-center text-medium">
                <ArrowRight height={15} width={15} />
              </div>
              <div className="text-sm leading-5">
                <Input
                  type="text"
                  disabled={!substitution.accepted}
                  value={substitution.to}
                  onChange={({ target: { value } }) => dispatch({ type: 'change_to', field, index, newValue: value })}
                />
              </div>
            </React.Fragment>
          )
        })}
      </div>
      <section className="mt-4 flex justify-between">
        <CancelButton onCancel={onCancel} />
        <div className="flex gap-x-2">
          <Button
            onClick={() => {
              dispatch({ type: 'skip_field_substitutions', field })
              onComplete()
            }}
          >
            Skip these fields
          </Button>
          <Button variant="accent" onClick={onComplete}>
            Continue
          </Button>
        </div>
      </section>
    </>
  )
}
