import { Editor } from '@tiptap/react'
import { CalendarLink } from './CalendarLink'

export type EditorMode = 'subject' | 'body'

export type State = {
  // currently focused editor
  editorMode: EditorMode

  preview: boolean

  // current editor and content reference (subject or body)
  editor: Editor | null

  // subject editor reference
  subjectEditor: Editor | null

  // body editor reference
  bodyEditor: Editor | null

  // available variables + their values for preview
  variables: Record<string, string>

  // available calendarLinks
  calendarLinks: CalendarLink[]
}

export enum ActionType {
  RegisterSubjectEditor,
  RegisterBodyEditor,
  ChangeEditorMode,
  TogglePreview,
}

export type Action =
  | { type: ActionType.RegisterSubjectEditor; editor: Editor }
  | { type: ActionType.RegisterBodyEditor; editor: Editor }
  | { type: ActionType.ChangeEditorMode; editorMode: EditorMode }
  | { type: ActionType.TogglePreview }

const reducers: {
  [P in ActionType]: (state: State, action: Extract<Action, { type: P }>) => State
} = {
  [ActionType.RegisterSubjectEditor](state, action) {
    return { ...state, subjectEditor: action.editor }
  },
  [ActionType.RegisterBodyEditor](state, action) {
    return { ...state, bodyEditor: action.editor }
  },
  [ActionType.ChangeEditorMode](state, action) {
    return {
      ...state,
      editorMode: action.editorMode,
      editor: action.editorMode === 'subject' ? state.subjectEditor : state.bodyEditor,
    }
  },
  [ActionType.TogglePreview](state) {
    return { ...state, preview: !state.preview }
  },
}

export function reducer(state: State, action: Action) {
  return reducers[action.type](state, action as any)
}
