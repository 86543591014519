import { Control, Controller } from 'react-hook-form'
import { ConferencingPlatform, PatchMeetingType, PostMeetingType, TokenStatus } from '@/api/core'
import { useZoomOauthStatus } from '@/api/oauth'
import { ConferencingPlatformLabel, ConferencingPlatformLabels } from '@/api/text/conferencing_platform'
import { Select, Text } from '@/ui'

export function ConferencingOptions(props: { control: Control<PostMeetingType | PatchMeetingType, any> }) {
  const zoomOauthStatus = useZoomOauthStatus()
  const zoomIntegrationEnabled = zoomOauthStatus.data?.data?.tokenStatus === TokenStatus.VALID
  return (
    <div>
      <Text className="label-medium mb-1 inline-block">Location</Text>
      <Controller
        control={props.control}
        name="conferencingPlatform"
        render={({ field: { onChange, value } }) => {
          const onSelectChange = (option: { label: string; value: string }) => {
            onChange(option.value)
          }
          return (
            <div className="flex items-baseline">
              <div className="w-36">
                <Select
                  onChange={onSelectChange}
                  value={ConferencingPlatformLabels.find(({ value: v }) => v === value) as ConferencingPlatformLabel}
                  options={ConferencingPlatformLabels}
                />
              </div>
              {value === ConferencingPlatform.ZOOM && !zoomIntegrationEnabled && (
                <p className="label ml-4 text-alert">Not connected</p>
              )}
            </div>
          )
        }}
      />
    </div>
  )
}
