import clsx from 'clsx'
import { Switch as HeadlessSwitch, SwitchProps as HeadlessSwitchProps } from '@headlessui/react'

type SwitchProps = HeadlessSwitchProps

export function Switch(props: SwitchProps) {
  const { className, ...rest } = props
  return (
    <HeadlessSwitch
      className={clsx(
        'relative inline-flex h-5 w-9 shrink-0 cursor-default rounded-[20px] border-2 border-transparent bg-light transition-all duration-100 ease-in hover:duration-200 hover:ease-out focus:outline-none focus-visible:ring-2 active:duration-200 active:ease-out ui-checked:bg-accent',
        props.disabled && 'opacity-50',
        className,
      )}
      {...rest}
    >
      <span
        aria-hidden="true"
        className={clsx(
          'pointer-events-none inline-block size-4 transform rounded-full bg-white shadow-rift ring-0 transition-all duration-100 ease-in hover:duration-200 hover:ease-out active:duration-200 active:ease-out',
          props.checked ? 'translate-x-4' : 'translate-x-0',
        )}
      />
    </HeadlessSwitch>
  )
}
