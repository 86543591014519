import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { Disclosure, Popover } from '@headlessui/react'
import { CaretRight } from '@phosphor-icons/react'
import {
  useCampaignEstimatedEndDate,
  useCampaignLaunch,
  useCampaignRecommendation,
  useCampaignStop,
} from '@/api/campaigns'
import { Campaign } from '@/api/core'
import { FeatureFlag } from '@/containers/FeatureFlag'
import { dateFormat } from '@/lib/date'
import { OpacityTransition, Text } from '@/ui'
import { Button, variantActiveClasses, variantDefaultClasses, variantHoverClasses } from '@/ui/Button/v2'
import { ErrorText } from '@/ui/Text/Error'
import { isRunning } from '../utility'
import { AdvancedSettings } from './AdvancedSettings'
import { Schedule } from './Schedule'
import { StartDateSection } from './StartDateSection'

export function Sidebar({ campaign }: { campaign: Campaign }) {
  const { data: estimatedEndDateData } = useCampaignEstimatedEndDate(campaign.id)
  return (
    <>
      <Schedule campaign={campaign} />
      <StartDateSection campaign={campaign} />
      <AdvancedSettings campaign={campaign} />
      <section className="flex items-baseline py-3.5">
        <Text className="text-sm font-medium text-dusk">End date</Text>
        <Text className="ml-auto">
          {estimatedEndDateData?.data.est_end_date ? dateFormat(estimatedEndDateData.data.est_end_date) : '—'}
        </Text>
      </section>
      <LaunchSection campaign={campaign} />
      {campaign.permissions.launch.deny && (
        <FeatureFlag>
          <section>{campaign.permissions.launch.reason}</section>
        </FeatureFlag>
      )}
      <FeatureFlag className="bt mt-4 pb-2">
        <section className="mt-4 border-t pt-4">
          <span className="text-desk text-sm font-medium">Writing assistant</span>
          {campaign.recommendations &&
            campaign.recommendations.length > 0 &&
            campaign.recommendations.map((recommendation) => (
              <Disclosure key={`basic-${recommendation.title}`}>
                {({ open }) => (
                  <div className="mt-2">
                    <Disclosure.Button className="flex w-full items-center">
                      <CaretRight weight="fill" className={clsx('h-4 w-4', open && 'rotate-90 transform')} />
                      <span className="text-sm font-medium text-dusk">{recommendation.title}</span>
                    </Disclosure.Button>
                    <OpacityTransition>
                      <Disclosure.Panel className="ml-4 text-sm text-dusk">{recommendation.text}</Disclosure.Panel>
                    </OpacityTransition>
                  </div>
                )}
              </Disclosure>
            ))}
          <Recommendation buttonName="LLM Recommendations" campaingId={campaign.id} promptEngine="llm" />
        </section>
        <section className="mt-4 border-t pt-4">
          <span className="text-desk text-sm font-medium"></span>
          <Recommendation buttonName="WordWare Recommendations" campaingId={campaign.id} promptEngine="wordware" />
        </section>
      </FeatureFlag>
    </>
  )
}

type RecommendationProps = {
  buttonName: string
  campaingId: string
  promptEngine: 'llm' | 'wordware'
}

function Recommendation(props: RecommendationProps) {
  const { data, status, refetch, isFetched } = useCampaignRecommendation(props.campaingId, props.promptEngine)

  return (
    <>
      <Button variant="basic" className="m-auto flex gap-1" onClick={() => refetch()}>
        {props.buttonName}
      </Button>
      {status === 'pending' && isFetched && <span>Loading...</span>}
      {status === 'error' && <span>Error!</span>}
      {status === 'success' &&
        data &&
        data.data.length > 0 &&
        data.data.map((recommendation) => (
          <Disclosure key={`${props.promptEngine}-${recommendation.title}`}>
            {({ open }) => (
              <div className="mt-2">
                <Disclosure.Button className="flex w-full items-center">
                  <CaretRight weight="fill" className={clsx('h-4 w-4', open && 'rotate-90 transform')} />
                  <span className="text-sm font-medium text-dusk">{recommendation.title}</span>
                </Disclosure.Button>
                <OpacityTransition>
                  <Disclosure.Panel className="ml-4 text-sm text-dusk">{recommendation.text}</Disclosure.Panel>
                </OpacityTransition>
              </div>
            )}
          </Disclosure>
        ))}
    </>
  )
}

function LaunchSection({ campaign }: { campaign: Campaign }) {
  return (
    <section className="flex items-baseline py-3.5">
      {!campaign.permissions.edit_launch.deny ? (
        <>
          {isRunning(campaign) ? (
            <StopButton campaign={campaign} />
          ) : !campaign.permissions.launch.deny ? (
            <LaunchButton campaign={campaign} />
          ) : (
            <ErrorButton campaign={campaign} />
          )}
        </>
      ) : (
        <>Sequence is {isRunning(campaign) ? 'running' : 'stopped'}</>
      )}
    </section>
  )
}

function ErrorButton({ campaign }: { campaign: Campaign }) {
  return (
    <Popover className="relative w-full">
      <Popover.Button
        className={twMerge(
          'flex items-center justify-center',
          variantDefaultClasses.basic,
          variantHoverClasses.basic,
          variantActiveClasses.basic,
          'w-full bg-transparent',
        )}
      >
        Start sequence...
      </Popover.Button>

      <Popover.Panel className="absolute right-0 top-[45px] z-10 rounded-lg border border-light bg-white p-6 shadow">
        {({ close }) => (
          <div className="w-[350px]">
            <Text variant="subtitle" className="text-alert">
              Can't launch sequence:
            </Text>
            <ErrorText errorMessage={campaign.permissions.launch.reason} />
            <div className="flex justify-end gap-3">
              <Button variant="basic" onClick={() => close()}>
                Close
              </Button>
            </div>
          </div>
        )}
      </Popover.Panel>
    </Popover>
  )
}

function LaunchButton({ campaign }: { campaign: Campaign }) {
  const campaignLaunch = useCampaignLaunch()
  const navigate = useNavigate()

  function handleLaunchButtonPress() {
    campaignLaunch.mutate(
      { campaignId: campaign.id },
      {
        onSuccess: () => navigate('/sequences', { replace: true }),
      },
    )
  }

  return (
    <Popover className="relative w-full">
      <Popover.Button
        className={twMerge(
          'flex items-center justify-center',
          variantDefaultClasses.accent,
          variantHoverClasses.accent,
          variantActiveClasses.accent,
          'w-full',
        )}
      >
        Start sequence...
      </Popover.Button>

      <Popover.Panel className="absolute right-0 top-[45px] z-10 rounded-lg border border-light bg-white p-6 shadow">
        {({ close }) => (
          <div className="w-[350px]">
            <Text variant="subtitle">Start sequence</Text>
            <Text>By pressing launch you will start your sequence. Would you like to continue?</Text>
            <div className="flex justify-end gap-3">
              <Button variant="basic" onClick={() => close()}>
                Cancel
              </Button>
              <Button variant="accent" onClick={handleLaunchButtonPress}>
                Launch
              </Button>
            </div>
          </div>
        )}
      </Popover.Panel>
    </Popover>
  )
}

function StopButton({ campaign }: { campaign: Campaign }) {
  const campaignStop = useCampaignStop()

  function handleLaunchButtonPress() {
    campaignStop.mutate({ campaignId: campaign.id })
  }

  return (
    <Button variant="accent" className="w-full" onClick={handleLaunchButtonPress}>
      Stop sequence
    </Button>
  )
}
