import { useEffect } from 'react'
import { useController, useForm } from 'react-hook-form'
import clsx from 'clsx'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { TrashSimple } from '@phosphor-icons/react'
import { ManualStepDraftProduct } from '@/api/core'
import { useManualStepsReplySave } from '@/api/manual_step'
import { useDebounceValue } from '@/hooks'
import { yupValidate } from '@/lib/template'
import { Button, EmailEditor, Text, Toolbar } from '@/ui'
import { PreviewButton } from '@/ui/EmailEditor/styled/PreviewButton'
import { ErrorText } from '@/ui/Text/Error'
import { SingleStep } from '../SingleStep'

type EditorProps = {
  variables: Record<string, string>
  subject: string
  body: string
  replyId: string
  onSend: () => void
  onDelete: () => void
  displayName: string
}

export function Editor({ variables, body, onDelete, onSend, replyId, subject, displayName }: EditorProps) {
  const saveDraft = useManualStepsReplySave(replyId)
  const [debounced, debouncedBody, setBody] = useDebounceValue(body, { delay: 200 })

  useEffect(() => {
    if (debouncedBody === body) return
    // TODO: implement
    saveDraft.mutate({
      subject: subject,
      body: debouncedBody,
      to: [],
      cc: [],
      bcc: [],
      product: ManualStepDraftProduct.RECOMMENDATIONS,
    })
  }, [debouncedBody])

  const schema = yup.object().shape({
    body: yup.string().required('').test({ test: yupValidate }),
  })

  const {
    control,
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: { body },
    mode: 'all',
    resolver: yupResolver(schema),
  })

  const {
    field: { onChange: onBodyChangeForm },
  } = useController({ control, name: 'body' })

  const onBodyChange = (value: string) => {
    setBody(value)
    onBodyChangeForm(value)
  }

  return (
    <SingleStep.Card>
      <Text className="text-medium">
        <span className="font-medium text-success">Draft</span> to{' '}
        <span className="font-medium text-dark">{displayName}</span>
      </Text>
      <form onSubmit={handleSubmit(onSend)}>
        <EmailEditor
          variables={variables}
          displaySubject={false}
          body={body}
          setBody={onBodyChange}
          className="min-h-48"
        >
          <Toolbar>
            <Toolbar.Full />
          </Toolbar>
          <div className="flex justify-between">
            <div className="flex gap-2">
              <Button
                className={clsx('w-fit', saveDraft.isPending && 'pointer-events-none')}
                type="submit"
                disabled={!isValid || isSubmitting || saveDraft.isPending || debounced != body}
              >
                Send
              </Button>
              <PreviewButton className="w-fit" variant="basic" />
            </div>

            <Button className="rounded-full px-2" onClick={onDelete} type="button">
              <TrashSimple className="h-4 w-4 text-medium" />
            </Button>
          </div>
        </EmailEditor>
      </form>

      {errors?.body?.message && <ErrorText errorMessage={`Body error: ${errors.body.message}`} />}
    </SingleStep.Card>
  )
}
