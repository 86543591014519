import { Fragment, Node, ResolvedPos, Slice } from 'prosemirror-model'
import Color from '@tiptap/extension-color'
import Document from '@tiptap/extension-document'
import History from '@tiptap/extension-history'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import Text from '@tiptap/extension-text'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import { EditorEvents, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Emoji, { gitHubEmojis } from '@tiptap-pro/extension-emoji'
import { DisableEnter } from './extension/DisableEnter'
import { getEmojiSuggestions } from './extension/emoji/suggestion'
import { UnsubscribeLink } from './extension/unsubscribe-link'
import { getSuggestions } from './extension/variable/suggestion'
import { fieldKeyName, VariableSuggestions } from './extension/variable/VariableSuggestions'
import { forcePasteAsPlainText } from './forcePasteAsPlainText'
import { putBrsInEmptyPTags } from './putBrsInEmptyPTags'

type EmailEditorOptions = {
  content: string
  placeholder?: string
  variables: string[]
  onChange?: (html: string, text: string) => void
  onFocus?: () => void
  onBlur?: () => void
  'data-testid'?: string
}

export function useSubjectEditor(options: EmailEditorOptions) {
  const emojiSuggestions = getEmojiSuggestions()

  return useEditor({
    content: options.content,
    onUpdate: ({ editor }) => {
      options.onChange?.(editor.getHTML(), editor.getText())
    },
    onFocus: options.onFocus,
    // BUG: do not pass options.onBlur directly, because it will be called anyway
    onBlur: () => options.onBlur?.(),
    autofocus: 'end',
    extensions: [
      // Do not use starter kit, because it adds extensions that
      // can't be used in the email subject like Bold, Italic, etc.
      // Add extensions one by one instead, and the one that are
      // needed for the subject.
      Document,
      Paragraph,
      Text,
      History,
      DisableEnter,
      Emoji.configure({
        emojis: gitHubEmojis,
        enableEmoticons: true,
        suggestion: emojiSuggestions,
      }),
      ...(options.variables.length > 0
        ? [
            VariableSuggestions.configure({
              HTMLAttributes: {
                class: fieldKeyName,
              },
              suggestion: getSuggestions(options.variables.map((v: string) => v + '}}')),
            }),
          ]
        : []),

      ...(options.placeholder
        ? [
            Placeholder.configure({
              placeholder: options.placeholder,
            }),
          ]
        : []),
    ],
  })
}

export function useBodyEditor(options: EmailEditorOptions) {
  const emojiSuggestions = getEmojiSuggestions()

  const onChange = (props: EditorEvents['create' | 'update']) => {
    const { editor } = props
    const isCreateEvent = !('transaction' in props) || props.transaction?.steps.length === 0

    // Do not call onChange for create event to prevent unnecessary saves to the db in sequences
    if (!options.onChange || isCreateEvent) return

    const html = putBrsInEmptyPTags(editor.getHTML())
    options.onChange(html, editor.getText())
  }
  const editorAttributes = {} as Record<string, string>
  if (options['data-testid']) {
    editorAttributes['data-testid'] = options['data-testid']
  }

  return useEditor({
    content: options.content,

    editorProps: {
      clipboardTextParser: (text: string, context: ResolvedPos) => {
        const blocks = text.split(/(?:\r\n?|\n)/)
        const nodes: Node[] = []

        blocks.forEach((line) => {
          const nodeJson: any = { type: 'paragraph' }
          if (line.length > 0) {
            nodeJson.content = [{ type: 'text', text: line }]
          }
          nodes.push(Node.fromJSON(context.doc.type.schema, nodeJson))
        })

        const fragment = Fragment.fromArray(nodes)
        return Slice.maxOpen(fragment)
      },
      handlePaste: forcePasteAsPlainText,
      attributes: editorAttributes,
    },
    onUpdate: onChange,
    onFocus: options.onFocus,
    // BUG: do not pass options.onBlur directly, because it will be called anyway
    onBlur: () => options.onBlur?.(),
    autofocus: 'end',
    extensions: [
      StarterKit.configure({
        horizontalRule: {
          // custom style for the horizontal rule, so it looks thin and subtle
          HTMLAttributes: { style: 'margin-top:0.25rem; margin-bottom:0.25rem; opacity:0.50' },
        },
        paragraph: {
          // custom style for the paragraph, so it looks like a normal text without indent
          // NOTE: this is needed so emails look good in the email clients
          HTMLAttributes: { style: 'margin: 0' },
        },
        hardBreak: false,
      }),
      ...(options.placeholder
        ? [
            Placeholder.configure({
              placeholder: options.placeholder,
            }),
          ]
        : []),
      TextStyle,
      Underline,
      Image,
      Color,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Link.configure({
        openOnClick: false,
        validate: (href) => /^https?:\/\//.test(href),
        protocols: ['https', 'mailto', 'tel'],
        HTMLAttributes: { style: 'text-decoration:none' },
      }),
      Emoji.configure({
        emojis: gitHubEmojis,
        enableEmoticons: true,
        suggestion: emojiSuggestions,
      }),
      ...(options.variables.length > 0
        ? [
            VariableSuggestions.configure({
              HTMLAttributes: {
                class: fieldKeyName,
              },
              suggestion: getSuggestions(options.variables.map((v: string) => v + '}}')),
            }),
          ]
        : []),
      UnsubscribeLink.configure({
        openOnClick: false,
        HTMLAttributes: {
          style: 'text-decoration: none',
        },
      }),
    ],
  })
}
