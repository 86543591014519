import { useMemo } from 'react'
import clsx from 'clsx'
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { CampaignAnalyticsStep, Float } from '@/api/core'
import { formatInteger, formatPercent } from '@/pages/utils'
import { useSequenceAnalyticsContext } from '@/providers/Sequences/SequenceAnalyticsContext'

type AnalyticsTableRow = Omit<CampaignAnalyticsStep, 'step_no'> & {
  step_no: number | string
  variant?: string
  improvement?: string
  probability?: JSX.Element
}

export function Table() {
  const { state: analyticsContext } = useSequenceAnalyticsContext()

  if (!analyticsContext.analytics?.steps_graph_data) return <div className="skeleton h-32 w-full" />

  const tableData = analyticsContext.analytics?.steps

  const formatRate = (rate: Float | number | undefined) => {
    if (rate === undefined) return ''

    return formatPercent(rate, 0)
  }

  const hasABTest = tableData.some((s) => s.ab_test)

  const columnHelper = createColumnHelper<AnalyticsTableRow>()

  const ABColumns = useMemo(
    () =>
      tableData.reduce((acc, cur) => {
        return [
          ...acc,
          cur,
          ...(cur.a
            ? [
                {
                  step_no: '',
                  variant: 'A: Control',
                  probability: <></>,
                  ...cur.a,
                },
              ]
            : []),
          ...(cur.b
            ? [
                {
                  step_no: '',
                  variant: 'B: Variant',
                  probability: (
                    <>
                      {formatRate(cur.ab_test?.open_better_probability)}
                      {'\n'}
                      {formatRate(cur.ab_test?.reply_better_probability)}
                    </>
                  ),
                  ...cur.b,
                },
              ]
            : []),
        ]
      }, [] as AnalyticsTableRow[]),
    [tableData],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor('step_no', {
        header: '',
        cell: (info) => {
          return !info.row.original.variant && <span>Step {info.getValue()}</span>
        },
      }),
      // TODO: Actually find if there is an AB test
      // eslint-disable-next-line no-constant-condition
      ...(hasABTest
        ? [
            columnHelper.accessor('variant', { header: 'Test' }),

            columnHelper.accessor('probability', {
              header: 'Probability',
              cell: (info) => <span className="whitespace-pre-line">{info.getValue()}</span>,
            }),
          ]
        : []),
      columnHelper.accessor((row) => row.scheduled, {
        header: 'Scheduled',
        cell: (info) => <>{formatInteger(info.getValue())}</>,
        meta: {
          classname: 'w-30 text-end tabular-nums',
        },
      }),
      columnHelper.accessor((row) => row.contacted, {
        header: 'Contacted',
        cell: (info) => <>{formatInteger(info.getValue())}</>,
      }),
      columnHelper.accessor((row) => row.opened, {
        header: 'Opened',
        cell: (info) => <>{formatInteger(info.getValue())}</>,
      }),
      columnHelper.accessor((row) => row.replied, {
        header: 'Replied',
        cell: (info) => <>{formatInteger(info.getValue())}</>,
      }),
      columnHelper.accessor((row) => row.meeting_booked, {
        header: 'Meetings',
        cell: (info) => <>{formatInteger(info.getValue())}</>,
      }),
      columnHelper.accessor((row) => row.unsubscribed, {
        header: 'Unsubscribed',
        cell: (info) => <>{formatInteger(info.getValue())}</>,
      }),
      columnHelper.accessor((row) => row.bounced, {
        header: 'Bounced',
        cell: (info) => <>{formatInteger(info.getValue())}</>,
      }),
    ],
    [],
  )

  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    columns,
    data: tableData.length > 0 ? ABColumns : [],
  })

  return (
    <table className=" w-full pt-2">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className="border-b">
            {headerGroup.headers.map((header, index) => (
              <th
                key={header.id}
                className={clsx('p-2 text-left text-sm font-medium', index !== 0 && 'text-end tabular-nums')}
              >
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row, rowIndex) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell, cellIndex) => (
              <td
                key={cell.id}
                className={clsx(
                  'p-2',
                  rowIndex !== table.getRowModel().rows.length - 1 &&
                    !row.original.ab_test &&
                    row.original.variant !== 'A: Control' &&
                    'border-b',
                  cellIndex !== 0 && 'text-end tabular-nums',
                  row.original.variant === 'A: Control' && cellIndex === 1 && 'text-medium',
                )}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
