import tippy, { GetReferenceClientRect, Instance } from 'tippy.js'
import type { Editor } from '@tiptap/core'
import { ReactRenderer } from '@tiptap/react'
import type { SuggestionKeyDownProps, SuggestionProps } from '@tiptap/suggestion'
import { ContextMenu, ContextMenuRef } from './ContextMenu'

export function getEmojiSuggestions() {
  return {
    items: ({ editor, query }: { editor: Editor; query: string }) => {
      return editor.storage.emoji.emojis
        .filter(({ shortcodes, tags }: { shortcodes: string[]; tags: string[] }) => {
          return (
            shortcodes.find((shortcode: string) => shortcode.startsWith(query.toLowerCase())) ||
            tags.find((tag: string) => tag.startsWith(query.toLowerCase()))
          )
        })
        .slice(0, 8)
    },

    allowSpaces: false,

    render: () => {
      let component: ReactRenderer<ContextMenuRef>
      let popup: Instance[]

      return {
        onStart: (props: SuggestionProps<ContextMenuRef>) => {
          component = new ReactRenderer(ContextMenu, {
            props,
            editor: props.editor,
          })

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect as GetReferenceClientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          })
          return
        },

        onUpdate(props: SuggestionProps) {
          component.updateProps(props)

          popup[0].setProps({
            getReferenceClientRect: props.clientRect as GetReferenceClientRect,
          })
        },

        onKeyDown(props: SuggestionKeyDownProps) {
          if (props.event.key === 'Escape') {
            popup[0].hide()
            component.destroy()

            return true
          }

          if (!component.ref) {
            return false
          }

          return component.ref.onKeyDown(props)
        },

        onExit() {
          popup[0].destroy()
          component.destroy()
        },
      }
    },
  }
}
