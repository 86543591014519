import { PropsWithChildren } from 'react'
import clsx from 'clsx'
import { Text } from '@/ui/Text'
import { EmailEditor as HeadlessEmailEditor } from '../EmailEditor'

type EmailEditorFnProps = {
  className?: string
  variables?: Record<string, string>

  subject?: string
  subjectPlaceholder?: string
  setSubject?: (content: string) => void
  displaySubject?: boolean

  body?: string
  bodyPlaceholder?: string
  setBody?: (content: string) => void
}

export function EmailEditor(props: PropsWithChildren<EmailEditorFnProps>) {
  const {
    className,
    variables,
    subject,
    subjectPlaceholder,
    setSubject,
    body,
    bodyPlaceholder,
    setBody,
    children,
    displaySubject = true,
  } = props
  return (
    <HeadlessEmailEditor as="div" variables={variables} className={clsx('flex grow flex-col gap-4', className)}>
      {({ preview }) => (
        <>
          {displaySubject && (
            <div className="flex items-center gap-2">
              {!preview && <Text className="font-medium">Subject</Text>}
              <HeadlessEmailEditor.Subject
                placeholder={subjectPlaceholder}
                content={subject}
                onChange={setSubject}
                className="w-full"
              />
              {preview && <HeadlessEmailEditor.PreviewSubject />}
            </div>
          )}

          <div className="grow">
            <HeadlessEmailEditor.Body
              placeholder={bodyPlaceholder}
              content={body}
              onChange={setBody}
              className="h-full w-full"
            />
            {preview && <HeadlessEmailEditor.PreviewBody />}
          </div>

          {children}
        </>
      )}
    </HeadlessEmailEditor>
  )
}
