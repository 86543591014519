import { useSearchParams } from 'react-router-dom'

// This hook allows storage of a string in the queryParam `paramName`.
// The setter function will remove the query param from the URL if passed `null`.
export function useQueryParamState(paramName: string): [string | null, (arg: string | null) => void] {
  const [searchParams, setSearchParams] = useSearchParams()

  function setQueryParamValue(value: string | null) {
    setSearchParams((prev) => {
      if (value === null) {
        prev.delete(paramName)
      } else {
        prev.set(paramName, value)
      }
      return prev
    })
  }

  return [searchParams.get(paramName), setQueryParamValue]
}

// This hook allows storage of a string in the queryParam `paramName` with a default value
// The setter function will remove the query param from the URL if passed `null`.
export function useQueryParamStateWithDefault(
  paramName: string,
  defaultValue: string,
): [string, (arg: string | null) => void] {
  const [queryParamStateValue, setQueryParamValue] = useQueryParamState(paramName)

  return [queryParamStateValue || defaultValue, setQueryParamValue]
}
