import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { DefaultService, Profile } from '@/api/core'

export const queryKeys = {
  memberProfile: () => ['member-profile'] as const,
}

export function useMemberProfile() {
  return useQuery({
    queryKey: queryKeys.memberProfile(),
    queryFn: () => DefaultService.getProfile(),
  })
}

export function useUpdateMemberProfile() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKeys.memberProfile() }),
    mutationFn: (body: Profile) => DefaultService.patchProfile(body),
  })
}
