import { useEffect } from 'react'
import { Control, Controller, useWatch } from 'react-hook-form'
import { PostMeetingType } from '@/api/core'
import { BOOKING_URL } from '@/config'
import { Input } from '@/ui'

type UrlInputProps = {
  companyShortName?: string
  control: Control<PostMeetingType>
}

function meetingNameToUrlValue(meetingName: string): string {
  const regex = /[^a-zA-Z0-9-_]+/g
  return meetingName.replaceAll(' ', '-').replaceAll(regex, '')
}

export function UrlInput(props: UrlInputProps) {
  const { companyShortName, control } = props
  const descriptionValue = useWatch({ name: 'description' })
  return (
    <div className="relative">
      <Controller
        name="short_name"
        control={control}
        render={({ field, fieldState }) => {
          const url = `${BOOKING_URL}/${companyShortName}/`
          useEffect(() => {
            if (
              !control._defaultValues.short_name &&
              descriptionValue &&
              meetingNameToUrlValue(descriptionValue) != field.value
            ) {
              field.onChange(meetingNameToUrlValue(descriptionValue))
            }
          }, [descriptionValue])
          return (
            <>
              <Input
                label="URL"
                containerClassName="mt-5"
                value={`${url}${field.value ?? ''}`}
                onChange={(e) => {
                  const val = e.target.value.substring(url.length)
                  field.onChange(val)
                }}
              />
              {fieldState.error && <p className="label text-alert">{fieldState.error?.message}</p>}
            </>
          )
        }}
      />
    </div>
  )
}
