import clsx from 'clsx'
import { Check, Plus } from '@phosphor-icons/react'
import { DoubleMapping, DoubleMappings, FieldDefinition, FieldDefinitions } from '@/api/core'
import { Dropdown } from '@/components/Dropdown'
import { ArrowDoubleIcon } from '@/icons/ArrowdoubleIcon'
import { ArrowMergeIcon } from '@/icons/ArrowmergeIcon'
import { ArrowSplitIcon } from '@/icons/ArrowsplitIcon'
import { Button, Text } from '@/ui'

export type MappingDirection = 'rift_to_crm' | 'crm_to_rift' | 'both'

type DoubleMappingProps = {
  title: string
  subtitle: string
  crmName: 'Salesforce' | 'Hubspot'
  riftFields: FieldDefinitions
  crmFields: FieldDefinitions
  crmConvertedFields: FieldDefinitions
  defaultFieldMapping: DoubleMappings
  direction: MappingDirection
  mapping: DoubleMappings
  setMapping: (mapping: DoubleMappings) => void
}

// add handling for patch functions
export function DoubleMappingForm({
  title,
  subtitle,
  crmName,
  direction,
  riftFields,
  crmFields,
  crmConvertedFields,
  mapping,
  defaultFieldMapping,
  setMapping,
}: DoubleMappingProps) {
  const formHeader = () =>
    direction === 'crm_to_rift' ? (
      <>
        <Text variant="subtitle">{crmName}</Text>
        <div></div>
        <Text variant="subtitle">rift</Text>
      </>
    ) : (
      <>
        <Text variant="subtitle">rift</Text>
        <div></div>
        <Text variant="subtitle">{crmName}</Text>
      </>
    )
  return (
    <section>
      <hgroup className="pb-5">
        <Text variant="title" className="pb-2">
          {title}
        </Text>
        <Text variant="subtext" className="text-medium">
          {subtitle}
        </Text>
      </hgroup>
      <div className="grid grid-cols-[minmax(0,_1fr)_40px_minmax(0,_1fr)] gap-y-5">
        {formHeader()}
        {riftFields.map((riftField, i) => (
          <DoubleMappingRow
            key={i}
            riftField={riftField}
            crmFields={crmFields.filter((field) => field.type === riftField.type)}
            crmConvertedFields={crmConvertedFields.filter((field) => field.type === riftField.type)}
            defaultMapping={
              defaultFieldMapping.find((mapping) => mapping.rift === riftField.id) || {
                rift: riftField.id,
                crm: '',
                crmConverted: '',
              }
            }
            direction={direction}
            selected={mapping.find((mapping) => mapping.rift === riftField.id) || undefined}
            onSelect={(m: DoubleMapping | undefined) => {
              const newMapping = mapping.filter((mapping) => mapping.rift !== riftField.id)
              setMapping(m ? newMapping.concat(m) : newMapping)
            }}
          />
        ))}
      </div>
    </section>
  )
}

type DoubleMappingRowProps = {
  riftField: FieldDefinition
  crmFields: FieldDefinitions
  crmConvertedFields: FieldDefinitions
  direction: MappingDirection
  defaultMapping: DoubleMapping | undefined
  selected: DoubleMapping | undefined
  onSelect: (field: DoubleMapping | undefined) => void
}
function DoubleMappingRow({
  riftField,
  crmFields,
  crmConvertedFields,
  direction,
  defaultMapping,
  selected,
  onSelect,
}: DoubleMappingRowProps) {
  const riftButtonSelection = () => (
    <Button
      variant="leading-icon"
      icon={selected ? Check : Plus}
      onClick={() => {
        onSelect(selected ? undefined : defaultMapping)
      }}
      className={clsx('w-full justify-start self-center', selected && 'border-accent')}
      iconclassname="text-medium fill-none"
    >
      {riftField.label}
    </Button>
  )
  const selectedCrm = crmFields.find((field) => field.id === selected?.crm) || null
  const selectedCrmConverted = crmConvertedFields.find((field) => field.id === selected?.crmConverted) || null
  const crmButtonsSelection = () =>
    selected ? (
      <div className="grid h-[88px]">
        <Dropdown
          options={crmFields}
          selected={selectedCrm}
          handleSelection={(selection) => onSelect({ ...selected, crm: selection.id as string })}
        />
        <Dropdown
          className="pt-1"
          options={crmConvertedFields}
          selected={selectedCrmConverted}
          handleSelection={(selection) => onSelect({ ...selected, crmConverted: selection.id as string })}
        />
      </div>
    ) : (
      <div className="grid h-[88px]">
        <Text variant="text" className="self-center text-medium">
          Don't sync
        </Text>
      </div>
    )

  const rowIcon = () => {
    if (!selected) {
      return <span />
    }
    switch (direction) {
      case 'rift_to_crm':
        return <ArrowSplitIcon width={20} height={20} className="-rotate-90 self-center justify-self-center" />
      case 'crm_to_rift':
        return <ArrowMergeIcon width={20} height={20} className="self-center justify-self-center" />
      case 'both':
        return <ArrowDoubleIcon width={20} height={20} className="self-center justify-self-center" />
    }
  }

  return (
    <div className="col-span-3 grid grid-cols-[minmax(222px,_1fr)_40px_minmax(222px,_1fr)] gap-x-3">
      {direction === 'crm_to_rift' ? (
        <>
          {crmButtonsSelection()}
          {rowIcon()}
          {riftButtonSelection()}
        </>
      ) : (
        <>
          {riftButtonSelection()}
          {rowIcon()}
          {crmButtonsSelection()}
        </>
      )}
    </div>
  )
}
