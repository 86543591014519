import { Configuration, FrontendApi } from '@ory/client'
import { AUTH_BACKEND_URL } from '@/config'

export const kratos = new FrontendApi(
  new Configuration({
    basePath: AUTH_BACKEND_URL,
    baseOptions: {
      withCredentials: true,
    },
  }),
)
