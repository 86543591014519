import { useNavigate, useParams } from 'react-router-dom'
import { PatchBlueprint } from '@/api/core'
import { useBlueprint, useUpdateBlueprint } from '@/api/sequence_generation'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { BlueprintForm } from './BlueprintForm'

export function EditBlueprint() {
  const { blueprintId } = useParams() as { blueprintId: string }
  const updateBlueprint = useUpdateBlueprint(blueprintId as string)
  const getBlueprint = useBlueprint(blueprintId as string)
  const navigate = useNavigate()
  const toast = useToast()

  const gotoList = () => {
    navigate('/account-sequences/blueprints')
  }

  const onCreate = (body: PatchBlueprint) => {
    updateBlueprint.mutate(body, {
      onSuccess: () => {
        toast.createToast({ message: 'Blueprint updated' })
        gotoList()
      },
      onError: () => {
        toast.createToast({ message: 'There was an error updating the blueprint', error: true })
        gotoList()
      },
    })
  }

  return (
    <div className="m-auto mt-5 max-w-2xl pb-5">
      {getBlueprint.isSuccess && (
        <BlueprintForm
          data={getBlueprint.data.data}
          onSubmit={onCreate}
          onCancel={gotoList}
          isLoading={updateBlueprint.isPending}
        />
      )}
    </div>
  )
}
