import React, { RefObject, useEffect, useRef, useState } from 'react'
import RangeSlider from 'react-range-slider-input'
import clsx from 'clsx'
import { abbreviateNumber } from '@/lib/utils'
import { Input } from '@/ui'
import { Tooltip } from '@/ui/headless'
import 'react-range-slider-input/dist/style.css'

type Props = {
  min?: number
  max?: number
  total?: number
  value?: number
  onChange: (value: number) => void
  label: string
  id?: string
  disabled?: boolean
  disabledPermissions?: boolean
}

function validateUpdate(
  input: string,
  min: number,
  max: number,
  prevValue: number | undefined,
  onChange: (value: number) => void,
  setInputValue: (value: string) => void,
) {
  if (input === '' || Number.isNaN(Number(input))) {
    setInputValue(String(prevValue) ?? String(min))
    return
  }
  if (Number(input) > max) {
    setInputValue(String(max))
    onChange(max)
    return
  }

  if (Number(input) < min) {
    setInputValue(String(min))
    onChange(min)
    return
  }
  onChange(Number(input))
}

export function DDVSlider(props: Props) {
  const sliderRef = useRef<{ element: RefObject<HTMLElement> }>(null)
  const max = !props.disabled && props.max ? props.max : 0
  const min = !props.disabled && props.min ? props.min : 0
  const total = !props.disabled && props.total ? props.total : 2000

  const stepSize = Math.round((max - min) / 500)

  useEffect(() => {
    const sliderElem = sliderRef.current?.element?.current
    if (sliderElem && !Number.isNaN(props.max) && !Number.isNaN(props.total)) {
      const maxPercentageOfTotal = Math.floor((max / total) * 100).toFixed(2)
      sliderElem.style.backgroundImage = `linear-gradient(to right, #348AF3 0%, #348AF3 ${maxPercentageOfTotal}%, #e5e7ec ${maxPercentageOfTotal}%, #e5e7ec 100%)`
    }
  }, [sliderRef, max, total])
  const [capacityInput, setCapacityInput] = useState<string>(props?.value?.toString() ?? '')
  useEffect(() => {
    if (capacityInput !== props?.value?.toString()) {
      setCapacityInput(props?.value?.toString() ?? '')
    }
  }, [props?.value])

  return (
    <>
      <div className="mb-2 flex items-center">
        <label htmlFor="capacity" className="mr-2 block font-medium text-dark">
          {props.label}
        </label>
        <Tooltip>
          <Tooltip.Trigger className={'relative h-4.5 w-4.5 cursor-pointer rounded-[100px] pl-1 pr-[5px]'}>
            <span className="content-sm-medium absolute inline-flex items-center justify-items-center rounded-[100px] border-[1px] border-light pl-1 pr-[5px] leading-4 hover:bg-light">
              ?
            </span>
          </Tooltip.Trigger>
          <Tooltip.Panel className="content-sm z-30 w-[280px] rounded-[10px] bg-white px-6 py-4 shadow-rift">
            <h2 className="content-md mb-3 leading-6">Dedicated daily volume</h2>
            <p className="mb-3">This shows how many daily emails you can send for this campaign.</p>
            <p className="mb-3">
              rift will recommend how much daily volume to dedicate to the campaign once contacts are imported, email
              steps are created and inbox groups have been selected.
            </p>
            <p>
              Increasing the dedicated daily volume for the campaign might mean it finishes faster. Decreasing means you
              can run more campaigns in parallel.
            </p>
          </Tooltip.Panel>
        </Tooltip>
      </div>
      <div className={clsx('flex items-center', (props.disabled || props.disabledPermissions) && 'opacity-40')}>
        <Input
          className={'mr-3 w-[78px] rounded-md px-4 py-2'}
          type="text"
          value={capacityInput}
          onChange={(e) => setCapacityInput(e.target.value)}
          onBlur={() => {
            validateUpdate(capacityInput, min, max, props.value, props.onChange, setCapacityInput)
          }}
        />
        <div className="flex grow flex-col">
          <RangeSlider
            disabled={props.disabled || props.disabledPermissions}
            ref={sliderRef}
            id={props.id}
            min={props.min}
            max={props.total}
            step={stepSize}
            value={[props.value, props.max]}
            thumbsDisabled={[false, true]}
            onInput={(vals: [number, number]) => {
              if (vals[0] > max) {
                props.onChange(max)
                return
              }
              props.onChange(vals[0])
            }}
            rangeSlideDisabled={true}
            className={'capacity-slider'}
          />
          <div className="relative mt-[6px] flex w-full">
            <span className="absolute left-0 block text-sm text-medium">{abbreviateNumber(min, 1)}</span>
            <span className="absolute right-0 block text-sm text-medium">{abbreviateNumber(total, 1)}</span>
          </div>
        </div>
      </div>
    </>
  )
}
