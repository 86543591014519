import { useLocation } from 'react-router-dom'
import { Button } from '@/ui'

export function ErrorPage() {
  const location = useLocation()

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center" role="alert">
      <h2 className="text-md font-medium leading-6 text-dark">An error was detected</h2>
      <p className="text-sm leading-5 text-dusk">Please reload the page to make it go away.</p>
      <p className="text-sm leading-5 text-dusk">If the error persists, contact our support.</p>
      <Button className="mt-5" onClick={() => window.location.assign(location.pathname)}>
        Reload
      </Button>
    </div>
  )
}
