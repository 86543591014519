import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { AxiosError } from 'axios'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { RecoveryFlow, UpdateRecoveryFlowBody } from '@ory/client'
import { handleFlowError } from '@/config/handleGetFlowError'
import { kratos } from '@/config/kratos'
import { useSCRFToken } from '@/hooks/useCSRFToken'
import { useRouter } from '@/hooks/useRouter'
import { RiftLogo } from '@/icons/RiftLogo'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button } from '@/ui/Button/v2'
import { Input } from '@/ui/Input/v2'
import { Text } from '@/ui/Text'

type ForgotPasswordFormDTO = {
  email: string
}

type ForgotPasswordProps = {
  onSuccess: () => void
}

export function ForgotPasswordForm({ onSuccess }: ForgotPasswordProps) {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email is invalid'),
  })

  const toast = useToast()

  const [flow, setFlow] = useState<RecoveryFlow>()
  const { setCSRFToken, csrfToken } = useSCRFToken()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>({ resolver: yupResolver(validationSchema) })

  // Get ?flow=... from the URL
  const router = useRouter()
  const { flow: flowId, return_to: returnTo } = router.query

  useEffect(() => {
    // If the router is not ready yet, or we already have a flow, do nothing.
    if (flow) {
      return
    }

    // If ?flow=.. was in the URL, we fetch it
    if (flowId) {
      kratos
        .getRecoveryFlow({ id: flowId as string })
        .then(({ data }) => {
          setFlow(data)
          setCSRFToken(data.ui.nodes)
        })
        .catch(handleFlowError(router.push, 'recovery', setFlow, toast.createToast))
      return
    }

    // Otherwise we initialize it
    kratos
      .createBrowserRecoveryFlow()
      .then(({ data }) => {
        setFlow(data)
      })
      .catch(handleFlowError(router.push, 'recovery', setFlow, toast.createToast))
      .catch((err: AxiosError) => {
        // If the previous handler did not catch the error it's most likely a form validation error
        if (err.response?.status === 400) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setFlow(err.response?.data)
          return
        }

        return Promise.reject(err)
      })
  }, [flowId, router, returnTo, flow])

  const onSubmit = (values: ForgotPasswordFormDTO) => {
    if (!flow) return navigate('/recovery', { replace: true })

    const token = csrfToken || setCSRFToken(flow.ui.nodes)
    const sendingValue = {
      method: 'link',
      email: values.email,
      csrf_token: token,
    } as UpdateRecoveryFlowBody

    kratos
      .updateRecoveryFlow({ flow: flow?.id, updateRecoveryFlowBody: sendingValue })
      .then(({ data }) => {
        if (data) {
          // Form submission was successful, show the message to the user!
          setFlow(data)
          onSuccess()
        }
      })
      .catch(handleFlowError(router.push, 'recovery', setFlow, toast.createToast))
      .catch((err: AxiosError) => {
        switch (err.response?.status) {
          case 400:
            // Status code 400 implies the form validation had an error
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setFlow(err?.response?.data)
            return
        }

        throw err
      })
  }

  return (
    <div className="flex min-h-[655px] flex-1 flex-col justify-center self-center pt-12 sm:px-6 lg:px-8">
      <hgroup className="text-center sm:mx-auto sm:w-full sm:max-w-md">
        <RiftLogo className="m-auto" simple={false} width={60} />
        <Text variant="title" className="mt-6">
          Reset your password
        </Text>
      </hgroup>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-8 shadow sm:rounded-lg sm:px-12">
          <form className="space-y-12" action="#" method="POST" onSubmit={handleSubmit(onSubmit)}>
            <hgroup className="text-center">
              <Text variant="text">
                Please enter your email address and we'll send you instructions to reset your password.
              </Text>
            </hgroup>
            <div>
              <Input
                name="identifier"
                id="identifier"
                type="email"
                placeholder="Enter email address"
                autoFocus
                required
                boldLabel
                error={errors['email']?.message}
                registration={register('email')}
              />

              <Button type="submit" variant="accent" className="mt-4 w-full">
                Send password instructions
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
