import { useCallback, useState } from 'react'

export function useDisclosure(defaultIsOpen = false) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen)

  const open = useCallback(() => setIsOpen(true), [])
  const close = useCallback(() => setIsOpen(false), [])
  const toggle = useCallback(() => setIsOpen((state) => !state), [])

  return { isOpen, open, close, toggle }
}

export function useDisclosureV2(defaultIsOpen = false): [boolean, () => void, () => void, () => void] {
  const [open, setOpen] = useState(defaultIsOpen)

  const onOpen = useCallback(() => setOpen(true), [])
  const onClose = useCallback(() => setOpen(false), [])
  const onToggle = useCallback(() => setOpen((state) => !state), [])

  return [open, onOpen, onClose, onToggle]
}
