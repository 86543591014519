import { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { X } from '@phosphor-icons/react'
import { useSenders } from '@/api'
import { PostSenderTestSend, Sender } from '@/api/core'
import { useSenderTestSendEmail } from '@/api/emails'
import { Button, Dialog, Select, Text } from '@/ui'
import { Input } from '@/ui'

type SendTestEmailDialogProps = {
  body: string
  subject: string
  campaignId: string
  contact: Record<string, any>
  isOpen: boolean
  close: () => void
}

export function SendTestEmailDialog(props: SendTestEmailDialogProps) {
  const { contact, isOpen, close, body, subject, campaignId } = props
  const [selected, setSelected] = useState<Sender | undefined>()
  const [sendMode, setSendMode] = useState<PostSenderTestSend.send_mode>(PostSenderTestSend.send_mode.STEP)
  const { data } = useSenders()
  const senders = data?.data || []
  const send = useSenderTestSendEmail()

  const schema = yup.object({
    contacts: yup
      .array(
        yup.object({
          email: yup.string().required().email(),
        }),
      )
      .required()
      .min(1),
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<{ contacts: { email: string }[] }>({
    resolver: yupResolver(schema),
    defaultValues: {
      contacts: [{ email: '' }],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  })

  const onSubmit = (values: { contacts: { email: string }[] }) => {
    send.mutate({
      campaign_id: campaignId,
      senderId: selected?.id as string,
      to: values.contacts.map((c) => c.email),
      subject: subject,
      body: body,
      data: contact,
      send_mode: sendMode,
    })
    close()
  }

  useEffect(() => {
    setSelected(senders?.[0])
  }, [senders])

  return (
    <Dialog open={isOpen} onClose={close}>
      <Dialog.Panel className="max-w-sm ">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <Text variant="subtitle">Send test email</Text>
          <div>
            <Text variant="subtitle" className="text-sm">
              Sender
            </Text>
            <SenderSelect senders={senders} selected={selected} setSelected={setSelected} />
          </div>

          <div>
            <Text variant="subtitle" className="-mb-1.5 text-sm">
              Send to
            </Text>
            {fields.map((field, index) => (
              <div key={field.id} className="relative">
                <Input
                  className="w-full placeholder-gray-400"
                  placeholder="email address"
                  type="email"
                  required
                  registration={register(`contacts.${index}.email`)}
                />

                <div className="absolute right-0 top-3 flex items-center justify-center pr-2">
                  <X className="size-3 text-medium" aria-hidden="true" onClick={() => remove(index)} />
                </div>
              </div>
            ))}
            <Button variant="text" type="button" onClick={() => append({ email: '' })} className="p-0 text-xs">
              Add Email
            </Button>
          </div>

          <fieldset>
            <Text variant="subtitle" className="text-sm">
              Method
            </Text>

            <div className="flex items-center">
              <input
                id="send-step"
                name="send-type"
                type="radio"
                defaultChecked={sendMode === 'step'}
                onClick={() => setSendMode(PostSenderTestSend.send_mode.STEP)}
                className={'h-3 w-3 border-gray-300 text-rift-blue-600 focus:ring-rift-blue-600'}
              />
              <label htmlFor={'step'} className="ml-1 block text-sm">
                Send single email
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="send-sequence"
                name="send-type"
                type="radio"
                defaultChecked={sendMode === 'sequence' && false}
                onClick={() => setSendMode(PostSenderTestSend.send_mode.SEQUENCE)}
                className={'h-3 w-3 border-gray-300 text-rift-blue-600 focus:ring-rift-blue-600'}
              />
              <label htmlFor={'send-sequence'} className="ml-1 block text-sm">
                Send full sequence
              </label>
            </div>
          </fieldset>

          <div className="flex">
            <Button variant="basic" onClick={() => close()} className="ml-auto mr-2 justify-center">
              Cancel
            </Button>
            <Button variant="accent" type="submit" disabled={!isValid || !selected} onClick={handleSubmit(onSubmit)}>
              Send
            </Button>
          </div>
        </form>
      </Dialog.Panel>
    </Dialog>
  )
}

type SenderSelectProps = {
  senders: Sender[]
  selected: Sender | undefined
  setSelected: (sender: Sender) => void
}

function SenderSelect({ senders, selected, setSelected }: SenderSelectProps) {
  function formatSenderOption({ first_name, last_name, email, id }: Sender) {
    return { label: `${first_name} ${last_name} <${email}>`, value: id }
  }

  function handleSelect(selected: { label: string; value: string } | null) {
    if (selected) {
      setSelected(senders.filter((sender) => sender.id === selected.value)[0])
    }
  }

  return (
    <Select
      options={senders.map((sender) => formatSenderOption(sender))}
      value={selected ? formatSenderOption(selected) : null}
      onChange={handleSelect}
    />
  )
}
