import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { DefaultService } from '@/api/core'
import { PostInboxesMain } from './core/models/PostInboxesMain'

export const queryKey = {
  list: () => ['inboxes'] as const,
  mainInbox: () => ['inboxes', 'main'] as const,
}

export function useInboxes() {
  return useQuery({
    queryKey: queryKey.list(),
    queryFn: DefaultService.getInboxes,
  })
}

export function useMainInboxes() {
  return useQuery({
    queryKey: queryKey.mainInbox(),
    queryFn: DefaultService.getInboxesMain,
  })
}

export function useManualInboxesSave() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (requestBody: PostInboxesMain) => {
      return DefaultService.postInboxesMain(requestBody)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.mainInbox() })
    },
  })
}

export function useManualInboxesDelete() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => {
      return DefaultService.deleteInboxesMain()
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.mainInbox() })
    },
  })
}
