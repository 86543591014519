import { ButtonHTMLAttributes } from 'react'
import clsx from 'clsx'

const variants = {
  primary: 'bg-rift-blue-600 border-transparent text-white',
  secondary: 'bg-white border-rift-blue-600 text-secondary',
  inverse: 'bg-white border-gray-600 text-gray-600',
  outline: 'bg-white text-red-600 border border-gray-200',
  danger: 'bg-red-600 text-white font-medium text-sm px-5 py-2.5 text-center',
  alternative:
    'bg-transparent text-primary hover:bg-transparent shadow-none focus:outline-0 focus:ring-0 focus:ring-offset-0 border-transparent',
}

const hover = {
  primary: 'hover:bg-white hover:text-primary hover:border-primary',
  secondary: 'hover:text-white hover:bg-rift-blue-600',
  inverse: '',
  outline: 'hover:bg-[#f8f8f8]',
  danger: 'hover:bg-red-700',
  alternative: 'hover:text-secondary hover:bg-transparent',
}

const sizes = {
  xxs: 'px-1.5 py-1.5 text-xs',
  xs: 'px-3 py-2 text-sm',
  sm: 'px-4 py-2 text-sm',
  md: 'px-8 py-2 text-base',
  lg: 'px-8 py-3 text-base',
  slim: 'px-16 py-1 text-base',
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: keyof typeof variants
  size?: keyof typeof sizes
}

export function Button({ type = 'button', className = '', variant = 'primary', size = 'md', ...props }: ButtonProps) {
  return (
    <button
      type={type}
      className={clsx(
        'inline-flex items-center border font-medium shadow-sm',
        props.disabled ? 'cursor-not-allowed bg-gray-400' : hover[variant],
        variants[variant],
        sizes[size],
        className,
      )}
      {...props}
    >
      {props.children && props.children}
    </button>
  )
}
