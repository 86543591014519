import { useContext, useState } from 'react'
import { FormContext } from '@/providers/Emails/context'

export function FormProvider({ children }: any) {
  const [data, setData] = useState({})

  const setFormValues = (values: any) => {
    setData((prevValues) => ({
      ...prevValues,
      ...values,
    }))
  }

  return <FormContext.Provider value={{ data, setFormValues }}>{children}</FormContext.Provider>
}

export function useFormData() {
  return useContext(FormContext)
}
