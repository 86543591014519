import { OnboardingDetails, OnboardingStatus } from '@/api/core'
import {
  useOnboardingContinue,
  useOnboardingDomains,
  useOnboardingInboxes,
  useOnboardingStepRetry,
} from '@/api/onboarding'
import { ButtonLoading, Text } from '@/ui'
import { Step } from '@/ui/headless'

type GenericStepProps = {
  onboarding: OnboardingDetails
}

export function GenericStep(props: GenericStepProps) {
  const { onboarding } = props
  const { data: domains, status: domainsStatus } = useOnboardingDomains()
  const { data: inboxes, status: inboxesStatus } = useOnboardingInboxes()
  const onboardingContinue = useOnboardingContinue()
  const onboardingRetryStep = useOnboardingStepRetry()

  const onContinue = () => onboardingContinue.mutate()
  const onStepRetry = () => onboardingRetryStep.mutate()

  return (
    <div className="flex flex-col">
      <Text variant="title" className="mb-8">
        {onboarding.stepName}
      </Text>

      {onboarding.status === OnboardingStatus.ERROR && (
        <div>
          <Text className="inline-block w-96 break-words break-all text-alert">Error: {onboarding.errorReason}</Text>
          <div className="mb-2 mt-8 flex justify-start gap-2">
            <ButtonLoading variant="accent" onClick={onStepRetry} isLoading={onboardingRetryStep.isPending}>
              Retry
            </ButtonLoading>
            <Step.Next
              as={ButtonLoading}
              variant="accent"
              onClick={onContinue}
              isLoading={onboardingContinue.isPending}
            >
              Continue
            </Step.Next>
          </div>
          <Text variant="subtitle">
            NOTE: if you continue it means that the error was resolved the step is completed
          </Text>
        </div>
      )}
      {onboarding.status === OnboardingStatus.IN_PROGRESS && (
        <>
          <Text variant="text" className="text-accent">
            In progress
          </Text>
        </>
      )}
      {onboarding.status === OnboardingStatus.ACTION_REQUIRED && (
        <>
          <Text variant="text">Manual step action required - once it's done click:</Text>
          <div className="mt-4">
            <Step.Next
              as={ButtonLoading}
              variant="accent"
              onClick={onContinue}
              isLoading={onboardingContinue.isPending}
            >
              Continue
            </Step.Next>
          </div>
        </>
      )}

      {domainsStatus === 'success' && (
        <div className="my-4">
          <Text variant="subtitle">Domains:</Text>
          {domains.data.domains.map((domain) => (
            <div key={domain.id} className="my-0.5">
              <Text variant="text">{domain.domain}</Text>
            </div>
          ))}
        </div>
      )}
      {inboxesStatus === 'success' && (
        <div className="my-4">
          <Text variant="subtitle">Inboxes:</Text>
          {inboxes.data.inboxes.map((inbox) => (
            <div key={inbox.id} className="my-0.5">
              <Text variant="text">
                {inbox.firstName} {inbox.lastName} &lt;
                <b>
                  {inbox.login}@{inbox.emailDomain}
                </b>
                &gt; forwarded to {inbox.forwardToEmail}
              </Text>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
