import { Text } from '@/ui'

export function Support() {
  return (
    <div className="mt-16 flex flex-col items-center justify-center">
      <section>
        <Text variant="title" className="pb-5">
          rift support
        </Text>
        <Text variant="subtitle">Hours of operations</Text>
        <Text variant="text">6 am PT - 6 pm PT</Text>
        <Text variant="subtitle">First response SLA:</Text>
        <Text variant="text">30 min</Text>
        <Text variant="subtitle">Email support</Text>
        <Text variant="text">support@getrift.com</Text>
        <Text variant="subtitle">Live support</Text>
        <Text variant="text">Slack channel</Text>
        <Text variant="text">Chat within the rift app (under Help button)</Text>
      </section>
    </div>
  )
}
