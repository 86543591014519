import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { X } from '@phosphor-icons/react'
import { Campaign } from '@/api/core'
import { durationSince } from '@/lib/date'
import { Text } from '@/ui'
import { HeaderDropMenu } from './HeaderDropMenu'
import { SequenceName } from './SequenceName'

export function Header({ className, campaign }: { className: string; campaign: Campaign | undefined }) {
  const navigate = useNavigate()

  return (
    <header className={clsx(className, 'flex items-center')}>
      <button className="h-9" onClick={() => navigate('/sequences')}>
        <span className="sr-only">Close</span>
        <X className="size-5" aria-hidden="true" />
      </button>
      {campaign && <SequenceName campaign={campaign} />}
      <div className="ml-auto flex items-center">
        {campaign && <UpdatedTime campaign={campaign} />}
        {campaign && <HeaderDropMenu campaign={campaign} />}
      </div>
    </header>
  )
}

function UpdatedTime({ campaign }: { campaign: Campaign }) {
  const [updatedAgo, setUpdatedAgo] = useState<string | null>()

  useEffect(() => {
    const timeoutID = setTimeout(() => setUpdatedAgo(durationSince(new Date(campaign.updated_at))), 60000)

    return () => clearTimeout(timeoutID)
  }, [])

  return (
    <Text className="px-6 text-medium">{campaign && (updatedAgo || durationSince(new Date(campaign.updated_at)))}</Text>
  )
}
