import { Fragment, useRef, useState } from 'react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { Combobox, Transition } from '@headlessui/react'
import { CaretUpDown, Check, MagnifyingGlass } from '@phosphor-icons/react'

export type DropdownOption = {
  label: string
  id: string | number
}

type DropdownParams = {
  options: DropdownOption[]
  selected: DropdownOption | null
  handleSelection: (selection: DropdownOption) => void
  formatDisplayValue?: (selection: DropdownOption) => string
  placeholder?: string
  className?: string
  error?: boolean
  ariaLabel?: string
}
/**
 * This search allows users to search a dropdown list. For mor information checkout https://headlessui.com/react/combobox
 *
 * @returns JSX.Elemnt
 */
export function Dropdown({
  options,
  selected,
  handleSelection,
  formatDisplayValue,
  placeholder,
  className,
  error,
  ariaLabel,
}: DropdownParams): JSX.Element {
  const [query, setQuery] = useState('')
  const [tempPlaceholder, setTempPlaceholder] = useState(selected ? selected.label : '')
  const buttonRef = useRef<HTMLButtonElement>(null)

  const filteredOption: DropdownOption[] =
    query === ''
      ? options
      : options.filter((person) =>
          person.label.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')),
        )

  function handleComboboxChange(selection: DropdownOption | null) {
    if (!selection) return
    handleSelection(selection)
    setTempPlaceholder(selection.label)
  }

  function displayValue(option: DropdownOption) {
    return formatDisplayValue ? formatDisplayValue(option) : option?.label
  }

  return (
    <Combobox value={selected} onChange={handleComboboxChange}>
      {({ open }) => (
        <div className={twMerge('relative mt-1', className)}>
          <div
            className={clsx(
              'relative w-full cursor-default overflow-hidden rounded-lg border text-left',
              error && 'border-alert',
            )}
          >
            <Combobox.Input
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus-visible:outline-none"
              placeholder={open ? tempPlaceholder : placeholder}
              displayValue={(option: DropdownOption) => (!open ? displayValue(option) : '')}
              onChange={(event) => setQuery(event.target.value)}
              onClick={() => buttonRef.current?.click()}
            />
            <Combobox.Button
              className="absolute inset-y-0 right-0 flex items-center pr-2"
              aria-label={ariaLabel}
              ref={buttonRef}
            >
              {open ? (
                <MagnifyingGlass className="h-4.5 w-4.5 text-gray-400" aria-hidden="true" />
              ) : (
                <CaretUpDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
              )}
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => {
              setQuery('')
            }}
          >
            <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-[var(--input-width)] overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredOption.length === 0 && query !== '' ? (
                <div className="relative select-none px-4 py-2 text-gray-700">Nothing found.</div>
              ) : (
                filteredOption
                  .filter((option) => option.label !== '')
                  .map((option, index) => (
                    <Combobox.Option
                      key={index}
                      className={({ active }) =>
                        clsx('flex cursor-pointer select-none py-2 pr-4', active ? 'bg-light-blue' : 'bg-white')
                      }
                      value={option}
                    >
                      {({ selected }) => (
                        <>
                          <span className={twMerge('flex items-center px-2', !selected && 'invisible')}>
                            <Check className="h-5 w-5" aria-hidden="true" />
                          </span>
                          <span className="block truncate font-normal" title={option.label}>
                            {option.label}
                          </span>
                        </>
                      )}
                    </Combobox.Option>
                  ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      )}
    </Combobox>
  )
}
