import arrowSplitSVG from '@/assets/arrow-split.svg'

export function ArrowSplitIcon({ width, height, className }: React.ImgHTMLAttributes<HTMLImageElement>) {
  return (
    <img
      width={width}
      height={height}
      className={className}
      src={arrowSplitSVG}
      alt="Arrow that is pointing to the right. The tip is split and points to the top and bottom"
    />
  )
}
