import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { MagnifyingGlass } from '@phosphor-icons/react'
import { useInboxes } from '@/api'
import { useTeams } from '@/api/team'
import { useDebounceValue } from '@/hooks'
import { Input, Text } from '@/ui'
import { SendersDropdownMenu } from './SendersDropdownMenu'
import { TeamView } from './TeamView'

export function Senders() {
  const [search, debouncedSearch, setSearch] = useDebounceValue('')
  const { data, status } = useTeams()
  const { data: inboxes, status: inboxesStatus } = useInboxes()

  if (status === 'error' || inboxesStatus === 'error') {
    return <Text className="text-alert">Error while loading senders</Text>
  }
  const inboxesNotHealthy = inboxes?.data.inboxes.filter((inbox) => !inbox.healthy) || []

  return (
    <>
      <div className="max-w-lg">
        <div className="mb-8 flex flex-col gap-2">
          <div className="flex justify-between">
            <Text variant="title" className="text-dark">
              Senders
            </Text>
            {status === 'success' && !data.permissions.add.deny && <SendersDropdownMenu />}
          </div>
          <Text className="text-dusk">
            Senders are email addresses that distribute the sending volume for
            <br />
            sequences.
          </Text>
        </div>

        <UsageCapacityGraph />

        {inboxesNotHealthy.length > 0 && (
          <div className="my-8 rounded-md bg-[#FFAF96] px-6 py-2">
            Issues found with {inboxesNotHealthy[0].email}{' '}
            {inboxesNotHealthy.length > 1 && `and ${inboxesNotHealthy.length - 1} more`}
          </div>
        )}
        <Input value={search} onChange={(e) => setSearch(e.target.value)} icon={MagnifyingGlass} />

        <div className="mt-4">
          {status === 'pending' || inboxesStatus === 'pending' ? (
            <div className="skeleton h-60 w-full" />
          ) : (
            <TeamView
              inboxes={inboxes.data.inboxes}
              structure={data.data}
              search={debouncedSearch}
              canMove={!data.permissions.move.deny}
            />
          )}
        </div>
      </div>
    </>
  )
}

function UsageCapacityGraph() {
  const { data, status } = useInboxes()
  if (status === 'error' || status === 'pending') return <></>

  const options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: data.data.stats.capacity.map((v) => v.date.substring(v.date.indexOf('-') + 1)),
      minPadding: 0,
      maxPadding: 0,
    },

    yAxis: {
      title: {
        text: '',
      },
      minPadding: 0,
      maxPadding: 0,
    },

    tooltip: {
      shared: true,
    },
    series: [
      {
        name: 'Usage',
        type: 'column',
        yAxis: 0,
        data: data.data.stats.capacity.map((v) => v.campaign_sent + v.campaign_scheduled),
        showInLegend: false,
        color: '#3489F2',
      },
      {
        name: 'Capacity',
        type: 'spline',
        data: data.data.stats.capacity.map((v) => v.campaign_capacity),
        color: '#7A8097',
        yAxis: 0,
        showInLegend: false,
        marker: {
          enabled: false,
        },
      },
    ],
  }

  return (
    <div>
      <Text variant="subtitle" className="mb-2 text-dark">
        Usage and capacity
      </Text>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}
