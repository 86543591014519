import React from 'react'
import { twMerge } from 'tailwind-merge'
import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react'
import { Text } from '@/ui'

type Props<T> = {
  value?: T
  options: { value: T; label: string; description?: string }[]
  onChange: (val: T) => void
  className?: string
  optionClassName?: string
}

export function HorizontalRadioGroup<T>(props: Props<T>) {
  return (
    <HeadlessRadioGroup value={props.value} onChange={props.onChange}>
      <div className={twMerge('flex flex-col space-y-2 rounded-lg', props.className)}>
        {props.options.map((option) => (
          <HeadlessRadioGroup.Option
            value={option.value}
            key={option.label}
            className={twMerge(
              'label -m-[1px] cursor-pointer rounded-lg border-2 border-light px-3 py-2  transition-all duration-100 ease-in hover:border-light hover:duration-200 hover:ease-out active:text-medium active:duration-200 active:ease-out  ui-checked:border-accent',
              props.optionClassName,
            )}
          >
            <div className="flex flex-col">
              <Text variant="subtitle">{option.label}</Text>
              {option.description && <span className="label text-sm text-medium">{option.description}</span>}
            </div>
          </HeadlessRadioGroup.Option>
        ))}
      </div>
    </HeadlessRadioGroup>
  )
}
