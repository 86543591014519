import React, { Fragment, useState } from 'react'
import { NavLink, useMatch } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { List, X } from '@phosphor-icons/react'
import { useCompany, useNotifications } from '@/api'
import { Notification } from '@/api/core'
import { useManualStepsInfo } from '@/api/manual_step'
import { NotificationSeverityToBannerSeverity } from '@/api/text/notification_severity'
import riftLogo from '@/assets/rift-logo.svg'
import riftVector from '@/assets/rift-vector.svg'
import { FeatureFlag } from '@/containers/FeatureFlag'
import { useFlags } from '@/containers/FeatureFlag/useFlags'
import { logoutHandler } from '@/containers/Logout'
import { RiftLogo } from '@/icons/RiftLogo'
import { useGlobalBannerContext } from '@/providers/Banners/GlobalBannerProvider'
import { UserStatus, useUserContext } from '@/providers/User/context'
import { Button } from '@/ui'
import { Banner } from '@/ui/Banner'
import { useUserPermissions } from '../ComponentDisplay'
import { HelpMenu } from './HelpMenu'
import { NavigationMenu } from './NavigationMenu'

type MainLayoutProps = {
  children?: React.ReactNode
  backgroundColor?: string
}

const navigation = [
  { name: 'Inbox', to: '/inbox' },
  { name: 'Contacts', to: '/contacts' },
  { name: 'Sequences', to: '/sequences' },
  { name: 'Settings', to: '/settings' },
  { name: 'Onboarding', to: '/onboarding', showFeatureFlag: true },
]

const NavBar: React.FC<{ setSidebarOpen: (val: boolean) => void; user: UserStatus }> = (props) => {
  const { data: manualStepsInfo } = useManualStepsInfo()
  const { showMeetings } = useUserPermissions()
  const { showFeatureFlag } = useFlags()
  const isInternalLink = navigation
    .filter((item) => !!item.showFeatureFlag)
    .reduce((prev, item) => !!useMatch(item.to) || prev, false)

  const { callsToMake, emailsToWrite, linkedinMessagesToWrite, repliesToWrite } = manualStepsInfo?.data ?? {
    callsToMake: 0,
    emailsToWrite: 0,
    linkedinMessagesToWrite: 0,
    repliesToWrite: 0,
  }
  const totalNotifications = callsToMake + emailsToWrite + linkedinMessagesToWrite + repliesToWrite

  return (
    <nav className="flex w-full items-center justify-start gap-2 space-y-1">
      <NavLink to="/">
        <RiftLogo className="mr-2 h-6 w-6" />
      </NavLink>
      {navigation
        .filter((item) => item.name !== 'Settings' && !item.showFeatureFlag)
        .map((item) => (
          <NavLink
            style={{ marginTop: '0' }}
            key={item.name}
            to={item.to}
            onClick={() => props.setSidebarOpen(false)}
            className={({ isActive }) =>
              twMerge(
                'group flex items-center rounded-full px-2 py-0.5 text-center text-base font-medium text-dusk',
                isActive ? 'bg-light text-dark' : ' hover:text-primary-hover',
              )
            }
          >
            <span>
              {item.name}
              {item.name === 'Inbox' && totalNotifications > 0 && (
                <span className="ml-2 text-xs font-normal"> {totalNotifications}</span>
              )}
            </span>
          </NavLink>
        ))}
      {showMeetings && (
        <NavLink
          style={{ marginTop: '0' }}
          key={'Meetings'}
          to={'/meetings'}
          onClick={() => props.setSidebarOpen(false)}
          className={({ isActive }) =>
            twMerge(
              'group flex items-center rounded-full px-2 py-0.5 text-center text-base font-medium text-dusk',
              isActive ? 'bg-light text-dark' : ' hover:text-primary-hover',
            )
          }
        >
          Meetings
        </NavLink>
      )}
      {!!showFeatureFlag && (
        <Menu style={{ marginTop: '0' }} as="div" className="relative inline-block text-left">
          <Menu.Button
            style={{ marginTop: '0' }}
            className={twMerge(
              'group flex items-center rounded-lg px-[10px] py-1 text-center text-base font-medium text-dusk hover:text-primary-hover',
              isInternalLink && 'bg-[#3648641a]',
            )}
          >
            Internal
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Menu.Items className="absolute right-1/2 mt-3.5 flex max-w-min translate-x-1/2 px-4">
              <div
                style={{ marginTop: '0' }}
                className="shrink rounded-[10px] bg-white px-4 py-[10px] text-md leading-6 text-dark shadow-lg ring-1 ring-gray-900/5"
              >
                {navigation
                  .filter((item) => item.showFeatureFlag)
                  .map((ffLink) => (
                    <Menu.Item key={ffLink.name}>
                      {({ close }) => (
                        <NavLink
                          style={{ marginTop: '0' }}
                          key={ffLink.name}
                          to={ffLink.to}
                          onClick={() => {
                            close()
                            props.setSidebarOpen(false)
                          }}
                          className={({ isActive }) =>
                            twMerge(
                              isActive ? 'bg-[#3648641a]' : 'text-dark hover:text-primary-hover',
                              'flex items-center rounded-lg px-[10px] py-1 text-center text-base',
                            )
                          }
                        >
                          {ffLink.name}
                        </NavLink>
                      )}
                    </Menu.Item>
                  ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}

      <div className="ml-auto flex items-center gap-2">
        <NavigationMenu user={props.user} />
        <HelpMenu />
      </div>
    </nav>
  )
}

export function MainLayout({ children, backgroundColor }: MainLayoutProps) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { user } = useUserContext()
  const { showFeatureFlag } = useFlags()
  const { bannersState } = useGlobalBannerContext()
  const { data: company, isSuccess: companySuccess } = useCompany(showFeatureFlag)
  const { data: notifications, isSuccess: notificationsSuccess } = useNotifications()
  const logout = logoutHandler()

  const getNavigationMenu = () => (
    <nav className="mt-2 flex-1 space-y-1 pb-4">
      {navigation.map((item) => (
        <FeatureFlag show={!!item.showFeatureFlag} key={item.name}>
          <NavLink
            to={item.to}
            onClick={() => setSidebarOpen(false)}
            className={({ isActive }) =>
              twMerge(
                isActive ? 'bg-rift-blue-600 text-white' : 'text-primary hover:text-primary-hover',
                'group flex items-center px-8 py-2 text-base font-medium',
              )
            }
          >
            {item.name}
          </NavLink>
        </FeatureFlag>
      ))}
    </nav>
  )

  const getSignOutNavigationMenu = () => (
    <nav className="flex-1 py-2">
      <div className="group flex items-center px-8 text-base font-medium text-primary">
        {user?.value?.traits?.first_name} {user?.value?.traits?.last_name}
      </div>
      <div className="group flex items-center px-8 text-xs font-medium text-gray-400">{user?.value?.traits?.email}</div>
      <div className="flex flex-row items-center">
        <button
          type="button"
          onClick={logout}
          className={twMerge(
            ' text-primary hover:text-primary-hover',
            'group flex items-center py-4 pl-8 pr-2 text-base font-bold',
          )}
        >
          Sign Out
        </button>
        <img className="h-6 w-auto" src={riftVector} alt="rift" />
      </div>
    </nav>
  )

  return (
    <div className={twMerge('min-h-screen ', backgroundColor ? backgroundColor : 'bg-white')}>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-4 sm:py-8">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute right-0 top-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full p-0 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <X className="size-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-8">
                  <img className="h-8 w-auto" src={riftLogo} alt="rift" />
                </div>
                <div className="mt-4 h-0 flex-1 overflow-y-auto bg-white">{getNavigationMenu()}</div>
                <div className="bg-white">{getSignOutNavigationMenu()}</div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {bannersState?.banners.map((banner) => banner)}
      {companySuccess && (
        <>
          {!company.data.warmerEnabled && (
            <FeatureFlag>
              <Banner severity="error" className="m-2">
                <Banner.Title>Warmer is disabled</Banner.Title>
              </Banner>
            </FeatureFlag>
          )}
          {!company.data.dripperEnabled && (
            <FeatureFlag>
              <Banner severity="error" className="m-2">
                <Banner.Title>Dripper is disabled</Banner.Title>
              </Banner>
            </FeatureFlag>
          )}
        </>
      )}
      {notificationsSuccess && <Notifications notifications={notifications.data} />}
      {/* Static navbar for desktop */}
      <div className="sticky top-0 z-20 hidden md:block">
        <div className="bg-[rgba(252,252,253,0.2)] px-2 py-6 backdrop-blur-2xl xl:px-8">
          <NavBar setSidebarOpen={setSidebarOpen} user={user} />
        </div>
      </div>
      <div className="flex flex-1 flex-col justify-between">
        <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 justify-end bg-white shadow md:hidden">
          <button
            type="button"
            className="focus:ring-primary-500 border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <List className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        {children}
      </div>
    </div>
  )
}

function Notifications({ notifications }: { notifications: Notification[] }) {
  return (
    <>
      {notifications.map((notification, i) => (
        <Banner key={i} severity={NotificationSeverityToBannerSeverity[notification.severity]}>
          <div className="flex justify-between">
            <Banner.Title>{notification.message}</Banner.Title>
            {notification.link && (
              <Button
                variant="basic"
                onClick={() => {
                  window.location.href = notification.link || ''
                }}
              >
                Open
              </Button>
            )}
          </div>
        </Banner>
      ))}
    </>
  )
}
