import { DomainRecordType } from '@/api/core'

export const DomainRecordTypeToText: Record<DomainRecordType, string> = {
  [DomainRecordType.TXT]: 'TXT',
  [DomainRecordType.A]: 'A',
  [DomainRecordType.AAAA]: 'AAAA',
  [DomainRecordType.CNAME]: 'CNAME',
  [DomainRecordType.MX]: 'MX',
  [DomainRecordType.NS]: 'NS',
}

export const TextToDomainRecordType: Record<string, DomainRecordType> = Object.fromEntries(
  Object.entries(DomainRecordType).map(([key, value]) => [value, key as DomainRecordType]),
)

export const DomainRecordTypeLabels: { label: string; value: DomainRecordType }[] = Object.entries(
  DomainRecordTypeToText,
).map(([value, label]) => ({
  label: label,
  value: value as DomainRecordType,
}))

export type DomainRecordTypeLabel = (typeof DomainRecordTypeLabels)[number]
