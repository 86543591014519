import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { queryKey as workspaceQueryKey } from '@/api/workspace'
import { DefaultService, MemberFeatureFlags } from './core'

export const queryKey = {
  base: () => ['featureFlags'] as const,
  senderEmailStatsCompanies: () => ['senderEmailStatsCompanies'] as const,
  senderEmailStatsCompany: (companyId: string, date_from: string, date_to: string) =>
    ['senderEmailStatsCompany', companyId, date_from, date_to] as const,
  sampleWarmerEmails: () => ['sampleWarmerEmails'] as const,
  warmerDrops: (date_from: string, date_to: string) => ['warmerDrops', date_from, date_to] as const,
}

export function useMemberFeatureFlags() {
  return useQuery({
    queryKey: queryKey.base(),
    queryFn: () => DefaultService.getAdminMemberFeatureFlags(),
  })
}

export function useMemberFeatureFlagsUpdate() {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.base() })
      queryClient.invalidateQueries({ queryKey: workspaceQueryKey.views() })
    },
    mutationFn: (body: MemberFeatureFlags) => DefaultService.putAdminMemberFeatureFlags(body),
  })
}

export function useSenderEmailStatsByCompany(companyId: string, date_from?: string, date_to?: string) {
  return useQuery({
    queryKey: queryKey.senderEmailStatsCompany(companyId, date_from || '', date_to || ''),
    queryFn: () => DefaultService.getAdminSenderEmailStatsCompany(companyId, date_from, date_to),
  })
}

export function useSenderEmailsStatsCompanies() {
  return useQuery({
    queryKey: queryKey.senderEmailStatsCompanies(),
    queryFn: () => DefaultService.getAdminSenderEmailStatsCompanyList(),
  })
}

export function useSampleWarmerEmails() {
  return useQuery({
    queryKey: queryKey.sampleWarmerEmails(),
    queryFn: () => DefaultService.getAdminSenderEmailStatsSampleWarmerEmails(),
  })
}

export function useGetCompaniesWithWarmerDrops(date_from?: string, date_to?: string) {
  return useQuery({
    queryKey: queryKey.warmerDrops(date_from || '', date_to || ''),
    queryFn: () => DefaultService.getAdminSenderEmailStatsCompaniesWithDrops(date_from, date_to),
  })
}
