import clsx from 'clsx'
import { MagnifyingGlass } from '@phosphor-icons/react'
import { Input } from '@/ui/Input/v2'

type SearchBarProps = {
  value: string
  onChange: (value: string) => void
  placeholder?: string
  className?: string
  autoFocus?: boolean
}

export function SearchBar(props: SearchBarProps) {
  return (
    <Input
      className={clsx('my-0', props.className)}
      containerClassName="my-0"
      icon={MagnifyingGlass}
      onChange={(e) => props.onChange(e.target.value)}
      value={props.value}
      autoFocus={props.autoFocus}
      placeholder={props.placeholder ?? 'Search...'}
    />
  )
}
