import * as Sentry from '@sentry/react'
import { ScopeContext } from '@sentry/types'
import { useUserContext } from './providers/User/context'

export function reportSentryErrorWithEmail(
  message: string | any | undefined,
  context: Partial<ScopeContext> = {},
  email = '',
) {
  // prevent double-wrapping Errors
  const sentryError = message instanceof Error ? message : new Error(message)

  // allow setting fingerprint from the function call
  // default to setting fingerprint by error message to always group errors with the same message
  const fingerprint =
    context.fingerprint || (sentryError.message ? [sentryError.message.toLowerCase().replace(/\s+/g, '-')] : undefined)
  Sentry.setUser({
    email: email,
  })

  Sentry.captureException(sentryError, {
    ...context,
    fingerprint,
  })
}
export function reportSentryError(message: string | any | undefined, context: Partial<ScopeContext> = {}) {
  // prevent double-wrapping Errors
  const sentryError = message instanceof Error ? message : new Error(message)
  const { user } = useUserContext()

  // allow setting fingerprint from the function call
  // default to setting fingerprint by error message to always group errors with the same message
  const fingerprint =
    context.fingerprint || (sentryError.message ? [sentryError.message.toLowerCase().replace(/\s+/g, '-')] : undefined)
  Sentry.setUser({
    email: user.value?.traits.email,
  })

  Sentry.captureException(sentryError, {
    ...context,
    fingerprint,
  })
}
