import { useState } from 'react'
import { useCampaignLabels, usePatchCampaignLabels } from '@/api/campaigns'
import { Campaign, CampaignLabels, CampaignType, CampaignTypeCampaignLabel } from '@/api/core'
import { CampaignTypeLabels, CampaignTypeToText } from '@/api/text/campaign_type'
import { Listbox } from '@/ui'

export type TypeSectionProps = {
  campaign: Campaign
}

export function TypeSection(props: TypeSectionProps) {
  const { campaign } = props
  const { data, status } = useCampaignLabels(campaign.id)

  return (
    <section className="flex items-baseline py-3.5">
      <label htmlFor="type-selection" className="text-sm text-medium">
        Type
      </label>
      <span id="type-selection" className="relative ml-auto">
        {status === 'success' && <TypeSectionForm campaign={campaign} labels={data.data} />}
      </span>
    </section>
  )
}

export type TypeSectionFormProps = {
  campaign: Campaign
  labels: CampaignLabels
}

export function TypeSectionForm(props: TypeSectionFormProps) {
  const { campaign, labels } = props
  const updateLabels = usePatchCampaignLabels()
  const [value, setValue] = useState(
    labels
      .filter((label) => label.type === CampaignTypeCampaignLabel.type.CAMPAIGN_TYPE)
      .map((label) => (label as CampaignTypeCampaignLabel).campaign_type)?.[0] ?? CampaignType.COLD_OUTREACH,
  )

  const onChange = (newValue: CampaignType) => {
    if (value === newValue) return
    setValue(newValue)

    updateLabels.mutate({
      campaignId: campaign.id,
      body: {
        labels: [
          {
            type: CampaignTypeCampaignLabel.type.CAMPAIGN_TYPE,
            campaign_type: newValue,
          },
        ],
      },
    })
  }
  return (
    <Listbox value={value} onChange={onChange} disabled={campaign.permissions.change_schedule.deny}>
      {({ disabled }) => (
        <>
          {disabled ? (
            <p className="text-sm">{CampaignTypeToText[value]}</p>
          ) : (
            <Listbox.Button variant="text" className="h-fit cursor-default px-0 py-0">
              {CampaignTypeToText[value]}
            </Listbox.Button>
          )}
          <Listbox.Options className="right-0">
            {CampaignTypeLabels.map((p) => (
              <Listbox.Option key={p.value} value={p.value}>
                {p.label}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </>
      )}
    </Listbox>
  )
}
