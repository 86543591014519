import { ContactFieldToSentenceText } from '@/api/text/contact_fields'
import { Button } from '@/ui'
import { ContactObject, FieldFixes } from '../reducer'
import { CancelButton } from './CancelButton'

export type SummaryProps = {
  onDone: () => void
  onCancel: () => void
  filename: string
  importedContactsNo: number
  fieldcleanings: FieldFixes
  numSkippedForBadOwnerEmail: number
}

// SummaryV2 is a copy-pasted version of the Summary step with additional changes to the UX for
// importing. It is currently behind a feature flag and is not yet rolled out to all users.
export function SummaryV2(props: SummaryProps) {
  const { onDone, onCancel, filename, importedContactsNo, fieldcleanings } = props
  const fieldCleaningsToRender: string[] = []
  let field: keyof ContactObject
  for (field in fieldcleanings) {
    const cleanings = fieldcleanings[field]
    if (cleanings === undefined || cleanings.length === 0) {
      continue
    }
    fieldCleaningsToRender.push(
      `${cleanings.length} ${ContactFieldToSentenceText(field)} field${cleanings.length > 1 ? 's' : ''} cleaned`,
    )
  }

  return (
    <>
      <div className="mb-6 flex grow flex-col gap-y-2 overflow-auto rounded-lg border border-solid border-light bg-white p-4 text-sm leading-5 text-dark">
        <div className="font-medium">{filename}</div>
        <div>{importedContactsNo} contacts</div>
        {props.numSkippedForBadOwnerEmail > 0 && (
          <div>
            {props.numSkippedForBadOwnerEmail} contact{props.numSkippedForBadOwnerEmail > 1 ? 's were' : ' was'} skipped
            because the owner email is invalid
          </div>
        )}
        {fieldCleaningsToRender.map((fieldCleaning, index) => (
          <div key={index}>{fieldCleaning}</div>
        ))}
      </div>
      <section className="mt-4 flex justify-between">
        <CancelButton onCancel={onCancel} />
        <Button variant="accent" onClick={onDone}>
          Import
        </Button>
      </section>
    </>
  )
}
