import clsx from 'clsx'
import { DefaultService } from '@/api/core'
import { useOnboardingPutGoogleWSCredentials } from '@/api/onboarding'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { ButtonLoading } from '@/ui'
import { Step } from '@/ui/headless'

export function GoogleWorkspace() {
  const toast = useToast()

  const putGoogleWSCredentials = useOnboardingPutGoogleWSCredentials()
  // get type PutGoogleWSCredentials from the useForm so it can be used in the onSubmit function
  // onSubmit function takes data of type PutGoogleWSCredentials
  const onSubmit = async () => {
    try {
      const resp = await DefaultService.getGoogleOauth('onboarding')
      window.location.href = resp.data.redirect_url
    } catch (err) {
      toast.createToast({ message: (err as any)?.body?.message ?? 'Something went wrong', error: true })
    }
  }

  return (
    <div>
      <h1 className="mb-6 text-lg font-medium">Google workspace credentials</h1>
      {/* note about why we're connecting google with oauth  - allow to automaitze adding subdomains to the workspace account */}
      <p className="mb-4 text-sm">
        To connect your Google Workspace account, we need to use OAuth to access your account and automate adding
        subdomains to your workspace account.
      </p>
      <span className={clsx('text-alert', !putGoogleWSCredentials.isError && 'invisible')}>
        Failed to save {(putGoogleWSCredentials.error as any)?.body?.message}
      </span>

      <div className="mt-8 flex justify-end">
        <Step.Next
          type="submit"
          as={ButtonLoading}
          variant="accent"
          isLoading={putGoogleWSCredentials.isPending}
          onClick={(e) => {
            e.preventDefault()
            onSubmit()
          }}
        >
          Next: Connect google workspace
        </Step.Next>
      </div>
    </div>
  )
}
