import { forwardRef, Ref, useState } from 'react'
import DatePicker from 'react-datepicker'
import clsx from 'clsx'
import { useCampaignUpdate } from '@/api/campaigns'
import { Campaign } from '@/api/core'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button } from '@/ui/Button/v2'

export function SendTimesSelect({
  campaign,
  setErrorMessage,
}: {
  campaign: Campaign
  setErrorMessage: (message: string | null) => void
}) {
  const disabled = campaign.permissions.change_schedule.deny
  const getHours = (time: string) => time.split(':')[0]

  const updateCampaign = useCampaignUpdate(campaign.id)
  const toast = useToast()
  const [fromDate, setFromDate] = useState(new Date(new Date().setHours(Number(getHours(campaign.day_time_from)))))
  const [toDate, setToDate] = useState(new Date(new Date().setHours(Number(getHours(campaign.day_time_to)))))

  // eslint-disable-next-line react/display-name
  const StartDateButton = forwardRef(
    ({ value, onClick }: { value: string; onClick: () => void }, ref: Ref<HTMLButtonElement>) => (
      <Button
        variant="text"
        onClick={onClick}
        ref={ref}
        className={clsx(
          'h-0 w-fit cursor-default p-0 text-sm lowercase',
          disabled && 'pointer-events-none font-normal text-dark hover:text-dark',
        )}
      >
        {value}
      </Button>
    ),
  )

  function handleFromDate(newFromDate: Date) {
    if (newFromDate.getTime() < toDate.getTime()) {
      setErrorMessage(null)
      const newDate = new Date(newFromDate).getHours()
      const newTime = `${newDate < 10 ? '0' + newDate.toString() : newDate.toString()}:00:00`

      if (campaign.day_time_from !== newTime) {
        setFromDate(newFromDate)
        updateCampaign.mutate(
          { day_time_from: newTime },
          {
            onError: (err: unknown) => {
              toast.createToast({ message: (err as any)?.body?.message || "Can't update the step", error: true })
            },
          },
        )
      }
    } else {
      setErrorMessage('Start time must be before the end time')
    }
  }

  function handleToDate(newToDate: Date) {
    if (newToDate.getTime() > fromDate.getTime()) {
      setErrorMessage(null)
      const newDate = new Date(newToDate).getHours()
      const newTime = `${newDate < 10 ? '0' + newDate.toString() : newDate.toString()}:00:00`
      if (campaign.day_time_to !== newTime) {
        setToDate(newToDate)
        updateCampaign.mutate(
          { day_time_to: newTime },
          {
            onError: (err: unknown) => {
              toast.createToast({ message: (err as any)?.body?.message || "Can't update the step", error: true })
            },
          },
        )
      }
    } else {
      setErrorMessage('End time must be after the start time')
    }
  }

  return (
    <div id="date-selection" className="flex flex-row items-center">
      <div className="w-fit">
        <DatePicker
          showTimeSelect
          onChange={handleFromDate}
          selected={fromDate}
          dateFormat="ha"
          showTimeSelectOnly
          timeIntervals={60}
          disabled={disabled}
          popperClassName="z-50"
          customInput={<StartDateButton value="" onClick={() => {}} />}
        />
      </div>
      <span className="px-0.5 text-medium">-</span>
      <div className="w-fit">
        <DatePicker
          showTimeSelect
          onChange={handleToDate}
          selected={toDate}
          disabled={disabled}
          dateFormat="ha"
          showTimeSelectOnly
          timeIntervals={60}
          popperClassName="z-50"
          customInput={<StartDateButton value="" onClick={() => {}} />}
        />
      </div>
    </div>
  )
}
