import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { useCompany, useOnboarding } from '@/api'
import { OnboardingDetails, OnboardingStatus } from '@/api/core'
import { FeatureFlag } from '@/containers/FeatureFlag'
import { useFlags } from '@/containers/FeatureFlag/useFlags'
import { Text } from '@/ui'
import { Step, StepStatus } from '@/ui/headless'
import { BasicInfo } from './steps/BasigInfo'
import { CollectDKIMs } from './steps/CollectDKIM'
import { CollectEmailAppPasswords } from './steps/CollectEmailAppPassword'
import { Congratulations } from './steps/Congratulations'
import { DomainSetup } from './steps/DomainSetup'
import { DomainsVerificationTXT } from './steps/DomainsVerificationTXT'
import { EmailAccounts } from './steps/EmailAccounts'
import { GenericStep } from './steps/GenericStep'
import { GoogleWorkspace } from './steps/GoogleWorkspace'
import { OnboardingCompleted } from './steps/OnboardingCompleted'
import { Payment } from './steps/Payment'
import { DomainRecordAdd } from './DomainRecordAdd'
import { Reset } from './Reset'

const OnboardingDetailStatusToStepStatus = {
  [OnboardingStatus.ERROR]: StepStatus.ERROR,
  [OnboardingStatus.ACTION_REQUIRED]: StepStatus.IN_PROGRESS,
  [OnboardingStatus.IN_PROGRESS]: StepStatus.IN_PROGRESS,
  [OnboardingStatus.REVIEW]: StepStatus.IN_PROGRESS,
  [OnboardingStatus.COMPLETED]: StepStatus.COMPLETED,
}

function OnboardingStep({ text, onboarding }: { text: string; onboarding: OnboardingDetails }) {
  return (
    <Step>
      {(props) => (
        <TextStep
          {...props}
          status={
            props.index === onboarding.stepNo ? OnboardingDetailStatusToStepStatus[onboarding.status] : props.status
          }
          text={text}
        />
      )}
    </Step>
  )
}

function GenericPanel({ onboarding }: { onboarding: OnboardingDetails }) {
  return (
    <Step.Panel>
      <GenericStep onboarding={onboarding} />
    </Step.Panel>
  )
}

export function Onboarding() {
  const navigate = useNavigate()
  const { showFeatureFlag } = useFlags()
  const { data: onboarding, status: onboardingStatus } = useOnboarding()
  const { data: company, status: companyStatus } = useCompany()

  useEffect(() => {
    if (company?.data?.onboardingCompleted) {
      navigate('/')
    }
  }, [company])

  if (onboardingStatus === 'pending' || companyStatus === 'pending') {
    return (
      <div className="flex justify-center">
        <div className="skeleton center h-60 w-3/4" />
      </div>
    )
  }

  if (onboardingStatus === 'error' || companyStatus === 'error') {
    return <div>Failed to load onboarding data</div>
  }

  if (company.data.onboardingCompleted) {
    return null
  }

  if (!showFeatureFlag && onboarding.data.userSetupCompleted) {
    return <Congratulations />
  }

  return (
    <>
      <Step.Group as="div" className="mt-10 flex" currentIndex={onboarding.data.stepNo}>
        <Step.List className="mr-8">
          <DomainRecordAdd />
          <Reset />
          <OnboardingStep text="Create account" onboarding={onboarding.data} />
          <OnboardingStep text="Basic info" onboarding={onboarding.data} />
          <OnboardingStep text="Plan and payment" onboarding={onboarding.data} />
          <OnboardingStep text="Domain setup" onboarding={onboarding.data} />
          <OnboardingStep text="Google workspace" onboarding={onboarding.data} />
          <OnboardingStep text="Email Accounts" onboarding={onboarding.data} />
          <FeatureFlag>
            <Text className="mb-1 mt-2" variant="title">
              GoDaddy DNS Configuration:
            </Text>
            <OnboardingStep text="MX records" onboarding={onboarding.data} />
            <OnboardingStep text="SPF records" onboarding={onboarding.data} />
            <OnboardingStep text="DMARC records" onboarding={onboarding.data} />
            <OnboardingStep text="Custom domain trackings" onboarding={onboarding.data} />
            <OnboardingStep text="Forwarding domains" onboarding={onboarding.data} />

            <Text className="mb-1 mt-2" variant="title">
              Google workspace setup:
            </Text>
            <OnboardingStep text="Set up Hardware 2FA for the admin account and OTP" onboarding={onboarding.data} />
            <OnboardingStep text="Set up language" onboarding={onboarding.data} />
            <OnboardingStep text="Upload logo" onboarding={onboarding.data} />
            <OnboardingStep text="Switch subscription to google workspace Biz Starter" onboarding={onboarding.data} />
            <OnboardingStep text="Turn off free trial" onboarding={onboarding.data} />
            <OnboardingStep text="Turn on smart features" onboarding={onboarding.data} />
            <OnboardingStep text="Allow for profile picture editing" onboarding={onboarding.data} />
            <OnboardingStep text="Enable contact sharing" onboarding={onboarding.data} />
            <OnboardingStep text="Set up DMARC reports as an alias on admin account" onboarding={onboarding.data} />
            <OnboardingStep text="Set up filtering for DMARC reports" onboarding={onboarding.data} />
            <OnboardingStep text="Adding subdomains" onboarding={onboarding.data} />
            <OnboardingStep text="Domains verification" onboarding={onboarding.data} />
            <OnboardingStep text="Activate Gmail for subdomains" onboarding={onboarding.data} />
            <OnboardingStep text="Collect DKIMs" onboarding={onboarding.data} />
            <OnboardingStep text="Update DKIMs in the DNS provider" onboarding={onboarding.data} />
            <OnboardingStep text="Activate DKIM authentication on google workspace" onboarding={onboarding.data} />
            <OnboardingStep text="Approve the set of new email accounts to be created" onboarding={onboarding.data} />
            <OnboardingStep text="Create the Gmail accounts in google workspace" onboarding={onboarding.data} />
            <OnboardingStep text="Optional – add photos for every account created" onboarding={onboarding.data} />
            <OnboardingStep
              text="Add hardware 2FA for every account created in google workspace"
              onboarding={onboarding.data}
            />

            <Text className="mb-1 mt-2" variant="title">
              Gmail Onboarding for each email account:
            </Text>
            <OnboardingStep text="Turn on OTP" onboarding={onboarding.data} />
            <OnboardingStep text="Remove hardware 2FA" onboarding={onboarding.data} />
            <OnboardingStep text="Authorization" onboarding={onboarding.data} />
            <OnboardingStep text="Add email forwarding" onboarding={onboarding.data} />
            <OnboardingStep text="Turn on IMAP" onboarding={onboarding.data} />
            <OnboardingStep text="Rift warmer filtering" onboarding={onboarding.data} />
            <OnboardingStep text="Enable photo sharing" onboarding={onboarding.data} />

            <Text className="mb-1 mt-2" variant="title">
              Rift Onboarding
            </Text>
            <OnboardingStep text="Import Inboxes to onboarding base" onboarding={onboarding.data} />
            <OnboardingStep text="Onboarding Completed" onboarding={onboarding.data} />
          </FeatureFlag>
        </Step.List>
        <Step.Panels>
          {/* this is create account panel step, it is always done during registration, 
            but it's needed here to align indexes */}
          <Step.Panel />

          <Step.Panel>{({ next }) => <BasicInfo nextStep={next} />}</Step.Panel>
          <Step.Panel>
            <Payment />
          </Step.Panel>
          <Step.Panel>{({ next }) => <DomainSetup nextStep={next} />}</Step.Panel>
          <Step.Panel>
            <GoogleWorkspace />
          </Step.Panel>
          <Step.Panel>{({ next }) => <EmailAccounts nextStep={next} />}</Step.Panel>

          {/* mx records */}
          <GenericPanel onboarding={onboarding.data} />

          {/* spf records */}
          <GenericPanel onboarding={onboarding.data} />

          {/* dmarc records */}
          <GenericPanel onboarding={onboarding.data} />

          {/* custom domain records */}
          <GenericPanel onboarding={onboarding.data} />

          {/* forwarding records */}
          <GenericPanel onboarding={onboarding.data} />

          {/* setup hardware */}
          <GenericPanel onboarding={onboarding.data} />

          {/* set language */}
          <GenericPanel onboarding={onboarding.data} />

          {/* upload logo */}
          <GenericPanel onboarding={onboarding.data} />

          {/* switch subscription */}
          <GenericPanel onboarding={onboarding.data} />

          {/* turn off free trial */}
          <GenericPanel onboarding={onboarding.data} />

          {/* turn on smart features */}
          <GenericPanel onboarding={onboarding.data} />

          {/* allow profile picture editing */}
          <GenericPanel onboarding={onboarding.data} />

          {/* enable contact sharing */}
          <GenericPanel onboarding={onboarding.data} />

          {/* set up dmarc reports */}
          <GenericPanel onboarding={onboarding.data} />

          {/* set up filtering for dmarc reports */}
          <GenericPanel onboarding={onboarding.data} />

          {/* adding subdomains */}
          <GenericPanel onboarding={onboarding.data} />

          {/* domains txt verification */}
          <Step.Panel>
            {({ next }) => <DomainsVerificationTXT onboarding={onboarding.data} nextStep={next} />}
          </Step.Panel>

          {/* activate gmail for subdomains */}
          <GenericPanel onboarding={onboarding.data} />

          {/* collect dkims */}
          <Step.Panel>{({ next }) => <CollectDKIMs onboarding={onboarding.data} nextStep={next} />}</Step.Panel>

          {/* update dkims */}
          <GenericPanel onboarding={onboarding.data} />

          {/* activate dkims */}
          <GenericPanel onboarding={onboarding.data} />

          {/* appvoe new emails */}
          <GenericPanel onboarding={onboarding.data} />

          {/* create gmail account */}
          <GenericPanel onboarding={onboarding.data} />

          {/* add photos*/}
          <GenericPanel onboarding={onboarding.data} />

          {/* add hardware 2fa */}
          <GenericPanel onboarding={onboarding.data} />

          {/* enable 2fa */}
          <GenericPanel onboarding={onboarding.data} />

          {/* remove hardware 2fa */}
          <GenericPanel onboarding={onboarding.data} />

          {/* app password */}
          <Step.Panel>
            {({ next }) => <CollectEmailAppPasswords onboarding={onboarding.data} nextStep={next} />}
          </Step.Panel>

          {/* domain forwarding */}
          <GenericPanel onboarding={onboarding.data} />

          {/* turn on imap */}
          <GenericPanel onboarding={onboarding.data} />

          {/* rift warmer filtering */}
          <GenericPanel onboarding={onboarding.data} />

          {/* enable photo sharing */}
          <GenericPanel onboarding={onboarding.data} />

          {/* rift inboxes add */}
          <GenericPanel onboarding={onboarding.data} />

          <Step.Panel>
            <OnboardingCompleted />
          </Step.Panel>
        </Step.Panels>
      </Step.Group>
    </>
  )
}

type TextStepProps = {
  status: StepStatus
  text: string
}

function TextStep(props: TextStepProps) {
  const { status, text } = props
  return (
    <div className="flex items-center">
      <div
        className={clsx(
          'inline-block h-2 w-2 rounded-full',
          status === StepStatus.ERROR && 'bg-alert',
          status === StepStatus.COMPLETED && 'bg-green-500',
          status === StepStatus.IN_PROGRESS && 'bg-blue-500',
          status === StepStatus.NOT_STARTED && 'bg-gray-500',
        )}
      />
      <span className="ml-4 text-sm">{text}</span>
    </div>
  )
}
