import riftLogo from '@/assets/rift-logo.svg'
import riftLogoSimple from '@/assets/rift-logo-simple.svg'

export function RiftLogo({
  width,
  className,
  simple = true,
}: React.ImgHTMLAttributes<HTMLImageElement> & { simple?: boolean }) {
  return <img width={width} className={className} src={simple ? riftLogoSimple : riftLogo} alt="rift logo" />
}
