import { ElementType, ReactNode } from 'react'
import clsx from 'clsx'
import { useFlags } from '@/containers/FeatureFlag/useFlags'

type Props = {
  as?: ElementType
  children: ReactNode
  show?: boolean
  className?: string
}

export function FeatureFlag({ children, as: asElement = 'div', show = true, className }: Props) {
  const Element = asElement
  const { showFeatureFlag } = useFlags()

  if (!show) {
    return <>{children}</>
  }

  return (
    <>
      {showFeatureFlag && (
        <Element className={clsx('border border-purple-700 bg-purple-100/50', className)}>{children}</Element>
      )}
    </>
  )
}
