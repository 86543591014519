import { useFieldArray, useForm } from 'react-hook-form'
import clsx from 'clsx'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { OnboardingDetails, OnboardingDomains, PutOnboardingDomainsDKIM } from '@/api/core'
import { useOnboardingDomains, useOnboardingPutDomainsDKIM } from '@/api/onboarding'
import { useFormLocalStorage } from '@/hooks'
import { emptyMsg } from '@/lib/yup'
import { ButtonLoading, Input } from '@/ui'
import { Step } from '@/ui/headless'

type CollectDKIMsProps = {
  nextStep: () => void
  onboarding: OnboardingDetails
}

export function CollectDKIMs(props: CollectDKIMsProps) {
  const { data, status } = useOnboardingDomains()
  if (status === 'pending') {
    return <div className="skeleton h-12 w-full" />
  }
  if (status === 'error') {
    return <div>Error ...</div>
  }

  return <CollectDKIMsForm nextStep={props.nextStep} domains={data.data} onboarding={props.onboarding} />
}

type CollectDKIMsFormProps = {
  nextStep: () => void
  domains: OnboardingDomains
  onboarding: OnboardingDetails
}

function CollectDKIMsForm(props: CollectDKIMsFormProps) {
  const { nextStep, domains, onboarding } = props
  const putDomainsDKIM = useOnboardingPutDomainsDKIM()

  const schema: yup.ObjectSchema<PutOnboardingDomainsDKIM> = yup.object().shape({
    coldOutboundDomainDKIM: yup.string().required('required').trim(),
    domains: yup
      .array()
      .required('')
      .of(
        yup.object().shape({
          id: yup.string().required(''),
          dkim: yup.string().required(emptyMsg).trim(),
        }),
      ),
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<PutOnboardingDomainsDKIM>({
    defaultValues: {
      domains: domains.domains,
    },
    resolver: yupResolver(schema),
  })
  const { fields } = useFieldArray({
    control,
    name: 'domains',
  })

  const storageName = onboarding.id + '_form_collect_dkim'
  const { clearFormLocalStorage } = useFormLocalStorage<PutOnboardingDomainsDKIM>(storageName, {
    setValue,
    watch,
  })

  const onSubmit = (data: PutOnboardingDomainsDKIM) => {
    putDomainsDKIM.mutate(data, {
      onSuccess: () => {
        clearFormLocalStorage()
        nextStep()
      },
    })
  }

  return (
    <form>
      <div>
        <h1 className="mb-6 text-lg font-medium">Enter domains DKIM value copied from google</h1>
        <div className="flex w-full flex-row items-end gap-4">
          <Input disabled value={domains.coldOutboundDomain} label="Cold outbound domain" />
          <Input registration={register(`coldOutboundDomainDKIM`)} error={errors.coldOutboundDomainDKIM} label="DKIM" />
        </div>

        {fields.map((field, index) => (
          <div key={field.id} className="flex w-full flex-row items-end gap-4">
            <Input disabled value={domains.domains[index].domain} />
            <Input
              registration={register(`domains.${index}.dkim`)}
              error={errors.domains?.[index]?.dkim}
              label="DKIM"
            />
          </div>
        ))}
      </div>

      <span className={clsx('text-alert', !putDomainsDKIM.isError && 'invisible')}>
        Failed to save {(putDomainsDKIM.error as any)?.body?.message}
      </span>

      <div className="mt-8 flex justify-end">
        <Step.Next
          type="submit"
          as={ButtonLoading}
          variant="accent"
          isLoading={putDomainsDKIM.isPending}
          onClick={(e) => {
            e.preventDefault()
            handleSubmit(onSubmit)()
          }}
        >
          Save & Finish
        </Step.Next>
      </div>
    </form>
  )
}
