import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  ApiError,
  DefaultService,
  OnboardingDetails,
  OnboardingDomains,
  OnboardingInboxes,
  PutGoogleWSCredentials,
  PutOnboardingBasicInfo,
  PutOnboardingDomainColdOutboundRecord,
  PutOnboardingDomainsDKIM,
  PutOnboardingDomainSetup,
  PutOnboardingDomainsVerificationTXT,
  PutOnboardingInboxes,
  PutOnboardingInboxesAppPassword,
} from './core'

export const queryKey = {
  base: () => ['onboarding'] as const,
  inboxes: () => ['onboarding', 'inboxes'] as const,
  domains: () => ['onboarding', 'domains'] as const,
  googleworkspace: () => ['onboarding', 'googleworkspace'] as const,
}

export function useOnboarding() {
  return useQuery<{ data: OnboardingDetails }, ApiError>({
    queryKey: queryKey.base(),
    queryFn: () => DefaultService.getOnboarding(),
    refetchInterval: (query) => {
      // auto update when onboarding is in progress
      if (query.state.data?.data.status === 'IN_PROGRESS') {
        return 5000
      }
      return false
    },
  })
}

export function useOnboardingInboxes() {
  return useQuery<{ data: OnboardingInboxes }, ApiError>({
    queryKey: queryKey.inboxes(),
    queryFn: () => DefaultService.getOnboardingInboxes(),
  })
}

export function useOnboardingDomains() {
  return useQuery<{ data: OnboardingDomains }, ApiError>({
    queryKey: queryKey.domains(),
    queryFn: () => DefaultService.getOnboardingDomains(),
  })
}

export function useOnboardingContinue() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: () => DefaultService.postOnboardingContinue(),
  })
}

export function useOnboardingReset() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: () => DefaultService.postOnboardingReset(),
  })
}

export function useOnboardingStepRetry() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: () => DefaultService.postOnboardingStepRetry(),
  })
}

export function useOnboardingPutBasicInfo() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: PutOnboardingBasicInfo) => DefaultService.putOnboardingBasicInfo(body),
  })
}

export function useOnboardingPutDomainSetup() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: PutOnboardingDomainSetup) => DefaultService.putOnboardingDomainSetup(body),
  })
}

export function useOnboardingPutGoogleWSCredentials() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: PutGoogleWSCredentials) => DefaultService.putOnboardingGoogleWsCredentials(body),
  })
}

export function useOnboardingPutInboxes() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: PutOnboardingInboxes) => DefaultService.putOnboardingInboxes(body),
  })
}

export function useOnboardingPutDomainsDKIM() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: PutOnboardingDomainsDKIM) => DefaultService.putOnboardingDomainsDkim(body),
  })
}

export function useOnboardingPutDomainsVerificationTXT() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: PutOnboardingDomainsVerificationTXT) => DefaultService.putOnboardingDomainsVerificationTxt(body),
  })
}

export function useOnboardingPutInboxesAppPassword() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: PutOnboardingInboxesAppPassword) => DefaultService.putOnboardingInboxesAppPassword(body),
  })
}

export function useOnboardingPutDomainColdOutboundRecord() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: PutOnboardingDomainColdOutboundRecord) =>
      DefaultService.putOnboardingDomainsColdOutboundRecords(body),
  })
}

export function useOnboardingInboxDisconnect() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (inboxId: string) => DefaultService.patchOnboardingInboxesDisconnect(inboxId),
  })
}
