/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CampaignType {
    COLD_OUTREACH = 'COLD_OUTREACH',
    REENGAGEMENT = 'REENGAGEMENT',
    NURTURE = 'NURTURE',
    EVENT = 'EVENT',
}
