import { useState } from 'react'
import clsx from 'clsx'
import { useContactsCampaign, useContactsCampaignRecentlyAdded } from '@/api/contacts'
import { Campaign, ContactEmailStatus, Permission, SequenceStatus } from '@/api/core'
import { useUserPermissions } from '@/containers/ComponentDisplay'
import { useFlags } from '@/containers/FeatureFlag/useFlags'
import { Button, DropdownMenu } from '@/ui'
import { ErrorText } from '@/ui/Text/Error'
import { AddAudience, addContactTypes } from './AddAudience'
import { ContactsTable } from './ContactsTable'

export function Audience({ campaign }: { campaign: Campaign }) {
  const [addContactType, setAddContactType] = useState<addContactTypes | null>(null)
  const { showFeatureFlag } = useFlags()
  const { showImportV2 } = useUserPermissions()
  const { data: contacts } = useContactsCampaign(campaign.id)
  const { data: contactsRecentlyAdded } = useContactsCampaignRecentlyAdded(campaign.id)
  // TODO: handle sorting in table component
  contacts?.data?.sort((a, b) => (a.first_name + '' + a.last_name > b.first_name + '' + b.last_name ? 1 : -1))

  const additionalOptions =
    showFeatureFlag || showImportV2
      ? [
          {
            label: 'Upload CSV +',
            onClick: () => setAddContactType('upload+'),
          },
        ]
      : []

  const options = [
    {
      label: 'Upload CSV',
      onClick: () => setAddContactType('upload'),
    },
    {
      label: 'Use existing list',
      onClick: () => setAddContactType('existing'),
    },
    {
      label: 'Add single contact',
      onClick: () => setAddContactType('single'),
    },
    ...additionalOptions,
  ]
  return (
    <section className={clsx('mt-6 grid h-[92%] w-full rounded border border-light bg-white')}>
      {addContactType && (
        <AddAudience campaign={campaign} onClose={() => setAddContactType(null)} addContactType={addContactType} />
      )}
      {campaign.contact_list_ids.length > 0 && contacts?.data ? (
        <ContactsTable
          campaign={campaign}
          contacts={[
            ...contacts.data.filter((contact) => contact.sequenceStatus === SequenceStatus.MISSING_FIELDS),
            ...contacts.data.filter((contact) => contact.sequenceStatus === SequenceStatus.SCHEDULED),
            ...contacts.data.filter((contact) => contact.sequenceStatus === SequenceStatus.ACTIVE),
            ...contacts.data.filter((contact) => contact.sequenceStatus === SequenceStatus.INACTIVE),
            ...contacts.data.filter((contact) => contact.sequenceStatus === SequenceStatus.NOT_INTERESTED),
            ...contacts.data.filter((contact) => contact.sequenceStatus === SequenceStatus.UNSUBSCRIBED),
            ...contacts.data.filter((contact) => contact.email_status === ContactEmailStatus.INVALID),
            ...contacts.data.filter((contact) => contact.email_status === ContactEmailStatus.CATCH_ALL),
          ]}
          contactsRecentlyAdded={contactsRecentlyAdded?.data}
          addContactsButton={
            <ContactsDropdown options={options} variant="basic" permission={campaign.permissions.add_remove_contacts} />
          }
        />
      ) : (
        <div className="m-auto">
          <ContactsDropdown options={options} permission={campaign.permissions.add_remove_contacts} />
        </div>
      )}
    </section>
  )
}

type ContactsDropdownProps = {
  options: { label: string; onClick: () => void }[]
  variant?: 'basic' | 'accent'
  permission: Permission
}
function ContactsDropdown({ options, variant = 'accent', permission }: ContactsDropdownProps) {
  return (
    <>
      <DropdownMenu>
        <DropdownMenu.Button as="div">
          <Button variant={variant}>Add contacts</Button>
        </DropdownMenu.Button>
        <DropdownMenu.Items>
          {!permission.deny ? (
            options.map((option) => (
              <DropdownMenu.Item onClick={option.onClick} key={option.label}>
                {option.label}
              </DropdownMenu.Item>
            ))
          ) : (
            <DropdownMenu.Item>
              <ErrorText errorMessage={permission.reason} className="items-start" />
            </DropdownMenu.Item>
          )}
        </DropdownMenu.Items>
      </DropdownMenu>
    </>
  )
}
