import { format } from 'date-fns'
import { useCampaignEstimatedEndDate } from '@/api/campaigns'
import { Campaign, Integer, IntegerType } from '@/api/core'
import { useSequenceAnalyticsContext } from '@/providers/Sequences/SequenceAnalyticsContext'
import { Statistics, Text, Tooltip } from '@/ui'

export function Overview({ campaign }: { campaign: Campaign }) {
  const { data: estimatedEndDateData } = useCampaignEstimatedEndDate(campaign.id)
  const { state: analyticsContext } = useSequenceAnalyticsContext()

  if (!analyticsContext || !analyticsContext.analytics) return <div className="skeleton h-32 w-full" />

  function dateRangeFormat() {
    const from = analyticsContext.from ? analyticsContext.from * 1000 : campaign.start_date
    const to = analyticsContext.to ? analyticsContext.to * 1000 : Number(estimatedEndDateData?.data.est_end_date) * 1000
    return (
      <Text className="mb-4 text-right text-medium">
        Start: {format(new Date(from), 'MMM d, yyyy')}
        {analyticsContext.to !== undefined ||
        (analyticsContext.from === undefined && analyticsContext.to === undefined && !isNaN(to))
          ? ` - End: ${format(to, 'MMM d, yyyy')} `
          : ''}
      </Text>
    )
  }

  return (
    <section className="rounded-lg border bg-white">
      <div className="grid grid-cols-5">
        <div className="col-span-3 flex px-6 py-4">
          <Statistics title="Progress" pct={analyticsContext.analytics.campaign.emails_sent_pct} />
          <div className="ml-8 w-full">
            {dateRangeFormat()}
            <div className="overflow-hidden rounded-full bg-light">
              <div
                className="h-2 rounded-full bg-dark"
                style={{ width: `${analyticsContext.analytics.campaign.emails_sent_pct * 100}%` }}
              />
            </div>
          </div>
        </div>
        <div className="px-6 py-4">
          <Statistics title="Emails scheduled" number={analyticsContext.analytics.campaign.emails_scheduled} />
        </div>
        <div className="px-6 py-4">
          <Statistics title="Emails sent" number={analyticsContext.analytics.campaign.emails_sent} />
        </div>
      </div>

      <div className="grid grid-cols-6">
        <div className="border-t px-6 py-4">
          <Title
            label="Contacted"
            data={analyticsContext.analytics.audience.contacted}
            additionalPercentage={analyticsContext.analytics.audience.contacted_pct.value}
          />
        </div>
        <div className="border-t px-6 py-4">
          <Title
            label="Opened"
            data={analyticsContext.analytics.audience.opened}
            additionalPercentage={analyticsContext.analytics.audience.opened_pct.value}
          />
        </div>
        <div className="border-t px-6 py-4">
          <Title
            label="Replied"
            data={analyticsContext.analytics.audience.replied}
            additionalPercentage={analyticsContext.analytics.audience.replied_pct.value}
          />
        </div>
        <div className="border-t px-6 py-4">
          <Title
            label="Meeting booked"
            data={analyticsContext.analytics.audience.meeting_booked}
            additionalPercentage={analyticsContext.analytics.audience.meeting_booked_pct.value}
          />
        </div>
        <div className="border-t px-6 py-4">
          <Title
            label="Unsubscribed"
            data={analyticsContext.analytics.audience.unsubscribed}
            additionalPercentage={analyticsContext.analytics.audience.unsubscribed_pct.value}
          />
        </div>
        <div className="border-t px-6 py-4">
          <Title
            label="Bounced"
            data={analyticsContext.analytics.audience.bounced}
            additionalPercentage={analyticsContext.analytics.audience.bounced_pct.value}
          />
        </div>
      </div>
    </section>
  )
}

function Title({
  label,
  data,
  additionalPercentage,
}: {
  label: string
  data: Integer | number
  additionalPercentage: number
}) {
  if (typeof data === 'object') {
    switch (data.type) {
      case IntegerType.NONE:
        return ''
      case IntegerType.INTEGER:
        return <Statistics title={label} number={data.value} pct={additionalPercentage} />
      case IntegerType.N_A:
        return (
          <>
            <p className="text-sm leading-5 tracking-wide text-medium">{label}</p>
            <Tooltip>
              <Tooltip.Trigger>
                <p className="text-xl font-medium leading-10 text-dark">n/a</p>
              </Tooltip.Trigger>
              <Tooltip.Panel>
                <Tooltip.Title>{data.tooltip?.title}</Tooltip.Title>
                <Tooltip.Content>{data.tooltip?.content}</Tooltip.Content>
              </Tooltip.Panel>
            </Tooltip>
          </>
        )
      default:
        return '—'
    }
  }
  return <Statistics title={label} number={data} pct={additionalPercentage} />
}
