import { Warning } from '@phosphor-icons/react/dist/ssr'
import { ContactActionable } from '@/api/core'
import { Button } from '@/ui'

export type ReviewProps = {
  onInclude: (contacts: ContactActionable[]) => void
  duplicated: ContactActionable[]
}

// ReviewV2 is a copy-pasted version of the Review with additional changes to the UX for
// importing. It is currently behind a feature flag and is not yet rolled out to all users.
export function ReviewV2(props: ReviewProps) {
  const { onInclude, duplicated } = props

  return (
    <div className="mb-6 flex grow flex-col overflow-auto rounded-lg border border-solid border-light bg-white p-4">
      <Warning className="mb-4 ml-2 mt-2 h-6 w-6 text-amber" />
      <p className="mx-2 text-md font-medium text-dark">{duplicated.length} contacts are already in other sequences</p>
      <p className="mx-2 text-sm leading-[22px] text-dusk">
        You can add them to this sequence and unenroll them from others, or skip adding contacts already in other
        sequences.
      </p>
      <div className="mt-6 flex gap-x-2">
        <Button className="flex-1" onClick={() => onInclude([])}>
          Don't add
        </Button>
        <Button className="flex-1" onClick={() => onInclude(duplicated)}>
          Add and unenroll
        </Button>
      </div>
    </div>
  )
}
