import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { DefaultService, GenerateApproachRequest, PatchBlueprint, PostBlueprint } from '@/api/core'

export const queryKeys = {
  enrichAccount: (accountDomainName: string, jobTitles: string) =>
    ['enrichAccount', accountDomainName, jobTitles] as const,
  generateApproach: (body: GenerateApproachRequest) => ['generateApproach', JSON.stringify(body)] as const,
  blueprints: ['blueprints'] as const,
  blueprint: (blueprintId: string) => ['blueprint', blueprintId] as const,
}

export function useSequenceGenerateEnrichAccount(accountDomainName: string, jobTitles: string) {
  return useQuery({
    queryKey: queryKeys.enrichAccount(accountDomainName, jobTitles),
    enabled: accountDomainName != '' && jobTitles != '',
    queryFn: () => DefaultService.getSequenceGenerationAccountEnrichment(accountDomainName, jobTitles),
  })
}

export function useSequenceGenerateApproach(body: GenerateApproachRequest) {
  return useQuery({
    queryKey: queryKeys.generateApproach(body),
    enabled: body.seller.description != '' && body.seller.valuePropositions.join(',') != '',
    queryFn: () => DefaultService.postSequenceGenerationApproach(body),
  })
}

export function useBlueprint(blueprintId: string) {
  return useQuery({
    queryKey: queryKeys.blueprint(blueprintId),
    queryFn: () => DefaultService.getBlueprints1(blueprintId),
  })
}

export function useBlueprints() {
  return useQuery({
    queryKey: queryKeys.blueprints,
    queryFn: () => DefaultService.getBlueprints(),
  })
}

export function useCreateBlueprint() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.blueprints })
    },
    mutationFn: (body: PostBlueprint) => DefaultService.postBlueprints(body),
  })
}

export function useUpdateBlueprint(blueprintId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.blueprints })
      queryClient.invalidateQueries({ queryKey: queryKeys.blueprint(blueprintId) })
    },
    mutationFn: (body: PatchBlueprint) => DefaultService.patchBlueprints(blueprintId, body),
  })
}

export function useDeleteBlueprint(blueprintId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.blueprints })
      queryClient.invalidateQueries({ queryKey: queryKeys.blueprint(blueprintId) })
    },
    mutationFn: () => DefaultService.deleteBlueprints(blueprintId),
  })
}
