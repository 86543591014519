import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { ArrowUDownLeft } from '@phosphor-icons/react'
import { ManualStepType } from '@/api/core'
import { useManualStepsDone, useManualStepsReplyUndo } from '@/api/manual_step'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Text } from '@/ui'
import { AllSteps } from '../AllSteps'
import { Finished } from '../Finished'
import { AllStepsSkeleton } from '../Skeletons'
import { doneURl } from '../utilities'

export function Done() {
  const toast = useToast()
  const { data: manualStepsDone, status } = useManualStepsDone()
  const undoReply = useManualStepsReplyUndo()
  if (status === 'error' || status === 'pending') {
    return <AllStepsSkeleton />
  }

  const onUndo = (id: string) => {
    undoReply.mutate(id, {
      onSuccess: () => {
        toast.createToast({ message: `Moved to replies` })
      },
      onError: (error) => {
        toast.createToast({ message: (error as any)?.body?.message || 'Failed to undo reply', error: true })
      },
    })
  }

  function getMessage(type: ManualStepType, subject: string, body: string) {
    switch (type) {
      case ManualStepType.EMAIL_REPLY_ABANDONED:
        return <Text className="font-medium text-dark">Reply abandoned</Text>
      case ManualStepType.EMAIL:
      case ManualStepType.EMAIL_REPLY:
        return (
          <>
            <Text className="font-medium text-dark">{subject}</Text>
            {body}
          </>
        )
      case ManualStepType.LINKEDIN_MESSAGE:
        return (
          <>
            <Text className="font-medium text-dark">Linkedin</Text>
            {body}
          </>
        )
      case ManualStepType.CALL:
        return (
          <>
            <Text className="font-medium text-dark">Call</Text>
            {body}
          </>
        )
      case ManualStepType.RECOMMENDATION:
        return (
          <>
            <Text className="font-medium text-dark">Recommendation</Text>
            {body}
          </>
        )
    }
  }

  function getTimestampWithActions(id: string, type: ManualStepType, createdAt: string) {
    return type === ManualStepType.EMAIL_REPLY_ABANDONED ? (
      <>
        <Text className="text-dusk group-hover:hidden">{format(new Date(createdAt || Date.now()), 'MMM d, yyyy')}</Text>
        <Button
          variant="icon"
          icon={ArrowUDownLeft}
          iconclassname="m-0"
          className="hidden group-hover:block"
          onClick={(e) => {
            onUndo(id)
            e.stopPropagation()
            e.preventDefault()
          }}
        />
      </>
    ) : (
      <Text className="text-dusk">{format(new Date(createdAt || Date.now()), 'MMM d, yyyy')}</Text>
    )
  }

  return (
    <section>
      {manualStepsDone && manualStepsDone.data.length > 0 ? (
        <AllSteps>
          <AllSteps.Group>
            {manualStepsDone.data.map(({ id, contact, type, createdAt, subject, body }, idx) => (
              <AllSteps.Item key={idx} className="group">
                <Link to={doneURl(type, id)} className="cursor-default">
                  <div className="grid w-full cursor-default grid-cols-[168px_1fr_80px] gap-6">
                    <Text className="overflow-hidden text-ellipsis whitespace-nowrap text-dusk">
                      {contact?.name || contact?.email}
                    </Text>
                    <div className="flex flex-row gap-2 overflow-hidden whitespace-nowrap text-sm">
                      {getMessage(type, subject, body)}
                    </div>
                    {getTimestampWithActions(id, type, createdAt)}
                  </div>
                </Link>
              </AllSteps.Item>
            ))}
          </AllSteps.Group>
        </AllSteps>
      ) : (
        <Finished title="No tasks done" message="Finish other tasks first then see them here." />
      )}
    </section>
  )
}
