import { useState } from 'react'
import { useDebounce } from 'use-debounce'
import { FunnelSimple, MagnifyingGlass } from '@phosphor-icons/react'
import { useCampaignActivity } from '@/api/campaigns'
import {
  Campaign,
  EventType,
  EventTypeCallAnswer,
  EventTypeCallReceive,
  EventTypeEmailAutorespond,
  EventTypeEmailBounce,
  EventTypeEmailOpen,
  EventTypeEmailReply,
  EventTypeEmailSend,
  EventTypeEmailUnsubscribe,
  EventTypeLinkedinSend,
  EventTypeMeetingBook,
} from '@/api/core'
import { useQueryParamState } from '@/hooks/useQueryParamState'
import { ActivityFeed } from '@/pages/Activity/ActivityFeed'
import { ContactDetails } from '@/pages/Contacts/ContactDetails'
import { Button, Checkbox, Input, Popover, Text } from '@/ui'

type FilterOption = {
  type: string
  action: EventType
}
const filterOptions: FilterOption[] = [
  { type: 'Auto-responded', action: EventTypeEmailAutorespond.EMAIL_AUTORESPOND },
  { type: 'Bounced', action: EventTypeEmailBounce.EMAIL_BOUNCE },
  { type: 'Call Answered', action: EventTypeCallAnswer.CALL_ANSWER },
  { type: 'Call Received', action: EventTypeCallReceive.CALL_RECEIVE },
  { type: 'Meeting Booked', action: EventTypeMeetingBook.MEETING_BOOK },
  { type: 'Opened', action: EventTypeEmailOpen.EMAIL_OPEN },
  { type: 'Replied', action: EventTypeEmailReply.EMAIL_REPLY },
  { type: 'Sent', action: EventTypeEmailSend.EMAIL_SEND },
  { type: 'Unsubscribed', action: EventTypeEmailUnsubscribe.EMAIL_UNSUBSCRIBE },
  { type: 'Linkedin Sent', action: EventTypeLinkedinSend.LINKEDIN_SEND },
]

export function Activity({ campaign }: { campaign: Campaign }) {
  const [selectedTypes, setSelectedTypes] = useState<FilterOption[]>([])
  const [limit, setLimit] = useState(5)
  const [query, setQuery] = useState('')
  const [contactId, setContactId] = useQueryParamState('contactId')
  const [queryValue] = useDebounce(query, 300)
  const { data, status, isPlaceholderData } = useCampaignActivity(
    campaign.id,
    queryValue,
    limit,
    selectedTypes.map((t) => t.action),
  )
  const events = data?.data || []

  function isSelected(type: string) {
    return !!selectedTypes.find((filterOption) => filterOption.type === type)
  }

  function updateSelectedTypes(option: FilterOption, shouldAdd: boolean) {
    if (shouldAdd) {
      setSelectedTypes([...selectedTypes, option])
    } else {
      setSelectedTypes(selectedTypes.filter((selected) => selected.type !== option.type))
    }
  }

  //todo skeleton
  if (status !== 'success') <></>

  return (
    <section className="my-6 rounded-lg border bg-white p-6">
      <Text variant="title">Activity</Text>

      <div className="flex items-center gap-6 py-6">
        <label htmlFor="sequence-contacts" className="sr-only">
          search sequence contacts
        </label>
        {/* TODO: change this for phosphor icon  */}
        <Input
          id="sequence-contacts"
          icon={MagnifyingGlass}
          value={query}
          onChange={(e) => {
            setQuery(e.target.value)
          }}
          containerClassName="grow"
          iconClassName="fill-none text-medium"
        />

        <Popover>
          <Popover.Button variant="basic" className="relative p-2" data-testid="stats-activity-filter">
            <FunnelSimple width={20} height={20} className="m-auto text-dark" />
            {selectedTypes.length > 0 && <span className="absolute right-1 top-1 h-1.5 w-1.5 rounded-full bg-accent" />}
          </Popover.Button>
          <Popover.Panel className="absolute right-0 z-10 mt-2 w-fit whitespace-nowrap rounded-lg border border-light bg-white p-3 text-sm shadow-sm">
            {filterOptions.map((filter) => (
              <div key={filter.type} className="my-1 flex items-center gap-2">
                <Checkbox
                  className="h-4 w-4   focus:ring-0"
                  checked={isSelected(filter.type)}
                  onChange={() => updateSelectedTypes(filter, !isSelected(filter.type))}
                />
                <Text>{filter.type}</Text>
              </div>
            ))}
            <Button variant="basic" className="mt-2 w-full" onClick={() => setSelectedTypes([])}>
              Clear filters
            </Button>
          </Popover.Panel>
        </Popover>
      </div>
      {contactId && <ContactDetails contactId={contactId} onClose={() => setContactId(null)} />}

      {events.length ? (
        <>
          <ActivityFeed activities={events} context="sequence" setContactId={setContactId} />
          <Button
            variant="basic"
            disabled={!data?.metadata?.more || isPlaceholderData}
            onClick={() => {
              setLimit((limit) => limit + 10)
            }}
          >
            {data?.metadata?.more ? 'Load more' : 'No more events'}
          </Button>
        </>
      ) : (
        <div className="p-4 text-center">
          <Text className="font-medium">No events data.</Text>
          <Text>
            The activity feed will begin to populate after <br />
            the first open, reply or unsubscribe event.
          </Text>
        </div>
      )}
    </section>
  )
}
