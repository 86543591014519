import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMemberFeatureFlags, useMemberFeatureFlagsUpdate } from '@/api/admin'
import { MemberFeatureFlags } from '@/api/core'
import { MemberFeatureFlagLabels } from '@/api/text/admin'
import { shallowEqual } from '@/lib/utils'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Switch, Text } from '@/ui'

export function MemberFeatures() {
  const userFeatureFlags = useMemberFeatureFlags()
  return (
    <div className="flex flex-col gap-6">
      {userFeatureFlags.status === 'success' && <FeatureFlagsForm featureFlags={userFeatureFlags.data.data} />}
    </div>
  )
}

type FeatureFlagsFormProps = {
  featureFlags: MemberFeatureFlags
}

function FeatureFlagsForm(props: FeatureFlagsFormProps) {
  const { featureFlags: featureFlags } = props
  const featureFlagsUpdate = useMemberFeatureFlagsUpdate()
  const toast = useToast()
  const { control, watch } = useForm<MemberFeatureFlags>({
    defaultValues: {
      smtpReplyToHeader: featureFlags.smtpReplyToHeader,
      writingAssistant: featureFlags.writingAssistant,
      importV2: featureFlags.importV2,
      dialer: featureFlags.dialer,
      salesforceIntegration: featureFlags.salesforceIntegration,
      hubspotIntegration: featureFlags.hubspotIntegration,
      recommendations: featureFlags.recommendations,
      sequenceV2: featureFlags.sequenceV2,
      sequenceLabels: featureFlags.sequenceLabels,
      sequenceSchedule: featureFlags.sequenceSchedule,
      meeting: featureFlags.meeting,
      googleInbox: featureFlags.googleInbox,
    },
  })

  const featureFlagsValues = watch()
  const [prevFeatureFlagsValues, setPrevFeatureFlagsValues] = useState(featureFlagsValues)
  useEffect(() => {
    if (shallowEqual(prevFeatureFlagsValues, featureFlagsValues)) return
    setPrevFeatureFlagsValues(featureFlagsValues)
    featureFlagsUpdate.mutate(featureFlagsValues, {
      onError: (err) =>
        toast.createToast({ message: (err as any)?.body?.message || 'failed to update feature flags', error: true }),
      onSuccess: () => toast.createToast({ message: 'User features successfully updated' }),
    })
  }, [featureFlagsValues])

  return (
    <>
      <form>
        <Text variant="text" className="font-medium">
          User features
        </Text>
        <Text variant="subtext" className="mb-2 font-medium text-dusk">
          Set features for individual users. Allow them to access features that are still not released to public.
        </Text>
        <div className="flex flex-col gap-2">
          {MemberFeatureFlagLabels.map((opt) => (
            <div key={opt.value} className="flex justify-between">
              <label htmlFor={opt.value}>{opt.label}</label>
              <Controller
                name={opt.value}
                control={control}
                render={({ field }) => <Switch checked={field.value} onChange={field.onChange} id={opt.value} />}
              />
            </div>
          ))}
        </div>
      </form>
    </>
  )
}
