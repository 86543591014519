import { createContext, useContext } from 'react'
import { Editor } from '@tiptap/react'
import { State } from './reducer'

export const EmailEditorActionContext = createContext<{
  registerSubjectEditor(editor: Editor): void
  registerBodyEditor(editor: Editor): void
  changeEditor(editor: 'subject' | 'body'): void
  togglePreview(): void
} | null>(null)

export function useActions() {
  const context = useContext(EmailEditorActionContext)
  if (context === null) {
    throw new Error(`useActions: <EmailEditor.Component /> is missing a parent <EmailEditor /> component.`)
  }
  return context
}

export const EmailEditorDataContext = createContext<State | null>(null)

export function useData() {
  const context = useContext(EmailEditorDataContext)
  if (context === null) {
    throw new Error(`useData: <EmailEditor.Component /> is missing a parent <EmailEditor />.`)
  }
  return context
}
