import { forwardRef, Fragment } from 'react'
import { Popover as HeadlessUIPopover, PopoverButtonProps, PopoverPanelProps } from '@headlessui/react'
import { Button as UIButton, OpacityTransition } from '@/ui'
import { uirender } from '../render'

function PopoverFn({ children }: React.PropsWithChildren, ref: React.Ref<HTMLDivElement>) {
  return (
    <HeadlessUIPopover className="relative" ref={ref}>
      {children}
    </HeadlessUIPopover>
  )
}

function Button(props: Omit<PopoverButtonProps<typeof UIButton>, 'as'>, ref: React.Ref<HTMLButtonElement>) {
  const { children, ...rest } = props
  return (
    <HeadlessUIPopover.Button ref={ref} as={UIButton} {...rest}>
      {children}
    </HeadlessUIPopover.Button>
  )
}

function Panel(props: PopoverPanelProps<'div'>, ref: React.Ref<HTMLDivElement>) {
  const { children, ...rest } = props
  return (
    <OpacityTransition>
      <HeadlessUIPopover.Panel ref={ref} {...rest}>
        {(slot) => <>{uirender({ ourProps: {}, theirProps: { children }, slot, defaultTag: Fragment })}</>}
      </HeadlessUIPopover.Panel>
    </OpacityTransition>
  )
}

export const Popover = Object.assign(forwardRef(PopoverFn), {
  Button: forwardRef(Button),
  Panel: forwardRef(Panel),
})
