import { ManualStepContactInfo } from '@/api/core'

export const getDisplayName = (email: string, contact: ManualStepContactInfo | undefined) => {
  const contactEmail = contact?.email

  switch (email) {
    case contactEmail:
      return contact?.name || email
    default:
      return email
  }
}
