import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { UpdateVerificationFlowBody, VerificationFlow } from '@ory/client'
import { UserAuthCard } from '@ory/elements'
import logoImage from '@/assets/rift-logo.svg'
import { kratos } from '@/config/kratos'
import { reportSentryError } from '@/sentry'

type VerificationProps = { isPublic: boolean }

export function Verification(props: VerificationProps) {
  const { isPublic } = props
  const [flow, setFlow] = useState<VerificationFlow | null>(null)
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const createFlow = useCallback(
    () =>
      kratos
        .createBrowserVerificationFlow()
        .then(({ data: flow }) => {
          setFlow(flow)
        })
        .catch((error) => {
          reportSentryError('[KRATOS] Verification initializeSelfServiceVerificationFlowForBrowsers failed', {
            extra: {
              error,
            },
          })
          navigate('/verification', { replace: true })
        }),
    [],
  )

  const getFlow = useCallback(
    (flowId: string) =>
      kratos
        .getVerificationFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch((error) => {
          reportSentryError('[KRATOS] Verification getSelfServiceVerificationFlow failed', {
            extra: {
              error,
            },
          })
          return error
        }),
    [],
  )

  const submitFlow = (body: UpdateVerificationFlowBody) => {
    if (!flow) return navigate('/verification', { replace: true })

    kratos
      .updateVerificationFlow({ flow: flow.id, updateVerificationFlowBody: body })
      .then(({ data: flow }) => {
        setFlow(flow)
      })
      .catch((error) => {
        reportSentryError('[KRATOS] Verification submitSelfServiceVerificationFlow failed', {
          extra: {
            error,
          },
        })
        switch (error.response.status) {
          case 400:
            setFlow(error.response.data)
            break
          case 422:
            // eslint-disable-next-line no-case-declarations
            const u = new URL(error.response.data.redirect_browser_to)
            getFlow(u.searchParams.get('flow') || '').catch(() => {
              navigate('/verification', { replace: true })
            })
            break
          case 410:
          case 404:
          default:
            return navigate('/verification', { replace: true })
        }
      })
  }

  useEffect(() => {
    const flowId = searchParams.get('flow')
    if (flowId) {
      getFlow(flowId).catch(createFlow)
      return
    }
    createFlow()
  }, [])

  return flow ? (
    <UserAuthCard
      cardImage={isPublic ? logoImage : ''}
      title="Verification"
      flowType={'verification'}
      flow={flow}
      additionalProps={{
        loginURL: '/login',
        forgotPasswordURL: '/recovery',
        signupURL: '/registration',
      }}
      // submit the verification form data to Ory
      onSubmit={({ body }) => submitFlow(body)}
    />
  ) : (
    <div className="skeleton h-8 w-16" />
  )
}
