import { Plus } from '@phosphor-icons/react'
import { DefaultService, WorkspaceViewId } from '@/api/core'
import { PermissionGuard } from '@/containers/ComponentDisplay'
import { FeatureFlag } from '@/containers/FeatureFlag'
import { useDisclosureV2 } from '@/hooks/useDisclosure'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { DropdownMenu } from '@/ui'
import { ConnectEmailDialog } from './ConnectEmailDialog'
import { GroupFormDialog } from './GroupFormDialog'

export function SendersDropdownMenu() {
  const toast = useToast()
  const [openCreateGroup, onOpenCreateGroup, onCloseCreateGroup] = useDisclosureV2(false)
  const [openConnectEmail, onOpenConnectEmail, onCloseConnectEmail] = useDisclosureV2(false)
  const onConnectGoogle = async () => {
    try {
      const resp = await DefaultService.getGoogleOauth('inbox')
      window.location.href = resp.data.redirect_url
    } catch {
      toast.createToast({ message: 'Something went wrong', error: true })
    }
  }
  const onConnectMicrosoft = async () => {
    try {
      const resp = await DefaultService.getMicrosoftOauthInbox()
      window.location.href = resp.data.redirect_url
    } catch {
      toast.createToast({ message: 'Something went wrong', error: true })
    }
  }

  return (
    <>
      <GroupFormDialog open={openCreateGroup} onClose={onCloseCreateGroup} mode="create" />
      <ConnectEmailDialog open={openConnectEmail} onClose={onCloseConnectEmail} />
      <DropdownMenu>
        <DropdownMenu.Button>
          <Plus className="h-4 w-4" />
        </DropdownMenu.Button>
        <DropdownMenu.Items className="right-0">
          <DropdownMenu.Item onClick={onOpenCreateGroup}>New sender group</DropdownMenu.Item>
          <DropdownMenu.Item onClick={onConnectMicrosoft}>Connect microsoft</DropdownMenu.Item>
          <PermissionGuard permission={WorkspaceViewId.GOOGLE_INBOX}>
            <DropdownMenu.Item onClick={onConnectGoogle}>Connect google</DropdownMenu.Item>
          </PermissionGuard>
          <FeatureFlag>
            <DropdownMenu.Item onClick={onOpenConnectEmail}>Connect email</DropdownMenu.Item>
          </FeatureFlag>
        </DropdownMenu.Items>
      </DropdownMenu>
    </>
  )
}
