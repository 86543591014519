import { useState } from 'react'
import clsx from 'clsx'
import { Dialog, DialogPanel, RadioGroup, Transition } from '@headlessui/react'
import { X } from '@phosphor-icons/react'
import { DefaultService } from '@/api/core'
import { FeatureFlag } from '@/containers/FeatureFlag'
import { Button } from '@/ui'

type RadioOptionProps = {
  id: string
  label: string
  isDisabled: boolean
  isFeatureFlag?: boolean
  index?: number
}

type ExportDialogProps = {
  close: () => void
  isOpen: boolean
  campaignId: string
  campaignName?: string
  hasOpenedContacts: boolean
  hasRepliedContacts: boolean
  hasValidContacts: boolean
  hasUnhealthyContacts: boolean
  hasUnsubscribeContacts?: boolean
  hasBouncedContacts?: boolean
  hasUnenrolledContacts?: boolean
  hasCatchAllContacts?: boolean
}

export enum ExportCampaignDataOption {
  AllContacts = 'all_contacts',
  ValidContacts = 'valid_contacts',
  UnhealthyContacts = 'unhealthy_contacts',
  OpenedContacts = 'opened_contacts',
  RepliedContacts = 'replied_contacts',
  UncontactedContacts = 'uncontacted_contacts',
  UnsubscribedContacts = 'unsubscribed_contacts',
  BouncedContacts = 'bounced_contacts',
  UnenrolledContacts = 'unenrolled_contacts',
  CatchAllContacts = 'catch_all_contacts',
  EmailsSchedule = 'emails_schedule',
}

export function ExportDataDialog(props: ExportDialogProps) {
  const {
    campaignId,
    hasUnhealthyContacts,
    hasValidContacts,
    hasOpenedContacts,
    hasRepliedContacts,
    hasUnsubscribeContacts,
    hasBouncedContacts,
    hasCatchAllContacts,
  } = props
  const [selected, setSelected] = useState(ExportCampaignDataOption.AllContacts)

  const handleDownload = async (value: ExportCampaignDataOption) => {
    const data = await DefaultService.getCampaignsExport(campaignId, value)
    if (!data) return
    handleCreateObjectURL(data, campaignId, value)
    props.close()
  }

  const EXPORT_DATA_ITEMS = [
    {
      id: ExportCampaignDataOption.AllContacts,
      label: 'All contacts',
      isDisabled: false,
    },
    {
      id: ExportCampaignDataOption.ValidContacts,
      label: 'Valid contacts',
      isDisabled: !hasValidContacts,
    },
    {
      id: ExportCampaignDataOption.UnhealthyContacts,
      label: 'Invalid contacts',
      isDisabled: !hasUnhealthyContacts,
    },
    {
      id: ExportCampaignDataOption.UncontactedContacts,
      label: 'Uncontacted emails',
      isDisabled: false,
    },
    {
      id: ExportCampaignDataOption.OpenedContacts,
      label: 'Opened contacts',
      isDisabled: !hasOpenedContacts,
    },
    {
      id: ExportCampaignDataOption.RepliedContacts,
      label: 'Replied contacts',
      isDisabled: !hasRepliedContacts,
    },
    {
      id: ExportCampaignDataOption.UnsubscribedContacts,
      label: 'Unsubscribed contacts',
      isDisabled: !hasUnsubscribeContacts,
    },
    {
      id: ExportCampaignDataOption.BouncedContacts,
      label: 'Bounced contacts',
      isDisabled: !hasBouncedContacts,
    },
    {
      id: ExportCampaignDataOption.UnenrolledContacts,
      label: 'Unenrolled contacts',
      isDisabled: false,
    },
    {
      id: ExportCampaignDataOption.CatchAllContacts,
      label: 'Catch All contacts',
      isDisabled: !hasCatchAllContacts,
    },
    {
      id: ExportCampaignDataOption.EmailsSchedule,
      label: 'Email schedule',
      isFeatureFlag: true,
      isDisabled: false,
    },
  ]

  const RadioOptionComponent = ({ id, isDisabled, index, label }: RadioOptionProps) => (
    <RadioGroup.Option
      key={id}
      value={id}
      disabled={isDisabled}
      className={({ checked }) =>
        clsx(
          index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
          index === EXPORT_DATA_ITEMS.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
          isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
          checked ? 'z-10 border-gray-200 bg-gray-100' : 'border-gray-200',
          'relative flex flex-col border p-4 focus:outline-none md:grid md:grid-cols-3 md:pl-4 md:pr-6',
        )
      }
    >
      {({ checked }) => (
        <>
          <span className="flex items-center text-sm">
            <span
              className={clsx(
                checked ? 'border-transparent bg-rift-blue-600' : 'border-gray-300 bg-white',
                'flex h-4 w-4 items-center justify-center rounded-full border',
              )}
              aria-hidden="true"
            >
              <span className="h-1.5 w-1.5 rounded-full bg-white" />
            </span>
            <RadioGroup.Label
              as="span"
              className={clsx(checked ? 'text-rift-blue-600' : 'text-primary', 'ml-3 font-medium')}
            >
              {label}
            </RadioGroup.Label>
          </span>
          {isDisabled && (
            <RadioGroup.Description
              as="span"
              className="mt-2 flex text-sm text-rift-red-500 sm:ml-4 sm:mt-0 sm:flex-col sm:text-right"
            >
              <span className="font-medium">No Data</span>
            </RadioGroup.Description>
          )}
        </>
      )}
    </RadioGroup.Option>
  )

  return (
    <Transition appear show={props.isOpen}>
      <Dialog as="div" className="relative z-10 focus:outline-none" onClose={props.close}>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel>
              <div className="inline-block max-w-[1000px] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 sm:align-middle">
                <div className="overflow-y-auto">
                  <div className="grid grid-cols-2">
                    <div className="text-left text-xl font-medium leading-6 text-primary">Export Data</div>
                    <div className="flex h-8 justify-end">
                      <X className="size-5 cursor-pointer" onClick={props.close} />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-2 text-sm font-medium text-primary">
                    <RadioGroup value={selected} onChange={setSelected}>
                      <RadioGroup.Label className="text-base  leading-6 text-primary">
                        What data would you like to export?
                      </RadioGroup.Label>
                      <RadioGroup.Label className="sr-only">Export Data</RadioGroup.Label>
                      <div className="relative mt-4 -space-y-px rounded-md bg-white">
                        {EXPORT_DATA_ITEMS.map(({ id, label, isDisabled, isFeatureFlag }: RadioOptionProps, index) => (
                          <div key={index}>
                            {isFeatureFlag ? (
                              <FeatureFlag>
                                <RadioOptionComponent id={id} label={label} isDisabled={isDisabled} index={index} />
                              </FeatureFlag>
                            ) : (
                              <RadioOptionComponent id={id} label={label} isDisabled={isDisabled} index={index} />
                            )}
                          </div>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                  <div className="box-border flex w-full justify-end gap-4 bg-white pt-6">
                    <Button variant="accent" type="submit" onClick={() => handleDownload(selected)}>
                      Download CSV
                    </Button>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

function handleCreateObjectURL(data: Blob, campaignId: string, fileName: string) {
  const date = new Date().toLocaleDateString()
  const url = window.URL.createObjectURL(new Blob([data], { type: 'text/csv;charset=utf-8;' }))
  const tempLink = document.createElement('a')

  tempLink.href = url
  tempLink.setAttribute('download', `${campaignId}_${fileName}_${date}.csv`)
  tempLink.click()
}
