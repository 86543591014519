import { ManualStepEmailDirection, ManualStepReplyInfoEmail } from '@/api/core'
import { timeFromNow } from '@/lib/date'
import { Text, Tooltip } from '@/ui'
import { SingleStep } from '../SingleStep'

type ReplyThreadProps = {
  reply: ManualStepReplyInfoEmail
  setFocused: () => void
  createDraft?: () => void
  getDisplayName: (email: string) => string
  draftBtn?: JSX.Element
}
export function ReplyThread({
  reply: { sentAt, from, bodyText, direction, cc, bcc },
  setFocused,
  getDisplayName,
}: ReplyThreadProps) {
  const contacts = [from, ...cc, ...bcc]
  return (
    <SingleStep.Card
      key={sentAt}
      focused={false}
      className="flex flex-[1_0_0%] cursor-default gap-6 text-sm"
      onClick={setFocused}
    >
      <Tooltip>
        <Tooltip.Trigger>
          <Text className="w-20 truncate font-medium text-dark">
            {direction === ManualStepEmailDirection.OUTGOING
              ? 'Me'
              : contacts.map((c) => getDisplayName(c.email)).join(', ')}
          </Text>
        </Tooltip.Trigger>
        <Tooltip.Panel width="2xl">
          <Tooltip.Content>
            <Text>{from.email}</Text>
          </Tooltip.Content>
        </Tooltip.Panel>
      </Tooltip>
      <Text className="line-clamp-1 flex-[1_0_0%] text-ellipsis text-dusk">{bodyText}</Text>
      <Text className="text-medium">{timeFromNow(new Date(sentAt))}</Text>
    </SingleStep.Card>
  )
}

export function ReplyThreadFocused({
  reply: { sentAt, from, body, to, direction, bcc, cc },
  setFocused,
  getDisplayName,
  draftBtn,
}: ReplyThreadProps) {
  const allAddresses = [...to, ...cc, ...bcc]
  return (
    <SingleStep.Card key={sentAt} focused={true} className="flex flex-col gap-4 text-sm" onClick={setFocused}>
      <div className="flex gap-1">
        <Tooltip>
          <Tooltip.Trigger>
            <Text className="font-medium text-dark">
              {direction === ManualStepEmailDirection.INCOMING ? getDisplayName(from.email) : 'Me'}
            </Text>
          </Tooltip.Trigger>
          <Tooltip.Panel width="2xl">
            <Tooltip.Content>
              <Text>{from.email}</Text>
            </Tooltip.Content>
          </Tooltip.Panel>
        </Tooltip>

        <Text className="text-dark">to</Text>

        <Tooltip>
          <Tooltip.Trigger>
            <Text className="font-medium text-dark">
              {direction === ManualStepEmailDirection.INCOMING
                ? 'Me'
                : allAddresses.map((to) => getDisplayName(to.email)).join(', ')}
            </Text>
          </Tooltip.Trigger>
          <Tooltip.Panel width="2xl">
            <Tooltip.Content>
              <Text>{allAddresses.map((v) => v.email).join(', ')}</Text>
            </Tooltip.Content>
          </Tooltip.Panel>
        </Tooltip>
      </div>
      <div dangerouslySetInnerHTML={{ __html: body }} className="whitespace-pre-line" />
      {draftBtn && draftBtn}
    </SingleStep.Card>
  )
}
