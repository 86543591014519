import { useQuery } from '@tanstack/react-query'
import { ApiError, DefaultService, OauthRedirectUrl } from '@/api/core'

export function useSalesforceOauthRedirect() {
  return useQuery<{ data: OauthRedirectUrl }, ApiError>({
    queryFn: () => DefaultService.getConnectionsSalesforceOauth2(),
    queryKey: ['salesforce_oauth'],
    gcTime: 0,
    enabled: false,
  })
}
