import { useFieldArray, useForm } from 'react-hook-form'
import clsx from 'clsx'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { X } from '@phosphor-icons/react'
import { PutOnboardingInbox, PutOnboardingInboxes } from '@/api/core'
import { useOnboardingPutInboxes } from '@/api/onboarding'
import { Button, ButtonLoading, Input } from '@/ui'
import { Step } from '@/ui/headless'

const empty = { firstName: '', lastName: '', login: '', forwardToEmail: '', count: 1 } as PutOnboardingInbox

type EmailAccountsProps = {
  nextStep: () => void
}

export function EmailAccounts(props: EmailAccountsProps) {
  const { nextStep } = props
  const putInboxes = useOnboardingPutInboxes()
  const schema: yup.ObjectSchema<PutOnboardingInboxes> = yup.object().shape({
    inboxes: yup
      .array()
      .min(1)
      .required()
      .of(
        yup.object().shape({
          firstName: yup.string().required('required').trim(),
          lastName: yup.string().required('required').trim(),
          login: yup
            .string()
            .required('required')
            .trim()
            .test('login', 'only username, not full email address', (value) => !value?.includes('@')),
          forwardToEmail: yup.string().email('must be a valid valid email').required('required').trim(),
          count: yup.number().min(1, '1 or more').max(50, '50 or less').required('required'),
        }),
      ),
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PutOnboardingInboxes>({
    defaultValues: {
      inboxes: [empty],
    },
    resolver: yupResolver(schema),
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'inboxes',
  })

  const onSubmit = (data: PutOnboardingInboxes) => {
    putInboxes.mutate(data, {
      onSuccess: () => {
        nextStep()
      },
    })
  }

  return (
    <form>
      <div>
        <h1 className="mb-6 text-lg font-medium">Enter fields accounts to create</h1>
        {fields.map((field, index) => (
          <div key={field.id} className="flex w-full flex-row items-end gap-4">
            <Input
              registration={register(`inboxes.${index}.firstName`)}
              error={errors.inboxes?.[index]?.firstName}
              label="First Name"
            />
            <Input
              registration={register(`inboxes.${index}.lastName`)}
              error={errors.inboxes?.[index]?.lastName}
              label="Last Name"
            />
            <Input
              registration={register(`inboxes.${index}.login`)}
              error={errors.inboxes?.[index]?.login}
              label="Login"
            />
            <Input
              registration={register(`inboxes.${index}.forwardToEmail`)}
              error={errors.inboxes?.[index]?.forwardToEmail}
              label="Forward To"
            />
            <Input
              className="w-16"
              type="number"
              registration={register(`inboxes.${index}.count`)}
              error={errors.inboxes?.[index]?.count}
              label="#"
            />
            <div className="relative my-1.5">
              <button
                type="button"
                className={clsx(fields.length === 1 && 'pointer-events-none invisible')}
                onClick={() => remove(index)}
              >
                <X className="size-5 hover:text-gray-500" />
              </button>
            </div>
          </div>
        ))}

        <div className="flex w-full justify-end">
          <Button type="button" variant="basic" className="mr-9 mt-5" onClick={() => append(empty)}>
            Add
          </Button>
        </div>
      </div>

      <span className={clsx('text-alert', !putInboxes.isError && 'invisible')}>
        Failed to save {(putInboxes.error as any)?.body?.message}
      </span>

      <div className="mt-8 flex justify-end">
        <Step.Next
          type="submit"
          as={ButtonLoading}
          variant="accent"
          isLoading={putInboxes.isPending}
          onClick={(e) => {
            e.preventDefault()
            handleSubmit(onSubmit)()
          }}
        >
          Save & Finish
        </Step.Next>
      </div>
    </form>
  )
}
