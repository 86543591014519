import { useState } from 'react'
import { UiNode, UiText } from '@ory/client'

const AccountAreadyExistsID = 4000007
const CredentialsAreInvalid = 4000006
const CredentialsAreTooSimialToEmail = 4000031
const PasswordFoundInDataBreaches = 4000034

export function useOryErrors(setEmailError: () => void, setPasswordError: () => void) {
  const [errors, setErrors] = useState<string[]>([])

  function findErrorsFromMessages(messages: UiText[]) {
    const tempErrors: string[] = []
    messages.forEach((message: { id: number; text: string }) => {
      // change error messages to be more user friendly in our case
      // see: https://www.ory.sh/docs/kratos/concepts/ui-user-interface#messages
      switch (message.id) {
        case CredentialsAreInvalid: {
          const message = 'The provided credentials are invalid, check for spelling mistakes in your id or password.'
          if (tempErrors.indexOf(message) < 0) {
            tempErrors.push(message)
            setEmailError()
            setPasswordError()
          }
          break
        }
        case AccountAreadyExistsID: {
          const message =
            'The email address you entered is already associated with an existing account. Please try a different email.'
          if (tempErrors.indexOf(message) < 0) {
            tempErrors.push(message)
            setEmailError()
          }
          break
        }
        case PasswordFoundInDataBreaches: {
          const message =
            'Password Security Alert: Your chosen password has been exposed in data breaches. Please select a different, more secure password.'
          if (tempErrors.indexOf(message) < 0) {
            tempErrors.push(message)
            setPasswordError()
          }
          break
        }
        case CredentialsAreTooSimialToEmail: {
          const message = "Password Too Similar: We can't accept this password as it closely resembles your identifier."
          if (tempErrors.indexOf(message) < 0) {
            tempErrors.push(message)
            setPasswordError()
          }
          break
        }
      }
    })
    return tempErrors
  }

  function setErrorsFromNodes(uiNodes: UiNode[], errorMessages: UiText[]) {
    if (errorMessages && errorMessages.length) {
      // used for dev
      setErrors(findErrorsFromMessages(errorMessages))
    } else {
      // used for staging and production
      let tempErrors: string[] = []
      for (const node of uiNodes) {
        const messages = findErrorsFromMessages(node.messages)
        tempErrors = tempErrors.concat(messages)
      }
      setErrors(tempErrors)
    }
  }

  return { setErrorsFromNodes, errors }
}
