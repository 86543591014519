import React, { useContext } from 'react'
import { WorkspaceViews } from '@/api/core'

export enum UserType {
  Unknown = 'unknown',
  Authenticated = 'authenticated',
  Public = 'public',
}

type State<T, V> = { type: T; value: V }
type ValueState<T, V> = { type: T; value: V }

type UserTraits = {
  email: string
  first_name: string
  last_name: string
}

type UserMetadata = {
  show_feature_flag?: boolean
}

export type UserStatus =
  | State<UserType.Unknown, undefined>
  | ValueState<UserType.Authenticated, { id: string; traits: UserTraits; metadata_public?: UserMetadata }>
  | State<UserType.Public, undefined>

type UserState = {
  user: UserStatus
  permissions: WorkspaceViews
  initialize: () => void
  makePublic: () => void
}

export const UserContext = React.createContext<UserState>({
  user: { type: UserType.Unknown, value: undefined },
  permissions: [],
  initialize: () => null,
  makePublic: () => null,
})

export function useUserContext() {
  return useContext(UserContext)
}

export function useAuthenticatedUser() {
  const { user } = useUserContext()
  if (user.type === UserType.Authenticated) {
    return user.value
  }
  throw new Error('User is not authenticated')
}
