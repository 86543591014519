import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MeetingSlots } from '@/api/core'
import './muiOverride.css'

type CalendarParams = {
  handleDateSelection: (date: string) => void
  timeSlots: Map<string, MeetingSlots>
  className?: string
  selectedDate?: dayjs.Dayjs
}

export function Calendar({ handleDateSelection, timeSlots, className, selectedDate }: CalendarParams) {
  const sortedDates = [...timeSlots.keys()].sort((a, b) => dayjs(a).diff(dayjs(b)))
  const minDate = dayjs(sortedDates[0])
  const maxDate = dayjs(sortedDates[sortedDates.length - 1])

  function handleCalendarChange(day: dayjs.Dayjs | null) {
    if (day) {
      handleDateSelection(day.toDate().toDateString())
    }
  }

  function shouldDisableDate(day: dayjs.Dayjs): boolean {
    return !timeSlots.has(day.toDate().toDateString())
  }

  return (
    <div className={`calendar ${className}`}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          value={selectedDate}
          minDate={minDate}
          maxDate={maxDate}
          shouldDisableDate={shouldDisableDate}
          disablePast={true}
          views={['day']}
          onChange={handleCalendarChange}
        />
      </LocalizationProvider>
    </div>
  )
}
