import { Button, DangerButtonWithIcon } from '@/ui/Button/v2'
import { Dialog } from './Dialog'

type ConfirmationDialogProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  message?: string
  confirmBtn?: string
  cancelBtn?: string
  showAreYouSureText?: boolean
  title?: string
  'data-testid'?: string
}

export function ConfirmationDialog(props: ConfirmationDialogProps) {
  const {
    open,
    onClose,
    onConfirm,
    message = 'You are about to delete.',
    confirmBtn = 'Delete',
    cancelBtn = 'Cancel',
    showAreYouSureText = true,
  } = props
  return (
    <Dialog open={open} onClose={onClose} title="Warning" data-testid={props['data-testid']}>
      <p className="text-sm text-gray-500">{message}</p>
      {showAreYouSureText && <p className="text-sm text-gray-500">Are you sure you want to continue?</p>}

      <div className="mt-4 flex justify-end gap-2">
        <Button
          variant={'basic'}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            onClose()
          }}
        >
          {cancelBtn}
        </Button>
        <DangerButtonWithIcon
          variant="basic"
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            onConfirm()
            onClose()
          }}
        >
          {confirmBtn}
        </DangerButtonWithIcon>
      </div>
    </Dialog>
  )
}
