import { PropsWithChildren } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { Text } from '../Text'

export function Nav({ children, className }: PropsWithChildren<{ className?: string }>) {
  return <nav className={twMerge('px-8 py-6', className)}>{children}</nav>
}

function Title({ children }: PropsWithChildren) {
  return (
    <Text variant="title" className="pb-4">
      {children}
    </Text>
  )
}

function Group({
  children,
  direction = 'col',
  className,
}: PropsWithChildren<{ direction?: 'col' | 'row'; className?: string }>) {
  return <ul className={twMerge(`flex flex-${direction}`, className)}>{children}</ul>
}

interface LinkItemProps extends NavLinkProps {}

function LinkItem({ children, className, ...props }: PropsWithChildren<LinkItemProps>) {
  return (
    <li className="py-2">
      <NavLink
        {...props}
        className={({ isActive }) =>
          twMerge('rounded-md px-2 text-sm leading-5 hover:text-medium', isActive && 'bg-light', className?.toString())
        }
      >
        {children}
      </NavLink>
    </li>
  )
}

function TitleItem({ children }: PropsWithChildren) {
  return <li className="py-2 text-sm text-medium">{children}</li>
}

function NotificationSubtext({ children }: PropsWithChildren) {
  return <span className="pl-2 text-xs font-normal text-medium">{children}</span>
}

Nav.Group = Group
Nav.Title = Title
Nav.TitleItem = TitleItem
Nav.LinkItem = LinkItem
Nav.NotificationSubtext = NotificationSubtext
