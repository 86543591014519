import { useNavigate } from 'react-router-dom'
import { PostBlueprint } from '@/api/core'
import { useCreateBlueprint } from '@/api/sequence_generation'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { BlueprintForm } from './BlueprintForm'

export function NewBlueprint() {
  const createBlueprint = useCreateBlueprint()
  const navigate = useNavigate()
  const toast = useToast()

  const gotoList = () => {
    navigate('/account-sequences/blueprints')
  }

  const onCreate = (body: PostBlueprint) => {
    createBlueprint.mutate(body, {
      onSuccess: () => {
        toast.createToast({ message: 'Blueprint created' })
        gotoList()
      },
      onError: () => {
        toast.createToast({ message: 'There was an error creating the blueprint', error: true })
        gotoList()
      },
    })
  }

  return (
    <div className="m-auto mt-5 max-w-2xl pb-5">
      <BlueprintForm data={undefined} onSubmit={onCreate} onCancel={gotoList} isLoading={createBlueprint.isPending} />
    </div>
  )
}
