import { Text } from '@/ui'

export function ZoomDocumentation() {
  return (
    <div className="flex w-full flex-col items-center pb-16 pt-16">
      <div className="flex max-w-xl flex-col gap-8">
        <Text variant="title">Zoom integration</Text>
        <section>
          <Text variant="subtitle">Adding the app</Text>
          <Text variant="text">To add rift meetings, you need to follow the following steps:</Text>
          <ol className="list-inside list-decimal">
            <li>
              Go to <a href="http://app.rift.com">app.rift.com</a> and log in with your rift account.
            </li>
            <li>
              Click on your first initial icon to reveal the dropdown. It's located in the top right corner, right next
              to the "Help" button.{' '}
            </li>
            <li>Select "Settings" from the dropdown.</li>
            <li>Navigate to the left nav bar, and select "Linked accounts".</li>
            <li>Click on "Connect" next to your Google account to connect rift with your GCal.</li>
            <li>Then, click on "Connect" next to Zoom to connect rift with your Zoom account.</li>
            <li>Next, click on "Company" button in the left nav bar of the Settings page. </li>
            <li>
              Fill in "Short name" field with the name of the company you'd like to use in your meetings link.
              <ul className="list-inside list-disc">
                <li>In our case, we used simply "rift". </li>
                <li>
                  If your business has a two part name, like "rift meetings", we'd recommend removing the space bar
                  ("riftmeetings") or replacing it with an underscore ("rift_meetings").{' '}
                </li>
              </ul>
            </li>
            <li>Click the "Save" button.</li>
          </ol>
          <Text variant="text">Congrats! You're now ready to create your first meeting on rift meetings.</Text>
        </section>
        <section>
          <Text variant="subtitle">Usage</Text>
          <Text variant="text">
            To create your first rift meeting, navigate to "Meetings" button in the top nav bar, or go to
            https://app.rift.com/meetings.{' '}
          </Text>
          <ol className="list-inside list-decimal">
            <li>Click on "New meeting type". </li>
            <li>
              First, set the name of the meeting, e.g. to "30 min intro call".
              <ul className="list-inside list-disc">
                <li>This name will be the title of the calendar event created on your Calendar.</li>
              </ul>
            </li>
            <li>
              At the top, you can see what the meeting link will look like.
              <ul className="list-inside list-disc">
                <li>In our case, it starts with https://app.rift.com/book/rift/.</li>
                <li>In your case, it will start with https://app.rift.com/book/short_company_name/</li>
              </ul>
            </li>
            <li>
              Set the remainder of the link to an easy-to-remember name.
              <ul className="list-inside list-disc">
                <li>e.g. https://app.rift.com/book/short_company_name/intro</li>
              </ul>
            </li>
            <li>Select the duration of the meeting to 30 min, 45 min or 60 min. </li>
            <li>Make sure the "Location" is set to "Zoom".</li>
            <li>
              Pick what type of a meeting you're creating:
              <ul className="list-inside list-disc">
                <li>"Sales", which will sync the data with your CRM if the CRM is connected.</li>
                <li>"Customer Success", which will sync with your CRM if it's connected. </li>
                <li>"Personal", which will not sync with your CRM.</li>
              </ul>
            </li>
            <li>
              Set the meeting assignment to round robin.
              <ul className="list-inside list-disc">
                <li>Pick any person from your team to include them in the round robin. </li>
                <li>If you're hoping to set the meeting just for yourself, pick just your email address.</li>
              </ul>
            </li>
            <li>
              Write the meeting description.
              <ul className="list-inside list-disc">
                <li>This description will appear in every meeting set on your calendar.</li>
              </ul>
            </li>
            <li>
              Define your availability.
              <ul className="list-inside list-disc">
                <li>Standard is Mon - Fri 9am - 5pm in your timezone</li>
              </ul>
            </li>
            <li>
              Set the buffer time.
              <ul className="list-inside list-disc">
                <li>
                  Buffer time is the time after the meeting in minutes when no one will be able to schedule a meeting
                  with you.
                </li>
                <li>
                  For instance, if you set Buffer time to 15, it means that the minimum time difference between two
                  meetings scheduled on rift is 15 minutes.
                </li>
              </ul>
            </li>
            <li>Add any email reminders you'd like to be sent prior to the meeting in the "Reminders" section.</li>
            <li>Click "Save". </li>
          </ol>
          <Text variant="text">
            Congrats! You can now share your meeting URL to schedule a meeting through rift meetings.
          </Text>
        </section>
        <section>
          <Text variant="subtitle">Removing the app</Text>
          <Text variant="text">To remove the app, follow these steps:</Text>
          <ol className="list-inside list-decimal">
            <li>
              Go to <a href="http://app.rift.com">app.rift.com</a> and log in with your rift account.
            </li>
            <li>
              Click on your first initial icon to reveal the dropdown. It's located in the top right corner, right next
              to the "Help" button.{' '}
            </li>
            <li>Select "Settings" from the dropdown.</li>
            <li>Navigate to the left nav bar, and select "Linked accounts".</li>
            <li>Click on "Disconnect" next to the Zoom icon.</li>
            <li>Click on "Disconnect" next to your Google icon.</li>
            <li>Login to your Zoom Account and navigate to the Zoom App Marketplace.</li>
            <li>
              Click <b>Manage</b> &gt;&gt; <b>Added Apps</b> or search for the "rift meetings" app.
            </li>
            <li>Click the "rift meetings" app.</li>
            <li>
              Click <b>Remove</b>.
            </li>
          </ol>
          <Text variant="text">Your rift meetings app will now be removed.</Text>
        </section>
        <section>
          <Text variant="subtitle">Troubleshooting</Text>
          <Text variant="text">Your meeting link displays no available times</Text>
          <ol className="list-inside list-decimal">
            <li>First, confirm that the meeting link is correct.</li>
            <li>Check in your Google Calendar if you have any availability.</li>
            <li>Confirm that both your Zoom and Google Calendar are connected to rift.</li>
            <li>
              If the above fail, please reach out to <a href="mailto:support@getrift.com">support@getrift.com</a> or
              click on the "Help" icon in the top right corner to start a chat with our team.
            </li>
          </ol>
          <Text variant="text">
            If you're struggling with anything else, please contact our support team at{' '}
            <a href="mailto:support@getrift.com">support@getrift.com</a> or by clicking on the "Help" icon in the top
            right corner to start a chat with our team.
          </Text>
        </section>
        <section>
          <Text variant="subtitle">Contact support</Text>
          <Text variant="text">
            If you're struggling with anything within the app, please contact us at{' '}
            <a href="mailto:support@getrift.com">support@getrift.com</a>, or through the in-product chat by clicking on
            the "Help" button in the top right corner.
          </Text>
        </section>
      </div>
    </div>
  )
}
