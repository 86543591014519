import { useNavigate, useParams } from 'react-router-dom'
import { ApiError, PatchMeetingType } from '@/api/core'
import { useMeetingsUsers, useMeetingType, useMeetingTypeDelete, useMeetingTypeUpdate } from '@/api/meetings'
import { MeetingTypeForm } from '@/pages/Meetings/MeetingTypeForm'
import { useToast } from '@/providers/Toasts/ToastsProvider'

export function EditMeetingType() {
  const { meeting_type_id } = useParams()
  const meetingType = useMeetingType(meeting_type_id ?? '')
  const deleteMeetingType = useMeetingTypeDelete(meeting_type_id ?? '')
  const patchMeetingType = useMeetingTypeUpdate(meeting_type_id ?? '')
  const users = useMeetingsUsers()
  const navigate = useNavigate()
  const toast = useToast()
  if (!meetingType.isSuccess) return null

  return (
    <div className="mx-auto max-w-xl">
      <MeetingTypeForm
        permissions={meetingType?.data?.data?.permissions}
        initialValues={meetingType.data.data}
        members={users.data?.data ?? []}
        update={(body) =>
          patchMeetingType.mutate(body as PatchMeetingType, {
            onSuccess: () => {
              navigate('/meetings')
              toast.createToast({ message: `Saved ${body.description}` })
            },
            onError: (error) => {
              if (error instanceof ApiError) {
                toast.createToast({ message: error.body.message, error: true })
              } else {
                toast.createToast({ message: `Failed saving ${body.description}`, error: true })
              }
            },
          })
        }
        delete={() =>
          deleteMeetingType.mutate(undefined, {
            onSuccess: () => {
              navigate('/meetings')
              toast.createToast({ message: `Deleted` })
            },
            onError: (err) => {
              toast.createToast({ message: (err as any)?.body?.message || 'failed to delete meeting', error: true })
            },
          })
        }
        cancel={() => navigate('/meetings')}
      />
    </div>
  )
}
