import { Verification as VerificationForm } from '@/containers/Verification/Form'

type VerificationProps = { isPublic: boolean }

export function Verification(props: VerificationProps) {
  const { isPublic } = props

  if (isPublic)
    return (
      <div className="mt-4 flex items-center justify-center ">
        <div className="ory w-[400px] rounded border-[1px] border-gray-300 bg-white p-4">
          <VerificationForm isPublic={isPublic} />
        </div>
      </div>
    )

  return (
    <div className="mx-auto my-4 max-w-7xl sm:px-10 lg:px-12">
      <div className="mx-auto bg-white p-4 shadow sm:rounded-lg">
        <div className="flex h-[80%] items-center justify-center ">
          <div className="ory w-full">
            <VerificationForm isPublic={isPublic} />
          </div>
        </div>
      </div>
    </div>
  )
}
