import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { queryKey as contactsQueryKey } from '@/api/contacts'
import {
  DefaultService,
  PatchManualStepCall,
  PatchManualStepEmailSave,
  PatchManualStepLinkedinMessage,
  PatchManualStepReplyDraft,
  PostManualStepCallMeetingLinkSend,
} from './core'

export const queryKey = {
  base: () => ['manualSteps'] as const,
  info: () => ['manualSteps', 'info'] as const,
  done: () => ['manualSteps', 'done'] as const,
  emails: () => ['manualSteps', 'emails'] as const,
  email: (id: string) => ['manualSteps', 'emails', id] as const,

  recommendations: () => ['manualSteps', 'recommendations'] as const,
  recommendation: (id: string) => ['manualSteps', 'recommendations', id] as const,
  donerecommendation: (id: string) => ['manualSteps', 'donerecommendations', id] as const,

  replies: () => ['manualSteps', 'replies'] as const,
  reply: (id: string) => ['manualSteps', 'replies', id] as const,

  calls: () => ['manualSteps', 'calls'] as const,
  call: (id: string) => ['manualSteps', 'calls', id] as const,
  deviceToken: (eventId: string) => ['manualSteps', 'calls', eventId, 'deviceToken'] as const,

  linkedinMessages: () => ['manualSteps', 'linkedinMessages'] as const,
  linkedinMessage: (id: string) => ['manualSteps', 'linkedinMessages', id] as const,
  linkedinMessageCopy: (id: string) => ['manualSteps', 'linkedinMessages', id, 'copy'] as const,
}

export function useManualStepsInfo() {
  return useQuery({
    queryKey: queryKey.info(),
    queryFn: () => DefaultService.getManualStepsInfo(),
    refetchInterval: 120000, // 2 minutes
  })
}

export function useManualStepsDone() {
  return useQuery({
    queryKey: queryKey.done(),
    // TODO: update this query after doing call/email/linkedin message
    queryFn: () => DefaultService.getManualStepsDone(),
  })
}

export function useManualStepsEmails() {
  return useQuery({
    queryKey: queryKey.emails(),
    gcTime: 0,
    queryFn: () => DefaultService.getManualStepsEmails(),
  })
}

export function useManualStepsEmailDone(id: string) {
  return useQuery({
    queryKey: queryKey.email(id),
    gcTime: 0,
    queryFn: () => {
      return DefaultService.getManualStepDoneEmail(id)
    },
  })
}

export function useManualStepsEmail(id: string) {
  return useQuery({
    queryKey: queryKey.email(id),
    // do not cache this query, because we want to fetch the fresh data every time
    // someone opens an email editor. This is needed when users goe back and forth
    // between the list of emails. In this case, we want to fetch the fresh data
    // otherwise users will see the initial data.
    gcTime: 0,
    queryFn: () => {
      return DefaultService.getManualStepEmail(id)
    },
  })
}

export function useManualStepsEmailSend(emailId: string, contactId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      // invalidate manual step info query after every device token fetch
      // this is needed because this might be the last email and after this
      // user is redirected to the sequences page. In this case, we want to
      // fetch the fresh data and do not display notification about things
      // to do.
      // NOTE: the same approach is taken in manual calls below.
      queryClient.invalidateQueries({ queryKey: queryKey.info() })
      queryClient.invalidateQueries({ queryKey: queryKey.done() })
      queryClient.invalidateQueries({ queryKey: contactsQueryKey.activities(contactId) })
    },
    mutationFn: () => {
      return DefaultService.postManualStepsEmailsSend(emailId)
    },
  })
}

export function useManualStepsEmailSave(emailId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.emails() }),
    mutationFn: (body: PatchManualStepEmailSave) => DefaultService.patchManualStepsEmailsSave(emailId, body),
  })
}

export function useManualStepsReplies() {
  return useQuery({
    queryKey: queryKey.replies(),
    gcTime: 0,
    queryFn: () => DefaultService.getManualStepsReplies(),
  })
}

export function useManualStepRecommendations() {
  return useQuery({
    queryKey: queryKey.recommendations(),
    gcTime: 0,
    queryFn: () => DefaultService.getManualStepsRecommendations(),
  })
}

export function useManualStepRecommendation(id: string) {
  return useQuery({
    queryKey: queryKey.recommendation(id),
    gcTime: 0,
    queryFn: () => DefaultService.getManualStepRecommendation(id),
  })
}

export function useManualStepDoneRecommendation(id: string) {
  return useQuery({
    queryKey: queryKey.donerecommendation(id),
    gcTime: 0,
    queryFn: () => DefaultService.getManualStepDoneRecommendation(id),
  })
}

export function useManualStepsReply(id: string) {
  return useQuery({
    queryKey: queryKey.reply(id),
    gcTime: 0,
    queryFn: () => DefaultService.getManualStepReply(id),
  })
}

export function useManualStepsDoneReply(id: string) {
  return useQuery({
    queryKey: queryKey.reply(id),
    gcTime: 0,
    queryFn: () => DefaultService.getManualStepDoneReply(id),
  })
}

export function useManualStepsReplySend(id: string) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => DefaultService.postManualStepsRepliesSend(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.reply(id) })
      queryClient.invalidateQueries({ queryKey: queryKey.done() })
      queryClient.invalidateQueries({ queryKey: queryKey.info() })
      queryClient.invalidateQueries({ queryKey: queryKey.replies() })
      queryClient.invalidateQueries({ queryKey: queryKey.recommendation(id) })
      queryClient.invalidateQueries({ queryKey: queryKey.recommendations() })
      queryClient.invalidateQueries({ queryKey: contactsQueryKey.activities(id) })
    },
  })
}

export function useManualStepsReplyDone() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => DefaultService.postManualStepsRepliesDone(id),
    onSuccess: (id: string) => {
      queryClient.invalidateQueries({ queryKey: queryKey.reply(id) })
      queryClient.invalidateQueries({ queryKey: queryKey.done() })
      queryClient.invalidateQueries({ queryKey: queryKey.info() })
      queryClient.invalidateQueries({ queryKey: queryKey.replies() })
    },
  })
}

export function useManualStepsRecommendationDone() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => DefaultService.postManualStepsRecommendationsDone(id),
    onSuccess: (id: string) => {
      queryClient.invalidateQueries({ queryKey: queryKey.done() })
      queryClient.invalidateQueries({ queryKey: queryKey.info() })
      queryClient.invalidateQueries({ queryKey: queryKey.recommendations() })
      queryClient.invalidateQueries({ queryKey: queryKey.recommendation(id) })
    },
  })
}

export function useManualStepsReplyUndo() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => DefaultService.postManualStepsRepliesUndo(id),
    onSuccess: (id: string) => {
      queryClient.invalidateQueries({ queryKey: queryKey.reply(id) })
      queryClient.invalidateQueries({ queryKey: queryKey.replies() })
      queryClient.invalidateQueries({ queryKey: queryKey.done() })
      queryClient.invalidateQueries({ queryKey: queryKey.info() })
    },
  })
}

export function useManualStepsReplySave(id: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.reply(id) })
      queryClient.invalidateQueries({ queryKey: queryKey.recommendation(id) })
    },
    mutationFn: (body: PatchManualStepReplyDraft) => DefaultService.patchManualStepsRepliesDraft(id, body),
  })
}

export function useManualStepsReplyDraftCreate() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => DefaultService.postManualStepsRepliesDraft(id),
    onSuccess: (id: string) => {
      queryClient.invalidateQueries({ queryKey: queryKey.reply(id) })
      queryClient.invalidateQueries({ queryKey: queryKey.replies() })
    },
  })
}

export function useManualStepsReplyDelete(id: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.reply(id) })
      queryClient.invalidateQueries({ queryKey: queryKey.replies() })
      queryClient.invalidateQueries({ queryKey: queryKey.recommendation(id) })
      queryClient.invalidateQueries({ queryKey: queryKey.recommendations() })
    },
    mutationFn: () => DefaultService.deleteManualStepsRepliesDraft(id),
  })
}

export function useManualStepsCalls() {
  return useQuery({
    queryKey: queryKey.calls(),
    gcTime: 0,
    queryFn: () => DefaultService.getManualStepsCalls(),
  })
}

export function useManualStepsCall(id: string) {
  return useQuery({
    queryKey: queryKey.call(id),
    // do not cache this query, because we want to fetch the fresh data every time
    // someone opens an email editor. This is needed when users goe back and forth
    // between the list of emails. In this case, we want to fetch the fresh data
    // otherwise users will see the initial data.
    gcTime: 0,
    queryFn: () => DefaultService.getManualStepCall(id),
  })
}

export function useManualStepsCallDone(id: string) {
  return useQuery({
    queryKey: queryKey.call(id),
    // do not cache this query, because we want to fetch the fresh data every time
    // someone opens an email editor. This is needed when users goe back and forth
    // between the list of emails. In this case, we want to fetch the fresh data
    // otherwise users will see the initial data.
    gcTime: 0,
    queryFn: () => DefaultService.getManualStepDoneCall(id),
  })
}

export function useManualStepsCallsDeviceToken(eventId: string) {
  const queryClient = useQueryClient()
  return useQuery({
    queryKey: queryKey.deviceToken(eventId),
    gcTime: 0,
    queryFn: () => {
      // invalidate manual step info query after every device token fetch
      // this is needed because this might be the last call and after this
      // user is redirected to the sequences page. In this case, we want to
      // fetch the fresh data and do not display notification about things
      // to do.
      queryClient.invalidateQueries({ queryKey: queryKey.info() })
      return DefaultService.getManualStepsCallsDeviceToken(eventId)
    },
  })
}

export function useManualStepCallMeetingLinkSend(eventId: string) {
  return useMutation({
    mutationFn: (body: PostManualStepCallMeetingLinkSend) =>
      DefaultService.postManualStepsCallsMeetingLinkSend(eventId, body),
  })
}

export function useManualStepCallUpdate(eventId: string) {
  return useMutation({
    mutationFn: (body: PatchManualStepCall) => DefaultService.patchManualStepCall(eventId, body),
  })
}

export function useManualStepCallAnswered(eventId: string) {
  return useMutation({
    mutationFn: () => DefaultService.patchManualStepsCallsAnswered(eventId),
  })
}

export function useManualStepCallUnanswered(eventId: string) {
  return useMutation({
    mutationFn: () => DefaultService.patchManualStepsCallsUnanswered(eventId),
  })
}

export function useManualLinkedinMessages() {
  return useQuery({
    queryKey: queryKey.linkedinMessages(),
    gcTime: 0,
    queryFn: () => DefaultService.getManualStepsLinkedinMessages(),
  })
}

export function useManualLinkedinMessage(id: string) {
  return useQuery({
    queryKey: queryKey.linkedinMessage(id),
    gcTime: 0,
    queryFn: () => DefaultService.getManualStepLinkedinMessage(id),
  })
}

export function useManualLinkedinMessageDone(id: string) {
  return useQuery({
    queryKey: queryKey.linkedinMessage(id),
    gcTime: 0,
    queryFn: () => DefaultService.getManualStepDoneLinkedinMessage(id),
  })
}

export function useManualLinkedinMessageUpdate(id: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.linkedinMessages() }),
    mutationFn: (body: PatchManualStepLinkedinMessage) => DefaultService.patchManualStepLinkedinMessage(id, body),
  })
}

export function useManualLinkedinMessageMarkAsDone(contactId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.info() })
      queryClient.invalidateQueries({ queryKey: queryKey.done() })
      queryClient.invalidateQueries({ queryKey: contactsQueryKey.activities(contactId) })
    },
    mutationFn: (eventId: string) => DefaultService.patchManualStepsLinkedinMessagesSent(eventId),
  })
}

export function useManualLinkedinMessageCopy(eventId: string) {
  return useQuery({
    queryKey: queryKey.linkedinMessageCopy(eventId),
    gcTime: 0,
    queryFn: () => DefaultService.getManualStepsLinkedinMessagesCopy(eventId),
    enabled: false,
    staleTime: 0,
  })
}
