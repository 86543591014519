/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ContactEmailStatus {
    VALID = 'VALID',
    INVALID = 'INVALID',
    CATCH_ALL = 'CATCH_ALL',
    UNKNOWN = 'UNKNOWN',
}
