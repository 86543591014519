import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { useDebounce } from 'use-debounce'
import { Tab } from '@headlessui/react'
import { useContacts } from '@/api/contacts'
import { useMembers } from '@/api/member'
import { useUserPermissions } from '@/containers/ComponentDisplay'
import { FeatureFlag } from '@/containers/FeatureFlag'
import { useFlags } from '@/containers/FeatureFlag/useFlags'
import { Button } from '@/ui/Button/v2'
import { ErrorInfo } from '@/ui/ErrorInfo'
import { SearchBar } from '@/ui/SearchBar'
import { Text } from '@/ui/Text'
import { Import } from './Import/Import'
import { AccountsView } from './AccountsView'
import { ContactsTable } from './ContactsTable'
import { CreateListDialog } from './CreateListDialog'
import { ContactFilters, Filter } from './Filter'
import { ListView } from './ListView'

export function ContactsView() {
  const navigate = useNavigate()
  const location = useLocation()
  const { showFeatureFlag } = useFlags()
  const { showImportV2 } = useUserPermissions()
  // match current tab index based on pathname
  // but later we need proper tabs and routes integration
  const tabs = [{ pathname: '/contacts' }, { pathname: '/contacts/lists' }, { pathname: '/contacts/accounts' }]
  const locationPick = () =>
    location.pathname.startsWith('/contacts/accounts') ? 2 : location.pathname.startsWith('/contacts/lists') ? 1 : 0
  const [curTabIdx, setCurTabIdx] = useState(locationPick())

  // TODO: the routing probably should not be done here at all?
  useEffect(() => {
    setCurTabIdx(locationPick())
  }, [location])
  const setCurTab = (idx: number) => {
    setCurTabIdx(idx)
    navigate(tabs[idx].pathname)
  }

  const [openCreateListDialog, setOpenCreateListDialog] = useState(false)
  const [openImportDialog, setOpenImpotDialog] = useState(false)

  return (
    <div className="bg-white p-4">
      <Text variant="title">Contacts</Text>
      {openImportDialog && <Import open={openImportDialog} onClose={() => setOpenImpotDialog(false)} />}
      <Tab.Group selectedIndex={curTabIdx} onChange={setCurTab}>
        <div className="mb-3 flex justify-between">
          <Tab.List className="flex h-10 gap-4">
            <Tab
              className={({ selected }) =>
                clsx('hover:text-gray-500 focus:outline-none', selected && 'text-rift-blue-600')
              }
            >
              All Contacts
            </Tab>
            <Tab
              className={({ selected }) =>
                clsx('hover:text-gray-500 focus:outline-none', selected && 'text-rift-blue-600')
              }
            >
              Lists
            </Tab>
            <FeatureFlag>
              <Tab
                className={({ selected }) =>
                  clsx('hover:text-gray-500 focus:outline-none', selected && 'text-rift-blue-600')
                }
              >
                Accounts
              </Tab>
            </FeatureFlag>
          </Tab.List>

          {curTabIdx === 0 ? (
            <div className="flex items-center gap-4">
              <Button data-testid="contacts-import-btn" variant="basic" onClick={() => setOpenImpotDialog(true)}>
                Import Contacts
              </Button>
              {(showFeatureFlag || showImportV2) && (
                <Button variant="basic" onClick={() => navigate('/import')}>
                  Import Contacts +
                </Button>
              )}
            </div>
          ) : curTabIdx === 1 ? (
            <div className="flex items-center gap-2">
              <CreateListDialog open={openCreateListDialog} onClose={() => setOpenCreateListDialog(false)} />
              <Button variant="basic" onClick={() => setOpenCreateListDialog(true)}>
                New list
              </Button>
              <Button variant="basic" onClick={() => setOpenImpotDialog(true)}>
                Import Contacts
              </Button>
              {(showFeatureFlag || showImportV2) && (
                <Button variant="basic" onClick={() => navigate('/import')}>
                  Import Contacts +
                </Button>
              )}
            </div>
          ) : (
            <div className="flex items-center gap-2">{/* Action buttons for accounts list */}</div>
          )}
        </div>
        <Tab.Panels>
          <Tab.Panel>
            <ContactsPanel />
          </Tab.Panel>
          <Tab.Panel>
            <ListView />
          </Tab.Panel>
          <Tab.Panel>
            <AccountsView />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export function ContactsPanel() {
  const [search, setSearch] = useState('')
  const [searchD] = useDebounce(search, 500)
  const { data, status } = useContacts()
  const { data: members, status: membersStatus } = useMembers({ phantom: true })
  const [contactsFilter, setContactsFilter] = useState<ContactFilters>({
    stages: [],
    created_at: {
      start: null,
      end: null,
    },
    last_contacted_at: {
      start: null,
      end: null,
    },
    owners: [],
  })

  if (status === 'error' || membersStatus === 'error') {
    return <ErrorInfo />
  }

  return (
    <div>
      {membersStatus === 'success' ? (
        <Filter onChange={setContactsFilter} owners={members.data}>
          <div className="mb-4 flex justify-between">
            <div className="flex gap-2">
              <div className="w-96">
                <SearchBar value={search} onChange={setSearch} />
              </div>
              <Filter.Add />
            </div>
          </div>
          <Filter.Labels />
        </Filter>
      ) : (
        <div className="skeleton mb-2 h-48 w-full"></div>
      )}
      {status === 'pending' || membersStatus === 'pending' ? (
        <>
          <div className="skeleton mb-2 h-48 w-full"></div>
          <span className="sr-only">Loading...</span>
        </>
      ) : (
        <ContactsTable
          data={data.data}
          owners={members.data}
          globalFilter={searchD}
          setGlobalFilter={setSearch}
          contactsFilter={contactsFilter}
        />
      )}
    </div>
  )
}
