import { useCallback } from 'react'
import { Control, SetValueConfig, useFieldArray, useForm, UseFormRegister, useFormState } from 'react-hook-form'
import clsx from 'clsx'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { DefaultService, OnboardingDetails, OnboardingInboxes, PutOnboardingInboxesAppPassword } from '@/api/core'
import {
  useOnboardingInboxDisconnect,
  useOnboardingInboxes,
  useOnboardingPutInboxesAppPassword,
} from '@/api/onboarding'
import { useFormLocalStorage } from '@/hooks'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { SetValueKey, SetValueType } from '@/types/react-hook-form'
import { Button, ButtonLoading, DangerButton, Input } from '@/ui'
import { Step } from '@/ui/headless'
import { InputPassword } from '@/ui/Input/v2'

type CollectEmailAppPasswordsProps = {
  nextStep: () => void
  onboarding: OnboardingDetails
}

export function CollectEmailAppPasswords(props: CollectEmailAppPasswordsProps) {
  const { data: inboxes, status: inboxesStatus } = useOnboardingInboxes()

  if (inboxesStatus === 'pending') {
    return <div className="skeleton h-12 w-full" />
  }
  if (inboxesStatus === 'error') {
    return <div>Error ...</div>
  }
  return <AppPasswordsForm nextStep={props.nextStep} inboxes={inboxes.data} onboarding={props.onboarding} />
}

type AppPasswordsFormProps = {
  nextStep: () => void
  inboxes: OnboardingInboxes
  onboarding: OnboardingDetails
}

function AppPasswordsForm(props: AppPasswordsFormProps) {
  const { nextStep, inboxes, onboarding } = props
  const putInboxesAppPassword = useOnboardingPutInboxesAppPassword()

  const schema: yup.ObjectSchema<PutOnboardingInboxesAppPassword> = yup.object().shape({
    inboxes: yup
      .array()
      .min(1)
      .required()
      .of(
        yup.object().shape({
          id: yup.string().required('required'),
          connected: yup.boolean(),
          appPassword: yup
            .string()
            .ensure()
            .when('connected', {
              is: false,
              then: (schema) => schema.length(16, '16-character code').required('required').trim(),
            }),
        }),
      ),
  })
  const { control, register, handleSubmit, setValue, watch } = useForm<PutOnboardingInboxesAppPassword>({
    defaultValues: {
      inboxes: inboxes.inboxes,
    },
    resolver: yupResolver(schema),
  })

  const customSetValue = useCallback(
    (
      name: SetValueKey<PutOnboardingInboxesAppPassword>,
      value: SetValueType<PutOnboardingInboxesAppPassword>,
      options?: SetValueConfig,
    ) => {
      // only load app password
      if (name.includes('appPassword')) {
        setValue(name, value, options)
      }
    },
    [],
  )

  const storageName = onboarding.id + '_form_inbox_auth'
  const { clearFormLocalStorage } = useFormLocalStorage<PutOnboardingInboxesAppPassword>(storageName, {
    setValue: customSetValue,
    watch,
  })

  const onSubmit = (data: PutOnboardingInboxesAppPassword) => {
    putInboxesAppPassword.mutate(data, {
      onSuccess: nextStep,
      onSettled: clearFormLocalStorage,
    })
  }

  return (
    <form>
      <div>
        <h1 className="mb-6 text-lg font-medium">Enter fields accounts to create</h1>
        <AppPasswordsFormInput inboxes={inboxes} control={control} register={register} setValue={setValue} />
      </div>

      <span className={clsx('text-alert', !putInboxesAppPassword.isError && 'invisible')}>
        Failed to save {(putInboxesAppPassword.error as any)?.body?.message}
      </span>

      <div className="mt-8 flex justify-end">
        <Step.Next
          type="submit"
          as={ButtonLoading}
          variant="accent"
          isLoading={putInboxesAppPassword.isPending}
          onClick={(e) => {
            e.preventDefault()
            handleSubmit(onSubmit)()
          }}
        >
          Save & Finish
        </Step.Next>
      </div>
    </form>
  )
}

type AppPasswordsFormInputProps = {
  inboxes: OnboardingInboxes
  control: Control<PutOnboardingInboxesAppPassword, any>
  register: UseFormRegister<PutOnboardingInboxesAppPassword>
  setValue: (
    name: SetValueKey<PutOnboardingInboxesAppPassword>,
    value: SetValueType<PutOnboardingInboxesAppPassword>,
    options?: SetValueConfig,
  ) => void
}

function AppPasswordsFormInput(props: AppPasswordsFormInputProps) {
  const toast = useToast()
  const { inboxes, control, setValue, register } = props
  const disconnect = useOnboardingInboxDisconnect()
  const { errors } = useFormState({ control })
  const { fields } = useFieldArray({ control, name: 'inboxes' })
  const onConnect = async (email: string) => {
    try {
      const resp = await DefaultService.getGoogleOauth('onboarding_inbox', email)
      window.location.href = resp.data.redirect_url
    } catch (err) {
      toast.createToast({ message: (err as any)?.body?.message ?? 'Something went wrong', error: true })
    }
  }

  const onDisconnect = (inboxId: string) => {
    disconnect.mutate(inboxId, {
      onError: (err) => {
        toast.createToast({ message: (err as any)?.body?.message ?? 'Something went wrong', error: true })
      },
    })
  }

  const onAppPasswordPaste =
    (name: `inboxes.${number}.appPassword`) => async (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault()
      setValue(
        name,
        e.clipboardData.getData('Text').replace(/\s/g, '') as unknown as SetValueType<PutOnboardingInboxesAppPassword>,
      )
    }

  return (
    <>
      {fields.map((field, index) => {
        const acc = inboxes.inboxes[index]
        return (
          <div key={field.id} className="flex w-full flex-row items-end gap-4">
            <Input disabled value={acc.firstName} label="First Name" />
            <Input disabled value={acc.firstName} label="First Name" />
            <Input disabled value={acc.lastName} label="Last Name" />
            <Input disabled value={acc.login} label="Login" />
            <Input disabled value={acc.emailDomain} label="Domain" />
            <InputPassword
              onPaste={onAppPasswordPaste(`inboxes.${index}.appPassword`)}
              registration={register(`inboxes.${index}.appPassword`)}
              error={errors.inboxes?.[index]?.appPassword}
              label="App Password"
            />
            {!acc.connected ? (
              <Button type="button" variant="basic" className="mb-1.5" onClick={() => onConnect(acc.email)}>
                Connect
              </Button>
            ) : (
              <DangerButton type="button" variant="basic" className="mb-1.5" onClick={() => onDisconnect(acc.id)}>
                Disconnect
              </DangerButton>
            )}
          </div>
        )
      })}
    </>
  )
}
