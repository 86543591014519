import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Control, Controller, Path, UseFormRegister } from 'react-hook-form'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { DayAvailability, MeetingAvailability } from '@/api/core'
import { Checkbox } from '@/ui/Checkbox'

const Weekdays = [
  { id: 'monday', display: 'Monday' },
  { id: 'tuesday', display: 'Tuesday' },
  { id: 'wednesday', display: 'Wednesday' },
  { id: 'thursday', display: 'Thursday' },
  { id: 'friday', display: 'Friday' },
  { id: 'saturday', display: 'Saturday' },
  { id: 'sunday', display: 'Sunday' },
] as const

type Props<T extends { availability?: MeetingAvailability }> = {
  register: UseFormRegister<T>
  availability?: MeetingAvailability
  control: Control<T>
  className?: string
}

export function Availability<T extends { availability?: MeetingAvailability }>(props: Props<T>) {
  return (
    <div className={props.className}>
      {Weekdays.map((day) => (
        <DayView
          control={props.control}
          register={props.register}
          key={day.id}
          field={day.id}
          label={day.display}
          available={props.availability && (props.availability[day.id] as DayAvailability).available}
        />
      ))}
    </div>
  )
}

type DayViewProps<F extends { availability?: MeetingAvailability }> = {
  available?: boolean
  field: string
  label: string
  register: UseFormRegister<F>
  control: Control<F>
}

function DayView<T extends { availability?: MeetingAvailability }>(props: DayViewProps<T>) {
  const [selected, setSelected] = useState(false)
  useEffect(() => {
    setSelected(!!props.available)
  }, [props.available])
  return (
    <div className="my-2 flex h-9 items-center justify-between">
      <Controller
        control={props.control}
        render={({ field }) => (
          <label className="flex h-9 items-center">
            <Checkbox
              checked={selected}
              value={field.value as string}
              onChange={(e) => {
                setSelected(e.target.checked)
                field.onChange(e)
              }}
            />
            <span className={clsx('label ml-4 block', !selected && 'text-medium')}>{props.label}</span>
          </label>
        )}
        name={`availability.${props.field}.available` as Path<T>}
      />
      {selected && (
        <div className="flex items-center">
          <Controller
            control={props.control}
            name={`availability.${props.field}.start_time` as Path<T>}
            render={({ field }) => (
              <DatePicker
                showTimeSelect
                showTimeSelectOnly
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onChange={(date: Date) => field.onChange(dayjs(date).format('HH:mm:ss'))}
                selected={dayjs(field.value as string, 'HH:mm:ss').toDate()}
                dateFormat="h:mm a"
                timeIntervals={15}
                className="w-[86px] outline-none"
              />
            )}
          />
          <span className="label mx-2">to</span>
          <Controller
            control={props.control}
            name={`availability.${props.field}.end_time` as Path<T>}
            render={({ field }) => (
              <DatePicker
                showTimeSelect
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onChange={(date: Date) => field.onChange(dayjs(date).format('HH:mm:ss'))}
                selected={dayjs(field.value as string, 'hh:mm:ss').toDate()}
                dateFormat="h:mm a"
                showTimeSelectOnly
                timeIntervals={15}
                className="w-[86px] outline-none"
              />
            )}
          />
        </div>
      )}
    </div>
  )
}
