import { useState } from 'react'
import { Control, Controller, useFieldArray, useFormContext, useFormState, useWatch } from 'react-hook-form'
import clsx from 'clsx'
import { format } from 'date-fns'
import DOMPurify from 'dompurify'
import { CopySimple, DotsThree, TrashSimple } from '@phosphor-icons/react'
import { ConferencingPlatform, PatchMeetingType, PostMeetingType } from '@/api/core'
import { useDisclosureV2 } from '@/hooks/useDisclosure'
import { dateOnlyRFC3999Format } from '@/lib/date'
import { pluralize } from '@/lib/strings'
import { useUserContext } from '@/providers/User/context'
import { Button, DropdownMenu, Input, Select, Text, Toolbar } from '@/ui'
import { EmailEditor } from '@/ui/EmailEditor'

type RemindersProps = {
  defaultSubject: string
  defaultBody: string
  variables: string[]
}

export function Reminders(props: RemindersProps) {
  const { control, getValues } = useFormContext<PostMeetingType | PatchMeetingType>()
  const { defaultBody, defaultSubject, variables } = props
  const { fields, append, remove, insert } = useFieldArray({
    control,
    keyName: '_id',
    name: 'reminders',
  })

  const onAdd = () => {
    append({
      sendBefore: { days: 0, hours: 24 },
      subject: defaultSubject,
      body: defaultBody,
    })
  }

  const onDuplicate = (index: number) => () => {
    insert(index + 1, {
      sendBefore: getValues(`reminders.${index}.sendBefore`),
      subject: getValues(`reminders.${index}.subject`),
      body: getValues(`reminders.${index}.body`),
    })
  }

  const onDelete = (index: number) => () => {
    remove(index)
  }

  return (
    <div className="rounded-lg border p-6">
      <div className="mb-6">
        <Text variant="subtitle">Reminders</Text>
        <div className="mt-2 text-sm">Send reminder emails to invitees at specified times</div>
      </div>

      <div className="flex w-full flex-col gap-2">
        {fields.map((field, index) => (
          <Reminder
            key={field._id}
            initTimeUnit={field.sendBefore.days ? 'days' : 'hours'}
            index={index}
            control={control}
            onDuplicate={onDuplicate(index)}
            onDelete={onDelete(index)}
            variables={variables}
          />
        ))}
        <Button type="button" variant="text" className="w-full justify-start rounded-lg border py-6" onClick={onAdd}>
          Add another reminder
        </Button>
      </div>
    </div>
  )
}

type ReminderProps = {
  control: Control<PostMeetingType | PatchMeetingType>
  index: number
  initTimeUnit: 'days' | 'hours'
  onDelete: () => void
  onDuplicate: () => void
  variables: string[]
}

function Reminder(props: ReminderProps) {
  const { index, onDelete, onDuplicate, initTimeUnit, variables } = props
  const { user } = useUserContext()
  const { control, watch } = useFormContext<PostMeetingType | PatchMeetingType>()
  const reminders = useWatch({ control, name: `reminders` })
  const remindersState = useFormState({ control, name: `reminders` })

  const [open, setOpen, setClose] = useDisclosureV2(false)
  const timeUnitOptions = [
    { label: 'hours', value: 'hours' },
    { label: 'days', value: 'days' },
  ]
  const [timeUnitOption, setTimeUnitOption] = useState(
    initTimeUnit === 'days' ? timeUnitOptions[1] : timeUnitOptions[0],
  )
  const [editorSubjectFocused, setEditorSubjectFocused] = useState(false)
  const [editorBodyFocused, setEditorBodyFocused] = useState(true)

  const formVariables = {
    ...variables.reduce((acc, item) => ((acc[item] = ''), acc), {} as Record<string, string>),

    // known variables that we can provide default values for
    MeetingName: watch(`description`),
    HostName: user.value?.traits.first_name + ' ' + user.value?.traits.last_name,
    InviteeName: 'Invitee',
    EventTime: format(new Date(), 'hh:mm'),
    EventDate: dateOnlyRFC3999Format(new Date()),
    MeetingLink:
      watch(`conferencingPlatform`) === ConferencingPlatform.ZOOM
        ? 'https://zoom.us/j/10041009022?pwd=lHFud3h3a0I5UTYxR8JIb2JOZUhaUT09'
        : 'https://meet.google.com/ept-bwmc-fus',
  }

  return (
    <div
      className={clsx(
        'rounded-lg border px-4 py-3',
        remindersState.errors.reminders?.[index] && !open && 'border-alert',
      )}
    >
      {!open ? (
        <div className="flex items-center justify-between">
          <div
            onClick={() => {
              // do not open if there is a selection
              if (window.getSelection()?.toString()) return
              setOpen()
            }}
          >
            <Text variant="subtitle">
              {timeUnitOption.value === 'days'
                ? pluralize(reminders[index].sendBefore.days, 'day')
                : pluralize(reminders[index].sendBefore.hours, 'hour')}{' '}
              before
            </Text>
            <div
              className="line-clamp-2 text-sm text-medium"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(reminders[index].body) }}
            />
          </div>
          <DropdownMenu>
            <DropdownMenu.Button>
              <DotsThree className="h-4.5 w-4.5" />
            </DropdownMenu.Button>
            <DropdownMenu.Items>
              <DropdownMenu.Item onClick={setOpen}>Edit</DropdownMenu.Item>
              <DropdownMenu.Item onClick={onDuplicate}>Duplicate</DropdownMenu.Item>
              <DropdownMenu.Item onClick={onDelete}>Delete</DropdownMenu.Item>
            </DropdownMenu.Items>
          </DropdownMenu>
        </div>
      ) : (
        <>
          <Text variant="subtitle">When</Text>
          <span className="mb-6 flex items-center gap-2">
            <Controller
              control={control}
              name={`reminders.${index}.sendBefore`}
              render={({ field: { onChange, value }, formState: { errors } }) => {
                const onTimeUnitChange = (option: { label: string; value: string }) => {
                  setTimeUnitOption(option)
                  if (option.value === 'days') {
                    onChange({ days: value.hours, seconds: 0 })
                  } else {
                    onChange({ days: 0, hours: value.days })
                  }
                }

                const onValueChange = (n: number) => {
                  if (timeUnitOption.value === 'days') {
                    onChange({ days: n, hours: 0 })
                  } else {
                    onChange({ days: 0, hours: n })
                  }
                }
                return (
                  <>
                    <Input
                      type="number"
                      min={1}
                      onChange={(e) => onValueChange(Number(e.target.value))}
                      value={value.days || value.hours}
                      className={clsx('w-20', errors?.reminders?.[index]?.sendBefore && 'border-alert')}
                    />{' '}
                    <Select onChange={onTimeUnitChange} value={timeUnitOption} options={timeUnitOptions} /> before event
                  </>
                )
              }}
            />
          </span>

          <EmailEditor variables={formVariables}>
            {({ preview }) => (
              <>
                <div className="mb-0.5">
                  <Text variant="subtitle" className="mb-2">
                    Email
                  </Text>
                  <Controller
                    control={control}
                    name={`reminders.${index}.subject`}
                    render={({ field: { onChange, value }, formState: { errors } }) => (
                      <div
                        className={clsx(
                          'mt-2 min-h-[4rem] rounded-lg border p-3',
                          errors?.reminders?.[index]?.subject && 'border-alert',
                          editorSubjectFocused && 'border-accent',
                        )}
                      >
                        <EmailEditor.Subject
                          onFocus={() => setEditorSubjectFocused(true)}
                          onBlur={() => setEditorSubjectFocused(false)}
                          content={value}
                          onChange={onChange}
                        />
                        {preview && <EmailEditor.PreviewSubject />}
                      </div>
                    )}
                  />
                </div>

                <div className="mb-0.5">
                  <Controller
                    control={control}
                    name={`reminders.${index}.body`}
                    render={({ field: { onChange, value }, formState: { errors } }) => (
                      <div
                        className={clsx(
                          'mt-2 min-h-[6rem] rounded-lg border p-3',
                          errors?.reminders?.[index]?.body && 'border-alert',
                          editorBodyFocused && 'border-accent',
                        )}
                      >
                        <EmailEditor.Body
                          onFocus={() => setEditorBodyFocused(true)}
                          onBlur={() => setEditorBodyFocused(false)}
                          content={value}
                          onChange={onChange}
                        />
                        {preview && <EmailEditor.PreviewBody />}
                      </div>
                    )}
                  />
                </div>
                <div className="-ml-0.5 mb-8 mt-2">
                  <Toolbar>
                    <Toolbar.Light />
                  </Toolbar>
                </div>
              </>
            )}
          </EmailEditor>

          <div className="flex items-center justify-between">
            <div className="flex gap-2">
              <TrashSimple
                className="h-5 w-5 cursor-pointer text-medium transition-all hover:text-dark hover:duration-100"
                onClick={onDelete}
              />
              <CopySimple
                className="h-5 w-5 cursor-pointer text-medium transition-all hover:text-dark hover:duration-100"
                onClick={onDuplicate}
              />
            </div>
            <Button variant="basic" onClick={setClose}>
              Done
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
