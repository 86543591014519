import { ContactEmailStatus } from '@/api/core'
import { ContactEmailStatusToText } from '@/api/text/contact_status'

export function EmailStatusBadge(email_status: ContactEmailStatus) {
  const text = ContactEmailStatusToText[email_status]
  switch (email_status) {
    case ContactEmailStatus.INVALID:
      return <span className="px-2 py-0.5 text-xs text-rift-red-800">{text}</span>
    case ContactEmailStatus.CATCH_ALL:
      return <span className="px-2 py-0.5 text-xs text-rift-orange-800">{text}</span>
    default:
      return <span className="px-2 py-0.5 text-xs capitalize">{text}</span>
  }
}
