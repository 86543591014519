import { ContactStage } from '@/api/core'

export const ContactStageToText: Record<ContactStage, string> = {
  [ContactStage.NEW]: 'New',
  [ContactStage.ACTIVE]: 'Active',
  [ContactStage.INVALID]: 'Invalid',
  [ContactStage.INACTIVE]: 'Inactive',
  [ContactStage.INTERESTED]: 'Interested',
  [ContactStage.CONVERTED]: 'Converted',
  [ContactStage.UNSUBSCRIBED]: 'Unsubscribed',
  [ContactStage.OOO]: 'OOO',
  [ContactStage.NOT_INTERESTED]: 'Not Interested',
}

export const TextToContactStage: Record<string, ContactStage> = Object.fromEntries(
  Object.entries(ContactStageToText).map(([key, value]) => [value, key as ContactStage]),
)

export const ContactStageLabels: { label: string; value: ContactStage }[] = Object.entries(ContactStageToText).map(
  ([value, label]) => ({ label, value: value as ContactStage }),
)

export type ContactStageLabel = (typeof ContactStageLabels)[number]
