import { ForwardedRef } from 'react'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import clsx from 'clsx'
import { SuggestionProps } from '@tiptap/suggestion'

export type ContextMenuRef = {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean
}

type ContextMenuProps = Pick<SuggestionProps, 'items' | 'command'>

function ContextMenuFn(props: ContextMenuProps, ref: ForwardedRef<ContextMenuRef>) {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectItem = (index: number) => {
    const item = props.items[index]

    if (item) {
      props.command({ name: item.name })
    }
  }

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
  }

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length)
  }

  const enterHandler = () => {
    selectItem(selectedIndex)
  }

  useEffect(() => setSelectedIndex(0), [props.items])

  useImperativeHandle(
    ref,
    () => {
      return {
        onKeyDown: (x: { event: KeyboardEvent }) => {
          if (x.event.key === 'ArrowUp') {
            upHandler()
            return true
          }

          if (x.event.key === 'ArrowDown') {
            downHandler()
            return true
          }

          if (x.event.key === 'Enter') {
            enterHandler()
            return true
          }

          return false
        },
      }
    },
    [upHandler, downHandler, enterHandler],
  )

  return (
    <div className="m-0 block w-full rounded-md bg-white text-left">
      {props.items.map((item: { fallbackImage: string; name: string; emoji: string }, index: number) => (
        <button
          type="button"
          className={clsx(
            'm-0 block w-full rounded-md bg-white text-left hover:bg-gray-100',
            index === selectedIndex ? 'bg-gray-100 text-primary' : 'text-gray-700',
          )}
          key={index}
          onClick={() => selectItem(index)}
        >
          <div className="flex flex-row items-center gap-2 p-2">
            {item.fallbackImage ? <img src={item.fallbackImage} alt="" className="h-4 w-4" /> : item.emoji}
            <span>:{item.name}:</span>
          </div>
        </button>
      ))}
    </div>
  )
}

export const ContextMenu = forwardRef<ContextMenuRef, ContextMenuProps>(ContextMenuFn)
