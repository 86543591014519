import { useNavigate, useParams } from 'react-router-dom'
import { ManualStepEmailInfo } from '@/api/core'
import { useManualStepsEmail, useManualStepsEmailSend } from '@/api/manual_step'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { ActivityHistory } from '../../ActivityHistory'
import { ContactInformation } from '../../ContactInformation'
import { SingleStep } from '../../SingleStep'
import { SingleStepSkeleton } from '../../Skeletons'
import { Editor } from './Editor'

export function SingleEmail() {
  const { emailId } = useParams() as { emailId: string }
  const { data, status } = useManualStepsEmail(emailId)

  if (status === 'pending' || status === 'error') {
    return <SingleStepSkeleton />
  }

  return (
    <section key={emailId}>
      <SingleEmailHandler emailId={emailId} data={data.data} />
    </section>
  )
}

type SingleEmailHandlerProps = {
  emailId: string
  data: ManualStepEmailInfo
}

function SingleEmailHandler({ emailId, data }: SingleEmailHandlerProps) {
  const navigate = useNavigate()
  const toast = useToast()
  const sendEmail = useManualStepsEmailSend(emailId, data.contact.id)

  const onSend = () => {
    sendEmail.mutate(undefined, {
      onSuccess: () => {
        toast.createToast({ message: `Sequence started for ${data.contact.firstName} ${data.contact.lastName}` })
        if (data.nextEmailId) {
          navigate(`/inbox/emails/${data.nextEmailId}`)
        } else if (data.prevEmailId) {
          navigate(`/inbox/emails/${data.prevEmailId}`)
        } else {
          navigate('/inbox/emails/')
        }
      },
      onError: (error) => {
        toast.createToast({ message: (error as any)?.body?.message || 'Failed to send email', error: true })
      },
    })
  }

  return (
    <SingleStep>
      <SingleStep.Navigation prev={data.prevEmailId} next={data.nextEmailId} url="/inbox/emails/" />

      <SingleStep.Main>
        <Editor
          variables={data.variables}
          subject={data.subjectTmpl}
          emailId={emailId}
          body={data.bodyTmpl}
          onSend={onSend}
          isSending={sendEmail.isPending}
        />
        <ActivityHistory contactId={data.contact.id} />
      </SingleStep.Main>
      <ContactInformation contactId={data.contact.id} />
    </SingleStep>
  )
}
