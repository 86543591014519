import { useContext, useEffect, useState } from 'react'
import { omitObject } from '@/lib/utils'
import { FormContext } from '@/providers/formContext'
import { getLocalStorageService } from './localStorageService'

export function FormProvider({ children }: any) {
  const [data, setData] = useState({})
  return <FormContext.Provider value={{ data, setData }}>{children}</FormContext.Provider>
}
const LOCAL_STORAGE_FORMS_KEY = 'forms'

export function useFormData<T extends Record<string, any>>(name: string, defaultValue: T, usePersistence?: boolean) {
  const ctx = useContext(FormContext)
  const formData: Partial<T> = ctx.data[name]
  const formStorage = getLocalStorageService(LOCAL_STORAGE_FORMS_KEY)

  useEffect(() => {
    if (typeof ctx.data[name] === 'undefined') {
      const storedValue = formStorage.getItem(name)
      const initialValue = usePersistence && storedValue ? storedValue : defaultValue
      ctx.setData((otherValues: any) => {
        return { ...otherValues, [name]: initialValue || {} }
      })
    }
  }, [])

  const setData = (values: Partial<T>) => {
    const newValues = { ...formData, ...values }
    const newState = { ...ctx.data, [name]: newValues }
    ctx.setData(newState)
    if (newValues && usePersistence) formStorage.setItem(name, newValues)
  }

  const clearData = () => {
    const newState = { ...ctx.data, [name]: undefined }
    formStorage.removeItem(name)
    ctx.setData(newState)
  }
  return { data: formData, setData, clearData }
}

export type StoredDraft = {
  id: string
  fields: { name: string | undefined }
  meta?: { created: number; updated: number }
}

export function useFormDataHelpers<T extends Record<string, any>>() {
  const ctx = useContext(FormContext)
  const data: Partial<T> = ctx.data
  const localStorage = getLocalStorageService(LOCAL_STORAGE_FORMS_KEY)
  const [draftsList, setDraftsList] = useState<StoredDraft[]>([])

  const getStoredDraftList = () => {
    setDraftsList(localStorage.getPartialObjectsWithMeta(['name']) as StoredDraft[])
  }

  useEffect(() => {
    getStoredDraftList()
  }, [])

  const checkFormData = (name: string) => {
    const storedValue = localStorage.getItem(name)
    return storedValue ? JSON.parse(storedValue) : null
  }

  const clearFormData = (name: string) => {
    const newState = omitObject(data, [name])
    localStorage.removeItem(name)
    ctx.setData(newState)
  }

  return { checkFormData, clearFormData, getStoredDraftList, draftsList }
}
