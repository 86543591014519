import { useEffect } from 'react'
import { useCampaignAnalytics } from '@/api/campaigns'
import { Campaign } from '@/api/core/models/Campaign'
import { useSequenceAnalyticsContext } from '@/providers/Sequences/SequenceAnalyticsContext'
import { Activity } from './Activity'
import { Graph } from './Graph'
import { Overview } from './Overview'

export function Stats({ campaign }: { campaign: Campaign }) {
  const { state, dispatch } = useSequenceAnalyticsContext()

  const { data: analyticsData } = useCampaignAnalytics(campaign.id, state.from || 0, state.to)

  useEffect(() => {
    if (analyticsData?.data && dispatch) {
      dispatch({ type: 'update_analytics', body: { analytics: analyticsData?.data } })
    }
  }, [analyticsData])

  // TODO: Add Skeleton

  return (
    <section className="bg-white' mt-6 grid h-[92%] w-full ">
      <div className="no-scrollbar relative overflow-y-scroll">
        <Overview campaign={campaign} />
        {state.analytics && <Graph />}
        <Activity campaign={campaign} />
      </div>
    </section>
  )
}
