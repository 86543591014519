import { useLayoutEffect, useState } from 'react'
import { Call, Device } from '@twilio/voice-sdk'

// useful docs
// - https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice#events
// - https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#events
// - https://www.twilio.com/docs/voice/twiml/dial#dialcallstatus

export function useDevice(token: string) {
  const [callDevice, setCallDevice] = useState<Device | null>(null)
  const [deviceError, setDeviceError] = useState<Error | null>(null)

  useLayoutEffect(() => {
    let device: Device | null = null
    try {
      device = new Device(token, {
        logLevel: 0,
        codecPreferences: [Call.Codec.PCMU, Call.Codec.Opus],
      })
      device.register()
      device.on('error', (error) => {
        setDeviceError(error)
      })
      device.on('registered', () => {
        setCallDevice(device)
      })
      device.on('closed', () => {
        if (device) {
          device.destroy()
          setCallDevice(null)
        }
      })
    } catch (error) {
      setDeviceError(error as any)
    }

    return () => {
      if (device) device.destroy()
      setCallDevice(null)
    }
  }, [token])

  return { device: callDevice, error: deviceError }
}

export function useCall(
  device: Device | null,
  eventId: string,
  startCall: boolean,
): {
  call: () => void

  // disconnect the call and close the connection
  disconnect: () => void

  // error that happened during the call
  error: unknown | null

  // true if the call was answered, false otherwise
  answered: boolean | null

  //
  completed: boolean | null
} {
  const [callState, setCallState] = useState<ReturnType<typeof useCall>>({
    call: () => {},
    disconnect: () => {},
    error: null,
    answered: null,
    completed: null,
  })

  useLayoutEffect(() => {
    if (device === null || !startCall) return
    ;(async () => {
      try {
        const call = await device.connect({ params: { eventId } })
        setCallState((prev) => ({ ...prev, disconnect: () => call.disconnect() }))
        call.on('cancel', () => setCallState((prev) => ({ ...prev, answered: false })))
        call.on('disconnect', () => setCallState((prev) => ({ ...prev, answered: false })))
        call.on('error', (error) => setCallState((prev) => ({ ...prev, error, answered: false })))
        call.on('reject', () => setCallState((prev) => ({ ...prev, answered: false })))
        call.on('completed', () => setCallState((prev) => ({ ...prev, completed: true })))
      } catch (error) {
        setCallState((prev) => ({ ...prev, error, answered: false }))
      }
    })()
  }, [device === null, eventId, startCall])

  return callState
}
