import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ArrowLeft } from '@phosphor-icons/react'
import { PostInboxesMain } from '@/api/core/models/PostInboxesMain'
import { useMainInboxes, useManualInboxesDelete, useManualInboxesSave } from '@/api/inbox'
import { GoogleMailIcon } from '@/icons/GoogleMailIcon'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Input, InputPassword, Text } from '@/ui'

export function GoogleMail() {
  const navigation = useNavigate()
  const toast = useToast()

  const { data } = useMainInboxes()
  const saveInbox = useManualInboxesSave()
  const deleteInbox = useManualInboxesDelete()

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email is invalid'),
    password: Yup.string().required('App password is required').length(16, 'App password must be 16 characters'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Omit<PostInboxesMain, 'provider'>>({ resolver: yupResolver(validationSchema) })

  const onSubmit = (data: Omit<PostInboxesMain, 'provider'>) => {
    saveInbox.mutate(
      { email: data.email, password: data.password, provider: PostInboxesMain.provider.GMAIL },
      {
        onSuccess: () => {
          toast.createToast({ message: 'Google Mail connected' })
        },
        onError: (err) => {
          toast.createToast({ error: true, message: (err as any)?.body?.message || 'Failed to connect' })
        },
      },
    )
  }

  const onDelete = () => {
    deleteInbox.mutate(undefined, {
      onSuccess: () => {
        toast.createToast({ message: 'Google Mail disconnected' })
      },
      onError: (err) => {
        toast.createToast({ error: true, message: (err as any)?.body?.message || 'Failed to disconnect' })
      },
    })
  }

  return (
    <section className="flex flex-col gap-8">
      <div className="flex gap-2">
        <button onClick={() => navigation('/settings/linked-accounts')}>
          <ArrowLeft className="h-5 w-5 text-medium" />
        </button>
        <Text variant="subtitle">My Linked Accounts</Text>
      </div>
      <div className="flex items-center justify-between">
        <section>
          <GoogleMailIcon className="h-12 w-12" />
          <Text variant="title" className="pt-4">
            Google Mail
          </Text>
          {data?.data === null ? (
            <Text className="text-dusk">
              Reply to lead responses with your primary email address directly in rift without switching to your email
              client.{' '}
              <Link
                to="https://support.google.com/mail/answer/185833?hl=en"
                className="underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </Link>
            </Text>
          ) : (
            <Text className="text-dusk"> Google Mail connected to {data?.data?.email}</Text>
          )}
        </section>

        {data?.data !== null && <Button onClick={onDelete}>Disconnect</Button>}
      </div>
      {data?.data === null && (
        <section>
          <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
            <Input
              label="Email"
              placeholder="Email"
              error={errors['email']?.message}
              registration={register('email')}
            />
            <div className="flex flex-col gap-2">
              <div>
                <Text className="font-medium" variant="text">
                  App password
                </Text>
                <Text className="text-dusk">
                  You need to create a Google app password in order to securely connect to Google Mail
                </Text>
              </div>

              <Button
                variant="accent"
                className="w-fit"
                onClick={() => window.open('https://myaccount.google.com/apppasswords', '_blank')}
              >
                Create an app password
              </Button>

              <div>
                <Text className="text-medium">then</Text>
                <InputPassword
                  placeholder="Enter your app password here"
                  error={errors['password']?.message}
                  registration={register('password', { setValueAs: (value) => value.replaceAll(' ', '') })}
                  iconButtonClassName="top-2"
                />
              </div>
            </div>
            <Button variant="basic" className="ml-auto w-fit" type="submit">
              Connect
            </Button>
          </form>
        </section>
      )}
    </section>
  )
}
