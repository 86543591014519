import { ElementType, Ref, useLayoutEffect, useMemo, useRef } from 'react'
import { Props } from '@headlessui/react/dist/types'
import { useSyncRefs } from '@/hooks/useSyncRefs'
import { forwardRefWithAs, uirender } from '@/ui/render'
import { Back } from './Back'
import { useActions, useData } from './Context'
import { Group } from './Group'
import { List } from './List'
import { Next } from './Next'
import { Panel } from './Panel'
import { Panels } from './Panels'

export const DEFAULT_STEP_TAG = 'div' as const
export enum StepStatus {
  ERROR,
  IN_PROGRESS,
  NOT_STARTED,
  COMPLETED,
}

export interface StepRenderPropArg {
  status: StepStatus
  index: number
}

export type StepProps<TTag extends ElementType> = Props<TTag, StepRenderPropArg, never>

function StepFn<TTag extends ElementType = typeof DEFAULT_STEP_TAG>(props: StepProps<TTag>, ref: Ref<HTMLElement>) {
  const actions = useActions('Step')
  const data = useData('Step')

  const internalStepRef = useRef<HTMLElement | null>(null)
  const stepRef = useSyncRefs(internalStepRef, ref)
  const myIndex = data.steps.indexOf(internalStepRef)
  const status =
    myIndex === data.currentIndex
      ? StepStatus.IN_PROGRESS
      : myIndex < data.currentIndex
        ? StepStatus.COMPLETED
        : StepStatus.NOT_STARTED

  const slot = useMemo(() => ({ status: status, index: myIndex }), [status, myIndex])

  useLayoutEffect(() => actions.registerStep(internalStepRef), [actions, internalStepRef])
  const ourProps = { ref: stepRef }

  if (data.renderCurrentStep && myIndex !== data.currentIndex) return null
  return uirender({ ourProps, theirProps: props, slot, defaultTag: DEFAULT_STEP_TAG })
}

export const Step = Object.assign(forwardRefWithAs(StepFn), {
  Group: forwardRefWithAs(Group),
  List: forwardRefWithAs(List),
  Panels: forwardRefWithAs(Panels),
  Panel: forwardRefWithAs(Panel),
  Back: forwardRefWithAs(Back),
  Next: forwardRefWithAs(Next),
})
