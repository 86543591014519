import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Password, PatchPassword } from '@/api/core'
import { usePasswordUpdate } from '@/api/vault'
import { totpSecretFromClipboard } from '@/lib/totp'
import { Button, ButtonLoading, Input, Textarea } from '@/ui'
import { InputPassword } from '@/ui/Input/v2'

type PasswordEditProps = {
  password: Password
  onClose: () => void
}

export function PasswordEdit(props: PasswordEditProps) {
  const { password, onClose } = props
  const update = usePasswordUpdate(password.id)

  const schema: yup.ObjectSchema<PatchPassword> = yup.object().shape({
    name: yup.string().required('required'),
    username: yup.string().required('required'),
    password: yup.string().required('required'),
    website: yup.string().required('required'),
    totpSecret: yup.string().nullable(),
    notes: yup.string().default(''),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<PatchPassword>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: password.name,
      username: password.username,
      password: password.password,
      website: password.website,
      totpSecret: password.totpSecret,
      notes: password.notes,
    },
  })
  const onSubmit = (data: PatchPassword) => update.mutate(data, { onSuccess: onClose })
  const onToptPaste = async (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const secret = await totpSecretFromClipboard<HTMLInputElement>(e)
    setValue('totpSecret', secret)
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex w-96 flex-col gap-4">
          <Input
            registration={register('name')}
            error={errors.name}
            label="Name"
            data-testid="password-vault-edit-name-input"
          />
          <Input
            registration={register('username')}
            error={errors.username}
            label="Username"
            data-testid="password-vault-edit-username-input"
          />
          <InputPassword
            registration={register('password')}
            error={errors.password}
            label="Password"
            data-testid="password-vault-edit-password-input"
          />
          <Input
            registration={register('website')}
            error={errors.website}
            label="Website"
            data-testid="password-vault-edit-website-input"
          />
          <InputPassword
            registration={register('totpSecret')}
            error={errors.totpSecret}
            label="TOTP Secret"
            onPaste={onToptPaste}
            data-testid="password-vault-edit-totp-secret-input"
          />
          <Textarea
            rows={4}
            registration={register('notes')}
            error={errors.notes}
            label="Notes"
            data-testid="password-vault-edit-notes-textarea"
          />
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button variant="basic" onClick={onClose} data-testid="password-vault-edit-cancel-btn">
            Cancel
          </Button>
          <ButtonLoading
            type="submit"
            variant="accent"
            isLoading={update.isPending}
            data-testid="password-vault-edit-save-btn"
          >
            Save
          </ButtonLoading>
        </div>
        <span className={clsx('text-alert', !update.isError && 'invisible')}>
          Failed to update {(update.error as any)?.body?.message}
        </span>
      </form>
    </div>
  )
}
