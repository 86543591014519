import { KratosForm } from '@/containers/Settings/KratosForm'
import { Text } from '@/ui'

export function Security() {
  return (
    <section>
      <Text variant="title" className="pb-2">
        Security
      </Text>
      <div className="ory w-full">
        <KratosForm settingsFlowType={['totp']} />
      </div>
    </section>
  )
}
