import clsx from 'clsx'
import { DateTime } from 'luxon'
import { twMerge } from 'tailwind-merge'
import { MeetingSlot } from '@/api/core'
import { Button } from '@/ui/Button/v2'

type TimeSlotProps = {
  slots: MeetingSlot[]
  selected?: MeetingSlot
  handleTimeSelection: (slot: MeetingSlot) => void
  className?: string
  slotClassName?: string
}

export function TimeSelect({ slots, handleTimeSelection, className, slotClassName, selected }: TimeSlotProps) {
  const options = slots.map((slot: MeetingSlot) => {
    const time = DateTime.fromISO(slot.start_time).toLocaleString(DateTime.TIME_SIMPLE)
    return { value: slot, label: time }
  })
  return (
    <div className={twMerge(className, 'time-slot w-24')}>
      {options.map(({ label, value }) => (
        <Button
          variant="basic"
          value={value.start_time}
          onClick={() => handleTimeSelection(value)}
          className={clsx('w-full', slotClassName, selected === value && 'border-rift-blue-600')}
          key={value.start_time}
        >
          {label}
        </Button>
      ))}
    </div>
  )
}
