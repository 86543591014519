import { Dialog } from '@headlessui/react'
import { useCompanyAPIKeyDelete } from '@/api'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Text } from '@/ui'

export function DeleteKey({
  isOpen,
  close,
  keyId,
  keyName,
}: {
  isOpen: boolean
  close: () => void
  keyId: string
  keyName: string
}) {
  const remove = useCompanyAPIKeyDelete()
  const toast = useToast()

  const onRemove = (id: string) => {
    remove.mutate(id, {
      onError: () => toast.createToast({ message: `Failed to delete API key`, error: true }),
    })
    close()
  }

  return (
    <Dialog open={isOpen} onClose={close} className="relative z-50">
      <div className="fixed inset-0 bg-white/70" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="mx-auto w-full max-w-xs rounded-lg border border-light bg-white p-6 shadow-sm">
          <Dialog.Title as="div">
            <Text variant="subtitle">Are you sure you want to delete {keyName}?</Text>
          </Dialog.Title>

          <div className="ml-auto flex">
            <Button variant="text" onClick={close} className="ml-auto">
              Cancel
            </Button>
            <Button variant="text" className="ml-4 p-0" onClick={() => onRemove(keyId)}>
              Delete
            </Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
