import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import * as yup from 'yup'
import { Disclosure } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { CaretUp } from '@phosphor-icons/react'
import { DomainRecordType, PutOnboardingDomainColdOutboundRecord } from '@/api/core'
import { useOnboardingPutDomainColdOutboundRecord } from '@/api/onboarding'
import { DomainRecordTypeLabel, DomainRecordTypeLabels } from '@/api/text/onboarding'
import { FeatureFlag } from '@/containers/FeatureFlag'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { ButtonLoading, Input, Select } from '@/ui'

export function DomainRecordAdd() {
  const putDomainColdOutboundRecord = useOnboardingPutDomainColdOutboundRecord()
  const toast = useToast()

  const schema: yup.ObjectSchema<PutOnboardingDomainColdOutboundRecord> = yup.object().shape({
    type: yup.mixed<DomainRecordType>().oneOf(Object.values(DomainRecordType)).required(),
    name: yup.string().required('required').trim(),
    data: yup.string().required('required').trim(),
    priority: yup.number().required('required').min(0).max(65535).default(0),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<PutOnboardingDomainColdOutboundRecord>({
    defaultValues: {
      type: DomainRecordType.TXT,
      name: '',
      data: '',
      priority: 0,
    },
    resolver: yupResolver(schema),
  })

  const typ = watch('type')

  const onSubmit = async (data: PutOnboardingDomainColdOutboundRecord) => {
    await putDomainColdOutboundRecord.mutateAsync(data, {
      onSuccess: () => {
        toast.createToast({ message: 'Domain record added' })
      },
    })
  }

  return (
    <FeatureFlag>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full py-2 text-left text-sm font-medium hover:text-rift-blue-500">
              <div>
                <p className="text-xs">Add cold outbound domain record</p>
              </div>
              <CaretUp className={clsx('ml-0.5 mt-0.5 h-4 w-4', open && 'rotate-180 transform')} />
            </Disclosure.Button>
            <Disclosure.Panel className="w-full pt-4">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Select
                  value={DomainRecordTypeLabels.find((s) => s.value === typ) as DomainRecordTypeLabel}
                  onChange={(v) => setValue('type', v.value)}
                  options={DomainRecordTypeLabels}
                />
                <Input registration={register(`name`)} error={errors.name} label="Name" />
                {typ === DomainRecordType.MX && (
                  <Input registration={register(`priority`)} error={errors.priority} label="Priority" />
                )}
                <Input registration={register(`data`)} error={errors.data} label="Value" />
                <ButtonLoading
                  type="submit"
                  className="w-full"
                  isLoading={putDomainColdOutboundRecord.isPending}
                  variant="text"
                >
                  Add
                </ButtonLoading>
              </form>
              <span className={clsx('text-alert', !putDomainColdOutboundRecord.isError && 'invisible')}>
                Failed to save {(putDomainColdOutboundRecord.error as any)?.body?.message}
              </span>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </FeatureFlag>
  )
}
