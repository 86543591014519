// kratos
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useQueryClient } from '@tanstack/react-query'
import { kratos } from '@/config/kratos'
import { useUserContext } from '@/providers/User/context'
import { reportSentryErrorWithEmail } from '@/sentry'

// Returns a function which will log the user out
export function logoutHandler() {
  const [logoutToken, setLogoutToken] = useState<string>('')
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { makePublic } = useUserContext()

  useEffect(() => {
    kratos
      .createBrowserLogoutFlow()
      .then(({ data }) => {
        setLogoutToken(data.logout_token)
      })
      .catch((error: AxiosError<any, any>) => {
        reportSentryErrorWithEmail('[KRATOS] Logout createSelfServiceLogoutFlowUrlForBrowsers failed', {
          extra: {
            error,
          },
        })
        switch (error.response?.status) {
          case 401:
            // do nothing, the user is not logged in
            return
        }

        // Something else happened!
        return Promise.reject(error)
      })
  }, [])

  return () => {
    if (logoutToken) {
      kratos.updateLogoutFlow({ token: logoutToken }).then(() => {
        queryClient.invalidateQueries()
        queryClient.resetQueries()
        queryClient.removeQueries()
        queryClient.clear()
        makePublic()
        navigate('/login')
      })
    }
  }
}
