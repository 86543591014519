import { useEffect } from 'react'
import { expireDuration } from '@/lib/date'
import { useCounter } from './useCounter'

type options = {
  interval?: number
}

export function useDatetimeCountdown(expiresAt: number, options: options = {}) {
  const { interval = 1000 } = options
  const expiresIn = Math.floor(expireDuration(expiresAt) / 1000)
  const [count, { dec, setCount }] = useCounter(expiresIn, { min: 0 })
  useEffect(() => setCount(expiresIn), [expiresIn])

  useEffect(() => {
    const timer = setInterval(dec, interval)
    return () => clearInterval(timer)
  }, [])
  return [count]
}
