import { MemberInbox, TeamMember } from '@/api/core'

export type State = {
  selectedInboxes: MemberInbox[]
  selectedMembers: TeamMember[]
}

export enum ActionType {
  Unselect,

  ToggleInbox,
  SelectInbox,

  ToggleMember,
  SelectMember,
}

export type Action =
  | { type: ActionType.Unselect }
  | { type: ActionType.ToggleInbox; inbox: MemberInbox }
  | { type: ActionType.SelectInbox; inbox: MemberInbox }
  | { type: ActionType.ToggleMember; member: TeamMember }
  | { type: ActionType.SelectMember; member: TeamMember }

const reducers: {
  [P in ActionType]: (state: State, action: Extract<Action, { type: P }>) => State
} = {
  [ActionType.Unselect]() {
    return { selectedInboxes: [], selectedMembers: [] }
  },
  [ActionType.ToggleInbox](state, action) {
    const selectedInboxes = state.selectedInboxes.some((inbox) => inbox.id === action.inbox.id)
      ? state.selectedInboxes.filter((inbox) => inbox.id !== action.inbox.id)
      : [...state.selectedInboxes, action.inbox]
    return { selectedInboxes, selectedMembers: [] }
  },
  [ActionType.SelectInbox](state, action) {
    return { selectedInboxes: [action.inbox], selectedMembers: [] }
  },
  [ActionType.ToggleMember](state, action) {
    const selectedMembers = state.selectedMembers.some((member) => member.id === action.member.id)
      ? state.selectedMembers.filter((member) => member.id !== action.member.id)
      : [...state.selectedMembers, action.member]
    return { selectedInboxes: [], selectedMembers }
  },
  [ActionType.SelectMember](state, action) {
    return { selectedInboxes: [], selectedMembers: [action.member] }
  },
}

export function reducer(state: State, action: Action) {
  return reducers[action.type](state, action as any)
}
