import { useNavigate } from 'react-router-dom'
import { useCampaignClone, useCampaignsArchive, useCampaignsStop } from '@/api/campaigns'
import { CampaignList } from '@/api/core/models/CampaignList'
import { pluralize } from '@/lib/strings'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { FloatingAppBar, Text } from '@/ui'
import { DisplayStatusName } from '../types'

type FloatingAppBarProps = {
  selectedRows: Record<string, boolean>
  dataById: Record<string, CampaignList>
  setRowSelection: (value: React.SetStateAction<Record<string, boolean>>) => void
}
export function SequenceFloatingAppBar(props: FloatingAppBarProps) {
  const { selectedRows, dataById, setRowSelection } = props
  const selectedSequenceIds = Object.keys(selectedRows).filter((id) => selectedRows[id])

  const selectedRunningRows = selectedSequenceIds.filter(
    (id) => DisplayStatusName[dataById[id]?.status] === DisplayStatusName.LAUNCHED,
  )

  const clearSelectedRows = () => setRowSelection({})

  return (
    <>
      <ArchiveFloatingAppBar
        clearSelectedRows={clearSelectedRows}
        campaignIds={selectedSequenceIds}
        open={selectedSequenceIds.length > 1 && !selectedRunningRows.length}
        selected={selectedSequenceIds.length}
      />
      <SingleSelectedFloatingAppBar
        open={selectedSequenceIds.length === 1}
        selected={selectedSequenceIds.length}
        campaignIds={selectedSequenceIds}
        clearSelectedRows={clearSelectedRows}
      />
      <PauseFloatingAppBar
        clearSelectedRows={clearSelectedRows}
        open={selectedRunningRows.length > 1 && selectedRunningRows.length === selectedSequenceIds.length}
        selected={selectedSequenceIds.length}
        campaignIds={selectedSequenceIds}
      />
    </>
  )
}

type FloatingAppBarComponentProps = {
  open: boolean
  selected: number
  campaignIds: string[]
  clearSelectedRows: () => void
}

function ArchiveFloatingAppBar({ open, selected, campaignIds, clearSelectedRows }: FloatingAppBarComponentProps) {
  const archiveCampaign = useCampaignsArchive()
  const toast = useToast()

  const onArchive = () => {
    archiveCampaign.mutate(
      { ids: campaignIds },
      {
        onSuccess: () => {
          toast.createToast({ message: `${pluralize(campaignIds.length, 'Sequence')} archived` })
          clearSelectedRows()
        },
        onError: (err: unknown) => {
          toast.createToast({
            message: (err as any)?.body?.message || `failed to archive ${pluralize(campaignIds.length, 'sequence')}`,
            error: true,
          })
        },
      },
    )
  }

  return (
    <FloatingAppBar open={open}>
      <Text variant="subtitle" className="text-sm">
        {selected} Sequence
      </Text>
      <FloatingAppBar.Action onClick={onArchive}>Archive</FloatingAppBar.Action>
    </FloatingAppBar>
  )
}

function PauseFloatingAppBar({ open, selected, campaignIds, clearSelectedRows }: FloatingAppBarComponentProps) {
  const stopCampaign = useCampaignsStop()
  const toast = useToast()

  const onPause = () => {
    stopCampaign.mutate(
      { ids: campaignIds },
      {
        onSuccess: () => {
          toast.createToast({ message: `${pluralize(campaignIds.length, 'Sequence')} paused` })
          clearSelectedRows()
        },
        onError: (err: unknown) => {
          toast.createToast({
            message: (err as any)?.body?.message || `failed to pause ${pluralize(campaignIds.length, 'sequence')}`,
            error: true,
          })
        },
      },
    )
  }

  return (
    <FloatingAppBar open={open}>
      <Text variant="subtitle" className="self-center text-sm">
        {selected} Sequence
      </Text>
      <FloatingAppBar.Action onClick={onPause} className="ml-auto self-center">
        Pause
      </FloatingAppBar.Action>
    </FloatingAppBar>
  )
}

function SingleSelectedFloatingAppBar({ open, selected, campaignIds: campaignId }: FloatingAppBarComponentProps) {
  const navigate = useNavigate()
  const toast = useToast()
  const cloneCampaign = useCampaignClone()

  const onEdit = () => {
    navigate(`/sequences/${campaignId[0]}`)
  }

  const onClone = () => {
    cloneCampaign.mutate(campaignId[0], {
      onSuccess: ({ data }) => {
        toast.createToast({ message: `${pluralize(campaignId.length, 'Sequence')} cloned` })
        navigate(`${data.id}`)
      },
      onError: (err: unknown) => {
        toast.createToast({ message: (err as any)?.body?.message || 'failed to clone sequence', error: true })
      },
    })
  }

  return (
    <FloatingAppBar open={open}>
      <Text variant="subtitle" className="self-center text-sm">
        {selected} Sequence
      </Text>
      <FloatingAppBar.Action onClick={onEdit}>Edit</FloatingAppBar.Action>
      <FloatingAppBar.Action onClick={onClone}>Clone</FloatingAppBar.Action>
    </FloatingAppBar>
  )
}
