import { useMemo, useState } from 'react'

type Key = string | number

export interface TreeOptions<T extends object> {
  /** Initial root items in the tree. */
  initialItems?: T[]
  /** The keys for the initially selected items. */
  initialSelectedKeys?: Iterable<Key>
  /** A function that returns a unique key for an item object. */
  getKey: (item: T) => Key
  /** A function that returns the children for an item object. */
  getChildren: (item: T) => T[]
}

interface TreeNode<T extends object> {
  /** A unique key for the tree node. */
  key: Key
  /** The key of the parent node. */
  parentKey: Key | null
  /** The value object for the tree node. */
  value: T
  /** Children of the tree node. */
  children: TreeNode<T>[]
}

export interface TreeData<T extends object> {
  /** The root nodes in the tree. */
  items: TreeNode<T>[]

  /** The keys of the currently selected items in the tree. */
  selectedKeys: Set<Key>

  /** Sets the selected keys. */
  setSelectedKeys(keys: Set<Key>): void

  /**
   * Gets a node from the tree by key.
   * @param key - The key of the item to retrieve.
   */
  getItem(key: Key): TreeNode<T> | null

  /**
   * Inserts an item into a parent node as a child.
   * @param parentKey - The key of the parent item to insert into. `null` for the root.
   * @param index - The index within the parent to insert into.
   * @param value - The value to insert.
   */
  insert(parentKey: Key | null, index: number, ...values: T[]): void

  /**
   * Inserts items into the list before the item at the given key.
   * @param key - The key of the item to insert before.
   * @param values - The values to insert.
   */
  insertBefore(key: Key, ...values: T[]): void

  /**
   * Inserts items into the list after the item at the given key.
   * @param key - The key of the item to insert after.
   * @param values - The values to insert.
   */
  insertAfter(key: Key, ...values: T[]): void

  /**
   * Appends an item into a parent node as a child.
   * @param parentKey - The key of the parent item to insert into. `null` for the root.
   * @param value - The value to insert.
   */
  append(parentKey: Key | null, ...values: T[]): void

  /**
   * Prepends an item into a parent node as a child.
   * @param parentKey - The key of the parent item to insert into. `null` for the root.
   * @param value - The value to insert.
   */
  prepend(parentKey: Key | null, ...value: T[]): void

  /**
   * Removes an item from the tree by its key.
   * @param key - The key of the item to remove.
   */
  remove(...keys: Key[]): void

  /**
   * Removes all items from the tree that are currently
   * in the set of selected items.
   */
  removeSelectedItems(): void

  /**
   * Moves an item within the tree.
   * @param key - The key of the item to move.
   * @param toParentKey - The key of the new parent to insert into. `null` for the root.
   * @param index - The index within the new parent to insert at.
   */
  move(key: Key, toParentKey: Key | null, index: number): void

  /**
   * Updates an item in the tree.
   * @param key - The key of the item to update.
   * @param newValue - The new value for the item.
   */
  update(key: Key, newValue: T | ((oldValue: T) => T)): void

  /**
   * prev returns the previous node in the tree, or null if the node is the first node.
   * @param key - The key of the current node.
   * @returns The previous node in the tree, or null if the node is the first node.
   */
  prev(key: Key): TreeNode<T> | null

  /**
   * next returns the next node in the tree, or null if the node is the last node.
   * @param key - The key of the current node.
   * @returns The next node in the tree, or null if the node is the last node.
   */
  next(key: Key): TreeNode<T> | null
}

/**
 * Manages state for an immutable tree data structure, and provides convenience methods to
 * update the data over time.
 */
export function useTreeData<T extends object>(options?: TreeOptions<T>): TreeData<T> {
  const {
    initialItems = [],
    initialSelectedKeys = [],
    getKey = (item: any) => item.id,
    getChildren = (item: any) => item.children,
  } = options || {}
  const map = useMemo(() => new Map<Key | null, TreeNode<T>>(), [])

  // We only want to compute this on initial render.
  const initialNodes = useMemo(() => buildTree(initialItems, null), [])
  const [items, setItems] = useState(initialNodes)
  const [selectedKeys, setSelectedKeys] = useState(new Set<Key>(initialSelectedKeys))

  function buildTree(initialItems: T[] = [], parentKey: Key | null) {
    return initialItems.map((item) => {
      const node: TreeNode<T> = {
        key: getKey(item),
        parentKey: parentKey,
        value: item,
        children: [],
      }

      node.children = buildTree(getChildren(item), node.key)
      map.set(node.key, node)
      return node
    })
  }

  function updateTree(items: TreeNode<T>[], key: Key, update: (node: TreeNode<T>) => TreeNode<T>) {
    let node = map.get(key)
    if (!node) {
      return items
    }

    // Create a new node. If null, then delete the node, otherwise replace.
    let newNode = update(node)
    if (newNode === null) {
      deleteNode(node)
    } else {
      addNode(newNode)
    }

    // Walk up the tree and update each parent to refer to the new children.
    while (node && node.parentKey) {
      const nextParent: TreeNode<T> = map.get(node.parentKey)!
      const copy: TreeNode<T> = {
        key: nextParent.key,
        parentKey: nextParent.parentKey,
        value: nextParent.value,
        children: [],
      }

      let children = nextParent.children
      if (newNode === null) {
        children = children.filter((c) => c !== node)
      }

      copy.children = children.map((child) => {
        if (child === node) {
          return newNode
        }

        return child
      })

      map.set(copy.key, copy)

      newNode = copy
      node = nextParent
    }

    if (newNode === null) {
      items = items.filter((c) => c !== node)
    }

    return items.map((item) => {
      if (item === node) {
        return newNode
      }

      return item
    })
  }

  function addNode(node: TreeNode<T>) {
    map.set(node.key, node)
    for (const child of node.children) {
      addNode(child)
    }
  }

  function deleteNode(node: TreeNode<T>) {
    map.delete(node.key)
    for (const child of node.children) {
      deleteNode(child)
    }
  }

  return {
    items,
    selectedKeys,
    setSelectedKeys,
    getItem(key: Key) {
      return map.get(key) ?? null
    },
    insert(parentKey: Key | null, index: number, ...values: T[]) {
      setItems((items) => {
        const nodes = buildTree(values, parentKey)

        // If parentKey is null, insert into the root.
        if (parentKey === null) {
          return [...items.slice(0, index), ...nodes, ...items.slice(index)]
        }

        // Otherwise, update the parent node and its ancestors.
        return updateTree(items, parentKey, (parentNode) => ({
          key: parentNode.key,
          parentKey: parentNode.parentKey,
          value: parentNode.value,
          children: [...parentNode.children.slice(0, index), ...nodes, ...parentNode.children.slice(index)],
        }))
      })
    },
    insertBefore(key: Key, ...values: T[]): void {
      const node = map.get(key)
      if (!node) {
        return
      }

      const parentNode = map.get(node.parentKey)
      const nodes = parentNode ? parentNode.children : items
      const index = nodes.indexOf(node)
      const parentKey = parentNode?.key ?? null
      this.insert(parentKey, index, ...values)
    },
    insertAfter(key: Key, ...values: T[]): void {
      const node = map.get(key)
      if (!node) {
        return
      }

      const parentNode = map.get(node.parentKey)
      const nodes = parentNode ? parentNode.children : items
      const index = nodes.indexOf(node)
      const parentKey = parentNode?.key ?? null
      this.insert(parentKey, index + 1, ...values)
    },
    prepend(parentKey: Key | null, ...values: T[]) {
      this.insert(parentKey, 0, ...values)
    },
    append(parentKey: Key | null, ...values: T[]) {
      if (parentKey === null) {
        this.insert(null, items.length, ...values)
      } else {
        const parentNode = map.get(parentKey)
        if (!parentNode) {
          return
        }

        this.insert(parentKey, parentNode.children.length, ...values)
      }
    },
    remove(...keys: Key[]) {
      let newItems = items
      for (const key of keys) {
        newItems = updateTree(newItems, key, (node) => node)
      }

      setItems(newItems)

      const selection = new Set(selectedKeys)
      for (const key of selectedKeys) {
        if (!map.has(key)) {
          selection.delete(key)
        }
      }

      setSelectedKeys(selection)
    },
    removeSelectedItems() {
      this.remove(...selectedKeys)
    },
    move(key: Key, toParentKey: Key | null, index: number) {
      setItems((items) => {
        const node = map.get(key)
        if (!node) {
          return items
        }

        items = updateTree(items, key, (node) => node)

        const movedNode = {
          ...node,
          parentKey: toParentKey,
        }

        // If parentKey is null, insert into the root.
        if (toParentKey === null) {
          return [...items.slice(0, index), movedNode, ...items.slice(index)]
        }

        // Otherwise, update the parent node and its ancestors.
        return updateTree(items, toParentKey, (parentNode) => ({
          key: parentNode.key,
          parentKey: parentNode.parentKey,
          value: parentNode.value,
          children: [...parentNode.children.slice(0, index), movedNode, ...parentNode.children.slice(index)],
        }))
      })
    },
    update(oldKey: Key, newValue: T | ((oldValue: T) => T)) {
      const node = map.get(oldKey)
      if (!node) {
        return
      }

      setItems((items) => {
        const value = newValue instanceof Function ? newValue(node.value) : newValue
        return updateTree(items, oldKey, (oldNode) => {
          const node: TreeNode<T> = {
            key: oldNode.key,
            parentKey: oldNode.parentKey,
            value: value,
            children: [],
          }

          node.children = buildTree(getChildren(value), node.key)
          return node
        })
      })
    },
    next(key: Key): TreeNode<T> | null {
      const node = map.get(key)
      if (!node) {
        return null
      }

      if (node.children.length > 0) {
        return node.children[0]
      }

      const parent = map.get(node.parentKey)
      if (!parent) {
        return null
      }

      const index = parent.children.indexOf(node)
      if (index < parent.children.length - 1) {
        return parent.children[index + 1]
      }

      // if there's no sibling go to the first sibling of an ancestor that have siblings
      let curNode: TreeNode<T> = node
      while (curNode.parentKey !== null) {
        const parent = map.get(curNode.parentKey)
        if (!parent) {
          return null
        }

        const nodeIndex = parent.children.indexOf(curNode)
        if (nodeIndex < parent.children.length - 1) {
          return parent.children[nodeIndex + 1]
        }

        curNode = map.get(curNode.parentKey)!
      }

      return null
    },
    prev(key: Key): TreeNode<T> | null {
      const node = map.get(key)
      if (!node) {
        return null
      }

      const parent = map.get(node.parentKey)
      if (!parent) {
        return null
      }

      const index = parent.children.indexOf(node)
      if (index === -1) {
        return null
      }
      if (index > 0) {
        return parent
      }

      let prevNode: TreeNode<T> | null = parent.children[index - 1]
      while (prevNode != null && prevNode.children.length > 0) {
        prevNode = prevNode.children[prevNode.children.length - 1]
      }
      return prevNode
    },
  }
}
