import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, FormProvider, useForm, useFormContext, useFormState, useWatch } from 'react-hook-form'
import clsx from 'clsx'
import * as yup from 'yup'
import { RadioGroup } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { ArrowDown, ArrowLeft, ArrowRight, ArrowsDownUp, ArrowUp, Check, Plus } from '@phosphor-icons/react'
import {
  ContactStage,
  HubspotActiveLists,
  HubspotActiveListSyncOption,
  HubspotContactsSyncOption,
  HubspotDataMappings,
  HubspotFieldMappings,
  HubspotFields,
  HubspotFieldType,
  HubspotIntegrationDirection,
  HubspotIntegrationStatus,
  HubspotLeadStatuses,
  HubspotLeadStatusMappings,
  HubspotMemberMappings,
  HubspotSettings as HubspotSettingsType,
  HubspotSettingsPermissions,
  HubspotUsers,
  PatchHubspotSettings,
  RiftContactStageMappings,
} from '@/api/core'
import {
  useHubspotActiveLists,
  useHubspotDataMappings,
  useHubspotSettings,
  useHubspotSettingsOnboarding,
  useHubspotSettingsUpdate,
} from '@/api/hubspot'
import { useHubspotOauthDisconnect, useHubspotOauthReconnect, useHubspotOauthRedirect } from '@/api/oauth'
import { ContactStageLabels, ContactStageToText } from '@/api/text'
import { Dropdown, DropdownOption } from '@/components/Dropdown'
import { useDebounceValue, useDisclosureV2 } from '@/hooks'
import { HubspotLogo } from '@/icons/HubspotLogo'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Banner, Button, Card, Checkbox, Dialog, InputRadio, SearchBar, Text } from '@/ui'
import { Step } from '@/ui/headless'

enum hsFormType {
  Sync = 'sync',
  User = 'user',
  ContactField = 'contact-field',
  ContactStageRiftToHs = 'contact-stage-rift-to-hs',
  ContactStageHsToRift = 'contact-stage-hs-to-rift',
  ContactStageFilter = 'contact-stage-filter',
}

export function HubspotSettings() {
  const toast = useToast()
  const { data: settingsData, status: settingsStatus } = useHubspotSettings()
  const { data: dataMappings, status: dataMappingsStatus } = useHubspotDataMappings()
  const { data: activeListsData, status: activeListsStatus } = useHubspotActiveLists()
  const [isMainForm, setIsMainForm] = useState(true)

  const isPending = settingsStatus === 'pending' || activeListsStatus === 'pending' || dataMappingsStatus === 'pending'
  const isError = settingsStatus === 'error' || activeListsStatus === 'error' || dataMappingsStatus === 'error'

  const connect = useHubspotOauthRedirect()
  const disconnect = useHubspotOauthDisconnect()
  const reconnect = useHubspotOauthReconnect()
  const navigate = useNavigate()
  const navigateToSettings = () => navigate('/settings/integrations')

  const onConnect = async () => {
    const { data, status } = await connect.refetch()
    if (status === 'success') {
      window.location.href = data.data.redirect_url
    } else if (status === 'error') {
      toast.createToast({ message: 'Failed to connect Hubspot', error: true })
    }
  }

  const onDisconnect = () => {
    disconnect.mutate(undefined, {
      onSuccess: navigateToSettings,
      onError: () => {
        toast.createToast({ message: 'Failed to disconnect Hubspot', error: true })
      },
    })
  }

  const onReconnect = () => {
    reconnect.mutate(undefined, {
      onSuccess: ({ data }) => {
        window.location.href = data.redirect_url
      },
      onError: () => toast.createToast({ message: 'Failed to disconnect Hubspot', error: true }),
    })
  }

  return (
    <div className="flex flex-col space-y-8">
      {settingsStatus === 'success' &&
        (settingsData.data.onboardingCompleted ||
          settingsData.data.integrationStatus === HubspotIntegrationStatus.NOT_CONNECTED) && (
          <div className={clsx('flex items-center gap-2', !isMainForm && 'invisible')}>
            <button onClick={navigateToSettings}>
              <ArrowLeft className="size-5 fill-medium" />
            </button>
            <span className="text-sm font-medium leading-3 text-dark">Integrations</span>
          </div>
        )}

      {settingsStatus === 'success' &&
        settingsData.data.integrationStatus === HubspotIntegrationStatus.RECONNECT_NEEDED && (
          <Banner severity="info">
            <div className="flex flex-row items-center justify-between">
              <Text variant="text" className="font-medium">
                Reauthorize Hubspot in order to access all features
              </Text>
              <Button variant="accent">Reauthorize</Button>
            </div>
          </Banner>
        )}
      {settingsStatus === 'success' &&
        settingsData.data.integrationStatus === HubspotIntegrationStatus.DISCONNECTED && (
          <Banner severity="error">
            <Text variant="text" className="font-medium">
              Please reconnect to Hubspot
            </Text>
          </Banner>
        )}

      {settingsStatus === 'success' &&
        (settingsData.data.onboardingCompleted ||
          settingsData.data.integrationStatus === HubspotIntegrationStatus.NOT_CONNECTED) && (
          <section>
            <HubspotLogo className="mb-6 ml-2.5 size-10" />
            <div className="mb-5 flex justify-between">
              <hgroup>
                <Text variant="title" className="pb-2">
                  Hubspot
                </Text>
                <Text variant="subtext" className="text-medium">
                  Sync your contact data between Hubspot and rift
                </Text>
              </hgroup>
              {isMainForm && (
                <>
                  {settingsData.data.integrationStatus === HubspotIntegrationStatus.NOT_CONNECTED ? (
                    <Button variant="accent" onClick={onConnect}>
                      Connect
                    </Button>
                  ) : settingsData.data.integrationStatus === HubspotIntegrationStatus.CONNECTED ? (
                    <Button onClick={onDisconnect}>Disconnect</Button>
                  ) : settingsData.data.integrationStatus === HubspotIntegrationStatus.DISCONNECTED ? (
                    <Button variant="accent" onClick={onReconnect}>
                      Reconnect
                    </Button>
                  ) : (
                    settingsData.data.integrationStatus === HubspotIntegrationStatus.RECONNECT_NEEDED && (
                      <Button onClick={onDisconnect}>Disconnect</Button>
                    )
                  )}
                </>
              )}
            </div>
          </section>
        )}

      {isPending ? (
        <div className="skeleton m-auto h-[200px] w-full max-w-2xl" />
      ) : isError ? (
        <div>Failed to load Hubspot details</div>
      ) : (
        <>
          {settingsData.data.integrationStatus !== HubspotIntegrationStatus.NOT_CONNECTED && (
            <HubspotSettingsForm
              settings={settingsData.data}
              permissions={settingsData.permissions}
              dataMappings={dataMappings.data}
              activeLists={activeListsData.data}
              setIsMainForm={setIsMainForm}
            />
          )}
        </>
      )}
    </div>
  )
}

type HubspotSettingsFormProps = {
  settings: HubspotSettingsType
  permissions: HubspotSettingsPermissions
  dataMappings: HubspotDataMappings
  activeLists: HubspotActiveLists
  setIsMainForm: (isMainForm: boolean) => void
}

export function HubspotSettingsForm(props: HubspotSettingsFormProps) {
  const { settings, permissions, dataMappings, activeLists, setIsMainForm } = props

  const toast = useToast()
  const patchSettings = useHubspotSettingsUpdate()
  const patchOnboarding = useHubspotSettingsOnboarding()
  const [formType, setFormType] = useState(hsFormType.Sync)
  const formRef = React.useRef<HTMLFormElement>(null)
  useEffect(() => {
    setIsMainForm(formType === hsFormType.Sync)
  }, [formType])

  const schema: yup.ObjectSchema<PatchHubspotSettings> = yup.object().shape({
    direction: yup.mixed<HubspotIntegrationDirection>().oneOf(Object.values(HubspotIntegrationDirection)).required(),
    contactsSyncOption: yup
      .mixed<HubspotContactsSyncOption>()
      .oneOf(Object.values(HubspotContactsSyncOption))
      .required(),
    activeListSyncOption: yup
      .mixed<HubspotActiveListSyncOption>()
      .oneOf(Object.values(HubspotActiveListSyncOption))
      .required(),
    activeListsSelected: yup.array().of(yup.string().required()).required(),
    riftToHubspotLeadStatusMappings: yup
      .array()
      .of(
        yup.object().shape({
          riftContactStage: yup.mixed<ContactStage>().oneOf(Object.values(ContactStage)).required(),
          hubspotLeadStatusInternalValue: yup.string().required().nullable(),
        }),
      )
      .required(),
    hubspotToRiftContactStageMappings: yup
      .array()
      .of(
        yup.object().shape({
          hubspotLeadStatusInternalValue: yup.string().required(),
          riftContactStage: yup.mixed<ContactStage>().oneOf(Object.values(ContactStage)).required().nullable(),
        }),
      )
      .required(),
    fieldMappings: yup
      .array()
      .of(
        yup.object().shape({
          riftFieldId: yup.string().required(),
          hubspotFieldId: yup.string().required().nullable(),
        }),
      )
      .test('unique', 'Cannot use the same hubspot field twice', (value) => {
        const hubspotFields = value?.filter((v) => v.hubspotFieldId !== null).map((v) => v.hubspotFieldId) || []
        return new Set(hubspotFields).size === hubspotFields.length
      })
      .required(),
    memberMappings: yup
      .array()
      .of(
        yup.object().shape({
          riftMemberId: yup.string().required(),
          hubspotUserId: yup.string().required().nullable(),
        }),
      )
      .required(),
    contactStageFilters: yup
      .array()
      .of(
        yup.object().shape({
          riftContactStage: yup.mixed<ContactStage>().oneOf(Object.values(ContactStage)).required(),
          sync: yup.boolean().required(),
        }),
      )
      .required(),

    hsLeadStatusFilters: yup
      .array()
      .of(
        yup.object().shape({
          hubspotLeadStatusInternalValue: yup.string().required(),
          sync: yup.boolean().required(),
        }),
      )
      .required(),
  })

  const methods = useForm<PatchHubspotSettings>({
    mode: 'all',
    disabled: permissions.edit.deny,
    defaultValues: {
      direction: settings.direction,
      contactsSyncOption: settings.contactsSyncOption,
      activeListSyncOption: settings.activeListSyncOption,
      activeListsSelected: settings.activeListsSelected.map((v) => v.id),
      memberMappings: settings.memberMappings.map((m) => ({
        riftMemberId: m.riftMember.id,
        hubspotUserId: m.hubspotUser?.id ?? null,
      })),
      fieldMappings: settings.fieldMappings.map((m) => ({
        riftFieldId: m.rift.id,
        hubspotFieldId: m.hubspot?.id ?? null,
      })),
      riftToHubspotLeadStatusMappings: settings.riftToHubspotLeadStatusMappings.map((m) => ({
        riftContactStage: m.riftContactStage,
        hubspotLeadStatusInternalValue: m.hubspotLeadStatus?.internalValue ?? null,
      })),
      hubspotToRiftContactStageMappings: settings.hubspotToRiftContactStageMappings.map((m) => ({
        hubspotLeadStatusInternalValue: m.hubspotLeadStatus.internalValue,
        riftContactStage: m.riftContactStage ?? null,
      })),
      contactStageFilters: settings.contactStageFilters,
      hsLeadStatusFilters: settings.hsLeadStatusFilters.map((m) => ({
        hubspotLeadStatusInternalValue: m.hubspotLeadStatus.internalValue,
        sync: m.sync,
      })),
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = (data: PatchHubspotSettings) => {
    if (!settings.onboardingCompleted) {
      return patchOnboarding.mutateAsync(data, {
        onSuccess: () => toast.createToast({ message: 'Hubspot connected' }),
        onError: (err) =>
          toast.createToast({ message: (err as any)?.body?.message ?? "Can't connect Hubspot", error: true }),
      })
    }

    return patchSettings.mutateAsync(data, {
      onSuccess: () => {
        toast.createToast({ message: 'Settings saved' })
        methods.reset(data)
        setFormType(hsFormType.Sync)
      },
      onError: (err) =>
        toast.createToast({ message: (err as any)?.body?.message ?? 'Settings saving error', error: true }),
    })
  }

  const onCancel = () => {
    methods.reset()
    setFormType(hsFormType.Sync)
  }

  const { isSubmitting } = methods.formState

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className={clsx(isSubmitting && 'pointer-events-none opacity-30')}
        ref={formRef}
      >
        {settings.onboardingCompleted ? (
          <SettingsForm
            formType={formType}
            setFormType={setFormType}
            onCancel={onCancel}
            settings={settings}
            dataMappings={dataMappings}
            activeLists={activeLists}
            formRef={formRef}
          />
        ) : (
          <SettingsOnboarding settings={settings} dataMappings={dataMappings} activeLists={activeLists} />
        )}
      </form>
    </FormProvider>
  )
}

type SettingsOnboardingProps = {
  settings: HubspotSettingsType
  dataMappings: HubspotDataMappings
  activeLists: HubspotActiveLists
}

function SettingsOnboarding(props: SettingsOnboardingProps) {
  const { settings, dataMappings, activeLists } = props
  const { isValid, errors } = useFormState<PatchHubspotSettings>()

  const direction = useWatch<PatchHubspotSettings>({ name: 'direction' })

  const showActiveList =
    direction === HubspotIntegrationDirection.BI_DIRECTIONAL ||
    direction === HubspotIntegrationDirection.HUBSPOT_TO_RIFT

  const showLeadStatusMappingStep =
    direction === HubspotIntegrationDirection.BI_DIRECTIONAL ||
    direction === HubspotIntegrationDirection.HUBSPOT_TO_RIFT

  const showContactStageMappingStep =
    direction === HubspotIntegrationDirection.BI_DIRECTIONAL ||
    direction === HubspotIntegrationDirection.RIFT_TO_HUBSPOT

  return (
    <Step.Group as="div" className="flex flex-col" renderCurrentStep>
      <Step.List>
        <Step>
          <hgroup className="mb-8">
            <Text variant="title" className="text-dark">
              Syncing between Hubspot and rift
            </Text>
            <Text className="text-dusk">Select how you’d like to sync data between rift and Hubspot</Text>
          </hgroup>
        </Step>

        {showActiveList && (
          <Step>
            <hgroup className="mb-8">
              <Text variant="title" className="text-dark">
                Active lists
              </Text>
              <Text className="text-dusk">Select how you’d like to sync your active lists from Hubspot</Text>
            </hgroup>
          </Step>
        )}

        <Step>
          <hgroup className="mb-8">
            <Text variant="title" className="text-dark">
              Map users from rift to Hubspot
            </Text>
            <Text className="text-dusk">Select how you’d like to sync your users from Hubspot</Text>
          </hgroup>
        </Step>

        <Step>
          <hgroup className="mb-8">
            <Text variant="title" className="text-dark">
              Map contact fields from rift to Hubspot
            </Text>
            <Text className="text-dusk">Select how you’d like to sync your contact fields from Hubspot</Text>
          </hgroup>
        </Step>

        {showContactStageMappingStep && (
          <Step>
            <hgroup className="mb-8">
              <Text variant="title" className="text-dark">
                Map contact stages from rift to Hubspot
              </Text>
              <Text className="text-dusk">Select how you’d like to sync your contact stages to Hubspot</Text>
            </hgroup>
          </Step>
        )}

        {showLeadStatusMappingStep && (
          <Step>
            <hgroup className="mb-8">
              <Text variant="title" className="text-dark">
                Map lead stages from Hubspot to rift
              </Text>
              <Text className="text-dusk">Select how you’d like to sync your lead statues from Hubspot</Text>
            </hgroup>
          </Step>
        )}
        <Step>
          <hgroup className="mb-8">
            <Text variant="title" className="text-dark">
              Contact filters
            </Text>
            <Text className="text-dusk">
              Choose which contacts you’d like to sync from rift to Hubspot. <br />
              Contact that match not-synced stages will not sync.
            </Text>
          </hgroup>
        </Step>
      </Step.List>

      <Step.Panels>
        <Step.Panel>
          <div className="flex flex-col gap-8">
            <SyncDirectionForm />
            <Step.Next as={Button} variant="accent" className="ml-auto w-fit">
              Next
            </Step.Next>
          </div>
        </Step.Panel>

        {showActiveList && (
          <Step.Panel>
            <div className="flex flex-col gap-8">
              <ActiveListsForm activeLists={activeLists} />
              <div className="mt-6 flex justify-end gap-4">
                <Step.Back as={Button}>Previous</Step.Back>
                <Step.Next as={Button} variant="accent" autoFocus>
                  Next
                </Step.Next>
              </div>
            </div>
          </Step.Panel>
        )}

        <Step.Panel>
          <UsersMappingForm
            memberMappings={settings.memberMappings}
            users={dataMappings.users}
            hint={dataMappings.hintMappingUsers}
          />
          <div className="mt-6 flex justify-end gap-4">
            <Step.Back as={Button}>Previous</Step.Back>
            <Step.Next as={Button} variant="accent">
              Next
            </Step.Next>
          </div>
        </Step.Panel>

        <Step.Panel>
          <ContactFieldsMappingForm
            fieldMappings={settings.fieldMappings}
            fields={dataMappings.fields}
            hint={dataMappings.hintMappingFields}
          />
          <Text className="float-end text-alert">{errors.fieldMappings && <>{errors.fieldMappings.message}</>}</Text>
          <div className="mt-6 flex justify-end gap-4">
            <Step.Back as={Button}>Previous</Step.Back>
            <Step.Next as={Button} variant="accent" disabled={!!errors.fieldMappings}>
              Next
            </Step.Next>
          </div>
        </Step.Panel>

        {showContactStageMappingStep && (
          <Step.Panel>
            <ContactStagesMappingForm
              riftToHubspotLeadStatusMappings={settings.riftToHubspotLeadStatusMappings}
              leadStatuses={dataMappings.leadStatuses}
              hint={dataMappings.hintMappingContactStages}
            />
            <div className="mt-6 flex justify-end gap-4">
              <Step.Back as={Button}>Previous</Step.Back>
              <Step.Next as={Button} variant="accent">
                Next
              </Step.Next>
            </div>
          </Step.Panel>
        )}

        {showLeadStatusMappingStep && (
          <Step.Panel>
            <LeadStatusesMappingForm
              hubspotToRiftContactStageMappings={settings.hubspotToRiftContactStageMappings}
              hint={dataMappings.hintMappingLeadStatuses}
            />
            <div className="flex justify-end gap-4">
              <Step.Back as={Button}>Previous</Step.Back>
              <Step.Next as={Button} variant="accent">
                Next
              </Step.Next>
            </div>
          </Step.Panel>
        )}
        <Step.Panel>
          <ContactStageFilterForm leadStatuses={dataMappings.leadStatuses} />
          <div className="mt-6 flex justify-end gap-4">
            <div className="flex justify-end gap-4">
              {errors && <>{JSON.stringify(errors)}</>}
              <Step.Back as={Button}>Previous</Step.Back>
              <Step.Next as={Button} type="submit" variant="accent" disabled={!isValid}>
                Save & finish
              </Step.Next>
            </div>
          </div>
        </Step.Panel>
      </Step.Panels>
    </Step.Group>
  )
}

type SettingsFormProps = {
  settings: HubspotSettingsType
  dataMappings: HubspotDataMappings
  activeLists: HubspotActiveLists
  onCancel: () => void
  formType: hsFormType
  setFormType: (type: hsFormType) => void
  formRef: React.RefObject<HTMLFormElement>
}

function SettingsForm(props: SettingsFormProps) {
  const { settings, dataMappings, activeLists, onCancel, formType, setFormType, formRef } = props
  const {
    watch,
    formState: { disabled, isDirty, isValid, errors },
  } = useFormContext<PatchHubspotSettings>()
  const [openSaveConfirmation, onOpenSaveConfirmation, onCloseSaveConfirmation] = useDisclosureV2(false)
  const onSaveDialogSubmit = () => {
    formRef.current!.requestSubmit()
    onCloseSaveConfirmation()
  }
  const onSaveDialogCancel = () => {
    onCancel()
    onCloseSaveConfirmation()
  }

  const { direction, contactsSyncOption, activeListSyncOption, activeListsSelected } = watch()
  const popupSaveWarning =
    (settings.activeListSyncOption === HubspotActiveListSyncOption.SYNC_ALL &&
      (activeListSyncOption === HubspotActiveListSyncOption.SYNC_SELECTED ||
        activeListSyncOption === HubspotActiveListSyncOption.SYNC_NONE)) ||
    (settings.contactsSyncOption === HubspotContactsSyncOption.SYNC_ALL &&
      contactsSyncOption === HubspotContactsSyncOption.SYNC_ACTIVE_LIST) ||
    (activeListSyncOption === HubspotActiveListSyncOption.SYNC_SELECTED &&
      settings.activeListsSelected.some((l) => !activeListsSelected.includes(l.id)))

  return (
    <div className="flex flex-col gap-8">
      {formType === hsFormType.Sync ? (
        <>
          <SyncDirectionForm />
          {(direction === HubspotIntegrationDirection.BI_DIRECTIONAL ||
            direction === HubspotIntegrationDirection.HUBSPOT_TO_RIFT) && <ActiveListsForm activeLists={activeLists} />}

          <fieldset className={clsx(disabled && 'pointer-events-none opacity-30')}>
            <div className="flex flex-col gap-4">
              <div className="flex justify-between">
                <p className="text-sm text-dark">User mapping</p>
                {!isDirty && (
                  <button type="button" onClick={() => setFormType(hsFormType.User)}>
                    <ArrowRight className="size-5 fill-medium" />
                  </button>
                )}
              </div>

              <div className="flex justify-between">
                <p className="text-sm text-dark">Field mapping</p>
                {!isDirty && (
                  <button type="button" onClick={() => setFormType(hsFormType.ContactField)}>
                    <ArrowRight className="size-5 fill-medium" />
                  </button>
                )}
              </div>

              <div className="flex justify-between">
                <p className="text-sm text-dark">Stage mapping</p>
                {!isDirty && (
                  <button
                    type="button"
                    onClick={() => {
                      if (
                        direction === HubspotIntegrationDirection.RIFT_TO_HUBSPOT ||
                        direction === HubspotIntegrationDirection.BI_DIRECTIONAL
                      ) {
                        setFormType(hsFormType.ContactStageRiftToHs)
                      } else {
                        setFormType(hsFormType.ContactStageHsToRift)
                      }
                    }}
                  >
                    <ArrowRight className="size-5 fill-medium" />
                  </button>
                )}
              </div>

              <div className="flex justify-between">
                <p className="text-sm text-dark">Contact filters</p>
                {!isDirty && (
                  <button type="button" onClick={() => setFormType(hsFormType.ContactStageFilter)}>
                    <ArrowRight className="size-5 fill-medium" />
                  </button>
                )}
              </div>
            </div>
          </fieldset>
        </>
      ) : formType === hsFormType.ContactField ? (
        <ContactFieldsMappingForm
          fieldMappings={settings.fieldMappings}
          fields={dataMappings.fields}
          hint={dataMappings.hintMappingFields}
        />
      ) : formType === hsFormType.User ? (
        <UsersMappingForm
          memberMappings={settings.memberMappings}
          users={dataMappings.users}
          hint={dataMappings.hintMappingUsers}
        />
      ) : formType === hsFormType.ContactStageRiftToHs ? (
        <ContactStagesMappingForm
          riftToHubspotLeadStatusMappings={settings.riftToHubspotLeadStatusMappings}
          leadStatuses={dataMappings.leadStatuses}
          hint={dataMappings.hintMappingContactStages}
        />
      ) : formType === hsFormType.ContactStageHsToRift ? (
        <LeadStatusesMappingForm
          hubspotToRiftContactStageMappings={settings.hubspotToRiftContactStageMappings}
          hint={dataMappings.hintMappingLeadStatuses}
        />
      ) : (
        formType === hsFormType.ContactStageFilter && (
          <ContactStageFilterForm leadStatuses={dataMappings.leadStatuses} />
        )
      )}

      <div className="flex flex-col justify-end">
        {errors && (
          <div>
            {errors.direction?.message && <Text className="float-end text-alert">{errors.direction.message}</Text>}
            {errors.contactsSyncOption?.message && (
              <Text className="float-end text-alert">{errors.contactsSyncOption.message}</Text>
            )}
            {errors.activeListSyncOption?.message && (
              <Text className="float-end text-alert">{errors.activeListSyncOption.message}</Text>
            )}
            {errors.activeListsSelected?.message && (
              <Text className="float-end text-alert">{errors.activeListsSelected.message}</Text>
            )}
            {errors.riftToHubspotLeadStatusMappings?.message && (
              <Text className="float-end text-alert">{errors.riftToHubspotLeadStatusMappings.message}</Text>
            )}
            {errors.hubspotToRiftContactStageMappings?.message && (
              <Text className="float-end text-alert">{errors.hubspotToRiftContactStageMappings.message}</Text>
            )}
            {errors.fieldMappings?.message && (
              <Text className="float-end text-alert">{errors.fieldMappings.message}</Text>
            )}
            {errors.memberMappings?.message && (
              <Text className="float-end text-alert">{errors.memberMappings.message}</Text>
            )}
            {errors.contactStageFilters?.message && (
              <Text className="float-end text-alert">{errors.contactStageFilters.message}</Text>
            )}
            {errors.hsLeadStatusFilters?.message && (
              <Text className="float-end text-alert">{errors.hsLeadStatusFilters.message}</Text>
            )}
          </div>
        )}

        <div className="flex justify-end gap-2">
          {isDirty && formType === hsFormType.Sync && (
            <>
              <Button type="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                type={popupSaveWarning ? 'button' : 'submit'}
                variant="accent"
                disabled={!isValid}
                onClick={popupSaveWarning ? onOpenSaveConfirmation : undefined}
              >
                Save
              </Button>
            </>
          )}
          {(formType === hsFormType.ContactField ||
            formType === hsFormType.User ||
            formType === hsFormType.ContactStageFilter) && (
            <>
              <Button type="button" onClick={onCancel}>
                Cancel
              </Button>
              {isDirty && (
                <Button type="submit" variant="accent" disabled={!isValid}>
                  Save
                </Button>
              )}
            </>
          )}

          {formType === hsFormType.ContactStageRiftToHs && (
            <>
              <Button type="button" onClick={onCancel}>
                Cancel
              </Button>
              {direction === HubspotIntegrationDirection.BI_DIRECTIONAL ? (
                <Button type="button" onClick={() => setFormType(hsFormType.ContactStageHsToRift)} variant="accent">
                  Next
                </Button>
              ) : (
                isDirty &&
                direction === HubspotIntegrationDirection.RIFT_TO_HUBSPOT && (
                  <Button type="submit" variant="accent" disabled={!isValid}>
                    Save
                  </Button>
                )
              )}
            </>
          )}

          {formType === hsFormType.ContactStageHsToRift && (
            <>
              {direction === HubspotIntegrationDirection.BI_DIRECTIONAL ? (
                <Button type="button" onClick={() => setFormType(hsFormType.ContactStageRiftToHs)}>
                  Previous
                </Button>
              ) : (
                direction === HubspotIntegrationDirection.HUBSPOT_TO_RIFT && (
                  <Button type="button" onClick={onCancel}>
                    Cancel
                  </Button>
                )
              )}
              {isDirty && (
                <Button type="submit" variant="accent" disabled={!isValid}>
                  Save
                </Button>
              )}
            </>
          )}

          <Dialog open={openSaveConfirmation} onClose={onCloseSaveConfirmation}>
            <Dialog.Panel className="max-w-80 p-4">
              <h1 className="mb-6 font-medium leading-5 tracking-wider text-dark">Only sync active list contacts?</h1>
              <p className="mb-4 text-sm leading-5 tracking-wide text-dusk">
                Any previously synced contacts that aren’t in an active list will not be available on rift.
                <br />
                <br />
                They will be removed from any sequences they may be enrolled in.
                <br />
                <br />
                They will continue to be represented in historical analytics and events.
              </p>
              <div className="flex justify-between gap-2">
                <Button type="button" onClick={onSaveDialogCancel} className="flex-1">
                  Cancel
                </Button>
                <Button type="button" variant="accent" onClick={onSaveDialogSubmit} className="flex-1">
                  Yes, save change
                </Button>
              </div>
            </Dialog.Panel>
          </Dialog>
        </div>
      </div>
    </div>
  )
}

function SyncDirectionForm() {
  const {
    control,
    formState: { disabled },
  } = useFormContext<PatchHubspotSettings>()
  return (
    <fieldset className={clsx(disabled && 'pointer-events-none opacity-30')}>
      <legend className="mb-2 text-sm font-medium leading-3 text-dark">Sync direction</legend>
      <div className="flex flex-col">
        <Controller
          control={control}
          name="direction"
          render={({ field }) => (
            <RadioGroup className="flex justify-evenly gap-2" {...field}>
              <RadioGroup.Option value={HubspotIntegrationDirection.BI_DIRECTIONAL}>
                {({ checked }) => (
                  <Card className={clsx('px-4', checked && 'outline outline-2 outline-accent')}>
                    <p className="text-sm">
                      Bi-directional
                      <ArrowsDownUp className="ml-4 inline size-5 fill-medium" />
                    </p>
                  </Card>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value={HubspotIntegrationDirection.HUBSPOT_TO_RIFT}>
                {({ checked }) => (
                  <Card className={clsx('px-4', checked && 'outline outline-2 outline-accent')}>
                    <p className="text-sm">
                      Hubspot to rift
                      <ArrowDown className="ml-4 inline size-5 fill-medium" />
                    </p>
                  </Card>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value={HubspotIntegrationDirection.RIFT_TO_HUBSPOT}>
                {({ checked }) => (
                  <Card className={clsx('px-4', checked && 'outline outline-2 outline-accent')}>
                    <p className="text-sm">
                      rift to Hubspot
                      <ArrowUp className="ml-4 inline size-5 fill-medium" />
                    </p>
                  </Card>
                )}
              </RadioGroup.Option>
            </RadioGroup>
          )}
        />
      </div>
    </fieldset>
  )
}

type ActiveListsFormProps = {
  activeLists: HubspotActiveLists
}

function ActiveListsForm(props: ActiveListsFormProps) {
  const { activeLists } = props
  const {
    register,
    formState: { disabled },
    control,
    watch,
  } = useFormContext<PatchHubspotSettings>()

  const activeListSyncOption = watch('activeListSyncOption')
  return (
    <fieldset className={clsx(disabled && 'pointer-events-none opacity-30')}>
      <legend className="mb-2 text-sm font-medium leading-5 text-dark">Active lists</legend>
      <div className="flex flex-col gap-4">
        <p className="flex items-center gap-4 text-sm leading-5 text-dark">
          <InputRadio
            id={HubspotActiveListSyncOption.SYNC_ALL}
            value={HubspotActiveListSyncOption.SYNC_ALL}
            {...register('activeListSyncOption')}
          />
          <label htmlFor={HubspotActiveListSyncOption.SYNC_ALL}>Sync all current and future active lists</label>
        </p>

        <p className="flex items-center gap-4 text-sm leading-5 text-dark">
          <InputRadio
            id={HubspotActiveListSyncOption.SYNC_SELECTED}
            value={HubspotActiveListSyncOption.SYNC_SELECTED}
            {...register('activeListSyncOption')}
          />
          <label htmlFor={HubspotActiveListSyncOption.SYNC_SELECTED}>Choose which lists to sync</label>
        </p>
        {activeListSyncOption === HubspotActiveListSyncOption.SYNC_SELECTED && (
          <ActiveListsSelectForm activeLists={activeLists} />
        )}
        <p className="flex items-center gap-4 text-sm leading-5 text-dark">
          <InputRadio
            id={HubspotActiveListSyncOption.SYNC_NONE}
            value={HubspotActiveListSyncOption.SYNC_NONE}
            {...register('activeListSyncOption')}
          />
          <label htmlFor={HubspotActiveListSyncOption.SYNC_NONE}>Don’t sync active lists</label>
        </p>

        {activeListSyncOption !== HubspotActiveListSyncOption.SYNC_NONE && (
          <label htmlFor="contactsSyncOption">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <div className="flex gap-4">
              <Controller
                control={control}
                name="contactsSyncOption"
                render={({ field }) => (
                  <Checkbox
                    id="contactsSyncOption"
                    ref={field.ref}
                    checked={field.value === HubspotContactsSyncOption.SYNC_ACTIVE_LIST}
                    disabled={field.disabled}
                    onBlur={field.onBlur}
                    onChange={(e) =>
                      field.onChange(
                        e.target.checked
                          ? HubspotContactsSyncOption.SYNC_ACTIVE_LIST
                          : HubspotContactsSyncOption.SYNC_ALL,
                      )
                    }
                  />
                )}
              />
              <div>
                <p className="text-sm leading-5 text-dark">Only sync active list contacts</p>
                <p className="text-xs leading-5 tracking-wide text-medium">
                  Choosing this option will only sync active list contacts and their associated data and <br />
                  not contacts that aren’t in active lists
                </p>
              </div>
            </div>
          </label>
        )}
      </div>
    </fieldset>
  )
}

type ActiveListsSelectFormProps = {
  activeLists: HubspotActiveLists
}

function ActiveListsSelectForm(props: ActiveListsSelectFormProps) {
  const { activeLists } = props
  const { control } = useFormContext<PatchHubspotSettings>()

  const [search, debouncedSearch, setSearch] = useDebounceValue('', { delay: 100 })

  return (
    <Card className="ml-8 flex max-h-96 flex-col gap-4 overflow-auto">
      {activeLists.length > 10 && <SearchBar value={search} onChange={setSearch} />}
      <Controller
        control={control}
        name="activeListsSelected"
        render={({ field }) => {
          const onChange = (id: string, checked: boolean) => {
            const selected = checked ? [...field.value, id] : field.value.filter((v) => v !== id)
            field.onChange([...new Set(selected)])
          }
          return (
            <>
              {activeLists
                .filter(
                  (l) =>
                    debouncedSearch === '' || l.name.toLocaleLowerCase().includes(debouncedSearch.toLocaleLowerCase()),
                )
                .map((list) => (
                  <p className="flex items-center gap-4 text-sm text-dark" key={list.id}>
                    <Checkbox
                      id={list.id}
                      disabled={field.disabled}
                      onBlur={field.onBlur}
                      checked={field.value.includes(list.id)}
                      onChange={(e) => onChange(list.id, e.target.checked)}
                    />
                    <label htmlFor={list.id}>{list.name}</label>
                  </p>
                ))}
            </>
          )
        }}
      />
    </Card>
  )
}

type UsersMappingFormProps = {
  memberMappings: HubspotMemberMappings
  users: HubspotUsers
  hint: HubspotMemberMappings
}

function UsersMappingForm(props: UsersMappingFormProps) {
  const { memberMappings, users, hint } = props
  const {
    control,
    formState: { disabled },
  } = useFormContext<PatchHubspotSettings>()

  const membersHint = hint.reduce(
    (acc, h) => {
      acc[h.riftMember.id] = h.hubspotUser?.id ?? null
      return acc
    },
    {} as Record<string, string | null>,
  )

  const usersDropdownOptions: DropdownOption[] = users.map((user) => ({
    id: user.id,
    label: user.email,
  }))

  return (
    <section className={clsx(disabled && 'pointer-events-none opacity-30')}>
      <div className="grid grid-cols-[minmax(0,_1fr)_40px_minmax(0,_1fr)] gap-y-5 align-middle">
        <Text variant="subtitle">rift</Text>
        <div />
        <Text variant="subtitle">Hubspot</Text>

        <Controller
          control={control}
          name="memberMappings"
          render={({ field }) => {
            const memberSelectedById = field.value.reduce(
              (acc, mapping) => {
                acc[mapping.riftMemberId] = mapping.hubspotUserId
                return acc
              },
              {} as Record<string, string | null>,
            )

            const onToggle = (memberId: string) => {
              const newValue = memberSelectedById[memberId] ? null : membersHint[memberId] ?? users[0].id
              field.onChange(
                field.value.map((mapping) =>
                  mapping.riftMemberId === memberId ? { riftMemberId: memberId, hubspotUserId: newValue } : mapping,
                ),
              )
            }

            const onSelect = (memberId: string, userId: string) => {
              field.onChange(
                field.value.map((mapping) =>
                  mapping.riftMemberId === memberId ? { riftMemberId: memberId, hubspotUserId: userId } : mapping,
                ),
              )
            }

            return (
              <>
                {memberMappings.map((mapping) => {
                  const member = mapping.riftMember
                  const selected = memberSelectedById[member.id]
                  return (
                    <div
                      key={member.id}
                      className="col-span-3 grid h-12 grid-cols-[minmax(206px,_1fr)_40px_minmax(206px,_1fr)] gap-x-3 align-middle"
                    >
                      <Button
                        variant="leading-icon"
                        type="button"
                        icon={selected ? Check : Plus}
                        className={clsx('w-full justify-start self-center truncate', selected && 'border-accent')}
                        onClick={() => onToggle(member.id)}
                        disabled={disabled}
                      >
                        <span title={member.email}>{member.email}</span>
                      </Button>

                      <ArrowRight
                        className={clsx('size-5 self-center justify-self-center fill-dusk', !selected && 'invisible')}
                      />
                      {selected ? (
                        <Dropdown
                          options={usersDropdownOptions}
                          selected={usersDropdownOptions.find((option) => option.id === selected) || null}
                          handleSelection={(selection) => onSelect(member.id, selection.id as string)}
                        />
                      ) : (
                        <Text variant="text" className="self-center pl-3 text-medium">
                          Don't sync
                        </Text>
                      )}
                    </div>
                  )
                })}
              </>
            )
          }}
        />
      </div>
    </section>
  )
}

type ContactFieldsMappingFormProps = {
  fieldMappings: HubspotFieldMappings
  fields: HubspotFields
  hint: HubspotFieldMappings
}

function ContactFieldsMappingForm(props: ContactFieldsMappingFormProps) {
  const { fieldMappings, fields, hint } = props
  const {
    control,
    formState: { disabled },
  } = useFormContext<PatchHubspotSettings>()

  const firstStringField = fields.find((f) => f.type === HubspotFieldType.STRING) || fields[0]
  const firstBooleanField = fields.find((f) => f.type === HubspotFieldType.BOOLEAN) || fields[0]

  const fieldHint = hint.reduce(
    (acc, h) => {
      acc[h.rift.id] =
        h.hubspot?.id ?? (h.rift.type === HubspotFieldType.BOOLEAN ? firstBooleanField.id : firstStringField.id)
      return acc
    },
    {} as Record<string, string>,
  )

  const fieldsBooleanDropdownOptions: DropdownOption[] = fields
    .filter((f) => f.type === HubspotFieldType.BOOLEAN)
    .map((field) => ({
      id: field.id,
      label: field.label,
    }))

  const filedsStringDropdownOptions: DropdownOption[] = fields
    .filter((f) => f.type === HubspotFieldType.STRING)
    .map((field) => ({
      id: field.id,
      label: field.label,
    }))

  return (
    <section className={clsx(disabled && 'pointer-events-none opacity-30')}>
      <div className="grid grid-cols-[minmax(0,_1fr)_40px_minmax(0,_1fr)] gap-y-5 align-middle">
        <Text variant="subtitle">rift</Text>
        <div />
        <Text variant="subtitle">Hubspot</Text>
      </div>

      <Controller
        control={control}
        name="fieldMappings"
        render={({ field }) => {
          const fieldSelectedById = field.value.reduce(
            (acc, mapping) => {
              acc[mapping.riftFieldId] = mapping.hubspotFieldId
              return acc
            },
            {} as Record<string, string | null>,
          )

          const onToggle = (fieldId: string) => {
            const newValue = fieldSelectedById[fieldId] ? null : fieldHint[fieldId] ?? fields[0].id
            field.onChange(
              field.value.map((mapping) =>
                mapping.riftFieldId === fieldId ? { riftFieldId: fieldId, hubspotFieldId: newValue } : mapping,
              ),
            )
          }

          const onSelect = (fieldId: string, hubspotFieldId: string) => {
            field.onChange(
              field.value.map((mapping) =>
                mapping.riftFieldId === fieldId ? { riftFieldId: fieldId, hubspotFieldId } : mapping,
              ),
            )
          }

          return (
            <>
              {fieldMappings.map((mapping) => {
                const rfield = mapping.rift
                const selected = fieldSelectedById[rfield.id] || rfield.isRequired
                return (
                  <div
                    key={rfield.id}
                    className="col-span-3 grid h-12 grid-cols-[minmax(206px,_1fr)_40px_minmax(206px,_1fr)] gap-x-3 align-middle"
                  >
                    <Button
                      variant="leading-icon"
                      type="button"
                      icon={selected ? Check : Plus}
                      className={clsx(
                        'w-full justify-start self-center truncate',
                        selected && 'border-accent',
                        rfield.isRequired && 'pointer-events-none',
                      )}
                      onClick={() => {
                        if (!rfield.isRequired) {
                          onToggle(rfield.id)
                        }
                      }}
                      disabled={disabled}
                    >
                      <span title={rfield.label}>{rfield.label}</span>
                    </Button>

                    <ArrowRight
                      className={clsx('size-5 self-center justify-self-center fill-dusk', !selected && 'invisible')}
                    />
                    {selected ? (
                      <Dropdown
                        options={
                          rfield.type === HubspotFieldType.BOOLEAN
                            ? fieldsBooleanDropdownOptions
                            : filedsStringDropdownOptions
                        }
                        selected={
                          rfield.type === HubspotFieldType.BOOLEAN
                            ? fieldsBooleanDropdownOptions.find((option) => option.id === selected) || null
                            : filedsStringDropdownOptions.find((option) => option.id === selected) || null
                        }
                        handleSelection={(selection) => onSelect(rfield.id, selection.id as string)}
                      />
                    ) : (
                      <Text variant="text" className="self-center pl-3 text-medium">
                        Don't sync
                      </Text>
                    )}
                  </div>
                )
              })}
            </>
          )
        }}
      />
    </section>
  )
}

type ContactStagesMappingFormProps = {
  riftToHubspotLeadStatusMappings: RiftContactStageMappings
  leadStatuses: HubspotLeadStatuses
  hint: RiftContactStageMappings
}

function ContactStagesMappingForm(props: ContactStagesMappingFormProps) {
  const { riftToHubspotLeadStatusMappings, leadStatuses, hint } = props
  const {
    control,
    formState: { disabled },
  } = useFormContext<PatchHubspotSettings>()

  const leadStatusesHintByContactStage = hint.reduce(
    (acc, h) => {
      acc[h.riftContactStage] = h.hubspotLeadStatus?.internalValue ?? null
      return acc
    },
    {} as Record<ContactStage, string | null>,
  )

  const leadStatusesDropdownOptions: DropdownOption[] = leadStatuses.map((status) => ({
    id: status.internalValue,
    label: status.label,
  }))

  return (
    <section className={clsx(disabled && 'pointer-events-none opacity-30')}>
      <div className="grid grid-cols-[minmax(0,_1fr)_40px_minmax(0,_1fr)] gap-y-5 align-middle">
        <Text variant="subtitle">rift</Text>
        <div />
        <Text variant="subtitle">Hubspot</Text>
      </div>

      <Controller
        control={control}
        name="riftToHubspotLeadStatusMappings"
        render={({ field }) => {
          const statusSelectedByType = field.value.reduce(
            (acc, mapping) => {
              acc[mapping.riftContactStage] = mapping.hubspotLeadStatusInternalValue
              return acc
            },
            {} as Record<ContactStage, string | null>,
          )

          const onToggle = (status: ContactStage) => {
            const newValue = statusSelectedByType[status]
              ? null
              : leadStatusesHintByContactStage[status] ?? leadStatuses[0].internalValue
            field.onChange(
              field.value.map((mapping) =>
                mapping.riftContactStage === status
                  ? { riftContactStage: status, hubspotLeadStatusInternalValue: newValue }
                  : mapping,
              ),
            )
          }

          const onSelect = (status: ContactStage, hubspotLeadStatusInternalValue: string) => {
            field.onChange(
              field.value.map((mapping) =>
                mapping.riftContactStage === status
                  ? { riftContactStage: status, hubspotLeadStatusInternalValue }
                  : mapping,
              ),
            )
          }

          return (
            <>
              {riftToHubspotLeadStatusMappings.map((mapping) => {
                const status = mapping.riftContactStage
                const selected = statusSelectedByType[status]
                return (
                  <div
                    key={status}
                    className="col-span-3 grid h-12 grid-cols-[minmax(206px,_1fr)_40px_minmax(206px,_1fr)] gap-x-3 align-middle"
                  >
                    <Button
                      variant="leading-icon"
                      type="button"
                      icon={selected ? Check : Plus}
                      className={clsx('w-full justify-start self-center truncate', selected && 'border-accent')}
                      onClick={() => onToggle(status)}
                      disabled={disabled}
                    >
                      {ContactStageToText[status]}
                    </Button>

                    <ArrowRight
                      className={clsx('size-5 self-center justify-self-center fill-dusk', !selected && 'invisible')}
                    />
                    {selected ? (
                      <Dropdown
                        options={leadStatusesDropdownOptions}
                        selected={leadStatusesDropdownOptions.find((option) => option.id === selected) || null}
                        handleSelection={(selection) => onSelect(status, selection.id as string)}
                      />
                    ) : (
                      <Text variant="text" className="self-center pl-3 text-medium">
                        Don't sync
                      </Text>
                    )}
                  </div>
                )
              })}
            </>
          )
        }}
      />
    </section>
  )
}

type LeadStatusesMappingFormProps = {
  hubspotToRiftContactStageMappings: HubspotLeadStatusMappings
  hint: HubspotLeadStatusMappings
}

function LeadStatusesMappingForm(props: LeadStatusesMappingFormProps) {
  const { hubspotToRiftContactStageMappings, hint } = props
  const {
    control,
    formState: { disabled },
  } = useFormContext<PatchHubspotSettings>()

  const statusHint = hint.reduce(
    (acc, h) => {
      acc[h.hubspotLeadStatus.internalValue] = h.riftContactStage ?? null
      return acc
    },
    {} as Record<string, ContactStage | null>,
  )

  const contactStageDropdownOptions: DropdownOption[] = ContactStageLabels.map((v) => ({
    id: v.value,
    label: v.label,
  }))

  return (
    <section className={clsx(disabled && 'pointer-events-none opacity-30')}>
      <div className="grid grid-cols-[minmax(0,_1fr)_40px_minmax(0,_1fr)] gap-y-5 align-middle">
        <Text variant="subtitle">Hubspot</Text>
        <div />
        <Text variant="subtitle">rift</Text>
      </div>

      <Controller
        control={control}
        name="hubspotToRiftContactStageMappings"
        render={({ field }) => {
          const statusSelectedByType = field.value.reduce(
            (acc, v) => {
              acc[v.hubspotLeadStatusInternalValue] = v.riftContactStage
              return acc
            },
            {} as Record<string, ContactStage | null>,
          )

          const onToggle = (internalValue: string) => {
            const newValue = statusSelectedByType[internalValue] ? null : statusHint[internalValue] ?? ContactStage.NEW
            field.onChange(
              field.value.map((v) =>
                v.hubspotLeadStatusInternalValue === internalValue
                  ? { hubspotLeadStatusInternalValue: internalValue, riftContactStage: newValue }
                  : v,
              ),
            )
          }

          const onSelect = (statusInternalValue: string, riftContactStage: ContactStage) => {
            field.onChange(
              field.value.map((mapping) =>
                mapping.hubspotLeadStatusInternalValue === statusInternalValue
                  ? { hubspotLeadStatusInternalValue: statusInternalValue, riftContactStage }
                  : mapping,
              ),
            )
          }

          return (
            <>
              {hubspotToRiftContactStageMappings.map((mapping) => {
                const status = mapping.hubspotLeadStatus
                const selected = statusSelectedByType[status.internalValue]
                return (
                  <div
                    key={status.internalValue}
                    className="col-span-3 grid h-12 grid-cols-[minmax(206px,_1fr)_40px_minmax(206px,_1fr)] gap-x-3 align-middle"
                  >
                    <Button
                      variant="leading-icon"
                      type="button"
                      icon={selected ? Check : Plus}
                      className={clsx('w-full justify-start self-center truncate', selected && 'border-accent')}
                      onClick={() => onToggle(status.internalValue)}
                      disabled={disabled}
                    >
                      <span title={status.label}>{status.label}</span>
                    </Button>

                    <ArrowRight
                      className={clsx('size-5 self-center justify-self-center fill-dusk', !selected && 'invisible')}
                    />
                    {selected ? (
                      <Dropdown
                        options={contactStageDropdownOptions}
                        selected={contactStageDropdownOptions.find((option) => option.id === selected) || null}
                        handleSelection={(selection) => onSelect(status.internalValue, selection.id as ContactStage)}
                      />
                    ) : (
                      <Text variant="text" className="self-center pl-3 text-medium">
                        Don't sync
                      </Text>
                    )}
                  </div>
                )
              })}
            </>
          )
        }}
      />
    </section>
  )
}

type ContactStageFilterFormProps = {
  leadStatuses: HubspotLeadStatuses
}

function ContactStageFilterForm(props: ContactStageFilterFormProps) {
  const { leadStatuses } = props
  const {
    control,
    formState: { disabled },
  } = useFormContext<PatchHubspotSettings>()

  return (
    <>
      <section className={clsx('w-lg', disabled && 'pointer-events-none opacity-30')}>
        <h1 className="mb-6 text-sm font-medium leading-5 tracking-wide text-dark">rift contacts</h1>
        <Controller
          control={control}
          name="contactStageFilters"
          render={({ field }) => {
            const onToggle = (stage: ContactStage) => {
              field.onChange(
                field.value.map((v) => (v.riftContactStage === stage ? { riftContactStage: stage, sync: !v.sync } : v)),
              )
            }
            return (
              <div className="grid grid-cols-2 items-center gap-x-12 gap-y-6">
                {field.value.map((v) => (
                  <Fragment key={'contactStageFilters_' + v.riftContactStage}>
                    <Button
                      variant="leading-icon"
                      type="button"
                      icon={v.sync ? Check : Plus}
                      className={clsx('w-full justify-start self-center truncate', v.sync && 'border-accent')}
                      onClick={() => onToggle(v.riftContactStage)}
                      disabled={disabled}
                    >
                      {ContactStageToText[v.riftContactStage]}
                    </Button>
                    <p className="text-sm leading-5 tracking-wide text-medium">{v.sync ? 'Sync' : 'Don’t sync'}</p>
                  </Fragment>
                ))}
              </div>
            )
          }}
        />
      </section>

      <section className={clsx('w-lg', disabled && 'pointer-events-none opacity-30')}>
        <h1 className="my-6 text-sm font-medium leading-5 tracking-wide text-dark">Hubspot contacts</h1>
        <Controller
          control={control}
          name="hsLeadStatusFilters"
          render={({ field }) => {
            const onToggle = (internalValue: string) => {
              const exist = field.value.some((v) => v.hubspotLeadStatusInternalValue === internalValue)
              field.onChange(
                exist
                  ? field.value.map((v) =>
                      v.hubspotLeadStatusInternalValue === internalValue
                        ? { hubspotLeadStatusInternalValue: internalValue, sync: !v.sync }
                        : v,
                    )
                  : [...field.value, { hubspotLeadStatusInternalValue: internalValue, sync: true }],
              )
            }

            const syncByInternalValue = field.value.reduce(
              (acc, v) => {
                acc[v.hubspotLeadStatusInternalValue] = v.sync
                return acc
              },
              {} as Record<string, boolean>,
            )

            return (
              <div className="grid grid-cols-2 items-center gap-x-12 gap-y-6">
                {leadStatuses.map((v) => (
                  <Fragment key={'hsLeadStatusFilters_' + v.internalValue}>
                    <Button
                      variant="leading-icon"
                      type="button"
                      icon={syncByInternalValue[v.internalValue] ? Check : Plus}
                      className={clsx(
                        'w-full justify-start self-center truncate',
                        syncByInternalValue[v.internalValue] && 'border-accent',
                      )}
                      onClick={() => onToggle(v.internalValue)}
                      disabled={disabled}
                    >
                      <span title={v.label}>{v.label}</span>
                    </Button>
                    <p className="text-sm leading-5 tracking-wide text-medium">
                      {syncByInternalValue[v.internalValue] ? 'Sync' : 'Don’t sync'}
                    </p>
                  </Fragment>
                ))}
              </div>
            )
          }}
        />
      </section>
    </>
  )
}
