import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react'

type RadioGroupProps<T> = {
  value: T
  options: { value: T; label: string }[]
  onChange: (val: T) => void
  label?: string
}

export function RadioGroup<T>(props: RadioGroupProps<T>) {
  return (
    <HeadlessRadioGroup value={props.value} onChange={props.onChange}>
      {props.label && (
        <HeadlessRadioGroup.Label className="label-medium mb-1 inline-block">{props.label}</HeadlessRadioGroup.Label>
      )}
      <div className="flex gap-0.5">
        {props.options.map((option) => (
          <HeadlessRadioGroup.Option
            value={option.value}
            key={option.label}
            className="label h-9 cursor-pointer rounded-lg border border-white px-3 py-2 transition-all duration-100 ease-in hover:border-light hover:duration-200 hover:ease-out active:duration-200 active:ease-out ui-checked:border-accent"
          >
            <span>{option.label}</span>
          </HeadlessRadioGroup.Option>
        ))}
      </div>
    </HeadlessRadioGroup>
  )
}
