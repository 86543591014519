import React, { useContext } from 'react'

type formState = {
  data: any
  setFormValues: any
}

export const FormContext = React.createContext<formState>({
  data: null,
  setFormValues: () => null,
})

export function useFormData() {
  return useContext(FormContext)
}
