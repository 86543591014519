import { useNavigate } from 'react-router-dom'
import { ConferencingPlatform, MeetingCategory, PostMeetingType, TokenStatus } from '@/api/core'
import { useMeetingsUsers, useMeetingTypeCreate } from '@/api/meetings'
import { useZoomOauthStatus } from '@/api/oauth'
import { useMemberProfile } from '@/api/user'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { MeetingTypeForm } from './MeetingTypeForm'

export const DefaultAvailability = {
  sunday: {
    available: false,
    start_time: `09:00:00`,
    end_time: `17:00:00`,
  },
  monday: {
    available: true,
    start_time: `09:00:00`,
    end_time: `17:00:00`,
  },
  tuesday: {
    available: true,
    start_time: `09:00:00`,
    end_time: `17:00:00`,
  },
  wednesday: {
    available: true,
    start_time: `09:00:00`,
    end_time: `17:00:00`,
  },
  thursday: {
    available: true,
    start_time: `09:00:00`,
    end_time: `17:00:00`,
  },
  friday: {
    available: true,
    start_time: `09:00:00`,
    end_time: `17:00:00`,
  },
  saturday: {
    available: false,
    start_time: `09:00:00`,
    end_time: `17:00:00`,
  },
}

export function NewMeetingType() {
  const { data } = useMemberProfile()
  const defaultAvailability = data?.data ?? { availability: DefaultAvailability, timezone: undefined }
  const createMeetingType = useMeetingTypeCreate()
  const users = useMeetingsUsers()
  const zoomOauthStatus = useZoomOauthStatus()
  const zoomIntegrationEnabled = zoomOauthStatus.data?.data?.tokenStatus === TokenStatus.VALID
  const navigate = useNavigate()
  const toast = useToast()
  const onUpdate = (body: PostMeetingType) => {
    createMeetingType.mutate(body, {
      onSuccess: () => {
        toast.createToast({ message: `${body.description} created` })
        navigate('/meetings')
      },
      onError: () => {
        toast.createToast({ message: `Error saving ${body.description}`, error: true })
      },
    })
  }
  return (
    <div className="mx-auto max-w-xl">
      <MeetingTypeForm
        members={users.data?.data ?? []}
        initialValues={{
          buffer: 0,
          category: MeetingCategory.PERSONAL,
          duration: 30,
          conferencingPlatform: zoomIntegrationEnabled ? ConferencingPlatform.ZOOM : ConferencingPlatform.GOOGLE_MEET,
          memberIds: [],
          availability: defaultAvailability.availability,
          timezone: defaultAvailability.timezone,
          syncToContacts: true,
          allowSameDayBooking: true,
        }}
        update={onUpdate}
        cancel={() => navigate('/meetings')}
      />
    </div>
  )
}
