import { useMemo, useState } from 'react'
import clsx from 'clsx'
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { ContactActionable, ContactStage } from '@/api/core'
import { Button, Checkbox, IndeterminateCheckbox } from '@/ui'
import { Tooltip } from '@/ui/headless'

export type ReviewProps = {
  onInclude: (contacts: ContactActionable[]) => void
  duplicated: ContactActionable[]
}

export function Review(props: ReviewProps) {
  const { onInclude, duplicated } = props
  const [rowSelection, setRowSelection] = useState<{ [key: number]: boolean }>({})
  const selectedCount = Object.values(rowSelection).filter((v) => v).length
  const onClick = () => onInclude(duplicated.filter((_, i) => rowSelection[i]))

  const columnHelper = createColumnHelper<ContactActionable>()
  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => (
          <IndeterminateCheckbox
            className="mr-2"
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.toggleAllRowsSelected}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            className="mr-2"
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
        meta: {
          className: 'w-8',
        },
      }),
      columnHelper.display({
        id: 'contact',
        header: () => <span>Contact</span>,
        cell: ({ row }) => (
          <>
            <div className="flex w-3/5 items-center justify-between overflow-auto">
              <div className="flex flex-col ">
                <span>
                  {row.original.first_name} {row.original.last_name}
                </span>
                <span className="text-sm text-gray-500">{row.original.email}</span>
              </div>
            </div>
          </>
        ),
      }),
      columnHelper.accessor((row) => row.campaigns, {
        id: 'campaign_name',
        header: () => <p>Campaign</p>,
        maxSize: 160,
        cell: ({ row }) => {
          if (!row.original.campaigns || row.original.campaigns.length === 0) return null
          if (row.original.campaigns.length === 1) {
            return <p className="label h-5 max-w-[160px] overflow-hidden">{row.original.campaigns[0].name}</p>
          }
          return (
            <div className="max-w-[160px]">
              <Tooltip placement={'top-start'}>
                <Tooltip.Trigger className="cursor-pointer">
                  <span className="label flex items-start">
                    <span className="h-5 overflow-hidden">{row.original.campaigns[0].name}</span>
                    <span className="ml-1">+{row.original.campaigns.length - 1}</span>
                  </span>
                </Tooltip.Trigger>
                <Tooltip.Panel className="z-[75] max-w-[300px]">
                  <ul className="rounded-lg border border-light bg-extra-light p-4">
                    {row.original.campaigns.map((campaign) => {
                      return (
                        <li className="label" key={campaign.id}>
                          {campaign.name}
                        </li>
                      )
                    })}
                  </ul>
                </Tooltip.Panel>
              </Tooltip>
            </div>
          )
        },
      }),
      columnHelper.accessor((row) => row.stage, {
        id: 'stage',
        header: () => <p className="text-right">Stage</p>,
        cell: ({ row }) => (
          <p className="text-right">
            <span className="rounded bg-accent bg-opacity-10 px-2 py-0.5">{row.original.stage}</span>
          </p>
        ),
      }),
    ],
    [],
  )

  const table = useReactTable<ContactActionable>({
    data: duplicated,
    columns,
    state: {
      rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
  })

  const onSelectInactive = () => {
    const newRowSelection: { [key: number]: boolean } = {}
    for (let i = 0; i < duplicated.length; i++) {
      if (duplicated[i].stage === ContactStage.INACTIVE) {
        newRowSelection[i] = true
      }
    }
    setRowSelection(newRowSelection)
  }

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex h-[90%] flex-col">
        <div className="my-4 flex flex-col items-center justify-center">
          <div className="w-full rounded bg-[#FEF9C3] px-6 py-5">
            <p className="mb-2 font-semibold text-[#422006]">{duplicated.length} contacts used in other campaigns</p>
            <p className="text-[#422006]">Select any you still want to include in this campaign</p>
          </div>
        </div>

        <div className="mb-8 overflow-auto px-1">
          <table className="w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      scope="col"
                      className={clsx(
                        'table-cell py-2 text-left font-semibold text-primary',
                        (header.column.columnDef.meta as { className?: string })?.className,
                      )}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="border-y border-gray-200 hover:bg-gray-50">
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="py-1">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex justify-between">
        <Button variant="basic" onClick={onSelectInactive}>
          Select all inactive
        </Button>

        <Button variant="accent" onClick={onClick}>
          {selectedCount === 0 ? (
            <>Don't include</>
          ) : selectedCount === duplicated.length ? (
            <>Include all</>
          ) : (
            <>Include {selectedCount}</>
          )}
        </Button>
      </div>
    </div>
  )
}
