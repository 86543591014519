import { useState } from 'react'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSenderEmailDelete, useSenderEmailUpdate } from '@/api'
import { PatchSenderEmail, SenderEmail } from '@/api/core'
import { FeatureFlag } from '@/containers/FeatureFlag'
import { useFlags } from '@/containers/FeatureFlag/useFlags'
import { ConfirmationDialog } from '@/pages/Contacts/ConfirmationDialog'
import { useFormData } from '@/providers/Emails/AccountEmailProvider'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { PermissionTooltip, TimezoneSelect } from '@/ui'
import { Button, DangerButtonWithIcon } from '@/ui/Button/v2'
import { InputField, InputPasswordField } from '@/ui/Input/v2'

type AccountInfoProps = {
  onSuccess: (action: 'update' | 'remove') => void
  sender: SenderEmail
}

export function AccountForm({ onSuccess, sender }: AccountInfoProps) {
  const { setFormValues } = useFormData()
  const { showFeatureFlag } = useFlags()
  const toast = useToast()

  const [campaignRescheduleConfirm, setCampaignRescheduleConfirm] = useState(false)
  const senderEmailUpdate = useSenderEmailUpdate()
  const senderEmailDelete = useSenderEmailDelete()

  const schema: yup.ObjectSchema<PatchSenderEmail> = yup.object().shape({
    first_name: yup.string().required('First name required'),
    last_name: yup.string().required('Last name required'),
    email: yup.string().required('Email required').email('Invalid email format'),
    password: yup.string(),
    timezone: yup.string().required().nullable(),
    custom_domain: yup.string(),
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<PatchSenderEmail>({
    defaultValues: {
      first_name: sender.first_name,
      last_name: sender.last_name,
      email: sender.email,
      password: undefined,
      timezone: sender.timezone,
      custom_domain: sender.custom_domain,
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = (values: PatchSenderEmail) => {
    senderEmailUpdate.mutate(
      {
        senderEmailId: sender.id,
        email: showFeatureFlag ? values?.email : undefined,
        first_name: values.first_name,
        last_name: values.last_name,
        password: values.password || undefined,
        timezone: values.timezone,
        custom_domain: values.custom_domain || undefined,
      },
      {
        onSuccess: () => {
          setFormValues(values)
          onSuccess('update')
          toast.createToast({ message: 'Email account updated successfully.' })
        },
        onError: (err) => {
          toast.createToast({ error: true, message: (err as any)?.body?.message || 'Failed to update sender' })
        },
      },
    )
  }

  const onRemove = () => {
    senderEmailDelete.mutate(sender.id, {
      onSuccess: () => {
        toast.createToast({ message: 'Email account deleted successfully.' })
        onSuccess('remove')
      },
      onError: (err) => {
        toast.createToast({ error: true, message: (err as any)?.body?.message || 'Failed to delete email account' })
      },
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={clsx(isSubmitting && 'pointer-events-none opacity-30')}>
        <InputField
          name="email"
          id="email"
          type="email"
          placeholder="Email"
          label="Email"
          error={errors['email']}
          registration={register('email')}
          // only allow edition for non-google/outlook accounts
          // if a user wants to change the email for a google/outlook account
          // they should reconnect the account
          disabled={!showFeatureFlag || sender?.oauthConnected}
        />
        <InputField
          name="first_name"
          id="first_name"
          type="text"
          placeholder="First Name"
          label="First Name"
          error={errors['first_name']}
          registration={register('first_name')}
        />

        <InputField
          name="last_name"
          id="last_name"
          type="text"
          placeholder="Last Name"
          label="Last Name"
          error={errors['last_name']}
          registration={register('last_name')}
        />

        <div className="py-2">
          <FeatureFlag>
            <TimezoneSelect
              name="timezone"
              id="timezone"
              label="Timezone"
              control={control}
              error={errors['timezone']}
            />
          </FeatureFlag>
        </div>
        <FeatureFlag>
          <InputField
            name="custom_domain"
            id="custom_domain"
            type="text"
            placeholder="Custom Domain"
            label="Custom Domain"
            error={errors['custom_domain']}
            registration={register('custom_domain')}
          />
        </FeatureFlag>

        {!sender.oauthConnected && (
          <InputPasswordField
            name="password"
            id="password"
            placeholder="App Password"
            label="App Password"
            error={errors['password']}
            registration={register('password')}
            extra="Enter your 16 character app password without any spaces"
          />
        )}
        <div className="flex flex-row justify-end">
          <div className="mt-4 flex flex-row">
            <ConfirmationDialog
              open={campaignRescheduleConfirm}
              onClose={() => setCampaignRescheduleConfirm(false)}
              onConfirm={onRemove}
              message="Are you sure you want to delete this account email?"
            />
            <DangerButtonWithIcon
              variant="basic"
              className="mr-4"
              disabled={senderEmailDelete.isPending || sender.permissions.delete.deny}
              type="button"
              onClick={() => setCampaignRescheduleConfirm(true)}
            >
              <PermissionTooltip permission={sender.permissions.delete}>Delete</PermissionTooltip>
            </DangerButtonWithIcon>

            <Button type="submit" variant="accent">
              Save
            </Button>
          </div>
        </div>
      </form>
    </>
  )
}
