import { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import { useDebounce } from 'use-debounce'
import { ArrowLeft, DotsThree } from '@phosphor-icons/react'
import { useContactList, useContactListContacts, useContactListDelete, useContactListUpdate } from '@/api/contact_lists'
import { PatchContactList } from '@/api/core'
import { useMembers } from '@/api/member'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, DropdownMenu, PermissionTooltip, SearchBar } from '@/ui'
import { ErrorInfo } from '@/ui/ErrorInfo'
import { Import } from './Import/Import'
import { ConfirmationDialog } from './ConfirmationDialog'
import { ContactsTable } from './ContactsTable'
import { ContactFilters, Filter } from './Filter'

export function ContactListView() {
  const { contactListId } = useParams() as { contactListId: string }
  const { data: contactList, status: contactListStatus } = useContactList(contactListId)
  const { data: contacts, status: contactsStatus } = useContactListContacts(contactListId)
  const { data: members, status: membersStatus } = useMembers({ phantom: true })
  const deleteContactList = useContactListDelete()
  const [search, setSearch] = useState('')
  const [searchD] = useDebounce(search, 500)
  const toast = useToast()
  const [contactsFilter, setContactsFilter] = useState<ContactFilters>({
    stages: [],
    created_at: {
      start: null,
      end: null,
    },
    last_contacted_at: {
      start: null,
      end: null,
    },
    owners: [],
  })
  const [openImportDialog, setOpenImpotDialog] = useState(false)
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false)

  const updateList = useContactListUpdate()
  const { register, reset, getValues } = useForm<PatchContactList>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      name: '',
      description: '',
    },
  })
  useEffect(() => {
    if (!contactList?.data) {
      return
    }

    reset({
      name: contactList.data.name,
      description: contactList.data.description,
    })
  }, [contactList])

  const onChange = () => {
    updateList.mutate({ ...getValues(), contactListId: contactListId }, {})
  }

  const onDelete = () => {
    deleteContactList.mutate(contactListId, {
      onSuccess: () => navigate('/contacts/lists'),
      onError: (err) => {
        toast.createToast({ message: (err as any)?.body?.message || 'failed to delete contacts list', error: true })
      },
    })
  }

  const navigate = useNavigate()
  const goBack = () => navigate(-1)

  if (contactListStatus === 'error' || contactsStatus === 'error' || membersStatus === 'error') {
    return <ErrorInfo />
  }

  if (contactListStatus === 'pending' || contactsStatus === 'pending' || membersStatus === 'pending') {
    return <div className="skeleton mb-2 h-48 w-full" />
  }

  return (
    <>
      <ConfirmationDialog
        open={deleteConfirmationDialog}
        onClose={() => setDeleteConfirmationDialog(false)}
        message={`You are about to delete ${contactList.data.name}.`}
        onConfirm={onDelete}
      />
      {openImportDialog && (
        <Import open={openImportDialog} onClose={() => setOpenImpotDialog(false)} contactListId={contactList.data.id} />
      )}
      <div className="inline-block min-w-full align-middle">
        <div className="mb-2 flex items-center justify-between ">
          <div className="flex grow items-center">
            <ArrowLeft className="icon-hover mt-0.5 size-6" aria-hidden="true" onClick={goBack} />
            <form onChange={onChange} className="w-full px-2">
              <input
                type="text"
                className={clsx(
                  'w-full rounded-lg border border-transparent p-1 pl-2 placeholder-gray-400',
                  'hover:border hover:border-gray-300 hover:bg-gray-50 hover:shadow-sm disabled:bg-white',
                  'disabled:bg-white',
                  'focus:border-primary',
                )}
                disabled={contactList.data.permissions.edit.deny}
                {...register('name', { required: 'name required', minLength: 1 })}
              />
            </form>
          </div>
          <div className="flex items-center justify-center gap-2">
            {!contactList.data.permissions.edit.deny && (
              <Button variant="basic" onClick={() => setOpenImpotDialog(true)}>
                Import contacts to list
              </Button>
            )}
            <DropdownMenu>
              <DropdownMenu.Button>
                <DotsThree className="size-6" />
              </DropdownMenu.Button>
              <DropdownMenu.Items className="-left-1/2">
                <DropdownMenu.Item
                  onClick={() => setDeleteConfirmationDialog(true)}
                  disabled={contactList.data.permissions.delete.deny}
                >
                  <PermissionTooltip permission={contactList.data.permissions.delete}>Delete</PermissionTooltip>
                </DropdownMenu.Item>
              </DropdownMenu.Items>
            </DropdownMenu>
          </div>
        </div>

        {membersStatus === 'success' && (
          <Filter onChange={setContactsFilter} owners={members.data}>
            <div className="mb-4 flex justify-between">
              <div className="flex gap-2">
                <div className="w-96">
                  <SearchBar value={search} onChange={setSearch} />
                </div>
                <Filter.Add />
              </div>
            </div>
            <Filter.Labels />
          </Filter>
        )}

        <ContactsTable
          data={contacts.data}
          owners={members.data}
          globalFilter={searchD}
          setGlobalFilter={setSearch}
          contactsFilter={contactsFilter}
          contactList={contactList.data}
        />
      </div>
    </>
  )
}
