import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { DefaultService, PatchTeam, PatchTeamsInboxesMove, PatchTeamsMembersMove, PostTeam } from './core'

export const queryKey = {
  base: () => ['teams'] as const,
}

export function useTeams() {
  return useQuery({
    queryKey: queryKey.base(),
    queryFn: () => DefaultService.getTeams(),
  })
}

export function useTeamCreate() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: PostTeam) => DefaultService.postTeams(body),
  })
}

export function useTeamUpdate() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: PatchTeam & { id: string }) => {
      const { id, ...rest } = body
      return DefaultService.patchTeams(id, rest)
    },
  })
}

export function useTeamDelete() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (teamId: string) => DefaultService.deleteTeams(teamId),
  })
}

export function useTeamMembersMove() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: PatchTeamsMembersMove) => DefaultService.patchTeamsMembersMove(body),
  })
}

export function useTeamInboxesMove() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: PatchTeamsInboxesMove) => DefaultService.patchTeamsInboxesMove(body),
  })
}
