import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useSenderEmail } from '@/api'
import { AccountForm } from '@/containers/Email/AccountForm'

export function Edit() {
  const navigate = useNavigate()
  const { senderEmailId } = useParams() as { senderEmailId: string }
  const { data, status } = useSenderEmail(senderEmailId)
  const goto = (action: 'update' | 'remove') => {
    if (action === 'update') {
      navigate(-1)
    } else if (action === 'remove') {
      navigate('/settings/senders')
    }
  }

  if (status === 'error') {
    return <Navigate to="/emails" />
  }

  if (status === 'pending') {
    return <div className="skeleton h-32 w-full" />
  }

  return (
    <div className="mx-auto max-w-7xl bg-white p-4">
      <div className="justify-start border-b border-gray-200 pb-4 text-center sm:flex sm:items-center">
        <h1 className="text-2xl font-bold text-primary">Edit Email Account</h1>
      </div>

      <div className="mx-auto mt-4 max-w-7xl bg-white">
        <AccountForm onSuccess={goto} sender={data.data} />
      </div>
    </div>
  )
}
