import clsx from 'clsx'
import Handlebars, { Exception } from 'handlebars'
import { Editor } from '@tiptap/react'
import { Text } from '../Text'
import { useData } from './Context'
import { putBrsInEmptyPTags } from './putBrsInEmptyPTags'

export type VariableItemProps = {
  className?: string
  unsubscribeLink?: string
  plainText?: string
}

export function PreviewSubject(props: VariableItemProps) {
  const { subjectEditor } = useData()
  return <Preview editor={subjectEditor} className={props.className} />
}

export function PreviewBody(props: VariableItemProps) {
  const { bodyEditor } = useData()
  return (
    <Preview
      editor={bodyEditor}
      className={props.className}
      unsubscribeLink={props.unsubscribeLink}
      {...(props.plainText && { plainText: props.plainText })}
    />
  )
}

type PreviewProps = {
  editor: Editor | null
  className?: string
  unsubscribeLink?: string
  plainText?: string
}

function Preview(props: PreviewProps) {
  const { editor, className, unsubscribeLink, plainText } = props
  const { variables } = useData()

  let output = ''
  try {
    output = Handlebars.compile(plainText ? plainText : putBrsInEmptyPTags(editor?.getHTML() ?? ''), {
      noEscape: true,
    })(variables)
  } catch (e) {
    output = (e as Exception).message
  }

  return (
    <div className={clsx('ProseMirror relative', className)}>
      <div dangerouslySetInnerHTML={{ __html: unsubscribeLink ? output + unsubscribeLink : output }} />
      {plainText && (
        <span className="absolute right-0 top-0 rounded-lg bg-light p-2 shadow-rift">
          <Text className="dus !text-xs" variant="subtext">
            Plain text mode enabled
          </Text>
        </span>
      )}
    </div>
  )
}
