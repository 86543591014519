import { DoubleMappings, SalesforceConnectionOptions } from '@/api/core'
import { DoubleMappingForm, MappingDirection } from './DoubleMapping'

type SalesforceFieldMappingProps = {
  direction: MappingDirection
  options: SalesforceConnectionOptions
  mapping: DoubleMappings
  setMapping: (mapping: DoubleMappings) => void
}
export function SalesforceFieldMappingForm({ direction, options, mapping, setMapping }: SalesforceFieldMappingProps) {
  return (
    <>
      <DoubleMappingForm
        title={
          direction === 'crm_to_rift'
            ? 'Map contact fields from Salesforce to rift'
            : 'Map contact fields from rift to Salesforce'
        }
        subtitle="Select how you’d like to sync data between rift and Salesforce"
        crmName="Salesforce"
        riftFields={options.riftFields}
        crmFields={options.crmFields}
        crmConvertedFields={options.crmConvertedFields}
        defaultFieldMapping={options.defaultFieldMappings}
        mapping={mapping}
        setMapping={setMapping}
        direction={direction}
      />
    </>
  )
}
