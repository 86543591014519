import { Link } from 'react-router-dom'
import { useManualStepsCalls } from '@/api/manual_step'
import { Text } from '@/ui'
import { AllSteps } from '../AllSteps'
import { Finished } from '../Finished'
import { AllStepsSkeleton } from '../Skeletons'

export function Calls() {
  const { data, status } = useManualStepsCalls()

  if (status === 'error' || status === 'pending') {
    return <AllStepsSkeleton />
  }

  return (
    <section>
      {data.data.length > 0 ? (
        <AllSteps>
          {data.data.map(({ sequence, calls }) => (
            <AllSteps.Group key={sequence.id} title={sequence.name}>
              {calls.map(({ id, contact }) => (
                <AllSteps.Item key={id}>
                  <Link to={`/inbox/calls/${id}`} className=" cursor-default items-center">
                    <Text variant="text" className="font-medium text-dark">
                      {contact.firstName} {contact.lastName}
                    </Text>
                    <Text className="text-dusk">
                      {contact.title} {contact.title && contact.company && 'at'} {contact.company}
                    </Text>
                  </Link>
                </AllSteps.Item>
              ))}
            </AllSteps.Group>
          ))}
        </AllSteps>
      ) : (
        <Finished message="No calls to make" />
      )}
    </section>
  )
}
