import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CopySimple, DotsThree } from '@phosphor-icons/react'
import { useCampaignArchive, useCampaignClone, useCampaignDelete, useCampaignUnarchive } from '@/api/campaigns'
import { Campaign } from '@/api/core'
import { ExportDataDialog } from '@/containers/Campaigns/ExportDataDialog'
import { useDisclosure } from '@/hooks/useDisclosure'
import { ConfirmationDialog } from '@/pages/Contacts/ConfirmationDialog'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { DropdownMenu, Text, Tooltip } from '@/ui'
import { ShareDialog } from '../ShareDialog'

export function HeaderDropMenu({ campaign }: { campaign: Campaign }) {
  const { isOpen: isExportOpen, open: openExport, close: closeExport } = useDisclosure()
  const toast = useToast()
  const deleteCampaign = useCampaignDelete()
  const cloneCampaign = useCampaignClone()
  const archiveCampaign = useCampaignArchive()
  const unarchiveCampaign = useCampaignUnarchive()
  const navigate = useNavigate()
  const [removeCampaign, setRemoveCampaign] = useState(false)
  const [shareDialogOpen, setShareDialogOpen] = useState(false)

  const onDeleteCampaign = () => {
    deleteCampaign.mutate(campaign.id, {
      onSuccess: () => {
        toast.createToast({ message: 'Sequence deleted' })
        navigate('/sequences')
      },
      onError: (err: unknown) => {
        toast.createToast({ message: (err as any)?.body?.message || 'Failed to delete sequence', error: true })
      },
    })
  }

  const onCloneCampaign = () => {
    cloneCampaign.mutate(campaign.id, {
      onSuccess: () => toast.createToast({ message: 'Sequence cloned' }),
      onError: (err: unknown) => {
        toast.createToast({ message: (err as any)?.body?.message || 'Failed to clone sequence', error: true })
      },
    })
  }

  const onArchiveCampaign = () => {
    archiveCampaign.mutate(campaign.id, {
      onSuccess: () => toast.createToast({ message: 'Sequence archived' }),
      onError: (err: unknown) => {
        toast.createToast({ message: (err as any)?.body?.message || 'Failed to archive sequence', error: true })
      },
    })
  }

  const onUnarchiveCampaign = () => {
    unarchiveCampaign.mutate(campaign.id, {
      onSuccess: () => toast.createToast({ message: 'Sequence unarchived' }),
      onError: (err: unknown) => {
        toast.createToast({ message: (err as any)?.body?.message || 'Failed to unarchive sequence', error: true })
      },
    })
  }

  return (
    <>
      <ConfirmationDialog
        open={removeCampaign}
        onClose={() => setRemoveCampaign(false)}
        onConfirm={onDeleteCampaign}
        message={`You are about to remove campaign ${campaign.name}.`}
      />
      <ShareDialog campaignId={campaign.id} open={shareDialogOpen} onClose={() => setShareDialogOpen(false)} />
      <button>
        <Tooltip>
          <Tooltip.Trigger>
            <CopySimple width={20} height={20} onClick={onCloneCampaign} className="mr-4" />
          </Tooltip.Trigger>
          <Tooltip.Panel>
            <Text>Clone</Text>
          </Tooltip.Panel>
        </Tooltip>
      </button>

      <DropdownMenu>
        <DropdownMenu.Button>
          <DotsThree className="size-4.5" />
        </DropdownMenu.Button>
        <DropdownMenu.Items className="right-0">
          {!campaign.permissions.share.deny && (
            <DropdownMenu.Item onClick={() => setShareDialogOpen(true)}>Share</DropdownMenu.Item>
          )}

          <DropdownMenu.Item onClick={openExport}>Export</DropdownMenu.Item>
          {!campaign.permissions.archive.deny && (
            <>
              {campaign.archived_at ? (
                <>
                  <DropdownMenu.Item onClick={onUnarchiveCampaign}>Unarchive</DropdownMenu.Item>
                  <DropdownMenu.Item onClick={() => setRemoveCampaign(true)}>Remove</DropdownMenu.Item>
                </>
              ) : (
                <>
                  <DropdownMenu.Item onClick={onArchiveCampaign}>Archive</DropdownMenu.Item>
                </>
              )}
            </>
          )}
        </DropdownMenu.Items>
      </DropdownMenu>
      {isExportOpen && (
        <ExportDataDialog
          isOpen
          close={closeExport}
          campaignId={campaign.id}
          campaignName={campaign.name}
          hasValidContacts={!campaign.permissions.download_valid_contacts.deny}
          hasUnhealthyContacts={!campaign.permissions.download_unhealthy_contacts.deny}
          hasOpenedContacts={!campaign.permissions.download_opened_contacts.deny}
          hasRepliedContacts={!campaign.permissions.download_replied_contacts.deny}
          hasUnsubscribeContacts={!campaign.permissions.download_unsubscribed_contacts.deny}
          hasBouncedContacts={!campaign.permissions.download_bounced_contacts.deny}
          hasCatchAllContacts={!campaign.permissions.download_catch_all_contacts.deny}
        />
      )}
    </>
  )
}
