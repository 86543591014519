import { useMemo, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import clsx from 'clsx'
import { X } from '@phosphor-icons/react'
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useAccounts } from '@/api'
import { useAccount } from '@/api/accounts'
import { AccountContact, AccountList, AccountsList } from '@/api/core'
import { useObserveSticky } from '@/hooks/useObserveSticky'
import { Drawer } from '@/ui'
import { KVList } from './KVList'

export function AccountsView() {
  const { data, isSuccess } = useAccounts()
  const { accountId } = useParams()
  const [currAccountId, setCurrAccountId] = useState<string | null>(accountId ? accountId : null)
  if (!isSuccess) {
    return (
      <>
        <div className="skeleton mb-2 h-48 w-full"></div>
        <span className="sr-only">Loading...</span>
      </>
    )
  }
  return (
    <>
      {currAccountId && (
        <AccountDetails
          accountId={currAccountId}
          onClose={() => {
            setCurrAccountId(null)
            history.pushState(null, '', `/contacts/accounts`)
          }}
        />
      )}
      <AccountsTable
        data={data.data}
        onAccountClick={(id: string) => {
          setCurrAccountId(id)
          history.pushState(null, '', `/contacts/accounts/${id}`)
        }}
      />
    </>
  )
}

type AccountsTableProps = {
  data: AccountsList
  onAccountClick: (id: string) => void
}

function AccountsTable(props: AccountsTableProps) {
  const [tableHeaderRef, tableHeaderColored] = useObserveSticky<HTMLTableRowElement>()
  const tableRef = useRef(null)

  const columnHelper = createColumnHelper<AccountList>()
  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'name',
        header: () => <span>Name</span>,
        cell: (info) => (
          <button className="cursor-pointer" onClick={() => props.onAccountClick(info.row.original.id)}>
            {info.row.original.name || '–'}
          </button>
        ),
      }),
      columnHelper.accessor((row) => row.domain, {
        id: 'email',
        cell: (info) => <span>{info.row.original.domain}</span>,
        header: () => <span>Domain</span>,
      }),
    ],
    [],
  ) as ColumnDef<AccountList, any>[]

  const table = useReactTable({
    data: props.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })
  return (
    <>
      <table className="min-w-full" ref={tableRef}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} ref={tableHeaderRef}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    scope="col"
                    className={clsx(
                      'sticky top-0 table-cell border-y border-gray-200 py-2 text-left text-sm font-semibold text-primary',
                      tableHeaderColored && 'bg-gray-100',
                    )}
                  >
                    <div className={clsx('relative', header.column.id === 'select' && 'w-full pr-2 text-center')}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="whitespace-wrap border-y border-gray-200 transition-colors hover:bg-gray-50"
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={clsx('py-3 text-sm font-medium', cell.column.id === 'select' && 'pr-2 text-center')}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              )
            })
          ) : (
            <tr className="justify-center border-gray-300">
              <td
                colSpan={table.getHeaderGroups()[0].headers.length}
                className="w-full py-4 text-center text-sm font-medium text-primary"
              >
                No Accounts
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="h-2" />
    </>
  )
}

type AccountDetailsProps = {
  accountId: string
  onClose: () => void
}
function AccountDetails(props: AccountDetailsProps) {
  const { data, isPending } = useAccount(props.accountId)

  const account = data?.data
  return (
    <Drawer open={true} onClose={props.onClose}>
      <div className="flex flex-col gap-4">
        <div className="mb-4 flex justify-between">
          <X className="icon-hover size-6" aria-hidden="true" onClick={props.onClose} />
        </div>
        {isPending || !account ? (
          <>
            <div className="skeleton mb-2 h-48 w-full"></div>
            <span className="sr-only">Loading...</span>
          </>
        ) : (
          <>
            {account.logo && (
              <div className="flex items-center justify-center ">
                <img width="100" src={account.logo} alt="salesforce logo" />
              </div>
            )}
            <h3 className="text-md font-semibold text-primary">Details</h3>
            <div className="pl-2">
              <KVList
                data={{
                  Name: account.name,
                  Domain: account.domain,
                  Description: account.description,
                  Facebook: account.facebook ? <Link to={account.facebook}>{account.facebook}</Link> : '-',
                  Twitter: account.twitter ? <Link to={account.twitter}>{account.twitter}</Link> : '-',
                  LinkedIn: account.linkedin ? <Link to={account.linkedin}>{account.linkedin}</Link> : '-',
                  Instagram: account.instagram ? <Link to={account.instagram}>{account.instagram}</Link> : '-',
                  'Year Founded': account.yearFounded,
                  Employees: account.employeesCount,
                  City: account.city,
                  'Zip Code': account.zipCode,
                  State: account.state,
                  Country: account.country,
                }}
              />
            </div>
            <h3 className="text-md font-semibold text-primary">Contacts</h3>
            <div className="pl-2">
              {account.contacts
                ? account.contacts.map((v: AccountContact) => (
                    <div key={`account-contact-${v.id}`}>
                      <Link to={`/contacts/${v.id}`} onClick={props.onClose} className="text-rift-blue-600">
                        {v.firstName} {v.lastName} ({v.email})
                      </Link>
                    </div>
                  ))
                : '-'}
            </div>
          </>
        )}
      </div>
    </Drawer>
  )
}
