import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  ApiError,
  DefaultService,
  PatchReplyTo,
  PatchSenderEmail,
  PostSenderEmails,
  PostSenderTestSend,
} from '@/api/core'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { errhandler } from './helpers'
import { queryKey as inboxQueryKey } from './inbox'
import { queryKey as teamQueryKey } from './team'

export const queryKey = ['sender_emails']
export const queryKeyStats = ['sender_emails_stats']

export function useSenderEmail(senderEmailId: string) {
  return useQuery({
    queryKey: ['sender_email', senderEmailId],
    queryFn: () => DefaultService.getSenderEmailById(senderEmailId),
  })
}

export function useSenders() {
  return useQuery({
    queryKey,
    queryFn: DefaultService.getSenders,
  })
}

export function useSenderEmailCreate() {
  const queryClient = useQueryClient()

  return useMutation({
    onError: (error: ApiError) => {
      errhandler(error, 'Email account creation failed.')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey })
      queryClient.invalidateQueries({ queryKey: queryKeyStats })
      queryClient.invalidateQueries({ queryKey: inboxQueryKey.list() })
      queryClient.invalidateQueries({ queryKey: teamQueryKey.base() })
    },
    mutationFn: (body: PostSenderEmails) => DefaultService.postSenderEmails(body),
  })
}

export function useSenderEmailUpdate() {
  const queryClient = useQueryClient()

  return useMutation({
    onError: (error: ApiError) => {
      errhandler(error, 'Email account update failed.')
    },
    onSuccess: (body) => {
      queryClient.invalidateQueries({ queryKey: ['sender_emails'] })
      queryClient.invalidateQueries({ queryKey: ['sender_email', body.data.id] })
      queryClient.invalidateQueries({ queryKey: inboxQueryKey.list() })
      queryClient.invalidateQueries({ queryKey: teamQueryKey.base() })
    },

    mutationFn: (data: PatchSenderEmail & { senderEmailId: string }) => {
      const { senderEmailId, ...body } = data
      return DefaultService.patchSenderEmails(senderEmailId, body)
    },
  })
}

export function useSenderEmailDelete() {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey })
      queryClient.invalidateQueries({ queryKey: queryKeyStats })
      queryClient.invalidateQueries({ queryKey: inboxQueryKey.list() })
      queryClient.invalidateQueries({ queryKey: teamQueryKey.base() })
    },
    mutationFn: (senderEmailId: string) => DefaultService.deleteSenderEmails(senderEmailId),
  })
}

export function useSenderEmailsRevalidateAuthn() {
  const queryClient = useQueryClient()
  const toast = useToast()

  return useMutation({
    onError: (error: ApiError) => {
      errhandler(error, 'Health checks failed.')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: inboxQueryKey.list() })
      toast.createToast({ message: 'Health checks refreshed.' })
    },
    mutationFn: () => DefaultService.postSenderEmailsRevalidateAuthn(),
  })
}

export function useSenderTestSendEmail() {
  const toast = useToast()

  return useMutation({
    onError: (e: ApiError) => {
      errhandler(e, 'Send test email failed.')
    },
    onSuccess: () => {
      toast.createToast({ message: 'Email sent' })
    },
    mutationFn: (data: PostSenderTestSend & { senderId: string }) => {
      const { senderId, ...body } = data
      return DefaultService.postSendersTestsend(senderId, body)
    },
  })
}

export function useSenderEmailDisconnect() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: (_, senderId) => {
      queryClient.invalidateQueries({ queryKey: ['sender_email', senderId] })
    },

    mutationFn: (senderId: string) => {
      return DefaultService.patchSenderEmailsDisconnect(senderId)
    },
  })
}

export function useSenderEmailConnect() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: (_, senderId) => {
      queryClient.invalidateQueries({ queryKey: ['sender_email', senderId] })
    },

    mutationFn: (senderId: string) => {
      return DefaultService.patchSenderEmailsConnect(senderId)
    },
  })
}

export function useSenderEmailReplyto() {
  return useMutation({
    mutationFn: (data: PatchReplyTo & { senderId: string }) => {
      const { senderId, ...body } = data
      return DefaultService.patchSenderEmailsReplyto(senderId, body)
    },
  })
}
