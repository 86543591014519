import { useQuery } from '@tanstack/react-query'
import { DefaultService } from '@/api/core'

export const queryKey = {
  base: () => ['notifications'] as const,
}

export function useNotifications() {
  return useQuery({
    queryKey: queryKey.base(),
    queryFn: () => DefaultService.getNotifications(),
  })
}
