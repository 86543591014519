import { useEffect, useState } from 'react'
import { Controller, useController, useForm } from 'react-hook-form'
import clsx from 'clsx'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { TrashSimple } from '@phosphor-icons/react'
import { ManualStepAddressInfo, ManualStepDraftProduct, PatchManualStepReplyDraft } from '@/api/core'
import { useManualStepsReplySave } from '@/api/manual_step'
import { useDebounceValue } from '@/hooks'
import { yupValidate } from '@/lib/template'
import { Button, EmailEditor, Text, Toolbar } from '@/ui'
import { InputMultiTag } from '@/ui/InputMultiTag'
import { ErrorText } from '@/ui/Text/Error'
import { SingleStep } from '../SingleStep'

type EditorProps = {
  variables: Record<string, string>
  subject: string
  body: string
  replyId: string
  onSend: () => void
  onDelete: () => void
  to: ManualStepAddressInfo[]
  cc: ManualStepAddressInfo[]
  bcc: ManualStepAddressInfo[]
}

export function Editor(props: EditorProps) {
  const { variables, body, onDelete, onSend, replyId, subject, to, cc, bcc } = props
  const saveDraft = useManualStepsReplySave(replyId)
  const [debounced, debouncedBody, setDebounceBody] = useDebounceValue(body, { delay: 200 })
  const allAddresses = [...to, ...cc, ...bcc]
  const [editHeaderMode, setHeaderEditMode] = useState(false)
  const validator = yup.string().email('invalid email')
  const [toInput, setToInput] = useState('')
  const [ccInput, setCcInput] = useState('')
  const [bccInput, setBccInput] = useState('')
  const addressInfoSchema = yup
    .array()
    .of(yup.object().shape({ email: yup.string().email().required(), name: yup.string() }))
    .required()

  const schema = yup.object().shape({
    body: yup.string().required('').test({ test: yupValidate }),
    subject: yup.string().required(''),
    to: addressInfoSchema,
    cc: addressInfoSchema,
    bcc: addressInfoSchema,
    product: yup.mixed<ManualStepDraftProduct>().oneOf(Object.values(ManualStepDraftProduct)).required(),
  })

  const {
    control,
    getValues,
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
    watch,
  } = useForm<PatchManualStepReplyDraft>({
    defaultValues: {
      body: body,
      subject: subject,
      to: to,
      cc: cc,
      bcc: bcc,
      product: ManualStepDraftProduct.REPLIES,
    },
    mode: 'all',
    resolver: yupResolver(schema),
  })
  const {
    field: { onChange: onBodyChangeForm },
  } = useController({ control, name: 'body' })

  const onBodyChange = (value: string) => {
    setDebounceBody(value)
    onBodyChangeForm(value)
  }
  const onSave = (v: PatchManualStepReplyDraft) => {
    saveDraft.mutate(v)
  }
  useEffect(() => {
    onSave(getValues())
  }, [watch('to'), watch('cc'), watch('bcc'), debouncedBody])

  return (
    <SingleStep.Card className="flex flex-col gap-y-2">
      {editHeaderMode ? (
        <div className="col-span-2 grid grid-cols-[40px_minmax(20px,_1fr)] items-center">
          <span className="text-medium">To</span>{' '}
          <Controller
            control={control}
            name="to"
            render={({ field }) => (
              <InputMultiTag
                value={field.value?.map((v) => v.name || v.email) || []}
                validate={(v) => validator.validateSync(v)}
                onChange={(v) => field.onChange(v.map((v) => ({ email: v })))}
                onInputValueChange={setToInput}
              />
            )}
          />
          <span className="text-medium">Cc</span>{' '}
          <Controller
            control={control}
            name="cc"
            render={({ field }) => (
              <InputMultiTag
                value={field.value?.map((v) => v.email) || []}
                validate={(v) => validator.validateSync(v)}
                onChange={(v) => field.onChange(v.map((v) => ({ email: v })))}
                onInputValueChange={setCcInput}
              />
            )}
          />
          <span className="text-medium">Bcc</span>{' '}
          <Controller
            control={control}
            name="bcc"
            render={({ field }) => (
              <InputMultiTag
                value={field.value?.map((v) => v.email) || []}
                validate={(v) => validator.validateSync(v)}
                onChange={(v) => field.onChange(v.map((v) => ({ email: v })))}
                onInputValueChange={setBccInput}
              />
            )}
          />
        </div>
      ) : (
        <div onClick={() => setHeaderEditMode(true)}>
          <Text className="text-medium">
            <span className="font-medium text-success">Draft</span> to{' '}
            {allAddresses.length === 0 && <span className="font-medium text-dark">no recepient</span>}
            {allAddresses.length > 0 &&
              allAddresses
                .map((address, idx) => (
                  <span key={idx} className="font-medium text-dark">
                    {address.name || address.email}
                  </span>
                ))
                .reduce((prev, next) => [prev, ', ', next] as any)}
          </Text>
        </div>
      )}
      <form onSubmit={handleSubmit(onSend)}>
        <EmailEditor
          variables={variables}
          displaySubject={false}
          body={body}
          setBody={onBodyChange}
          className="min-h-48"
        >
          <Toolbar>
            <Toolbar.Full />
          </Toolbar>
          <div className="flex justify-between">
            <div className="flex gap-2">
              <Button
                className={clsx('w-fit', saveDraft.isPending && 'pointer-events-none')}
                type="submit"
                disabled={
                  !isValid ||
                  isSubmitting ||
                  saveDraft.isPending ||
                  debounced != body ||
                  !!toInput ||
                  !!ccInput ||
                  !!bccInput
                }
              >
                Send
              </Button>
            </div>

            <Button className="rounded-full px-2" onClick={onDelete} type="button">
              <TrashSimple className="h-4 w-4 text-medium" />
            </Button>
          </div>
        </EmailEditor>
      </form>
      {errors?.body?.message && <ErrorText errorMessage={`Body error: ${errors.body.message}`} />}
    </SingleStep.Card>
  )
}
