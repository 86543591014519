import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowBendUpLeft, Check } from '@phosphor-icons/react'
import { ManualStepReplyInfo } from '@/api/core/models/ManualStepReplyInfo'
import {
  useManualStepsReply,
  useManualStepsReplyDelete,
  useManualStepsReplyDone,
  useManualStepsReplyDraftCreate,
  useManualStepsReplySend,
} from '@/api/manual_step'
import { useDisclosure } from '@/hooks/useDisclosure'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Dialog, Text } from '@/ui'
import { ContactInformation } from '../ContactInformation'
import { SingleStep } from '../SingleStep'
import { SingleStepSkeleton } from '../Skeletons'
import { Editor } from './Editor'
import { ReplyThread, ReplyThreadFocused } from './Threads'
import { getDisplayName } from './utilities'

export function SingleReply() {
  const { replyId } = useParams() as { replyId: string }
  const { data, status } = useManualStepsReply(replyId)

  if (status === 'pending' || status === 'error') {
    return <SingleStepSkeleton />
  }

  return (
    <section key={replyId}>
      <SingleEmailHandler data={data.data} />
    </section>
  )
}

type SingleEmailHandlerProps = {
  data: ManualStepReplyInfo
}

function SingleEmailHandler({
  data: { emails, draft, id, contact, nextMessageId, prevMessageId },
}: SingleEmailHandlerProps) {
  const [focusedId, setFocusedId] = useState<string | null>(draft ? null : emails[emails.length - 1].sentAt)
  const navigate = useNavigate()
  const toast = useToast()
  const sendEmail = useManualStepsReplySend(id)
  const createDraft = useManualStepsReplyDraftCreate()
  const deleteDraft = useManualStepsReplyDelete(id)
  const doneEmail = useManualStepsReplyDone()
  const { open, close, isOpen } = useDisclosure(false)
  const replyEmail = emails[0]

  const onSend = () => {
    if (draft && draft.to.length === 0 && draft.cc.length === 0 && draft.bcc.length === 0) {
      open()
      return
    }
    sendEmail.mutate(undefined, {
      onSuccess: () => {
        toast.createToast({ message: `Reply Sent` })
        if (nextMessageId) {
          navigate(`/inbox/replies/${nextMessageId}`)
        } else if (prevMessageId) {
          navigate(`/inbox/replies/${prevMessageId}`)
        } else {
          navigate('/inbox/replies/')
        }
      },
      onError: (error) => {
        toast.createToast({ message: (error as any)?.body?.message || 'Failed to send reply', error: true })
      },
    })
  }

  const onDone = () => {
    doneEmail.mutate(id, {
      onSuccess: () => {
        toast.createToast({ message: `Reply Done` })
        if (nextMessageId) {
          navigate(`/inbox/replies/${nextMessageId}`)
        } else if (prevMessageId) {
          navigate(`/inbox/replies/${prevMessageId}`)
        } else {
          navigate('/inbox/replies/')
        }
      },
      onError: (error) => {
        toast.createToast({ message: (error as any)?.body?.message || 'Failed to mark reply done', error: true })
      },
    })
  }

  const onDraftCreate = () => {
    createDraft.mutate(id, {
      onSuccess: () => {
        setFocusedId(null)
      },
      onError: (error) => {
        toast.createToast({ message: (error as any)?.body?.message || 'Failed to create draft', error: true })
      },
    })
  }

  const handleDeleteDraft = () => {
    deleteDraft.mutate(undefined, {
      onSuccess: () => {
        setFocusedId(emails[emails.length - 1].sentAt)
      },
      onError: (error) => {
        toast.createToast({ message: (error as any)?.body?.message || 'Failed to delete draft', error: true })
      },
    })
  }

  const handleReplyClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation()
    onDraftCreate()
  }

  return (
    <SingleStep>
      <Dialog open={isOpen} onClose={() => close()}>
        <Dialog.Panel className="flex max-w-80 flex-col gap-4">
          <Text variant="subtitle">Add at least one recipient</Text>
          <div className="flex flex-row justify-end">
            <Button variant="accent" onClick={() => close()}>
              Ok
            </Button>
          </div>
        </Dialog.Panel>
      </Dialog>
      <SingleStep.Navigation prev={prevMessageId} next={nextMessageId} url="/inbox/replies/" />

      <SingleStep.Main>
        <div className="flex flex-row pb-6">
          <Text variant="title">{replyEmail.subject}</Text>
          <Button
            onClick={onDone}
            variant="icon"
            icon={Check}
            className="ml-auto rounded-lg border"
            iconclassname="h-6 w-6 m-2"
          />
        </div>

        <div className="flex flex-col gap-4">
          {emails.map((message, index) =>
            message.sentAt === focusedId ? (
              <ReplyThreadFocused
                reply={message}
                key={message.sentAt}
                setFocused={() => setFocusedId(null)}
                getDisplayName={(email) => getDisplayName(email, contact)}
                {...(index === emails.length - 1 &&
                  !draft && {
                    draftBtn: (
                      <Button onClick={handleReplyClick} className="flex w-fit items-center gap-2 pt-2">
                        <ArrowBendUpLeft className="h-4 w-4 text-medium" />
                        Reply
                      </Button>
                    ),
                  })}
              />
            ) : (
              <ReplyThread
                reply={message}
                key={message.sentAt}
                setFocused={() => setFocusedId(message.sentAt)}
                getDisplayName={(email) => getDisplayName(email, contact)}
              />
            ),
          )}
          {draft && (
            <Editor
              variables={{}}
              replyId={id}
              body={draft.body}
              onSend={onSend}
              onDelete={handleDeleteDraft}
              subject={replyEmail.subject}
              to={draft.to}
              cc={draft.cc}
              bcc={draft.bcc}
            />
          )}
        </div>
      </SingleStep.Main>
      {contact ? <ContactInformation contactId={contact?.id} /> : <div />}
    </SingleStep>
  )
}
