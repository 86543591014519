import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { SettingsFlow, UiNodeInputAttributes, UpdateSettingsFlowBody } from '@ory/client'
import { kratos } from '@/config/kratos'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { reportSentryErrorWithEmail } from '@/sentry'
import { Input, Text } from '@/ui'
import { Button } from '@/ui/Button/v2'
import { useCreateSettingsFlow } from '../hooks/useCreateSettingsFlow'
import { useGetSettingsFlow } from '../hooks/useGetSettingsFlow'
import { ResetPasswordModal } from './ResetPasswordModal'

type formBody = {
  email: string
  firstName: string
  lastName: string
  company: string
}

export function Profile() {
  const toast = useToast()
  const navigate = useNavigate()
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email is invalid'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
  })

  const methods = useForm<formBody>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema) as any,
  })

  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false)
  const [flow, setFlow] = useState<SettingsFlow | null>(null)
  const [searchParams] = useSearchParams()
  const createFlow = useCreateSettingsFlow({ setFlow: setFlow, reset: methods.reset })
  const getFlow = useGetSettingsFlow({ setFlow: setFlow, reset: methods.reset })

  useEffect(() => {
    const flowId = searchParams.get('flow')
    if (flowId) {
      getFlow(flowId).catch(createFlow)
      return
    }
    createFlow()
  }, [])

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = methods

  function saveInKratos(body: UpdateSettingsFlowBody, successMsg: string) {
    if (!flow) return

    kratos
      .updateSettingsFlow({ flow: flow.id, updateSettingsFlowBody: body })
      .then(({ data: flow }) => {
        setFlow(flow)
        toast.createToast({ message: successMsg })
        setOpenResetPasswordModal(false)
      })
      .catch((error) => {
        reportSentryErrorWithEmail('[KRATOS] Settings submitSelfServiceSettingsFlow failed', {
          extra: {
            error,
          },
        })
        switch (error.response.status) {
          case 400:
            setFlow(error.response.data)
            toast.createToast({ message: 'Unable to save', error: true })
            break
          case 403:
            toast.createToast({ message: error.response.data.error.reason, error: true })
            break
          case 401 | 410:
            return navigate('/settings', { replace: true })
          case 404:
          default:
            setFlow(error.response.data)
        }
      })
  }

  const onSubmit = (data: formBody) => {
    const flowBody = {
      csrf_token: (flow?.ui.nodes[0].attributes as UiNodeInputAttributes).value,
      method: 'profile',
      traits: {
        company_name: data.company,
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
      },
    } as UpdateSettingsFlowBody

    saveInKratos(flowBody, 'Profile settings updated.')
  }

  const onSubmitPassword = (newPassword: string) => {
    const flowBody = {
      csrf_token: (flow?.ui.nodes[0].attributes as UiNodeInputAttributes).value,
      method: 'password',
      password: newPassword,
    } as UpdateSettingsFlowBody

    if (!flow) return

    saveInKratos(flowBody, 'Password updated.')
  }

  return (
    <section>
      <Text variant="title" className="pb-5">
        Profile
      </Text>
      <FormProvider {...methods}>
        <form className="w-60" onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="First name"
            containerClassName="py-3"
            registration={register('firstName')}
            error={errors['firstName']?.message}
          />
          <Input
            label="Last name"
            containerClassName="py-3"
            registration={register('lastName')}
            error={errors['lastName']?.message}
          />
          <Input
            label="Email"
            type="email"
            containerClassName="py-3"
            registration={register('email')}
            error={errors['email']?.message}
          />

          <Button type="submit" variant="accent" className="">
            Save
          </Button>
        </form>
      </FormProvider>
      <ResetPasswordModal
        savePassword={onSubmitPassword}
        isOpen={openResetPasswordModal}
        setIsOpen={(val) => setOpenResetPasswordModal(val)}
      />
      <Button variant="text" className="mt-6 p-0" onClick={() => setOpenResetPasswordModal(true)}>
        Change password
      </Button>
    </section>
  )
}
