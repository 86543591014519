import { ReactNode, useEffect, useState } from 'react'
import { WorkspaceViews } from '@/api/core'
import { useWorkspaceViews } from '@/api/workspace'
import { kratos } from '@/config/kratos'
import { UserContext, UserStatus, UserType } from '@/providers/User/context'

export function UserProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<UserStatus>({ type: UserType.Unknown, value: undefined })
  const [displayComponents, setDisplayComponents] = useState<WorkspaceViews>([])
  const workspaceViews = useWorkspaceViews({ enabled: !!user.value })
  const initialize = async () => {
    try {
      setUser({ type: UserType.Unknown, value: undefined })

      const session = await kratos.toSession()
      const id = session.data.identity
      if (!id) {
        throw new Error('No active session')
      }
      const meta = id.metadata_public as any | null
      setUser({
        type: UserType.Authenticated,
        value: { id: id.id, traits: id.traits, metadata_public: { show_feature_flag: meta?.show_feature_flag } },
      })
    } catch (error) {
      setUser({ type: UserType.Public, value: undefined })
    }
  }

  const makePublic = () => setUser({ type: UserType.Public, value: undefined })

  useEffect(() => {
    initialize()
  }, [])

  useEffect(() => {
    if (workspaceViews.data) {
      setDisplayComponents(workspaceViews.data.data)
    }
  }, [workspaceViews.data])

  return (
    <UserContext.Provider value={{ initialize, user, permissions: displayComponents, makePublic }}>
      {children}
    </UserContext.Provider>
  )
}
