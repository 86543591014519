import React from 'react'
import { useLatestValue } from './useLatestValue'

// A Hook to define an event handler with an always-stable function identity.
// The code inside useEvent "sees" the props/state values at the time of the call.
// The returned function has a stable identity even if the props/state it references change.
// There is no dependency array.
// see: https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
export function useEvent<F extends (...args: any[]) => any, P extends any[] = Parameters<F>, R = ReturnType<F>>(
  cb: (...args: P) => R,
) {
  const cache = useLatestValue(cb)
  return React.useCallback((...args: P) => cache.current(...args), [cache])
}
