export function pluralize(count: number, noun: string, suffix = 's', showCount = true) {
  return `${showCount ? count : ''} ${noun}${count !== 1 ? suffix : ''}`
}

export function possessive(s: string) {
  const APOSTROPHE_CHAR = '’'
  if (s === '') {
    return s
  }
  const lastChar = s.slice(-1)
  const endOfWord = lastChar.toLowerCase() === 's' ? APOSTROPHE_CHAR : `${APOSTROPHE_CHAR}s`

  return `${s}${endOfWord}`
}

export function findBestMatch<T extends string, S extends string>(knownField: S, possibleMatches: T[]) {
  const ratings = []
  let bestMatchIndex = 0

  for (let i = 0; i < possibleMatches.length; i++) {
    const currentPossibleMatchString = possibleMatches[i]
    const currentRating = compareTwoStrings(knownField, currentPossibleMatchString)
    ratings.push({ target: currentPossibleMatchString, rating: currentRating })
    if (currentRating > ratings[bestMatchIndex].rating) {
      bestMatchIndex = i
    }
  }

  const bestMatch = ratings[bestMatchIndex]
  return { ratings: ratings, bestMatch: bestMatch }
}

export function compareTwoStrings(first: string, second: string) {
  first = first.replace(/\s+/g, '')
  second = second.replace(/\s+/g, '')

  if (first === second) return 1 // identical or empty
  if (first.length < 2 || second.length < 2) return 0 // if either is a 0-letter or 1-letter string

  const firstBigrams = new Map()
  for (let i = 0; i < first.length - 1; i++) {
    const bigram = first.substring(i, i + 2)
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1
    firstBigrams.set(bigram, count)
  }

  let intersectionSize = 0
  for (let i = 0; i < second.length - 1; i++) {
    const bigram = second.substring(i, i + 2)
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0

    if (count > 0) {
      firstBigrams.set(bigram, count - 1)
      intersectionSize++
    }
  }

  return (2.0 * intersectionSize) / (first.length + second.length - 2)
}
