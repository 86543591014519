import { useSalesforceConnection, useSalesforceConnectionUpdate } from '@/api'
import { FeatureFlag } from '@/containers/FeatureFlag'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Text } from '@/ui'
import { SalesforceSynchronizationSettings } from './SynchronizationSettings'

export function SalesforceIntegrationDetails() {
  const connection = useSalesforceConnection()
  const toast = useToast()
  const connectionUpdate = useSalesforceConnectionUpdate()
  const data = connection.data?.data
  if (connection.status === 'pending' || data === undefined) {
    return <SalesforceIntegrationDetailsSkeleton />
  }

  const onRiftToCrmChange = (isEnabled: boolean) => {
    connectionUpdate.mutate(
      { isRiftToCrmEnabled: isEnabled },
      {
        onSuccess: () => {
          toast.createToast({ message: 'Rift to CRM ' + (isEnabled ? 'enabled' : 'disabled') })
        },
      },
    )
  }

  const onCrmToRiftChange = (isEnabled: boolean) => {
    connectionUpdate.mutate(
      { isCrmToRiftEnabled: isEnabled },
      {
        onSuccess: () => {
          toast.createToast({ message: 'CRM to Rift ' + (isEnabled ? 'enabled' : 'disabled') })
        },
      },
    )
  }

  const onInteractionHistoryChange = (isEnabled: boolean) => {
    connectionUpdate.mutate(
      { isInteractionHistoryEnabled: isEnabled },
      {
        onSuccess: () => {
          toast.createToast({ message: 'Interaction history ' + (isEnabled ? 'enabled' : 'disabled') })
        },
      },
    )
  }

  return (
    <>
      <SalesforceSynchronizationSettings
        withSubpages
        riftToCrmEnabled={data.stageMappings.riftToCrm.isEnabled}
        onRiftToCrmChange={onRiftToCrmChange}
        crmToRiftEnabled={data.stageMappings.crmToRift.isEnabled}
        onCrmToRiftChange={onCrmToRiftChange}
        interactionHistoryEnabled={data.isInteractionHistoryEnabled}
        onInteractionHistoryChange={onInteractionHistoryChange}
      />
      <FeatureFlag>
        <Text variant="subtitle" className="pt-5">
          Details
        </Text>
        <div>Synchronization stage: {data.synchronizationStage}</div>
        Last modification sync:
        <div className="pl-2">Rift to SF: {data.stageMappings.riftToCrm.lastSyncAt}</div>
        <div className="pl-2">SF to Rift: {data.stageMappings.crmToRift.lastSyncAt}</div>
      </FeatureFlag>
    </>
  )
}

function SalesforceIntegrationDetailsSkeleton() {
  return (
    <div>
      <div className="skeleton m-auto h-[200px] w-full max-w-2xl"></div>
    </div>
  )
}
