import React from 'react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { ArrowRight } from '@phosphor-icons/react'
import { Dropdown, DropdownOption } from '@/components/Dropdown'
import { Checkbox, Input } from '@/ui'
import { Button } from '@/ui/Button/v2'
import { ErrorText } from '@/ui/Text/Error'
import { Action, ContactObject, errorMessage, header, isInvalid, State, stdHeadersToSelect } from '../reducer'
import { CancelButton } from './CancelButton'

export type MappingProps = {
  onCancel: () => void
  onImport: () => void
  state: State
  dispatch: React.Dispatch<Action>
}

// MappingV2 is a copy-pasted version of the Mapping with additional changes to the UX for
// importing. It is currently behind a feature flag and is not yet rolled out to all users.
export function MappingV2(props: MappingProps) {
  const { onCancel, onImport, state, dispatch } = props

  const errorMsg = errorMessage(state)
  return (
    <>
      <div className="my-6 grid grid-cols-[minmax(auto,246px)_20px_minmax(auto,246px)] gap-5 overflow-auto rounded-lg border border-light bg-white p-6">
        <div className="text-sm font-medium text-dark">Columns in file</div>
        <div></div>
        <div className="text-sm font-medium text-dark">Fields in rift</div>
        {state.headers.map((heading, index: number) => (
          <React.Fragment key={index}>
            <label
              className="flex items-center gap-x-4 overflow-hidden text-sm leading-5"
              htmlFor={`skip-field-${heading.from}`}
            >
              <Checkbox
                checked={heading.type !== 'skip'}
                onChange={() => dispatch({ type: 'toggle_skip_header', headerNo: index })}
                aria-label={`skip field ${heading.from}`}
                id={`skip-field-${heading.from}`}
              />
              <div className="flex flex-col">
                <div>{heading.from}</div>
                <div className="text-medium" title={state.contacts?.[0]?.data[heading.from] ?? ''}>
                  {state.contacts?.[0]?.data[heading.from] ?? ''}
                </div>
              </div>
            </label>
            <div className="flex items-center text-medium">
              {heading.type === 'skip' ? null : <ArrowRight height={15} width={15} />}
            </div>
            <AttributesRow
              key={index}
              selectable={stdHeadersToSelect(state)}
              header={heading}
              setCustomHeader={(toHeader: string) => {
                dispatch({
                  type: 'custom_header',
                  headerNo: index,
                  toHeader,
                })
              }}
              setStandardHeader={(toHeader: keyof ContactObject) => {
                dispatch({
                  type: 'map_header',
                  headerNo: index,
                  toHeader,
                })
              }}
            />
          </React.Fragment>
        ))}
      </div>
      {errorMsg && (
        <section className="mt-4 flex justify-between">
          <ErrorText errorMessage={errorMsg} />
        </section>
      )}
      <section className="mt-4 flex justify-between">
        <CancelButton onCancel={onCancel} />
        <Button disabled={!!errorMsg} variant="accent" onClick={() => onImport()}>
          Continue
        </Button>
      </section>
    </>
  )
}

const defaultOptions = [{ label: 'Custom field', value: '' }]

type AttributesRowProps = {
  selectable: Array<keyof ContactObject>
  header: header
  setCustomHeader: (toHeader: string) => void
  setStandardHeader: (toHeader: keyof ContactObject) => void
}

export function AttributesRow(props: AttributesRowProps) {
  const { selectable, header, setCustomHeader, setStandardHeader } = props

  const options = [...selectable.map((s) => ({ label: s, value: s })), ...defaultOptions]
  const label = header.type === 'standard' ? header.to : header.type === 'custom' ? 'Custom field' : 'Skip field'
  const value = { label: label, value: header.type === 'skip' ? undefined : header.to }

  function handleSelection(selected: DropdownOption) {
    if (selected.label === 'Custom field') {
      setCustomHeader(props.header.from)
    } else {
      setStandardHeader(selected.id as keyof ContactObject)
    }
  }

  return (
    <div className={clsx(header.type === 'skip' && 'invisible')}>
      <Dropdown
        selected={{ id: value.value as string, label: value.label as string }}
        handleSelection={handleSelection}
        options={options.map((option) => ({ id: option.value as string, label: option.label }))}
        ariaLabel={`${header.from} assigned to`}
        className="static"
      />
      {value.label === 'Custom field' && (
        <Input
          placeholder="Field name"
          className={twMerge('mt-4 h-9 w-full border border-gray-300 px-2 py-2', isInvalid(header) && 'border-alert')}
          value={value.value || ''}
          aria-label={`${header.from} assigned to custom field`}
          onChange={(e) => setCustomHeader(e.target.value)}
        />
      )}
    </div>
  )
}
