import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { ArrowRight } from '@phosphor-icons/react'
import { useHubspotStatus } from '@/api/hubspot'
import { HubspotIntegrationStatusToColor, HubspotIntegrationStatusToText } from '@/api/text/hubspot'
import { HubspotLogo } from '@/icons/HubspotLogo'
import { Card, Text } from '@/ui'

export function HubspotIntegrationCard() {
  const statusQuery = useHubspotStatus()
  const navigate = useNavigate()
  const onOpenSettings = () => navigate('/settings/integrations/hubspot')

  if (statusQuery.status === 'pending') {
    return (
      <Card>
        <div className="skeleton h-14 w-full" />
      </Card>
    )
  }

  if (statusQuery.status === 'error') {
    return null
  }

  const status = statusQuery.data.data
  const permissions = statusQuery.data.permissions

  return (
    <Card
      className={clsx(
        'mb-3 flex items-center rounded-lg',
        !permissions.view.deny && 'group transition-colors duration-150 hover:bg-[#36486408]',
      )}
      onClick={() => {
        if (!permissions.view.deny) onOpenSettings()
      }}
    >
      <div className="flex size-12 items-center justify-center">
        <HubspotLogo className="size-10 max-w-none" />
      </div>
      <div className="grow pl-4">
        <Text className="font-medium">Hubspot</Text>
        <Text variant="subtext" className={clsx(HubspotIntegrationStatusToColor[status.integrationStatus])}>
          {HubspotIntegrationStatusToText[status.integrationStatus]}
        </Text>
        {permissions.view.deny && (
          <Text variant="subtext" className="text-dusk">
            Contact your admin to sync your rift contacts with Hubspot
          </Text>
        )}
      </div>

      {!permissions.edit.deny && (
        <ArrowRight className="size-5 text-medium opacity-0 transition-opacity duration-100 group-hover:opacity-100" />
      )}
    </Card>
  )
}
