import clsx from 'clsx'
import { Checkbox, CheckboxProps } from '@headlessui/react'

export function IndeterminateCheckbox<TType = string>(props: CheckboxProps<any, TType>) {
  const { className = '', ...rest } = props
  return (
    <Checkbox
      {...rest}
      className={clsx(
        // base
        'background-origin-border group flex size-4.5 flex-shrink-0 cursor-pointer select-none appearance-none rounded border border-light bg-white',

        // transition
        'transition-all duration-100 ease-in',

        // checked
        'data-[checked]:bg-accent',

        // indeterminate
        'data-[indeterminate]:bg-accent',

        // active
        'active:duration-200 active:ease-out',

        // disabled
        'disabled:pointer-events-none disabled:opacity-30',

        className,
      )}
    >
      {({ checked, indeterminate }) => (
        <>
          {indeterminate && !checked && (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="white">
              <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
            </svg>
          )}
          {!indeterminate && checked && (
            <svg viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z" />
            </svg>
          )}
        </>
      )}
    </Checkbox>
  )
}
