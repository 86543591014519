import clsx from 'clsx'
import { SequenceSendMode } from '@/api/core'
import { SequenceSendModeLabels } from '@/api/text/manual_step'
import { useUserPermissions } from '@/containers/ComponentDisplay'
import { Select, Text } from '@/ui'

type ModeSelectorProps = {
  disabledSelect?: boolean
  mode: SequenceSendMode
  onChange: (mode: SequenceSendMode) => void
  className?: string
  stepNumber: number
}

export function ModeSelector({ mode, onChange, disabledSelect, className, stepNumber }: ModeSelectorProps) {
  const { showDialer } = useUserPermissions()

  const value = SequenceSendModeLabels.find((label) => label.value === mode)!

  // TODO: remove when we release dialer & linkedin messages
  const options = showDialer
    ? SequenceSendModeLabels
    : SequenceSendModeLabels.filter((mode) => mode.value !== SequenceSendMode.MANUAL_CALL)

  return (
    <section
      className={clsx(
        'relative mt-2 flex items-center rounded border border-b-0 border-light bg-white px-8 py-6',
        className,
      )}
    >
      <Text variant="text" className="text-dusk">
        Step {stepNumber}
      </Text>
      <span className="relative px-3">
        {disabledSelect ? (
          <Text variant="text" className="text-medium">
            {value.label}
          </Text>
        ) : (
          <Select options={options} value={value} onChange={(mode) => onChange(mode.value)} />
        )}
      </span>
    </section>
  )
}
