import { CSSProperties } from 'react'
import { Controller, FieldError } from 'react-hook-form'
import ReactTimezoneSelect, { allTimezones } from 'react-timezone-select'
import { FieldWrapper, FieldWrapperPassThroughProps } from '@/ui/FieldWrapper'

type CustomStyles = {
  disabledBackgroundColor?: string
}

type InputFieldProps = FieldWrapperPassThroughProps & {
  labelClassName?: string
  name: string
  control: any
  error?: FieldError | FieldError[] | string | undefined
  required?: boolean
  onChange?: (timezone: string) => void
  disabled?: boolean
  styling?: CustomStyles
  controlStyles?: CSSProperties
}

export function TimezoneSelect(props: InputFieldProps) {
  const { label, name, control, error, required, onChange, disabled } = props

  return (
    <FieldWrapper label={label} name={name} error={error} required={required} className={props.labelClassName}>
      <Controller
        control={control}
        name={name}
        defaultValue={Intl.DateTimeFormat().resolvedOptions().timeZone}
        render={({ field }) => (
          <ReactTimezoneSelect
            isDisabled={disabled}
            value={field.value}
            onChange={(e) => {
              field.onChange(e.value)
              onChange?.(e.value)
            }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                border: state.isFocused ? '0' : '1',
                borderColor: state.isFocused ? '#364864' : '#d1d5db',
                borderRadius: '0',
                cursor: disabled ? 'not-allowed' : 'pointer',
                color: '#364864',
                backgroundColor: disabled
                  ? props.styling?.disabledBackgroundColor ?? baseStyles.backgroundColor
                  : baseStyles.backgroundColor,
                ...props.controlStyles,
              }),
              menuList: (baseStyles) => ({
                ...baseStyles,
                zIndex: 10,
              }),
              menu: (baseStyles) => ({
                ...baseStyles,
                zIndex: 10,
              }),
              singleValue: (baseStyles) => ({
                ...baseStyles,
                color: '#364864',
              }),
            }}
            labelStyle="altName"
            timezones={{
              ...allTimezones,
            }}
          />
        )}
      />
    </FieldWrapper>
  )
}
