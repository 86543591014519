import { DateRange } from 'react-day-picker'
import { getUnixTime } from 'date-fns'
import { useCampaignEstimatedEndDate } from '@/api/campaigns'
import { Campaign } from '@/api/core'
import { useSequenceAnalyticsContext } from '@/providers/Sequences/SequenceAnalyticsContext'
import { RichDateRangePicker } from '@/ui'

export function AllTimeSelector({ campaign }: { campaign: Campaign }) {
  const { data: estimatedEndDateData } = useCampaignEstimatedEndDate(campaign.id)
  const { dispatch } = useSequenceAnalyticsContext()

  const setRange = (range: DateRange | undefined) => {
    // all time
    if (range === undefined) {
      if (!estimatedEndDateData?.data.est_end_date) return
      dispatch({ type: 'change_from_date', body: { date: getUnixTime(new Date(campaign.start_date)) } })
      dispatch({
        type: 'change_to_date',
        body: { date: getUnixTime(estimatedEndDateData.data.est_end_date * 1000) },
      })
      return
    }

    if (range?.from || range?.to) {
      dispatch({
        type: 'change_from_date',
        body: { date: range?.from ? getUnixTime(range?.from) : undefined },
      })
      dispatch({
        type: 'change_to_date',
        body: { date: range?.to ? getUnixTime(range?.to) : undefined },
      })
    }
  }

  return (
    <RichDateRangePicker
      onRangeChange={setRange}
      options={[
        { label: 'All time', allTime: true },
        { label: 'Last hour', hours: -1 },
        { label: 'Last day', days: -1 },
        { label: 'Last 7 days', days: -7 },
        { label: 'Last 14 days', days: -14 },
        { label: 'Last 30 days', days: -30 },
      ]}
    />
  )
}
