import { createContext, MutableRefObject, useContext } from 'react'
import { State } from './reducer'

export const StepsActionsContext = createContext<{
  registerStep(step: MutableRefObject<HTMLElement | null>): () => void
  registerPanel(panel: MutableRefObject<HTMLElement | null>): () => void
  change(index: number): void
} | null>(null)
StepsActionsContext.displayName = 'StepsActionsContext'

export function useActions(component: string) {
  const context = useContext(StepsActionsContext)
  if (context === null) {
    const err = new Error(`<${component} /> is missing a parent <Step.Group /> component.`)
    if (Error.captureStackTrace) Error.captureStackTrace(err, useActions)
    throw err
  }
  return context
}

export const StepsDataContext = createContext<State | null>(null)
StepsDataContext.displayName = 'StepsDataContext'

export function useData(component: string) {
  const context = useContext(StepsDataContext)
  if (context === null) {
    const err = new Error(`<${component} /> is missing a parent <Step.Group /> component.`)
    if (Error.captureStackTrace) Error.captureStackTrace(err, useData)
    throw err
  }
  return context
}
