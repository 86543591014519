import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { MeetingType, Signature } from '@/api/core'
import { useUpdateSignature } from '@/api/signatures'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, ButtonLoading } from '@/ui/Button/v2'
import { EmailEditor } from '@/ui/EmailEditor/EmailEditor'
import { Toolbar } from '@/ui/EmailEditor/styled'
import { Input } from '@/ui/Input/v2'

type SignatureFormProps = {
  initialData: Signature
  calendarLinks: MeetingType[]
  cancelEditing: () => void
}

export function SignatureForm(props: SignatureFormProps) {
  const { initialData, cancelEditing, calendarLinks } = props
  const signatureUpdate = useUpdateSignature()
  const toast = useToast()

  const schema = yup.object().shape({
    name: yup.string().required('Signature name required'),
    body: yup.string().required('Signature content required'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<Signature>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      name: initialData.name,
      body: initialData.body,
      id: initialData.id,
    },
  })

  function onSubmit(form: Signature) {
    signatureUpdate.mutate(form, {
      onError: () => toast.createToast({ message: 'Failed to update signature', error: true }),
      onSuccess: () => {
        cancelEditing()
        toast.createToast({ message: 'Signature successfully updated' })
      },
    })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register('id')} />
      <div className="flex flex-col items-center justify-end">
        <div className="w-full">
          <Input
            name="name"
            id="name"
            type="text"
            label="Title"
            placeholder="Title"
            error={errors['name']}
            registration={register('name')}
          />
        </div>
        <div className="mt-6 w-full">
          <EmailEditor
            calendarLinks={calendarLinks}
            as="div"
            className="flex min-h-48 flex-col justify-between gap-4 rounded-lg border border-light px-4 pb-2 pt-4"
          >
            {({ preview }) => (
              <>
                <EmailEditor.Body content={getValues('body')} onChange={(value) => setValue('body', value)} />
                {preview && <EmailEditor.PreviewBody />}
                <Toolbar>
                  <Toolbar.Light />
                </Toolbar>
              </>
            )}
          </EmailEditor>
        </div>
      </div>
      <div className="box-border flex w-full justify-end gap-2 bg-white py-4">
        <Button type="button" onClick={cancelEditing} variant="basic">
          Cancel
        </Button>
        <ButtonLoading type="submit" variant="accent" isLoading={signatureUpdate.isPending}>
          Update
        </ButtonLoading>
      </div>
    </form>
  )
}
