import { createContext, PropsWithChildren, useContext } from 'react'
import { twMerge } from 'tailwind-merge'
import { X } from '@phosphor-icons/react/dist/ssr'
import { Text } from '@/ui'

// Banner styling variant hook
type styles = { container: string; title: string }

type BannerProps = {
  className?: string
  severity: 'info' | 'warning' | 'error'
  onClose?: () => void
}

const BannerContext = createContext<styles | null>(null)

function Banner({ className, severity, children, onClose }: PropsWithChildren<BannerProps>) {
  const styling = useBannerVariant(severity)

  return (
    <BannerContext.Provider value={styling}>
      <div className={twMerge(`relative flex gap-2 rounded-lg px-4 py-3`, styling.container, className)}>
        <div className="grow">{children}</div>
        {onClose && <X className="h-5 w-5" onClick={onClose} />}
      </div>
    </BannerContext.Provider>
  )
}

function Title({ children }: PropsWithChildren) {
  const styling = useBannerContext()
  return (
    <Text variant="subtitle" className={styling.title}>
      {children}
    </Text>
  )
}

// Banner context
function useBannerContext() {
  const context = useContext(BannerContext)

  if (!context) {
    throw new Error('Child components of Banner cannot be rendered outside the Banner component!')
  }

  return context
}

function useBannerVariant(variant: 'info' | 'warning' | 'error') {
  const variants: { [key: string]: styles } = {
    error: {
      container: 'text-[#601502] bg-[#FFAF96]',
      title: 'text-[#3B0C00]',
    },
    info: {
      container: 'text-dusk bg-light',
      title: 'text-dark',
    },
    warning: {
      container: 'text-[#853E00] bg-[#FFCD7C]',
      title: 'text-[#592700]',
    },
  }

  return variants[variant]
}

export { Banner }
Banner.Title = Title
