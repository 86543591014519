import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoginFlow, UiNode, UpdateLoginFlowBody } from '@ory/client'
import { kratos } from '@/config/kratos'
import { useSCRFToken } from '@/hooks/useCSRFToken'
import { useOryErrors } from '@/hooks/useOryErrors'
import { GoogleLogo } from '@/icons/GoogleLogo'
import { RiftLogo } from '@/icons/RiftLogo'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { useUserContext } from '@/providers/User/context'
import { reportSentryErrorWithEmail } from '@/sentry'
import { Button } from '@/ui/Button/v2'
import { Input } from '@/ui/Input/v2'
import { Text } from '@/ui/Text'
import { ErrorText } from '@/ui/Text/Error'
import { AgreementFooter } from '../Layout/PublicPage'

export function Login() {
  const [flow, setFlow] = useState<LoginFlow | null>(null)
  const [searchParams] = useSearchParams()
  const [totpFlow, setTotpFlow] = useState<boolean>(false)
  const { initialize } = useUserContext()
  const { setCSRFToken, csrfToken } = useSCRFToken()
  const aal = searchParams.get('aal')

  const createFlow = useCallback(() => {
    return kratos
      .createBrowserLoginFlow({
        refresh: true,
        aal: aal || '',
      })
      .then(({ data: flow }) => {
        setFlow(flow)
        setCSRFToken(flow.ui.nodes)
        setTotpFlow(flow.ui.nodes.find((node) => node.group === 'totp') !== undefined)
      })
      .catch((error) => {
        reportSentryErrorWithEmail('[KRATOS] Login initializeSelfServiceLoginFlowForBrowsers failed', {
          extra: {
            error,
          },
        })
        setFlow(error.response.data)
      })
  }, [aal])

  const getFlow = useCallback(
    (flowId: string) =>
      kratos
        .getLoginFlow({ id: flowId })
        .then(({ data: flow }) => {
          setFlow(flow)
          setCSRFToken(flow.ui.nodes)
        })
        .catch((error) => {
          reportSentryErrorWithEmail('[KRATOS] Login getSelfServiceLoginFlow failed', {
            extra: {
              error,
            },
          })
          return error
        }),
    [],
  )

  useEffect(() => {
    const flowId = searchParams.get('flow')
    if (flowId) {
      getFlow(flowId).catch(createFlow)
    } else {
      createFlow()
    }
  }, [aal])

  if (!flow || !csrfToken) {
    return <></>
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center gap-6 sm:px-6 lg:px-8">
      <hgroup className="text-center sm:mx-auto sm:w-full sm:max-w-md">
        <RiftLogo className="m-auto" simple={false} width={60} />
        <Text variant="title" className="">
          Login to your account
        </Text>
      </hgroup>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          {totpFlow ? (
            <TOTP flow={flow} csrfToken={csrfToken} initialize={initialize} />
          ) : (
            <>
              <PasswordLogin
                initialize={initialize}
                flow={flow}
                csrfToken={csrfToken}
                setCSRFToken={setCSRFToken}
                setFlow={setFlow}
              />
              <section>
                <div className="relative mt-10">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <Text variant="subtext" className="relative flex justify-center font-medium leading-6">
                    <span className="bg-white px-6">or continue with</span>
                  </Text>
                </div>
                <GoogleForm flow={flow} setFlow={setFlow} />
              </section>
            </>
          )}
        </div>
        {!totpFlow && (
          <Text variant="subtext" className="pt-4 text-center">
            {`Don't have an account? `}
            <a href="/registration" className=" text-accent">
              Sign up
            </a>
          </Text>
        )}
      </div>
      <AgreementFooter />
    </div>
  )
}

function TOTP({ flow, csrfToken, initialize }: { flow: LoginFlow; csrfToken: string; initialize: () => void }) {
  const navigate = useNavigate()
  const validationSchema = Yup.object()
    .shape({
      totp: Yup.string()
        .length(6, 'TOTP length is 6 characters')
        .max(6, 'Maximum totp length is 6 characters')
        .default(''),
      passwrod: Yup.string().default(''),
    })
    .required()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) })

  const onSubmit = async ({ totp }: { totp: string }) => {
    const sendingValue = {
      method: 'totp',
      csrf_token: csrfToken as string,
      totp_code: totp,
    } as UpdateLoginFlowBody
    kratos
      .updateLoginFlow({ flow: flow?.id, updateLoginFlowBody: sendingValue })
      .then(() => {
        initialize()
        navigate('/', { replace: true })
      })
      .catch((error) => {
        reportSentryErrorWithEmail('[KRATOS] Login to application failed', {
          extra: {
            error,
          },
        })
        return navigate('/login', { replace: true })
      })
  }

  return (
    <form className="space-y-5" action="#" method="POST" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Input
          name="totp"
          id="totp"
          type="text"
          label="Authenticator code"
          boldLabel
          required
          error={errors['totp']?.message}
          registration={register('totp')}
        />
      </div>
      <Button type="submit" variant="accent" className="w-full">
        Sign in
      </Button>
    </form>
  )
}

type PasswordLoginProps = {
  initialize: () => void
  flow: LoginFlow | null
  csrfToken: string | null
  setCSRFToken: (nodes: UiNode[]) => void
  setFlow: (flow: LoginFlow | null) => void
}
function PasswordLogin({ initialize, flow, csrfToken, setCSRFToken, setFlow }: PasswordLoginProps) {
  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    identifier: Yup.string().required('Email is required').email('Email is invalid'),
    password: Yup.string()
      .min(6, 'Minimum password length is 6 characters')
      .max(72, 'Maximum password length is 72 characters')
      .required('Password is required'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<{ identifier: string; password: string }>({ resolver: yupResolver(validationSchema) })

  const { setErrorsFromNodes, errors: oryErrors } = useOryErrors(
    () => setError('identifier', { type: 'manual', message: ' ' }),
    () => setError('password', { type: 'manual', message: ' ' }),
  )
  const onSubmit = async ({ identifier, password }: { identifier: string; password: string }) => {
    if (!flow) return navigate('/login', { replace: true })

    const token = csrfToken || setCSRFToken(flow.ui.nodes)

    const sendingValue = {
      method: 'password',
      identifier: identifier,
      password: password,
      csrf_token: token as string,
    } as UpdateLoginFlowBody

    kratos
      .updateLoginFlow({ flow: flow?.id, updateLoginFlowBody: sendingValue })
      .then(() => {
        initialize()
        // NOTE: in case that aal1 is enough we will be redirected to app page anyway
        // for now it's used for local testing
        navigate('/login?aal=aal2', { replace: true })
      })
      .catch((error) => {
        reportSentryErrorWithEmail('[KRATOS] Login to application failed', {
          extra: {
            error,
          },
        })
        switch (error.response.status) {
          case 400:
            setErrorsFromNodes(error.response.data.ui.nodes, error.response.data?.ui?.messages)
            setFlow(error.response.data)
            break
          case 422:
            // eslint-disable-next-line no-case-declarations
            navigate('/login?aal=aal2', { replace: true })
            break
          case 410:
          case 404:
          default:
            return navigate('/login', { replace: true })
        }
      })
  }
  return (
    <form className="space-y-5" action="#" method="POST" onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="identifier"
        id="identifier"
        type="email"
        label="Email"
        autoFocus
        boldLabel
        required
        error={errors['identifier']?.message}
        registration={register('identifier')}
        data-testid="email-input"
      />
      <div>
        <Input
          name="password"
          id="password"
          type="password"
          label="Password"
          boldLabel
          required
          error={errors['password']?.message}
          registration={register('password')}
          data-testid="password-input"
        />
        <Text variant="subtext">
          <a href="/recovery" className="text-accent">
            Forgot password?
          </a>
        </Text>
      </div>

      {oryErrors?.map((message) => <ErrorText key={message} errorMessage={message} exclamationIconWidth={32} />)}

      <Button type="submit" variant="accent" className="w-full" data-testid="signin-btn">
        Sign in
      </Button>
    </form>
  )
}

function GoogleForm({ flow, setFlow }: { flow: LoginFlow | null; setFlow: (flow: LoginFlow | null) => void }) {
  const { handleSubmit } = useForm()
  const toast = useToast()
  const { initialize } = useUserContext()
  const navigate = useNavigate()
  const { setCSRFToken } = useSCRFToken()
  const [disabled, setDisabled] = useState(false)

  const onGoogleSubmit = async () => {
    if (!flow) return navigate('/login', { replace: true })

    const token = setCSRFToken(flow.ui.nodes)

    const sendingValue = {
      method: 'oidc',
      provider: 'google',
      csrf_token: token,
    } as UpdateLoginFlowBody

    setDisabled(true)

    kratos
      .updateLoginFlow({ flow: flow?.id, updateLoginFlowBody: sendingValue })
      .then(() => {
        initialize()
        navigate('/', { replace: true })
        setDisabled(false)
      })
      .catch((error) => {
        reportSentryErrorWithEmail('[KRATOS] Login to application failed', {
          extra: {
            error,
          },
        })
        switch (error.response.status) {
          case 422:
            window.location.replace(error.response.data.redirect_browser_to)
            break
          default:
            setDisabled(false)
            toast.createToast({
              error: true,
              message: 'The provided credentials are invalid, check for spelling mistakes in your id or password.',
            })
            setFlow(error.response.data)
            break
        }
      })
  }

  return (
    <form className="mt-6" action="#" method="POST" onSubmit={handleSubmit(onGoogleSubmit)}>
      <Button variant="basic" className="w-full" type="submit" disabled={disabled}>
        <GoogleLogo className="mr-4 h-4 w-4" />
        <span>Sign in with Google</span>
      </Button>
    </form>
  )
}
