type StatisticsProps = {
  title: string
} & (
  | { number: number; pct?: never; precision?: never }
  | { number?: never; pct: number; precision?: number }
  | { number: number; pct: number; precision?: number }
)

export function Statistics(props: StatisticsProps) {
  const { title, number, pct, precision = 2 } = props

  const pctStr = Math.round((pct ?? 0) * 100 * Math.pow(10, precision)) / Math.pow(10, precision) + '%'
  const primary = number !== undefined ? number.toLocaleString('en-US') : pctStr
  const secondary = number !== undefined && pct !== undefined ? pctStr : undefined

  return (
    <div>
      <p className="text-sm leading-5 tracking-wide text-medium">{title}</p>
      <div className="flex items-baseline">
        <p className="text-xl font-medium leading-10 text-dark">{primary}</p>
        {secondary && <p className="ml-1 text-medium">{secondary}</p>}
      </div>
    </div>
  )
}
