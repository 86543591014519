import { ElementType, Ref, useRef } from 'react'
import { Props } from '@headlessui/react/dist/types'
import { useEvent } from '@/hooks/useEvent'
import { useSyncRefs } from '@/hooks/useSyncRefs'
import { uirender } from '@/ui/render'
import { useActions, useData } from './Context'

export const DEFAULT_BACK_TAG = 'button'
export interface BackRenderPropArg {}

export type StepBackProps<TTag extends ElementType> = Props<TTag, BackRenderPropArg>

export function Back<TTag extends ElementType = typeof DEFAULT_BACK_TAG>(
  props: StepBackProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { currentIndex } = useData('Step.Back')
  const actions = useActions('Step.Back')

  const internalBackRef = useRef<HTMLElement | null>(null)
  const nextRef = useSyncRefs(internalBackRef, ref)

  const onBackClick = useEvent(() => {
    if (internalBackRef.current?.hasAttribute('disabled')) return
    actions.change(currentIndex - 1)
  })

  const ourProps = {
    ref: nextRef,
    onClick: onBackClick,
  }

  return uirender({
    ourProps,
    theirProps: props,
    slot: { back: onBackClick },
    defaultTag: DEFAULT_BACK_TAG,
  })
}
