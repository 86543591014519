import React from 'react'
import clsx from 'clsx'
import { Text } from '@/ui/Text'
import './tabs.css'

type TabsProps = {
  tabs: string[]
  selected: string
  setSelected: (tab: string) => void
}

export function Tabs({ tabs, selected, setSelected }: TabsProps) {
  return (
    <div role="tablist" className="flex items-center gap-2 text-dusk">
      {tabs.map((tab, index) => (
        <React.Fragment key={index}>
          <button role="tab" {...(selected === tab && { 'aria-selected': true })} onClick={() => setSelected(tab)}>
            <Text
              className={clsx(
                'flex gap-2 rounded-full px-3 py-1 font-medium',
                selected === tab && 'bg-light text-dark',
              )}
            >
              {tab}
            </Text>
          </button>
        </React.Fragment>
      ))}
    </div>
  )
}
