import { useState } from 'react'
import clsx from 'clsx'
import { useDebounce } from 'use-debounce'
import { Tab } from '@headlessui/react'
import { Passwords } from '@/api/core'
import { usePasswords } from '@/api/vault'
import { Button, Input, Text } from '@/ui'
import { ErrorInfo } from '@/ui/ErrorInfo'
import { ImportPasswordButton } from './ImportPasswordButton'
import { PasswordCreate } from './PasswordCreate'
import { PasswordItem } from './PasswordItem'

export function Vault() {
  const { data, status } = usePasswords()
  if (status === 'pending') {
    return (
      <div className="flex h-screen gap-8">
        <div className="skeleton h-4/5 w-3/12" />
        <div className="skeleton h-4/5 w-9/12" />
      </div>
    )
  }

  if (status === 'error') {
    return <ErrorInfo />
  }

  return <VaultPage passwords={data.data} />
}

type VaultPageProps = {
  passwords: Passwords
}

function VaultPage(props: VaultPageProps) {
  const { passwords } = props
  const [search, setSearch] = useState('')
  const [searchD] = useDebounce(search, 100)
  const items = !searchD
    ? passwords
    : passwords.filter(
        (password) =>
          password.name.includes(searchD) || password.username.includes(searchD) || password.notes.includes(searchD),
      )

  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <>
      <Tab.Group vertical className="mt-2 flex gap-8" selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <div className="w-3/12">
          <ImportPasswordButton />
          <Tab.List className="flex flex-col">
            <Tab /> {/* Empty tab for init page*/}
            <Tab as="div" className="outline-none">
              <Button variant="accent" className="mb-2 w-full" data-testid="password-vault-create-btn">
                Add password
              </Button>
            </Tab>
            <Input
              placeholder="Search"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              data-testid="password-vault-search-input"
            />
            <div className="flex max-h-[480px] flex-col overflow-auto">
              {items.map((password) => (
                <Tab as="div" key={password.id} className="outline-none" data-testid="password-vault-tabs">
                  {({ selected }) => (
                    <PasswordTab
                      selected={selected}
                      name={password.name}
                      username={password.username}
                      data-testid={`password-vault-select-${password.id}-tab`}
                    />
                  )}
                </Tab>
              ))}
            </div>
            {passwords.length > 0 && items.length === 0 && (
              <p className="mt-2 flex justify-center text-gray-700" data-testid="password-vault-not-found-text">
                No passwords found
              </p>
            )}
          </Tab.List>
        </div>
        <Tab.Panels>
          <Tab.Panel /> {/* Empty panel to align init empty page */}
          <Tab.Panel>
            <PasswordCreate onClose={() => setSelectedIndex(0)} />
          </Tab.Panel>
          {items.map((password) => (
            <Tab.Panel key={password.id}>
              <PasswordItem password={password} />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </>
  )
}

type PasswordTabProps = {
  selected: boolean
  name: string
  username: string
  'data-testid'?: string
}

function PasswordTab(props: PasswordTabProps) {
  const { selected, name, username } = props
  return (
    <div
      className={clsx(
        'w-full px-4 py-2 text-left hover:rounded-xl hover:bg-gray-100',
        selected && 'rounded-xl bg-accent-light',
      )}
      data-testid={props['data-testid']}
    >
      <Text variant="title" className="truncate">
        {name}
      </Text>
      <Text variant="subtitle" className="truncate">
        {username}
      </Text>
    </div>
  )
}
