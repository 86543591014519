import React from 'react'
import ReactDOM from 'react-dom/client'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import * as Highcharts from 'highcharts'
import highchartsAccessibility from 'highcharts/modules/accessibility'
import * as Sentry from '@sentry/react'
import { OpenAPI } from '@/api/core'
import { App } from '@/App'
import { BACKEND_URL } from '@/config'
import '@/index.css'
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.extend(customParseFormat)
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: 'now',
    m: 'now',
    mm: '%dm ago',
    h: '1h ago',
    hh: '%dh ago',
    d: '1d ago',
    dd: '%dd ago',
    M: '1mo ago',
    MM: '%dmo ago',
    y: '1y ago',
    yy: '%dy ago',
  },
})
highchartsAccessibility(Highcharts)

OpenAPI.BASE = BACKEND_URL + '/v1'
OpenAPI.WITH_CREDENTIALS = true

// NOTE: This only detects unsubscribe on the initial load of our application,
// which is correct for users clicking the unsubscribe link.
const onUnsubscribePage = window.location.pathname.match(/^\/unsubscribe\/\w{10}$/)

Sentry.init({
  dsn: 'https://2a9a9036a64d4878a470be7bb4be07f8@o4504500291698688.ingest.us.sentry.io/4504500355268608',
  integrations: [
    Sentry.browserTracingIntegration({ tracingOrigins: ['*'] }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      sessionSampleRate: onUnsubscribePage ? 0 : 0.1,
      errorSampleRate: 1,
    }),
    Sentry.captureConsoleIntegration({ levels: ['error'] }),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,
  environment: import.meta.env.VITE_SENTRY_ENV,
  release: import.meta.env.VITE_SENTRY_RELEASE,
  enabled: import.meta.env.PROD,
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred in the app</p>}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
)
