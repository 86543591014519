import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ApiError, DefaultService } from '@/api/core'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { queryKey as hubspotQueryKey } from './hubspot'

export const queryKey = {
  google: () => ['oauth', 'google'] as const,
  zoom: () => ['oauth', 'zoom'] as const,
  hubspot: () => ['oauth', 'hubspot'] as const,
  hubspotReconnect: () => ['oauth', 'hubspot', 'reconnect'] as const,
}

export function useGoogleOauthRedirect() {
  const toast = useToast()

  return async () => {
    try {
      const resp = await DefaultService.getGoogleOauth('calendar')
      window.location.href = resp.data.redirect_url
    } catch (error) {
      if (error instanceof ApiError) {
        toast.createToast({ error: true, message: error?.body?.message })
      } else {
        toast.createToast({ error: true, message: 'Something went wrong' })
      }
    }
  }
}

export function useZoomOauthRedirect() {
  const toast = useToast()

  return async () => {
    try {
      const resp = await DefaultService.getZoomOauth()
      window.location.href = resp.data.redirect_url
    } catch (error) {
      if (error instanceof ApiError) {
        toast.createToast({ error: true, message: error?.body?.message })
      } else {
        toast.createToast({ error: true, message: 'Something went wrong' })
      }
    }
  }
}

export function useZoomOauthStatus() {
  return useQuery({
    queryKey: queryKey.zoom(),
    queryFn: () => DefaultService.getZoomOauthStatus(),
  })
}

export function useGoogleMeetingOauthStatus() {
  return useQuery({
    queryKey: queryKey.google(),
    queryFn: () => DefaultService.getGoogleOauthStatus('calendar'),
  })
}

export function useZoomOauthDelete() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.zoom() })
    },
    mutationFn: () => DefaultService.deleteZoomOauth(),
  })
}

export function useGoogleMeetingOauthDelete() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.google() })
    },
    mutationFn: () => DefaultService.deleteGoogleOauth('calendar'),
  })
}

export function useHubspotOauthRedirect() {
  return useQuery({
    queryFn: DefaultService.getHubspotOauth,
    queryKey: queryKey.hubspot(),
    gcTime: 0,
    enabled: false,
  })
}

export function useHubspotOauthReconnect() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: DefaultService.patchHubspotOauthReconnect,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: hubspotQueryKey.base() })
    },
    gcTime: 0,
  })
}

export function useHubspotOauthDisconnect() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: DefaultService.deleteHubspotOauth,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: hubspotQueryKey.base() })
    },
    gcTime: 0,
  })
}
