/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PostInboxesMain = {
    email: string;
    password: string;
    provider: PostInboxesMain.provider;
};

export namespace PostInboxesMain {

    export enum provider {
        GMAIL = 'GMAIL',
        OUTLOOK = 'OUTLOOK',
        LOCALDEV = 'LOCALDEV',
    }


}

