import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Dialog } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { PatchTeam, PostTeam } from '@/api/core'
import { useTeamCreate, useTeamUpdate } from '@/api/team'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Input, Text } from '@/ui'

type GroupCreateDialogProps = {
  open: boolean
  onClose: () => void
  mode?: 'create' | 'edit'
  teamId?: string
  updateData?: PatchTeam
}

export function GroupFormDialog(props: GroupCreateDialogProps) {
  const { open, onClose, updateData, teamId, mode = 'create' } = props
  const toast = useToast()

  const schema = yup.object().shape({
    name: yup.string().required('required'),
  })

  const teamCreate = useTeamCreate()
  const teamUpdate = useTeamUpdate()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PostTeam | PatchTeam>({
    resolver: yupResolver(schema),
    defaultValues: updateData,
  })

  const onSubmit = (data: PostTeam | PatchTeam) => {
    if (mode === 'edit') {
      teamUpdate.mutate(
        { ...data, id: teamId! },
        {
          onSuccess: () => {
            reset()
            onClose()
          },
          onError: (err) =>
            toast.createToast({ message: (err as any)?.body.message || 'Failed to update group', error: true }),
        },
      )
    } else {
      teamCreate.mutate(data, {
        onSuccess: () => {
          reset()
          onClose()
        },
        onError: (err) =>
          toast.createToast({ message: (err as any)?.body.message || 'Failed to create group', error: true }),
      })
    }
  }

  const label = mode === 'create' ? 'Create' : 'Update'
  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="fixed inset-0 bg-white/70" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="mx-auto w-72 rounded-lg border border-light bg-white p-6 shadow-sm">
            <Dialog.Title as="div">
              <Text variant="subtitle">{label} group</Text>
            </Dialog.Title>

            <div className="w-full flex-col space-y-5 pt-2">
              <Input registration={register('name')} error={errors.name} label="Name" />

              <div className="flex justify-end gap-3">
                <Button type="button" variant="text" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit" variant="accent">
                  {label}
                </Button>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </form>
    </Dialog>
  )
}
