import { FieldError, Merge } from 'react-hook-form'

export function keys<T>(t: T): Array<keyof T> {
  return t && typeof t === 'object' ? (Object.keys(t) as any) : []
}

export function pickObject<T, K extends keyof T>(source: T, keysToPick: K[]): Pick<T, K> {
  const res: Pick<T, K> = {} as any
  keysToPick.forEach((k) => (res[k] = source[k]))
  return res
}

export function omit<T extends Record<any, any>>(object: T, keysToOmit: string[] = []) {
  const clone = Object.assign({}, object) as T
  for (const key of keysToOmit) {
    if (key in clone) delete clone[key]
  }
  return clone
}

export function omitObject<T, K extends keyof T>(source: T, keysToOmit: K[]): Omit<T, K> {
  const res: T = {} as any
  ;(keys(source) as K[]).filter((k) => !keysToOmit.includes(k)).forEach((k) => (res[k] = source[k]))
  return res
}

export type FormError = Merge<FieldError, (FieldError | undefined)[]>

const englishOrdinalRules = new Intl.PluralRules('en', { type: 'ordinal' })
export function ordinal(n: number) {
  const rule = englishOrdinalRules.select(n)
  switch (rule) {
    case 'one':
      return `${n}st`
    case 'two':
      return `${n}nd`
    case 'few':
      return `${n}rd`
    default:
      return `${n}th`
  }
}

export function abbreviateNumber(numToAbbreviate: number, digitsToRender: number) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
  ]

  if (numToAbbreviate > 1000) {
    // round numToAbbreviate to nearest 100
    numToAbbreviate = Math.round(numToAbbreviate / 100) * 100
  }

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item =
    lookup
      .slice()
      .reverse()
      .find(function (item) {
        return numToAbbreviate >= item.value
      }) ?? lookup[0]

  return (numToAbbreviate / item.value).toFixed(digitsToRender).replace(rx, '$1') + item.symbol
}

export function sortByDomNode<T>(
  nodes: T[],
  resolveKey: (item: T) => HTMLElement | null = (i) => i as unknown as HTMLElement | null,
): T[] {
  return nodes.slice().sort((aItem, zItem) => {
    const a = resolveKey(aItem)
    const z = resolveKey(zItem)

    if (a === null || z === null) return 0

    const position = a.compareDocumentPosition(z)

    if (position & Node.DOCUMENT_POSITION_FOLLOWING) return -1
    if (position & Node.DOCUMENT_POSITION_PRECEDING) return 1
    return 0
  })
}

export async function copy(text: string) {
  if (!navigator?.clipboard) {
    return false
  }

  try {
    await navigator.clipboard.writeText(text)
    return true
  } catch (error) {
    return false
  }
}

export function shallowEqual(a: any, b: any) {
  return Object.keys(a).length === Object.keys(b).length && Object.keys(a).every((key) => a[key] === b[key])
}
