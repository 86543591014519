import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { WarningCircle, X } from '@phosphor-icons/react'
import { useContactList, useContactListDelete, useContactListUpdate } from '@/api/contact_lists'
import { ContactList, PatchContactList } from '@/api/core'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Drawer, Spinner } from '@/ui'
import { ConfirmationDialog } from './ConfirmationDialog'
import { KVList } from './KVList'

type ContactListDetailsProps = {
  open: boolean
  onClose: () => void
  contactListId: string
}

export function ContactListDetails(props: ContactListDetailsProps) {
  const { open, onClose, contactListId } = props
  const { data, status } = useContactList(contactListId)
  const remove = useContactListDelete()
  const [editing, setEditing] = useState(false)
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false)
  const toast = useToast()

  if (status === 'pending' || status === 'error') {
    return <></>
  }

  const onDelete = () => {
    remove.mutate(contactListId, {
      onSuccess: () => {
        onClose()
        toast.createToast({ message: 'List deleted' })
      },
      onError: (err) =>
        toast.createToast({ message: (err as any)?.body?.message || 'failed to delete contacts list', error: true }),
    })
  }

  return (
    <Drawer open={open} onClose={onClose}>
      <ConfirmationDialog
        open={deleteConfirmationDialog}
        onClose={() => setDeleteConfirmationDialog(false)}
        onConfirm={onDelete}
      />
      {editing ? (
        <Edit onClose={onClose} data={data.data} stopEdit={() => setEditing(false)} />
      ) : (
        <>
          <div className="mb-4 flex justify-between">
            <X className="icon-hover size-6" aria-hidden="true" onClick={onClose} />
            <div className="flex gap-1">
              <button className="btn-v2 py-0.5 text-sm" onClick={() => setDeleteConfirmationDialog(true)}>
                {!remove.isPending ? 'Delete' : <Spinner />}
              </button>
              <button className="btn-v2 py-0.5 text-sm" onClick={() => setEditing(true)}>
                Edit
              </button>
            </div>
          </div>

          <KVList
            data={{
              Name: data.data.name,
              Description: data.data.description,
            }}
          />
        </>
      )}
    </Drawer>
  )
}

type EditProps = {
  data: ContactList
  onClose: () => void
  stopEdit: () => void
}

function Edit(props: EditProps) {
  const update = useContactListUpdate()
  const toast = useToast()
  const { register, handleSubmit, formState } = useForm<PatchContactList>({
    mode: 'onChange',
    defaultValues: props.data,
  })

  const onSubmit = (data: PatchContactList) => {
    update.mutate(
      { contactListId: props.data.id, ...data },
      {
        onSuccess: () => props.stopEdit(),
        onError: (err) =>
          toast.createToast({ message: (err as any)?.body?.message || 'failed to update contacts list', error: true }),
      },
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between">
        <X className="icon-hover size-6" aria-hidden="true" onClick={props.onClose} />
        <div className="flex gap-1">
          <button className="btn-v2 py-0.5 text-sm" onClick={props.stopEdit}>
            Cancel
          </button>
          <button className="btn-v2 py-0.5 text-sm" disabled={!formState.isValid}>
            {!update.isPending ? 'Save' : <Spinner />}
          </button>
        </div>
      </div>

      <div className="mt-4 flex flex-col gap-y-4">
        <div className="flex items-center">
          <div className="w-24 min-w-[6rem] text-sm text-gray-500">Name</div>
          <div className="relative w-full">
            <input
              type="text"
              className="block w-full appearance-none rounded-lg border border-gray-300 p-1 text-sm placeholder-gray-400 shadow-sm transition-colors hover:bg-gray-50 focus:border-primary focus:outline-none focus:ring-primary"
              {...register('name', { required: 'name required', minLength: 1 })}
            />
            {formState.dirtyFields.name && formState.errors.name && (
              <WarningCircle
                className="absolute right-0 top-1/2 h-4 w-4 -translate-x-1/2 -translate-y-1/2 text-red-500"
                aria-hidden="true"
              />
            )}
          </div>
        </div>
        <div className="flex">
          <div className="w-24 min-w-[6rem] text-sm text-gray-500">Description</div>
          <textarea
            className="block w-full appearance-none rounded-lg border border-gray-300 p-1 text-sm placeholder-gray-400 shadow-sm transition-colors hover:bg-gray-50 focus:border-primary focus:outline-none focus:ring-primary"
            {...register('description')}
          />
        </div>
      </div>
    </form>
  )
}
