import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSalesforceConnection, useSalesforceConnectionDelete } from '@/api'
import { useSalesforceOauthRedirect } from '@/api/salesforce_oauth'
import { SalesforceLogo } from '@/icons/SalesforceLogo'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { HubspotIntegrationCard } from './Hubspot/HubspotIntegrationCard'
import { ConnectionRow, ConnectionSkeleton } from './ConnectionRow'

export function Connections() {
  return (
    <section>
      <SalesforceConnection />
      <HubspotIntegrationCard />
    </section>
  )
}

function SalesforceConnection() {
  const salesforceOauth = useSalesforceOauthRedirect()
  const salesforceConnection = useSalesforceConnection()
  const salesforceDelete = useSalesforceConnectionDelete()
  const toast = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    if (salesforceOauth.error) {
      toast.createToast({
        message: (salesforceOauth.error as any)?.body?.message || 'Failed auth with Salesforce',
        error: true,
      })
    }
    if (salesforceOauth.status === 'success') {
      window.location.href = salesforceOauth.data.data.redirect_url
    }
  }, [salesforceOauth.status])

  useEffect(() => {
    if (salesforceDelete.error) {
      toast.createToast({ message: 'Failed to disconnect Salesforce connection', error: true })
    }
    if (salesforceDelete.status === 'success') {
      toast.createToast({ message: 'Salesforce disconnected' })
    }
  }, [salesforceDelete.status])

  const onSalesforceConnect = () => {
    salesforceOauth.refetch()
  }

  const onSalesforceDelete = () => {
    salesforceDelete.mutate()
  }
  if (salesforceConnection.status === 'pending' || !salesforceConnection.data) {
    return <ConnectionSkeleton />
  }

  const onSalesforceSettings = () => {
    navigate('/settings/integrations/salesforce')
  }
  return (
    <ConnectionRow
      connected={salesforceConnection?.data.data.connected}
      onDisconnect={onSalesforceDelete}
      onConnect={onSalesforceConnect}
      connectionName="Salesforce"
      Logo={<SalesforceLogo className="h-10 w-10 max-w-none" />}
      editPermission={salesforceConnection.data.permissions.edit}
      onSettings={onSalesforceSettings}
    />
  )
}
