import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { ArrowRight } from '@phosphor-icons/react'
import { Switch, Text } from '@/ui'

type SalesforceSynchronizationSettingsProps = {
  withSubpages?: boolean
  riftToCrmEnabled: boolean
  onRiftToCrmChange: (isEnabled: boolean) => void
  crmToRiftEnabled: boolean
  onCrmToRiftChange: (isEnabled: boolean) => void
  interactionHistoryEnabled: boolean
  onInteractionHistoryChange: (isEnabled: boolean) => void
}

export function SalesforceSynchronizationSettings({
  withSubpages = false,
  riftToCrmEnabled,
  onRiftToCrmChange,
  crmToRiftEnabled,
  onCrmToRiftChange,
  interactionHistoryEnabled,
  onInteractionHistoryChange,
}: SalesforceSynchronizationSettingsProps) {
  const dataSyncEnabled = riftToCrmEnabled || crmToRiftEnabled

  const rowClassName = 'flex items-center justify-between'

  const redirectArrow = (href: string) => (
    <Link to={href}>
      <ArrowRight
        width={20}
        height={20}
        className={clsx('self-center justify-self-center', 'duration-150 hover:mr-2')}
      />
    </Link>
  )

  return (
    <section>
      <hgroup className="pb-5">
        <Text variant="title" className="pb-2">
          Salesforce Integration
        </Text>
        <Text variant="subtext" className="text-medium">
          Select how you’d like to sync data between rift and Salesforce
        </Text>
      </hgroup>

      <div className="flex w-full flex-col gap-5">
        <div className={rowClassName}>
          <Text variant="subtitle">Sync rift to Salesforce</Text>
          <Switch checked={riftToCrmEnabled} onChange={onRiftToCrmChange} />
        </div>
        <div className={rowClassName}>
          <Text variant="subtitle">Sync Salesforce to rift</Text>
          <Switch checked={crmToRiftEnabled} onChange={onCrmToRiftChange} />
        </div>
        <div className={rowClassName}>
          <Text variant="subtitle">Sync interaction history</Text>
          <Switch checked={interactionHistoryEnabled} onChange={onInteractionHistoryChange} />
        </div>
        {withSubpages && (
          <>
            <div className={clsx(rowClassName, !dataSyncEnabled && 'hidden')}>
              <Text variant="subtitle">User mapping</Text>
              {redirectArrow('/settings/integrations/salesforce/user-mapping')}
            </div>
            <div className={clsx(rowClassName, !dataSyncEnabled && 'hidden')}>
              <Text variant="subtitle">Field mapping</Text>
              {redirectArrow('/settings/integrations/salesforce/field-mapping')}
            </div>
            <div className={clsx(rowClassName, !dataSyncEnabled && 'hidden')}>
              <Text variant="subtitle">Stage mapping</Text>
              {redirectArrow('/settings/integrations/salesforce/stage-mapping')}
            </div>
            <div className={clsx(rowClassName, !dataSyncEnabled && 'hidden')}>
              <Text variant="subtitle">Contacts synchronization debug</Text>
              {redirectArrow('/settings/integrations/salesforce/contacts-synchronization-debug')}
            </div>
          </>
        )}
      </div>
    </section>
  )
}
