import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useBeforeUnload } from 'react-router-dom'
import { useImmerReducer } from 'use-immer'
import { useCampaign } from '@/api/campaigns'
import { useContactListAddContacts, useContactListsImportable } from '@/api/contact_lists'
import {
  useContactFieldCleaner,
  useContactsCreate,
  useContactsUploadCampaignId,
  useContactsUploadContactList,
  useContactsUploadContactListId,
} from '@/api/contacts'
import { ContactActionable, PostContactsResponse } from '@/api/core'
import { useMembers } from '@/api/member'
import { DuplicativeDataReview } from './steps/DuplicativeDataReview'
import { contactsToUpload } from './convert'
import {
  ContactObject,
  DuplicativeData,
  duplicativeDataCheck,
  encodeToBase64,
  initState,
  MaybeDuplicativeData,
  readData,
  reducer,
  Transformation,
} from './reducer'
import {
  ErrorStep,
  FieldCleaningStep,
  ListSelectStep,
  LoadingStep,
  MappingStepV2,
  ReviewStepV2,
  SummaryStepV2,
  UploadStepV2,
} from './steps'

type Step = 'upload' | 'mapping' | 'duplicativedata' | 'fieldcleaning' | 'listselect' | 'error' | 'review' | 'summary'

// ImportV2 is a copy-pasted version of the Import component with the FieldCleaning step added and
// additional changes to the UX for importing. It is currently behind a feature flag and is not
// yet rolled out to all users.
export function ImportV2() {
  const [searchParams] = useSearchParams()
  const campaignId = searchParams.get('sequenceId')
  const navigate = useNavigate()
  const onClose = async () => {
    if (campaignId) {
      navigate(`/sequences/${campaignId}?tab=Contacts`)
    } else {
      navigate(`/contacts`)
    }
  }
  const { sequenceId } = useParams() as { sequenceId: string }
  const { data: campaignData, status: campaignLoadStatus } = useCampaign(sequenceId)
  const campaign = campaignData?.data
  const defaultListName = campaign ? campaign.name + ' contacts' : ''
  const [contactState, dispatch] = useImmerReducer(reducer, initState)
  const [step, setStep] = useState<Step>('upload')
  const [uploadError, setUploadError] = useState<Error | null>(null)
  // const [importedContactsNo, setImportedContactsNo] = useState<number>(0)
  const [postResponse, setPostResponse] = useState<PostContactsResponse>({} as PostContactsResponse)
  const [duplicativeData, setDuplicativeData] = useState<MaybeDuplicativeData>(null)

  const [contactListName, setContactListName] = useState<string | undefined>(defaultListName)
  const [contactListId, setContactListId] = useState<string | undefined>(undefined)
  const uploadContacts = useContactsCreate()
  const uploadContactsToNewList = useContactsUploadContactList()
  const uploadContactsToList = useContactsUploadContactListId()
  const uploadContactsToCampaign = useContactsUploadCampaignId()
  const addContactsToList = useContactListAddContacts()
  const { data: contactLists } = useContactListsImportable()
  const contactFieldCleaning = useContactFieldCleaner()
  const { data: members } = useMembers({ phantom: false })

  // Prevent user from closing the tab mid-import
  const unloadCallback = useCallback(
    (e: BeforeUnloadEvent) => {
      if (step !== 'upload') {
        e.preventDefault()
      }
    },
    [step],
  )
  useBeforeUnload(unloadCallback)
  const fieldsToClean: Array<keyof ContactObject> = ['Company', 'FirstName', 'LastName', 'Title']
  const [currentFieldCleaningIndex, setCurrentFieldCleaningIndex] = useState<number | null>(null)
  function moveToNextFieldIndex() {
    if (currentFieldCleaningIndex === null) {
      setCurrentFieldCleaningIndex(0)
    } else {
      setCurrentFieldCleaningIndex(currentFieldCleaningIndex + 1)
    }
  }
  const currentFieldToClean = fieldsToClean[currentFieldCleaningIndex || 0]

  const onUpload = async (files: File[]) => {
    if (files.length != 1) return

    try {
      const b64File = await encodeToBase64(files[0])
      const state = readData(b64File, files[0].name)
      dispatch({ type: 'set_state', state })
      setStep('mapping')
    } catch (e) {
      setUploadError(e as Error)
    }
  }

  const doImport = () => {
    const postContacts = contactsToUpload(contactState)

    if (campaignId) {
      uploadContactsToCampaign.mutate(
        {
          contacts: postContacts,
          contact_list_name: contactListName ?? '',
          campaignId: campaignId,
        },
        {
          onSuccess: (resp) => {
            setPostResponse(resp.data)
            setContactListName(contactListName)
            if (resp.data.duplicated_in_campaigns.length > 0) {
              setStep('review')
            } else {
              onClose()
            }
          },
          onError: (err) => {
            setUploadError(err as Error)
            setStep('error')
          },
        },
      )
    } else if (contactListId) {
      uploadContactsToList.mutate(
        {
          contacts: postContacts,
          contactListId: contactListId || '',
        },
        {
          onSuccess: (resp) => {
            setPostResponse(resp.data)
            setContactListName(contactListName)
            if (resp.data.duplicated_in_campaigns.length > 0) {
              setStep('review')
            } else {
              onClose()
            }
          },
          onError: (err) => {
            setUploadError(err as Error)
            setStep('error')
          },
        },
      )
    } else if (contactListName) {
      uploadContactsToNewList.mutate(
        {
          contacts: postContacts,
          contact_list_name: contactListName,
        },
        {
          onSuccess: (resp) => {
            setPostResponse(resp.data)
            setContactListName(contactListName)
            if (resp.data.duplicated_in_campaigns.length > 0) {
              setStep('review')
            } else {
              onClose()
            }
          },
          onError: (err) => {
            setUploadError(err as Error)
            setStep('error')
          },
        },
      )
    } else {
      uploadContacts.mutate(
        { contacts: postContacts },
        {
          onSuccess: (resp) => {
            setPostResponse(resp.data)
            onClose()
          },
          onError: (err) => {
            setUploadError(err as Error)
            setStep('error')
          },
        },
      )
    }
  }

  function afterFieldCleaning() {
    if (campaignId || contactListId) {
      setStep('summary')
    } else {
      setStep('listselect')
    }
  }

  // An explanation of this useEffect:
  //     Before we start the field cleaning step, the currentFieldCleaningIndex will be null
  //
  //     After the Mapping step, we clean the first field by setting currentFieldCleaningIndex to 0
  //     After each field cleaning step, we move to the next field by incrementing
  //     currentFieldCleaningIndex and going through fieldToClean one element at a time. If we
  //     reach the end of the fieldsToClean array, we call afterFieldCleaning, which proceeds to
  //     the correct step after field cleaning has been complete
  //
  //      This useEffect is triggered each time currentFieldCleaningIndex changes, and will only clean fields if
  //      the currentFieldCleaningIndex is a valid index for fieldsToClean.
  //      - Lindsay
  useEffect(() => {
    if (currentFieldCleaningIndex === null) {
      // we haven't reached field cleaning
      return
    } else if (currentFieldCleaningIndex >= fieldsToClean.length) {
      // we have cleaned all fields
      afterFieldCleaning()
    } else {
      const field = fieldsToClean[currentFieldCleaningIndex]
      if (contactState.headers.every((h) => h.type === 'standard' && h.to !== field)) {
        // if the field is not in the headers
        moveToNextFieldIndex()
      } else {
        // The field is in the headers, so we should clean them using the Backend
        contactFieldCleaning.mutate(
          {
            contacts: contactsToUpload(contactState),
            fields: [field],
          },
          {
            onSuccess: ({ data }) => {
              // Check the payload for field suggestions
              const fieldCleanings = data.cleanedValues.find(({ fieldName }) => fieldName === field)?.fieldChanges
              if (fieldCleanings) {
                // If we find field suggestions in the response, update our reducer state.
                dispatch({
                  type: 'check_fields',
                  field,
                  fieldCleanings,
                })
                if (step !== 'fieldcleaning') {
                  // If we came from the mapping step, set the step to field cleaning so user can
                  //     accept/edit field suggestions.
                  setStep('fieldcleaning')
                }
              } else {
                // If we don't find field suggestions in the response, move to the next field.
                moveToNextFieldIndex()
              }
            },
          },
        )
      }
    }
  }, [currentFieldCleaningIndex])

  function afterFieldMapping() {
    const data = duplicativeDataCheck(contactState)
    if (data) {
      setDuplicativeData(data)
      setStep('duplicativedata')
    } else {
      setCurrentFieldCleaningIndex(0)
    }
  }

  const onInclude = (contacts: ContactActionable[]) => {
    if (contacts.length === 0) {
      onClose()
    }

    const contactIds = contacts.map((c) => c.id)
    addContactsToList.mutate(
      {
        contactListId: postResponse.contact_list_id,
        contact_ids: contactIds,
      },
      {
        onSuccess: () => {
          onClose()
        },

        onError: () => {
          // NOTE: we dont' have a good error handling right now, but some contacts where added
          // so let just go to summary page
          onClose()
        },
      },
    )
  }

  const validMemberEmails = members?.data.map((m) => m.email) || []
  const contactsWithOwnerEmails = contactsToUpload(contactState).filter(
    (c) => c.owner_email !== undefined && c.owner_email !== '',
  )

  const numSkippedForBadOwnerEmail = contactsWithOwnerEmails.filter(
    (c) => !validMemberEmails.includes(c.owner_email.toLocaleLowerCase() as string),
  ).length

  const isPending =
    uploadContacts.isPending ||
    uploadContactsToNewList.isPending ||
    uploadContactsToList.isPending ||
    uploadContactsToCampaign.isPending ||
    contactFieldCleaning.isPending ||
    campaignLoadStatus === 'pending'

  const steps: Record<Step, React.FC> = {
    upload: () => (
      <div className="h-full">
        <UploadStepV2 onUpload={onUpload} onCancel={onClose} uploadError={uploadError} />
      </div>
    ),
    mapping: () => (
      <div className="mx-auto max-w-[600px]">
        <div className="my-6 px-2 pb-2 pt-1">
          <div className="text-lg font-medium leading-8 text-dark">Map columns</div>
          <div className="text-sm font-normal leading-5 text-dusk">
            Confirm your column mapping to available fields in rift
          </div>
        </div>
        <MappingStepV2 onImport={afterFieldMapping} onCancel={onClose} state={contactState} dispatch={dispatch} />
      </div>
    ),
    duplicativedata: () => (
      <div className="mx-auto my-auto mt-2 max-w-[600px]">
        <DuplicativeDataReview
          onCancel={onClose}
          onContinue={() => setCurrentFieldCleaningIndex(0)}
          duplicativeData={duplicativeData as DuplicativeData}
        />
      </div>
    ),
    fieldcleaning: () =>
      contactState.fieldCleanings[currentFieldToClean] && (
        <div className="mx-auto my-auto max-w-[600px]">
          <div className="my-6 px-2 pb-2 pt-1">
            <div className="text-lg font-medium leading-8 text-dark">Clean fields</div>
            <div className="text-sm font-normal leading-5 text-dusk">
              These fields were found to have extra or malformed information. Uncheck items to skip.
            </div>
          </div>
          <FieldCleaningStep
            onCancel={onClose}
            onComplete={moveToNextFieldIndex}
            field={currentFieldToClean}
            cleanings={contactState.fieldCleanings[currentFieldToClean] as Transformation[]}
            dispatch={dispatch}
          />
        </div>
      ),
    listselect: () => (
      <div className="mx-auto my-auto max-w-[600px]">
        <div className="my-6">
          <div className="text-lg font-medium leading-8 text-dark">Add to contact list</div>
        </div>
        <ListSelectStep
          onImport={(v: { listName?: string; contactListId?: string }) => {
            if (v.listName) {
              setContactListName(v.listName)
            }
            if (v.contactListId) {
              setContactListId(v.contactListId)
            }
            setStep('summary')
          }}
          onCancel={onClose}
          contactLists={contactLists?.data || []}
        />
      </div>
    ),
    review: () => (
      <div className="mx-auto my-auto mt-2 max-w-[600px]">
        <ReviewStepV2 onInclude={onInclude} duplicated={postResponse.duplicated_in_campaigns} />
      </div>
    ),
    summary: () => (
      <div className="mx-auto my-auto max-w-[600px]">
        <div className="my-6">
          <div className="text-lg font-medium leading-8 text-dark">Ready to import</div>
        </div>
        <SummaryStepV2
          onCancel={onClose}
          onDone={() => doImport()}
          numSkippedForBadOwnerEmail={numSkippedForBadOwnerEmail}
          filename={contactState.filename || ''}
          importedContactsNo={contactState.contacts.length - numSkippedForBadOwnerEmail}
          fieldcleanings={contactState.fieldCleanings}
        />
      </div>
    ),
    error: () => (
      <div className="mx-auto max-w-[600px]">
        <div>Import Contacts</div>
        <ErrorStep onCancel={onClose} error={uploadError} />
      </div>
    ),
  }

  return <div className="m-4 h-[calc(100vh-32px)]">{isPending ? <LoadingStep /> : steps[step]({})}</div>
}
