import { SenderEmailProvider } from '@/api/core'

export const SenderEmailProviderToText: Record<SenderEmailProvider, string> = {
  [SenderEmailProvider.LOCALDEV]: 'Localdev',
  [SenderEmailProvider.GMAIL]: 'Gmail',
  [SenderEmailProvider.OUTLOOK]: 'Outlook',
}

export const TextToSenderEmailProvider: Record<string, SenderEmailProvider> = Object.fromEntries(
  Object.entries(SenderEmailProvider).map(([key, value]) => [value, key as SenderEmailProvider]),
)

export const SenderEmailProviderLabels: { label: string; value: SenderEmailProvider }[] = Object.entries(
  SenderEmailProviderToText,
).map(([value, label]) => ({
  label: label,
  value: value as SenderEmailProvider,
}))

export type SenderEmailProviderLabel = (typeof SenderEmailProviderLabels)[number]
