import { useCallback, useMemo, useState } from 'react'

export type Actions<T> = {
  add: (value: T) => void
  delete: (value: T) => void
  toggle: (value: T) => void
  clear: () => void
  has: (value: T) => boolean
}

export function useSet<T>(initialSet = new Set<T>()): [Set<T>, Actions<T>] {
  const [set, setSet] = useState(initialSet)

  const actions = useMemo(() => {
    return {
      add: (value: T) => setSet((prevSet) => new Set([...Array.from(prevSet), value])),
      delete: (value: T) => setSet((prevSet) => new Set(Array.from(prevSet).filter((i) => i !== value))),
      toggle: (value: T) =>
        setSet((prevSet) =>
          prevSet.has(value)
            ? new Set(Array.from(prevSet).filter((i) => i !== value))
            : new Set([...Array.from(prevSet), value]),
        ),
      clear: () => setSet(new Set()),
    }
  }, [setSet])
  const has = useCallback((item: T) => set.has(item), [set])

  return [
    set,
    {
      add: actions.add,
      delete: actions.delete,
      toggle: actions.toggle,
      clear: actions.clear,
      has,
    },
  ]
}
