import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { DefaultService, PatchHubspotSettings } from '@/api/core'

export const queryKey = {
  base: () => ['hubspot'] as const,
  settings: () => ['hubspot', 'settings'] as const,
  status: () => ['hubspot', 'status'] as const,
  dataMappings: () => ['hubspot', 'dataMappings'] as const,
  activeLists: () => ['hubspot', 'activeLists'] as const,
  contact: (contactId: string) => ['hubspot', 'contact', contactId] as const,
}

export function useHubspotStatus() {
  return useQuery({
    queryKey: queryKey.status(),
    queryFn: () => DefaultService.getIntegrationsHubspotStatus(),
  })
}

export function useHubspotSettings() {
  return useQuery({
    queryKey: queryKey.settings(),
    queryFn: () => DefaultService.getIntegrationsHubspotSettings(),
  })
}

export function useHubspotSettingsOnboarding() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (body: PatchHubspotSettings) => DefaultService.putIntegrationsHubspotSettingsOnboarding(body),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
  })
}

export function useHubspotSettingsUpdate() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (body: PatchHubspotSettings) => DefaultService.patchIntegrationsHubspotSettings(body),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
  })
}

export function useHubspotContact(contactId: string) {
  return useQuery({
    queryKey: queryKey.contact(contactId),
    queryFn: () => DefaultService.getIntegrationsHubspotContacts(contactId),
  })
}

export function useHubspotDataMappings() {
  return useQuery({
    queryKey: queryKey.dataMappings(),
    queryFn: () => DefaultService.getIntegrationsHubspotMappings(),
  })
}

export function useHubspotActiveLists() {
  return useQuery({
    queryKey: queryKey.activeLists(),
    queryFn: () => DefaultService.getIntegrationsHubspotActivelists(),
  })
}
