import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { TokenStatus } from '@/api/core'
import { useMainInboxes, useManualInboxesDelete } from '@/api/inbox'
import {
  useGoogleMeetingOauthDelete,
  useGoogleMeetingOauthStatus,
  useGoogleOauthRedirect,
  useZoomOauthDelete,
  useZoomOauthRedirect,
  useZoomOauthStatus,
} from '@/api/oauth'
import { GoogleMailIcon } from '@/icons/GoogleMailIcon'
import { GoogleMeetIcon } from '@/icons/GoogleMeetIcon'
import { ZoomLogo } from '@/icons/ZoomLogo'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Dialog, Text } from '@/ui'

export function LinkedAccounts() {
  const navigate = useNavigate()
  const toast = useToast()

  const zoomOauth = useZoomOauthStatus()
  const zoomDelete = useZoomOauthDelete()
  const zoomOauthRedirect = useZoomOauthRedirect()
  const googleMeetOauthRedirect = useGoogleOauthRedirect()
  const { data: googleMailInbox, status: googleMailStatus } = useMainInboxes()
  const deleteInbox = useManualInboxesDelete()

  const googleMeetOauth = useGoogleMeetingOauthStatus()
  const googleMeetDelete = useGoogleMeetingOauthDelete()

  const [showGoogleMailModal, setShowGoogleMailModal] = useState(false)

  if (zoomOauth.status !== 'success' || googleMeetOauth.status !== 'success' || googleMailStatus !== 'success') {
    return null
  }

  const onDelete = () => {
    deleteInbox.mutate(undefined, {
      onSuccess: () => {
        setShowGoogleMailModal(false)
        toast.createToast({ message: 'Google Mail disconnected' })
      },
      onError: (err) => {
        toast.createToast({ error: true, message: (err as any)?.body?.message || 'Failed to disconnect' })
      },
    })
  }

  const googleOnClick = () => {
    if (googleMeetOauth.data.data?.tokenStatus === TokenStatus.VALID) {
      googleMeetDelete.mutate()
    } else if (googleMeetOauth.data.data?.tokenStatus === TokenStatus.NOT_FOUND) {
      googleMeetOauthRedirect()
    }
  }

  const zoomOnClick = () => {
    if (zoomOauth.data.data?.tokenStatus === TokenStatus.VALID) {
      zoomDelete.mutate()
    } else if (zoomOauth.data.data?.tokenStatus === TokenStatus.NOT_FOUND) {
      zoomOauthRedirect()
    }
  }

  const googleMailOnClick = () => {
    if (googleMailInbox?.data === null) {
      navigate('/settings/linked-accounts/google-mail')
    } else {
      setShowGoogleMailModal(true)
    }
  }

  const connections = [
    {
      name: 'Google Mail',
      subtitle:
        'Reply to lead responses with your primary email address directly in rift without switching to your email client',
      logo: GoogleMailIcon,
      status: googleMailInbox?.data === null ? TokenStatus.NOT_FOUND : TokenStatus.VALID,
      onClick: googleMailOnClick,
    },
    {
      name: 'Google Calendar and Meet',
      subtitle: 'Sync calendar events and use Meet for rift calls',
      logo: GoogleMeetIcon,
      status: googleMeetOauth.data.data?.tokenStatus,
      onClick: googleOnClick,
    },
    {
      name: 'Zoom',
      subtitle: 'Use Zoom for rift calls',
      logo: ZoomLogo,
      status: zoomOauth.data.data?.tokenStatus,
      onClick: zoomOnClick,
    },
  ]

  return (
    <section>
      <Text variant="title" className="pb-2">
        Linked accounts
      </Text>
      <Text className="pb-8">Connect your rift account with other services</Text>

      {connections.map((conn, i) => {
        const isConnected = conn.status === TokenStatus.VALID
        return (
          <section key={i} className="mb-6 flex items-center rounded-lg border border-light p-4">
            {conn.name === 'Google' ? (
              <div className="flex h-12 w-12 items-center justify-center">
                <conn.logo className="h-[34px] w-[34px]" />
              </div>
            ) : (
              <conn.logo className="h-12 w-12 rounded-lg" />
            )}

            <div className={clsx('ml-6', isConnected && 'my-auto')}>
              <Text variant="subtitle" className="text-sm">
                {isConnected ? `Your ${conn.name} account is connected` : conn.name}
              </Text>
              {!isConnected && <Text>{conn.subtitle}</Text>}
            </div>
            <Button variant={isConnected ? 'basic' : 'text'} className="ml-auto mt-2" onClick={conn.onClick}>
              {isConnected ? 'Disconnect' : 'Connect'}
            </Button>
          </section>
        )
      })}

      <Dialog open={showGoogleMailModal} onClose={() => setShowGoogleMailModal(false)}>
        <Dialog.Panel className="max-w-screen-sm">
          <Text variant="subtitle">Disconnect Google Mail</Text>
          <Text>
            Are you sure you want to disconnect Google Mail? You will no longer be able to reply to lead responses with
            your primary email address directly in rift.
          </Text>
          <div className="mt-4 flex justify-start gap-2">
            <Button variant="basic" onClick={() => setShowGoogleMailModal(false)}>
              Cancel
            </Button>
            <Button variant="accent" onClick={onDelete}>
              Disconnect
            </Button>
          </div>
        </Dialog.Panel>
      </Dialog>
    </section>
  )
}
