import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ArrowUpRight } from '@phosphor-icons/react'
import { useChameleon, useChameleonDelete, useCompany, useCompanyUpdate } from '@/api'
import { useCampaignsReschedule } from '@/api/campaigns'
import { useCompanyDelete } from '@/api/company'
import { Company, PatchCompany } from '@/api/core'
import { useCustomerPortal } from '@/api/payment'
import { logoutHandler } from '@/containers/Logout'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Card, Checkbox, DangerButton, Text, Tooltip } from '@/ui'
import { InputField } from '@/ui/Input/v2'
import { ConfirmationDialog } from '../Contacts/ConfirmationDialog'

export function CompanySettings() {
  const { data, status } = useCompany()
  if (status !== 'success') {
    return null
  }

  return <CompanySettingsWithData company={data.data} />
}

type CompanySettingsWithDataProps = {
  company: Company
}

function CompanySettingsWithData(props: CompanySettingsWithDataProps) {
  const { company } = props
  const update = useCompanyUpdate()
  const campaignReschedule = useCampaignsReschedule()
  const companyDelete = useCompanyDelete()
  const chameleon = useChameleon()
  const chameleonDelete = useChameleonDelete()
  const [companyDeleteConfirm, setCompanyDeleteConfirm] = useState(false)
  const [campaignRescheduleConfirm, setCampaignRescheduleConfirm] = useState(false)
  const toast = useToast()
  const logout = logoutHandler()
  const { register, handleSubmit } = useForm<Company>({
    defaultValues: company,
  })

  const onReschedule = () => {
    campaignReschedule.mutate(undefined, {
      onSuccess: () => {
        toast.createToast({ message: 'Campaigns rescheduled started' })
      },
      onError: () => {
        toast.createToast({ message: 'Campaigns rescheduling failed', error: true })
      },
    })
  }

  const onSubmit = (data: PatchCompany) =>
    update.mutate(data, {
      onSuccess: () => toast.createToast({ message: 'Company updated successfully' }),
      onError: () => toast.createToast({ message: 'Company not updated', error: true }),
    })

  const onDelete = () => {
    companyDelete.mutate(undefined, {
      onSuccess: () => {
        toast.createToast({ message: 'Company deleted successfully' })

        if (chameleon.data?.data?.email !== '') {
          chameleonDelete.mutate(undefined, {
            onSuccess: () => {
              toast.createToast({ message: 'User identity changed' })
            },
          })
        } else {
          logout()
        }
      },
      onError: () => toast.createToast({ message: 'Company not deleted', error: true }),
    })
  }

  const paymentPortal = useCustomerPortal()
  const onPaymentPortal = () => {
    paymentPortal.mutate(undefined, {
      onSuccess: (url) => {
        window.location.href = url
      },
    })
  }

  return (
    <div>
      <div className="bg-white">
        <Card>
          <Text variant="subtitle">Organization Settings</Text>
          <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
            <div className="flex flex-col gap-2">
              <InputField
                name="senders_limit"
                id="senders_limit"
                label="Senders Limit"
                type="number"
                registration={register('senders_limit', { valueAsNumber: true, validate: (v) => v >= 0 })}
              />
              <div className="flex items-end gap-1">
                <div className="w-2/3">
                  <InputField
                    name="stripe_customer_id"
                    id="stripe_customer_id"
                    label={
                      <>
                        Stripe Customer ID
                        <ArrowUpRight
                          className="size-4 cursor-pointer text-dusk"
                          aria-hidden="true"
                          onClick={() => {
                            window.open(
                              `https://dashboard.stripe.com/customers/${company.stripe_customer_id}`,
                              '_blank',
                            )
                          }}
                        />
                      </>
                    }
                    type="text"
                    registration={register('stripe_customer_id')}
                  />
                </div>
                <Button variant="basic" type="button" className="mb-1.5" onClick={onPaymentPortal}>
                  Payment portal
                </Button>
              </div>
              <div>
                <div className="flex flex-col">
                  <label
                    htmlFor="campaign_catch_all"
                    className="flex flex-row items-center pb-1 pt-2 text-start text-xs font-medium text-primary"
                  >
                    Allow catch-all emails
                  </label>
                  <div className="flex justify-between">
                    <p>Catch-all emails will not be removed from the campaign contact list.</p>
                    <Tooltip>
                      <Tooltip.Trigger>
                        <Checkbox {...register('campaign_catch_all')} id="campaign_catch_all" />
                      </Tooltip.Trigger>
                      <Tooltip.Panel>
                        Catch-all emails are designed to receive all emails sent to a domain, even to incorrect email
                        addresses. <br />
                        They tend to have a higher bounce rate than other email addresses. <br />
                        It is recommended for most users to no allow catch-all emails.
                      </Tooltip.Panel>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex flex-col">
                  <label
                    htmlFor="warmerEnabled"
                    className="flex flex-row items-center pb-1 pt-2 text-start text-xs font-medium text-primary"
                  >
                    Email Warmer
                  </label>
                  <div className="flex justify-between">
                    <p>Enables/Disables the email warmer feature for all the company emails.</p>
                    <Checkbox {...register('warmerEnabled')} id="warmerEnabled" />
                  </div>
                </div>
              </div>
              <div>
                <div className="flex flex-col">
                  <div>
                    <label
                      htmlFor="dripperEnabled"
                      className="flex flex-row items-center pb-1 pt-2 text-start text-xs font-medium text-primary"
                    >
                      Sequence dripper
                    </label>
                  </div>
                  <div className="flex justify-between">
                    Sequences will still be marked as "Active" but they will not send any email.
                    <Checkbox {...register('dripperEnabled')} id="dripperEnabled" />
                  </div>
                </div>
              </div>
              <div>
                <div className="flex flex-col">
                  <div>
                    <label
                      htmlFor="isSalesCustomer"
                      className="flex flex-row items-center pb-1 pt-2 text-start text-xs font-medium text-primary"
                    >
                      Sales customer
                    </label>
                  </div>
                  <div className="flex justify-between">
                    This customer has a multiple sales users on their account.
                    <Checkbox {...register('isSalesCustomer')} id="isSalesCustomer" />
                  </div>
                </div>
              </div>
              <div>
                <div className="flex flex-col">
                  <div>
                    <label
                      htmlFor="schedulingWithAssignedOwner"
                      className="flex flex-row items-center pb-1 pt-2 text-start text-xs font-medium text-primary"
                    >
                      Scheduling with assigned member
                    </label>
                  </div>
                  <div className="flex justify-between">
                    New contacts will be scheduled with the assigned owner except using the earlies one to use.
                    <Checkbox {...register('schedulingWithAssignedOwner')} id="schedulingWithAssignedOwner" />
                  </div>
                </div>
              </div>
            </div>

            <Button variant="basic" type="submit" className="ml-auto mt-4">
              Update
            </Button>
          </form>
        </Card>

        <Card className="my-6">
          <Text variant="subtitle" className="text-yellow-500">
            Warn zone
          </Text>
          <ConfirmationDialog
            open={campaignRescheduleConfirm}
            onClose={() => setCampaignRescheduleConfirm(false)}
            onConfirm={onReschedule}
            message={`You are about to reschedule all campaigns. This will reset only emails that were not yet send.`}
          />
          <DangerButton onClick={() => setCampaignRescheduleConfirm(true)} variant="basic" className="my-4 uppercase">
            Reschedule campaigns
          </DangerButton>
        </Card>

        <Card>
          <Text variant="subtitle" className="text-red-600">
            Danger zone
          </Text>

          <ConfirmationDialog
            open={companyDeleteConfirm}
            onClose={() => setCompanyDeleteConfirm(false)}
            onConfirm={onDelete}
            message={`You are about to delete this organization.`}
          />
          <DangerButton onClick={() => setCompanyDeleteConfirm(true)} variant="basic" className="my-4 uppercase">
            Delete company
          </DangerButton>
        </Card>
      </div>
    </div>
  )
}
