import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSalesforceConnection, useSalesforceConnectionOptions, useSalesforceConnectionUpdate } from '@/api'
import {
  PatchSalesforceConnection,
  SalesforceConnection,
  SalesforceConnectionOptions,
  SingularMappings,
} from '@/api/core'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button } from '@/ui'
import { MappingSkeleton } from './Skeleton'
import { SalesforceUserMappingForm } from './UserMappingForm'

export function SalesforceUserMapping() {
  const connection = useSalesforceConnection()
  const options = useSalesforceConnectionOptions()
  if (connection.status === 'pending' || options.status === 'pending' || !connection.data || !options.data) {
    return <MappingSkeleton />
  }
  return <MappingWithData salesforceConnection={connection.data.data} salesforceOptions={options.data.data} />
}

type SalesforceUserMappingProps = {
  salesforceConnection: SalesforceConnection
  salesforceOptions: SalesforceConnectionOptions
}
function MappingWithData({ salesforceConnection, salesforceOptions }: SalesforceUserMappingProps) {
  const navigate = useNavigate()
  const toast = useToast()
  const salesforceUpdate = useSalesforceConnectionUpdate()
  const [mapping, setMapping] = useState<PatchSalesforceConnection>({
    userMappings: salesforceConnection.userMappings,
  })
  const onMappingSave = () => {
    salesforceUpdate.mutate(mapping, {
      onSuccess: () => {
        toast.createToast({ message: 'User mapping saved' })
        navigate('/settings/integrations/salesforce')
      },
    })
  }
  const onCancel = () => {
    navigate('/settings/integrations/salesforce')
  }

  return (
    <div className="flex flex-col">
      <SalesforceUserMappingForm
        options={salesforceOptions}
        mapping={mapping.userMappings || []}
        setMapping={(newMapping: SingularMappings) => setMapping({ ...mapping, userMappings: newMapping })}
      />

      <div className="flex flex-row justify-end gap-4 pb-16 pt-8">
        <Button onClick={onCancel} variant="text">
          Cancel
        </Button>
        <Button onClick={onMappingSave} variant="accent">
          Save
        </Button>
      </div>
    </div>
  )
}
