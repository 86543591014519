import { Navigate } from 'react-router-dom'
import { Campaign } from '@/api/core'
import { useUserPermissions } from '@/containers/ComponentDisplay'
import { useFlags } from '@/containers/FeatureFlag/useFlags'
import { useQueryParamStateWithDefault } from '@/hooks/useQueryParamState'
import { SequenceAnalyticsProvider } from '@/providers/Sequences/SequenceAnalyticsContext'
import { Tabs } from '@/ui/Tabs'
import { useLoadSequence } from '../hooks/useLoadSequence'
import { AllTimeSelector } from './AllTimeSelector/AllTimeSelector'
import { Audience } from './Audience'
import { Email } from './Email'
import { Header } from './Header'
import { NoSendDatesBanner } from './NoSendDatesBanner'
import { ScheduleGraph } from './Schedule'
import { Sidebar } from './Sidebar'
import { Stats } from './Stats'
import { isDraft } from './utility'

export function SequenceView() {
  const { data, status } = useLoadSequence()
  if (status === 'error') {
    return <Navigate to="/sequences" />
  }

  if (status === 'pending') {
    return <div className="skeleton mt-1k m-auto h-96 max-w-4xl" />
  }

  return (
    <SequenceAnalyticsProvider>
      <div className="grid h-screen w-screen auto-rows-max grid-cols-[1fr_300px] bg-extra-light">
        <Header className="col-span-2 h-20 border-b border-light px-8 py-5" campaign={data} />
        {data && <NoSendDatesBanner campaign={data} />}
        <section className="h-[calc(100vh_-_80px)] py-6 pl-8">
          {data && <AudienceEmailSection campaign={data} />}
        </section>
        <section className="mx-8 my-6 h-[calc(100vh_-_80px)]">{data && <Sidebar campaign={data} />}</section>
      </div>
    </SequenceAnalyticsProvider>
  )
}

function AudienceEmailSection({ campaign }: { campaign: Campaign }) {
  const [tab, setTab] = useQueryParamStateWithDefault('tab', isDraft(campaign) ? 'Contacts' : 'Stats')

  const { showSequenceSchedule } = useUserPermissions()
  const { showFeatureFlag } = useFlags()
  const tabs = [
    'Contacts',
    'Steps',
    ...(!isDraft(campaign) ? ['Stats'] : []),
    ...(showSequenceSchedule || showFeatureFlag ? ['Schedule'] : []),
  ]

  function section() {
    switch (tab) {
      case 'Contacts': {
        return <Audience campaign={campaign} />
      }
      case 'Steps': {
        return <Email campaign={campaign} />
      }
      case 'Stats': {
        return <Stats campaign={campaign} />
      }
      case 'Schedule': {
        return <ScheduleGraph campaign={campaign} />
      }
    }
  }

  return (
    <>
      <div className="flex">
        <Tabs tabs={tabs} selected={tab} setSelected={setTab} />
        {!isDraft(campaign) && tab === 'Stats' && (
          <span className="ml-auto">
            <AllTimeSelector campaign={campaign} />
          </span>
        )}
      </div>
      {section()}
    </>
  )
}
