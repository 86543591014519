import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { OrganizationMember, PhoneNumber } from '@/api/core'
import { useMembers } from '@/api/member'
import {
  usePhoneNumberBuy,
  usePhoneNumberRelease,
  usePhoneNumbers,
  usePhoneNumbersBuyable,
  usePhoneNumberUpdate,
} from '@/api/phone_numbers'
import { useDebounceValue } from '@/hooks'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { useAuthenticatedUser } from '@/providers/User/context'
import { Button, Card, DangerButton, Input, Popover, Select } from '@/ui'

export function PhoneNumbers() {
  const { data: members, status: membersStatus } = useMembers()
  const { data: numbers, status: numbersStatus } = usePhoneNumbers()
  if (membersStatus !== 'success' || numbersStatus !== 'success') {
    return null
  }

  return (
    <div className="space-y-6">
      <PhoneNumbersBuyCard members={members.data} />
      <PhoneNumbersManage members={members.data} numbers={numbers.data} />
    </div>
  )
}

type PhoneNumbersBuyCardProps = {
  members: OrganizationMember[]
}

function PhoneNumbersBuyCard(props: PhoneNumbersBuyCardProps) {
  const toast = useToast()
  const { members } = props
  const [, deboundedFilter, setFilter] = useDebounceValue('')
  const user = useAuthenticatedUser()

  const membersOptions = members.map((member) => ({ label: member.name, value: member.id }))
  let initMemberIdx = members.findIndex((member) => user.traits.email === member.email)
  if (initMemberIdx === -1) initMemberIdx = 0
  const [curMember, setCurMember] = useState(membersOptions[initMemberIdx])
  const { data: numbers, refetch, status } = usePhoneNumbersBuyable(deboundedFilter)

  const buyPhoneNumber = usePhoneNumberBuy()

  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      filter: '',
    },
  })

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSearch)())
    return () => subscription.unsubscribe()
  }, [handleSubmit, watch])

  useEffect(() => {
    refetch()
  }, [deboundedFilter])

  const onSearch = (data: { filter: string }) => {
    setFilter(data.filter)
  }

  const onBuy = (phoneNumber: string) => {
    buyPhoneNumber.mutate(
      {
        phoneNumber,
        memberId: curMember.value,
      },
      {
        onError: () => toast.createToast({ message: 'Failed to buy number', error: true }),
      },
    )
  }

  return (
    <Card>
      <h1 className="mb-2 font-medium text-dark">Buy a new number</h1>
      <form>
        <div className="flex gap-2">
          <Input registration={register('filter')} containerClassName="flex-1 my-0 py-0" placeholder="filter..." />
          <Select value={curMember} onChange={setCurMember} options={membersOptions} />
        </div>
      </form>

      <ul className="mt-4 space-y-2">
        {status === 'pending' ? (
          <div className="skeleton h-10" />
        ) : status === 'error' ? (
          <div>
            <span className="text-alert"> error while fetching numbers</span>
          </div>
        ) : (
          <>
            {numbers.data.phoneNumbers.length === 0 && <p className="text-md text-dusk">No numbers found</p>}
            {numbers.data.phoneNumbers.map((number) => (
              <li key={number} className="flex items-center gap-2">
                <p className="flex-1 text-md text-dusk">{number}</p>
                <Popover>
                  <Popover.Button variant="basic">Buy</Popover.Button>
                  <Popover.Panel className="absolute right-0 z-10 mt-1 translate-x-12 transform bg-white px-0.5">
                    {({ close }) => (
                      <Card>
                        <div className="flex flex-col gap-2">
                          <p className="whitespace-nowrap">
                            Are you sure you want to buy <strong>{number}</strong>?
                          </p>
                          <p className="whitespace-nowrap text-sm text-dusk">
                            Every new number costs $1.00 per month, and will be billed right away.
                          </p>
                          <div className="flex justify-end gap-2">
                            <Button variant="basic" onClick={() => close()}>
                              Cancel
                            </Button>
                            <DangerButton
                              variant="basic"
                              onClick={() => {
                                close()
                                onBuy(number)
                              }}
                            >
                              Buy & Pay
                            </DangerButton>
                          </div>
                        </div>
                      </Card>
                    )}
                  </Popover.Panel>
                </Popover>
              </li>
            ))}
          </>
        )}
      </ul>
    </Card>
  )
}

type PhoneNumbersManageProps = {
  numbers: PhoneNumber[]
  members: OrganizationMember[]
}

function PhoneNumbersManage(props: PhoneNumbersManageProps) {
  const toast = useToast()
  const { numbers, members } = props
  const releasePhoneNumber = usePhoneNumberRelease()
  const updatePhoneNumber = usePhoneNumberUpdate()

  const membersOptions = members.map((member) => ({ label: member.name, value: member.id }))

  const onMemberChange = (phoneNumberId: string, memberId: string) => {
    updatePhoneNumber.mutate([phoneNumberId, { memberId }], {
      onError: () => toast.createToast({ message: 'Failed to update number', error: true }),
    })
  }
  const onRelease = (phoneNumberId: string) => {
    releasePhoneNumber.mutate(phoneNumberId, {
      onError: () => toast.createToast({ message: 'Failed to release number', error: true }),
    })
  }

  return (
    <Card>
      <h1 className="mb-1 font-medium text-dark">Manage numbers</h1>
      <div className="flex flex-col gap-2">
        {numbers.map((number) => {
          const value = membersOptions.find((member) => number.member.id === member.value)!
          return (
            <div className="flex items-center gap-2" key={number.id}>
              <p className="grow text-md text-dusk">{number.phoneNumber}</p>
              <Select
                value={value}
                onChange={(member) => onMemberChange(number.id, member.value)}
                options={membersOptions}
              />
              <Popover>
                <Popover.Button variant="basic">Release</Popover.Button>
                <Popover.Panel className="absolute left-1/2 z-10 mt-1 -translate-x-1/2 transform bg-white px-0.5">
                  {({ close }) => (
                    <Card>
                      <div className="flex flex-col gap-2">
                        <p className="whitespace-nowrap">
                          Are you sure you want to release <strong>{number.phoneNumber}</strong>?
                        </p>
                        <div className="flex justify-end gap-2">
                          <Button variant="basic" onClick={() => close()}>
                            Cancel
                          </Button>
                          <DangerButton
                            variant="basic"
                            onClick={() => {
                              close()
                              onRelease(number.id)
                            }}
                          >
                            Confirm
                          </DangerButton>
                        </div>
                      </div>
                    </Card>
                  )}
                </Popover.Panel>
              </Popover>
            </div>
          )
        })}
      </div>
    </Card>
  )
}
