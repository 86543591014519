import { useEffect } from 'react'
import { useController, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useManualStepsEmailSave } from '@/api/manual_step'
import { useDebounceValue } from '@/hooks'
import { yupValidate } from '@/lib/template'
import { Button, EmailEditor, Toolbar } from '@/ui'
import { PreviewButton } from '@/ui/EmailEditor/styled/PreviewButton'
import { ErrorText } from '@/ui/Text/Error'
import { SingleStep } from '../../SingleStep'

type EditorProps = {
  variables: Record<string, string>
  subject: string
  body: string
  emailId: string
  onSend: () => void
  isSending: boolean
}

export function Editor(props: EditorProps) {
  const { variables, subject, body, emailId, onSend, isSending } = props
  const saveEmail = useManualStepsEmailSave(emailId)

  const [, debouncedSubject, setSubject] = useDebounceValue(subject, { delay: 500 })
  const [, debouncedBody, setBody] = useDebounceValue(body, { delay: 500 })

  useEffect(() => {
    if (debouncedSubject === subject && debouncedBody === body) return
    saveEmail.mutate({ subject: debouncedSubject, body: debouncedBody })
  }, [debouncedSubject, debouncedBody])

  const onChange = (values: { subject: string; body: string }) => {
    setSubject(values.subject)
    setBody(values.body)
  }

  const schema = yup.object().shape({
    subject: yup.string().required('required').test({ test: yupValidate }),
    body: yup.string().required('').test({ test: yupValidate }),
  })

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    defaultValues: { subject, body },
    mode: 'all',
    resolver: yupResolver(schema),
  })

  const isSendDisabled = !isValid || !isSending

  const {
    field: { onChange: onSubjectChangeForm, value: formSubject },
  } = useController({ control, name: 'subject' })

  const {
    field: { onChange: onBodyChangeForm, value: formBody },
  } = useController({ control, name: 'body' })

  const onBodyChange = (value: string) => {
    onChange({ subject: formSubject, body: value })
    onBodyChangeForm(value)
  }

  const onSubjectChange = (value: string) => {
    onChange({ subject: value, body: formBody })
    onSubjectChangeForm(value)
  }

  return (
    <SingleStep.Card>
      <form onSubmit={handleSubmit(onSend)} className="">
        <EmailEditor
          variables={variables}
          subject={subject}
          setSubject={onSubjectChange}
          body={body}
          setBody={onBodyChange}
        >
          <Toolbar>
            <Toolbar.Full />
          </Toolbar>
          <div className="flex gap-2">
            <Button variant="accent" className="w-fit" type="submit" disabled={!isSendDisabled}>
              {isSending ? 'Sending' : 'Send & start'}
            </Button>
            <PreviewButton className="w-fit" variant="basic" />
          </div>
        </EmailEditor>
      </form>

      {errors?.subject?.message && <ErrorText errorMessage={`Subject error: ${errors.subject.message}`} />}
      {errors?.body?.message && <ErrorText errorMessage={`Body error: ${errors.body.message}`} />}
    </SingleStep.Card>
  )
}
