import { useEffect, useRef, useState } from 'react'

export function useObserveSticky<E extends HTMLElement>(): [React.RefObject<E>, boolean] {
  const ref = useRef<E>(null)

  const [moved, setMoved] = useState(false)
  useEffect(() => {
    if (ref.current === null) {
      return
    }
    const observer = new IntersectionObserver(([e]) => setMoved(e.intersectionRatio !== 1), {
      threshold: [1],
    })

    observer.observe(ref.current)
  }, [ref.current])

  return [ref, moved]
}
