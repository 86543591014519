import { ArrowUpRight, DownloadSimple } from '@phosphor-icons/react'
import { useCompany } from '@/api'
import { Company } from '@/api/core'
import { FeatureFlag } from '@/containers/FeatureFlag'
import { Text } from '@/ui'
import { Connections } from './Connections'
import { Keys } from './Keys'

function IntegrationsPage({ company }: { company: Company }) {
  const canEdit = company.permissions.edit.deny === false
  return (
    <section>
      <Text variant="title" className="pb-2">
        Integrations
      </Text>
      <Text className="pb-8">
        You can connect your rift account with other apps and services for sharing data between them.
      </Text>
      <FeatureFlag>
        <ApiDocumentation />
      </FeatureFlag>
      <Connections />
      <Keys canEdit={canEdit} />
    </section>
  )
}

function IntegrationsWrapper() {
  const { data, status } = useCompany()
  if (status !== 'success') {
    return null
  }

  return <IntegrationsPage company={data.data} />
}

function ApiDocumentation() {
  return (
    <Text className="flex items-center gap-2">
      Public API Docs
      <a href="/public.spec.yaml" target="_blank">
        <ArrowUpRight className="size-4 cursor-pointer text-dusk" />
      </a>
      <a href="/public.spec.yaml" download>
        <DownloadSimple className="size-4 cursor-pointer text-dusk" />
      </a>
    </Text>
  )
}
export const Integrations = IntegrationsWrapper
