import clsx from 'clsx'
import { Check, Plus } from '@phosphor-icons/react'
import { FieldDefinitions, IntegrationFilters } from '@/api/core'
import { Button, Text } from '@/ui'

type StageFilteringFormProps = {
  riftStages: FieldDefinitions
  crmStages: FieldDefinitions
  riftFilters: IntegrationFilters
  setRiftFilters: (filters: IntegrationFilters) => void
  crmFilters: IntegrationFilters
  setCrmFilters: (filters: IntegrationFilters) => void
}

export function StageFilteringForm(props: StageFilteringFormProps) {
  const { riftStages, crmStages, riftFilters, setRiftFilters, crmFilters, setCrmFilters } = props

  return (
    <div className="mt-6 flex flex-col gap-4">
      <section>
        <hgroup className="pb-5">
          <Text variant="title" className="text-dark">
            Contact filters
          </Text>
          <Text className="text-dusk">
            Choose which contacts you’d like to sync from rift to Hubspot. <br />
            Contact that match not-synced stages will not sync.
          </Text>
        </hgroup>
      </section>
      <section>
        <h1 className="mb-6 text-sm font-medium leading-5 tracking-wide text-dark">rift contacts</h1>
        <div className="grid grid-cols-2 items-center gap-x-12 gap-y-6">
          {riftStages.map(({ id, label }) => (
            <SyncRow
              key={id}
              label={label}
              sync={!riftFilters.some((f) => f.field === id)}
              onToggle={(newValue: boolean) =>
                setRiftFilters(
                  newValue ? riftFilters.filter((f) => f.field !== id) : riftFilters.concat({ field: id, sync: false }),
                )
              }
            />
          ))}
        </div>
      </section>
      <section>
        <h1 className="mb-6 text-sm font-medium leading-5 tracking-wide text-dark">Salesforce contacts</h1>
        <div className="grid grid-cols-2 items-center gap-x-12 gap-y-6">
          {crmStages.map(({ id, label }) => (
            <SyncRow
              key={id}
              label={label}
              sync={!crmFilters.some((f) => f.field === id)}
              onToggle={(newValue: boolean) =>
                setCrmFilters(
                  newValue ? crmFilters.filter((f) => f.field !== id) : crmFilters.concat({ field: id, sync: false }),
                )
              }
            />
          ))}
        </div>
      </section>
    </div>
  )
}

type SyncRowProps = {
  label: string
  sync: boolean
  onToggle: (newValue: boolean) => void
}

function SyncRow(props: SyncRowProps) {
  const { label, sync, onToggle } = props
  return (
    <>
      <Button
        variant="leading-icon"
        type="button"
        icon={sync ? Check : Plus}
        className={clsx('w-full justify-start self-center truncate', sync && 'border-accent')}
        onClick={() => onToggle(!sync)}
      >
        {label}
      </Button>
      <p className="text-sm leading-5 tracking-wide text-medium">{sync ? 'Sync' : 'Don’t sync'}</p>
    </>
  )
}
