import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Blueprint, PatchBlueprint, PostBlueprint } from '@/api/core'
import { Button, ButtonLoading } from '@/ui/Button/v2'
import { Input } from '@/ui/Input/v2'

type BlueprintFormProps = {
  data: Blueprint | undefined
  onSubmit: (body: PostBlueprint | PatchBlueprint) => void
  onCancel: () => void
  isLoading: boolean
}

export function BlueprintForm(props: BlueprintFormProps) {
  const { data, onSubmit, onCancel, isLoading } = props
  const schema = yup.object().shape({
    name: yup.string().required('Name required'),
    companyDescription: yup.string().required('Company description required'),
    valuePropositions: yup.string().required('Value propositions required'),
    jobTitles: yup.string().required('Job titles required'),
  })

  const newBlueprint = data === undefined
  const defaultValues = data
    ? {
        name: data.name,
        companyDescription: data.companyDescription,
        valuePropositions: data.valuePropositions,
        jobTitles: data.jobTitles,
      }
    : {}

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PostBlueprint>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col items-center justify-end">
        <div className="w-full">
          <Input
            name="name"
            id="name"
            type="text"
            label="Name"
            placeholder=""
            error={errors.name}
            registration={register('name')}
            autoComplete="off"
          />
        </div>
        <div className="w-full">
          <Input
            name="companyDescription"
            id="companyDescription"
            type="text"
            label="Company description"
            placeholder=""
            error={errors.companyDescription}
            registration={register('companyDescription')}
            autoComplete="off"
          />
        </div>
        <div className="w-full">
          <Input
            name="valuePropositions"
            id="valuePropositions"
            type="text"
            label="Value propositions"
            placeholder=""
            error={errors.valuePropositions}
            registration={register('valuePropositions')}
          />
        </div>
        <div className="w-full">
          <Input
            name="jobTitles"
            id="jobTitles"
            type="text"
            label="Job titles"
            placeholder=""
            error={errors.jobTitles}
            registration={register('jobTitles')}
          />
        </div>
      </div>
      <div className="box-border flex w-full justify-end gap-2 bg-white py-4">
        <Button onClick={onCancel}>Cancel</Button>
        <ButtonLoading type="submit" variant="accent" isLoading={isLoading}>
          {newBlueprint ? 'Create' : 'Save'}
        </ButtonLoading>
      </div>
    </form>
  )
}
