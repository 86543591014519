/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum WorkspaceViewId {
    WORKSPACES = 'WORKSPACES',
    PASSWORD_VAULT = 'PASSWORD_VAULT',
    SETTINGS_SALESFORCE_INTEGRATION = 'SETTINGS.SALESFORCE_INTEGRATION',
    SETTINGS_HUBSPOT_INTEGRATION = 'SETTINGS.HUBSPOT_INTEGRATION',
    SEQUENCES_V2 = 'SEQUENCES.V2',
    SEQUENCES_SCHEDULE = 'SEQUENCES.SCHEDULE',
    SEQUENCES_LABELS = 'SEQUENCES.LABELS',
    MEETINGS = 'MEETINGS',
    DIALER = 'DIALER',
    IMPORT_V2 = 'IMPORT.V2',
    RECOMMENDATIONS = 'RECOMMENDATIONS',
    WRITING_ASSISTANCE = 'WRITING.ASSISTANCE',
    SMTP_REPLY_TO_HEADER = 'SMTP.REPLY.TO.HEADER',
    GOOGLE_INBOX = 'GOOGLE.INBOX',
}
