import { Fragment, useMemo, useState } from 'react'
import clsx from 'clsx'
import { Dialog, Transition } from '@headlessui/react'
import { useCampaignAppendContactList, useCampaignAttachContactList } from '@/api/campaigns'
import { useContactListsCampaignAssignable } from '@/api/contact_lists'
import { ContactList } from '@/api/core'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, OpacityTransition, SearchBar, Text } from '@/ui'

type Props = {
  open: boolean
  onClose: () => void
  campaignId: string
  append?: boolean
}

export function AssignContactListDialog(props: Props) {
  const { open, onClose, campaignId, append } = props
  const { data } = useContactListsCampaignAssignable()
  const attachContactList = useCampaignAttachContactList()
  const appendContactList = useCampaignAppendContactList()
  const toast = useToast()
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState<ContactList | null>(null)

  const onSearch = (q: string) => {
    setSearch(q)
    setSelected(null)
  }

  const onSubmit = () => {
    if (!data || !data.data || selected === null) return
    if (selected.permissions.addToSequence.deny) {
      toast.createToast({ message: selected.permissions.addToSequence.reason, error: true })
      onClose()
      setSearch('')
      setSelected(null)
      return
    }

    const mut = append ? appendContactList : attachContactList
    mut.mutate(
      { campaignId: campaignId, contact_list_id: selected.id },
      {
        onSuccess: () => {
          onClose()
          setSearch('')
          setSelected(null)
          toast.createToast({ message: 'Contact list added' })
        },
        onError: (err: Error) => {
          toast.createToast({ message: (err as any)?.body?.message || 'Failed to add contact list' })
        },
      },
    )
  }

  const filteredData = useMemo(() => {
    return (data?.data || []).filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
  }, [data, search])

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={props.onClose}>
          <OpacityTransition show={true}>
            <div className="fixed inset-0 bg-extra-light bg-opacity-60" />
          </OpacityTransition>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-sm transform rounded-lg border bg-white p-4 text-left align-middle shadow-rift transition-all">
                  <Dialog.Title as={Fragment}>
                    <Text variant="subtitle">Choose contact list</Text>
                  </Dialog.Title>

                  <div className="mt-4 flex">
                    <div className="flex-1">
                      <SearchBar value={search} onChange={onSearch} />
                    </div>
                  </div>

                  <div className="flex h-[50vh] max-h-96 flex-col gap-1 overflow-auto">
                    {!data?.data?.length && (
                      <div className="mt-8 text-center text-gray-500">No contact list available</div>
                    )}
                    {filteredData.map((item) => (
                      <button
                        key={item.id}
                        className={clsx(
                          'w-full px-1 text-left text-sm hover:bg-gray-50',
                          selected?.id === item.id && 'rounded-md bg-light-blue font-medium',
                        )}
                        onClick={() => setSelected(item)}
                      >
                        {item.name}
                      </button>
                    ))}
                  </div>
                  <div className="-mb-4 flex gap-6 pt-2">
                    <Button variant="text" className="ml-auto p-0" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button variant="text" className="p-0" disabled={selected === null} onClick={onSubmit}>
                      {append ? 'Append' : 'Add'}
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
