import React, { TextareaHTMLAttributes, useId } from 'react'
import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

const Variants = {
  basic:
    'w-full label rounded-lg border border-light outline-2 outline-accent py-2 px-3 disabled:bg-extra-light disabled:text-medium active:duration-200 active:ease-out hover:ring-1',
}

export type InputProps = {
  label?: string
  error?: FieldError | string
  registration?: Partial<UseFormRegisterReturn>
  labelClassName?: string
  containerClassName?: string
}

export function Textarea(props: TextareaHTMLAttributes<HTMLTextAreaElement> & InputProps) {
  const { label, error, registration, labelClassName, containerClassName, ...rest } = props

  const id = useId()

  return (
    <div className={twMerge('my-1.5', containerClassName)}>
      {label && (
        <label htmlFor={id} className={twMerge('label', labelClassName)}>
          {label}
        </label>
      )}
      <textarea
        id={id}
        rows={2}
        {...rest}
        className={twMerge(Variants['basic'], props.className, error && 'border-alert outline-alert')}
        {...registration}
      />
      {error && <p className="label text-alert">{typeof error === 'string' ? error : error?.message}</p>}
    </div>
  )
}
