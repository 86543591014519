import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Profile } from '@/api/core'
import { useMemberProfile, useUpdateMemberProfile } from '@/api/user'
import { Availability as MeetingAvailability } from '@/pages/Meetings/Availability'
import { DefaultAvailability } from '@/pages/Meetings/NewMeetingType'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Text, TimezoneSelect } from '@/ui'

export function Availability() {
  const toast = useToast()
  const { data } = useMemberProfile()
  const user = data?.data
  const { control, handleSubmit, reset, register } = useForm<Profile>()

  const updateProfile = useUpdateMemberProfile()
  useEffect(() => {
    if (user) {
      reset({ availability: DefaultAvailability, ...user })
    }
  }, [user])
  return (
    <section>
      <Text variant="title" className="pb-2">
        Availability
      </Text>
      <Text className="pb-6">
        Your available working hours for use in meeting schedules. You can fine tune your availability for specific
        meeting types when creating them.
      </Text>
      <form
        onSubmit={handleSubmit((form) =>
          updateProfile.mutate(form, {
            onSuccess: () => {
              toast.createToast({ message: 'Successfully updated profile' })
            },
            onError: () => {
              toast.createToast({ message: 'Error updating profile', error: true })
            },
          }),
        )}
      >
        <Text className="text-sm" variant="subtitle">
          Timezone
        </Text>
        <TimezoneSelect name={'timezone'} control={control} />
        <MeetingAvailability register={register} control={control} availability={user?.availability} className="mt-6" />
        <Button type="submit" variant="accent" className="ml-auto mt-4">
          Save
        </Button>
      </form>
    </section>
  )
}
