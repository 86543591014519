import arrowDoubleSVG from '@/assets/arrow-double.svg'

export function ArrowDoubleIcon({ width, height, className }: { width?: number; height?: number; className?: string }) {
  return (
    <img
      width={width}
      height={height}
      className={className}
      src={arrowDoubleSVG}
      alt="Two arrow that are pointing left and right."
    />
  )
}
