import { format, formatDistanceToNow, intervalToDuration, parse } from 'date-fns'
import { enUS } from 'date-fns/locale'
import dayjs from 'dayjs'

export function Unix(seconds: number) {
  return new Date(seconds * 1000)
}

// format unix timestamp to string date. It skips current year by default
export function UnixFormat(seconds: number, opt?: { curYear?: boolean }) {
  const d = Unix(seconds)
  const year = new Date().getFullYear()

  const dateFormat = !opt?.curYear && d.getFullYear() === year ? 'd LLL h:mm a' : 'd LLL yyyy h:mm a'
  return format(d, dateFormat)
}

/*
 * expireDuration returns the duration in milliseconds until the given timestamp
 * If the timestamp is in the past, it returns 0.
 */
export function expireDuration(expiresAt: number) {
  return Math.max(0, Unix(expiresAt).getTime() - Date.now())
}

export function dateOnlyRFC3999Format(d: Date) {
  return format(d, 'yyyy-MM-dd')
}
export function parseDate(d: string) {
  return parse(d, 'yyyy-MM-dd', new Date())
}

export function dateFormat(
  d: Date | number,
  options?: {
    curYear?: boolean
  },
) {
  const { curYear = true } = options || {}

  if (typeof d === 'string') {
    d = new Date(d)
  } else if (typeof d === 'number') {
    d = Unix(d)
  }
  const year = new Date().getFullYear()

  const dateFormat = d.getFullYear() !== year || curYear ? 'LLL d, yyyy' : 'LLL d'
  return format(d, dateFormat)
}

/*
 * dateOrTimeFormat returns a time duration (1 hour ago, 2 days ago, etc) if the date is today
 * otherwise it returns the date in the format LLL d, yyyy
 */
export function dateOrTimeFormat(d: Date | number, options?: { datePrefix?: string; timeSuffix?: string }) {
  if (typeof d === 'number') {
    d = Unix(d)
  }
  const today = new Date()
  if (d.getDate() === today.getDate() && d.getMonth() === today.getMonth() && d.getFullYear() === today.getFullYear()) {
    return durationTextSince(d) + (options?.timeSuffix || '')
  }
  return (options?.datePrefix || '') + dateFormat(d)
}

export function durationSince(start: Date, baseValue = false) {
  const d = intervalToDuration({ start, end: new Date() })
  if (d.years) {
    return baseValue ? `${d.years}y` : `Updated ${d.years}y ago`
  }
  if (d.months) {
    return baseValue ? `${d.months}mo` : `Updated ${d.months}mo ago`
  }
  if (d.days) {
    return baseValue ? `${d.days}d` : `Updated ${d.days}d ago`
  }
  if (d.hours) {
    return baseValue ? `${d.hours}h` : `Updated ${d.hours}h ago`
  }
  if (d.minutes) {
    return baseValue ? `${d.minutes}m` : `Updated ${d.minutes}m ago`
  }
  return baseValue ? `now` : `Updated now`
}

export function durationTextSince(start: Date) {
  const d = intervalToDuration({ start, end: new Date() })
  if (d.years) {
    return `${d.years}y`
  }
  if (d.months) {
    return `${d.months}mo`
  }
  if (d.days) {
    return `${d.days}d`
  }
  if (d.hours) {
    return `${d.hours}h`
  }
  if (d.minutes) {
    return `${d.minutes}m`
  }
  return `sec`
}

export function timeAgo(timeInUnix: number) {
  return <span title={UnixFormat(timeInUnix)}>{dayjs(Unix(timeInUnix)).fromNow()}</span>
}

export function timeFromNow(time: number | Date) {
  const options = {
    locale: {
      ...enUS,
      formatDistance: (unit: string, count: number) => {
        switch (unit) {
          case 'xDays':
            return `${count}d ago`

          case 'aboutXHours':
          case 'xHours':
            return `${count}h ago`

          case 'halfAMinute':
          case 'lessThanXMinutes':
          case 'xMinutes':
            return `${count} min ago`

          case 'aboutXWeeks':
          case 'xWeeks':
            return `${count}w ago`

          case 'aboutXMonths':
          case 'xMonths':
            return `${count} mo ago`

          case 'xSeconds':
            return 'just now'

          case 'aboutXYears':
          case 'almostXYears':
          case 'overXYears':
          case 'xYears':
            return `${count}y ago`
        }

        return `${count}`
      },
    },
  }

  return formatDistanceToNow(time, options)
}
