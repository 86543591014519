import { useState } from 'react'
import { UiNode, UiNodeInputAttributes } from '@ory/client'

export function useSCRFToken() {
  const [token, setToken] = useState<null | string>(null)

  function setCSRFToken(uiNodes: UiNode[]) {
    for (const node of uiNodes) {
      if (node.type === 'input') {
        const attributes: UiNodeInputAttributes = node.attributes as UiNodeInputAttributes
        if (attributes.name === 'csrf_token') {
          setToken(attributes.value)
          return attributes.value as string
        }
      }
    }
  }

  return { setCSRFToken, csrfToken: token }
}
