import { forwardRef, Fragment, Ref } from 'react'
import { Menu as HeadlessMenu, MenuButtonProps, MenuItemProps, MenuItemsProps, MenuProps } from '@headlessui/react'
import { OpacityTransition } from '@/ui/Transition/OpacityTransition'
import { formatClassName, uirender } from '../render'

function Menu(props: Omit<MenuProps<'div'>, 'as'>, ref: Ref<HTMLDivElement>) {
  const { className, ...rest } = props
  return <HeadlessMenu ref={ref} as="div" className={formatClassName('relative inline-block', className)} {...rest} />
}

function Button(props: MenuButtonProps<'button' | 'div'>, ref: Ref<HTMLButtonElement>) {
  const { className, ...rest } = props
  return <HeadlessMenu.Button className={formatClassName('cursor-default', className)} ref={ref} {...rest} />
}

function Items(props: MenuItemsProps<'div'>, ref: Ref<HTMLDivElement>) {
  const { children, className, ...rest } = props
  return (
    <OpacityTransition>
      <HeadlessMenu.Items
        ref={ref}
        as="div"
        className={formatClassName('absolute z-10 mt-2 flex flex-col rounded-md bg-white shadow-rift', className)}
        {...rest}
      >
        {(slot) => <>{uirender({ ourProps: {}, theirProps: { children }, slot, defaultTag: Fragment })}</>}
      </HeadlessMenu.Items>
    </OpacityTransition>
  )
}

function Item(props: MenuItemProps<'button'>, ref: Ref<HTMLButtonElement>) {
  const { children, className, ...rest } = props
  return (
    <HeadlessMenu.Item
      ref={ref}
      as="button"
      type="button"
      className={formatClassName(
        'inline-flex w-full cursor-default items-center whitespace-nowrap rounded-md px-4 py-2 text-left text-sm text-dark hover:bg-accent-light',
        className,
      )}
      {...rest}
    >
      {(slot) => <>{uirender({ ourProps: {}, theirProps: { children }, slot, defaultTag: Fragment })}</>}
    </HeadlessMenu.Item>
  )
}

export const DropdownMenu = Object.assign(forwardRef(Menu), {
  Button: forwardRef(Button),
  Items: forwardRef(Items),
  Item: forwardRef(Item),
})
