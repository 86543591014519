import { GlobalBannerProps } from './types'

export type Action =
  | { type: 'add'; body: { banner: JSX.Element } }
  | { type: 'remove'; body: { key: string } }
  | { type: 'remove_all' }

export function reducer(state: GlobalBannerProps, action: Action) {
  switch (action.type) {
    case 'add': {
      // Check if the banner is already dismissed and key is set in localStorage
      const isDismissedAlready = action.body.banner.key && localStorage.getItem(action.body.banner.key)

      // Check if the banner is already in the state or is dismissed
      // (Need to check if already in state for dev env since react renders twice in dev mode and it causes the same banner to be added twice)
      if (isDismissedAlready || state.banners.filter((banner) => banner.key === action.body.banner.key).length > 0) {
        return state
      }

      return {
        banners: [...state.banners, action.body.banner],
      }
    }
    case 'remove': {
      // Dismiss the banner by setting the key in localStorage
      localStorage.setItem(action.body.key, 'true')

      return {
        banners: state.banners.filter((banner) => banner.key !== action.body.key),
      }
    }
    case 'remove_all': {
      return {
        banners: [],
      }
    }
  }
}
