import { PaymentPriceType } from '@/api/core'

export const PaymentPriceTypeToText: Record<PaymentPriceType, string> = {
  [PaymentPriceType.STARTER_MONTHLY]: 'Starter Monthly',
  [PaymentPriceType.STARTER_YEARLY]: 'Starter Yearly',
  [PaymentPriceType.GROWTH_MONTHLY]: 'Growth Monthly',
  [PaymentPriceType.GROWTH_YEARLY]: 'Growth Yearly',
  [PaymentPriceType.CUSTOM]: 'Custom',
}

// NOTE: this should be returned from stripe (through the backend),
// but it's here to save the implementation time
export const PaymentPriceTypeToCash: Record<PaymentPriceType, string> = {
  [PaymentPriceType.STARTER_MONTHLY]: '$417',
  [PaymentPriceType.STARTER_YEARLY]: '$5,000',
  [PaymentPriceType.GROWTH_MONTHLY]: '$833.33',
  [PaymentPriceType.GROWTH_YEARLY]: '$10,000',
  [PaymentPriceType.CUSTOM]: 'contact us',
}
export const TextToPaymentPriceType: Record<string, PaymentPriceType> = Object.fromEntries(
  Object.entries(PaymentPriceType).map(([key, value]) => [value, key as PaymentPriceType]),
)

export const PaymentPriceTypeLabels: { label: string; value: PaymentPriceType }[] = Object.entries(
  PaymentPriceTypeToText,
).map(([value, label]) => ({
  label: label + ` (${PaymentPriceTypeToCash[value as PaymentPriceType]})`,
  value: value as PaymentPriceType,
}))

export type PaymentPriceTypeLabel = (typeof PaymentPriceTypeLabels)[number]
