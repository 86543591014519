import { Navigate, Route, Routes } from 'react-router-dom'
import { ForgotPassword } from '@/pages/ForgotPassword'
import { Login } from '@/pages/Login'
import { Index } from '@/pages/meetingsportal'
import { Register } from '@/pages/Register'
import { Support } from '@/pages/Support'
import { Unsubscribe } from '@/pages/Unsubscribe'
import { Verification } from '@/pages/Verification'
import { ZoomDocumentation } from '@/pages/ZoomDocumentation'

export function PublicRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} index />
      <Route path="/registration" element={<Register />} index />
      <Route path="/login" element={<Login />} index />
      <Route path="/recovery" element={<ForgotPassword />} index />
      <Route path="/verification" element={<Verification isPublic />} index />
      <Route path="/demo" element={<Navigate to="/book/rift/rift-demo-fil" replace />} />
      <Route path="/book/:company/:meeting_type" element={<Index />} />
      <Route path="/support" element={<Support />} />
      <Route path="/documents/zoom" element={<ZoomDocumentation />} />
      <Route path="/unsubscribe/:token" element={<Unsubscribe />} />
      <Route path="*" element={<Login />} />
    </Routes>
  )
}
