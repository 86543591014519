import { HighFive } from '@/icons/highfive'
import { Text } from '@/ui'

export function Congratulations() {
  return (
    <div className="flex flex-col items-center">
      <h1 className="mb-6 text-lg font-medium">Congratulations and welcome aboard!</h1>
      <HighFive />
      <Text>Wait until we finish setting up your account.</Text>
      <Text>It takes up to 2 days, we let you know once it's done.</Text>
    </div>
  )
}
