import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useDebounce } from 'use-debounce'
import { Disclosure } from '@headlessui/react'
import { CaretRight } from '@phosphor-icons/react'
import { useCampaignDailyCapacity, useCampaignUpdate } from '@/api/campaigns'
import { Campaign } from '@/api/core'
import { FeatureFlag } from '@/containers/FeatureFlag'
import { useCurrentTime } from '@/hooks'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { OpacityTransition, PermissionTooltip, Switch, Tooltip } from '@/ui'
import { DDVSlider } from './DDVSlider'
import { TypeSection } from './TypeSection'

export function AdvancedSettings({ campaign }: { campaign: Campaign }) {
  const curTime = useCurrentTime({ timezone: campaign.timezone })
  return (
    <section className="py-3">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full items-center">
              <span className="text-desk text-sm font-medium">Advanced settings</span>
              <CaretRight className={clsx('ml-auto h-4 w-4 text-medium', open && 'rotate-90 transform')} />
            </Disclosure.Button>
            <OpacityTransition>
              <Disclosure.Panel>
                <div className="flex flex-col gap-2">
                  <TypeSection campaign={campaign} />
                  <CapacitySlider campaign={campaign} />
                  <PlainTextOnlySwitch campaign={campaign} />
                  <ContactTimezoneSwitch campaign={campaign} />
                  <FeatureFlag>
                    <UnsubscribeHeaderSwitch campaign={campaign} />
                    <AutoStopSwitch campaign={campaign} />
                    <p className="-right text-sm text-dusk">current time: {curTime}</p>
                  </FeatureFlag>
                </div>
              </Disclosure.Panel>
            </OpacityTransition>
          </>
        )}
      </Disclosure>
    </section>
  )
}

function CapacitySlider({ campaign }: { campaign: Campaign }) {
  const toast = useToast()
  const updateCampaign = useCampaignUpdate(campaign.id)
  const { data } = useCampaignDailyCapacity(campaign.id)
  const dailyCapacity = data?.data

  const [capacity, setCapacity] = useState(campaign.max_daily_capacity)
  const [capacityDebounced] = useDebounce(capacity, 100)
  useEffect(() => {
    updateCampaign.mutate(
      { max_daily_capacity: capacity },
      {
        onError: (err: unknown) => {
          toast.createToast({ message: (err as any)?.body?.message || "Can't update this setting", error: true })
        },
      },
    )
  }, [capacityDebounced])

  if (!dailyCapacity) return null

  const disabled = dailyCapacity.permissions.editDDV.deny

  return (
    <div>
      <PermissionTooltip permission={dailyCapacity.permissions.editDDV}>
        <DDVSlider
          disabledPermissions={campaign.permissions.change_schedule.deny}
          disabled={disabled}
          id="campaign-capacity-slider"
          total={dailyCapacity.totalDailyCapacity}
          min={dailyCapacity.minDailyCapacity}
          max={dailyCapacity.maxDailyCapacity}
          value={capacity}
          onChange={(val) => {
            setCapacity(Math.round(val))
          }}
          label="Dedicated daily volume"
        />
      </PermissionTooltip>
    </div>
  )
}

export function UnsubscribeHeaderSwitch({ campaign }: { campaign: Campaign }) {
  const updateCampaign = useCampaignUpdate(campaign.id)
  const toast = useToast()

  const onChange = (val: boolean) => {
    updateCampaign.mutate(
      { unsubscribeHeader: val },
      {
        onError: (err: unknown) => {
          toast.createToast({
            message: (err as any)?.body?.message || "Can't update unsubscribe header setting",
            error: true,
          })
        },
      },
    )
  }
  return (
    <div className="flex gap-4">
      <Switch onChange={onChange} checked={campaign.unsubscribeHeader} />
      <h2 className="content-md mb-3 leading-6">Unsubscribe header</h2>
    </div>
  )
}

export function PlainTextOnlySwitch({ campaign }: { campaign: Campaign }) {
  const updateCampaign = useCampaignUpdate(campaign.id)
  const toast = useToast()

  const onChange = (plainTextOnly: boolean) => {
    updateCampaign.mutate(
      { plainTextOnly: plainTextOnly },
      {
        onError: (err: unknown) => {
          toast.createToast({
            message: (err as any)?.body?.message || "Can't update plain text only setting",
            error: true,
          })
        },
      },
    )
  }

  return (
    <PermissionTooltip permission={campaign.permissions.editPlainTextMode}>
      <div className="flex gap-4">
        <Switch
          onChange={onChange}
          checked={campaign.plainTextOnly}
          disabled={campaign.permissions.editPlainTextMode.deny}
          data-testid="plain-text-only-switch"
        />
        <h2 className="content-md mb-3 leading-6">Plain text only</h2>
      </div>
    </PermissionTooltip>
  )
}

export function AutoStopSwitch({ campaign }: { campaign: Campaign }) {
  const updateCampaign = useCampaignUpdate(campaign.id)
  const toast = useToast()

  const onChange = (checked: boolean) => {
    updateCampaign.mutate(
      { auto_stop_enabled: checked },
      {
        onError: (err: unknown) => {
          toast.createToast({ message: (err as any)?.body?.message || "Can't update auto stop setting", error: true })
        },
      },
    )
  }
  return (
    <div className="flex gap-4">
      <Switch onChange={onChange} checked={campaign.auto_stop_enabled} />
      <h2 className="content-md mb-3 leading-6">Auto-stop on deliverability issue anomalies</h2>
    </div>
  )
}

export function ContactTimezoneSwitch({ campaign }: { campaign: Campaign }) {
  const updateCampaign = useCampaignUpdate(campaign.id)
  const toast = useToast()

  const onChange = (checked: boolean) => {
    updateCampaign.mutate(
      { sendInContactTimezone: checked },
      {
        onError: (err: unknown) => {
          toast.createToast({
            message: (err as any)?.body?.message || "Can't update send in contact's timezone",
            error: true,
          })
        },
      },
    )
  }

  return (
    <Tooltip>
      <Tooltip.Trigger>
        <div className="flex gap-4">
          <Switch
            onChange={onChange}
            checked={campaign.sendInContactTimezone}
            disabled={campaign.permissions.editSendInContactTimezone.deny}
            data-testid="contacts-timezone-switch"
          />
          <h2 className="content-md mb-3 leading-6">Send in contact's timezone</h2>
        </div>
      </Tooltip.Trigger>
      {campaign.permissions.editSendInContactTimezone?.deny && (
        <Tooltip.Panel>
          {campaign.permissions.editSendInContactTimezone.reason}
          <br />
          {campaign.permissions.editSendInContactTimezone.action}
        </Tooltip.Panel>
      )}
      {!campaign.permissions.editSendInContactTimezone?.deny && (
        <Tooltip.Panel>
          If city or timezone is provided, send according to that timezone for that recipient. If no timezone or city
          uploaded, default to the main timezone set for the sequence.
        </Tooltip.Panel>
      )}
    </Tooltip>
  )
}
