import { useContactActivities } from '@/api/contacts'
import { Text } from '@/ui'
import { ActivityFeed } from '../Activity/ActivityFeed'

type ContactActivityFeedProps = {
  contactId: string
}

export function ContactActivityFeed(props: ContactActivityFeedProps) {
  const { contactId } = props
  const { data: activitiesData } = useContactActivities(contactId)

  const activities = activitiesData?.data?.events

  return activities === undefined ? (
    <div className="skeleton mb-2 h-48 w-full"></div>
  ) : (
    <>
      <Text className="pb-2 pt-6 font-medium text-dusk">Interaction history</Text>
      <ActivityFeed activities={activities} context="contact" />
    </>
  )
}
