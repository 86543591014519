import clsx from 'clsx'
import { Text } from '@/ui/Text'

export type ToastProps = {
  message: string
  error?: boolean
}

export function Toast(props: ToastProps) {
  const { message, error } = props
  return (
    <section
      className={clsx(
        'w-fit rounded-lg px-4 py-3 shadow-rift backdrop-blur-[10px]',
        error ? 'bg-[#FE734C] text-dusk' : 'bg-dusk text-white',
      )}
      aria-label={`toast: ${message}`}
    >
      <Text>{message}</Text>
    </section>
  )
}
