import { WorkspaceMemberRole } from '@/api/core'

export const WorkspaceMemberRoleKeys = Object.keys(WorkspaceMemberRole) as WorkspaceMemberRole[]

export const WorkspaceMemberRoleToText: Record<WorkspaceMemberRole, string> = {
  [WorkspaceMemberRole.ADMIN]: 'Admin',
  [WorkspaceMemberRole.MEMBER]: 'Member',
}

export const TextToWorkspaceMemberRole: Record<string, WorkspaceMemberRole> = Object.fromEntries(
  Object.entries(WorkspaceMemberRole).map(([key, value]) => [value, key as WorkspaceMemberRole]),
)

export const WorkspaceMemberRoleLabels: { label: string; value: WorkspaceMemberRole }[] = Object.entries(
  WorkspaceMemberRoleToText,
).map(([value, label]) => ({
  label: label,
  value: value as WorkspaceMemberRole,
}))

export type WorkspaceMemberRoleLabel = (typeof WorkspaceMemberRoleLabels)[number]
