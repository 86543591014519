import { ElementType, forwardRef, Fragment, Ref } from 'react'
import clsx from 'clsx'
import {
  Listbox as HListbox,
  ListboxButtonProps,
  ListboxOptionProps,
  ListboxOptionsProps,
  ListboxProps,
} from '@headlessui/react'
import { HasDisplayName, RefProp } from '@headlessui/react/dist/utils/render'
import { Check } from '@phosphor-icons/react'
import { Button as UIButton, OpacityTransition } from '@/ui'
import { formatClassName } from '@/ui/render'

const DEFAULT_LISTBOX_TAG = Fragment

function ListboxFn<TType = string, TActualType = TType extends (infer U)[] ? U : TType>(
  props: ListboxProps<typeof Fragment, TType, TActualType>,
  ref: Ref<HTMLElement>,
) {
  const { children, ...rest } = props
  return (
    <HListbox {...(rest as typeof props)} ref={ref}>
      {(slot) => <>{typeof children === 'function' ? children(slot) : children}</>}
    </HListbox>
  )
}

const DEFAULT_BUTTON_TAG = UIButton

function ButtonFn(props: ListboxButtonProps<typeof DEFAULT_BUTTON_TAG>, ref: Ref<HTMLButtonElement>) {
  const { children, ...rest } = props
  return (
    <HListbox.Button as={UIButton} {...rest} ref={ref}>
      {(slot) => <>{typeof children === 'function' ? children(slot) : children}</>}
    </HListbox.Button>
  )
}

const DEFAULT_OPTIONS_TAG = 'ul' as const

function OptionsFn(props: ListboxOptionsProps<typeof DEFAULT_OPTIONS_TAG>, ref: Ref<HTMLElement>) {
  const { children, className, ...rest } = props
  return (
    <OpacityTransition>
      <HListbox.Options
        {...rest}
        ref={ref}
        className={formatClassName(
          'absolute z-10 mt-0.5 max-h-96 w-fit overflow-auto rounded-lg border border-light bg-white p-1 shadow-rift',
          className,
        )}
      >
        {(slot) => <>{typeof children === 'function' ? children(slot) : children}</>}
      </HListbox.Options>
    </OpacityTransition>
  )
}

const DEFAULT_OPTION_TAG = 'li' as const

function OptionFn<TType = string>(props: ListboxOptionProps<typeof DEFAULT_OPTION_TAG, TType>, ref: Ref<HTMLElement>) {
  const { children, className, ...rest } = props
  return (
    <HListbox.Option
      {...rest}
      ref={ref}
      className={formatClassName(
        clsx(
          'relative w-full cursor-default select-none overflow-y-hidden truncate whitespace-nowrap rounded-sm py-2 pl-3 pr-9 text-dark',
          !rest.disabled && 'hover:bg-accent-light',
        ),
        className,
      )}
    >
      {(slot) => (
        <>
          {typeof children === 'function' ? children(slot) : children}
          {slot.selected && (
            <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-medium">
              <Check className="size-3.5" aria-hidden="true" />
            </span>
          )}
        </>
      )}
    </HListbox.Option>
  )
}

interface ComponentListbox extends HasDisplayName {
  <
    TTag extends ElementType = typeof DEFAULT_LISTBOX_TAG,
    TType = string,
    TActualType = TType extends (infer U)[] ? U : TType,
  >(
    props: ListboxProps<TTag, TType, TActualType> & RefProp<typeof ListboxFn>,
  ): JSX.Element
}

const ListboxRoot = forwardRef(ListboxFn) as unknown as ComponentListbox
const Button = forwardRef(ButtonFn)
const Options = forwardRef(OptionsFn)
const Option = forwardRef(OptionFn)

export const Listbox = Object.assign(ListboxRoot, { Button, Options, Option })
