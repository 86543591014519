import { EditorView } from '@tiptap/pm/view'

// forcePasteAsPlainText captures pasting and ensures that we are grabbing data from
// the plain text clipboard as opposed to the HTML one.
// Sourced from: https://stackoverflow.com/a/34876744
export function forcePasteAsPlainText(_view: EditorView, e: ClipboardEvent) {
  e.preventDefault()
  let text = ''
  if (e.clipboardData || (e as any).originalEvent.clipboardData) {
    text = ((e as any).originalEvent || e).clipboardData.getData('text/plain')
  } else if ((window as any).clipboardData) {
    text = (window as any).clipboardData.getData('Text')
  }
  if (document.queryCommandSupported('insertText')) {
    document.execCommand('insertText', false, text)
  } else {
    document.execCommand('paste', false, text)
  }
  return true
}
