import { ElementType, Ref, useMemo } from 'react'
import { Props } from '@headlessui/react/dist/types'
import { useSyncRefs } from '@/hooks/useSyncRefs'
import { uirender } from '@/ui/render'
import { useData } from './Context'

export const DEFAULT_PANELS_TAG = 'div'
export interface PanelsRenderPropArg {}

export type StepPanelsProps<TTag extends ElementType> = Props<TTag, PanelsRenderPropArg>

export function Panels<TTag extends ElementType = typeof DEFAULT_PANELS_TAG>(
  props: StepPanelsProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { currentIndex } = useData('Step.Panels')
  const panelsRef = useSyncRefs(ref)
  const slot = useMemo(() => ({ currentIndex }), [currentIndex])
  const ourProps = { ref: panelsRef }
  return uirender({ ourProps, theirProps: props, slot, defaultTag: DEFAULT_PANELS_TAG })
}
