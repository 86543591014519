/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Account } from '../models/Account';
import type { AccountEnrichment } from '../models/AccountEnrichment';
import type { AccountsList } from '../models/AccountsList';
import type { Approach } from '../models/Approach';
import type { Availability } from '../models/Availability';
import type { Blueprint } from '../models/Blueprint';
import type { Campaign } from '../models/Campaign';
import type { CampaignActivity } from '../models/CampaignActivity';
import type { CampaignAnalytics } from '../models/CampaignAnalytics';
import type { CampaignContactsCustomFieldNames } from '../models/CampaignContactsCustomFieldNames';
import type { CampaignContactsRecentlyAdded } from '../models/CampaignContactsRecentlyAdded';
import type { CampaignContactsSample } from '../models/CampaignContactsSample';
import type { CampaignDailyCapacity } from '../models/CampaignDailyCapacity';
import type { CampaignEstEndDate } from '../models/CampaignEstEndDate';
import type { CampaignLabels } from '../models/CampaignLabels';
import type { CampaignParticipants } from '../models/CampaignParticipants';
import type { CampaignParticipantsAttach } from '../models/CampaignParticipantsAttach';
import type { CampaignParticipantsDetach } from '../models/CampaignParticipantsDetach';
import type { CampaignSchedule } from '../models/CampaignSchedule';
import type { CampaignsDashboard } from '../models/CampaignsDashboard';
import type { CampaignSharees } from '../models/CampaignSharees';
import type { CampaignsList } from '../models/CampaignsList';
import type { CampaignsMetrics } from '../models/CampaignsMetrics';
import type { CampaignSortField } from '../models/CampaignSortField';
import type { ChameleonUser } from '../models/ChameleonUser';
import type { ChameleonUsers } from '../models/ChameleonUsers';
import type { Company } from '../models/Company';
import type { CompanyApiKeyCreate } from '../models/CompanyApiKeyCreate';
import type { CompanyApiKeyPermissions } from '../models/CompanyApiKeyPermissions';
import type { CompanyApiKeys } from '../models/CompanyApiKeys';
import type { CompanyOffDays } from '../models/CompanyOffDays';
import type { CompanyOffDaysPermissions } from '../models/CompanyOffDaysPermissions';
import type { CompanyProduct } from '../models/CompanyProduct';
import type { ConnectionPermissions } from '../models/ConnectionPermissions';
import type { ContactList } from '../models/ContactList';
import type { ContactLists } from '../models/ContactLists';
import type { ContactsV2 } from '../models/ContactsV2';
import type { ContactV2 } from '../models/ContactV2';
import type { Events } from '../models/Events';
import type { EventType } from '../models/EventType';
import type { GenerateApproachRequest } from '../models/GenerateApproachRequest';
import type { GroupedMeetings } from '../models/GroupedMeetings';
import type { HubspotActiveLists } from '../models/HubspotActiveLists';
import type { HubspotContactDetails } from '../models/HubspotContactDetails';
import type { HubspotDataMappings } from '../models/HubspotDataMappings';
import type { HubspotSettings } from '../models/HubspotSettings';
import type { HubspotSettingsPermissions } from '../models/HubspotSettingsPermissions';
import type { HubspotSettingsStatus } from '../models/HubspotSettingsStatus';
import type { Inboxes } from '../models/Inboxes';
import type { InboxGroup } from '../models/InboxGroup';
import type { InboxGroups } from '../models/InboxGroups';
import type { IntercomIdentity } from '../models/IntercomIdentity';
import type { MainInbox } from '../models/MainInbox';
import type { ManualStepCallDeviceToken } from '../models/ManualStepCallDeviceToken';
import type { ManualStepCallInfo } from '../models/ManualStepCallInfo';
import type { ManualStepCalls } from '../models/ManualStepCalls';
import type { ManualStepDone } from '../models/ManualStepDone';
import type { ManualStepDoneCall } from '../models/ManualStepDoneCall';
import type { ManualStepDoneEmail } from '../models/ManualStepDoneEmail';
import type { ManualStepDoneLinkedinMessage } from '../models/ManualStepDoneLinkedinMessage';
import type { ManualStepDoneReply } from '../models/ManualStepDoneReply';
import type { ManualStepEmailInfo } from '../models/ManualStepEmailInfo';
import type { ManualStepEmails } from '../models/ManualStepEmails';
import type { ManualStepInfo } from '../models/ManualStepInfo';
import type { ManualStepLinkedinMessageCopy } from '../models/ManualStepLinkedinMessageCopy';
import type { ManualStepLinkedinMessageInfo } from '../models/ManualStepLinkedinMessageInfo';
import type { ManualStepLinkedinMessages } from '../models/ManualStepLinkedinMessages';
import type { ManualStepRecommendationInfo } from '../models/ManualStepRecommendationInfo';
import type { ManualStepRecommendations } from '../models/ManualStepRecommendations';
import type { ManualStepReplies } from '../models/ManualStepReplies';
import type { ManualStepReplyInfo } from '../models/ManualStepReplyInfo';
import type { MeetingMembers } from '../models/MeetingMembers';
import type { MeetingType } from '../models/MeetingType';
import type { MeetingTypes } from '../models/MeetingTypes';
import type { MeetingTypeTemplateReminderInfo } from '../models/MeetingTypeTemplateReminderInfo';
import type { MemberFeatureFlags } from '../models/MemberFeatureFlags';
import type { MemberOwnerContactsCount } from '../models/MemberOwnerContactsCount';
import type { Members } from '../models/Members';
import type { Metadata } from '../models/Metadata';
import type { Notifications } from '../models/Notifications';
import type { OauthRedirectUrl } from '../models/OauthRedirectUrl';
import type { OauthStatus } from '../models/OauthStatus';
import type { OffsetPagination } from '../models/OffsetPagination';
import type { OnboardingDetails } from '../models/OnboardingDetails';
import type { OnboardingDomains } from '../models/OnboardingDomains';
import type { OnboardingInboxes } from '../models/OnboardingInboxes';
import type { PatchBlueprint } from '../models/PatchBlueprint';
import type { PatchCampaign } from '../models/PatchCampaign';
import type { PatchCampaignAddMissingSenders } from '../models/PatchCampaignAddMissingSenders';
import type { PatchCampaignAttachContactList } from '../models/PatchCampaignAttachContactList';
import type { PatchCampaignContactReassignOwnership } from '../models/PatchCampaignContactReassignOwnership';
import type { PatchCampaignContactRedistribute } from '../models/PatchCampaignContactRedistribute';
import type { PatchCampaignContactsAbandon } from '../models/PatchCampaignContactsAbandon';
import type { PatchCampaignContactsReschedule } from '../models/PatchCampaignContactsReschedule';
import type { PatchCampaignContactsUnsubscribe } from '../models/PatchCampaignContactsUnsubscribe';
import type { PatchCampaignLabels } from '../models/PatchCampaignLabels';
import type { PatchCampaignsArchive } from '../models/PatchCampaignsArchive';
import type { PatchCampaignShareeShare } from '../models/PatchCampaignShareeShare';
import type { PatchCampaignsStop } from '../models/PatchCampaignsStop';
import type { PatchCompany } from '../models/PatchCompany';
import type { PatchCompanyApiKey } from '../models/PatchCompanyApiKey';
import type { PatchCompanyHolidays } from '../models/PatchCompanyHolidays';
import type { PatchCompanyOffDay } from '../models/PatchCompanyOffDay';
import type { PatchCompanyOffDayEnable } from '../models/PatchCompanyOffDayEnable';
import type { PatchContact } from '../models/PatchContact';
import type { PatchContactList } from '../models/PatchContactList';
import type { PatchContactListAddContacts } from '../models/PatchContactListAddContacts';
import type { PatchContactListRemoveContacts } from '../models/PatchContactListRemoveContacts';
import type { PatchContactsOwners } from '../models/PatchContactsOwners';
import type { PatchContactsOwnersSplit } from '../models/PatchContactsOwnersSplit';
import type { PatchContactsUnenroll } from '../models/PatchContactsUnenroll';
import type { PatchContactsUnsubscribe } from '../models/PatchContactsUnsubscribe';
import type { PatchHubspotSettings } from '../models/PatchHubspotSettings';
import type { PatchInboxGroup } from '../models/PatchInboxGroup';
import type { PatchInboxGroupMoveInboxes } from '../models/PatchInboxGroupMoveInboxes';
import type { PatchManualStepCall } from '../models/PatchManualStepCall';
import type { PatchManualStepEmailSave } from '../models/PatchManualStepEmailSave';
import type { PatchManualStepLinkedinMessage } from '../models/PatchManualStepLinkedinMessage';
import type { PatchManualStepReplyDraft } from '../models/PatchManualStepReplyDraft';
import type { PatchMeeting } from '../models/PatchMeeting';
import type { PatchMeetingType } from '../models/PatchMeetingType';
import type { PatchPassword } from '../models/PatchPassword';
import type { PatchPhoneNumber } from '../models/PatchPhoneNumber';
import type { PatchReplyTo } from '../models/PatchReplyTo';
import type { PatchSalesforceConnection } from '../models/PatchSalesforceConnection';
import type { PatchSenderEmail } from '../models/PatchSenderEmail';
import type { PatchSignature } from '../models/PatchSignature';
import type { PatchTeam } from '../models/PatchTeam';
import type { PatchTeamsInboxesMove } from '../models/PatchTeamsInboxesMove';
import type { PatchTeamsMembersMove } from '../models/PatchTeamsMembersMove';
import type { PatchWorkspaceInvitation } from '../models/PatchWorkspaceInvitation';
import type { PatchWorkspaceMember } from '../models/PatchWorkspaceMember';
import type { PatchWorkspaceMemberDelete } from '../models/PatchWorkspaceMemberDelete';
import type { PaymentProducts } from '../models/PaymentProducts';
import type { PhoneNumbers } from '../models/PhoneNumbers';
import type { PhoneNumbersBuyable } from '../models/PhoneNumbersBuyable';
import type { PostBlueprint } from '../models/PostBlueprint';
import type { PostCampaignContactsDelete } from '../models/PostCampaignContactsDelete';
import type { PostCompanyApiKey } from '../models/PostCompanyApiKey';
import type { PostCompanyOffDays } from '../models/PostCompanyOffDays';
import type { PostContactList } from '../models/PostContactList';
import type { PostContacts } from '../models/PostContacts';
import type { PostContactsDelete } from '../models/PostContactsDelete';
import type { PostContactsExport } from '../models/PostContactsExport';
import type { PostContactsResponse } from '../models/PostContactsResponse';
import type { PostContactsUploadCampaignId } from '../models/PostContactsUploadCampaignId';
import type { PostContactsUploadContactList } from '../models/PostContactsUploadContactList';
import type { PostContactsUploadContactListId } from '../models/PostContactsUploadContactListId';
import type { PostContactUploadCampaignId } from '../models/PostContactUploadCampaignId';
import type { PostFieldCleanings } from '../models/PostFieldCleanings';
import type { PostFieldCleaningsResponse } from '../models/PostFieldCleaningsResponse';
import type { PostInboxesMain } from '../models/PostInboxesMain';
import type { PostInboxGroup } from '../models/PostInboxGroup';
import type { PostManualStepCallMeetingLinkSend } from '../models/PostManualStepCallMeetingLinkSend';
import type { PostMeeting } from '../models/PostMeeting';
import type { PostMeetingForLead } from '../models/PostMeetingForLead';
import type { PostMeetingType } from '../models/PostMeetingType';
import type { PostPassword } from '../models/PostPassword';
import type { PostPaymentCheckoutSession } from '../models/PostPaymentCheckoutSession';
import type { PostPhoneNumber } from '../models/PostPhoneNumber';
import type { PostSenderEmails } from '../models/PostSenderEmails';
import type { PostSenderTestSend } from '../models/PostSenderTestSend';
import type { PostTeam } from '../models/PostTeam';
import type { PostWorkspaceInvitationAccept } from '../models/PostWorkspaceInvitationAccept';
import type { PostWorkspaceMember } from '../models/PostWorkspaceMember';
import type { Profile } from '../models/Profile';
import type { PutGoogleWSCredentials } from '../models/PutGoogleWSCredentials';
import type { PutOnboardingBasicInfo } from '../models/PutOnboardingBasicInfo';
import type { PutOnboardingDomainColdOutboundRecord } from '../models/PutOnboardingDomainColdOutboundRecord';
import type { PutOnboardingDomainsDKIM } from '../models/PutOnboardingDomainsDKIM';
import type { PutOnboardingDomainSetup } from '../models/PutOnboardingDomainSetup';
import type { PutOnboardingDomainsVerificationTXT } from '../models/PutOnboardingDomainsVerificationTXT';
import type { PutOnboardingInboxes } from '../models/PutOnboardingInboxes';
import type { PutOnboardingInboxesAppPassword } from '../models/PutOnboardingInboxesAppPassword';
import type { Recommendation } from '../models/Recommendation';
import type { SalesforceConnection } from '../models/SalesforceConnection';
import type { SalesforceConnectionContactDetails } from '../models/SalesforceConnectionContactDetails';
import type { SalesforceConnectionDebugData } from '../models/SalesforceConnectionDebugData';
import type { SalesforceConnectionOptions } from '../models/SalesforceConnectionOptions';
import type { SegmentEvent } from '../models/SegmentEvent';
import type { SenderEmail } from '../models/SenderEmail';
import type { SenderEmails } from '../models/SenderEmails';
import type { SenderEmailStats } from '../models/SenderEmailStats';
import type { Senders } from '../models/Senders';
import type { Sequence } from '../models/Sequence';
import type { SequenceContacts } from '../models/SequenceContacts';
import type { Signature } from '../models/Signature';
import type { TeamsPermissions } from '../models/TeamsPermissions';
import type { TeamStructure } from '../models/TeamStructure';
import type { Workspace } from '../models/Workspace';
import type { WorkspaceInvitations } from '../models/WorkspaceInvitations';
import type { WorkspaceViews } from '../models/WorkspaceViews';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * @returns any Accounts list response
     * @throws ApiError
     */
    public static getAccounts(): CancelablePromise<{
        data: AccountsList;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/accounts',
        });
    }

    /**
     * @param accountId
     * @returns any Account response
     * @throws ApiError
     */
    public static getAccounts1(
        accountId: string,
    ): CancelablePromise<{
        data: Account;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/accounts/{account_id}',
            path: {
                'account_id': accountId,
            },
        });
    }

    /**
     * @returns any Member features
     * @throws ApiError
     */
    public static getAdminMemberFeatureFlags(): CancelablePromise<{
        data: MemberFeatureFlags;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/member/feature_flags',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static putAdminMemberFeatureFlags(
        requestBody: MemberFeatureFlags,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/admin/member/feature_flags',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Sender email stats companies
     * @throws ApiError
     */
    public static getAdminSenderEmailStatsCompanyList(): CancelablePromise<{
        data: Array<{
            id: string;
            name: string;
            short_name: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/sender_email_stats/company_list',
        });
    }

    /**
     * @param companyId
     * @param dateFrom
     * @param dateTo
     * @returns any Sender email stats
     * @throws ApiError
     */
    public static getAdminSenderEmailStatsCompany(
        companyId: string,
        dateFrom?: string,
        dateTo?: string,
    ): CancelablePromise<{
        data: SenderEmailStats;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/sender_email_stats/company/{company_id}',
            path: {
                'company_id': companyId,
            },
            query: {
                'date_from': dateFrom,
                'date_to': dateTo,
            },
        });
    }

    /**
     * @returns any Sample warmer emails
     * @throws ApiError
     */
    public static getAdminSenderEmailStatsSampleWarmerEmails(): CancelablePromise<{
        data: Array<{
            id: string;
            subject: string;
            body: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/sender_email_stats/sample_warmer_emails',
        });
    }

    /**
     * @param dateFrom
     * @param dateTo
     * @returns any Companies with warmer email drops
     * @throws ApiError
     */
    public static getAdminSenderEmailStatsCompaniesWithDrops(
        dateFrom?: string,
        dateTo?: string,
    ): CancelablePromise<{
        data: Array<{
            id: string;
            name: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/sender_email_stats/companies_with_drops',
            query: {
                'date_from': dateFrom,
                'date_to': dateTo,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static postStoreEvent(
        requestBody: SegmentEvent,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/store/event',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any get company data
     * @throws ApiError
     */
    public static getCompany(): CancelablePromise<{
        data: Company;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/company',
        });
    }

    /**
     * @returns any delete company
     * @throws ApiError
     */
    public static deleteCompany(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/company',
        });
    }

    /**
     * @param requestBody
     * @returns any ok
     * @throws ApiError
     */
    public static patchCompany(
        requestBody: PatchCompany,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/company',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any create api key
     * @throws ApiError
     */
    public static postCompanyApiKeys(
        requestBody: PostCompanyApiKey,
    ): CancelablePromise<{
        data: CompanyApiKeyCreate;
        permission?: CompanyApiKeyPermissions;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/company/api_keys',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any get company api key
     * @throws ApiError
     */
    public static getCompanyApiKeys(): CancelablePromise<{
        data: CompanyApiKeys;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/company/api_keys',
        });
    }

    /**
     * @param companyApiKeyId
     * @param requestBody
     * @returns any ok
     * @throws ApiError
     */
    public static patchCompanyApiKeys(
        companyApiKeyId: string,
        requestBody: PatchCompanyApiKey,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/company/api_keys/{company_api_key_id}',
            path: {
                'company_api_key_id': companyApiKeyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param companyApiKeyId
     * @returns any ok
     * @throws ApiError
     */
    public static deleteCompanyApiKeys(
        companyApiKeyId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/company/api_keys/{company_api_key_id}',
            path: {
                'company_api_key_id': companyApiKeyId,
            },
        });
    }

    /**
     * @param sequenceEmailStatusId
     * @returns any unsubscribe from campaign
     * @throws ApiError
     */
    public static patchCampaignsEmailsUnsubscribe(
        sequenceEmailStatusId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns_emails/{sequence_email_status_id}/unsubscribe',
            path: {
                'sequence_email_status_id': sequenceEmailStatusId,
            },
        });
    }

    /**
     * @param sequenceEmailStatusId
     * @returns any Response to render unsubscribe page
     * @throws ApiError
     */
    public static patchCampaignsEmailsSubscribe(
        sequenceEmailStatusId: string,
    ): CancelablePromise<{
        data?: {
            email: string;
        };
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns_emails/{sequence_email_status_id}/subscribe',
            path: {
                'sequence_email_status_id': sequenceEmailStatusId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any create an email
     * @throws ApiError
     */
    public static postSenderEmails(
        requestBody: PostSenderEmails,
    ): CancelablePromise<{
        data: SenderEmails;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sender_emails',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any check mail pass, SPF, DKIM and DMARC statues
     * @throws ApiError
     */
    public static postSenderEmailsRevalidateAuthn(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sender_emails/revalidate_authn',
        });
    }

    /**
     * @param senderEmailId
     * @returns any get a single email
     * @throws ApiError
     */
    public static getSenderEmailById(
        senderEmailId: string,
    ): CancelablePromise<{
        data: SenderEmail;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sender_emails/{sender_email_id}',
            path: {
                'sender_email_id': senderEmailId,
            },
        });
    }

    /**
     * @param senderEmailId
     * @param requestBody
     * @returns any edit a single email
     * @throws ApiError
     */
    public static patchSenderEmails(
        senderEmailId: string,
        requestBody: PatchSenderEmail,
    ): CancelablePromise<{
        data: SenderEmail;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/sender_emails/{sender_email_id}',
            path: {
                'sender_email_id': senderEmailId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param senderEmailId
     * @returns any delete a single email
     * @throws ApiError
     */
    public static deleteSenderEmails(
        senderEmailId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/sender_emails/{sender_email_id}',
            path: {
                'sender_email_id': senderEmailId,
            },
        });
    }

    /**
     * @returns any get all sender emails
     * @throws ApiError
     */
    public static getSenders(): CancelablePromise<{
        data: Senders;
        metadata: Metadata;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/senders',
        });
    }

    /**
     * @param senderId
     * @returns any oauth disconnect
     * @throws ApiError
     */
    public static patchSenderEmailsDisconnect(
        senderId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/sender_emails/{sender_id}/disconnect',
            path: {
                'sender_id': senderId,
            },
        });
    }

    /**
     * @param senderId
     * @returns any OauthRedirectUrl response
     * @throws ApiError
     */
    public static patchSenderEmailsConnect(
        senderId: string,
    ): CancelablePromise<{
        data: OauthRedirectUrl;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/sender_emails/{sender_id}/connect',
            path: {
                'sender_id': senderId,
            },
        });
    }

    /**
     * @param senderId
     * @param requestBody
     * @returns any replyto saved
     * @throws ApiError
     */
    public static patchSenderEmailsReplyto(
        senderId: string,
        requestBody: PatchReplyTo,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/sender_emails/{sender_id}/replyto',
            path: {
                'sender_id': senderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param senderId
     * @param requestBody
     * @returns any send test email
     * @throws ApiError
     */
    public static postSendersTestsend(
        senderId: string,
        requestBody: PostSenderTestSend,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/senders/{sender_id}/testsend',
            path: {
                'sender_id': senderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any get user signature
     * @throws ApiError
     */
    public static getSignatures(): CancelablePromise<{
        metadata: Metadata;
        data: Signature;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/signatures',
        });
    }

    /**
     * @param signatureId
     * @param requestBody
     * @returns any edit a single signature
     * @throws ApiError
     */
    public static patchSignatures(
        signatureId: string,
        requestBody: PatchSignature,
    ): CancelablePromise<{
        data: Signature;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/signatures/{signature_id}',
            path: {
                'signature_id': signatureId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any List of blueprints
     * @throws ApiError
     */
    public static getBlueprints(): CancelablePromise<{
        data: Array<Blueprint>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/blueprints',
        });
    }

    /**
     * @param requestBody
     * @returns any Blueprint
     * @throws ApiError
     */
    public static postBlueprints(
        requestBody: PostBlueprint,
    ): CancelablePromise<{
        data: Blueprint;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/blueprints',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @returns any Blueprint
     * @throws ApiError
     */
    public static getBlueprints1(
        blueprintId: string,
    ): CancelablePromise<{
        data: Blueprint;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/blueprints/{blueprint_id}',
            path: {
                'blueprint_id': blueprintId,
            },
        });
    }

    /**
     * @param blueprintId
     * @param requestBody
     * @returns any Blueprint
     * @throws ApiError
     */
    public static patchBlueprints(
        blueprintId: string,
        requestBody: PatchBlueprint,
    ): CancelablePromise<{
        data: Blueprint;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/blueprints/{blueprint_id}',
            path: {
                'blueprint_id': blueprintId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @returns any success
     * @throws ApiError
     */
    public static deleteBlueprints(
        blueprintId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/blueprints/{blueprint_id}',
            path: {
                'blueprint_id': blueprintId,
            },
        });
    }

    /**
     * @param q
     * @param page
     * @param sort
     * @param archived
     * @param filterByLabels
     * @param access
     * @param includeAnalytics
     * @returns any 200
     * @throws ApiError
     */
    public static getCampaigns(
        q: string,
        page?: number,
        sort?: CampaignSortField,
        archived?: boolean,
        filterByLabels?: string,
        access: 'ALL' | 'PRIVATE' | 'SHARED' = 'ALL',
        includeAnalytics?: boolean,
    ): CancelablePromise<{
        data: CampaignsList;
        metadata: OffsetPagination;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns',
            query: {
                'page': page,
                'sort': sort,
                'q': q,
                'archived': archived,
                'filter_by_labels': filterByLabels,
                'access': access,
                'includeAnalytics': includeAnalytics,
            },
        });
    }

    /**
     * @returns any get a single campaign
     * @throws ApiError
     */
    public static postCampaigns(): CancelablePromise<{
        data: {
            id: string;
        };
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/campaigns',
        });
    }

    /**
     * @param startDate
     * @param endDate
     * @returns any Campaigns metrics
     * @throws ApiError
     */
    public static getCampaignsMetrics(
        startDate?: string,
        endDate?: string,
    ): CancelablePromise<{
        data: CampaignsMetrics;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns/metrics',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
        });
    }

    /**
     * @param startDate
     * @param endDate
     * @returns any Campaigns dashboard
     * @throws ApiError
     */
    public static getCampaignsDashboard(
        startDate?: string,
        endDate?: string,
    ): CancelablePromise<{
        data: CampaignsDashboard;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns/dashboard',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
        });
    }

    /**
     * @returns any success
     * @throws ApiError
     */
    public static postCampaignsReschedule(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/campaigns/reschedule',
        });
    }

    /**
     * @param campaignId
     * @returns any 200
     * @throws ApiError
     */
    public static getCampaigns1(
        campaignId: string,
    ): CancelablePromise<{
        data: Campaign;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns/{campaign_id}',
            path: {
                'campaign_id': campaignId,
            },
        });
    }

    /**
     * @param campaignId
     * @param requestBody
     * @returns any 200
     * @throws ApiError
     */
    public static patchCampaigns(
        campaignId: string,
        requestBody: PatchCampaign,
    ): CancelablePromise<{
        data: Campaign;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}',
            path: {
                'campaign_id': campaignId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param campaignId
     * @returns any success
     * @throws ApiError
     */
    public static deleteCampaigns(
        campaignId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/campaigns/{campaign_id}',
            path: {
                'campaign_id': campaignId,
            },
        });
    }

    /**
     * @param campaignId
     * @returns any 200
     * @throws ApiError
     */
    public static patchCampaignsStepsAppend(
        campaignId: string,
    ): CancelablePromise<{
        data: Sequence;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}/steps/append',
            path: {
                'campaign_id': campaignId,
            },
        });
    }

    /**
     * @param campaignId
     * @param stepId
     * @returns any 200
     * @throws ApiError
     */
    public static patchCampaignsStepsAb(
        campaignId: string,
        stepId: string,
    ): CancelablePromise<{
        data: Sequence;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}/steps/{step_id}/ab',
            path: {
                'campaign_id': campaignId,
                'step_id': stepId,
            },
        });
    }

    /**
     * @param campaignId
     * @param estEndDate
     * @returns any 200
     * @throws ApiError
     */
    public static getCampaignsSchedule(
        campaignId: string,
        estEndDate?: number,
    ): CancelablePromise<{
        data: CampaignSchedule;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns/{campaign_id}/schedule',
            path: {
                'campaign_id': campaignId,
            },
            query: {
                'estEndDate': estEndDate,
            },
        });
    }

    /**
     * @param campaignId
     * @returns any 200
     * @throws ApiError
     */
    public static getCampaignsEstEndDate(
        campaignId: string,
    ): CancelablePromise<{
        data: CampaignEstEndDate;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns/{campaign_id}/est_end_date',
            path: {
                'campaign_id': campaignId,
            },
        });
    }

    /**
     * @param campaignId
     * @returns any 200
     * @throws ApiError
     */
    public static getCampaignsParticipants(
        campaignId: string,
    ): CancelablePromise<{
        data: CampaignParticipants;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns/{campaign_id}/participants',
            path: {
                'campaign_id': campaignId,
            },
        });
    }

    /**
     * @param campaignId
     * @param requestBody 200
     * @returns any success
     * @throws ApiError
     */
    public static patchCampaignsParticipantsAttach(
        campaignId: string,
        requestBody: CampaignParticipantsAttach,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}/participants/attach',
            path: {
                'campaign_id': campaignId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param campaignId
     * @param requestBody 200
     * @returns any success
     * @throws ApiError
     */
    public static patchCampaignsParticipantsDetach(
        campaignId: string,
        requestBody: CampaignParticipantsDetach,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}/participants/detach',
            path: {
                'campaign_id': campaignId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param campaignId
     * @returns any 200
     * @throws ApiError
     */
    public static getCampaignsDailyCapacity(
        campaignId: string,
    ): CancelablePromise<{
        data: CampaignDailyCapacity;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns/{campaign_id}/daily_capacity',
            path: {
                'campaign_id': campaignId,
            },
        });
    }

    /**
     * @param requestBody 200
     * @returns any success
     * @throws ApiError
     */
    public static patchCampaignsArchive(
        requestBody?: PatchCampaignsArchive,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/archive',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param campaignId
     * @returns any success
     * @throws ApiError
     */
    public static patchCampaignArchive(
        campaignId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}/archive',
            path: {
                'campaign_id': campaignId,
            },
        });
    }

    /**
     * @param campaignId
     * @returns any success
     * @throws ApiError
     */
    public static patchCampaignsUnarchive(
        campaignId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}/unarchive',
            path: {
                'campaign_id': campaignId,
            },
        });
    }

    /**
     * @param campaignId
     * @returns any success
     * @throws ApiError
     */
    public static patchCampaignsLaunch(
        campaignId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}/launch',
            path: {
                'campaign_id': campaignId,
            },
        });
    }

    /**
     * @param campaignId
     * @returns any success
     * @throws ApiError
     */
    public static patchCampaignStop(
        campaignId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}/stop',
            path: {
                'campaign_id': campaignId,
            },
        });
    }

    /**
     * @param requestBody 200
     * @returns any success
     * @throws ApiError
     */
    public static patchCampaignsStop(
        requestBody?: PatchCampaignsStop,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/stop',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param campaignId
     * @returns any get a single campaign
     * @throws ApiError
     */
    public static postCampaignsClone(
        campaignId: string,
    ): CancelablePromise<{
        data: {
            id: string;
        };
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/campaigns/{campaign_id}/clone',
            path: {
                'campaign_id': campaignId,
            },
        });
    }

    /**
     * @param campaignId
     * @param createdAtLte
     * @param createdAtGte
     * @returns any 200
     * @throws ApiError
     */
    public static getCampaignsAnalytics(
        campaignId: string,
        createdAtLte?: number | null,
        createdAtGte?: number,
    ): CancelablePromise<{
        data: CampaignAnalytics;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns/{campaign_id}/analytics',
            path: {
                'campaign_id': campaignId,
            },
            query: {
                'created_at_lte': createdAtLte,
                'created_at_gte': createdAtGte,
            },
        });
    }

    /**
     * @param campaignId
     * @returns any 200
     * @throws ApiError
     */
    public static getCampaignsContactsSample(
        campaignId: string,
    ): CancelablePromise<{
        data: CampaignContactsSample;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns/{campaign_id}/contacts/sample',
            path: {
                'campaign_id': campaignId,
            },
        });
    }

    /**
     * @param campaignId
     * @returns any 200
     * @throws ApiError
     */
    public static getCampaignsContactsCustomFieldNames(
        campaignId: string,
    ): CancelablePromise<{
        data: CampaignContactsCustomFieldNames;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns/{campaign_id}/contacts/custom_field_names',
            path: {
                'campaign_id': campaignId,
            },
        });
    }

    /**
     * @param campaignId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchCampaignsContactsUnsubscribe(
        campaignId: string,
        requestBody: PatchCampaignContactsUnsubscribe,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}/contacts/unsubscribe',
            path: {
                'campaign_id': campaignId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param campaignId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchCampaignsContactsReschedule(
        campaignId: string,
        requestBody: PatchCampaignContactsReschedule,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}/contacts/reschedule',
            path: {
                'campaign_id': campaignId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param campaignId
     * @param contactId
     * @returns any success
     * @throws ApiError
     */
    public static patchCampaignsContactUnsubscribe(
        campaignId: string,
        contactId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}/contacts/{contact_id}/unsubscribe',
            path: {
                'campaign_id': campaignId,
                'contact_id': contactId,
            },
        });
    }

    /**
     * @param campaignId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchCampaignsContactsAbandon(
        campaignId: string,
        requestBody: PatchCampaignContactsAbandon,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}/contacts/abandon',
            path: {
                'campaign_id': campaignId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param campaignId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static postCampaignsContactsDelete(
        campaignId: string,
        requestBody: PostCampaignContactsDelete,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/campaigns/{campaign_id}/contacts/delete',
            path: {
                'campaign_id': campaignId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param campaignId
     * @param campaignExportType
     * @returns binary download csv file
     * @throws ApiError
     */
    public static getCampaignsExport(
        campaignId: string,
        campaignExportType: 'all_contacts' | 'unhealthy_contacts' | 'valid_contacts' | 'opened_contacts' | 'replied_contacts' | 'uncontacted_contacts' | 'unsubscribed_contacts' | 'bounced_contacts' | 'unenrolled_contacts' | 'catch_all_contacts' | 'emails_schedule',
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns/{campaign_id}/{campaign_export_type}/export',
            path: {
                'campaign_id': campaignId,
                'campaign_export_type': campaignExportType,
            },
        });
    }

    /**
     * @param campaignId
     * @returns any Labels for a particular campaign
     * @throws ApiError
     */
    public static getCampaignsLabels(
        campaignId: string,
    ): CancelablePromise<{
        data: CampaignLabels;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns/{campaign_id}/labels',
            path: {
                'campaign_id': campaignId,
            },
        });
    }

    /**
     * @param campaignId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchCampaignsLabels(
        campaignId: string,
        requestBody: PatchCampaignLabels,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}/labels',
            path: {
                'campaign_id': campaignId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param campaignId
     * @returns any Product options for campaign
     * @throws ApiError
     */
    public static getCampaignsCompanyProducts(
        campaignId: string,
    ): CancelablePromise<{
        data: Array<CompanyProduct>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns/{campaign_id}/company_products',
            path: {
                'campaign_id': campaignId,
            },
        });
    }

    /**
     * @param campaignId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchCampaignsAttachContactList(
        campaignId: string,
        requestBody: PatchCampaignAttachContactList,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}/attach_contact_list',
            path: {
                'campaign_id': campaignId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param campaignId
     * @param requestBody 200
     * @returns any success
     * @throws ApiError
     */
    public static patchCampaignsAddMissingSenders(
        campaignId: string,
        requestBody: PatchCampaignAddMissingSenders,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}/add_missing_senders',
            path: {
                'campaign_id': campaignId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param campaignId
     * @param requestBody 200
     * @returns any success
     * @throws ApiError
     */
    public static patchCampaignsContactsRedistribute(
        campaignId: string,
        requestBody: PatchCampaignContactRedistribute,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}/contacts/redistribute',
            path: {
                'campaign_id': campaignId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param campaignId
     * @param requestBody 200
     * @returns any success
     * @throws ApiError
     */
    public static patchCampaignsContactsReassignOwnership(
        campaignId: string,
        requestBody: PatchCampaignContactReassignOwnership,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}/contacts/reassign_ownership',
            path: {
                'campaign_id': campaignId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param campaignId
     * @returns any 200
     * @throws ApiError
     */
    public static getCampaignsSharees(
        campaignId: string,
    ): CancelablePromise<{
        data: CampaignSharees;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns/{campaign_id}/sharees',
            path: {
                'campaign_id': campaignId,
            },
        });
    }

    /**
     * @param campaignId
     * @param memberId
     * @param requestBody 200
     * @returns any success
     * @throws ApiError
     */
    public static patchCampaignsShareesShare(
        campaignId: string,
        memberId: string,
        requestBody: PatchCampaignShareeShare,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}/sharees/{member_id}/share',
            path: {
                'campaign_id': campaignId,
                'member_id': memberId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param campaignId
     * @param memberId
     * @returns any success
     * @throws ApiError
     */
    public static patchCampaignsShareesRevoke(
        campaignId: string,
        memberId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/campaigns/{campaign_id}/sharees/{member_id}/revoke',
            path: {
                'campaign_id': campaignId,
                'member_id': memberId,
            },
        });
    }

    /**
     * @param campaignId
     * @param promptEngine
     * @returns any 200
     * @throws ApiError
     */
    public static getCampaignsRecommendations(
        campaignId: string,
        promptEngine: 'llm' | 'wordware',
    ): CancelablePromise<{
        data: Array<Recommendation>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaigns/{campaign_id}/{promptEngine}/recommendations',
            path: {
                'campaign_id': campaignId,
                'promptEngine': promptEngine,
            },
        });
    }

    /**
     * @param q
     * @returns any ok
     * @throws ApiError
     */
    public static getChameleonSearch(
        q: string,
    ): CancelablePromise<{
        data: ChameleonUsers;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/chameleon/search',
            query: {
                'q': q,
            },
        });
    }

    /**
     * @returns any ok
     * @throws ApiError
     */
    public static getChameleon(): CancelablePromise<{
        data: ChameleonUser;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/chameleon',
        });
    }

    /**
     * @returns any delete chameleon
     * @throws ApiError
     */
    public static deleteChameleon(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/chameleon',
        });
    }

    /**
     * @param identityIdTo
     * @returns any ok
     * @throws ApiError
     */
    public static postChameleon(
        identityIdTo: string,
    ): CancelablePromise<{
        data: ChameleonUser;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/chameleon/{identity_id_to}',
            path: {
                'identity_id_to': identityIdTo,
            },
        });
    }

    /**
     * @returns any Salesforce connections response
     * @throws ApiError
     */
    public static getConnectionsSalesforce(): CancelablePromise<{
        data: SalesforceConnection;
        permissions: ConnectionPermissions;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/connections/salesforce',
        });
    }

    /**
     * @returns any success
     * @throws ApiError
     */
    public static deleteConnectionsSalesforce(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/connections/salesforce',
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchConnectionsSalesforce(
        requestBody: PatchSalesforceConnection,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/connections/salesforce',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Salesforce connection contact details response
     * @throws ApiError
     */
    public static getConnectionsSalesforceDebugData(): CancelablePromise<{
        data: SalesforceConnectionDebugData;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/connections/salesforce/debug-data',
        });
    }

    /**
     * @param contactId
     * @returns any Salesforce connection contact details response
     * @throws ApiError
     */
    public static getConnectionsSalesforceContacts(
        contactId: string,
    ): CancelablePromise<{
        data: SalesforceConnectionContactDetails;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/connections/salesforce/contacts/{contact_id}',
            path: {
                'contact_id': contactId,
            },
        });
    }

    /**
     * @returns any Salesforce connection options response
     * @throws ApiError
     */
    public static getConnectionsSalesforceOptions(): CancelablePromise<{
        data: SalesforceConnectionOptions;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/connections/salesforce/options',
        });
    }

    /**
     * @returns any OauthRedirectUrl response
     * @throws ApiError
     */
    public static getConnectionsSalesforceOauth2(): CancelablePromise<{
        data: OauthRedirectUrl;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/connections/salesforce/oauth2',
        });
    }

    /**
     * @param requestBody
     * @returns any PostContactsResponse
     * @throws ApiError
     */
    public static postContacts(
        requestBody: PostContacts,
    ): CancelablePromise<{
        data: PostContactsResponse;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/contacts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Contacts response
     * @throws ApiError
     */
    public static getContacts(): CancelablePromise<{
        data: ContactsV2;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/contacts',
        });
    }

    /**
     * @param requestBody
     * @returns any PostContactsResponse
     * @throws ApiError
     */
    public static postContactsUploadContactList(
        requestBody: PostContactsUploadContactList,
    ): CancelablePromise<{
        data: PostContactsResponse;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/contacts/upload/contact_list',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param contactListId
     * @param requestBody
     * @returns any PostContactsResponse
     * @throws ApiError
     */
    public static postContactsUploadContactListId(
        contactListId: string,
        requestBody: PostContactsUploadContactListId,
    ): CancelablePromise<{
        data: PostContactsResponse;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/contacts/upload/contact_list/{contact_list_id}',
            path: {
                'contact_list_id': contactListId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param campaignId
     * @param requestBody
     * @returns any PostContactsResponse
     * @throws ApiError
     */
    public static postContactsUploadCampaignsSingle(
        campaignId: string,
        requestBody: PostContactUploadCampaignId,
    ): CancelablePromise<{
        data: PostContactsResponse;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/contacts/upload/campaigns/{campaign_id}/single',
            path: {
                'campaign_id': campaignId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any PostFieldCleaningsResponse
     * @throws ApiError
     */
    public static postContactsFieldCleanings(
        requestBody: PostFieldCleanings,
    ): CancelablePromise<{
        data: PostFieldCleaningsResponse;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/contacts/field_cleanings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param campaignId
     * @param requestBody
     * @returns any PostContactsResponse
     * @throws ApiError
     */
    public static postContactsUploadCampaigns(
        campaignId: string,
        requestBody: PostContactsUploadCampaignId,
    ): CancelablePromise<{
        data: PostContactsResponse;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/contacts/upload/campaigns/{campaign_id}',
            path: {
                'campaign_id': campaignId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param campaignId
     * @returns any CampaignContacts
     * @throws ApiError
     */
    public static getContactsCampaigns(
        campaignId: string,
    ): CancelablePromise<{
        data: SequenceContacts;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/contacts/campaigns/{campaign_id}',
            path: {
                'campaign_id': campaignId,
            },
        });
    }

    /**
     * @param campaignId
     * @returns any CampaignContactsRecentlyAdded
     * @throws ApiError
     */
    public static getContactsCampaignsRecentlyAdded(
        campaignId: string,
    ): CancelablePromise<{
        data: CampaignContactsRecentlyAdded;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/contacts/campaigns/{campaign_id}/recently_added',
            path: {
                'campaign_id': campaignId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns binary download csv file
     * @throws ApiError
     */
    public static postContactsExport(
        requestBody: PostContactsExport,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/contacts/export',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchContactsUnsubscribe(
        requestBody: PatchContactsUnsubscribe,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/contacts/unsubscribe',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchContactsUnenroll(
        requestBody: PatchContactsUnenroll,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/contacts/unenroll',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static postContactsDelete(
        requestBody: PostContactsDelete,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/contacts/delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param contactId
     * @returns any Contacts response
     * @throws ApiError
     */
    public static getContacts1(
        contactId: string,
    ): CancelablePromise<{
        data: ContactV2;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/contacts/{contact_id}',
            path: {
                'contact_id': contactId,
            },
        });
    }

    /**
     * @param contactId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchContacts(
        contactId: string,
        requestBody: PatchContact,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/contacts/{contact_id}',
            path: {
                'contact_id': contactId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param contactId
     * @returns any success
     * @throws ApiError
     */
    public static deleteContacts(
        contactId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/contacts/{contact_id}',
            path: {
                'contact_id': contactId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchContactsOwners(
        requestBody: PatchContactsOwners,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/contacts/owners',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchContactsOwnersSplit(
        requestBody: PatchContactsOwnersSplit,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/contacts/owners/split',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static postContactLists(
        requestBody: PostContactList,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/contact_lists',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any ContactLists response
     * @throws ApiError
     */
    public static getContactLists(): CancelablePromise<{
        data: ContactLists;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/contact_lists',
        });
    }

    /**
     * @returns any ContactLists response
     * @throws ApiError
     */
    public static getContactListsCampaignsAssignable(): CancelablePromise<{
        data: ContactLists;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/contact_lists/campaigns/assignable',
        });
    }

    /**
     * @returns any ContactLists response
     * @throws ApiError
     */
    public static getContactListsImportable(): CancelablePromise<{
        data: ContactLists;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/contact_lists/importable',
        });
    }

    /**
     * @param contactListId
     * @returns any ContactList response
     * @throws ApiError
     */
    public static getContactLists1(
        contactListId: string,
    ): CancelablePromise<{
        data: ContactList;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/contact_lists/{contact_list_id}',
            path: {
                'contact_list_id': contactListId,
            },
        });
    }

    /**
     * @param contactListId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchContactLists(
        contactListId: string,
        requestBody: PatchContactList,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/contact_lists/{contact_list_id}',
            path: {
                'contact_list_id': contactListId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param contactListId
     * @returns any success
     * @throws ApiError
     */
    public static deleteContactLists(
        contactListId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/contact_lists/{contact_list_id}',
            path: {
                'contact_list_id': contactListId,
            },
        });
    }

    /**
     * @param contactListId
     * @returns any ContactList response
     * @throws ApiError
     */
    public static getContactListsContacts(
        contactListId: string,
    ): CancelablePromise<{
        data: ContactsV2;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/contact_lists/{contact_list_id}/contacts',
            path: {
                'contact_list_id': contactListId,
            },
        });
    }

    /**
     * @param contactListId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchContactListsAddContacts(
        contactListId: string,
        requestBody: PatchContactListAddContacts,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/contact_lists/{contact_list_id}/add_contacts',
            path: {
                'contact_list_id': contactListId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param contactListId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchContactListsRemoveContacts(
        contactListId: string,
        requestBody: PatchContactListRemoveContacts,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/contact_lists/{contact_list_id}/remove_contacts',
            path: {
                'contact_list_id': contactListId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param contactId
     * @returns any Events
     * @throws ApiError
     */
    public static getEventsContacts(
        contactId: string,
    ): CancelablePromise<{
        data: Events;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/events/contacts/{contact_id}',
            path: {
                'contact_id': contactId,
            },
        });
    }

    /**
     * @param campaignId
     * @param q
     * @param limit
     * @param eventTypes
     * @returns any 200
     * @throws ApiError
     */
    public static getEventsCampaigns(
        campaignId: string,
        q: string,
        limit?: number,
        eventTypes?: Array<EventType>,
    ): CancelablePromise<{
        data: CampaignActivity;
        metadata: {
            more: boolean;
        };
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/events/campaigns/{campaign_id}',
            path: {
                'campaign_id': campaignId,
            },
            query: {
                'q': q,
                'limit': limit,
                'event_types': eventTypes,
            },
        });
    }

    /**
     * @returns any response
     * @throws ApiError
     */
    public static getIntegrationsHubspotStatus(): CancelablePromise<{
        data: HubspotSettingsStatus;
        permissions: HubspotSettingsPermissions;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/integrations/hubspot/status',
        });
    }

    /**
     * @returns any response
     * @throws ApiError
     */
    public static getIntegrationsHubspotSettings(): CancelablePromise<{
        data: HubspotSettings;
        permissions: HubspotSettingsPermissions;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/integrations/hubspot/settings',
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchIntegrationsHubspotSettings(
        requestBody: PatchHubspotSettings,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/integrations/hubspot/settings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static putIntegrationsHubspotSettingsOnboarding(
        requestBody: PatchHubspotSettings,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/integrations/hubspot/settings/onboarding',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Hubspot users
     * @throws ApiError
     */
    public static getIntegrationsHubspotMappings(): CancelablePromise<{
        data: HubspotDataMappings;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/integrations/hubspot/mappings',
        });
    }

    /**
     * @returns any Hubspot active lists
     * @throws ApiError
     */
    public static getIntegrationsHubspotActivelists(): CancelablePromise<{
        data: HubspotActiveLists;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/integrations/hubspot/activelists',
        });
    }

    /**
     * @param contactId
     * @returns any Hubspot connection contact details response
     * @throws ApiError
     */
    public static getIntegrationsHubspotContacts(
        contactId: string,
    ): CancelablePromise<{
        data: HubspotContactDetails;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/integrations/hubspot/contacts/{contact_id}',
            path: {
                'contact_id': contactId,
            },
        });
    }

    /**
     * @returns any Identity response
     * @throws ApiError
     */
    public static getIdentity(): CancelablePromise<{
        data: IntercomIdentity;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/identity',
        });
    }

    /**
     * @returns any Inboxes
     * @throws ApiError
     */
    public static getInboxes(): CancelablePromise<{
        data: Inboxes;
        metadata: Metadata;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/inboxes',
        });
    }

    /**
     * @returns any Main inbox data
     * @throws ApiError
     */
    public static getInboxesMain(): CancelablePromise<{
        data?: MainInbox;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/inboxes/main',
        });
    }

    /**
     * @returns any Inbox deleted
     * @throws ApiError
     */
    public static deleteInboxesMain(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/inboxes/main',
        });
    }

    /**
     * @param requestBody body
     * @returns any Inbox created
     * @throws ApiError
     */
    public static postInboxesMain(
        requestBody: PostInboxesMain,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/inboxes/main',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Inbox group
     * @throws ApiError
     */
    public static postInboxGroups(
        requestBody: PostInboxGroup,
    ): CancelablePromise<{
        data: InboxGroup;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/inbox_groups',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Inbox groups
     * @throws ApiError
     */
    public static getInboxGroups(): CancelablePromise<{
        data: InboxGroups;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/inbox_groups',
        });
    }

    /**
     * @param inboxGroupId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchInboxGroups(
        inboxGroupId: string,
        requestBody: PatchInboxGroup,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/inbox_groups/{inbox_group_id}',
            path: {
                'inbox_group_id': inboxGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param inboxGroupId
     * @returns any success
     * @throws ApiError
     */
    public static deleteInboxGroups(
        inboxGroupId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/inbox_groups/{inbox_group_id}',
            path: {
                'inbox_group_id': inboxGroupId,
            },
        });
    }

    /**
     * @param inboxGroupId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchInboxGroupsMoveInboxes(
        inboxGroupId: string,
        requestBody: PatchInboxGroupMoveInboxes,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/inbox_groups/{inbox_group_id}/move_inboxes',
            path: {
                'inbox_group_id': inboxGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any success
     * @throws ApiError
     */
    public static getManualStepsInfo(): CancelablePromise<{
        data: ManualStepInfo;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/manual_steps/info',
        });
    }

    /**
     * @returns any success
     * @throws ApiError
     */
    public static getManualStepsDone(): CancelablePromise<{
        data: ManualStepDone;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/manual_steps/done',
        });
    }

    /**
     * @param emailId
     * @returns any success
     * @throws ApiError
     */
    public static getManualStepDoneEmail(
        emailId: string,
    ): CancelablePromise<{
        data: ManualStepDoneEmail;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/manual_steps/done/emails/{email_id}',
            path: {
                'email_id': emailId,
            },
        });
    }

    /**
     * @param messageId
     * @returns any success
     * @throws ApiError
     */
    public static getManualStepDoneReply(
        messageId: string,
    ): CancelablePromise<{
        data: ManualStepDoneReply;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/manual_steps/done/replies/{message_id}',
            path: {
                'message_id': messageId,
            },
        });
    }

    /**
     * @param messageId
     * @returns any success
     * @throws ApiError
     */
    public static getManualStepDoneRecommendation(
        messageId: string,
    ): CancelablePromise<{
        data: ManualStepRecommendationInfo;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/manual_steps/done/recommendations/{message_id}',
            path: {
                'message_id': messageId,
            },
        });
    }

    /**
     * @param callId
     * @returns any success
     * @throws ApiError
     */
    public static getManualStepDoneCall(
        callId: string,
    ): CancelablePromise<{
        data: ManualStepDoneCall;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/manual_steps/done/calls/{call_id}',
            path: {
                'call_id': callId,
            },
        });
    }

    /**
     * @param sequenceEmailStatusId
     * @returns any success
     * @throws ApiError
     */
    public static getManualStepDoneLinkedinMessage(
        sequenceEmailStatusId: string,
    ): CancelablePromise<{
        data: ManualStepDoneLinkedinMessage;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/manual_steps/done/linkedin_messages/{sequence_email_status_id}',
            path: {
                'sequence_email_status_id': sequenceEmailStatusId,
            },
        });
    }

    /**
     * @returns any success
     * @throws ApiError
     */
    public static getManualStepsEmails(): CancelablePromise<{
        data: ManualStepEmails;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/manual_steps/emails',
        });
    }

    /**
     * @param emailId
     * @returns any success
     * @throws ApiError
     */
    public static getManualStepEmail(
        emailId: string,
    ): CancelablePromise<{
        data: ManualStepEmailInfo;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/manual_steps/emails/{email_id}',
            path: {
                'email_id': emailId,
            },
        });
    }

    /**
     * @param emailId
     * @returns any success
     * @throws ApiError
     */
    public static postManualStepsEmailsSend(
        emailId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/manual_steps/emails/{email_id}/send',
            path: {
                'email_id': emailId,
            },
        });
    }

    /**
     * @param emailId
     * @param requestBody success
     * @returns any success
     * @throws ApiError
     */
    public static patchManualStepsEmailsSave(
        emailId: string,
        requestBody: PatchManualStepEmailSave,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/manual_steps/emails/{email_id}/save',
            path: {
                'email_id': emailId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any A list of recommendations
     * @throws ApiError
     */
    public static getManualStepsRecommendations(): CancelablePromise<{
        data: ManualStepRecommendations;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/manual_steps/recommendations',
        });
    }

    /**
     * @param messageId
     * @returns any success
     * @throws ApiError
     */
    public static getManualStepRecommendation(
        messageId: string,
    ): CancelablePromise<{
        data: ManualStepRecommendationInfo;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/manual_steps/recommendations/{message_id}',
            path: {
                'message_id': messageId,
            },
        });
    }

    /**
     * @param messageId
     * @returns any success
     * @throws ApiError
     */
    public static postManualStepsRecommendationsDone(
        messageId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/manual_steps/recommendations/{message_id}/done',
            path: {
                'message_id': messageId,
            },
        });
    }

    /**
     * @returns any success
     * @throws ApiError
     */
    public static getManualStepsReplies(): CancelablePromise<{
        data: ManualStepReplies;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/manual_steps/replies',
        });
    }

    /**
     * @param messageId
     * @returns any success
     * @throws ApiError
     */
    public static getManualStepReply(
        messageId: string,
    ): CancelablePromise<{
        data: ManualStepReplyInfo;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/manual_steps/replies/{message_id}',
            path: {
                'message_id': messageId,
            },
        });
    }

    /**
     * @param messageId
     * @returns any success
     * @throws ApiError
     */
    public static postManualStepsRepliesDone(
        messageId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/manual_steps/replies/{message_id}/done',
            path: {
                'message_id': messageId,
            },
        });
    }

    /**
     * @param messageId
     * @returns any success
     * @throws ApiError
     */
    public static postManualStepsRepliesUndo(
        messageId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/manual_steps/replies/{message_id}/undo',
            path: {
                'message_id': messageId,
            },
        });
    }

    /**
     * @param messageId
     * @returns any success
     * @throws ApiError
     */
    public static postManualStepsRepliesSend(
        messageId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/manual_steps/replies/{message_id}/send',
            path: {
                'message_id': messageId,
            },
        });
    }

    /**
     * @param messageId
     * @returns any success
     * @throws ApiError
     */
    public static postManualStepsRepliesSendCancel(
        messageId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/manual_steps/replies/{message_id}/send-cancel',
            path: {
                'message_id': messageId,
            },
            errors: {
                412: `thread already sent`,
            },
        });
    }

    /**
     * @param messageId
     * @param requestBody success
     * @returns any success
     * @throws ApiError
     */
    public static patchManualStepsRepliesDraft(
        messageId: string,
        requestBody: PatchManualStepReplyDraft,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/manual_steps/replies/{message_id}/draft',
            path: {
                'message_id': messageId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param messageId
     * @returns any success
     * @throws ApiError
     */
    public static postManualStepsRepliesDraft(
        messageId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/manual_steps/replies/{message_id}/draft',
            path: {
                'message_id': messageId,
            },
        });
    }

    /**
     * @param messageId
     * @returns any success
     * @throws ApiError
     */
    public static deleteManualStepsRepliesDraft(
        messageId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/manual_steps/replies/{message_id}/draft',
            path: {
                'message_id': messageId,
            },
        });
    }

    /**
     * @returns any success
     * @throws ApiError
     */
    public static getManualStepsCalls(): CancelablePromise<{
        data: ManualStepCalls;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/manual_steps/calls',
        });
    }

    /**
     * @param callId
     * @returns any success
     * @throws ApiError
     */
    public static getManualStepCall(
        callId: string,
    ): CancelablePromise<{
        data: ManualStepCallInfo;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/manual_steps/calls/{call_id}',
            path: {
                'call_id': callId,
            },
        });
    }

    /**
     * @param callId
     * @param requestBody success
     * @returns any success
     * @throws ApiError
     */
    public static patchManualStepCall(
        callId: string,
        requestBody: PatchManualStepCall,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/manual_steps/calls/{call_id}',
            path: {
                'call_id': callId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param callId
     * @returns any success
     * @throws ApiError
     */
    public static patchManualStepsCallsAnswered(
        callId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/manual_steps/calls/{call_id}/answered',
            path: {
                'call_id': callId,
            },
        });
    }

    /**
     * @param callId
     * @returns any success
     * @throws ApiError
     */
    public static patchManualStepsCallsUnanswered(
        callId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/manual_steps/calls/{call_id}/unanswered',
            path: {
                'call_id': callId,
            },
        });
    }

    /**
     * @param callId
     * @returns any success
     * @throws ApiError
     */
    public static postManualStepsCallsTwilioCallStatus(
        callId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/manual_steps/calls/{call_id}/twilio-call-status',
            path: {
                'call_id': callId,
            },
        });
    }

    /**
     * @param callId
     * @returns any success
     * @throws ApiError
     */
    public static getManualStepsCallsDeviceToken(
        callId: string,
    ): CancelablePromise<{
        data: ManualStepCallDeviceToken;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/manual_steps/calls/{call_id}/device_token',
            path: {
                'call_id': callId,
            },
        });
    }

    /**
     * @param callId
     * @param requestBody success
     * @returns any success
     * @throws ApiError
     */
    public static postManualStepsCallsMeetingLinkSend(
        callId: string,
        requestBody: PostManualStepCallMeetingLinkSend,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/manual_steps/calls/{call_id}/meeting_link_send',
            path: {
                'call_id': callId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static postDialerCall(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dialer/call',
        });
    }

    /**
     * @returns any success
     * @throws ApiError
     */
    public static getManualStepsLinkedinMessages(): CancelablePromise<{
        data: ManualStepLinkedinMessages;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/manual_steps/linkedin_messages',
        });
    }

    /**
     * @param sequenceEmailStatusId
     * @returns any success
     * @throws ApiError
     */
    public static getManualStepLinkedinMessage(
        sequenceEmailStatusId: string,
    ): CancelablePromise<{
        data: ManualStepLinkedinMessageInfo;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/manual_steps/linkedin_messages/{sequence_email_status_id}',
            path: {
                'sequence_email_status_id': sequenceEmailStatusId,
            },
        });
    }

    /**
     * @param sequenceEmailStatusId
     * @param requestBody success
     * @returns any success
     * @throws ApiError
     */
    public static patchManualStepLinkedinMessage(
        sequenceEmailStatusId: string,
        requestBody: PatchManualStepLinkedinMessage,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/manual_steps/linkedin_messages/{sequence_email_status_id}',
            path: {
                'sequence_email_status_id': sequenceEmailStatusId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sequenceEmailStatusId
     * @returns any success
     * @throws ApiError
     */
    public static patchManualStepsLinkedinMessagesSent(
        sequenceEmailStatusId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/manual_steps/linkedin_messages/{sequence_email_status_id}/sent',
            path: {
                'sequence_email_status_id': sequenceEmailStatusId,
            },
        });
    }

    /**
     * @param sequenceEmailStatusId
     * @returns any success
     * @throws ApiError
     */
    public static getManualStepsLinkedinMessagesCopy(
        sequenceEmailStatusId: string,
    ): CancelablePromise<{
        data: ManualStepLinkedinMessageCopy;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/manual_steps/linkedin_messages/{sequence_email_status_id}/copy',
            path: {
                'sequence_email_status_id': sequenceEmailStatusId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static createMeeting(
        requestBody?: PostMeetingForLead,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/meetings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param meetingId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchMeetings(
        meetingId: string,
        requestBody?: PatchMeeting,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/meetings/{meeting_id}',
            path: {
                'meeting_id': meetingId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param companyShortName
     * @param meetingShortName
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static createMeetingByType(
        companyShortName: string,
        meetingShortName: string,
        requestBody?: PostMeeting,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/meetings/{company_short_name}/{meeting_short_name}',
            path: {
                'company_short_name': companyShortName,
                'meeting_short_name': meetingShortName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param companyShortName
     * @param meetingShortName
     * @returns any Availability response
     * @throws ApiError
     */
    public static getMeetingAvailabilityByMeetingType(
        companyShortName: string,
        meetingShortName: string,
    ): CancelablePromise<{
        data: Availability;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/meetings/{company_short_name}/{meeting_short_name}/available',
            path: {
                'company_short_name': companyShortName,
                'meeting_short_name': meetingShortName,
            },
        });
    }

    /**
     * @returns any Get users eligible to use Meetings product
     * @throws ApiError
     */
    public static getMeetingTypeMembers(): CancelablePromise<{
        data: MeetingMembers;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/meeting_type/members',
        });
    }

    /**
     * @returns any Get meeting types for company
     * @throws ApiError
     */
    public static getPersonalMeetingTypes(): CancelablePromise<{
        data: MeetingTypes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/meeting_type/personal',
        });
    }

    /**
     * @returns any Get meeting types for company
     * @throws ApiError
     */
    public static getMeetingTypes(): CancelablePromise<{
        data: MeetingTypes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/meeting_type',
        });
    }

    /**
     * @param requestBody Create meeting type
     * @returns any success
     * @throws ApiError
     */
    public static postMeetingType(
        requestBody?: PostMeetingType,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/meeting_type',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any MeetingTypeTemplateReminderInfo
     * @throws ApiError
     */
    public static getMeetingTypeTemplatesReminderInfo(): CancelablePromise<{
        data: MeetingTypeTemplateReminderInfo;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/meeting_type/templates/reminder/info',
        });
    }

    /**
     * @param meetingTypeId
     * @param requestBody Update meeting type
     * @returns any success
     * @throws ApiError
     */
    public static patchMeetingType(
        meetingTypeId: string,
        requestBody?: PatchMeetingType,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/meeting_type/{meeting_type_id}',
            path: {
                'meeting_type_id': meetingTypeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param meetingTypeId
     * @returns any Get meeting type
     * @throws ApiError
     */
    public static getMeetingType(
        meetingTypeId: string,
    ): CancelablePromise<{
        data: MeetingType;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/meeting_type/{meeting_type_id}',
            path: {
                'meeting_type_id': meetingTypeId,
            },
        });
    }

    /**
     * @param meetingTypeId
     * @returns any success
     * @throws ApiError
     */
    public static deleteMeetingType(
        meetingTypeId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/meeting_type/{meeting_type_id}',
            path: {
                'meeting_type_id': meetingTypeId,
            },
        });
    }

    /**
     * @param time
     * @param q
     * @param types
     * @returns any Get meeting type
     * @throws ApiError
     */
    public static getBookings(
        time?: string,
        q?: string,
        types?: string,
    ): CancelablePromise<{
        data: GroupedMeetings;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bookings',
            query: {
                'time': time,
                'q': q,
                'types': types,
            },
        });
    }

    /**
     * @param phantom
     * @returns any success
     * @throws ApiError
     */
    public static getMembers(
        phantom?: boolean,
    ): CancelablePromise<{
        data: Members;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/members',
            query: {
                'phantom': phantom,
            },
        });
    }

    /**
     * @param memberId
     * @returns any success
     * @throws ApiError
     */
    public static getMembersOwnerContactsCount(
        memberId: string,
    ): CancelablePromise<{
        data: MemberOwnerContactsCount;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/members/{member_id}/owner/contacts/count',
            path: {
                'member_id': memberId,
            },
        });
    }

    /**
     * @returns any success
     * @throws ApiError
     */
    public static getNotifications(): CancelablePromise<{
        data: Notifications;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/notifications',
        });
    }

    /**
     * @param googleService
     * @param loginHint
     * @returns any OauthRedirectUrl response
     * @throws ApiError
     */
    public static getGoogleOauth(
        googleService: 'calendar' | 'onboarding' | 'inbox' | 'onboarding_inbox',
        loginHint?: string,
    ): CancelablePromise<{
        data: OauthRedirectUrl;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/google/oauth/{google_service}',
            path: {
                'google_service': googleService,
            },
            query: {
                'login_hint': loginHint,
            },
        });
    }

    /**
     * @param googleService
     * @returns any success
     * @throws ApiError
     */
    public static deleteGoogleOauth(
        googleService: 'calendar' | 'onboarding' | 'inbox' | 'onboarding_inbox',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/google/oauth/{google_service}',
            path: {
                'google_service': googleService,
            },
        });
    }

    /**
     * @param googleService
     * @returns any OauthStatus response
     * @throws ApiError
     */
    public static getGoogleOauthStatus(
        googleService: 'calendar' | 'onboarding' | 'inbox' | 'onboarding_inbox',
    ): CancelablePromise<{
        data: OauthStatus;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/google/oauth/{google_service}/status',
            path: {
                'google_service': googleService,
            },
        });
    }

    /**
     * @returns any OauthRedirectUrl response
     * @throws ApiError
     */
    public static getZoomOauth(): CancelablePromise<{
        data: OauthRedirectUrl;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/zoom/oauth',
        });
    }

    /**
     * @returns any success
     * @throws ApiError
     */
    public static deleteZoomOauth(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/zoom/oauth',
        });
    }

    /**
     * @returns any OauthStatus response
     * @throws ApiError
     */
    public static getZoomOauthStatus(): CancelablePromise<{
        data: OauthStatus;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/zoom/oauth/status',
        });
    }

    /**
     * @returns any OauthRedirectUrl response
     * @throws ApiError
     */
    public static getMicrosoftOauthInbox(): CancelablePromise<{
        data: OauthRedirectUrl;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/microsoft/oauth/inbox',
        });
    }

    /**
     * @returns any OauthRedirectUrl response
     * @throws ApiError
     */
    public static getHubspotOauth(): CancelablePromise<{
        data: OauthRedirectUrl;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/hubspot/oauth',
        });
    }

    /**
     * @returns any success
     * @throws ApiError
     */
    public static deleteHubspotOauth(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/hubspot/oauth',
        });
    }

    /**
     * @returns any OauthRedirectUrl response
     * @throws ApiError
     */
    public static patchHubspotOauthReconnect(): CancelablePromise<{
        data: OauthRedirectUrl;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/hubspot/oauth/reconnect',
        });
    }

    /**
     * @returns any success
     * @throws ApiError
     */
    public static getCompanyOffDays(): CancelablePromise<{
        data: CompanyOffDays;
        permission?: CompanyOffDaysPermissions;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/company/off_days',
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static postCompanyOffDays(
        requestBody: PostCompanyOffDays,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/company/off_days',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param offDayId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchCompanyOffDays(
        offDayId: string,
        requestBody: PatchCompanyOffDay,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/company/off_days/{off_day_id}',
            path: {
                'off_day_id': offDayId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param offDayId
     * @returns any success
     * @throws ApiError
     */
    public static deleteCompanyOffDays(
        offDayId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/company/off_days/{off_day_id}',
            path: {
                'off_day_id': offDayId,
            },
        });
    }

    /**
     * @param offDayId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchCompanyOffDaysEnable(
        offDayId: string,
        requestBody: PatchCompanyOffDayEnable,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/company/off_days/{off_day_id}/enable',
            path: {
                'off_day_id': offDayId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any success
     * @throws ApiError
     */
    public static getCompanyHolidays(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/company/holidays',
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchCompanyHolidays(
        requestBody: PatchCompanyHolidays,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/company/holidays',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OnboardingDetails
     * @throws ApiError
     */
    public static getOnboarding(): CancelablePromise<{
        data: OnboardingDetails;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/onboarding',
        });
    }

    /**
     * @returns any success
     * @throws ApiError
     */
    public static postOnboardingStepRetry(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/onboarding/step/retry',
        });
    }

    /**
     * @returns any success
     * @throws ApiError
     */
    public static postOnboardingContinue(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/onboarding/continue',
        });
    }

    /**
     * @returns any success
     * @throws ApiError
     */
    public static postOnboardingReset(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/onboarding/reset',
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static putOnboardingBasicInfo(
        requestBody: PutOnboardingBasicInfo,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/onboarding/basic_info',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static putOnboardingDomainSetup(
        requestBody: PutOnboardingDomainSetup,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/onboarding/domain_setup',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static putOnboardingGoogleWsCredentials(
        requestBody: PutGoogleWSCredentials,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/onboarding/google_ws_credentials',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OnboardingDomains
     * @throws ApiError
     */
    public static getOnboardingDomains(): CancelablePromise<{
        data: OnboardingDomains;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/onboarding/domains',
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static putOnboardingDomainsColdOutboundRecords(
        requestBody: PutOnboardingDomainColdOutboundRecord,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/onboarding/domains/cold_outbound/records',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static putOnboardingDomainsVerificationTxt(
        requestBody: PutOnboardingDomainsVerificationTXT,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/onboarding/domains/verification_txt',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static putOnboardingDomainsDkim(
        requestBody: PutOnboardingDomainsDKIM,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/onboarding/domains/dkim',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OnboardingInboxes
     * @throws ApiError
     */
    public static getOnboardingInboxes(): CancelablePromise<{
        data: OnboardingInboxes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/onboarding/inboxes',
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static putOnboardingInboxes(
        requestBody: PutOnboardingInboxes,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/onboarding/inboxes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static putOnboardingInboxesAppPassword(
        requestBody: PutOnboardingInboxesAppPassword,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/onboarding/inboxes/app_password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param inboxId
     * @returns any success
     * @throws ApiError
     */
    public static patchOnboardingInboxesDisconnect(
        inboxId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/onboarding/inboxes/{inbox_id}/disconnect',
            path: {
                'inbox_id': inboxId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static postVaultPasswords(
        requestBody: PostPassword,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/vault/passwords',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any success
     * @throws ApiError
     */
    public static getVaultPasswords(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/vault/passwords',
        });
    }

    /**
     * @param requestBody csv file
     * @returns any success
     * @throws ApiError
     */
    public static postVaultPasswordsImport(
        requestBody?: Blob,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/vault/passwords/import',
            body: requestBody,
            mediaType: 'text/csv',
        });
    }

    /**
     * @param passwordId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchVaultPasswords(
        passwordId: string,
        requestBody: PatchPassword,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/vault/passwords/{password_id}',
            path: {
                'password_id': passwordId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param passwordId
     * @returns any success
     * @throws ApiError
     */
    public static getVaultPasswords1(
        passwordId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/vault/passwords/{password_id}',
            path: {
                'password_id': passwordId,
            },
        });
    }

    /**
     * @param passwordId
     * @returns any success
     * @throws ApiError
     */
    public static deleteVaultPasswords(
        passwordId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/vault/passwords/{password_id}',
            path: {
                'password_id': passwordId,
            },
        });
    }

    /**
     * @param passwordId
     * @returns any success
     * @throws ApiError
     */
    public static getVaultPasswordsOtpCode(
        passwordId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/vault/passwords/{password_id}/otp_code',
            path: {
                'password_id': passwordId,
            },
        });
    }

    /**
     * @returns any success
     * @throws ApiError
     */
    public static getPaymentProducts(): CancelablePromise<{
        data: PaymentProducts;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/payment/products',
        });
    }

    /**
     * @param sessionId
     * @returns void
     * @throws ApiError
     */
    public static getPaymentCheckoutSuccess(
        sessionId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/payment/checkout/success',
            query: {
                'session_id': sessionId,
            },
            errors: {
                302: `success`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns string success
     * @throws ApiError
     */
    public static postPaymentCheckoutSession(
        requestBody: PostPaymentCheckoutSession,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/payment/checkout/session',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns string success
     * @throws ApiError
     */
    public static getPaymentCustomerPortal(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/payment/customer/portal',
        });
    }

    /**
     * @returns any body
     * @throws ApiError
     */
    public static getPhoneNumbers(): CancelablePromise<{
        data: PhoneNumbers;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/phone_numbers',
        });
    }

    /**
     * @param requestBody body
     * @returns any ok
     * @throws ApiError
     */
    public static postPhoneNumbers(
        requestBody: PostPhoneNumber,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/phone_numbers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param filter
     * @returns any body
     * @throws ApiError
     */
    public static getPhoneNumbersBuyable(
        filter?: string,
    ): CancelablePromise<{
        data: PhoneNumbersBuyable;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/phone_numbers/buyable',
            query: {
                'filter': filter,
            },
        });
    }

    /**
     * @param phoneNumberId
     * @param requestBody body
     * @returns any ok
     * @throws ApiError
     */
    public static patchPhoneNumbers(
        phoneNumberId: string,
        requestBody: PatchPhoneNumber,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/phone_numbers/{phoneNumberId}',
            path: {
                'phoneNumberId': phoneNumberId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param phoneNumberId
     * @returns any ok
     * @throws ApiError
     */
    public static deletePhoneNumbers(
        phoneNumberId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/phone_numbers/{phoneNumberId}',
            path: {
                'phoneNumberId': phoneNumberId,
            },
        });
    }

    /**
     * @returns any Profile response
     * @throws ApiError
     */
    public static getProfile(): CancelablePromise<{
        data: Profile;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/profile',
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchProfile(
        requestBody: Profile,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/profile',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param accountDomainName
     * @param jobTitles
     * @returns any Enriched account
     * @throws ApiError
     */
    public static getSequenceGenerationAccountEnrichment(
        accountDomainName: string,
        jobTitles: string,
    ): CancelablePromise<{
        data: AccountEnrichment;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sequence_generation/account_enrichment',
            query: {
                'accountDomainName': accountDomainName,
                'jobTitles': jobTitles,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Generated approach
     * @throws ApiError
     */
    public static postSequenceGenerationApproach(
        requestBody: GenerateApproachRequest,
    ): CancelablePromise<{
        data: Approach;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sequence_generation/approach',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any body
     * @throws ApiError
     */
    public static getTeams(): CancelablePromise<{
        data: TeamStructure;
        permissions: TeamsPermissions;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/teams',
        });
    }

    /**
     * @param requestBody body
     * @returns any ok
     * @throws ApiError
     */
    public static postTeams(
        requestBody: PostTeam,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/teams',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param teamId
     * @param requestBody body
     * @returns any ok
     * @throws ApiError
     */
    public static patchTeams(
        teamId: string,
        requestBody: PatchTeam,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/teams/{teamId}',
            path: {
                'teamId': teamId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param teamId
     * @returns any ok
     * @throws ApiError
     */
    public static deleteTeams(
        teamId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/teams/{teamId}',
            path: {
                'teamId': teamId,
            },
        });
    }

    /**
     * @param requestBody body
     * @returns any ok
     * @throws ApiError
     */
    public static patchTeamsMembersMove(
        requestBody: PatchTeamsMembersMove,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/teams/members/move',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody body
     * @returns any ok
     * @throws ApiError
     */
    public static patchTeamsInboxesMove(
        requestBody: PatchTeamsInboxesMove,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/teams/inboxes/move',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Workspace response
     * @throws ApiError
     */
    public static getWorkspace(): CancelablePromise<{
        data: Workspace;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/workspace',
        });
    }

    /**
     * @returns any Workspace invitations response
     * @throws ApiError
     */
    public static getWorkspaceInvitations(): CancelablePromise<{
        data: WorkspaceInvitations;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/workspace/invitations',
        });
    }

    /**
     * @param invitationId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static postWorkspaceInvitationsAccept(
        invitationId: string,
        requestBody: PostWorkspaceInvitationAccept,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/workspace/invitations/{invitation_id}/accept',
            path: {
                'invitation_id': invitationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param invitationId
     * @returns any success
     * @throws ApiError
     */
    public static deleteWorkspaceInvitations(
        invitationId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/workspace/invitations/{invitation_id}',
            path: {
                'invitation_id': invitationId,
            },
        });
    }

    /**
     * @param invitationId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchWorkspaceInvitations(
        invitationId: string,
        requestBody: PatchWorkspaceInvitation,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/workspace/invitations/{invitation_id}',
            path: {
                'invitation_id': invitationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Workspace response
     * @throws ApiError
     */
    public static getWorkspaceViews(): CancelablePromise<{
        data: WorkspaceViews;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/workspace/views',
        });
    }

    /**
     * @param memberId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchWorkspaceMembersDelete(
        memberId: string,
        requestBody: PatchWorkspaceMemberDelete,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/workspace/members/{member_id}/delete',
            path: {
                'member_id': memberId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param memberId
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static patchWorkspaceMembers(
        memberId: string,
        requestBody: PatchWorkspaceMember,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/workspace/members/{member_id}',
            path: {
                'member_id': memberId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param invitationId
     * @returns any success
     * @throws ApiError
     */
    public static postWorkspaceInvitationsReminder(
        invitationId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/workspace/invitations/{invitation_id}/reminder',
            path: {
                'invitation_id': invitationId,
            },
        });
    }

    /**
     * @param invitationId
     * @param token
     * @returns any success
     * @throws ApiError
     */
    public static getWorkspaceUsersAccept(
        invitationId: string,
        token: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/workspace/users/{invitation_id}/accept',
            path: {
                'invitation_id': invitationId,
            },
            query: {
                'token': token,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static postWorkspaceMembers(
        requestBody: PostWorkspaceMember,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/workspace/members',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
