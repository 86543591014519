import { SingleStep } from './SingleStep'

export function AllStepsSkeleton() {
  return (
    <div className="m-auto max-w-2xl">
      {[...Array(3).keys()].map((_, index) => (
        <div key={index} className="skeleton mt-6 h-80 w-full" />
      ))}
    </div>
  )
}

export function SingleStepSkeleton() {
  return (
    <SingleStep>
      <div className="skeleton m-auto h-[400px] w-full max-w-2xl"></div>
      <div className="skeleton m-auto h-[400px] w-full max-w-2xl"></div>
      <div className="skeleton m-auto h-[400px] w-full max-w-2xl"></div>
    </SingleStep>
  )
}
