import { useState } from 'react'
import { useMeetingTypes } from '@/api/meetings'
import { useGetSignature } from '@/api/signatures'
import { Text } from '@/ui'
import { SignatureForm } from './SignatureForm'

export function Signatures() {
  const { data: queryData, isPending: getSignatureIsPending } = useGetSignature()
  const { data: meetingTypeData, isPending: getMeetingTypesIsPending } = useMeetingTypes()
  const data = queryData?.data
  const [isEditing, setIsEditing] = useState(false)

  return (
    <section>
      <Text variant="title" className="pb-2">
        Signature
      </Text>
      <Text className="pb-6">Manage your personal signature.</Text>
      {!getSignatureIsPending &&
        !getMeetingTypesIsPending &&
        data &&
        (isEditing ? (
          <SignatureForm
            initialData={data}
            cancelEditing={() => setIsEditing(false)}
            calendarLinks={meetingTypeData?.data || []}
          />
        ) : (
          <button
            className="w-full space-y-1 rounded-lg border border-light p-4 text-left text-sm hover:bg-extra-light"
            onClick={() => setIsEditing(true)}
            aria-label="edit signature"
          >
            <div className="font-medium">{data.name}</div>
            <div className="text-medium" dangerouslySetInnerHTML={{ __html: data.body }}></div>
          </button>
        ))}
    </section>
  )
}
