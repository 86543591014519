import { Text } from '@/ui/Text'

export function NotFound() {
  return (
    <div className="m-auto flex h-screen w-80 flex-col justify-center text-center">
      <div className="pb-44">
        <Text variant="title" className="mb-2 leading-8 text-dark">
          Not found
        </Text>
        <Text className="leading-5 text-dusk">
          We’re sorry but that page cannot be found. Please check the link spelling or request a new one.
        </Text>
      </div>
    </div>
  )
}
