import { EmailThreadMode, Sequence, SequenceSendMode } from '@/api/core'

export type Action =
  | { type: 'change_body'; body: { text: string; index: number } }
  | { type: 'change_thread_mode'; body: { mode: EmailThreadMode; index: number } }
  | { type: 'change_subject'; body: { text: string; index: number } }
  | { type: 'change_delay'; body: { delay: number; index: number } }
  | { type: 'change_send_mode'; body: { sendMode: SequenceSendMode; index: number } }
  | { type: 'add_step'; step: Sequence }
  | { type: 'add_ab_step'; step: Sequence; index: number }
  | { type: 'delete_step'; index: number }

export function reducer(state: { sequences: Sequence[] }, action: Action) {
  function isNextABTest(index: number) {
    return index < state.sequences.length - 1 && state.sequences[index + 1].ab_test
  }

  switch (action.type) {
    case 'add_step': {
      return {
        ...state,
        sequences: [...state.sequences, action.step],
      }
    }
    case 'add_ab_step': {
      const { step, index } = action
      const sequences = [...state.sequences]
      sequences.splice(index + 1, 0, step)
      return {
        ...state,
        sequences: sequences,
      }
    }
    case 'delete_step': {
      const { index: indexToDelete } = action
      const newSequences = state.sequences.filter((_, index) => index !== indexToDelete)

      // If user is deleteing step A in AB test, turn B into a normal step
      // by filtering out the step to be deleted step B would take the index of step A
      if (isNextABTest(indexToDelete)) {
        newSequences[indexToDelete].ab_test = false
      }

      if (indexToDelete === 0) {
        // deleted the first index so new first index's duration needs to be set default state
        newSequences[0] = {
          ...newSequences[0],
          delay: {
            days: 0,
            microseconds: 0,
          },
          email_thread_mode: EmailThreadMode.NEW,
          ab_test: false,
        }
      }

      return {
        ...state,
        sequences: newSequences,
      }
    }
    case 'change_body': {
      const { text, index } = action.body
      const sequences = [...state.sequences]
      sequences[index].email_template = text

      return {
        ...state,
        sequences: sequences,
      }
    }
    case 'change_delay': {
      const { delay, index } = action.body
      const sequences = [...state.sequences]
      sequences[index].delay = { days: delay, microseconds: 0 }

      if (isNextABTest(index)) {
        sequences[index + 1].delay = { days: delay, microseconds: 0 }
      }

      return {
        ...state,
        sequences: sequences,
      }
    }
    case 'change_send_mode': {
      const { sendMode, index } = action.body
      const isABTest = isNextABTest(index)
      const sequences = [...state.sequences]
      sequences[index].send_mode = sendMode
      if (sendMode === SequenceSendMode.MANUAL_CALL) {
        sequences[index].email_subject = ''
        sequences[index].email_template = ''
        sequences[index].email_thread_mode = EmailThreadMode.NEW
        if (isABTest) {
          sequences[index].ab_test = false
          sequences.splice(index + 1, 1)
        }
      } else if (sendMode === SequenceSendMode.AUTOMATIC_EMAIL) {
        if (isABTest) {
          sequences[index + 1].send_mode = sendMode
        }
      } else if (sendMode === SequenceSendMode.LINKEDIN_MESSAGE) {
        sequences[index].email_subject = ''
        sequences[index].email_thread_mode = EmailThreadMode.NEW
        if (isABTest) {
          sequences[index].ab_test = false
          sequences.splice(index + 1, 1)
        }
      } else if (sendMode === SequenceSendMode.MANUAL_EMAIL) {
        if (isABTest) {
          sequences[index].ab_test = false
          sequences.splice(index + 1, 1)
        }
      }

      return {
        ...state,
        sequences: sequences,
      }
    }
    case 'change_thread_mode': {
      const { mode, index } = action.body
      const sequences = [...state.sequences]

      // Cannot change thread mode for the first step
      if (index !== 0) {
        sequences[index].email_thread_mode = mode

        if (isNextABTest(index)) {
          sequences[index + 1].email_thread_mode = mode
        }
      }

      return {
        ...state,
        sequences: sequences,
      }
    }
    case 'change_subject': {
      const { text, index } = action.body
      const sequences = [...state.sequences]
      sequences[index].email_subject = text

      return {
        ...state,
        sequences: sequences,
      }
    }
  }
}
