import { useState } from 'react'
import { useImmerReducer } from 'use-immer'
import { useContactListAddContacts, useContactListsImportable } from '@/api/contact_lists'
import {
  useContactsCreate,
  useContactsUploadCampaignId,
  useContactsUploadContactList,
  useContactsUploadContactListId,
} from '@/api/contacts'
import { ContactActionable, PostContactsResponse } from '@/api/core'
import { Dialog } from '../Dialog'
import { contactsToUpload } from './convert'
import { encodeToBase64, initState, readData, reducer } from './reducer'
import { ErrorStep, ListSelectStep, LoadingStep, MappingStep, ReviewStep, SummaryStep, UploadStep } from './steps'

type ImportProps = {
  open: boolean
  onClose: () => void
  contactListId?: string
  campaignId?: string
  campaignHasListName?: boolean
  defaultListName?: string
}

export function Import(props: ImportProps) {
  const { open, onClose, defaultListName, campaignId, campaignHasListName, contactListId } = props
  const [contactState, dispatch] = useImmerReducer(reducer, initState)
  const [step, setStep] = useState<'upload' | 'mapping' | 'listselect' | 'error' | 'review' | 'summary'>('upload')
  const [uploadError, setUploadError] = useState<Error | null>(null)
  const [importedContactsNo, setImportedContactsNo] = useState<number>(0)
  const [postResponse, setPostResponse] = useState<PostContactsResponse>({} as PostContactsResponse)

  const [contactListName, setContactListName] = useState<string | undefined>(defaultListName)
  const uploadContacts = useContactsCreate()
  const uploadContactsToNewList = useContactsUploadContactList()
  const uploadContactsToList = useContactsUploadContactListId()
  const uploadContactsToCampaign = useContactsUploadCampaignId()
  const addContactsToList = useContactListAddContacts()
  const { data: contactLists } = useContactListsImportable()

  const onUpload = async (files: File[]) => {
    if (files.length != 1) return

    try {
      const b64File = await encodeToBase64(files[0])
      const state = readData(b64File, files[0].name)
      dispatch({ type: 'set_state', state })
      setStep('mapping')
    } catch (e) {
      setUploadError(e as Error)
    }
  }

  const doImport = (v: { listName?: string; contactListId?: string }) => {
    const postContacts = contactsToUpload(contactState)

    if (campaignId) {
      uploadContactsToCampaign.mutate(
        {
          contacts: postContacts,
          contact_list_name: v?.listName ?? '',
          campaignId: campaignId,
        },
        {
          onSuccess: (resp) => {
            setPostResponse(resp.data)
            setContactListName(v?.listName)
            if (resp.data.duplicated_in_campaigns.length > 0) {
              setStep('review')
            } else {
              setStep('summary')
            }
          },
          onError: (err) => {
            setUploadError(err as Error)
            setStep('error')
          },
        },
      )
    } else if (contactListId || v.contactListId) {
      uploadContactsToList.mutate(
        {
          contacts: postContacts,
          contactListId: contactListId || v.contactListId || '',
        },
        {
          onSuccess: (resp) => {
            setPostResponse(resp.data)
            setContactListName(v?.listName)
            if (resp.data.duplicated_in_campaigns.length > 0) {
              setStep('review')
            } else {
              setStep('summary')
            }
          },
          onError: (err) => {
            setUploadError(err as Error)
            setStep('error')
          },
        },
      )
    } else if (v.listName) {
      uploadContactsToNewList.mutate(
        {
          contacts: postContacts,
          contact_list_name: v.listName,
        },
        {
          onSuccess: (resp) => {
            setPostResponse(resp.data)
            setContactListName(v.listName)
            if (resp.data.duplicated_in_campaigns.length > 0) {
              setStep('review')
            } else {
              setStep('summary')
            }
          },
          onError: (err) => {
            setUploadError(err as Error)
            setStep('error')
          },
        },
      )
    } else {
      uploadContacts.mutate(
        { contacts: postContacts },
        {
          onSuccess: (resp) => {
            setPostResponse(resp.data)
            setStep('summary')
          },
          onError: (err) => {
            setUploadError(err as Error)
            setStep('error')
          },
        },
      )
    }
  }

  const onMappingImport = () => {
    if (campaignId || contactListId) {
      doImport({ listName: contactListName })
    } else {
      setStep('listselect')
    }
  }

  const onListSelectImport = (v: { listName?: string; contactListId?: string }) => {
    doImport(v)
  }

  const onInclude = (contacts: ContactActionable[]) => {
    if (contacts.length === 0) {
      setStep('summary')
    }

    const contactIds = contacts.map((c) => c.id)
    addContactsToList.mutate(
      {
        contactListId: postResponse.contact_list_id,
        contact_ids: contactIds,
      },
      {
        onSuccess: () => {
          setImportedContactsNo((n) => n + contacts.length)
          setStep('summary')
        },

        onError: () => {
          // NOTE: we dont' have a good error handling right now, but some contacts where added
          // so let just go to summary page
          setStep('summary')
        },
      },
    )
  }

  const isPending =
    uploadContacts.isPending ||
    uploadContactsToNewList.isPending ||
    uploadContactsToList.isPending ||
    uploadContactsToCampaign.isPending

  const steps = {
    upload: () => <UploadStep onUpload={onUpload} onCancel={onClose} uploadError={uploadError} />,
    mapping: () => (
      <MappingStep
        onImport={onMappingImport}
        onCancel={onClose}
        state={contactState}
        dispatch={dispatch}
        createList={!!campaignId && !campaignHasListName}
        defaultListName={contactListName}
        importBtnText={campaignId || contactListId ? 'Import' : 'Next'}
        setListName={setContactListName}
      />
    ),
    listselect: () => (
      <ListSelectStep onImport={onListSelectImport} onCancel={onClose} contactLists={contactLists?.data || []} />
    ),
    review: () => <ReviewStep onInclude={onInclude} duplicated={postResponse.duplicated_in_campaigns} />,
    summary: () => (
      <SummaryStep
        onDone={onClose}
        importedContacts={postResponse.contacts_imported_no + importedContactsNo}
        inUseContactsIncluded={importedContactsNo}
        notInUseContactsIncluded={postResponse.duplicated_in_campaigns.length - importedContactsNo}
        unsubscribedContacts={postResponse.unsubscribed.length}
        notInterestedContacts={postResponse.not_interested.length}
        duplicatedContacts={postResponse.duplicated_in_list_no}
      />
    ),
    error: () => <ErrorStep onCancel={onClose} error={uploadError} />,
  }

  const title = () => {
    switch (step) {
      case 'mapping': {
        return 'Map attributes'
      }
      default: {
        return 'Import Contacts'
      }
    }
  }

  return (
    <Dialog open={open} onClose={onClose} title={title()}>
      <div className="h-[min(60vh,500px)] w-[min(80vw,700px)]">
        <div className="flex h-full flex-col">{isPending ? <LoadingStep /> : steps[step]()}</div>
      </div>
    </Dialog>
  )
}
