import { useEffect, useState } from 'react'
import { DateRange, DayPicker, DayPickerRangeProps, DayPickerSingleProps } from 'react-day-picker'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import * as yup from 'yup'
import { Popover, Tab } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCompanyOffDayCreate, useCompanyOffDayUpdate } from '@/api/company_off_days'
import { CompanyOffDay, PostCompanyOffDays } from '@/api/core'
import { dateFormat, dateOnlyRFC3999Format, parseDate } from '@/lib/date'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Input } from '@/ui'
import { OpacityTransition } from '@/ui/Transition/OpacityTransition'

type OffDayFormValues = Omit<PostCompanyOffDays, 'startDate' | 'endDate'> & {
  startDate: Date | undefined
  endDate: Date | undefined
}

type OffDayFormProps = {
  onClose: () => void
  edit: boolean
  offDay?: CompanyOffDay
}

export function OffDayForm(props: OffDayFormProps) {
  const toast = useToast()
  const { edit, offDay, onClose } = props

  const [mode, setMode] = useState<'single' | 'range'>(offDay?.endDate ? 'range' : 'single')
  const [selectedIndex, setSelectedIndex] = useState(offDay?.endDate ? 1 : 0)
  useEffect(() => {
    setSelectedIndex(mode === 'single' ? 0 : 1)
  }, [mode])

  const schema = yup.object().shape({
    name: yup.string().required('required'),
    startDate: yup.date().required('required'),
    endDate: yup.date(),
  })

  const methods = useForm<OffDayFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      name: offDay?.name ?? '',
      startDate: offDay?.startDate ? parseDate(offDay.startDate) : undefined,
      endDate: offDay?.endDate ? parseDate(offDay.endDate) : undefined,
    },
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = methods

  const createOffDay = useCompanyOffDayCreate()
  const updateOffDay = useCompanyOffDayUpdate(offDay?.id ?? '')

  const onSubmit = (data: OffDayFormValues) => {
    // should never happen because of yup validation
    if (!data.startDate) return

    if (mode === 'range' && !data.endDate) {
      setError('endDate', { message: 'end date required' })
      return
    }

    const mut = edit ? updateOffDay : createOffDay
    mut.mutate(
      {
        name: data.name,
        startDate: dateOnlyRFC3999Format(data.startDate),
        endDate: data.endDate ? dateOnlyRFC3999Format(data.endDate) : null,
      },
      {
        onSuccess: () => onClose(),
        onError: (err) => {
          toast.createToast({ message: (err as any)?.body?.message || 'Failed to create off day', error: true })
        },
      },
    )
  }

  const onTabChange = (index: number) => {
    setMode(index === 0 ? 'single' : 'range')
    setValue('startDate', undefined)
    setValue('endDate', undefined)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="rounded-md border border-light p-6">
        <div>
          <Input registration={register('name')} label="Name" className="w-full" error={errors.name} />
          <Tab.Group onChange={onTabChange} selectedIndex={selectedIndex}>
            <Tab.List className="my-6 flex w-fit gap-2 rounded-lg border">
              <Tab className="px-3 py-2 focus-visible:outline-none ui-selected:rounded-lg ui-selected:ring-2 ui-selected:ring-accent">
                Single day
              </Tab>
              <Tab className="px-3 py-2 focus-visible:outline-none ui-selected:rounded-lg ui-selected:ring-2 ui-selected:ring-accent">
                Date range
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <InputDatePicker />
              </Tab.Panel>
              <Tab.Panel>
                <InputDateRangePicker />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
          <div className="mt-6 flex items-center justify-end gap-2">
            <Button type="button" variant="text" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" variant="accent">
              {edit ? 'Update' : 'Add'}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

function InputDatePicker() {
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<OffDayFormValues>()
  const startDate = watch('startDate')

  return (
    <>
      <Popover className="relative">
        <Popover.Button
          as={Input}
          label="Date"
          error={errors.startDate}
          placeholder={dateFormat(new Date())}
          value={startDate ? dateFormat(startDate) : ''}
          readOnly
        />

        <OpacityTransition>
          <Popover.Panel className="absolute left-0 z-10 mt-1 rounded-lg shadow-lg ring-1 ring-gray-300">
            {({ close }) => (
              <StyledDayPicker
                mode="single"
                onSelect={(d) => {
                  close()
                  setValue('startDate', d)
                }}
                selected={startDate}
              />
            )}
          </Popover.Panel>
        </OpacityTransition>
      </Popover>
    </>
  )
}

function InputDateRangePicker() {
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<OffDayFormValues>()
  const startDate = watch('startDate')
  const endDate = watch('endDate')
  const selected = { from: startDate, to: endDate }
  const onSelect = (d: DateRange | undefined) => {
    setValue('startDate', d?.from)
    setValue('endDate', d?.to)
  }

  const value = (startDate && `${dateFormat(startDate)} — ${endDate ? dateFormat(endDate) : 'End'}`) ?? ''

  return (
    <>
      <Popover className="relative ">
        <Popover.Button
          as={Input}
          label="Date range"
          value={value}
          error={errors.startDate ?? errors.endDate}
          placeholder="Start — End"
          readOnly
        />

        <OpacityTransition>
          <Popover.Panel className="absolute left-0 z-10 mt-1 rounded-lg bg-extra-light shadow-lg ring-1 ring-gray-300">
            <StyledDayPicker onSelect={onSelect} selected={selected} mode="range" className="bg-white" />
          </Popover.Panel>
        </OpacityTransition>
      </Popover>
    </>
  )
}

function StyledDayPicker(props: DayPickerSingleProps | DayPickerRangeProps) {
  return (
    <DayPicker
      {...props}
      fromDate={new Date()}
      fixedWeeks
      className="rounded-lg bg-extra-light "
      modifiersClassNames={{
        selected: 'bg-accent text-white',
      }}
    />
  )
}
