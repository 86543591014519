import React from 'react'

type MouseTrapProps = {
  children: React.ReactNode
  as?: React.ElementType
  preventDefault?: boolean
}

export function MouseTrap(props: MouseTrapProps) {
  const { children, as: Component = 'div', preventDefault = false } = props
  return (
    <Component
      onClick={(e: React.MouseEvent) => {
        preventDefault && e.preventDefault()
        e.stopPropagation()
      }}
    >
      {children}
    </Component>
  )
}
