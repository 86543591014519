// putBrsInEmptyPTags takes all <p> tags with no content and adds
// a <br> inside them
export function putBrsInEmptyPTags(html: string): string {
  const parser = new DOMParser()
  const document = parser.parseFromString(html, 'text/html')
  const emptyPTags = document.querySelectorAll('p:not(:has(*))')
  emptyPTags.forEach((pTag) => {
    if (pTag.innerHTML.trim().length === 0) {
      const newBr = document.createElement('br')
      newBr.setAttribute('class', 'rift-br')
      pTag.replaceChildren(newBr)
    }
  })

  return document.body.innerHTML
}
