/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ManualStepType {
    EMAIL = 'EMAIL',
    EMAIL_REPLY = 'EMAIL_REPLY',
    EMAIL_REPLY_ABANDONED = 'EMAIL_REPLY_ABANDONED',
    CALL = 'CALL',
    LINKEDIN_MESSAGE = 'LINKEDIN_MESSAGE',
    RECOMMENDATION = 'RECOMMENDATION',
}
