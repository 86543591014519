import { PropsWithChildren } from 'react'
import { EmailEditor } from '../EmailEditor'

// Note for now this is just a simple wrapper around the EmailEditor component
// to distinguish it in our app.
// In the future the text editor should be a separate component.
function TextEditorFn(props: PropsWithChildren & { variables?: Record<string, string> }) {
  const { children, variables } = props
  return <EmailEditor variables={variables}>{children}</EmailEditor>
}

export const TextEditor = Object.assign(TextEditorFn, {
  Editor: EmailEditor.Body,
})
