const DEFAULT = { id: 'skip', label: 'Skip' }

export function MappingSkeleton() {
  const tempRows = Array(5).fill(DEFAULT)

  return (
    <section>
      <div className="skeleton h-[32px] text-lg" />
      <div className="grid grid-cols-[minmax(0,_1fr)_20px_minmax(0,_1fr)] gap-y-5 pt-8">
        <div className="skeleton h-[32px] w-[32px]" />
        <div className="skeleton col-start-3 h-[32px] w-[32px]" />
        {tempRows.map((_, index) => (
          <div key={index} className="skeleton col-span-3 h-[88px]" />
        ))}
        <div className="col-span-3 flex justify-end">
          <div className="skeleton h-[36px] w-[200px]" />
        </div>
      </div>
    </section>
  )
}
