import { PropsWithChildren } from 'react'
import clsx from 'clsx'

export type TextVariants = 'title' | 'subtitle' | 'text' | 'subtext'

type TextProps = {
  className?: string
  variant?: TextVariants
}

export function Text({ className, variant = 'text', children, ...props }: PropsWithChildren<TextProps>) {
  switch (variant) {
    case 'title': {
      return <h1 className={clsx('text-lg font-medium', className)}>{children}</h1>
    }
    case 'subtitle': {
      return (
        <h2 className={clsx('text-md font-medium', className)} {...props}>
          {children}
        </h2>
      )
    }
    case 'subtext': {
      return (
        <p className={clsx('text-xs', className)} {...props}>
          {children}
        </p>
      )
    }
    default: {
      return (
        <p className={clsx('text-sm', className)} {...props}>
          {children}
        </p>
      )
    }
  }
}
