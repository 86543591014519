import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ApiError, DefaultService } from '@/api/core'
import { errhandler } from './helpers'

export const queryKey = {
  base: ['chameleon'],
  search: (q: string) => ['chameleon-search', q],
}

export function useChameleonSearch(q: string) {
  return useQuery({
    queryKey: queryKey.search(q),
    queryFn: () => DefaultService.getChameleonSearch(q),
  })
}

export function useChameleon() {
  return useQuery({
    queryKey: queryKey.base,
    queryFn: DefaultService.getChameleon,
  })
}

export function useChameleonCreate() {
  const queryClient = useQueryClient()

  return useMutation({
    onError: (error: ApiError) => {
      errhandler(error, 'Client switching failed.')
    },
    onSuccess: () => {
      queryClient.invalidateQueries()
      queryClient.resetQueries()
      queryClient.removeQueries()
      queryClient.clear()
    },
    mutationFn: (identityIdTo: string) => DefaultService.postChameleon(identityIdTo),
  })
}

export function useChameleonDelete() {
  const queryClient = useQueryClient()

  return useMutation({
    onError: (error: ApiError) => {
      errhandler(error, 'Client Reset Identity failed.')
    },
    onSuccess: () => {
      queryClient.invalidateQueries()
      queryClient.resetQueries()
      queryClient.removeQueries()
      queryClient.clear()
    },
    mutationFn: () => DefaultService.deleteChameleon(),
  })
}
