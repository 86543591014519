import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { PutOnboardingDomainSetup } from '@/api/core'
import { useOnboardingPutDomainSetup } from '@/api/onboarding'
import { ButtonLoading, Input } from '@/ui'
import { Step } from '@/ui/headless'

type DomainSetupProps = {
  nextStep: () => void
}

export function DomainSetup(props: DomainSetupProps) {
  const { nextStep } = props
  const schema: yup.ObjectSchema<PutOnboardingDomainSetup> = yup.object().shape({
    apiKey: yup.string().required('required').trim(),
    apiSecret: yup.string().required('required').trim(),
    customerNumber: yup.string().required('required').matches(/^\d+$/, 'must be a number'),
    primaryDomain: yup.string().required('required').trim(),
    coldOutboundDomain: yup.string().required('required').trim(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PutOnboardingDomainSetup>({
    resolver: yupResolver(schema),
  })
  const putDomainSetup = useOnboardingPutDomainSetup()

  const onSubmit = (data: PutOnboardingDomainSetup) => {
    putDomainSetup.mutate(data, {
      onSuccess: nextStep,
    })
  }

  return (
    <form>
      <div className="w-[30rem]">
        <h1 className="mb-6 text-lg font-medium">GoDaddy credentials</h1>
        <div className="flex w-full flex-col gap-4">
          <Input registration={register('apiKey')} error={errors.apiKey} label="API key" />
          <Input registration={register('apiSecret')} error={errors.apiSecret} type="password" label="API secret" />
          <Input registration={register('customerNumber')} error={errors.customerNumber} label="Customer number" />
          <Input registration={register('primaryDomain')} error={errors.primaryDomain} label="Primary domain" />
          <Input
            registration={register('coldOutboundDomain')}
            error={errors.coldOutboundDomain}
            label="Cold outbound domain"
          />
        </div>

        <span className={clsx('text-alert', !putDomainSetup.isError && 'invisible')}>
          Failed to save basic info {(putDomainSetup.error as any)?.body?.message}
        </span>

        <div className="mt-8 flex justify-end">
          <Step.Next
            as={ButtonLoading}
            type="submit"
            variant="accent"
            isLoading={putDomainSetup.isPending}
            onClick={(e) => {
              e.preventDefault()
              handleSubmit(onSubmit)()
            }}
          >
            Next: Google workspace
          </Step.Next>
        </div>
      </div>
    </form>
  )
}
