import { useLayoutEffect, useRef } from 'react'

export function useLatestValue<T>(value: T) {
  const cache = useRef(value)

  useLayoutEffect(() => {
    cache.current = value
  }, [value])

  return cache
}
