import React, { ButtonHTMLAttributes, FC, forwardRef, PropsWithChildren } from 'react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { Icon, WarningCircle } from '@phosphor-icons/react'

export const Variants = ['basic', 'accent', 'text', 'trailing-icon', 'leading-icon', 'icon']
export type Variant = (typeof Variants)[number]

export const variantDefaultClasses: { [key in Variant]?: string } = {
  basic:
    'bg-white border label border-light rounded-lg py-2 px-3 transition-all duration-100 hover:duration-200 ease-in hover:ease-out active:duration-200 active:ease-out h-9 disabled:opacity-80 disabled:cursor-not-allowed disabled:text-medium',
  accent:
    'bg-accent label text-white rounded-lg py-2 px-3 transition-all duration-100 hover:duration-200 ease-in hover:ease-out active:duration-200 active:ease-out h-9 disabled:opacity-60 disabled:cursor-not-allowed',
  text: 'label text-accent py-2 px-3 font-medium transition-all duration-100 hover:duration-200 ease-in hover:ease-out active:duration-200 active:ease-out h-9 disabled:opacity-80 disabled:cursor-not-allowed',
  'trailing-icon':
    'border label border-light rounded-lg py-2 pl-3 transition-all duration-100 hover:duration-200 ease-in hover:ease-out active:duration-200 active:ease-out h-9 disabled:opacity-80 disabled:cursor-not-allowed',
  'leading-icon':
    'border label border-light rounded-lg py-2 pr-3 transition-all duration-100 hover:duration-200 ease-in hover:ease-out active:duration-200 active:ease-out h-9 disabled:opacity-80 disabled:cursor-not-allowed',
}

export const variantHoverClasses: { [key in Variant]?: string } = {
  basic: 'hover:rounded-xl disabled:hover:rounded-lg',
  accent: 'hover:rounded-xl disabled:hover:rounded-lg',
  text: 'hover:text-[#5DA5FE]',
  'trailing-icon': 'hover:rounded-xl',
  'leading-icon': 'hover:rounded-xl',
}

export const variantActiveClasses: { [key in Variant]?: string } = {
  basic: 'active:rounded-xl active:text-medium',
  accent: 'active:rounded-xl active:text-opacity-80',
  text: 'active:text-medium',
  'trailing-icon': 'active:rounded-xl active:text-medium',
  'leading-icon': 'active:rounded-xl active:text-medium',
}

const iconVariantFills: { [key in Variant]?: string } = {
  'trailing-icon': 'fill-medium active:fill-light mx-2 min-w-5 min-h-5 pointer-events-none',
  'leading-icon': 'fill-medium active:fill-light mx-2 min-w-5 min-h-5 pointer-events-none',
  icon: 'fill-dark hover:fill-medium active:fill-light my-2 min-w-5 min-h-5 pointer-events-none',
}

type ButtonOurProps = {
  variant?: Variant
  icon?: Icon
  iconclassname?: string
}

type ButtonProps = PropsWithChildren<ButtonOurProps & ButtonHTMLAttributes<HTMLButtonElement>>

export const Button = forwardRef(function Button(props: ButtonProps, ref: React.Ref<HTMLButtonElement>) {
  const { variant = 'basic', icon: Icon, className, iconclassname, children, ...rest } = props
  return (
    <button
      {...rest}
      ref={ref}
      className={twMerge(
        'flex items-center justify-center',
        'disabled:pointer-events-none',
        variantDefaultClasses[variant],
        variantHoverClasses[variant],
        variantActiveClasses[variant],
        className,
      )}
    >
      {(variant === 'leading-icon' || variant === 'icon') && Icon && (
        <Icon className={twMerge(iconVariantFills[variant], iconclassname)} />
      )}
      {variant !== 'icon' && children}
      {variant === 'trailing-icon' && Icon && <Icon className={twMerge(iconVariantFills[variant], 'fill-alert')} />}
    </button>
  )
})

const DangerVariants = {
  basic: 'border-alert text-alert',
  accent: 'bg-alert text-white',
}
export const DangerButton: FC<
  PropsWithChildren<{ variant: 'basic' | 'accent' } & ButtonHTMLAttributes<HTMLButtonElement>>
> = (props) => {
  return (
    <Button {...props} className={twMerge(DangerVariants[props.variant], props.className)}>
      {props.children}
    </Button>
  )
}

export const DangerButtonWithIcon: FC<
  PropsWithChildren<{ variant: 'basic' | 'accent' } & ButtonHTMLAttributes<HTMLButtonElement>>
> = (props) => {
  return (
    <Button
      {...props}
      variant={'leading-icon'}
      icon={WarningCircle}
      iconclassname={props.variant === 'basic' ? 'fill-alert' : 'fill-white'}
      className={twMerge(DangerVariants[props.variant], props.className)}
    >
      {props.children}
    </Button>
  )
}

type ButtonLoadingProps = {
  variant: 'basic' | 'accent' | 'text'
  isLoading?: boolean
}

const loadingBaseClass = `
after:h-6 
after:w-6 
after:absolute
after:m-auto
after:top-0
after:bottom-0
after:left-0
after:right-0
after:animate-spin 
after:rounded-full 
after:border-2 
after:border-t-transparent
`
const loadingColorClasses: { [key in Variant]?: string } = {
  basic: 'after:border-gray-400',
  accent: 'after:border-accent',
  text: 'after:border-accent',
}

export const ButtonLoading = forwardRef(function ButtonLoading(
  props: PropsWithChildren<ButtonLoadingProps & ButtonHTMLAttributes<HTMLButtonElement>>,
  ref: React.Ref<HTMLButtonElement>,
) {
  const { isLoading, ...rest } = props
  return (
    <Button
      {...rest}
      ref={ref}
      className={clsx(isLoading && 'pointer-events-none relative border-none bg-transparent')}
    >
      {props.isLoading && <div className={clsx(loadingBaseClass, loadingColorClasses[props.variant])}></div>}
      <div className={clsx(props.isLoading && 'invisible')}>{props.children}</div>
    </Button>
  )
})
