import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { Spinner } from '@/ui/Spinner'

export function Loading() {
  return (
    <Transition
      appear
      as={Fragment}
      show={true}
      enter="ease-out delay-500 duration-200"
      enterFrom="transform opacity-0"
      enterTo="transform opacity-100"
    >
      <div className="flex grow flex-col items-center justify-center">
        <span className="mb-2 text-gray-900">Please do not close this window until the import is complete.</span>
        <span className="mb-2 text-sm text-gray-900">It may take a few minutes.</span>
        <Spinner size="xl" />
      </div>
    </Transition>
  )
}
