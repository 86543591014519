import { useParams } from 'react-router-dom'
import { useManualLinkedinMessageDone } from '@/api/manual_step'
import { ActivityHistory } from '../../ActivityHistory'
import { ContactInformation } from '../../ContactInformation'
import { SingleStep } from '../../SingleStep'
import { SingleStepSkeleton } from '../../Skeletons'

export function DoneLinkedinMessage() {
  const { eventId } = useParams() as { eventId: string }
  const { data, status } = useManualLinkedinMessageDone(eventId)

  if (status === 'pending' || status === 'error') {
    return <SingleStepSkeleton />
  }

  const { prev, next, contact, message } = data.data

  return (
    <SingleStep>
      <SingleStep.Navigation prev={prev} next={next} url="/inbox/done/" />
      <SingleStep.Main>
        <SingleStep.Card>
          <div className="ProseMirror relative">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </div>
        </SingleStep.Card>
        <ActivityHistory contactId={contact.id} />
      </SingleStep.Main>
      <ContactInformation contactId={contact.id} />
    </SingleStep>
  )
}
