import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { DefaultService, PatchMeeting, PatchMeetingType, PostMeeting, PostMeetingType } from '@/api/core'

export const queryKey = {
  base: () => ['meetings'] as const,
  lead: (id?: string) => ['meetings', 'lead', id] as const,
  meetingSlots: () => ['meetings', 'meetingSlots'] as const,
  meetingTypes: () => ['meetings', 'meetingTypes'] as const,
  meetingTypesPersonal: () => ['meetings', 'meetingTypes', 'personal'] as const,
  meetingType: (id: string) => ['meetings', 'meetingTypes', id] as const,
  users: () => ['meetings', 'users'] as const,
  tmplReminder: () => ['meetings', 'tmplReminder'] as const,
  bookings: (time: 'upcoming' | 'past' | undefined, q: string | undefined, types: string | undefined) =>
    ['meetings', 'bookings', time, q, types] as const,
}

export function useAvailability(company: string, meetingType: string) {
  return useQuery({
    queryKey: queryKey.meetingSlots(),
    queryFn: () => DefaultService.getMeetingAvailabilityByMeetingType(company, meetingType),
  })
}

export function useMeetingCreate(company: string, meetingType: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: PostMeeting) => DefaultService.createMeetingByType(company, meetingType, body),
  })
}

export function useMeetingUpdate(meetingId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: PatchMeeting) => DefaultService.patchMeetings(meetingId, body),
  })
}

export function useMeetingTypesPersonal() {
  return useQuery({
    queryKey: queryKey.meetingTypesPersonal(),
    queryFn: () => DefaultService.getPersonalMeetingTypes(),
  })
}
export function useMeetingTypes() {
  return useQuery({
    queryKey: queryKey.meetingTypes(),
    queryFn: () => DefaultService.getMeetingTypes(),
  })
}

export function useMeetingType(id: string) {
  return useQuery({
    queryKey: queryKey.meetingType(id),
    queryFn: () => DefaultService.getMeetingType(id),
  })
}

export function useMeetingTypeUpdate(id: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.meetingType(id) })
      queryClient.invalidateQueries({ queryKey: queryKey.meetingTypes() })
    },
    mutationFn: (body: PatchMeetingType) => DefaultService.patchMeetingType(id, body),
  })
}

export function useMeetingTypeCreate() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.meetingTypes() }),
    mutationFn: (body: PostMeetingType) => DefaultService.postMeetingType(body),
  })
}

export function useMeetingsUsers() {
  return useQuery({
    queryKey: queryKey.users(),
    queryFn: () => DefaultService.getMeetingTypeMembers(),
  })
}

export function useMeetingsTemplateReminder() {
  return useQuery({
    queryKey: queryKey.tmplReminder(),
    queryFn: DefaultService.getMeetingTypeTemplatesReminderInfo,
  })
}

export function useBookings(time: 'past' | 'upcoming' | undefined, q: string | undefined, types: string) {
  return useQuery({
    queryKey: queryKey.bookings(time, q, types),
    queryFn: () => DefaultService.getBookings(time, q, types),
  })
}

export function useMeetingTypeDelete(meetingTypeID: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.meetingTypes() })
      queryClient.invalidateQueries({ queryKey: queryKey.meetingType(meetingTypeID) })
    },
    mutationFn: () => DefaultService.deleteMeetingType(meetingTypeID),
  })
}
