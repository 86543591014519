import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Campaign } from '@/api/core'
import { Import } from '@/pages/Contacts/Import/Import'
import { useLoadSequence } from '@/pages/Sequences/hooks/useLoadSequence'
import { AddSingleContact } from './AddSingleContact'
import { AssignContactListDialog } from './AssignContactListDialog'

export type addContactTypes = 'upload' | 'upload+' | 'existing' | 'single'

type AddAudienceProps = {
  onClose: () => void
  campaign: Campaign
  addContactType: addContactTypes
}

export function AddAudience({ onClose, addContactType }: AddAudienceProps) {
  const { data, status } = useLoadSequence()
  if (status !== 'success') return null
  return <AddAudienceWithData onClose={onClose} campaign={data as Campaign} addContactType={addContactType} />
}

function AddAudienceWithData({ onClose, campaign, addContactType }: AddAudienceProps) {
  const hasContactList = campaign.contact_list_ids.length > 0
  const navigate = useNavigate()

  useEffect(() => {
    if (addContactType === 'upload+') {
      navigate(`/import?sequenceId=${campaign.id}`)
    }
  }, [addContactType])

  if (addContactType === 'upload') {
    return (
      <Import
        open={true}
        onClose={onClose}
        campaignId={campaign.id}
        campaignHasListName={campaign.contact_list_ids.length > 0}
        defaultListName={campaign.name + ' contacts'}
      />
    )
  }
  if (addContactType === 'upload+') {
    return null
  }

  if (addContactType === 'single') {
    return <AddSingleContact onClose={onClose} campaignId={campaign.id} />
  }

  return (
    <>
      {addContactType === 'existing' && (
        <AssignContactListDialog open={true} onClose={onClose} campaignId={campaign.id} append={hasContactList} />
      )}
    </>
  )
}
