import { CrmStageObject, StageDefinitions } from '@/api/core'
import { Select, Text } from '@/ui'

type SalesforceSyncObjectFormProps = {
  syncObjectsOptions: StageDefinitions
  object: CrmStageObject
  fieldId: string
  setSyncObject: (object: CrmStageObject, fieldId: string) => void
}

export function SalesforceSyncObjectForm({
  syncObjectsOptions,
  object,
  fieldId,
  setSyncObject,
}: SalesforceSyncObjectFormProps) {
  const options = syncObjectsOptions.map((option) => ({
    value: option.object + ' - ' + option.fieldId,
    label: option.object + ' - ' + option.fieldId,
    object: option.object,
    fieldId: option.fieldId,
  }))
  return (
    <section>
      <hgroup className="pb-5">
        <Text variant="title" className="pb-2">
          Pick the field to sync rift stages
        </Text>
        <Text variant="subtext" className="text-medium">
          Select which object and field you’d like to sync data between rift and Salesforce
        </Text>
      </hgroup>
      <div>
        <Select
          options={options}
          value={options.find((option) => option.object == object && option.fieldId == fieldId) || null}
          onChange={(value) => setSyncObject(value.object, value.fieldId)}
        />
      </div>
    </section>
  )
}
