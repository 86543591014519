import { useDeferredValue, useMemo, useReducer, useRef } from 'react'
import React from 'react'
import { Link } from 'react-router-dom'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import clsx from 'clsx'
import { CaretDown, CaretRight, CheckCircle, DotsSixVertical, WarningCircle, Wrench } from '@phosphor-icons/react'
import {
  CustomDomainStatus,
  DKIMStatus,
  DMARCStatus,
  EmailAppStatus,
  Inbox,
  MemberInbox,
  MemberInboxes,
  SPFStatus,
  Team,
  TeamMember,
  TeamMembers,
  TeamStructure,
} from '@/api/core'
import { useTeamDelete, useTeamInboxesMove, useTeamMembersMove } from '@/api/team'
import { useDisclosureV2 } from '@/hooks/useDisclosure'
import { useEvent } from '@/hooks/useEvent'
import { useOutsideClick } from '@/hooks/useOutsideClick'
import { ConfirmationDialog } from '@/pages/Contacts/ConfirmationDialog'
import { formatPercent } from '@/pages/utils'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Tooltip } from '@/ui'
import { Tree } from '@/ui/headless'
import { GroupFormDialog } from './GroupFormDialog'
import { InboxDataContext, useData as useInboxData } from './InboxContext'
import { SelectItemsActionsContext, SelectItemsDataContext, useActions, useData } from './SelectItemsContext'
import { ActionType, reducer } from './treeSelectedItemsReducer'

const MemberDragType = Symbol('MemberDragType')
const InboxDragType = Symbol('InboxDragType')

type TeamViewProps = {
  structure: TeamStructure
  search: string
  canMove: boolean
  inboxes: Inbox[]
}

export function TeamView(props: TeamViewProps) {
  const { inboxes, structure: initialStructure, search, canMove = false } = props
  const defSearch = useDeferredValue(search)
  const structure = useMemo(() => filterStructureByText(initialStructure, defSearch), [initialStructure, defSearch])
  const inboxesData = useMemo(() => {
    const data = inboxes.reduce(
      (acc, inbox) => {
        acc[inbox.id] = inbox
        return acc
      },
      {} as Record<string, Inbox>,
    )

    const membersHealth = initialStructure.members.reduce(
      (acc, member) => {
        acc[member.id] = member.inboxes.every((inbox) => data[inbox.id]?.healthy)
        return acc
      },
      {} as Record<string, boolean>,
    )

    const teamsHealth = initialStructure.teams.reduce(
      (acc, team) => {
        acc[team.id] = team.members.reduce((acc, member) => {
          membersHealth[member.id] = member.inboxes.every((inbox) => data[inbox.id]?.healthy)
          return acc && membersHealth[member.id]
        }, true)
        return acc
      },
      {} as Record<string, boolean>,
    )

    return {
      inboxes: data,
      teamsHealth,
      membersHealth,
    }
  }, [inboxes])

  const [state, dispatch] = useReducer(reducer, {
    selectedInboxes: [],
    selectedMembers: [],
  })

  const onSelectInbox = useEvent((e: React.MouseEvent, inbox: MemberInbox) => {
    if (e.ctrlKey || e.metaKey) {
      dispatch({ type: ActionType.ToggleInbox, inbox })
    } else {
      dispatch({ type: ActionType.SelectInbox, inbox })
    }
  })

  const onSelectMember = useEvent((e: React.MouseEvent, member: TeamMember) => {
    if (e.ctrlKey || e.metaKey) {
      dispatch({ type: ActionType.ToggleMember, member })
    } else {
      dispatch({ type: ActionType.SelectMember, member })
    }
  })

  const unselect = useEvent(() => dispatch({ type: ActionType.Unselect }))

  const actions = useMemo(
    () => ({
      onSelectInbox,
      onSelectMember,
      unselect,
    }),
    [],
  )

  const outsideRef = useOutsideClick(() => unselect())

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <InboxDataContext.Provider value={inboxesData}>
          <SelectItemsDataContext.Provider value={state}>
            <SelectItemsActionsContext.Provider value={actions}>
              <div ref={outsideRef}>
                <TeamStructureTree structure={structure} canMove={canMove} />
              </div>
            </SelectItemsActionsContext.Provider>
          </SelectItemsDataContext.Provider>
        </InboxDataContext.Provider>
      </DndProvider>
    </>
  )
}

type TeamStructureTreeProps = {
  structure: TeamStructure
  canMove: boolean
}

function TeamStructureTree(props: TeamStructureTreeProps) {
  const { structure, canMove } = props

  return (
    <Tree>
      <Tree.Items>
        {structure.teams.map((team) => (
          <TeamTree key={team.id} team={team} canMove={canMove} />
        ))}
        {structure.members.map((member) => (
          <MemberTree key={member.id} member={member} canMove={canMove} />
        ))}
      </Tree.Items>
    </Tree>
  )
}

type TeamTreeProps = {
  team: Team
  canMove: boolean
}

function TeamTree(props: TeamTreeProps) {
  const { team, canMove } = props
  const [openEditForm, onOpenEditForm, onCloseEditForm] = useDisclosureV2(false)
  const [openDeleteDialog, onOpenDeleteDialog, onCloseDeleteDialog] = useDisclosureV2(false)
  const moveMembers = useTeamMembersMove()
  const { selectedMembers } = useData()
  const { teamsHealth } = useInboxData()
  const { unselect } = useActions()
  const toast = useToast()

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: MemberDragType,
      drop: (item: TeamMember) => {
        const memberIds = selectedMembers.length === 0 ? [item.id] : selectedMembers.map((m) => m.id)
        moveMembers.mutate(
          {
            toTeamId: team.id,
            memberIds,
          },
          {
            onSuccess: () => {
              unselect()
            },
            onError: (err) => {
              toast.createToast({ message: (err as any)?.body.message || 'Failed to move member', error: true })
            },
          },
        )
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [selectedMembers],
  )

  const deleteTeam = useTeamDelete()
  const onDelete = () => {
    deleteTeam.mutate(team.id, {
      onSuccess: () => {
        onCloseDeleteDialog()
      },
      onError: (err) => {
        toast.createToast({ message: (err as any)?.body.message || 'Failed to delete a group', error: true })
      },
    })
  }

  return (
    <>
      <GroupFormDialog
        open={openEditForm}
        onClose={onCloseEditForm}
        mode="edit"
        teamId={team.id}
        updateData={{ name: team.name }}
      />
      <ConfirmationDialog
        open={openDeleteDialog}
        onClose={onCloseDeleteDialog}
        onConfirm={onDelete}
        message={`You are about to delete ${team.name} group?`}
      />
      <Tree.Item>
        <div
          className={clsx(
            'group inline-flex w-full items-center justify-between gap-3',
            isOver && canDrop && 'outline outline-2 outline-accent',
          )}
        >
          <div className="inline-flex w-full gap-3" ref={canMove ? drop : undefined}>
            <TreeToggleIcon />
            <Tree.Label className="inline-flex items-center gap-3">
              <div className="py-2">
                <p className="inline w-full text-dark">
                  <span className="mr-2">{team.name}</span>
                  <span className="text-sm text-dusk">{team.inboxCount} senders</span>
                </p>
              </div>
            </Tree.Label>
          </div>
          <div className="flex items-center gap-2">
            {!team.permissions.edit.deny && (
              <Button variant="basic" className="invisible group-hover:visible" onClick={onOpenEditForm}>
                Edit
              </Button>
            )}
            {!team.permissions.remove.deny && (
              <Button variant="basic" className="invisible group-hover:visible" onClick={onOpenDeleteDialog}>
                Remove
              </Button>
            )}
            {teamsHealth[team.id] ? (
              <CheckCircle className="mb-0.5 mr-[34px] size-4" />
            ) : (
              <WarningCircle className="mb-0.5 mr-[34px] size-4 text-alert" />
            )}
          </div>
        </div>
        <Tree.Items className="ml-8">
          {team.members.map((member) => (
            <MemberTree key={member.id} member={member} canMove={canMove} />
          ))}
        </Tree.Items>
      </Tree.Item>
    </>
  )
}

type MemberTreeProps = {
  member: TeamMember
  canMove: boolean
}

function MemberTree(props: MemberTreeProps) {
  const { member, canMove } = props
  const moveInboxes = useTeamInboxesMove()
  const { unselect, onSelectMember } = useActions()
  const { selectedInboxes, selectedMembers } = useData()
  const { membersHealth } = useInboxData()
  const isSelected = selectedMembers.some((m) => m.id === member.id)
  const toast = useToast()

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: MemberDragType,
      item: member,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [member],
  )

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: InboxDragType,
      drop: (item: MemberInbox) => {
        // if selected inboxes are empty it means that only one item was dragged
        const inboxIds = selectedInboxes.length === 0 ? [item.id] : selectedInboxes.map((i) => i.id)
        moveInboxes.mutate(
          {
            toMemberId: member.id,
            inboxIds,
          },
          {
            onSuccess: () => {
              unselect()
            },
            onError: (err) => {
              toast.createToast({ message: (err as any)?.body.message || 'Failed to move member', error: true })
            },
          },
        )
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [selectedInboxes],
  )
  const memberRef = useRef<HTMLSpanElement>(null)

  return (
    <Tree.Item className={clsx('w-full')}>
      <div
        className={clsx('flex w-full', isOver && canDrop && 'outline outline-2 outline-accent')}
        ref={canMove ? drop : undefined}
      >
        <TreeToggleIcon />
        <div
          ref={canMove ? drag : undefined}
          className={clsx('w-full', isDragging && 'opacity-40')}
          onClick={(e) => onSelectMember(e, member)}
        >
          <Tree.Label
            className={clsx(
              'inline-flex w-full items-center gap-3 rounded-md px-3 py-2',
              isSelected ? 'bg-accent text-white' : 'hover:bg-accent-light',
              canMove && 'group',
            )}
          >
            <span
              // NOTE: contentEditable is used here to allow text selection
              // react-dnd is not trigger onDragStart event on input, textarea, select and contentEditable elements
              // dangerouslySetInnerHTML is used to set the innerHTML of the span, because passing
              // children to the span causes errors in devtools
              // the same technique is used in inboxes
              ref={memberRef}
              contentEditable
              onInput={(e) => {
                e.preventDefault()
                if (memberRef.current) memberRef.current.innerText = member.name
              }}
              className={clsx('mr-2 caret-transparent outline-none', !isSelected && 'text-dark')}
              dangerouslySetInnerHTML={{ __html: member.name }}
            />
            <span className={clsx('text-sm', !isSelected && 'text-dusk')}>{member.inboxes.length} senders</span>
            <div className="ml-auto flex gap-0.5">
              {membersHealth[member.id] ? (
                <CheckCircle className="mb-0.5 size-4" />
              ) : (
                <WarningCircle className="mb-0.5 size-4 text-alert" />
              )}
              <TreeItemDragIcon />
            </div>
          </Tree.Label>
        </div>
      </div>

      <Tree.Items className={clsx(isDragging && 'opacity-40')}>
        {member.inboxes.map((inbox) => (
          <InboxTree key={inbox.id} inbox={inbox} canMove={canMove} />
        ))}
      </Tree.Items>
    </Tree.Item>
  )
}

type InboxTreeProps = {
  inbox: MemberInbox
  canMove: boolean
}

function InboxTree(props: InboxTreeProps) {
  const { inbox, canMove } = props
  const { selectedInboxes } = useData()
  const { onSelectInbox } = useActions()
  const { inboxes } = useInboxData()
  const isSelected = selectedInboxes.some((i) => i.id === inbox.id)

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: InboxDragType,
      item: inbox,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [inbox],
  )

  const inboxRef = useRef<HTMLSpanElement>(null)

  return (
    <Tree.Item className={clsx('mb-[1px] w-full', isDragging && 'opacity-40')}>
      <div ref={canMove ? drag : undefined} className="w-full" onClick={(e) => onSelectInbox(e, inbox)}>
        <Tree.Label
          className={clsx(
            'ml-8 flex w-[calc(100%-2rem)] items-center justify-between rounded-md py-2 pl-8 pr-3 text-dusk',
            isSelected ? 'bg-accent text-white' : 'hover:bg-accent-light',
            canMove && 'group',
          )}
        >
          <span
            // NOTE: see contentEditable info above to understand why it's used here
            ref={inboxRef}
            contentEditable
            onInput={(e) => {
              e.preventDefault()
              if (inboxRef.current) inboxRef.current.innerText = inbox.email
            }}
            className={clsx('mr-2 caret-transparent outline-none', !isSelected && 'text-dark')}
            dangerouslySetInnerHTML={{ __html: inbox.email }}
          />

          <div className="flex items-center gap-0.5">
            <Link to={`/emails/${inbox.id}`}>
              <Wrench className="invisible ml-auto h-5 w-5 font-medium text-gray-400 group-hover:visible" />
            </Link>
            <Tooltip>
              <Tooltip.Trigger>
                {formatPercent(inboxes[inbox.id]?.deliverability_score_last_7_days.delivered_pct)}
              </Tooltip.Trigger>
              <Tooltip.Panel>Deliverability score</Tooltip.Panel>
            </Tooltip>

            {inboxes[inbox.id]?.healthy ? (
              <CheckCircle className="mb-0.5 size-4" />
            ) : (
              <Tooltip>
                <Tooltip.Trigger>
                  <WarningCircle className="mb-0.5 size-4 text-alert" />
                </Tooltip.Trigger>
                <Tooltip.Panel>
                  {inboxes[inbox.id].email_app_status !== EmailAppStatus.ON ? (
                    <>Email is not connected</>
                  ) : inboxes[inbox.id].spf_status != SPFStatus.ON ? (
                    <>SPF is not properly configured</>
                  ) : inboxes[inbox.id].dkim_status !== DKIMStatus.ON ? (
                    <>DKIM is not properly configured</>
                  ) : inboxes[inbox.id].dmarc_status !== DMARCStatus.ON ? (
                    <>DMARC is not properly configured</>
                  ) : inboxes[inbox.id].custom_domain_status !== CustomDomainStatus.ON ? (
                    <>Custom domain is not properly configured</>
                  ) : (
                    <>There's a problem with email configuration</>
                  )}
                </Tooltip.Panel>
              </Tooltip>
            )}
            <TreeItemDragIcon />
          </div>
        </Tree.Label>
      </div>
    </Tree.Item>
  )
}

function TreeItemDragIcon() {
  return <DotsSixVertical className="invisible ml-auto h-5 w-5 font-medium text-gray-400 group-hover:visible" />
}

function TreeToggleIcon() {
  return (
    <Tree.Toggle className="cursor-default">
      {({ open }) =>
        open ? (
          <CaretDown className="h-5 w-5 font-medium text-medium" />
        ) : (
          <CaretRight className="h-5 w-5 font-medium text-medium" />
        )
      }
    </Tree.Toggle>
  )
}

function filterStructureByText(structure: TeamStructure, search: string): TeamStructure {
  if (search === '') return structure

  const searchLower = search.toLowerCase()

  const filterInboxes = (inboxes: MemberInboxes, bypassFilter = false) =>
    bypassFilter ? inboxes : inboxes.filter((inbox) => inbox.email.toLowerCase().includes(searchLower))

  const filterMembers = (members: TeamMembers, bypassInboxFilter = false) =>
    members
      .map((member) => ({
        ...member,
        inboxes: filterInboxes(member.inboxes, bypassInboxFilter || member.name.toLowerCase().includes(searchLower)),
      }))
      .filter((member) => member.name.toLowerCase().includes(searchLower) || member.inboxes.length > 0)

  const teams = structure.teams
    .map((team) => {
      const nameMatches = team.name.toLowerCase().includes(searchLower)
      return {
        ...team,
        members: filterMembers(team.members, nameMatches),
      }
    })
    .filter((team) => team.name.toLowerCase().includes(searchLower) || team.members.length > 0)

  const members = filterMembers(structure.members || [])

  return { ...structure, teams, members }
}
