import { ManualStepType } from '@/api/core'

export function doneURl(type: ManualStepType, id: string): string {
  switch (type) {
    case ManualStepType.EMAIL: {
      return `/inbox/emails/${id}/done`
    }
    case ManualStepType.EMAIL_REPLY: {
      return `/inbox/replies/${id}/done`
    }
    case ManualStepType.EMAIL_REPLY_ABANDONED: {
      return `/inbox/replies/${id}/done`
    }
    case ManualStepType.CALL: {
      return `/inbox/calls/${id}/done`
    }
    case ManualStepType.LINKEDIN_MESSAGE: {
      return `/inbox/linkedin-messages/${id}/done`
    }
    case ManualStepType.RECOMMENDATION: {
      return `/inbox/recommendations/${id}/done`
    }
  }
}
