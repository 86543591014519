import { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { GlobalBannerProvider } from '@/providers/Banners/GlobalBannerProvider'
import { FormProvider } from '@/providers/FormProvider'
import { UserProvider } from '@/providers/User/UserProvider'
import { AppRoutes } from '@/routes'
import { ApiError } from './api/core'
import { ErrorPage } from './pages/ErrorPage'
import { ToastProvider } from './providers/Toasts/ToastsProvider'
import { PylonSetup } from './providers/User/PylonSetup'
import '@/App.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-day-picker/dist/style.css'
import 'tippy.js/dist/tippy.css'

export function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      document.title = 'DEV | rift'
    }
  }, [])
  // NOTE: temporary redirect from old domain
  useEffect(() => {
    if (window.location.hostname === 'app.getrift.com' && !window.location.pathname.includes('unsubscribe')) {
      window.location.hostname = 'app.rift.com'
    }
  })
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1 * 60 * 1000,
        retry: (failureCount: number, error: unknown) => {
          const maxRetries = 2
          return (error as ApiError)?.status >= 500 && failureCount < maxRetries
        },
      },
    },
  })

  return (
    <>
      {process.env.NODE_ENV === 'development' && (
        <div className="fixed bottom-3 left-3 z-50 table size-16 rounded-full bg-teal-300">
          <span className="flex h-full items-center justify-center text-lg font-medium text-pink-500">Dev</span>
        </div>
      )}
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <BrowserRouter>
            <ErrorBoundary FallbackComponent={ErrorPage}>
              <FormProvider>
                <GlobalBannerProvider>
                  <ToastProvider>
                    <AppRoutes />
                  </ToastProvider>
                </GlobalBannerProvider>
              </FormProvider>
              <PylonSetup />
            </ErrorBoundary>
          </BrowserRouter>
        </UserProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  )
}
