import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useDebounce } from 'use-debounce'
import * as Yup from 'yup'
import { MagnifyingGlass } from '@phosphor-icons/react'
import { useInboxes } from '@/api'
import { Inbox } from '@/api/core'
import { useSenderEmailReplyto } from '@/api/emails'
import { useDebounceValue } from '@/hooks'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Input, Text } from '@/ui'

export function ReplyTo() {
  const [, debouncedSearch, setSearch] = useDebounceValue('')
  const { data, status } = useInboxes()

  if (status === 'error') {
    return <Text className="text-alert">Error while loading senders</Text>
  } else if (status === 'pending') {
    return <div className="skeleton h-60 w-full" />
  }

  return (
    <section className="space-y-4">
      <Text variant="title">Reply to</Text>
      <Input
        containerClassName="w-full"
        icon={MagnifyingGlass}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search..."
      />
      {data.data.inboxes
        .filter(
          (l) =>
            debouncedSearch === '' ||
            l.first_name.toLocaleLowerCase().includes(debouncedSearch.toLocaleLowerCase()) ||
            l.last_name.toLocaleLowerCase().includes(debouncedSearch.toLocaleLowerCase()) ||
            l.email.toLocaleLowerCase().includes(debouncedSearch.toLocaleLowerCase()),
        )
        .map((inbox) => (
          <div key={inbox.id} className={clsx('flex items-center py-[10px]')}>
            <div className="item-center mr-4 grid h-9 w-9 content-center rounded-full bg-gray-300 text-center">
              {inbox.email.toUpperCase().charAt(0) || 'X'}
            </div>
            <div>
              <div className="flex flex-row">
                <Text variant="text" className="pr-4 font-medium text-dark">
                  {inbox.first_name} {inbox.last_name}
                </Text>
              </div>
              <Text variant="text" className="w-64 truncate text-ellipsis">
                {inbox.email}
              </Text>
            </div>
            <ReplyToInput inbox={inbox} />
          </div>
        ))}
    </section>
  )
}

function ReplyToInput({ inbox }: { inbox: Inbox }) {
  const validationSchema = Yup.object().shape({
    email: Yup.string().optional().email('Email is invalid'),
  })

  const updateSenderEmailReplyto = useSenderEmailReplyto()
  const toast = useToast()

  const [replyTo, setReplyTo] = useState<string | undefined>(undefined)
  const [error, setError] = useState<string | undefined>(undefined)
  const [emailDebounced] = useDebounce(replyTo, 700)

  useEffect(() => {
    try {
      if (replyTo === undefined) {
        return
      }
      validationSchema.validateSync({ email: replyTo })
      setError(undefined)
      updateSenderEmailReplyto.mutate(
        { senderId: inbox.id, email: replyTo },
        {
          onSuccess: () => {
            toast.createToast({ message: 'Reply-to email updated for ' + inbox.email })
          },
          onError: (err) => setError((err as any)?.body?.message || err.message),
        },
      )
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setError(err.errors[0])
      }
    }
  }, [emailDebounced])

  return (
    <Input
      type="text"
      value={replyTo ?? inbox.smtpReplyToHeader}
      placeholder="Reply-to email"
      onChange={(e) => setReplyTo(e.target.value)}
      error={error}
    />
  )
}
