import clsx from 'clsx'

type SpinnerProps = {
  size?: 'sm' | 'md' | 'lg' | 'xl'
}

const sizes = {
  sm: 'h-3 w-3',
  md: 'h-4 w-4',
  lg: 'h-6 w-6',
  xl: 'h-8 w-8',
}

export function Spinner(props: SpinnerProps) {
  const { size = 'md' } = props
  return (
    <div
      className={clsx(
        'inline-block animate-spin rounded-full border-2 border-current border-t-transparent',
        sizes[size],
      )}
      role="status"
      aria-label="loading"
    >
      <span className="sr-only">Loading...</span>
    </div>
  )
}
