import { SequenceSendMode } from '@/api/core'

export const SequenceSendModeToText: Record<SequenceSendMode, string> = {
  [SequenceSendMode.AUTOMATIC_EMAIL]: 'Automated email',
  [SequenceSendMode.MANUAL_EMAIL]: 'Manual email',
  [SequenceSendMode.MANUAL_CALL]: 'Call',
  [SequenceSendMode.LINKEDIN_MESSAGE]: 'LinkedIn message',
}

export const TextToSequenceSendMode: Record<string, SequenceSendMode> = Object.fromEntries(
  Object.entries(SequenceSendMode).map(([key, value]) => [value, key as SequenceSendMode]),
)

export const SequenceSendModeLabels: { label: string; value: SequenceSendMode }[] = Object.entries(
  SequenceSendModeToText,
).map(([value, label]) => ({
  label: label,
  value: value as SequenceSendMode,
}))

export type SequenceSendModeLabel = (typeof SequenceSendModeLabels)[number]
