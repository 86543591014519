import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ApiError, DefaultService, PatchCompany } from '@/api/core'
import { errhandler } from '@/api/helpers'
import { queryKey as inboxQueryKey } from '@/api/inbox'

const queryKey = ['company']

export function useCompany(enabled?: boolean) {
  return useQuery({
    queryKey: queryKey,
    enabled: enabled,
    queryFn: () => DefaultService.getCompany(),
  })
}

export function useCompanyDelete() {
  return useMutation({
    mutationFn: () => DefaultService.deleteCompany(),
  })
}

export function useCompanyUpdate() {
  const queryClient = useQueryClient()

  return useMutation({
    onError: (error: ApiError) => errhandler(error, 'Campaign update failed.'),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey })
      queryClient.invalidateQueries({ queryKey: inboxQueryKey.list() })
    },
    mutationFn: (body: PatchCompany) => DefaultService.patchCompany(body),
  })
}
