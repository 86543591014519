import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useCampaign } from '@/api/campaigns'
import { useToast } from '@/providers/Toasts/ToastsProvider'

export function useLoadSequence() {
  const navigate = useNavigate()
  const toast = useToast()
  const { sequenceId } = useParams() as { sequenceId: string }
  const { data, status, error } = useCampaign(sequenceId)

  useEffect(() => {
    if (status === 'error') {
      if ((error as any)?.status === 404) {
        toast.createToast({ message: 'Sequence not found', error: true })
        navigate('/sequences')
      } else {
        toast.createToast({ message: (error as any)?.body?.message || 'failed to load sequence', error: true })
      }
    }
  }, [status, error])

  return { data: data?.data, status, error }
}
