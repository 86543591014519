import { NavLink } from 'react-router-dom'
import { Popover } from '@headlessui/react'
import { Question } from '@phosphor-icons/react'
import { OpacityTransition } from '@/ui'

export function HelpMenu() {
  return (
    <div>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button className="rounded-full border-[1px] p-2 hover:bg-gray-100" data-testid="help-menu">
              <Question className="h-5 w-5 text-medium" />
            </Popover.Button>
            <OpacityTransition show={open}>
              <Popover.Panel className="absolute right-2 mt-3.5  flex shrink flex-col rounded-[10px] bg-white p-1 shadow-lg ring-1 ring-gray-900/5">
                {({ close }) => (
                  <>
                    <NavLink
                      style={{ marginTop: '0' }}
                      key="get-help"
                      to="#"
                      onClick={() => {
                        window.Pylon('show')
                        close()
                      }}
                      className="m-1 block text-nowrap px-4 py-2  hover:rounded-lg hover:bg-gray-100"
                    >
                      Get help...
                    </NavLink>
                    <NavLink
                      style={{ marginTop: '0' }}
                      key="privacy-policy"
                      to="https://www.rift.com/privacy-policy"
                      target="_blank"
                      className="m-1 block text-nowrap px-4 py-2  hover:rounded-lg hover:bg-gray-100"
                    >
                      Privacy policy
                    </NavLink>
                  </>
                )}
              </Popover.Panel>
            </OpacityTransition>
          </>
        )}
      </Popover>
    </div>
  )
}
