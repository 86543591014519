import * as React from 'react'
import type { Placement } from '@floating-ui/react'
import {
  autoUpdate,
  flip,
  FloatingPortal,
  hide,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
} from '@floating-ui/react'

export type TooltipOptions = {
  initialOpen?: boolean
  placement?: Placement
  hidden?: boolean
}

export function useTooltip({ placement = 'top', hidden = false }: TooltipOptions = {}) {
  const [open, setOpen] = React.useState(false)

  const data = useFloating({
    placement,
    open,
    onOpenChange: (open) => {
      !hidden && setOpen(open)
    },
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: 'start',
        crossAxis: placement.includes('-'),
      }),
      shift({ padding: 5 }),
      hidden && hide(),
    ],
  })

  const context = data.context

  const hover = useHover(context, {
    move: false,
    enabled: true,
  })
  const focus = useFocus(context, {
    enabled: true,
  })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  const interactions = useInteractions([hover, focus, dismiss, role])

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data],
  )
}

type ContextType = ReturnType<typeof useTooltip> | null

const TooltipContext = React.createContext<ContextType>(null)

export function useTooltipState() {
  const context = React.useContext(TooltipContext)
  if (context === null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />')
  }
  return context
}

export function TooltipFn({ children, ...options }: { children: React.ReactNode } & TooltipOptions) {
  const tooltip = useTooltip(options)
  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>
}

export const Panel = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  function TooltipContent(props, propRef) {
    const state = useTooltipState()
    const ref = useMergeRefs([state.floating, propRef])

    return (
      <FloatingPortal>
        {state.open && (
          <div
            ref={ref}
            style={{
              position: state.strategy,
              top: state.y ?? 0,
              left: state.x ?? 0,
              visibility: state.x === null ? 'hidden' : 'visible',
              ...props.style,
            }}
            {...state.getFloatingProps(props)}
          />
        )}
      </FloatingPortal>
    )
  },
)

export const Trigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & { asChild?: boolean; as?: React.ElementType }
>(function TooltipTrigger({ children, asChild = false, as = 'div', ...props }, propRef) {
  const state = useTooltipState()
  const childrenRef = (children as any).ref
  const ref = useMergeRefs([state.reference, propRef, childrenRef])

  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      state.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': state.open ? 'open' : 'closed',
      }),
    )
  }

  const As = as

  return (
    <As ref={ref} data-state={state.open ? 'open' : 'closed'} {...state.getReferenceProps(props)}>
      {children}
    </As>
  )
})

export const Tooltip = Object.assign(TooltipFn, {
  Trigger: Trigger,
  Panel: Panel,
})
