import { Link } from 'react-router-dom'
import { ChameleonSearch } from '@/containers/Chameleon'
import { Card, Text } from '@/ui'
import { CompanySettings } from './CompanySettings'
import { FeatureFlagSettings } from './FeatureFlagSettings'
import { MemberFeatures } from './MemberFeatures'

export function Admin() {
  return (
    <>
      <div className="mx-auto flex max-w-md flex-col gap-4 bg-white">
        <Text variant="title" className="pb-5">
          rift super admin
        </Text>
        <ChameleonSearch />

        <Card className="flex flex-col gap-5">
          <Text variant="subtitle">Feature flags</Text>
          <FeatureFlagSettings />
          <MemberFeatures />
        </Card>

        <Card className="flex flex-col gap-5">
          <Text variant="subtitle">Company Email Stats</Text>
          <Link to="/email_stats" className="text-dusk">
            Here
          </Link>
        </Card>

        <CompanySettings />
      </div>
    </>
  )
}
