import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { DefaultService, PatchSignature } from '@/api/core'

const queryKey = ['signatures']

export function useGetSignature() {
  return useQuery({
    queryKey,
    queryFn: DefaultService.getSignatures,
  })
}

export function useUpdateSignature() {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey }),
    mutationFn: (data: PatchSignature & { id: string }) => {
      const { id, ...body } = data

      return DefaultService.patchSignatures(id, body)
    },
  })
}
