import React from 'react'

export function useOutsideClick(cb: (event: MouseEvent | PointerEvent | FocusEvent | TouchEvent) => void) {
  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const handler = (e: MouseEvent | PointerEvent | FocusEvent | TouchEvent) => {
      const element = ref.current
      if (element && !element.contains(e.target as Node)) {
        cb(e)
      }
    }

    document.addEventListener('mousedown', handler)
    document.addEventListener('touchstart', handler)

    return () => {
      document.removeEventListener('mousedown', handler)
      document.removeEventListener('touchstart', handler)
    }
  }, [])

  return ref
}
