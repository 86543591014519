/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CampaignSortField {
    NAME = 'name',
    _NAME = '-name',
    START_DATE = 'start_date',
    _START_DATE = '-start_date',
}
