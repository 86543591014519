import { Link } from 'react-router-dom'

export function AgreementFooter() {
  return (
    <footer className="font-sm mx-auto w-full pb-4 text-center text-xs text-dusk">
      By continuing, you agree to our{' '}
      <Link to="https://www.rift.com/terms-of-service" className="font-medium text-dark" target="_blank">
        Terms of Service
      </Link>{' '}
      and
      <br />
      acknowledge our{' '}
      <Link to="https://www.rift.com/privacy-policy" className="font-medium text-dark" target="_blank">
        Privacy Policy
      </Link>
      .
    </footer>
  )
}
