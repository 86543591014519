import tippy, { GetReferenceClientRect, Instance } from 'tippy.js'
import { ReactRenderer } from '@tiptap/react'
import { SuggestionKeyDownProps, SuggestionOptions, SuggestionProps } from '@tiptap/suggestion'
import { ContextMenu } from './ContextMenu'

export function getSuggestions(list: string[]): Omit<SuggestionOptions, 'editor'> {
  return {
    items: ({ query }: { query: string }) => {
      return list.filter((item) => item.toLowerCase().startsWith(query.toLowerCase()))
    },

    render: () => {
      let reactRenderer: ReactRenderer<any> | undefined
      let popup: Instance[]

      return {
        onStart: (props: SuggestionProps) => {
          reactRenderer = new ReactRenderer(ContextMenu, {
            props,
            editor: props.editor,
          })

          if (!props.clientRect) {
            return
          }

          popup = tippy('body', {
            theme: 'rift',
            getReferenceClientRect: props.clientRect as GetReferenceClientRect,
            content: reactRenderer.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
            arrow: false,
          })
          return
        },

        onUpdate(props: SuggestionProps) {
          if (reactRenderer) reactRenderer.updateProps(props)

          if (!props.clientRect) {
            return
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect as GetReferenceClientRect,
          })
        },

        onKeyDown(props: SuggestionKeyDownProps) {
          if (props.event.key === 'Escape') {
            popup[0].hide()
            return true
          }

          if (!reactRenderer || !reactRenderer.ref) {
            return false
          }

          return reactRenderer.ref.onKeyDown(props)
        },

        onExit() {
          popup[0].destroy()
          reactRenderer?.destroy()
        },
      }
    },
  }
}
