import { useParams } from 'react-router-dom'
import { CheckCircle } from '@phosphor-icons/react'
import { useManualStepsCallDone } from '@/api/manual_step'
import { Text } from '@/ui'
import { ActivityHistory } from '../ActivityHistory'
import { ContactInformation } from '../ContactInformation'
import { SingleStep } from '../SingleStep'
import { SingleStepSkeleton } from '../Skeletons'

export function DoneCall() {
  const { callId } = useParams() as { callId: string }
  const { data, status } = useManualStepsCallDone(callId)

  if (status === 'pending' || status === 'error') {
    return <SingleStepSkeleton />
  }

  const { prev, next, contact } = data.data

  return (
    <SingleStep>
      <SingleStep.Navigation prev={prev} next={next} url="/inbox/done/" />
      <SingleStep.Main>
        <SingleStep.Card className="p-6">
          <div className="flex items-center justify-between">
            <Text className="flex items-center gap-2 text-medium">
              <CheckCircle className="h-5 w-5 fill-success text-white" weight="fill" />
              Call Completed
            </Text>
          </div>
        </SingleStep.Card>

        <ActivityHistory contactId={contact.id} />
      </SingleStep.Main>
      <ContactInformation contactId={contact.id} />
    </SingleStep>
  )
}
