import { ElementType, Fragment, Ref, useLayoutEffect, useMemo, useReducer } from 'react'
import { Props } from '@headlessui/react/dist/types'
import { useEvent } from '@/hooks/useEvent'
import { useLatestValue } from '@/hooks/useLatestValue'
import { useSyncRefs } from '@/hooks/useSyncRefs'
import { sortByDomNode } from '@/lib/utils'
import { uirender } from '@/ui/render'
import { StepsActionsContext, StepsDataContext } from './Context'
import { ActionType, reducer } from './reducer'

export const DEFAULT_STEPS_TAG = Fragment
export interface StepGroupRenderPropArg {
  currentIndex: number
}

export type StepGroupProps<TTag extends ElementType> = Props<
  TTag,
  StepGroupRenderPropArg,
  never,
  {
    defaultIndex?: number
    onChange?: (index: number) => void
    currentIndex?: number
    // renderCurrentStep is a boolean that indicates whether
    // the current step and only that one should be rendered
    // if this is set to false, all steps are rendered
    renderCurrentStep?: boolean
  }
>

export function Group<TTag extends ElementType = typeof DEFAULT_STEPS_TAG>(
  props: StepGroupProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { defaultIndex = 0, onChange, currentIndex = null, renderCurrentStep = false, ...theirProps } = props
  const isControlled = currentIndex !== null
  const stepsRef = useSyncRefs(ref)

  const [state, dispatch] = useReducer(reducer, {
    currentIndex: currentIndex ?? defaultIndex,
    renderCurrentStep,
    steps: [],
    panels: [],
  })

  const slot = useMemo(() => ({ currentIndex: state.currentIndex }), [state.currentIndex])
  const onChangeRef = useLatestValue(onChange || (() => {}))
  const stepsData = useMemo(() => state, [state])
  const realCurrentIndex = useLatestValue(isControlled ? currentIndex : state.currentIndex)

  const registerStep = useEvent((step) => {
    dispatch({ type: ActionType.RegisterStep, step })
    return () => dispatch({ type: ActionType.UnregisterStep, step })
  })

  const registerPanel = useEvent((panel) => {
    dispatch({ type: ActionType.RegisterPanel, panel })
    return () => dispatch({ type: ActionType.UnregisterPanel, panel })
  })

  const change = useEvent((index: number) => {
    if (realCurrentIndex.current !== index) {
      onChangeRef.current(index)
    }

    if (!isControlled) {
      dispatch({ type: ActionType.SetSelectedIndex, index })
    }
  })

  const stepsActions = useMemo(() => ({ registerStep, registerPanel, change }), [])

  useLayoutEffect(() => {
    dispatch({ type: ActionType.SetSelectedIndex, index: currentIndex ?? defaultIndex })
  }, [currentIndex /* Deliberately skipping defaultIndex */])

  useLayoutEffect(() => {
    if (realCurrentIndex.current === undefined) return
    if (state.steps.length <= 0) return

    const sorted = sortByDomNode(state.steps, (step) => step.current)
    const didOrderChange = sorted.some((step, i) => state.steps[i] !== step)

    if (didOrderChange) {
      change(sorted.indexOf(state.steps[realCurrentIndex.current]))
    }
  })

  const ourProps = {
    ref: stepsRef,
  }

  return (
    <div>
      <StepsActionsContext.Provider value={stepsActions}>
        <StepsDataContext.Provider value={stepsData}>
          {uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_STEPS_TAG })}
        </StepsDataContext.Provider>
      </StepsActionsContext.Provider>
    </div>
  )
}
