import { useCallback, useEffect, useState } from 'react'

// Options for the temporary value hook
type Options = {
  // Timeout duration in milliseconds
  // default: 5000
  timeout: number
}

/**
 * Custom hook to manage a temporary value that replaces the initial value for a specified duration.
 * @param initialValue The initial value.
 * @param temporaryValue The temporary value that replaces the initial value temporarily.
 * @param options Options for the temporary value hook, including timeout duration.
 * @returns A stateful value and trigger to replace it.
 */
export function useTemporaryValue<T>(initialValue: T, temporaryValue: T, options?: Options): [T, () => void] {
  const { timeout = 1000 } = options || {}
  const [value, setValue] = useState<T>(initialValue)
  const [timeoutId, setTimeoutId] = useState<number | null>(null)
  // this is a dep for useEffect to make sure it is called when
  // trigger is called. every time trigger is called,
  // a new timeout is set, so the old one should be cleared.
  const [forceEffect, setForceEffect] = useState<boolean>(false)

  useEffect(() => {
    if (value === initialValue) return

    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    const id = setTimeout(() => setValue(initialValue), timeout)
    setValue(temporaryValue)
    return () => {
      clearTimeout(id)
      setTimeoutId(null)
    }
  }, [value, initialValue, temporaryValue, timeout, forceEffect])

  const trigger = useCallback(() => {
    setValue(temporaryValue)
    setForceEffect((prev) => !prev)
  }, [])
  return [value, trigger]
}
