import { ConferencingPlatform } from '@/api/core'

export const ConferencingPlatformToText: Record<ConferencingPlatform, string> = {
  [ConferencingPlatform.ZOOM]: 'Zoom',
  [ConferencingPlatform.GOOGLE_MEET]: 'Google Meet',
}

export const ConferencingPlatformLabels: { label: string; value: ConferencingPlatform }[] = Object.entries(
  ConferencingPlatformToText,
).map(([value, label]) => ({ label, value: value as ConferencingPlatform }))

export type ConferencingPlatformLabel = (typeof ConferencingPlatformLabels)[number]
