import { useState } from 'react'
import { DateRange } from 'react-day-picker'
import { useCampaignsMetrics } from '@/api/campaigns'
import { dateOnlyRFC3999Format } from '@/lib/date'
import { Statistics, Text } from '@/ui'
import { RichDateRangePicker } from '@/ui/RichDateRangePicker'

export function ContactsMetrics() {
  const [range, setRange] = useState<DateRange | undefined>({
    // by default we want to start with 14 days ago
    // note: this is connected with the defaultMenuOptionIndex in RichDateRangePicker
    // if you change one, you need to change the other.
    // This is a poor api and I need to think about a better one
    from: new Date(new Date().setDate(new Date().getDate() - 14)),
    to: new Date(),
  })
  const { data, status } = useCampaignsMetrics(
    range && range.from ? dateOnlyRFC3999Format(range.from) : undefined,
    range && range.to ? dateOnlyRFC3999Format(range.to) : undefined,
  )

  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        <Text variant="subtitle">Stats</Text>
        <RichDateRangePicker
          dataTestId="date-range-picker"
          onRangeChange={setRange}
          options={[
            { label: 'All time', allTime: true },
            { label: 'Last day', days: -1 },
            { label: 'Last 7 days', days: -7 },
            { label: 'Last 14 days', days: -14 },
            { label: 'Last 30 days', days: -30 },
          ]}
          optionIndex={3}
        />
      </div>
      {status === 'pending' && <div className="skeleton h-16 w-full" />}
      {status === 'success' && (
        <div className="mt-4 flex justify-between">
          <Statistics title="Contacts reached" number={data.data.contacts.contacted} />
          <Statistics title="Open rate" pct={data.data.contacts.open_rate} />
          <Statistics title="Reply rate" pct={data.data.contacts.reply_rate} />
          <Statistics title="Meeting rate" pct={data.data.contacts.meeting_booked_rate} />
          <Statistics title="Unsubscribe rate" pct={data.data.contacts.unsubscribe_rate} />
          <Statistics title="Bounce rate" pct={data.data.contacts.bounce_rate} />
        </div>
      )}
    </div>
  )
}
