import { Member } from '@/api/core'
import { FloatingAppBar } from '@/ui/FloatingAppBar'
import { ContactsOwnerPopover } from './ContactsOwnerPopover'

type BaseFloatingAppBarProps = {
  open: boolean
  close: () => void
  posXRef?: React.RefObject<HTMLDivElement>
  onExportToCSV: () => void
  selectedContactIds: string[]
}

type ContactsBottomMenuProps = BaseFloatingAppBarProps & {
  onUnsubscribe: () => void
  onAddToList: () => void
  onDelete: () => void
  onAbandoned: () => void
  members: Member[]
}

export function ContactsFloatingAppBar(props: ContactsBottomMenuProps) {
  const { open, selectedContactIds, onUnsubscribe, onAddToList, onAbandoned, onExportToCSV, onDelete, members, close } =
    props
  return (
    <FloatingAppBar open={open}>
      <FloatingAppBar.Close onClose={close} />
      <span className="whitespace-nowrap">{selectedContactIds.length} selected</span>
      <FloatingAppBar.Action onClick={onUnsubscribe}>Unsubscribe</FloatingAppBar.Action>
      <FloatingAppBar.Action onClick={onAddToList}>Add to list</FloatingAppBar.Action>
      {selectedContactIds.length > 1 && <ContactsOwnerPopover members={members} contactIds={selectedContactIds} />}
      <FloatingAppBar.Action onClick={onAbandoned}>Unenroll</FloatingAppBar.Action>
      <FloatingAppBar.Action onClick={onExportToCSV}>Export</FloatingAppBar.Action>
      <FloatingAppBar.Action onClick={onDelete}>Delete</FloatingAppBar.Action>
    </FloatingAppBar>
  )
}

type ContactsListFloatingAppBarProps = BaseFloatingAppBarProps & {
  onUnsubscribe: () => void
  onRemoveFromList: (() => void) | undefined
  onAbandoned: () => void
  onDelete: () => void
  members: Member[]
}

export function ContactsListFloatingAppBar(props: ContactsListFloatingAppBarProps) {
  const {
    open,
    selectedContactIds,
    onUnsubscribe,
    onRemoveFromList,
    onExportToCSV,
    onAbandoned,
    onDelete,
    members,
    close,
  } = props
  return (
    <FloatingAppBar open={open}>
      <FloatingAppBar.Close onClose={close} />
      <span className="whitespace-nowrap">{selectedContactIds.length} selected</span>
      <FloatingAppBar.Action onClick={onUnsubscribe}>Unsubscribe</FloatingAppBar.Action>
      {onRemoveFromList && <FloatingAppBar.Action onClick={onRemoveFromList}>Remove from list</FloatingAppBar.Action>}
      <FloatingAppBar.Action onClick={onRemoveFromList}>Remove from list</FloatingAppBar.Action>
      {selectedContactIds.length > 1 && <ContactsOwnerPopover members={members} contactIds={selectedContactIds} />}
      <FloatingAppBar.Action onClick={onAbandoned}>Unenroll</FloatingAppBar.Action>
      <FloatingAppBar.Action onClick={onExportToCSV}>Export</FloatingAppBar.Action>
      <FloatingAppBar.Action onClick={onDelete}>Delete</FloatingAppBar.Action>
    </FloatingAppBar>
  )
}

type CampaignListFloatingAppBarProps = BaseFloatingAppBarProps & {
  onUnsubscribe: () => void
  onAbandoned: () => void
  onDelete: () => void
}

export function CampaignListFloatingAppBar(props: CampaignListFloatingAppBarProps) {
  const { open, selectedContactIds, onUnsubscribe, onAbandoned, onExportToCSV, onDelete, close } = props
  return (
    <FloatingAppBar open={open}>
      <FloatingAppBar.Close onClose={close} />
      <span className="whitespace-nowrap">{selectedContactIds.length} selected</span>
      <FloatingAppBar.Action onClick={onUnsubscribe}>Unsubscribe</FloatingAppBar.Action>
      <FloatingAppBar.Action onClick={onAbandoned}>Unenroll</FloatingAppBar.Action>
      <FloatingAppBar.Action onClick={onExportToCSV}>Export</FloatingAppBar.Action>
      <FloatingAppBar.Action onClick={onDelete}>Delete</FloatingAppBar.Action>
    </FloatingAppBar>
  )
}
