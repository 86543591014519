import { useState } from 'react'
import { Campaign } from '@/api/core'
import { ErrorText } from '@/ui/Text/Error'
import { SendTimesSelect } from './selectors/SendTimesSelect'
import { TimezoneSelect } from './selectors/TimezoneSelect'
import { WeekdaysSelect } from './selectors/WeekdaysSelect'

export function Schedule({ campaign }: { campaign: Campaign }) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  return (
    <section className="py-3">
      <label htmlFor="date-selection" className="text-sm font-medium text-dusk">
        Schedule
      </label>

      <span id="date-selection" className=" text-sm text-medium">
        <div className="flex flex-wrap items-center gap-1">
          <WeekdaysSelect campaign={campaign} /> between{' '}
          <SendTimesSelect campaign={campaign} setErrorMessage={(message: string | null) => setErrorMessage(message)} />
        </div>
        <div>
          <TimezoneSelect campaign={campaign} />
        </div>
        {errorMessage && <ErrorText errorMessage={errorMessage} exclamationIconWidth={0} />}
      </span>
    </section>
  )
}
