/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ContactStage {
    NEW = 'NEW',
    ACTIVE = 'ACTIVE',
    INVALID = 'INVALID',
    INACTIVE = 'INACTIVE',
    INTERESTED = 'INTERESTED',
    CONVERTED = 'CONVERTED',
    UNSUBSCRIBED = 'UNSUBSCRIBED',
    OOO = 'OOO',
    NOT_INTERESTED = 'NOT_INTERESTED',
}
