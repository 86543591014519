import { Warning } from '@phosphor-icons/react/dist/ssr'
import { Button } from '@/ui'
import { DuplicativeData } from '../reducer'

export type DuplicativeDataReviewProps = {
  onContinue: () => void
  onCancel: () => void
  duplicativeData: DuplicativeData
}

function Mono({ text }: { text: string }) {
  return <span className=" bg-light font-mono text-xs">{text}</span>
}

export function DuplicativeDataReview({ onContinue, onCancel, duplicativeData }: DuplicativeDataReviewProps) {
  return (
    <div className="mb-6 flex grow flex-col overflow-auto rounded-lg border border-solid border-light bg-white p-4">
      <Warning className="mb-4 ml-2 mt-2 h-6 w-6 text-amber" />
      <p className="mx-2 mb-2 text-md font-medium text-dark">Issues found</p>
      {duplicativeData.map(({ numContacts, csvValue, csvHeader }) => (
        <p className="mx-2 text-sm leading-[20px] text-dusk" key={numContacts + csvValue + csvHeader}>
          {numContacts} contact{numContacts > 1 ? 's have' : ' has'} <Mono text={csvValue} /> for the column{' '}
          <Mono text={csvHeader} />
        </p>
      ))}
      <p className="mx-2 mt-2 text-sm leading-[22px] text-dusk">
        We recommend fixing this in the spreadsheet and re-uploading.
      </p>
      <div className="mt-6 flex gap-x-2">
        <Button className="flex-1" onClick={onCancel}>
          Cancel
        </Button>
        <Button className="flex-1" onClick={onContinue}>
          Continue anyway
        </Button>
      </div>
    </div>
  )
}
