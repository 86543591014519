import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { queryKey as campaignQueryKey } from '@/api/campaigns'
import { queryKey as contactListQueryKey } from '@/api/contact_lists'
import {
  ApiError,
  ContactsV2,
  ContactV2,
  DefaultService,
  Events,
  PatchContact,
  PatchContactsOwners,
  PatchContactsOwnersSplit,
  PatchContactsUnenroll,
  PatchContactsUnsubscribe,
  PostContacts,
  PostContactsDelete,
  PostContactsExport,
  PostContactsUploadCampaignId,
  PostContactsUploadContactList,
  PostContactsUploadContactListId,
  PostContactUploadCampaignId,
  PostFieldCleanings,
  SequenceContacts,
} from '@/api/core'

export const queryKey = {
  base: () => ['contacts'] as const,
  list: () => ['contacts', 'list'] as const,
  get: (id: string) => ['contacts', id] as const,
  activities: (id: string) => ['contacts', id, 'activities'] as const,
  campaigns: () => ['contacts', 'campaigns'] as const,
  campaign: (campaignId: string) => ['contacts', 'campaigns', campaignId] as const,
  recentlyAdded: (campaignId: string) => ['contacts', 'campaigns', campaignId, 'recentlyAdded'] as const,
}

export function useContacts() {
  return useQuery<{ data: ContactsV2 }, ApiError>({
    queryKey: queryKey.list(),
    queryFn: () => DefaultService.getContacts(),
  })
}

export function useContactsCampaign(campaignId: string) {
  return useQuery<{ data: SequenceContacts }, ApiError>({
    queryKey: queryKey.campaign(campaignId),
    queryFn: () => DefaultService.getContactsCampaigns(campaignId),
  })
}

export function useContactsCampaignRecentlyAdded(campaignId: string) {
  return useQuery({
    queryKey: queryKey.recentlyAdded(campaignId),
    queryFn: () => DefaultService.getContactsCampaignsRecentlyAdded(campaignId),
  })
}

export function useContact(contactId: string) {
  return useQuery<{ data: ContactV2 }, ApiError>({
    queryKey: queryKey.get(contactId),
    queryFn: () => DefaultService.getContacts1(contactId),
  })
}

export function useContactActivities(contactId: string) {
  return useQuery<{ data: Events }, ApiError>({
    queryKey: queryKey.activities(contactId),
    queryFn: () => DefaultService.getEventsContacts(contactId),
  })
}

export function useContactsCreate() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
      queryClient.invalidateQueries({ queryKey: contactListQueryKey.base() })
    },
    mutationFn: (body: PostContacts) => DefaultService.postContacts(body),
  })
}

export function useContactsUploadContactList() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
      queryClient.invalidateQueries({ queryKey: contactListQueryKey.base() })
    },
    mutationFn: (body: PostContactsUploadContactList) => DefaultService.postContactsUploadContactList(body),
  })
}

export function useContactsUploadContactListId() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
      queryClient.invalidateQueries({ queryKey: contactListQueryKey.base() })
      queryClient.invalidateQueries({ queryKey: contactListQueryKey.get(variables.contactListId) })
    },
    mutationFn: (data: PostContactsUploadContactListId & { contactListId: string }) => {
      const { contactListId, ...body } = data
      return DefaultService.postContactsUploadContactListId(contactListId, body)
    },
  })
}

export function useContactsUploadCampaignId() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
      queryClient.invalidateQueries({ queryKey: queryKey.campaign(variables.campaignId) })
      queryClient.invalidateQueries({ queryKey: contactListQueryKey.base() })
      queryClient.invalidateQueries({ queryKey: contactListQueryKey.base() })
      queryClient.invalidateQueries({ queryKey: campaignQueryKey.get(variables.campaignId) })
    },
    mutationFn: (data: PostContactsUploadCampaignId & { campaignId: string }) => {
      const { campaignId, ...body } = data
      return DefaultService.postContactsUploadCampaigns(campaignId, body)
    },
  })
}

export function useContactFieldCleaner() {
  return useMutation({
    mutationFn: (data: PostFieldCleanings) => {
      const { ...body } = data
      return DefaultService.postContactsFieldCleanings(body)
    },
  })
}

export function useSingleContactUploadCampaignId() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
      queryClient.invalidateQueries({ queryKey: queryKey.campaign(variables.campaignId) })
      queryClient.invalidateQueries({ queryKey: contactListQueryKey.base() })
      queryClient.invalidateQueries({ queryKey: contactListQueryKey.base() })
      queryClient.invalidateQueries({ queryKey: campaignQueryKey.get(variables.campaignId) })
    },
    mutationFn: (data: PostContactUploadCampaignId & { campaignId: string }) => {
      const { campaignId, ...body } = data
      return DefaultService.postContactsUploadCampaignsSingle(campaignId, body)
    },
  })
}

export function useContactUpdate() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
      queryClient.invalidateQueries({ queryKey: queryKey.campaigns() })
      queryClient.invalidateQueries({ queryKey: queryKey.get(variables.contactId) })
      queryClient.invalidateQueries({ queryKey: contactListQueryKey.base() })
    },
    mutationFn: (data: PatchContact & { contactId: string }) => {
      const { contactId, ...body } = data
      return DefaultService.patchContacts(contactId, body)
    },
  })
}

export function useContactDelete() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
      queryClient.invalidateQueries({ queryKey: contactListQueryKey.base() })
    },
    mutationFn: (contactId: string) => DefaultService.deleteContacts(contactId),
  })
}

export function useContactsDelete() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
      queryClient.invalidateQueries({ queryKey: queryKey.campaigns() })
      queryClient.invalidateQueries({ queryKey: contactListQueryKey.base() })
      queryClient.invalidateQueries({ queryKey: campaignQueryKey.base() })
    },
    mutationFn: (body: PostContactsDelete) => DefaultService.postContactsDelete(body),
  })
}

export function useContactsExport() {
  return useMutation({
    mutationFn: (body: PostContactsExport) => DefaultService.postContactsExport(body),
  })
}

export function useContactsUnsubscribe() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
      queryClient.invalidateQueries({ queryKey: queryKey.campaigns() })
      queryClient.invalidateQueries({ queryKey: contactListQueryKey.base() })
      queryClient.invalidateQueries({ queryKey: campaignQueryKey.base() })
    },
    mutationFn: (body: PatchContactsUnsubscribe) => DefaultService.patchContactsUnsubscribe(body),
  })
}

export function useContactsUnenroll() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.base() })
      queryClient.invalidateQueries({ queryKey: contactListQueryKey.base() })
      queryClient.invalidateQueries({ queryKey: campaignQueryKey.base() })
    },
    mutationFn: (body: PatchContactsUnenroll) => DefaultService.patchContactsUnenroll(body),
  })
}

export function useContactsOwnerChange() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.base() })
      queryClient.invalidateQueries({ queryKey: contactListQueryKey.base() })
      queryClient.invalidateQueries({ queryKey: campaignQueryKey.base() })
    },
    mutationFn: (body: PatchContactsOwners) => DefaultService.patchContactsOwners(body),
  })
}

export function useContactsOwnerSplit() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.base() })
      queryClient.invalidateQueries({ queryKey: contactListQueryKey.base() })
      queryClient.invalidateQueries({ queryKey: campaignQueryKey.base() })
    },
    mutationFn: (body: PatchContactsOwnersSplit) => DefaultService.patchContactsOwnersSplit(body),
  })
}
