/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CampaignStatus {
    CREATED = 'CREATED',
    READY_TO_LAUNCH = 'READY_TO_LAUNCH',
    STOPPED = 'STOPPED',
    LAUNCHED = 'LAUNCHED',
    FINISHED = 'FINISHED',
}
