import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DotsThree } from '@phosphor-icons/react'
import { useCampaignArchive, useCampaignClone, useCampaignDelete, useCampaignUnarchive } from '@/api/campaigns'
import { CampaignList, CampaignStatus } from '@/api/core'
import { ConfirmationDialog } from '@/pages/Contacts/ConfirmationDialog'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { DropdownMenu, MouseTrap } from '@/ui'
import { ShareDialog } from '../View/ShareDialog'

type ActionMenuProps = {
  campaign: CampaignList
  onDelete?: () => void
  onArchive?: () => void
}

export function ActionMenu(props: ActionMenuProps) {
  const { campaign, onDelete, onArchive } = props
  const navigate = useNavigate()
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const archiveCampaign = useCampaignArchive()
  const unarchiveCampaign = useCampaignUnarchive()
  const deleteCampaign = useCampaignDelete()
  const cloneCampaign = useCampaignClone()
  const toast = useToast()

  const onEditCampaign = () => {
    navigate(`/sequences/${campaign.id}`)
  }

  const onArchiveCampaign = () => {
    onArchive?.()
    archiveCampaign.mutate(campaign.id, {
      onSuccess: () => {
        toast.createToast({ message: 'Sequence archived' })
      },
      onError: (err: unknown) => {
        toast.createToast({ message: (err as any)?.body?.message || 'failed to archive sequence', error: true })
      },
    })
  }

  const onUnarchiveCampaign = () => {
    unarchiveCampaign.mutate(campaign.id, {
      onSuccess: () => toast.createToast({ message: 'Sequence unarchived' }),
      onError: (err: unknown) => {
        toast.createToast({ message: (err as any)?.body?.message || 'failed to unarchive sequence', error: true })
      },
    })
  }

  const onDeleteCampaign = () => {
    onDelete?.()
    deleteCampaign.mutate(campaign.id, {
      onSuccess: () => {
        toast.createToast({ message: 'Sequence deleted' })
      },
      onError: (err: unknown) => {
        toast.createToast({ message: (err as any)?.body?.message || 'Failed to delete sequence', error: true })
      },
    })
  }

  const onCloneCampaign = () => {
    cloneCampaign.mutate(campaign.id, {
      onSuccess: ({ data }) => {
        toast.createToast({ message: 'Sequence cloned' })
        navigate(`${data.id}`)
      },
      onError: (err: unknown) => {
        toast.createToast({ message: (err as any)?.body?.message || 'Failed to clone sequence', error: true })
      },
    })
  }
  const [removeCampaign, setRemoveCampaign] = useState(false)

  return (
    <>
      <ConfirmationDialog
        open={removeCampaign}
        onClose={() => setRemoveCampaign(false)}
        onConfirm={() => onDeleteCampaign()}
        message={`you are about to remove campaign ${campaign.name}`}
      />
      <ShareDialog campaignId={campaign.id} open={shareDialogOpen} onClose={() => setShareDialogOpen(false)} />
      <MouseTrap>
        <div className="flex justify-end">
          <DropdownMenu>
            <DropdownMenu.Button>
              <DotsThree className="size-4.5" />
            </DropdownMenu.Button>
            <DropdownMenu.Items className="-left-1/2">
              {!campaign.permissions.share.deny && (
                <DropdownMenu.Item onClick={() => setShareDialogOpen(true)}>Share</DropdownMenu.Item>
              )}
              <DropdownMenu.Item onClick={onEditCampaign}>Edit</DropdownMenu.Item>
              <DropdownMenu.Item onClick={onCloneCampaign}>Clone</DropdownMenu.Item>
              {campaign.archived_at ? (
                <>
                  <DropdownMenu.Item onClick={onUnarchiveCampaign}>Unarchive</DropdownMenu.Item>
                  <DropdownMenu.Item onClick={() => setRemoveCampaign(true)}>Remove</DropdownMenu.Item>
                </>
              ) : (
                campaign.status !== CampaignStatus.LAUNCHED && (
                  <DropdownMenu.Item onClick={onArchiveCampaign}>Archive</DropdownMenu.Item>
                )
              )}
            </DropdownMenu.Items>
          </DropdownMenu>
        </div>
      </MouseTrap>
    </>
  )
}
