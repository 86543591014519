import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LinkedinLogo } from '@phosphor-icons/react'
import { ManualStepLinkedinMessageInfo } from '@/api/core'
import {
  useManualLinkedinMessage,
  useManualLinkedinMessageCopy,
  useManualLinkedinMessageMarkAsDone,
  useManualLinkedinMessageUpdate,
} from '@/api/manual_step'
import { useDebounceValue, useTemporaryValue } from '@/hooks'
import { useCopyToClipboard } from '@/hooks'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, TextEditor, Toolbar } from '@/ui'
import { ActivityHistory } from '../../ActivityHistory'
import { ContactInformation } from '../../ContactInformation'
import { SingleStep } from '../../SingleStep'
import { SingleStepSkeleton } from '../../Skeletons'

export function SingleLinkedinMessage() {
  const { eventId } = useParams() as { eventId: string }
  const { data, status } = useManualLinkedinMessage(eventId)

  if (status === 'pending' || status === 'error') {
    return <SingleStepSkeleton />
  }

  return (
    <section key={eventId}>
      <Editor eventId={eventId} data={data.data} />
    </section>
  )
}

type EditorProps = {
  eventId: string
  data: ManualStepLinkedinMessageInfo
}

function Editor({ eventId, data }: EditorProps) {
  const navigate = useNavigate()
  const toast = useToast()
  const { copy } = useCopyToClipboard()
  const markAsDone = useManualLinkedinMessageMarkAsDone(data.contact.id)
  const { refetch: copyRefetch } = useManualLinkedinMessageCopy(eventId)
  const [, debouncedMessage, setMessage] = useDebounceValue(data.message, { delay: 500 })
  const saveEmail = useManualLinkedinMessageUpdate(eventId)
  const [copyBtnText, triggerCopyBtnText] = useTemporaryValue('Copy message', 'Copied')
  const onLinkedinOpen = () => window.open(data.contact.linkedin, '_blank')
  const onCopyMessage = () => {
    copyRefetch().then((data) => {
      if (data.status === 'success') {
        copy(data.data.data.message)
        triggerCopyBtnText()
      }
    })
  }

  useEffect(() => {
    if (debouncedMessage === data.message) return
    saveEmail.mutate({ message: debouncedMessage })
  }, [debouncedMessage, data.message])

  const onMarkAsDone = () => {
    markAsDone.mutate(eventId, {
      onSuccess: () => {
        toast.createToast({ message: `Sequence started for ${data.contact.firstName} ${data.contact.lastName}` })
        if (data.nextId) {
          navigate(`/inbox/linkedin-messages/${data.nextId}`)
        } else if (data.prevId) {
          navigate(`/inbox/linkedin-messages/${data.prevId}`)
        } else {
          navigate('/inbox/linkedin-messages')
        }
      },
      onError: (error) => {
        toast.createToast({ message: (error as any)?.body?.message || 'Failed to mark as done', error: true })
      },
    })
  }

  return (
    <SingleStep>
      <SingleStep.Navigation prev={data.prevId} next={data.nextId} url="/inbox/linkedin-messages/" />

      <SingleStep.Main>
        <SingleStep.Card>
          <TextEditor>
            <TextEditor.Editor autoFocus content={data.message} onChange={setMessage} className="grow " />
            <Toolbar>
              <Toolbar.Signature />
              <Toolbar.CalendarLinks />
              <Toolbar.Variable />
              <Toolbar.Emoji />
              <Toolbar.Separator />
              <Toolbar.Divider />
              <Toolbar.Palette />
              <Toolbar.Bold />
              <Toolbar.Underline />
              <Toolbar.Italic />
              <Toolbar.Strikethrough />
              <Toolbar.Code />
              <Toolbar.Divider />
              <Toolbar.AlignLeft />
              <Toolbar.AlignCenter />
              <Toolbar.AlignRight />
              <Toolbar.AlignJustify />
              <Toolbar.Divider />
              <Toolbar.ListOrdered />
              <Toolbar.ListUnordered />
              <Toolbar.CodeBlock />
              <Toolbar.Divider />
              <Toolbar.Link />
              <Toolbar.Unlink />
              <Toolbar.Image />
              <Toolbar.Divider />
              <Toolbar.FormatClear />
            </Toolbar>
          </TextEditor>
          <div className="mt-4 flex gap-2">
            <Button variant="accent" onClick={onMarkAsDone}>
              Mark as done
            </Button>
            <Button onClick={onLinkedinOpen} aria-label="open-linkedin">
              <LinkedinLogo className="mr-2 size-4 text-medium" />
              Open {data.contact.firstName}'s linkedin
            </Button>
            <Button onClick={onCopyMessage}>{copyBtnText}</Button>
          </div>
        </SingleStep.Card>
        <ActivityHistory contactId={data.contact.id} />
      </SingleStep.Main>
      <ContactInformation contactId={data.contact.id} />
    </SingleStep>
  )
}
