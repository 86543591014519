import { useState } from 'react'
import clsx from 'clsx'
import { WorkspaceInvitedMember, WorkspaceMemberRole } from '@/api/core'
import { WorkspaceMemberRoleLabels } from '@/api/text/workspace'
import {
  useWorkspaceInvitationDelete,
  useWorkspaceInvitationReminder,
  useWorkspaceInvitationUpdate,
} from '@/api/workspace'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { useUserContext } from '@/providers/User/context'
import { Button, Dialog, Select, Text } from '@/ui'

type InviteCardProps = {
  member: WorkspaceInvitedMember
  canEdit: boolean
}

export function InviteCard({ member, canEdit }: InviteCardProps) {
  const toast = useToast()
  const invitationUpdate = useWorkspaceInvitationUpdate()
  const invitationRemind = useWorkspaceInvitationReminder()
  const { user: self } = useUserContext()
  const [inviteToRemove, setInviteToRemove] = useState<WorkspaceInvitedMember | null>(null)

  const onRoleChange = (invitationId: string, newRole: WorkspaceMemberRole) => {
    invitationUpdate.mutate(
      {
        invitationId: invitationId,
        role: newRole,
      },
      {
        onError: (err: unknown) => {
          toast.createToast({ message: (err as any)?.body?.message || 'Failed to change user role', error: true })
        },
        onSuccess: () => {
          toast.createToast({ message: 'User role changed' })
        },
      },
    )
  }
  const onReminder = (memberId: string) => {
    invitationRemind.mutate(memberId, {
      onError: (err: unknown) => {
        toast.createToast({ message: (err as any)?.body?.message || 'Failed to send reminder', error: true })
      },
      onSuccess: () => {
        toast.createToast({ message: 'Reminder sent' })
      },
    })
  }

  return (
    <>
      {inviteToRemove && <RemoveDialog member={inviteToRemove} onFinish={() => setInviteToRemove(null)} />}
      <div className={clsx('flex items-center py-[10px]', canEdit && 'group')}>
        <div className="item-center mr-4 grid h-9 w-9 content-center rounded-full bg-gray-300 text-center">
          <div className="text-md font-medium  text-white">{member.email.toUpperCase().charAt(0) || 'X'}</div>
        </div>

        <div>
          <Text variant="text" className="pr-4 font-medium text-medium">
            Invited
          </Text>
          <Text variant="text" className="w-32 truncate text-ellipsis text-medium">
            {member.email}
          </Text>
        </div>

        <div className="ml-auto flex items-center space-x-2">
          <Button
            variant="basic"
            className="invisible p-4.5 group-hover:visible"
            onClick={() => onReminder(member.invite_id)}
            disabled={invitationRemind.isPending}
          >
            Reminder
          </Button>
          {self.value?.traits.email !== member.email && (
            <Button className="invisible p-4.5 group-hover:visible" onClick={() => setInviteToRemove(member)}>
              Remove
            </Button>
          )}

          {canEdit ? (
            <Select
              options={WorkspaceMemberRoleLabels}
              defaultValue={
                WorkspaceMemberRoleLabels.find((v) => v.value === member.role) || WorkspaceMemberRoleLabels[0]
              }
              value={WorkspaceMemberRoleLabels.find((v) => v.value === member.role) || WorkspaceMemberRoleLabels[0]}
              onChange={(v) => onRoleChange(member.invite_id, v.value)}
            />
          ) : (
            <Text variant="text">
              {(WorkspaceMemberRoleLabels.find((v) => v.value === member.role) || WorkspaceMemberRoleLabels[0]).label}
            </Text>
          )}
        </div>
      </div>
    </>
  )
}

type RemoveDialogProps = {
  member: WorkspaceInvitedMember
  onFinish: () => void
}

function RemoveDialog(props: RemoveDialogProps) {
  const { member, onFinish } = props
  const toast = useToast()
  const invitationDelete = useWorkspaceInvitationDelete()
  const [open, setOpen] = useState(true)

  const onCancel = () => {
    onFinish()
    setOpen(false)
  }

  const onDelete = () => {
    invitationDelete.mutate(member.invite_id, {
      onError: (err: unknown) => {
        const message = (err as any)?.body?.message || 'Failed to remove invitation'
        toast.createToast({ message, error: true })
      },
      onSuccess: () => toast.createToast({ message: 'Invitation removed' }),
      onSettled: () => {
        onFinish()
        setOpen(false)
      },
    })
  }

  return (
    <Dialog open={open} onClose={onCancel}>
      <Dialog.Panel className="max-w-80 space-y-4">
        <Text variant="subtitle">Remove {member.email} from this organization?</Text>
        <div className="flex justify-between gap-2">
          <Button className="w-full" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="accent" className="w-full" onClick={onDelete}>
            Remove
          </Button>
        </div>
      </Dialog.Panel>
    </Dialog>
  )
}
