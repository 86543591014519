import { useCallback, useEffect, useState } from 'react'

type options = {
  min?: number
  max?: number
}
type CounterOutput = [
  number,
  {
    inc: () => void
    dec: () => void
    reset: () => void
    setCount: (value: number) => void
  },
]

export function useCounter(initialValue = 0, options: options = {}): CounterOutput {
  const { min = -Infinity, max = Infinity } = options
  const [count, setCount] = useState(initialValue)
  useEffect(() => setCount(initialValue), [initialValue])

  const inc = useCallback(() => setCount((c) => (c + 1 > max ? c : c + 1)), [])
  const dec = useCallback(() => setCount((c) => (c - 1 < min ? c : c - 1)), [])
  const reset = useCallback(() => setCount(initialValue), [])

  return [
    count,
    {
      inc,
      dec,
      reset,
      setCount,
    },
  ]
}
