import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ManualStepDoneReply } from '@/api/core/models/ManualStepDoneReply'
import { useManualStepsDoneReply } from '@/api/manual_step'
import { Text } from '@/ui'
import { ContactInformation } from '../ContactInformation'
import { SingleStep } from '../SingleStep'
import { SingleStepSkeleton } from '../Skeletons'
import { ReplyThread, ReplyThreadFocused } from './Threads'
import { getDisplayName } from './utilities'

export function DoneReply() {
  const { replyId } = useParams() as { replyId: string }
  const { data, status } = useManualStepsDoneReply(replyId)

  if (status === 'pending' || status === 'error') {
    return <SingleStepSkeleton />
  }

  return (
    <section key={replyId}>
      <DoneReplyHandler data={data.data} />
    </section>
  )
}

function DoneReplyHandler({ data: { emails, prev, next, contact } }: { data: ManualStepDoneReply }) {
  const [focusedId, setFocusedId] = useState<number | null>(emails.length - 1 || null)
  const subject = emails[0].subject

  return (
    <SingleStep>
      <SingleStep.Navigation prev={prev} next={next} url="/inbox/done/" />
      <SingleStep.Main>
        <Text variant="title" className="pb-6">
          {subject}
        </Text>
        <div className="flex flex-col gap-4">
          {emails.map((message, index) =>
            index === focusedId ? (
              <ReplyThreadFocused
                reply={message}
                key={index}
                setFocused={() => setFocusedId(null)}
                getDisplayName={(email) => getDisplayName(email, contact)}
              />
            ) : (
              <ReplyThread
                reply={message}
                key={index}
                setFocused={() => setFocusedId(index)}
                getDisplayName={(email) => getDisplayName(email, contact)}
              />
            ),
          )}
        </div>
      </SingleStep.Main>
      {contact ? <ContactInformation contactId={contact?.id} /> : <div />}
    </SingleStep>
  )
}
