import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Listbox, RadioGroup } from '@headlessui/react'
import { useCampaignUpdate } from '@/api/campaigns'
import { Campaign, Weekday, Weekdays } from '@/api/core'
import { equal } from '@/lib/arrays'

export function WeekdaysSelect({ campaign }: { campaign: Campaign }) {
  const WeekdaysDisplay = [
    { id: 'SUNDAY', display: 'Su' },
    { id: 'MONDAY', display: 'Mo' },
    { id: 'TUESDAY', display: 'Tu' },
    { id: 'WEDNESDAY', display: 'We' },
    { id: 'THURSDAY', display: 'Th' },
    { id: 'FRIDAY', display: 'Fr' },
    { id: 'SATURDAY', display: 'Sa' },
  ]

  const Ranges: { selection: Weekdays; display: string; key: number }[] = [
    {
      selection: [Weekday.MONDAY, Weekday.TUESDAY, Weekday.WEDNESDAY, Weekday.THURSDAY, Weekday.FRIDAY],
      display: 'Mon - Fri',
      key: 0,
    },
    {
      selection: [Weekday.TUESDAY, Weekday.WEDNESDAY, Weekday.THURSDAY],
      display: 'Tue - Thu',
      key: 1,
    },
  ]
  const updateCampaign = useCampaignUpdate(campaign.id)
  const [radioSelection, setRadioSelection] = useState<number | null>(0)
  const [labelText, setLabelText] = useState<string>('')
  const [customDays, setCustomDays] = useState<Weekday[]>([])

  useEffect(() => {
    const weekdays = Ranges[0].selection.sort()
    const tueToThur = Ranges[1].selection.sort()
    const campaignDays = campaign.weekdays.sort()

    if (equal(weekdays, campaignDays)) {
      setRadioSelection(Ranges[0].key)
    } else if (equal(campaignDays, tueToThur)) {
      setRadioSelection(Ranges[1].key)
    } else {
      setRadioSelection(2)
      setCustomDays(campaign.weekdays)
    }
  }, [])

  useEffect(() => {
    if (radioSelection === 0 || radioSelection === 1) {
      setLabelText(Ranges[radioSelection].display)
    }
  }, [radioSelection])

  useEffect(() => {
    if (radioSelection === 2) {
      setLabelText(
        customDays.map((day) => WeekdaysDisplay.find((weekday) => weekday.id === day)?.display).join(', ') ||
          'Select days',
      )
    }
  }, [customDays])

  function presetSelection(selected: number) {
    //todo save to db
    if (selected === 1 || selected === 0) {
      updateCampaign.mutate({ weekdays: Ranges[selected].selection })
    }
    setRadioSelection(selected)
    setCustomDays([])
  }

  function customSelection(selected: Weekday[]) {
    setCustomDays(selected)
    if (selected.length) {
      updateCampaign.mutate({ weekdays: selected })
    }
  }

  const disabled = campaign.permissions.change_schedule.deny

  return (
    <Listbox value={customDays} multiple onChange={customSelection} disabled={disabled}>
      <Listbox.Button
        className={clsx(
          'label relative cursor-pointer text-sm',
          disabled ? 'pointer-events-none' : 'font-medium text-accent',
        )}
        data-testid="weekday-selector"
      >
        <span className="block truncate">{labelText}</span>
      </Listbox.Button>
      <Listbox.Options
        className="z-10 max-h-60 rounded-lg bg-white p-5 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        anchor="bottom"
      >
        <RadioGroup className="flex justify-between" value={radioSelection} onChange={presetSelection}>
          {Ranges.map((range, i) => (
            <RadioGroup.Option
              className="m-1 select-none whitespace-nowrap rounded-lg border bg-extra-light px-5 py-2 hover:border-accent ui-checked:border-accent"
              key={i}
              value={i}
            >
              <span>{range.display}</span>
            </RadioGroup.Option>
          ))}
          <RadioGroup.Option
            className="m-1 select-none whitespace-nowrap rounded-lg border bg-extra-light px-5 py-2 hover:border-accent ui-checked:border-accent"
            value={Ranges.length}
          >
            <span>Custom</span>
          </RadioGroup.Option>
        </RadioGroup>
        {radioSelection === Ranges.length && (
          <div className="mt-2.5 grid grid-cols-7 items-center justify-center text-center">
            {WeekdaysDisplay.map((day) => (
              <Listbox.Option
                className="m-1 select-none rounded-lg border bg-extra-light px-1 py-1 text-center hover:border-accent ui-selected:border-accent"
                key={day.id}
                value={day.id}
              >
                {day.display}
              </Listbox.Option>
            ))}
          </div>
        )}
      </Listbox.Options>
    </Listbox>
  )
}
