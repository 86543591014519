import { forwardRef, InputHTMLAttributes, Ref } from 'react'
import clsx from 'clsx'

function InputRadioFn(props: InputHTMLAttributes<HTMLInputElement>, ref: Ref<HTMLInputElement>) {
  const { className, ...rest } = props
  return (
    <input
      ref={ref}
      type="radio"
      className={clsx(
        // base
        'background-origin-border flex size-4.5 flex-shrink-0 cursor-default select-none appearance-none rounded-full border border-light',

        // transition
        'transition-all duration-100 ease-in',

        // checked
        'checked:border-[5px] checked:border-accent',

        // active
        'active:duration-200 active:ease-out',

        // disabled
        'disabled:bg-extra-light disabled:checked:border-accent disabled:checked:opacity-30',

        className,
      )}
      {...rest}
    />
  )
}
export const InputRadio = forwardRef(InputRadioFn)
