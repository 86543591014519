import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSalesforceConnection, useSalesforceConnectionOptions, useSalesforceConnectionUpdate } from '@/api'
import { DoubleMappings, SalesforceConnection, SalesforceConnectionOptions } from '@/api/core'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button } from '@/ui'
import { SalesforceFieldMappingForm } from './FieldMappingForm'
import { MappingSkeleton } from './Skeleton'

export function SalesforceFieldMapping() {
  const connection = useSalesforceConnection()
  const options = useSalesforceConnectionOptions()
  if (connection.status === 'pending' || options.status === 'pending' || !connection.data || !options.data) {
    return <MappingSkeleton />
  }
  return <MappingWithData salesforceConnection={connection.data.data} salesforceOptions={options.data.data} />
}

type SalesforceFieldMappingProps = {
  salesforceConnection: SalesforceConnection
  salesforceOptions: SalesforceConnectionOptions
}
function MappingWithData({ salesforceConnection, salesforceOptions }: SalesforceFieldMappingProps) {
  const navigate = useNavigate()
  const toast = useToast()
  const salesforceUpdate = useSalesforceConnectionUpdate()
  const [mapping, setMapping] = useState<DoubleMappings>(salesforceConnection.fieldMappings)
  const crmToRiftEnabled = salesforceConnection.stageMappings.crmToRift.isEnabled
  const riftToCrmEnabled = salesforceConnection.stageMappings.riftToCrm.isEnabled
  const direction = crmToRiftEnabled && riftToCrmEnabled ? 'both' : crmToRiftEnabled ? 'crm_to_rift' : 'rift_to_crm'

  const onMappingSave = () => {
    salesforceUpdate.mutate(
      {
        fieldMappings: mapping,
      },
      {
        onSuccess: () => {
          toast.createToast({ message: 'Field mapping saved' })
          navigate('/settings/integrations/salesforce')
        },
      },
    )
  }

  const onCancel = () => {
    navigate('/settings/integrations/salesforce')
  }

  return (
    <>
      <SalesforceFieldMappingForm
        direction={direction}
        options={salesforceOptions}
        mapping={mapping}
        setMapping={setMapping}
      />
      <div className="flex flex-row justify-end gap-4 pb-16 pt-8">
        <Button onClick={onCancel} variant="text">
          Cancel
        </Button>
        <Button onClick={onMappingSave} variant="accent">
          Save
        </Button>
      </div>
    </>
  )
}
