import { MutableRefObject } from 'react'
import { sortByDomNode } from '@/lib/utils'

export type State = {
  currentIndex: number
  renderCurrentStep: boolean

  steps: MutableRefObject<HTMLElement | null>[]
  panels: MutableRefObject<HTMLElement | null>[]
}

export enum ActionType {
  SetSelectedIndex,

  RegisterStep,
  UnregisterStep,

  RegisterPanel,
  UnregisterPanel,
}

export type Action =
  | { type: ActionType.SetSelectedIndex; index: number }
  | { type: ActionType.RegisterStep; step: MutableRefObject<HTMLElement | null> }
  | { type: ActionType.UnregisterStep; step: MutableRefObject<HTMLElement | null> }
  | { type: ActionType.RegisterPanel; panel: MutableRefObject<HTMLElement | null> }
  | { type: ActionType.UnregisterPanel; panel: MutableRefObject<HTMLElement | null> }

const reducers: {
  [P in ActionType]: (state: State, action: Extract<Action, { type: P }>) => State
} = {
  [ActionType.SetSelectedIndex](state, action) {
    const steps = sortByDomNode(state.steps, (step) => step.current)
    const panels = sortByDomNode(state.panels, (panel) => panel.current)
    const currentIndex =
      action.index < 0 ? 0 : action.index > state.steps.length - 1 ? state.steps.length - 1 : action.index
    return { ...state, steps, panels, currentIndex }
  },
  [ActionType.RegisterStep](state, action) {
    if (state.steps.includes(action.step)) return state
    const activeStep = state.steps[state.currentIndex]

    const adjustedSteps = sortByDomNode([...state.steps, action.step], (step) => step.current)
    let currentIndex = adjustedSteps.indexOf(activeStep) ?? state.currentIndex
    if (currentIndex === -1) currentIndex = state.currentIndex

    return { ...state, steps: adjustedSteps, currentIndex: currentIndex }
  },
  [ActionType.UnregisterStep](state, action) {
    return { ...state, steps: state.steps.filter((step) => step !== action.step) }
  },
  [ActionType.RegisterPanel](state, action) {
    if (state.panels.includes(action.panel)) return state
    return {
      ...state,
      panels: sortByDomNode([...state.panels, action.panel], (panel) => panel.current),
    }
  },
  [ActionType.UnregisterPanel](state, action) {
    return { ...state, panels: state.panels.filter((panel) => panel !== action.panel) }
  },
}

export function reducer(state: State, action: Action) {
  return reducers[action.type](state, action as any)
}
