import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import { PutOnboardingBasicInfo } from '@/api/core'
import { useOnboardingPutBasicInfo } from '@/api/onboarding'
import { ButtonLoading, Input } from '@/ui'
import { Step } from '@/ui/headless'

type BasicInfoProps = {
  nextStep: () => void
}

export function BasicInfo(props: BasicInfoProps) {
  const { nextStep } = props
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PutOnboardingBasicInfo>()
  const putBasicInfo = useOnboardingPutBasicInfo()

  const onSubmit = (data: PutOnboardingBasicInfo) => {
    putBasicInfo.mutate(data, {
      onSuccess: () => {
        nextStep()
      },
    })
  }

  return (
    <form>
      <div className="w-[30rem]">
        <h1 className="mb-6 text-lg font-medium">Basic info about you</h1>
        <div className="w-fill flex flex-col gap-4">
          <Input
            registration={register('companyName', { required: 'Required' })}
            error={errors.companyName}
            label="Company name"
          />
          <Input
            registration={register('firstName', { required: 'Required' })}
            error={errors.firstName}
            label="First name"
          />
          <Input
            registration={register('lastName', { required: 'Required' })}
            error={errors.lastName}
            label="Last name"
          />
          <Input
            registration={register('phoneNumber', { required: 'Required' })}
            error={errors.phoneNumber}
            label="Phone number"
          />
        </div>

        <span className={clsx('text-alert', !putBasicInfo.isError && 'invisible')}>
          Failed to save basic info {(putBasicInfo.error as any)?.body?.message}
        </span>

        <div className="mt-8 flex justify-end">
          <Step.Next
            as={ButtonLoading}
            type="button"
            variant="accent"
            isLoading={putBasicInfo.isPending}
            onClick={(e) => {
              e.preventDefault()
              handleSubmit(onSubmit)()
            }}
          >
            Next: Plan any payment
          </Step.Next>
        </div>
      </div>
    </form>
  )
}
