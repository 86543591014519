import { SalesforceConnectionOptions, SingularMappings } from '@/api/core'
import { mappingSetterToSingularMappings, mappingsFromSingularMappings, SingularMappingForm } from './SingularMapping'

type SalesforceUserMappingFormProps = {
  options: SalesforceConnectionOptions
  mapping: SingularMappings
  setMapping: (mapping: SingularMappings) => void
}

export function SalesforceUserMappingForm({ options, mapping, setMapping }: SalesforceUserMappingFormProps) {
  const crmToRiftDirection = false
  const defaults = mappingsFromSingularMappings(options.defaultUserMappings, crmToRiftDirection)
  const mappings = mappingsFromSingularMappings(mapping, crmToRiftDirection)
  const leftFields = crmToRiftDirection ? options.crmUsers : options.riftUsers
  const rightFields = crmToRiftDirection ? options.riftUsers : options.crmUsers
  const onSetMapping = mappingSetterToSingularMappings(setMapping, crmToRiftDirection)
  return (
    <SingularMappingForm
      title={'Map users from rift to Salesforce'}
      subtitle="Select how you’d like to sync data between rift and Salesforce"
      leftColumnTitle={'Rift'}
      rightColumnTitle={'Salesforce'}
      leftFields={leftFields}
      rightFields={rightFields}
      defaultFieldMapping={defaults}
      mapping={mappings}
      multipleRightOptions
      setMapping={onSetMapping}
    />
  )
}
