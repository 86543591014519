import { createContext, PropsWithChildren, useContext, useReducer } from 'react'
import { ToastProps } from '@/ui/Toast/Toast'
import { reducer } from './reducer'
import { ToastWrapper } from './ToastWrapper'

type ToastContextType = {
  createToast: (toast: ToastProps) => void
}

export const ToastContext = createContext<ToastContextType | null>(null)

export function ToastProvider({ children }: PropsWithChildren) {
  const [{ toasts }, dispatchToasts] = useReducer(reducer, { toasts: [] })

  const createToast = (toast: ToastProps) => {
    dispatchToasts({ type: 'add', body: toast })
  }

  const removeToast = (id: string) => {
    dispatchToasts({ type: 'remove', body: { id: id } })
  }

  return (
    <ToastContext.Provider value={{ createToast }}>
      <>
        {children}
        {toasts.length > 0 && (
          <div className="fixed bottom-0 z-50 flex w-full flex-col items-center gap-2 py-6">
            {toasts.map(({ id, ...rest }) => (
              <ToastWrapper key={id} dismiss={() => removeToast(id)} {...rest} />
            ))}
          </div>
        )}
      </>
    </ToastContext.Provider>
  )
}

export function useToast() {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error('Child components of Toasts cannot be rendered outside the Toast provider!')
  }

  return context
}
