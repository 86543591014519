import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSalesforceConnection, useSalesforceConnectionOptions, useSalesforceConnectionUpdate } from '@/api'
import {
  IntegrationFilters,
  SalesforceConnection,
  SalesforceConnectionOptions,
  SalesforceStageMapping as SalesforceStageMappingT,
  SingularMappings,
} from '@/api/core'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button } from '@/ui'
import { Step } from '@/ui/headless'
import { MappingSkeleton } from './Skeleton'
import { StageFilteringForm } from './StageFilteringForm'
import { SalesforceStageMappingForm } from './StageMappingForm'
import { SalesforceSyncObjectForm } from './SyncObjectForm'

export function SalesforceStageMapping() {
  const connection = useSalesforceConnection()
  const options = useSalesforceConnectionOptions()
  if (connection.status === 'pending' || options.status === 'pending' || !connection.data || !options.data) {
    return <MappingSkeleton />
  }
  return <MappingWithData salesforceConnection={connection.data.data} salesforceOptions={options.data.data} />
}

type SalesforceStageMappingProps = {
  salesforceConnection: SalesforceConnection
  salesforceOptions: SalesforceConnectionOptions
}
function MappingWithData({ salesforceConnection, salesforceOptions }: SalesforceStageMappingProps) {
  const navigate = useNavigate()
  const toast = useToast()
  const salesforceUpdate = useSalesforceConnectionUpdate()
  const [mapping, setMapping] = useState<SalesforceStageMappingT>({
    object: salesforceConnection.stageMappings.object,
    fieldId: salesforceConnection.stageMappings.fieldId,
    riftToCrm: salesforceConnection.stageMappings.riftToCrm.stageMappings,
    crmToRift: salesforceConnection.stageMappings.crmToRift.stageMappings,
  })
  const [riftToCrmFilters, setRiftToCrmFilters] = useState<IntegrationFilters>(
    salesforceConnection.stageMappings.riftToCrm.filters,
  )
  const [crmToRiftFilters, setCrmToRiftFilters] = useState<IntegrationFilters>(
    salesforceConnection.stageMappings.crmToRift.filters,
  )
  const crmToRiftEnabled = salesforceConnection.stageMappings.crmToRift.isEnabled
  const riftToCrmEnabled = salesforceConnection.stageMappings.riftToCrm.isEnabled
  const crmStages = salesforceOptions.crmStages.find(
    (stage) => stage.object === mapping.object && stage.fieldId === mapping.fieldId,
  )
  const onRiftToCrmStageMappingUpdate = (newMapping: SingularMappings) =>
    setMapping({
      ...mapping,
      riftToCrm: newMapping,
    })
  const onCrmToRiftStageMappingUpdate = (newMapping: SingularMappings) =>
    setMapping({
      ...mapping,
      crmToRift: newMapping,
    })

  const onMappingSave = () => {
    salesforceUpdate.mutate(
      { stageMappings: mapping, crmToRiftFilters: crmToRiftFilters, riftToCrmFilters: riftToCrmFilters },
      {
        onSuccess: () => {
          toast.createToast({ message: 'Stage mapping saved' })
          navigate('/settings/integrations/salesforce')
        },
      },
    )
  }
  const onCancel = () => {
    navigate('/settings/integrations/salesforce')
  }
  const bottomNavClassName = 'flex flex-row justify-end gap-4 pb-16 pt-8'

  const stageMappingPickerPanel = () => (
    <Step.Panel>
      <SalesforceSyncObjectForm
        syncObjectsOptions={salesforceOptions.crmStages}
        object={mapping.object}
        fieldId={mapping.fieldId}
        setSyncObject={(object, fieldId) => setMapping({ ...mapping, object, fieldId, riftToCrm: [], crmToRift: [] })}
      />
      <div className={bottomNavClassName}>
        <Button onClick={onCancel} variant="text">
          Cancel
        </Button>
        <Step.Next as={Button} variant="accent">
          Next
        </Step.Next>
      </div>
    </Step.Panel>
  )

  const riftToCrmPanel = () => (
    <Step.Panel>
      <SalesforceStageMappingForm
        crmToRiftDirection={false}
        riftStages={salesforceOptions.riftStages}
        crmStages={crmStages?.values || []}
        defaultStageMappings={salesforceOptions.defaultStageMappings || []}
        mapping={mapping.riftToCrm || []}
        setMapping={onRiftToCrmStageMappingUpdate}
        disabled={!riftToCrmEnabled}
      />
      <div className={bottomNavClassName}>
        <Step.Back as={Button} variant="text">
          Back
        </Step.Back>
        <Step.Next as={Button} variant="accent">
          Next
        </Step.Next>
      </div>
    </Step.Panel>
  )

  const crmToRiftPanel = () => (
    <Step.Panel>
      <SalesforceStageMappingForm
        crmToRiftDirection={true}
        riftStages={salesforceOptions.riftStages}
        crmStages={crmStages?.values || []}
        defaultStageMappings={salesforceOptions.defaultStageMappings || []}
        mapping={mapping.crmToRift || []}
        setMapping={onCrmToRiftStageMappingUpdate}
        disabled={!crmToRiftEnabled}
      />

      <div className={bottomNavClassName}>
        <Step.Back as={Button} variant="text">
          Back
        </Step.Back>
        <Step.Next as={Button} variant="accent">
          Next
        </Step.Next>
      </div>
    </Step.Panel>
  )

  const filteringPanel = () => (
    <Step.Panel>
      <StageFilteringForm
        riftStages={salesforceOptions.riftStages}
        crmStages={crmStages?.values || []}
        riftFilters={riftToCrmFilters || []}
        crmFilters={crmToRiftFilters || []}
        setRiftFilters={setRiftToCrmFilters}
        setCrmFilters={setCrmToRiftFilters}
      />
      <div className={bottomNavClassName}>
        <Step.Back as={Button} variant="text">
          Back
        </Step.Back>
        <Button onClick={onMappingSave} variant="accent">
          Save
        </Button>
      </div>
    </Step.Panel>
  )

  return (
    <Step.Group as="div" className="row flex" defaultIndex={0}>
      <Step.List>
        <Step></Step>
        <Step></Step>
        <Step></Step>
        <Step></Step>
      </Step.List>
      <Step.Panels>
        {stageMappingPickerPanel()}
        {riftToCrmPanel()}
        {crmToRiftPanel()}
        {filteringPanel()}
      </Step.Panels>
    </Step.Group>
  )
}
