import { useEffect } from 'react'
import { UserType, useUserContext } from './context'

export function PylonSetup() {
  const { user } = useUserContext()

  useEffect(() => {
    if (user.type === UserType.Authenticated) {
      window.pylon = {
        chat_settings: {
          app_id: '76da3505-c5dd-4ef7-ae37-40b4b9397bb6',
          email: user.value.traits.email,
          name:
            user.value.traits.first_name && user.value.traits.last_name
              ? `${user.value.traits.first_name} ${user.value.traits.last_name}`
              : user.value.traits.email,
        },
      }
      window.Pylon('hide')
    }
  }, [user])

  return <></>
}

declare global {
  interface Window {
    pylon: any
    Pylon: any
  }
}
