import { useState } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import {
  Event,
  EventContact,
  EventEmailReply,
  EventSequence,
  EventTypeCallAnswer,
  EventTypeCallReceive,
  EventTypeContactAdd,
  EventTypeContactStageChange,
  EventTypeEmailAutorespond,
  EventTypeEmailBounce,
  EventTypeEmailOpen,
  EventTypeEmailReply,
  EventTypeEmailReplySend,
  EventTypeEmailSend,
  EventTypeEmailUnsubscribe,
  EventTypeLinkedinSend,
  EventTypeMeetingBook,
} from '@/api/core'
import { ContactStageToText } from '@/api/text'
import { timeAgo } from '@/lib/date'

type ContactActivityFeedProps =
  | {
      activities: Array<Event>
      context: 'contact'
    }
  | {
      activities: Array<Event>
      context: 'sequence'
      setContactId: (s: string) => void
    }

export function ActivityFeed(props: ContactActivityFeedProps) {
  const { activities, context } = props

  function Entity({ text }: { text: string }) {
    return <span className="text-dark">{text}</span>
  }

  function Contact({ contact }: { contact: EventContact }) {
    if (context === 'contact') {
      return <Entity text={contact.name} />
    } else {
      return (
        <button className="text-dark" onClick={() => props.setContactId(contact.id)}>
          {contact.name}
        </button>
      )
    }
  }

  function Sequence({ sequence }: { sequence: EventSequence }) {
    if (context === 'contact') {
      return (
        <>
          <Separator />
          <Link className="text-dark" to={`/sequences/${sequence.id}`}>
            {sequence.name}
          </Link>
        </>
      )
    } else {
      return <></>
    }
  }

  function Separator() {
    return <>&ensp;•&ensp;</>
  }

  function EmailReplyWithBody({ event }: { event: EventEmailReply }) {
    const [truncate, setTruncate] = useState(true)
    return context === 'contact' ? (
      <>
        <Contact contact={event.contact} /> replied to{' '}
        <span title={event.emailBody}>
          <Entity text={event.emailSubject} />
        </span>{' '}
        from <Entity text={event.actorName} />
        <Sequence sequence={event.sequence} />
        <Separator />
        {timeAgo(event.occurredAt)}
      </>
    ) : (
      <div className="rounded-lg border bg-white p-6 hover:bg-extra-light" onClick={() => setTruncate(!truncate)}>
        <Contact contact={event.contact} /> replied to <Entity text={event.emailSubject} /> from{' '}
        <Entity text={event.actorName} />
        <Sequence sequence={event.sequence} />
        <Separator />
        {timeAgo(event.occurredAt)}
        <div className={clsx({ 'line-clamp-2': truncate }, 'mt-2 leading-5')}>{event.emailBody}</div>
      </div>
    )
  }

  function renderActivity(a: Event) {
    switch (a.type) {
      case EventTypeCallAnswer.CALL_ANSWER:
        return (
          <>
            <Contact contact={a.contact} /> answered call from <Entity text={a.actorName} /> was answered
            <Sequence sequence={a.sequence} />
            <Separator />
            {timeAgo(a.occurredAt)}
          </>
        )
      case EventTypeCallReceive.CALL_RECEIVE:
        return (
          <>
            <Entity text={a.actorName} /> called <Contact contact={a.contact} />
            <Sequence sequence={a.sequence} />
            <Separator />
            {timeAgo(a.occurredAt)}
          </>
        )
      case EventTypeContactAdd.CONTACT_ADD:
        return (
          <>
            <Entity text={a.actorName} /> added contact
            <Separator />
            {timeAgo(a.occurredAt)}
          </>
        )
      case EventTypeContactStageChange.CONTACT_STAGE_CHANGE:
        return (
          <>
            <Entity text={a.actorName} /> updated status from{' '}
            <Entity text={ContactStageToText[a.stageChange.previousStage]} /> to{' '}
            <Entity text={ContactStageToText[a.stageChange.newStage]} />
            <Separator />
            {timeAgo(a.occurredAt)}
          </>
        )
      case EventTypeEmailAutorespond.EMAIL_AUTORESPOND:
        return (
          <>
            <Contact contact={a.contact} /> auto-responded to <Entity text={a.emailSubject} /> from{' '}
            <Entity text={a.actorName} />
            <Separator />
            Step {a.stepNo}
            <Sequence sequence={a.sequence} />
            <Separator />
            {timeAgo(a.occurredAt)}
          </>
        )
      case EventTypeEmailBounce.EMAIL_BOUNCE:
        return (
          <>
            Email address <Entity text={a.contact.email} /> bounced
            <Separator />
            Step {a.stepNo}
            <Sequence sequence={a.sequence} />
            <Separator />
            {timeAgo(a.occurredAt)}
          </>
        )
      case EventTypeEmailOpen.EMAIL_OPEN:
        return (
          <>
            <Contact contact={a.contact} /> opened <Entity text={a.emailSubject} />
            <Sequence sequence={a.sequence} />
            <Separator />
            {timeAgo(a.occurredAt)}
          </>
        )
      case EventTypeEmailReply.EMAIL_REPLY:
        return <EmailReplyWithBody event={a} />
      case EventTypeEmailSend.EMAIL_SEND:
        return (
          <>
            <Entity text={a.actorName} /> <span data-testid="sent-activity">sent</span> <Entity text={a.emailSubject} />{' '}
            to <Contact contact={a.contact} />
            <Separator />
            Step {a.stepNo}
            <Sequence sequence={a.sequence} />
            <Separator />
            {timeAgo(a.occurredAt)}
          </>
        )

      case EventTypeEmailReplySend.EMAIL_REPLY_SEND:
        return (
          <>
            <Entity text={a.actorName} /> <span data-testid="sent-activity">replied</span> to{' '}
            <Contact contact={a.contact} />
            <Separator />
            <Entity text={a.emailSubject} />
            <Separator />
            {timeAgo(a.occurredAt)}
          </>
        )
      case EventTypeEmailUnsubscribe.EMAIL_UNSUBSCRIBE:
        return (
          <>
            <Contact contact={a.contact} /> unsubscribed
            {a.sequence && <Sequence sequence={a.sequence} />}
            <Separator />
            {timeAgo(a.occurredAt)}
          </>
        )
      case EventTypeMeetingBook.MEETING_BOOK:
        return (
          <>
            <Contact contact={a.contact} /> booked a meeting with <Entity text={a.actorName} />
            {a.sequence && <Sequence sequence={a.sequence} />}
            <Separator />
            {timeAgo(a.occurredAt)}
          </>
        )
      case EventTypeLinkedinSend.LINKEDIN_SEND:
        return (
          <>
            <Entity text={a.actorName} /> sent a LinkedIn message to <Contact contact={a.contact} />
            <Separator />
            Step {a.stepNo}
            <Sequence sequence={a.sequence} />
            <Separator />
            {timeAgo(a.occurredAt)}
          </>
        )
    }
  }

  return (
    <ul className="text-sm leading-5 text-medium">
      {activities.map((activity) => (
        <li key={activity.id} className="my-4" data-testid="activity-row">
          {renderActivity(activity)}
        </li>
      ))}
    </ul>
  )
}
