import { useNavigate } from 'react-router-dom'
import { HighFive } from '@/icons/highfive'
import { Button } from '@/ui'

export function OnboardingCompleted() {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col items-center">
      <h1 className="mb-6 text-lg font-medium">Onboarding completed!</h1>
      <HighFive />
      <Button type="button" variant="accent" onClick={() => navigate('/')}>
        Take me to rift
      </Button>
    </div>
  )
}
