import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'

type Options = {
  timezone?: string
  precision?: 'second' | 'minute' | 'hour'
}

/**
 * useCurrentTime returns the current time in the specified timezone
 * with given precision as a string
 */
export function useCurrentTime(options?: Options) {
  const { timezone = 'UTC', precision = 'minute' } = options || {}
  const format = precision === 'second' ? 'hh:mm:ss a' : precision === 'minute' ? 'hh:mm a' : 'hh a'
  const interval = precision === 'second' ? 1000 : precision === 'minute' ? 60000 : 3600000
  const now = DateTime.now().setZone(timezone).toFormat(format)
  const [currentTime, setCurrentTime] = useState(now)

  useEffect(() => {
    const intervalFn = setInterval(() => {
      const now = DateTime.now().setZone(timezone).toFormat(format)
      setCurrentTime(now)
    }, interval)
    return () => clearInterval(intervalFn)
  })

  return currentTime
}
