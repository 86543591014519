import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { queryKey as contactQueryKey } from '@/api/contacts'
import {
  DefaultService,
  PatchContactList,
  PatchContactListAddContacts,
  PatchContactListRemoveContacts,
  PostContactList,
} from '@/api/core'

export const queryKey = {
  base: () => ['contact_lists'] as const,
  list: () => ['contact_lists', 'list'] as const,
  campaignAssignable: () => ['contact_lists', 'list', 'campaigns', 'assaignable'] as const,
  importable: () => ['contact_lists', 'list', 'importable'] as const,
  get: (id: string) => ['contact_lists', id] as const,
  contacts: (id: string) => ['contact_lists', id, 'contacts'] as const,
}

export function useContactListCreate() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.list() }),
    mutationFn: (data: PostContactList) => DefaultService.postContactLists(data),
  })
}

export function useContactLists() {
  return useQuery({
    queryKey: queryKey.list(),
    queryFn: () => DefaultService.getContactLists(),
  })
}

export function useContactListsCampaignAssignable() {
  return useQuery({
    queryKey: queryKey.campaignAssignable(),
    queryFn: () => DefaultService.getContactListsCampaignsAssignable(),
  })
}
export function useContactListsImportable() {
  return useQuery({
    queryKey: queryKey.importable(),
    queryFn: () => DefaultService.getContactListsImportable(),
  })
}

export function useContactList(contactListId: string) {
  return useQuery({
    queryKey: queryKey.get(contactListId),
    queryFn: () => DefaultService.getContactLists1(contactListId),
  })
}

export function useContactListContacts(contactListId: string) {
  return useQuery({
    enabled: !!contactListId,
    queryKey: queryKey.contacts(contactListId),
    queryFn: () => DefaultService.getContactListsContacts(contactListId),
  })
}

export function useContactListUpdate() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
      queryClient.invalidateQueries({ queryKey: queryKey.get(variables.contactListId) })
    },
    mutationFn: (data: PatchContactList & { contactListId: string }) => {
      const { contactListId, ...body } = data
      return DefaultService.patchContactLists(contactListId, body)
    },
  })
}

export function useContactListDelete() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
    },
    mutationFn: (contactListId: string) => DefaultService.deleteContactLists(contactListId),
  })
}

export function useContactListAddContacts() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: queryKey.get(variables.contactListId) })
      // NOTE: this is called by campaign page but there's no information about campaign id
      // so let's invalidate all contacts campaings although it's not optimal
      queryClient.invalidateQueries({ queryKey: contactQueryKey.campaigns() })
    },
    mutationFn: (data: PatchContactListAddContacts & { contactListId: string }) => {
      const { contactListId, ...body } = data
      return DefaultService.patchContactListsAddContacts(contactListId, body)
    },
  })
}

export function useContactListRemoveContacts() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: queryKey.get(variables.contactListId) })
    },
    mutationFn: (data: PatchContactListRemoveContacts & { contactListId: string }) => {
      const { contactListId, ...body } = data
      return DefaultService.patchContactListsRemoveContacts(contactListId, body)
    },
  })
}
