import { useNavigate } from 'react-router-dom'
import { useBlueprints } from '@/api/sequence_generation'
import { Button, Text } from '@/ui'
import { DeleteBlueprintButton } from './DeleteBlueprintButton'

export function Blueprints() {
  const { data, isError, isPending } = useBlueprints()
  const navigate = useNavigate()

  const addBlueprint = () => {
    navigate('/account-sequences/blueprints/new')
  }

  return (
    <section className="m-auto mt-5 max-w-2xl pb-5">
      <Text variant="title">Blueprints</Text>
      <div>
        {!isPending &&
          data &&
          data.data.map((blueprint) => {
            return (
              <div key={blueprint.id} className="my-4 rounded-lg border bg-white p-4">
                <dl>
                  <dt className="font-semibold">Name</dt>
                  <dd>{blueprint.name}</dd>
                  <dt className="font-semibold">Company Description</dt>
                  <dd>{blueprint.companyDescription}</dd>
                  <dt className="font-semibold">Value Propositions</dt>
                  <dd>{blueprint.valuePropositions}</dd>
                  <dt className="font-semibold">Job Titles</dt>
                  <dd>{blueprint.jobTitles}</dd>
                </dl>
                <div className="mt-4 flex gap-x-4">
                  <DeleteBlueprintButton id={blueprint.id} />
                  <Button variant="accent" onClick={() => navigate(`/account-sequences/blueprints/${blueprint.id}`)}>
                    Edit
                  </Button>
                </div>
              </div>
            )
          })}
        {isError && <div>There was an error fetching blueprints</div>}
        <Button onClick={addBlueprint}>Add blueprint</Button>
      </div>
    </section>
  )
}
