import { useState } from 'react'
import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { twMerge } from 'tailwind-merge'
import {
  useGetCompaniesWithWarmerDrops,
  useSampleWarmerEmails,
  useSenderEmailsStatsCompanies,
  useSenderEmailStatsByCompany,
} from '@/api/admin'
import { SenderEmailStatLine } from '@/api/core'
import { Dropdown } from '@/components/Dropdown'
import { dateOnlyRFC3999Format } from '@/lib/date'
import { Banner, Button, RichDateRangePicker, Text } from '@/ui'
import { DateRangeClosed } from '@/ui/RichDateRangePicker/RichDateRangePicker'

export function EmailStats() {
  const [range, setRange] = useState<DateRangeClosed>({
    from: new Date(new Date().setDate(new Date().getDate() - 30)) as Date,
    to: new Date(new Date().setDate(new Date().getDate() - 1)) as Date,
  })
  const [companyId, setCompanyId] = useState<string | null>(null)
  const { data: companyData } = useSenderEmailsStatsCompanies()
  const { data: statsData } = useSenderEmailStatsByCompany(
    companyId || '',
    range ? dateOnlyRFC3999Format(range?.from) : undefined,
    range ? dateOnlyRFC3999Format(range?.to) : undefined,
  )
  const [showWarmerSamples, setShowWarmerSamples] = useState(false)
  const { data: sampleWarmerEmailsData } = useSampleWarmerEmails()
  const { data: companyWithWarmerDropsData } = useGetCompaniesWithWarmerDrops(
    range ? dateOnlyRFC3999Format(range?.from) : undefined,
    range ? dateOnlyRFC3999Format(range?.to) : undefined,
  )
  const [showDataFor, setShowDataFor] = useState<'warmer' | 'campaign_sent' | 'campaign_delivered'>(
    'campaign_delivered',
  )

  const companyOptions = [
    { id: '', label: 'All Companies' },
    ...(companyData?.data || []).map((company) => ({
      id: company.id,
      label: company.name,
    })),
  ]
  const graphData = statsData?.data || []
  const sampleWarmerEmails = sampleWarmerEmailsData?.data || []
  const companyWithWarmerDrops = companyWithWarmerDropsData?.data || []

  if (!graphData) return <></>

  const warmerDataSum = graphData.reduce((acc, datum) => acc + (datum.warmerEmailsSent || 0), 0) || 1
  const warmerAverage = warmerDataSum / graphData.length

  function transformChartData(datum: SenderEmailStatLine) {
    switch (showDataFor) {
      case 'warmer':
        return datum.warmerEmailsSent
      case 'campaign_sent':
        return datum.campaignEmailsSent
      case 'campaign_delivered':
        return 100 * (datum.campaignEmailsSent ? datum.campaignEmailsDelivered / datum.campaignEmailsSent : 0)
    }
  }

  const options: Highcharts.Options = {
    chart: {
      type: 'column',
      style: {
        fontFamily: 'Monument, sans-serif',
        fontSize: '16px',
      },
    },
    legend: {
      enabled: false,
    },

    xAxis: {
      type: 'category',
      categories: graphData.map((datum) => datum.date),
      crosshair: false,
      accessibility: {
        description: 'Dates',
      },
    },
    yAxis: {
      title: {
        text: `${showDataFor === 'warmer' ? 'Warmer' : 'Campaign'} emails ${showDataFor === 'campaign_delivered' ? 'delivered' : 'sent'}`,
      },
      min: showDataFor === 'campaign_delivered' ? 0 : undefined,
      max: showDataFor === 'campaign_delivered' ? 100 : undefined,
      labels: {
        format: showDataFor === 'campaign_delivered' ? '{value:.0f}%' : undefined,
      },
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
      },
      column: {
        zones: [
          {
            value: 0.8 * (showDataFor === 'warmer' ? warmerAverage : -100),
            color: '#F35E35',
          },
          {
            color: '#073874',
          },
        ],
      },
    },
    title: {
      text: '',
    },
    series: [
      {
        type: 'column',
        name: `${showDataFor === 'warmer' ? 'Warmer' : 'Campaign'} emails ${showDataFor === 'campaign_delivered' ? 'delivered' : 'sent'}`,
        data: graphData.map(transformChartData),
        color: '#073874',
        dataLabels: {
          format: '{y:.2f}%',
        },
      },
    ],
    tooltip: {
      style: {
        fontSize: '14px',
        fontFamily: 'Monument, sans-serif',
        lineHeight: '21px',
      },
      valueDecimals: showDataFor === 'campaign_delivered' ? 2 : undefined,
      valueSuffix: showDataFor === 'campaign_delivered' ? '%' : '',
    },
    credits: {
      enabled: false,
    },
  }

  const value = companyOptions.find((option) => option.id === companyId) || { id: '', label: 'All Companies' }

  return (
    <div className="mx-auto  max-w-[800px]">
      {companyWithWarmerDrops.length > 0 && (
        <Banner severity="warning">
          <Text variant="title" className="mb-4">
            Companies with warmer drops
          </Text>
          <div>
            {companyWithWarmerDrops.map((company) => (
              <React.Fragment key={company.id}>
                <div
                  className="mr-2 cursor-pointer underline"
                  onClick={() => {
                    setCompanyId(company.id)
                    setShowDataFor('warmer')
                  }}
                >
                  {company.name}
                </div>
              </React.Fragment>
            ))}
          </div>
        </Banner>
      )}
      <div className="flex items-center gap-x-2">
        <Text variant="title" className="my-4">
          {showDataFor === 'warmer'
            ? 'Warmer email'
            : showDataFor === 'campaign_sent'
              ? 'Campaign emails sent'
              : 'Campaign delivery'}{' '}
          stats
        </Text>
        <Button
          className={twMerge('h-6 text-xs', showDataFor === 'warmer' && 'hidden')}
          onClick={() => setShowDataFor('warmer')}
        >
          Warmer email stats
        </Button>
        <Button
          className={twMerge('h-6 text-xs', showDataFor === 'campaign_sent' && 'hidden')}
          onClick={() => setShowDataFor('campaign_sent')}
        >
          Campaign email sent stats
        </Button>
        <Button
          className={twMerge('h-6 text-xs', showDataFor === 'campaign_delivered' && 'hidden')}
          onClick={() => setShowDataFor('campaign_delivered')}
        >
          Campaign delivery stats
        </Button>
      </div>

      <div className="mb-10 flex items-center justify-between">
        <div className="flex items-center">
          <Text className="mr-4 text-lg font-medium">Company:</Text>
          <Dropdown
            selected={value}
            handleSelection={(selected) => setCompanyId(selected.id as string)}
            options={companyOptions}
          />
        </div>
        <RichDateRangePicker
          dataTestId="date-range-picker"
          onRangeChange={setRange}
          disableToday={true}
          options={[]}
          startingRange={range}
        />
      </div>
      <div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      <Text variant="title" className="mb-4 mt-10">
        Sample Warmer Emails
      </Text>
      <div>
        {showWarmerSamples ? (
          sampleWarmerEmails.map(({ id, subject, body }) => (
            <div key={id} className="overflow-auto border-b p-4">
              <div className="font-medium">Subject:</div>
              <div className="whitespace-pre">{subject}</div>
              <div className="font-medium">Body:</div>
              <div className="whitespace-pre-line">{body}</div>
            </div>
          ))
        ) : (
          <Button onClick={() => setShowWarmerSamples(true)}>Get warmer email samples</Button>
        )}
      </div>
    </div>
  )
}
