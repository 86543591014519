import { createContext, useContext } from 'react'
import { Inbox } from '@/api/core'

export type State = {
  inboxes: Record<string, Inbox>
  membersHealth: Record<string, boolean>
  teamsHealth: Record<string, boolean>
}

export const InboxDataContext = createContext<State | null>(null)
InboxDataContext.displayName = 'InboxDataContext'

export function useData() {
  const context = useContext(InboxDataContext)
  if (context === null) {
    throw new Error(`component is missing a parent <InboxDataContext /> component.`)
  }
  return context
}
