import { useEffect, useState } from 'react'
import { Control, useController } from 'react-hook-form'
import { MeetingMember, PostMeetingType } from '@/api/core'
import { Checkbox } from '@/ui'

type MembersSelectProps = {
  members: MeetingMember[]
  control: Control<PostMeetingType>
}

export function MembersSelect(props: MembersSelectProps) {
  const { field, fieldState } = useController({
    control: props.control,
    name: 'memberIds',
    rules: {
      validate: (value: string[] | undefined) => {
        if (value && value.length > 0) {
          return
        }
        return 'At least one member must be selected'
      },
    },
  })

  const [selectedMembers, setSelectedMembers] = useState<string[]>(field.value ?? [])
  useEffect(() => {
    setSelectedMembers(field.value ?? [])
  }, [field.value])
  return (
    <ul className="space-y-4">
      {props.members.map((member) => (
        <label key={member.id} className="flex">
          <Checkbox
            className="label"
            value={selectedMembers.includes(member.id) ? member.id : ''}
            checked={selectedMembers.includes(member.id)}
            onChange={(e) => {
              e.preventDefault()
              const update: string[] = e.currentTarget.checked
                ? selectedMembers.length > 0
                  ? [...selectedMembers, member.id]
                  : [member.id]
                : selectedMembers.filter((i: string) => i !== member.id) ?? []
              setSelectedMembers(update)
              field.onChange(update)
            }}
          />
          <span className="label ml-3 block">
            {member.name} ({member.email})
          </span>
        </label>
      ))}
      {fieldState.error && <p className="label text-alert">{fieldState.error?.message}</p>}
    </ul>
  )
}
