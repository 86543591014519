import { useQuery } from '@tanstack/react-query'
import { Account, AccountsList, ApiError, DefaultService } from '@/api/core'

export const queryKey = {
  base: () => ['account_list'] as const,
  list: () => ['account_list', 'list'] as const,
}

export function useAccounts() {
  return useQuery<{ data: AccountsList }, ApiError>({
    queryKey: queryKey.list(),
    queryFn: () => DefaultService.getAccounts(),
  })
}

export function useAccount(id: string) {
  return useQuery<{ data: Account }, ApiError>({
    queryKey: queryKey.base(),
    queryFn: () => DefaultService.getAccounts1(id),
  })
}
