import { Dispatch, SetStateAction, useState } from 'react'
import { useDebounce } from 'use-debounce'

type Options = {
  delay: number
}

export function useDebounceValue<S>(initialState: S, options?: Options): [S, S, Dispatch<SetStateAction<S>>] {
  const { delay = 300 } = options || {}
  const [value, setValue] = useState(initialState)
  const [debounced] = useDebounce(value, delay)
  return [value, debounced, setValue]
}
