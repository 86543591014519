import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import produce from 'immer'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { RegistrationFlow, UpdateRegistrationFlowBody } from '@ory/client'
import { kratos } from '@/config/kratos'
import { useSCRFToken } from '@/hooks/useCSRFToken'
import { useOryErrors } from '@/hooks/useOryErrors'
import { RiftLogo } from '@/icons/RiftLogo'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { useUserContext } from '@/providers/User/context'
import { reportSentryError, reportSentryErrorWithEmail } from '@/sentry'
import { Button } from '@/ui/Button/v2'
import { Input } from '@/ui/Input/v2'
import { Text } from '@/ui/Text'
import { ErrorText } from '@/ui/Text/Error'

type formBody = {
  email: string
  password: string
  firstName: string
  lastName: string
  company: string
}

const validationSchema = Yup.object()
  .required()
  .shape({
    email: Yup.string().required('Email is required').email('Email is invalid'),
    password: Yup.string()
      .min(8, 'Minimum password length is 8 characters')
      .max(72, 'Maximum password length is 72 characters')
      .required('Password is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    company: Yup.string().required('Company is required'),
  })

export function Register() {
  const [searchParams] = useSearchParams()
  const { initialize } = useUserContext()
  const navigate = useNavigate()
  const { setCSRFToken, csrfToken } = useSCRFToken()
  const toast = useToast()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = useForm({ resolver: yupResolver(validationSchema) })

  const pathEmail = searchParams.get('email')

  useEffect(() => {
    if (pathEmail != null) {
      setValue('email', pathEmail)
    }
  }, [pathEmail])

  const { setErrorsFromNodes, errors: oryErrors } = useOryErrors(
    () => {
      setError('email', { type: 'manual', message: ' ' })
    },
    () => setError('password', { type: 'manual', message: ' ' }),
  )

  const [flow, setFlow] = useState<RegistrationFlow | null>(null)

  // HACK,NOTE: this is a hack to turn off google sign up but keep google sign in in our app
  const setFlowWithoutOidc = (baseFlow: RegistrationFlow | null) =>
    setFlow(
      produce(baseFlow, (flow) => {
        if (flow) {
          flow.ui.nodes = flow.ui.nodes.filter((node) => node.group !== 'oidc')
        }
      }),
    )

  const createFlow = useCallback(
    () =>
      kratos
        .createBrowserRegistrationFlow()
        .then(({ data: flow }) => {
          setFlowWithoutOidc(flow)
          setFlow(flow)
          setCSRFToken(flow.ui.nodes)
        })
        .catch((error) => {
          reportSentryErrorWithEmail('[KRATOS] Registration initializeSelfServiceRegistrationFlowForBrowsers failed', {
            extra: {
              error,
            },
          })
          switch (error.response?.status) {
            case 400:
              setFlowWithoutOidc(error.response.data)
              break
            case 410:
            case 404:
              navigate('/registration', { replace: true })
          }
        }),
    [],
  )

  const getFlow = useCallback(
    (flowId: string) =>
      kratos
        .getRegistrationFlow({ id: flowId })
        .then(({ data: flow }) => {
          setFlowWithoutOidc(flow)
          setFlow(flow)
          setCSRFToken(flow.ui.nodes)
        })
        .catch((error) => {
          reportSentryError('[KRATOS] Registration getSelfServiceRegistrationFlow failed', {
            extra: {
              error,
            },
          })
          return error
        }),
    [],
  )

  const submitForm = ({ email, company, firstName, lastName, password }: formBody) => {
    if (!flow) {
      return navigate('/registration', { replace: true })
    }

    const token = csrfToken || setCSRFToken(flow.ui.nodes)

    const body = {
      csrf_token: token,
      method: 'password',
      password: password,
      traits: {
        company_name: company,
        email: email,
        first_name: firstName,
        last_name: lastName,
      },
    } as UpdateRegistrationFlowBody

    kratos
      .updateRegistrationFlow({ flow: flow.id, updateRegistrationFlowBody: body })
      .then(() => {
        initialize()
        toast.createToast({ message: 'Account created! Please sign in to get started.' })
        navigate('/', { replace: true })
      })
      .catch((error) => {
        reportSentryErrorWithEmail('[KRATOS] Registration submitSelfServiceRegistrationFlow failed', {
          extra: {
            error,
          },
        })

        switch (error.response.status) {
          case 400: {
            setErrorsFromNodes(error.response.data.ui.nodes, error.response.data?.ui?.messages)
            setFlowWithoutOidc(error.response.data)
            break
          }
          case 422:
            // eslint-disable-next-line no-case-declarations
            const u = new URL(error.response.data.redirect_browser_to)
            getFlow(u.searchParams.get('flow') || '').catch(() => {
              navigate('/registration', { replace: true })
            })
            break
          case 410:
          case 404:
          default:
            return navigate('/registration', { replace: true })
        }
        toast.createToast({
          error: true,
          message: 'Account creation failed. Please review your credentials and attempt registration again.',
        })
      })
  }

  useEffect(() => {
    const flowId = searchParams.get('flow')
    if (flowId) {
      getFlow(flowId).catch(createFlow)
      return
    }
    createFlow()
  }, [])

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <hgroup className="text-center sm:mx-auto sm:w-full sm:max-w-md">
        <RiftLogo className="m-auto" simple={false} width={60} />
        <Text variant="title" className="mt-6">
          Create an account
        </Text>
      </hgroup>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <form className="space-y-5" action="#" method="POST" onSubmit={handleSubmit(submitForm)}>
            <Input
              name="fist-name"
              id="first-name"
              type="text"
              label="First name"
              boldLabel
              autoFocus
              containerClassName="my-0"
              error={errors['firstName']?.message}
              className="mt-0"
              registration={register('firstName')}
              data-testid="firstname-input"
            />
            <Input
              name="last-name"
              id="last-name"
              className="mt-0"
              containerClassName="my-0"
              type="text"
              boldLabel
              label="Last name"
              error={errors['lastName']?.message}
              registration={register('lastName')}
              data-testid="lastname-input"
            />
            <Input
              name="company"
              id="company"
              type="text"
              label="Company"
              boldLabel
              error={errors['company']?.message}
              registration={register('company')}
              data-testid="company-input"
            />
            <Input
              name="email"
              id="email"
              type="email"
              label="Email"
              required
              boldLabel
              error={errors['email']?.message}
              registration={register('email')}
              disabled={!!pathEmail}
              data-testid="email-input"
            />
            <Input
              name="password"
              id="password"
              type="password"
              label="Password"
              boldLabel
              required
              error={errors['password']?.message}
              registration={register('password')}
              data-testid="password-input"
            />
            {oryErrors?.map((message) => <ErrorText key={message} errorMessage={message} exclamationIconWidth={32} />)}
            <Button type="submit" variant="accent" className="w-full" data-testid="signup-btn">
              Create an account
            </Button>
          </form>
        </div>

        <Text variant="subtext" className="pt-4 text-center">
          Already have an account{` `}
          <a href="/login" className=" text-accent" data-testid="signin-link">
            Sign in
          </a>
        </Text>
      </div>
    </div>
  )
}
