import { useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { CaretDown, CaretUp, CaretUpDown } from '@phosphor-icons/react'
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowData,
  SortingState,
  Table,
  useReactTable,
} from '@tanstack/react-table'
import { useCampaignContactsAbandon, useCampaignContactsDelete } from '@/api/campaigns'
import { useContactListAddContacts, useContactListRemoveContacts } from '@/api/contact_lists'
import {
  useContactsDelete,
  useContactsExport,
  useContactsOwnerChange,
  useContactsUnenroll,
  useContactsUnsubscribe,
} from '@/api/contacts'
import { Campaign, ContactList, ContactV2, Member, MemberRole, OrganizationMember } from '@/api/core'
import { ContactStageToText } from '@/api/text'
import { useObserveSticky } from '@/hooks/useObserveSticky'
import { useQueryParamState } from '@/hooks/useQueryParamState'
import { Unix } from '@/lib/date'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Checkbox, IndeterminateCheckbox, Listbox, LoadingDialog, Tooltip } from '@/ui'
import { AddToListDialog } from './AddToListDialog'
import { ConfirmationDialog } from './ConfirmationDialog'
import { ContactDetails } from './ContactDetails'
import {
  CampaignListFloatingAppBar,
  ContactsFloatingAppBar,
  ContactsListFloatingAppBar,
} from './ContactsFloatingAppBar'
import { EmailStatusBadge } from './EmailStatusBadge'
import { ContactFilters } from './Filter'

type ContactTableProps = {
  data: ContactV2[]
  owners: Member[]
  contactsFilter: ContactFilters
  globalFilter: string
  setGlobalFilter: (value: string) => void
} & (
  | { contactList: ContactList; campaign?: never }
  | { contactList?: never; campaign: Campaign }
  | { contactList?: never; campaign?: never }
)

export function ContactsTable(props: ContactTableProps) {
  const { data, globalFilter, setGlobalFilter, contactsFilter, owners, contactList, campaign } = props
  const context = contactList ? 'contacts_list' : campaign ? 'campaign' : 'contacts'
  const contactListId = contactList?.id
  const campaignId = campaign?.id

  const toast = useToast()

  const [sorting, setSorting] = useState<SortingState>([{ id: 'created_at', desc: true }])
  const [rowSelection, setRowSelection] = useState<{ [key: number]: boolean }>({})
  const selectedCount = Object.values(rowSelection).filter((v) => v).length
  const [tableHeaderRef, tableHeaderColored] = useObserveSticky<HTMLTableRowElement>()

  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false)
  const [deleteFromListConfirmationDialog, setDeleteFromListConfirmationDialog] = useState(false)

  const [unsubConfirmationDialog, setUnsubConfirmationDialog] = useState(false)
  const [abandonConfirmationDialog, setAbandonConfirmationDialog] = useState(false)
  const [abandonCampaignConfirmationDialog, setAbandonCampaignConfirmationDialog] = useState(false)
  const [deleteCampaignConfirmationDialog, setDeleteCampaignConfirmationDialog] = useState(false)

  const [addToListDialogOpen, setAddToListDialogOpen] = useState(false)
  const [openFloatingAppBar, setOpenFloatingAppBar] = useState(false)

  const [contactId, setContactId] = useQueryParamState('contactId')

  const deleteContacts = useContactsDelete()
  const contactsUnsubscribe = useContactsUnsubscribe()
  const contactsUnenroll = useContactsUnenroll()
  const campaignContactsUnenroll = useCampaignContactsAbandon(campaignId || '')
  const deleteCampaignContacts = useCampaignContactsDelete()
  const contactListRemoveContacts = useContactListRemoveContacts()
  const contactsExport = useContactsExport()
  const addContacts = useContactListAddContacts()
  const contactOwnerChange = useContactsOwnerChange()

  const onContactOwnerChange = (contactId: string, ownerId: string) => {
    contactOwnerChange.mutate(
      {
        contactIds: [contactId],
        newOwnerId: ownerId,
      },
      {
        onSuccess: () => toast.createToast({ message: 'Owner changed' }),
        onError: () => toast.createToast({ message: 'Failed to change owner', error: true }),
      },
    )
  }

  const onDeleteContacts = () => {
    const ids = Object.keys(rowSelection).map((i) => data[Number(i)].id)
    deleteContacts.mutate(
      { ids },
      {
        onSuccess: () => {
          setRowSelection({})
          toast.createToast({ message: 'Removed' })
        },
        onError: (err) => {
          toast.createToast({ message: (err as any)?.body?.message || 'failed to remove contacts', error: true })
        },
      },
    )
  }

  const onUnsubscribeContacts = () => {
    const ids = Object.keys(rowSelection).map((i) => data[Number(i)].id)
    contactsUnsubscribe.mutate(
      { ids: ids },
      {
        onSuccess: () => {
          toast.createToast({ message: 'Contacts unsubscribed' })
        },
        onError: (err) => {
          toast.createToast({ message: (err as any)?.body?.message || 'Failed to unsubscribe contacts', error: true })
        },
      },
    )
  }

  const onAbandonContacts = () => {
    const ids = Object.keys(rowSelection).map((i) => data[Number(i)].id)
    contactsUnenroll.mutate(
      { ids: ids },
      {
        onSuccess: () => {
          toast.createToast({ message: 'Contacts unenrolled' })
        },
        onError: (err) => {
          toast.createToast({ message: (err as any)?.body?.message || 'Failed to unenroll contacts', error: true })
        },
      },
    )
  }

  const onAbandonCampaignContacts = () => {
    const ids = Object.keys(rowSelection).map((i) => data[Number(i)].id)
    campaignContactsUnenroll.mutate(
      { contact_ids: ids },
      {
        onSuccess: () => {
          toast.createToast({ message: 'Contacts unenrolled' })
        },
        onError: (err) => {
          toast.createToast({ message: (err as any)?.body?.message || 'Failed to unenroll contacts', error: true })
        },
      },
    )
  }
  const onDeleteCampaignContacts = () => {
    const ids = Object.keys(rowSelection).map((i) => data[Number(i)].id)
    deleteCampaignContacts.mutate(
      { contact_ids: ids, campaignId: campaignId! },
      {
        onSuccess: () => {
          setRowSelection({})
          toast.createToast({ message: 'Removed' })
        },
        onError: (err) => {
          toast.createToast({ message: (err as any)?.body?.message || 'failed to remove contacts', error: true })
        },
      },
    )
  }

  const onContactListRemoveContacts = () => {
    const ids = Object.keys(rowSelection).map((i) => data[Number(i)].id)
    contactListRemoveContacts.mutate(
      { contactListId: contactListId!, contact_ids: ids },
      {
        onSuccess: () => {
          setRowSelection({})
          toast.createToast({ message: 'contacts deleted' })
        },
        onError: (err) => {
          toast.createToast({ message: (err as any)?.body?.message || 'Failed to delete contacts', error: true })
        },
      },
    )
  }

  const onContactsExport = async () => {
    const ids = Object.keys(rowSelection).map((i) => data[Number(i)].id)
    contactsExport.mutate(
      { ids: ids },
      {
        onSuccess: (b: Blob) => {
          const date = new Date().toLocaleDateString()
          const url = window.URL.createObjectURL(new Blob([b], { type: 'text/csv;charset=utf-8;' }))
          const tempLink = document.createElement('a')

          tempLink.href = url
          tempLink.setAttribute('download', `contacts_${date}.csv`)
          tempLink.click()
        },
        onError: (err) => {
          toast.createToast({ message: (err as any)?.body?.message || 'Failed to export contacts', error: true })
        },
      },
    )
  }

  useEffect(() => setOpenFloatingAppBar(Object.keys(rowSelection).length > 0), [rowSelection])
  const tableRef = useRef(null)

  const columnHelper = createColumnHelper<ContactV2>()
  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => (
          <IndeterminateCheckbox
            aria-label="Select all"
            className="mb-1"
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.toggleAllRowsSelected}
          />
        ),
        cell: ({ row }) => {
          return (
            <Checkbox
              className="mb-1"
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              onChange={row.getToggleSelectedHandler()}
            />
          )
        },
      }),
      columnHelper.accessor(
        (row) => [row.first_name, row.last_name, row.company, row.email, row.email_status].join(' '),
        {
          id: 'contact',
          header: () => <span>Contact</span>,
          cell: (info) => (
            <button
              className="cursor-pointer text-left"
              onClick={() => setContactId(info.row.original.id)}
              data-testid="contact-edit-btn"
            >
              <p className="font-medium">
                {[info.row.original.first_name, info.row.original.last_name].join(' ').trim() || '–'}
              </p>
              <div className="font-normal">
                <span className="text-dusk">{info.row.original.email}</span>
                {EmailStatusBadge(info.row.original.email_status)}
              </div>
              <div className="font-normal text-dusk">{info.row.original.company || '–'}</div>
            </button>
          ),
        },
      ),
      columnHelper.accessor((row) => row.stage, {
        id: 'stage',
        cell: (info) => <span>{ContactStageToText[info.row.original.stage]}</span>,
        header: () => <span>Stage</span>,
        enableColumnFilter: true,
        filterFn: (row, columnId, value) => {
          return value.length === 0 || value.includes(row.getValue(columnId))
        },
      }),
      columnHelper.accessor((row) => (row.active_sequences || []).map(({ name }) => name).join(' '), {
        id: 'active_sequence',
        cell: ({
          row: {
            original: { active_sequences },
          },
        }) => (
          <div className="flex max-w-56 flex-col">
            {active_sequences
              ? active_sequences.map(({ id, name }) => (
                  <div key={id} className="truncate">
                    {name || ''}
                  </div>
                ))
              : '–'}
          </div>
        ),
        header: () => 'Active sequence',
        enableColumnFilter: true,
      }),
      columnHelper.accessor((row) => row.created_at, {
        id: 'created_at',
        enableColumnFilter: true,
        cell: (info) => (
          <span data-testid="contact-added-date">
            {Unix(info.row.original.created_at).toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })}
          </span>
        ),
        header: () => <span>Added</span>,
        filterFn: (row, columnId, value) => {
          const d = Unix(row.getValue(columnId))
          return (!value.start || d >= value.start) && (!value.end || d <= value.end)
        },
      }),
      columnHelper.accessor((row) => row.last_contacted_at, {
        id: 'last_contacted_at',
        enableColumnFilter: true,
        cell: (info) => (
          <span>
            {info.row.original.last_contacted_at &&
              Unix(info.row.original.last_contacted_at).toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              })}
          </span>
        ),
        header: () => <span>Last contacted</span>,
        filterFn: (row, columnId, value) => {
          const d = Unix(row.getValue(columnId))
          return (!value.start || d >= value.start) && (!value.end || d <= value.end)
        },
      }),
      columnHelper.accessor((row) => row.owner, {
        id: 'owner',
        header: () => <span>Owner</span>,
        cell: (info) => (
          <Listbox value={info.row.original.owner.id}>
            <Listbox.Button>
              {info.row.original.owner.name}
              <CaretUpDown className="ml-2 inline size-5 text-medium" />
            </Listbox.Button>

            <Listbox.Options>
              {owners.map((owner) => (
                <Listbox.Option
                  key={owner.id}
                  value={owner.id}
                  className="cursor-default"
                  disabled={owner.role === MemberRole.PHANTOM}
                  onClick={() => onContactOwnerChange(info.row.original.id, owner.id)}
                  title={owner.role === MemberRole.PHANTOM ? 'Can only assign rift member as owner' : undefined}
                >
                  <p
                    className={clsx(
                      'text-sm font-normal  leading-5 tracking-[0.28px]',
                      owner.role === MemberRole.PHANTOM ? 'text-medium' : 'text-dark',
                    )}
                  >
                    {owner.name}
                  </p>
                  {owner.role === MemberRole.PHANTOM && (
                    <p className="text-xs font-normal leading-4 tracking-[0.36px] text-medium">Not a member</p>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        ),
        enableColumnFilter: true,
        filterFn: (row, columnId, value) => {
          return value.length === 0 || (value as OrganizationMember[]).some((v) => v.id === row.original.owner.id)
        },
      }),
    ],
    [],
  ) as ColumnDef<ContactV2, any>[]

  const pageSize = 50
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      sorting,
      rowSelection,
    },

    initialState: {
      pagination: {
        pageSize: pageSize,
      },
    },

    enableSorting: true,
    onSortingChange: setSorting,

    enableFilters: true,
    onGlobalFilterChange: setGlobalFilter,

    onRowSelectionChange: setRowSelection,

    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  useEffect(() => {
    table.getColumn('stage')?.setFilterValue(contactsFilter.stages)
    table.getColumn('created_at')?.setFilterValue({
      start: contactsFilter?.created_at?.start ?? null,
      end: contactsFilter?.created_at?.end ?? null,
    })
    table.getColumn('last_contacted_at')?.setFilterValue({
      start: contactsFilter?.last_contacted_at?.start ?? null,
      end: contactsFilter?.last_contacted_at?.end ?? null,
    })
    table.getColumn('owner')?.setFilterValue(contactsFilter.owners)
  }, [contactsFilter])

  const onAddConfirm = (contactListId: string) => {
    addContacts.mutate(
      {
        contactListId,
        contact_ids: Object.keys(rowSelection).map((i) => data[Number(i)].id),
      },
      {
        onSuccess: () => {
          toast.createToast({ message: 'contacts added to list' })
        },
        onError: (err) => {
          toast.createToast({ message: (err as any)?.body?.message || 'Failed to add contacts to list', error: true })
        },
      },
    )
  }

  const isPending =
    deleteContacts.isPending ||
    contactsUnsubscribe.isPending ||
    contactsUnenroll.isPending ||
    campaignContactsUnenroll.isPending ||
    deleteCampaignContacts.isPending ||
    contactListRemoveContacts.isPending ||
    contactsExport.isPending ||
    addContacts.isPending

  return (
    <>
      <LoadingDialog show={isPending} />
      <AddToListDialog
        open={addToListDialogOpen}
        onClose={() => setAddToListDialogOpen(false)}
        onConfirm={onAddConfirm}
      />
      {contactId && <ContactDetails contactId={contactId} onClose={() => setContactId(null)} />}
      <ConfirmationDialog
        open={deleteConfirmationDialog}
        onClose={() => setDeleteConfirmationDialog(false)}
        onConfirm={onDeleteContacts}
        message={`You are about to remove ${selectedCount} contacts.`}
      />
      <ConfirmationDialog
        open={abandonConfirmationDialog}
        onClose={() => setAbandonConfirmationDialog(false)}
        onConfirm={onAbandonContacts}
        message={`You are about to unenroll ${selectedCount} contacts.`}
        confirmBtn="Unenroll"
      />
      <ConfirmationDialog
        open={abandonCampaignConfirmationDialog}
        onClose={() => setAbandonCampaignConfirmationDialog(false)}
        onConfirm={onAbandonCampaignContacts}
        message={`You are about to unenroll ${selectedCount} contacts .`}
        confirmBtn="Unenroll"
      />

      <ConfirmationDialog
        open={deleteCampaignConfirmationDialog}
        onClose={() => setDeleteCampaignConfirmationDialog(false)}
        onConfirm={onDeleteCampaignContacts}
        message={`You are about to remove ${selectedCount} contacts from campaign.`}
        confirmBtn="Remove"
      />

      <ConfirmationDialog
        open={unsubConfirmationDialog}
        onClose={() => setUnsubConfirmationDialog(false)}
        onConfirm={onUnsubscribeContacts}
        message={`You are about to unsubscribe ${selectedCount} contacts.`}
        confirmBtn="Unsubscribe"
      />
      <ConfirmationDialog
        open={deleteFromListConfirmationDialog}
        onClose={() => setDeleteFromListConfirmationDialog(false)}
        onConfirm={onContactListRemoveContacts}
        message={`You are about to remove  ${selectedCount} contacts from contact list.`}
      />
      {context === 'contacts' && (
        <ContactsFloatingAppBar
          open={openFloatingAppBar}
          close={() => setOpenFloatingAppBar(false)}
          selectedContactIds={Object.keys(rowSelection).map((i) => data[Number(i)].id)}
          posXRef={tableRef}
          onAddToList={() => setAddToListDialogOpen(true)}
          onDelete={() => setDeleteConfirmationDialog(true)}
          onExportToCSV={onContactsExport}
          onAbandoned={() => setAbandonConfirmationDialog(true)}
          onUnsubscribe={() => setUnsubConfirmationDialog(true)}
          members={owners}
        />
      )}
      {contactList && (
        <ContactsListFloatingAppBar
          open={openFloatingAppBar}
          close={() => setOpenFloatingAppBar(false)}
          selectedContactIds={Object.keys(rowSelection).map((i) => data[Number(i)].id)}
          posXRef={tableRef}
          onRemoveFromList={
            !contactList.permissions.removeContact.deny ? () => setDeleteFromListConfirmationDialog(true) : undefined
          }
          onExportToCSV={onContactsExport}
          onAbandoned={() => setAbandonConfirmationDialog(true)}
          onDelete={() => setDeleteConfirmationDialog(true)}
          onUnsubscribe={() => setUnsubConfirmationDialog(true)}
          members={owners}
        />
      )}
      {context === 'campaign' && (
        <CampaignListFloatingAppBar
          open={openFloatingAppBar}
          close={() => setOpenFloatingAppBar(false)}
          selectedContactIds={Object.keys(rowSelection).map((i) => data[Number(i)].id)}
          posXRef={tableRef}
          onExportToCSV={onContactsExport}
          onAbandoned={() => setAbandonCampaignConfirmationDialog(true)}
          onUnsubscribe={() => setUnsubConfirmationDialog(true)}
          onDelete={() => setDeleteCampaignConfirmationDialog(true)}
        />
      )}
      <Pagination table={table} />
      <table className="min-w-full" ref={tableRef}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} ref={tableHeaderRef}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    scope="col"
                    className={clsx(
                      'sticky top-0 table-cell border-y border-gray-200 py-2 text-left text-sm font-semibold text-primary',
                      tableHeaderColored && 'bg-gray-100',
                    )}
                  >
                    <div
                      aria-hidden="true"
                      onClick={header.column.getToggleSortingHandler()}
                      className="flex cursor-pointer select-none items-center"
                    >
                      <div className={clsx('relative', header.column.id === 'select' && 'w-full pr-2 text-center')}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        <div className="pointer-events-none absolute inset-y-0 -right-4 flex items-center">
                          {header.column.getIsSorted() === 'asc' ? (
                            <CaretUp className="h-3 w-3 text-black" />
                          ) : header.column.getIsSorted() === 'desc' ? (
                            <CaretDown className="h-3 w-3 text-black" />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="whitespace-wrap border-y border-gray-200 transition-colors hover:bg-gray-50"
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={clsx(
                        'py-3 text-sm leading-5 tracking-[0.28px] text-dark',
                        cell.column.id === 'select' && 'pr-2 text-center',
                      )}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              )
            })
          ) : (
            <tr className="justify-center border-gray-300">
              <td
                colSpan={table.getHeaderGroups()[0].headers.length}
                className="w-full py-4 text-center text-sm font-medium text-primary"
              >
                No Accounts
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="h-2" />
    </>
  )
}

type PaginationProps<E extends RowData> = {
  table: Table<E>
}

// NOTE: repalce temporary Pagination component def with this one
//function Pagination<E extends RowData>(props: PaginationProps<E>) {
function Pagination(props: PaginationProps<ContactV2>) {
  const { table } = props

  const data = table.getFilteredRowModel().rows
  const contactsCount = useMemo(() => {
    const contactsCount = {
      total: data.length,
      valid: 0,
      invalid: 0,
      catchAll: 0,
      verifying: 0,
    }

    data.forEach((c) => {
      switch (c.original.email_status) {
        case 'VALID':
          contactsCount.valid++
          break
        case 'INVALID':
          contactsCount.invalid++
          break
        case 'CATCH_ALL':
          contactsCount.catchAll++
          break
        default:
          contactsCount.verifying++
          break
      }
    })
    return contactsCount
  }, [data])

  return (
    <nav className="flex items-center justify-between pb-1" aria-label="Pagination">
      <p className="text-sm font-medium text-gray-700">
        <span data-testid="contacts-start-table-index">
          {table.getState().pagination.pageIndex * table.getState().pagination.pageSize}
        </span>{' '}
        —{' '}
        <span>
          {Math.min((table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize, data.length)}
        </span>{' '}
        of{' '}
        <Tooltip>
          <Tooltip.Trigger as="span">
            <span
              className="underline decoration-gray-300 decoration-dashed underline-offset-2"
              data-testid="contacts-total"
            >
              {data.length}
            </span>
          </Tooltip.Trigger>
          <Tooltip.Panel>
            <p>Total: {contactsCount.total}</p>
            <p>Valid: {contactsCount.valid}</p>
            <p>Invalid: {contactsCount.invalid}</p>
            <p>Catch-all: {contactsCount.catchAll}</p>
            <p>Verifying: {contactsCount.verifying}</p>
          </Tooltip.Panel>
        </Tooltip>
      </p>
      <div className="flex flex-1 justify-end gap-2">
        <Button
          variant="basic"
          className={clsx(!table.getCanPreviousPage() && 'invisible')}
          onClick={() => table.previousPage()}
        >
          Previous
        </Button>
        <Button
          variant="basic"
          className={clsx(!table.getCanNextPage() && 'invisible')}
          onClick={() => table.nextPage()}
        >
          Next
        </Button>
      </div>
    </nav>
  )
}
