import { ElementType, Fragment, Ref, useRef } from 'react'
import { Props } from '@headlessui/react/dist/types'
import { HasDisplayName, RefProp } from '@headlessui/react/dist/utils/render'
import { Editor } from '@tiptap/react'
import { useEvent } from '@/hooks/useEvent'
import { forwardRefWithAs, uirender } from '@/ui/render'
import { fieldKeyName } from './extension/variable/VariableSuggestions'
import { CalendarLink } from './CalendarLink'
import { useActions, useData } from './Context'
import { EditorMode } from './reducer'

const UnsubscribeURLVariable = '{{UnsubscribeURL}}'

export const DEFAULT_TOOLBAR_TAG = Fragment
export type ToolbarRenderPropArg = { mode: EditorMode }
export type ToolbarProps<TTag extends ElementType> = Props<TTag, ToolbarRenderPropArg, never>

function ToolbarFn<TTag extends ElementType = typeof DEFAULT_TOOLBAR_TAG>(
  props: ToolbarProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { ...theirProps } = props
  const { editorMode, editor } = useData()
  const slot = { mode: editorMode }
  const ourProps = { ref }
  if (editor && !editor.isEditable) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_TOOLBAR_TAG })
}

export const DEFAULT_GENERIC_ITEM_TAG = 'button' as const
export type GenericItemRenderPropArg = { active: boolean }
export type GenericItemProps<TTag extends ElementType> = Props<
  TTag,
  GenericItemRenderPropArg,
  never,
  {
    type?: 'button' | 'submit' | 'reset' | undefined
  }
>

function BoldItem<TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
  props: GenericItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { type = 'button', ...theirProps } = props
  const { editor, editorMode, preview } = useData()
  const onClick = useEvent(() => editor?.chain().focus().toggleBold().run())
  const slot = { active: editor?.isActive('bold') ?? false }
  const ourProps = { ref, onClick, type }
  if (editorMode === 'subject' || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_GENERIC_ITEM_TAG })
}

function UnderlineItem<TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
  props: GenericItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { type = 'button', ...theirProps } = props
  const { editor, editorMode, preview } = useData()
  const onClick = useEvent(() => editor?.chain().focus().toggleUnderline().run())
  const slot = { active: editor?.isActive('underline') ?? false }
  const ourProps = { ref, onClick, type }
  if (editorMode === 'subject' || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_GENERIC_ITEM_TAG })
}

function ItalicItem<TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
  props: GenericItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { type = 'button', ...theirProps } = props
  const { editor, editorMode, preview } = useData()
  const onClick = useEvent(() => editor?.chain().focus().toggleItalic().run())
  const slot = { active: editor?.isActive('italic') ?? false }
  const ourProps = { ref, onClick, type }
  if (editorMode === 'subject' || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_GENERIC_ITEM_TAG })
}

function TextStrikethroughItem<TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
  props: GenericItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { type = 'button', ...theirProps } = props
  const { editor, editorMode, preview } = useData()
  const onClick = useEvent(() => editor?.chain().focus().toggleStrike().run())
  const slot = { active: editor?.isActive('strike') ?? false }
  const ourProps = { ref, onClick, type }
  if (editorMode === 'subject' || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_GENERIC_ITEM_TAG })
}

function CodeItem<TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
  props: GenericItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { type = 'button', ...theirProps } = props
  const { editor, editorMode, preview } = useData()
  const onClick = useEvent(() => editor?.chain().focus().toggleCode().run())
  const slot = { active: editor?.isActive('code') ?? false }
  const ourProps = { ref, onClick, type }
  if (editorMode === 'subject' || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_GENERIC_ITEM_TAG })
}

function AlignLeftItem<TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
  props: GenericItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { type = 'button', ...theirProps } = props
  const { editor, editorMode, preview } = useData()
  const onClick = useEvent(() => editor?.chain().focus().setTextAlign('left').run())
  const slot = { active: editor?.isActive({ textAlign: 'left' }) ?? false }
  const ourProps = { ref, onClick, type }
  if (editorMode === 'subject' || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_GENERIC_ITEM_TAG })
}

function AlignCenterItem<TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
  props: GenericItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { type = 'button', ...theirProps } = props
  const { editor, editorMode, preview } = useData()
  const onClick = useEvent(() => editor?.chain().focus().setTextAlign('center').run())
  const slot = { active: editor?.isActive({ textAlign: 'center' }) ?? false }
  const ourProps = { ref, onClick, type }
  if (editorMode === 'subject' || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_GENERIC_ITEM_TAG })
}

function AlignRightItem<TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
  props: GenericItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { type = 'button', ...theirProps } = props
  const { editor, editorMode, preview } = useData()
  const onClick = useEvent(() => editor?.chain().focus().setTextAlign('right').run())
  const slot = { active: editor?.isActive({ textAlign: 'right' }) ?? false }
  const ourProps = { ref, onClick, type }
  if (editorMode === 'subject' || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_GENERIC_ITEM_TAG })
}

function AlignJustifyItem<TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
  props: GenericItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { type = 'button', ...theirProps } = props
  const { editor, editorMode, preview } = useData()
  const onClick = useEvent(() => editor?.chain().focus().setTextAlign('justify').run())
  const slot = { active: editor?.isActive({ textAlign: 'justify' }) ?? false }
  const ourProps = { ref, onClick, type }
  if (editorMode === 'subject' || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_GENERIC_ITEM_TAG })
}

function ListUnorderedItem<TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
  props: GenericItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { type = 'button', ...theirProps } = props
  const { editor, editorMode, preview } = useData()
  const onClick = useEvent(() => editor?.chain().focus().toggleBulletList().run())
  const slot = { active: editor?.isActive('bulletList') ?? false }
  const ourProps = { ref, onClick, type }
  if (editorMode === 'subject' || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_GENERIC_ITEM_TAG })
}

function ListOrderedItem<TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
  props: GenericItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { type = 'button', ...theirProps } = props
  const { editor, editorMode, preview } = useData()
  const onClick = useEvent(() => editor?.chain().focus().toggleOrderedList().run())
  const slot = { active: editor?.isActive('orderedList') ?? false }
  const ourProps = { ref, onClick, type }
  if (editorMode === 'subject' || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_GENERIC_ITEM_TAG })
}

function CodeBlockItem<TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
  props: GenericItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { type = 'button', ...theirProps } = props
  const { editor, editorMode, preview } = useData()
  const onClick = useEvent(() => editor?.chain().focus().toggleCodeBlock().run())
  const slot = { active: editor?.isActive('codeBlock') ?? false }
  const ourProps = { ref, onClick, type }
  if (editorMode === 'subject' || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_GENERIC_ITEM_TAG })
}

function SeparatorItem<TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
  props: GenericItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { type = 'button', ...theirProps } = props
  const { editor, editorMode, preview } = useData()
  const onClick = useEvent(() => editor?.chain().focus().setHorizontalRule().run())
  const slot = { active: editor?.isActive('separator') ?? false }
  const ourProps = { ref, onClick, type }
  if (editorMode === 'subject' || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_GENERIC_ITEM_TAG })
}

function PaletteItem<TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
  props: GenericItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  // color picker is a special type of toolbar item, because it requires a hidden input
  // that is opened by clicking the color picker icon, this is why it's handled separately
  const { type = 'button', ...theirProps } = props
  const colorInputRef = useRef<HTMLInputElement>(null)
  const { editor, editorMode, preview } = useData()
  const onChange = useEvent((e) => editor?.chain().focus().setColor(e.target.value).run())
  const onClick = useEvent(() => colorInputRef.current?.click())
  const slot = { active: editor?.isActive('font-color') ?? false }
  const ourProps = { ref, onClick, type }
  if (editorMode === 'subject' || preview) return null
  return (
    <>
      <input
        ref={colorInputRef}
        type="color"
        onChange={onChange}
        style={{
          width: 0,
          height: 0,
          visibility: 'hidden',
        }}
      />
      {uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_GENERIC_ITEM_TAG })}
    </>
  )
}

function ImageItem<TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
  props: GenericItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { type = 'button', ...theirProps } = props
  const { editor, editorMode, preview } = useData()
  const onClick = useEvent(() => {
    const url = window.prompt('URL', '')
    if (url === null) return
    editor?.chain().focus().setImage({ src: url }).run()
  })
  const slot = { active: false }
  const ourProps = { ref, onClick, type }
  if (editorMode === 'subject' || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_GENERIC_ITEM_TAG })
}

function FormatClearItem<TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
  props: GenericItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { type = 'button', ...theirProps } = props
  const { editor, editorMode, preview } = useData()
  const onClick = useEvent(() => editor?.chain().focus().clearNodes().unsetAllMarks().run())
  const slot = { active: false }
  const ourProps = { ref, onClick, type }
  if (editorMode === 'subject' || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_GENERIC_ITEM_TAG })
}

function LinkItem<TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
  props: GenericItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  // link is a special type of toolbar item, because it requires a popup prompt
  const { type = 'button', ...theirProps } = props
  const { editor, editorMode, preview } = useData()
  const onClick = useEvent(() => {
    if (editor === null) return

    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)
    if (url === null) return

    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()
    } else {
      editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
    }
  })

  const slot = { active: editor?.isActive('link') ?? false }
  const ourProps = { ref, onClick, type }
  if (editorMode === 'subject' || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_GENERIC_ITEM_TAG })
}

function UnlinkItem<TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
  props: GenericItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { type = 'button', ...theirProps } = props
  const { editor, editorMode, preview } = useData()
  const onClick = useEvent(() => editor?.chain().focus().unsetLink().run())
  const slot = { active: (!editor?.isEmpty && editor?.isActive('unsetlink')) ?? false }
  const ourProps = { ref, onClick, type }
  if (editorMode === 'subject' || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_GENERIC_ITEM_TAG })
}

const DEFAULT_EMOJI_ITEM_TAG = Fragment
type EmojiItemRenderPropArg = {
  insert: (emoji: string) => void
}

type ToolbarEmojiItemProps<TTag extends ElementType> = Props<TTag, EmojiItemRenderPropArg, never>

function EmojiItem<TTag extends ElementType = typeof DEFAULT_EMOJI_ITEM_TAG>(
  props: ToolbarEmojiItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { ...theirProps } = props
  const { editor, preview } = useData()
  const insert = useEvent((emoji: string) => editor?.commands.insertContent(emoji))
  const slot = { insert }
  const ourProps = { ref }
  if (preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_EMOJI_ITEM_TAG })
}

export const DEFAULT_VARIABLE_ITEM_TAG = Fragment
export type VariableItemRenderPropArg = {
  insert: (variable: string) => void
  variables: string[]
}

export type VariableItemProps<TTag extends ElementType> = Props<TTag, VariableItemRenderPropArg, never>

function VariableItem<TTag extends ElementType = typeof DEFAULT_VARIABLE_ITEM_TAG>(
  props: VariableItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { ...theirProps } = props
  const { editor, variables, preview } = useData()
  const insert = useEvent((variable: string) =>
    editor?.commands.insertContent(
      `<span data-type="${fieldKeyName}" class="${fieldKeyName}" data-id="${variable}}}" data-string="${variable}">{{${variable}}}</span>`,
    ),
  )
  const slot = { insert, variables: Object.keys(variables) }
  const ourProps = { ref }
  if (Object.keys(variables).length === 0 || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_VARIABLE_ITEM_TAG })
}

export const DEFAULT_CALENDER_LINK_ITEM_TAG = Fragment
export type CalendarLinkItemRenderPropArg = {
  insert: (meetingType: CalendarLink) => void
  calendarLinks: CalendarLink[]
}
export type CalendarLinksItemsProps<TTag extends ElementType> = Props<TTag, CalendarLinkItemRenderPropArg, never>

function CalendarLinksItem<TTag extends ElementType = typeof DEFAULT_CALENDER_LINK_ITEM_TAG>(
  props: CalendarLinksItemsProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { ...theirProps } = props
  const { editor, preview, calendarLinks } = useData()
  const insert = ({ description, url }: CalendarLink) => {
    editor?.commands.insertContent(`<a href="${url}">${description}</a>`)
  }
  const slot = { insert, calendarLinks }
  const ourProps = { ref }
  if (calendarLinks.length === 0 || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_CALENDER_LINK_ITEM_TAG })
}

function UnsubscribeLinkItem<TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
  props: GenericItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  // NOTE: this is very product specific, and should be refactored and removed
  // from the generic toolbar at some point
  const { type = 'button', ...theirProps } = props
  const { editor, editorMode, preview } = useData()
  const onClick = useEvent(() => editor?.chain().focus().toggleUnsubscribeLink({ href: UnsubscribeURLVariable }).run())
  const slot = { active: editor?.isActive('unsubscribeLink') ?? false }
  const ourProps = { ref, onClick, type }
  if (editorMode === 'subject' || preview) return null
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_GENERIC_ITEM_TAG })
}

export const DEFAULT_CUSTOM_ITEM_TAG = Fragment
export type CustomItemRenderPropArg = {
  editor: Editor | null
  editorMode: EditorMode
  preview: boolean
  togglePreview: () => void
}
export type CustomItemProps<TTag extends ElementType> = Props<TTag, CustomItemRenderPropArg, never>

function CustomItem<TTag extends ElementType = typeof DEFAULT_CUSTOM_ITEM_TAG>(
  props: CustomItemProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { ...theirProps } = props
  const { editor, editorMode, preview } = useData()
  const { togglePreview } = useActions()
  const slot = { editor, editorMode, preview, togglePreview }
  const ourProps = { ref }
  return uirender({ ourProps, theirProps, slot, defaultTag: DEFAULT_CUSTOM_ITEM_TAG })
}

export interface _internal_Toolbar extends HasDisplayName {
  <TTag extends ElementType = typeof DEFAULT_TOOLBAR_TAG>(
    props: ToolbarProps<TTag> & RefProp<typeof ToolbarFn>,
  ): JSX.Element
}
const ToolbarRoot = forwardRefWithAs(ToolbarFn) as unknown as _internal_Toolbar

export interface _internal_GenericItem extends HasDisplayName {
  <TTag extends ElementType = typeof DEFAULT_GENERIC_ITEM_TAG>(
    props: GenericItemProps<TTag> & RefProp<typeof BoldItem>,
  ): JSX.Element
}

export const Toolbar = Object.assign(ToolbarRoot, {
  Bold: forwardRefWithAs(BoldItem) as unknown as _internal_GenericItem,
  Underline: forwardRefWithAs(UnderlineItem),
  Italic: forwardRefWithAs(ItalicItem),
  UnsubscribeLink: forwardRefWithAs(UnsubscribeLinkItem),
  TextStrikethrough: forwardRefWithAs(TextStrikethroughItem),
  Code: forwardRefWithAs(CodeItem),
  CodeBlock: forwardRefWithAs(CodeBlockItem),
  AlignLeft: forwardRefWithAs(AlignLeftItem),
  AlignCenter: forwardRefWithAs(AlignCenterItem),
  AlignRight: forwardRefWithAs(AlignRightItem),
  AlignJustify: forwardRefWithAs(AlignJustifyItem),
  ListUnordered: forwardRefWithAs(ListUnorderedItem),
  ListOrdered: forwardRefWithAs(ListOrderedItem),
  Separator: forwardRefWithAs(SeparatorItem),
  Palette: forwardRefWithAs(PaletteItem),
  Image: forwardRefWithAs(ImageItem),
  FormatClear: forwardRefWithAs(FormatClearItem),
  Link: forwardRefWithAs(LinkItem),
  Unlink: forwardRefWithAs(UnlinkItem),
  Emoji: forwardRefWithAs(EmojiItem),
  CalendarLinks: forwardRefWithAs(CalendarLinksItem),
  Variable: forwardRefWithAs(VariableItem),
  Custom: forwardRefWithAs(CustomItem),
})
