import { useState } from 'react'
import { Password } from '@/api/core'
import { usePasswordDelete, usePasswordOTPCode } from '@/api/vault'
import { copy } from '@/lib/utils'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Text } from '@/ui'
import { DangerButton } from '@/ui/Button/v2'
import { ConfirmationDialog } from '../Contacts/ConfirmationDialog'
import { OneTimePassword } from './OneTimePassword'
import { PasswordEdit } from './PasswordEdit'

type PasswordItemProps = {
  password: Password
}

export function PasswordItem(props: PasswordItemProps) {
  const { password } = props
  const [state, setState] = useState<'edit' | 'show'>('show')
  if (state === 'show') {
    return <Show password={password} onEdit={() => setState('edit')} />
  }
  return <PasswordEdit password={password} onClose={() => setState('show')} />
}

type ShowProps = {
  password: Password
  onEdit: () => void
}

function Show(props: ShowProps) {
  const { password, onEdit } = props
  const { data } = usePasswordOTPCode(password.id, { enabled: !password.permissions.canGenerateOTPCode.deny })
  const passwordDelete = usePasswordDelete(password.id)
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false)
  const toast = useToast()

  const onDelete = () => {
    passwordDelete.mutate(undefined, {
      onError: () => {
        toast.createToast({ message: 'Failed to delete password', error: true })
      },
    })
    setDeleteConfirmationDialog(false)
  }
  const showOTP = !password.permissions.canGenerateOTPCode.deny && data?.data

  return (
    <>
      <ConfirmationDialog
        open={deleteConfirmationDialog}
        onClose={() => setDeleteConfirmationDialog(false)}
        onConfirm={onDelete}
        message={`You are about to delete password ${password.name}.`}
        data-testid="password-vault-view-delete-confirmation-dialog"
      />

      <div className="w-full text-left">
        <div className="mb-2 flex items-center justify-stretch gap-2">
          <Text variant="title" className="w-96 truncate">
            {password.name}
          </Text>
          <div className="flex gap-2">
            <Button variant="accent" onClick={onEdit} data-testid="password-vault-view-edit-btn">
              Edit
            </Button>
            <DangerButton
              variant="accent"
              onClick={() => setDeleteConfirmationDialog(true)}
              data-testid="password-vault-view-delete-btn"
            >
              Delete
            </DangerButton>
          </div>
        </div>
        <div className="my-1">
          <CopiableText value={password.username} data-testid="password-vault-view-username-copy">
            <Text variant="subtext" className="font-semibold">
              username:
            </Text>
            <Text>{password.username}</Text>
          </CopiableText>
        </div>
        <div className="my-1">
          <CopiableText value={password.password} data-testid="password-vault-view-password-copy">
            <Text variant="subtext" className="font-semibold">
              password:
            </Text>
            <Text>***************</Text>
          </CopiableText>
        </div>
        <div className="my-1">
          <CopiableText value={password.website} data-testid="password-vault-view-website-copy">
            <Text variant="subtext" className="font-semibold">
              website:
            </Text>
            <Text>{password.website}</Text>
          </CopiableText>
        </div>
        {showOTP && (
          <div className="my-1">
            <CopiableText value={data.data.otpCode} data-testid="password-vault-view-totp-secret-copy">
              <OneTimePassword otpCode={data.data.otpCode} expiresAt={data.data.expiresAt} />
            </CopiableText>
          </div>
        )}
        <Text variant="subtext" className="font-semibold">
          notes:
        </Text>
        <Text className="whitespace-pre-wrap" data-testid="password-vault-view-notes-text">
          {password.notes}
        </Text>
      </div>
    </>
  )
}

type CopiableTextProps = {
  value: string
  'data-testid'?: string
}

function CopiableText(props: React.PropsWithChildren<CopiableTextProps>) {
  const { value, children } = props
  const toast = useToast()
  const onCopy = async () => {
    if (!value) return

    const copied = await copy(value)
    if (copied) {
      toast.createToast({ message: 'Copied' })
    } else {
      toast.createToast({ message: 'Failed to copy clipboard', error: true })
    }
  }

  return (
    // eslint-disable-next-line
    <div onClick={onCopy} className="group relative cursor-pointer hover:bg-gray-50" data-testid={props['data-testid']}>
      {children}
      <Text className="invisible absolute inset-y-3 right-2 text-gray-600 group-hover:visible">Copy</Text>
    </div>
  )
}
