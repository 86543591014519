import { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { DateTime } from 'luxon'
import { CaretUpDown, MagnifyingGlass } from '@phosphor-icons/react'
import { useBookings, useMeetingTypes } from '@/api/meetings'
import { MeetingCategoryToText } from '@/api/text/meetings'
import { BOOKING_URL } from '@/config'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Input, Listbox, RadioGroup } from '@/ui'
import { MeetingDropMenu } from './MeetingDropMenu'

export function Meetings() {
  const { data } = useMeetingTypes()
  const toast = useToast()
  const meetings = data?.data
  const navigate = useNavigate()
  const [bookingParams, setBookingParams] = useState<{
    time: 'past' | 'upcoming'
    q?: string
    types: { id: string; name: string }[]
  }>({
    time: 'upcoming',
    types: [],
  })

  return (
    <div className="mx-auto max-w-3xl pb-28">
      <header className="mb-6 flex h-5 items-center justify-between">
        <h2 className="content-md-medium">Host a meeting</h2>
        <Button variant={'text'} onClick={() => navigate('/meetings/new')}>
          New meeting type
        </Button>
      </header>
      <ul className="grid grid-cols-2 gap-x-3">
        {meetings?.map((meeting) => (
          <NavLink
            key={meeting.short_name}
            to={`/meetings/${meeting.id}/edit`}
            className="group mb-3  block rounded-lg border border-light px-4 py-3"
          >
            <li key={meeting.short_name}>
              <h3 className="label-medium">{meeting.description}</h3>
              <span className="text-medium">
                {meeting.category && MeetingCategoryToText[meeting.category]}&nbsp;&#8226;&nbsp;
                {meeting.duration} min
              </span>
              <div className="invisible mt-2 flex items-center justify-items-start group-hover:visible">
                <Button
                  variant="text"
                  className="px-0 font-normal"
                  onClick={(e) => {
                    e.preventDefault()
                    window.open(`${BOOKING_URL}/${meeting.company_short_name}/${meeting.short_name}`, '_blank')
                  }}
                >
                  View page
                </Button>
                <Button
                  variant="text"
                  className="ml-3 px-0 font-normal"
                  onClick={(e) => {
                    e.preventDefault()
                    navigator.clipboard
                      .writeText(`${BOOKING_URL}/${meeting.company_short_name}/${meeting.short_name}`)
                      .then(() => toast.createToast({ message: 'Copied to clipboard' }))
                      .catch(() => toast.createToast({ message: 'Failed to copy to clipboard', error: true }))
                  }}
                >
                  Copy link
                </Button>
              </div>
            </li>
          </NavLink>
        ))}
      </ul>
      <div className="space-y-8">
        <h2 className="content-md-medium mb-6 mt-12">My meetings</h2>
        <div className="flex">
          <div className="flex grow items-center gap-3">
            <Input
              value={bookingParams.q ?? ''}
              onChange={(e) => setBookingParams({ ...bookingParams, q: e.target.value })}
              placeholder=""
              icon={MagnifyingGlass}
              className="w-56"
            />
            <RadioGroup
              options={[
                { value: 'upcoming', label: 'Upcoming' },
                { value: 'past', label: 'Past' },
              ]}
              onChange={(e) => setBookingParams({ ...bookingParams, time: e as 'upcoming' | 'past' })}
              value={bookingParams.time}
            />
            <Listbox
              multiple
              value={bookingParams.types}
              onChange={(selected) => setBookingParams({ ...bookingParams, types: selected })}
            >
              <div className="relative">
                <Listbox.Button className="cursor-default">
                  {bookingParams.types.length === 0 || bookingParams.types.length === meetings?.length
                    ? 'All event types'
                    : 'Filtered'}
                  <CaretUpDown className="ml-1 size-5 text-gray-400" aria-hidden="true" />
                </Listbox.Button>
                <Listbox.Options>
                  {meetings?.map((m) => (
                    <Listbox.Option key={m.short_name} value={m}>
                      {m.description}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>
            </Listbox>
          </div>
        </div>
        <div>
          <Bookings
            time={bookingParams.time}
            q={bookingParams.q}
            typeFilters={bookingParams.types.map((type) => type.id)}
          />
        </div>
      </div>
    </div>
  )
}

type BookingProps = {
  time: 'past' | 'upcoming'
  q?: string
  typeFilters: string[]
}

function Bookings(props: BookingProps) {
  const { data, status } = useBookings(props.time, props.q, props.typeFilters.join(','))
  if (status === 'error') {
    return <div>Failed to load</div>
  }

  if (status === 'pending') {
    return <div className="skeleton mb-2 h-48 w-full" />
  }

  return (
    <div className="text-center">
      {data.data.length === 0 ? (
        <div>
          <span className="label font-medium">No {props.time} meetings</span>
          <div>
            Try some outbound with{' '}
            <NavLink to={'/campaigns'} className="text-accent">
              a new sequence?
            </NavLink>
          </div>
        </div>
      ) : (
        <div>
          {data.data.map((day) => {
            return (
              <div key={day.date}>
                <h3 className="label-medium border-b-1 my-4 border-b border-light pb-4">{formatDate(day.date)}</h3>
                <ul>
                  {day.meetings.map((meeting) => (
                    <li key={meeting.id} className="label flex gap-x-10 border-b border-light px-3 py-4">
                      <span>
                        {DateTime.fromISO(meeting.start_time).toLocaleString(DateTime.TIME_SIMPLE)}&nbsp;—&nbsp;
                        {DateTime.fromISO(meeting.end_time).toLocaleString(DateTime.TIME_SIMPLE)}
                      </span>

                      <div>
                        <span className="label-medium block">{meeting.description}</span>
                        <span className="label text-medium">
                          {meeting.meeting_type}&nbsp;&#8226;&nbsp;{meeting.meeting_duration} min&nbsp;&#8226;&nbsp;
                          {MeetingCategoryToText[meeting.category]}
                        </span>
                      </div>

                      <div className="ml-auto">
                        <MeetingDropMenu
                          videoPlayUrl={meeting.videoPlayUrl}
                          videoDownloadUrl={meeting.videoDownloadUrl}
                          transcriptDownloadUrl={meeting.transcriptDownloadUrl}
                          joinUrl={meeting.joinUrl}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

function formatDate(dateStr?: string) {
  const today = dayjs()
  const tomorrow = today.add(1, 'day')
  const date = dayjs(dateStr)

  if (date.isSame(today, 'day')) {
    return 'Today'
  }
  if (date.isSame(tomorrow, 'day')) {
    return 'Tomorrow'
  }
  return date.format('M/D/YY')
}
