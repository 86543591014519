import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Command } from 'cmdk'
import { Dialog, Transition } from '@headlessui/react'
import { WarningCircle } from '@phosphor-icons/react'
import { useCampaignCreate } from '@/api/campaigns'
import { useModKey } from '@/hooks'
import { useDisclosureV2 } from '@/hooks/useDisclosure'
import './styles.css'

export function Cmdk() {
  const navigate = useNavigate()
  const [opError, setOpError] = useState<Error | null>(null)
  const [open, , onClose, onToggle] = useDisclosureV2()
  const modKey = useModKey()
  const create = useCampaignCreate()

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && e[modKey]) {
        e.preventDefault()
        onToggle()
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  const onSelect = (path: string) => {
    navigate(path)
    onClose()
  }

  const onSequenceCreate = () => {
    create.mutate(undefined, {
      onSuccess: ({ data: { id } }) => {
        navigate(`/sequences/${id}`)
        onClose()
      },
      onError: (err) => {
        setOpError(err as Error)
      },
    })
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog open={open} onClose={onClose} className="relative z-10">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <>
                <Command>
                  <Command.Input data-autofocus className="mb-2 h-4 border border-solid border-light" />
                  <Command.List>
                    <Command.Empty>No results found.</Command.Empty>

                    <Command.Group heading="Sequences">
                      <Command.Item onSelect={() => onSelect('/sequences')}>Sequences</Command.Item>
                      <Command.Item onSelect={() => onSequenceCreate()}>Sequence Create</Command.Item>
                    </Command.Group>

                    <Command.Separator />

                    <Command.Group heading="navigation">
                      <Command.Item onSelect={() => onSelect('/superadmin')}>rift admin</Command.Item>
                      <Command.Item onSelect={() => onSelect('/superadmin')}>chameleon</Command.Item>
                      <Command.Item onSelect={() => onSelect('/contacts')}>Contacts</Command.Item>
                      <Command.Item onSelect={() => onSelect('/contacts/lists')}>Contact list</Command.Item>
                      <Command.Item onSelect={() => onSelect('/dialer')}>Dialer</Command.Item>
                      <Command.Item onSelect={() => onSelect('/meetings')}>Meetings</Command.Item>
                      <Command.Item onSelect={() => onSelect('/onboarding')}>Onboarding</Command.Item>
                      <Command.Item onSelect={() => onSelect('/vault')}>Password vault</Command.Item>
                      <Command.Item onSelect={() => onSelect('/inbox')}>Inbox</Command.Item>
                      <Command.Item onSelect={() => onSelect('/inbox/emails')}>Inbox / Emails</Command.Item>
                      <Command.Item onSelect={() => onSelect('/inbox/linkedin-messages')}>
                        Inbox / Linkedin
                      </Command.Item>
                      <Command.Item onSelect={() => onSelect('/inbox/calls')}>Inbox / Calls</Command.Item>
                      <Command.Item onSelect={() => onSelect('/inbox/done')}>Inbox / Done</Command.Item>
                      <Command.Item onSelect={() => onSelect('/inbox/calls')}>Manual calls</Command.Item>
                      <Command.Item onSelect={() => onSelect('/inbox/replies')}>Manual replies</Command.Item>

                      <Command.Item onSelect={() => onSelect('/settings/profile')}>Account / Profile</Command.Item>
                      <Command.Item onSelect={() => onSelect('/settings/signatures')}>Account / Signature</Command.Item>
                      <Command.Item onSelect={() => onSelect('/settings/availability')}>
                        Account / Availability
                      </Command.Item>
                      <Command.Item onSelect={() => onSelect('/settings/security')}>Account / Security</Command.Item>
                      <Command.Item onSelect={() => onSelect('/settings/linked-accounts')}>
                        Account / Linked accounts
                      </Command.Item>

                      <Command.Item onSelect={() => onSelect('/settings/company')}>Organization / Company</Command.Item>
                      <Command.Item onSelect={() => onSelect('/settings/members')}>Organization / Members</Command.Item>
                      <Command.Item onSelect={() => onSelect('/settings/senders')}>Organization / Senders</Command.Item>
                      <Command.Item onSelect={() => onSelect('/settings/replyto')}>
                        Organization / Reply-to
                      </Command.Item>
                      <Command.Item onSelect={() => onSelect('/settings/phone-numbers')}>
                        Organization / Phone numbers
                      </Command.Item>
                      <Command.Item onSelect={() => onSelect('/settings/no-send-dates')}>
                        Organization / No-send dates
                      </Command.Item>
                      <Command.Item onSelect={() => onSelect('/settings/integrations')}>
                        Organization / Integrations
                      </Command.Item>
                      <Command.Item onSelect={() => onSelect('/settings/integrations/salesforce')}>
                        Organization / Integrations / Salesforce
                      </Command.Item>
                      <Command.Item
                        onSelect={() => onSelect('/settings/integrations/salesforce/contacts-synchronization-debug')}
                      >
                        Organization / Integrations / Salesforce / Sync debug
                      </Command.Item>
                      <Command.Item onSelect={() => onSelect('/settings/integrations/hubspot')}>
                        Organization / Integrations / Hubspot
                      </Command.Item>
                    </Command.Group>
                  </Command.List>
                </Command>
                {opError && (
                  <p className="text-alert">
                    <WarningCircle className="inline size-4" /> {opError.message}
                  </p>
                )}
              </>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
