import { useState } from 'react'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { AccountEnrichment, AccountEnrichmentContact, GenerateApproachRequest } from '@/api/core'
import { useSequenceGenerateApproach } from '@/api/sequence_generation'
import { ButtonLoading, Input } from '@/ui'

export function ApproachForm({ enrichmentData }: { enrichmentData: AccountEnrichment }) {
  const initialFormData = {
    account: enrichmentData.account,
    contact1:
      enrichmentData.contacts.length > 0
        ? enrichmentData.contacts[0]
        : { firstName: '', lastName: '', email: '', title: '', photoUrl: '' },
    contact2:
      enrichmentData.contacts.length > 1
        ? enrichmentData.contacts[1]
        : { firstName: '', lastName: '', email: '', title: '', photoUrl: '' },
    contact3:
      enrichmentData.contacts.length > 2
        ? enrichmentData.contacts[2]
        : { firstName: '', lastName: '', email: '', title: '', photoUrl: '' },
    seller: {
      description: '',
      valuePropositions: '',
    },
  }
  const initialData = {
    account: enrichmentData.account,
    contacts: enrichmentData.contacts,
    seller: {
      description: '',
      valuePropositions: [],
    },
  }
  const [generateApproachData, setGenerateApproachData] = useState<GenerateApproachRequest>(initialData)

  const { data, isLoading, isSuccess, isError } = useSequenceGenerateApproach(generateApproachData)

  type GenerateApproachFormData = {
    account: {
      name: string
      description: string
    }
    seller: {
      description: string
      valuePropositions: string
    }
    contact1: AccountEnrichmentContact
    contact2: AccountEnrichmentContact
    contact3: AccountEnrichmentContact
  }

  const schema = yup.object().shape({
    account: yup.object().shape({
      name: yup.string().required(),
      description: yup.string().required(),
    }),
    seller: yup.object().shape({
      description: yup.string().required(),
      valuePropositions: yup.string().required(),
    }),
    contact1: yup.object().shape({
      email: yup.string().email().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      title: yup.string().required(),
      photoUrl: yup.string().url().required(),
    }),
    contact2: yup.object().shape({
      email: yup.string().email().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      title: yup.string().required(),
      photoUrl: yup.string().url().required(),
    }),
    contact3: yup.object().shape({
      email: yup.string().email().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      title: yup.string().required(),
      photoUrl: yup.string().url().required(),
    }),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<GenerateApproachFormData>({
    resolver: yupResolver(schema),
    defaultValues: initialFormData,
  })

  function onGenerateApproach(form: GenerateApproachFormData) {
    const contacts = [form.contact1, form.contact2, form.contact3].filter((contact) => contact.email != '')
    setGenerateApproachData({
      account: {
        name: form.account.name,
        description: form.account.description,
      },
      contacts,
      seller: {
        description: form.seller.description,
        valuePropositions: form.seller.valuePropositions.split(','),
      },
    })
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onGenerateApproach)}>
        <div className="flex flex-col items-center justify-end">
          <div className="w-full">
            <Input
              name="account.name"
              id="account.name"
              type="text"
              label="Account name"
              placeholder=""
              error={errors.account?.name}
              registration={register('account.name')}
              autoComplete="off"
            />
          </div>
          <div className="w-full">
            <Input
              name="account.description"
              id="account.description"
              type="text"
              label="Account description"
              placeholder=""
              error={errors.account?.description}
              registration={register('account.description')}
            />
          </div>
          <div className="w-full">
            <Input
              name="seller.description"
              id="seller.description"
              type="text"
              label="Seller description"
              placeholder=""
              error={errors.seller?.description}
              registration={register('seller.description')}
            />
          </div>
          <div className="w-full">
            <Input
              name="seller.valuePropositions"
              id="seller.valuePropositions"
              type="text"
              label="Seller Value Propositions"
              placeholder=""
              error={errors.seller?.valuePropositions}
              registration={register('seller.valuePropositions')}
            />
          </div>
          {/* <!-- CONTACT 1 --> */}
          <div className="w-full">
            <Input
              name="contact1.email"
              id="contact1.email"
              type="text"
              label="Contact 1 Email"
              placeholder=""
              error={errors.contact1?.email}
              registration={register('contact1.email')}
            />
          </div>
          <div className="w-full">
            <Input
              name="contact1.firstName"
              id="contact1.firstName"
              type="text"
              label="Contact 1 First Name"
              placeholder=""
              error={errors.contact1?.firstName}
              registration={register('contact1.firstName')}
            />
          </div>
          <div className="w-full">
            <Input
              name="contact1.lastName"
              id="contact1.lastName"
              type="text"
              label="Contact 1 Last Name"
              placeholder=""
              error={errors.contact1?.lastName}
              registration={register('contact1.lastName')}
            />
          </div>
          <div className="w-full">
            <Input
              name="contact1.title"
              id="contact1.title"
              type="text"
              label="Contact 1 Job Title"
              placeholder=""
              error={errors.contact1?.title}
              registration={register('contact1.title')}
            />
          </div>
          <div className="w-full">
            <Input
              name="contact1.photoUrl"
              id="contact1.photoUrl"
              type="text"
              label="Contact 1 Photo URL"
              placeholder=""
              error={errors.contact1?.photoUrl}
              registration={register('contact1.photoUrl')}
            />
          </div>
          {/* <!-- CONTACT 2 --> */}
          <div className="w-full">
            <Input
              name="contact2.email"
              id="contact2.email"
              type="text"
              label="Contact 2 Email"
              placeholder=""
              error={errors.contact2?.email}
              registration={register('contact2.email')}
            />
          </div>
          <div className="w-full">
            <Input
              name="contact2.firstName"
              id="contact2.firstName"
              type="text"
              label="Contact 2 First Name"
              placeholder=""
              error={errors.contact2?.firstName}
              registration={register('contact2.firstName')}
            />
          </div>
          <div className="w-full">
            <Input
              name="contact2.lastName"
              id="contact2.lastName"
              type="text"
              label="Contact 2 Last Name"
              placeholder=""
              error={errors.contact2?.lastName}
              registration={register('contact2.lastName')}
            />
          </div>
          <div className="w-full">
            <Input
              name="contact2.title"
              id="contact2.title"
              type="text"
              label="Contact 2 Job Title"
              placeholder=""
              error={errors.contact2?.title}
              registration={register('contact2.title')}
            />
          </div>
          <div className="w-full">
            <Input
              name="contact2.photoUrl"
              id="contact2.photoUrl"
              type="text"
              label="Contact 2 Photo URL"
              placeholder=""
              error={errors.contact2?.photoUrl}
              registration={register('contact2.photoUrl')}
            />
          </div>
          {/* <!-- CONTACT 3 --> */}
          <div className="w-full">
            <Input
              name="contact3.email"
              id="contact3.email"
              type="text"
              label="Contact 3 Email"
              placeholder=""
              error={errors.contact3?.email}
              registration={register('contact3.email')}
            />
          </div>
          <div className="w-full">
            <Input
              name="contact3.firstName"
              id="contact3.firstName"
              type="text"
              label="Contact 3 First Name"
              placeholder=""
              error={errors.contact3?.firstName}
              registration={register('contact3.firstName')}
            />
          </div>
          <div className="w-full">
            <Input
              name="contact3.lastName"
              id="contact3.lastName"
              type="text"
              label="Contact 3 Last Name"
              placeholder=""
              error={errors.contact3?.lastName}
              registration={register('contact3.lastName')}
            />
          </div>
          <div className="w-full">
            <Input
              name="contact3.title"
              id="contact3.title"
              type="text"
              label="Contact 3 Job Title"
              placeholder=""
              error={errors.contact3?.title}
              registration={register('contact3.title')}
            />
          </div>
          <div className="w-full">
            <Input
              name="contact3.photoUrl"
              id="contact3.photoUrl"
              type="text"
              label="Contact 3 Photo URL"
              placeholder=""
              error={errors.contact3?.photoUrl}
              registration={register('contact3.photoUrl')}
            />
          </div>
        </div>
        <div className="box-border flex w-full justify-end gap-2 bg-white py-4">
          <ButtonLoading type="submit" variant="accent" isLoading={isLoading}>
            Generate Approach
          </ButtonLoading>
        </div>
      </form>
      {data && isSuccess && (
        <div>
          {data.data.overviews
            .filter(({ contact }) => contact !== undefined && contact !== null)
            .map(({ contact, touchpoints }, index) => (
              <div key={index}>
                <dl>
                  <dt className="font-semibold">Contact Name:</dt>
                  <dd>
                    {contact.firstName} {contact.lastName}
                  </dd>
                  <dt className="font-semibold">Job Title:</dt>
                  <dd>{contact.title}</dd>
                  <dt className="font-semibold">Email:</dt>
                  <dd>{contact.email}</dd>
                  <dt className="font-semibold">Photo URL:</dt>
                  <dd>{contact.photoUrl}</dd>
                  {Object.keys(touchpoints)
                    .sort((a, b) => parseInt(a) - parseInt(b))
                    .map((touchpointKey) => (
                      <React.Fragment key={touchpointKey}>
                        <dt className="font-semibold">Day {touchpointKey}:</dt>
                        <dd>{touchpoints[touchpointKey].join(', ')}</dd>
                      </React.Fragment>
                    ))}
                </dl>
              </div>
            ))}
          {isError && <div>Error generating approach</div>}
        </div>
      )}
    </div>
  )
}
