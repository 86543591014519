export const HolidayNames = {
  newYear: `New Years Day`,
  mlkDay: `Martin Luther King Jr. Day`,
  memorialDay: `Memorial Day`,
  juneteenth: `Juneteenth`,
  independenceDay: `Independence Day`,
  laborDay: `Labor Day`,
  veteransDay: `Veterans Day`,
  thanksgivingDay: `Thanksgiving Day`,
  christmasDay: `Christmas Day`,
}
