import { useMemo, useState } from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useCampaignSchedule } from '@/api/campaigns'
import { Campaign, CampaignScheduleExampleItem, CampaignScheduleItem } from '@/api/core'
import { dateFormat, Unix } from '@/lib/date'
import { Switch } from '@/ui'

type ScheduleGraphProps = {
  campaign: Campaign
}

export function ScheduleGraph(props: ScheduleGraphProps) {
  const { campaign } = props
  const { data, status } = useCampaignSchedule(campaign.id)
  const [hideZero, setHideZero] = useState(true)

  const curData = useMemo(() => {
    if (status !== 'success') {
      return []
    }

    const d = data.data.byDate
    const d1 = d.map((item) => ({
      ...item,
      date: dateFormat(Unix(item.date)),
    }))

    if (hideZero) {
      return d1.filter((item) => item.scheduled !== 0 || item.done !== 0 || item.unenrolled !== 0)
    }
    return d1
  }, [hideZero, data])

  if (status !== 'success') {
    return null
  }

  const rows = data.data.byDate.filter((item) => item.done !== 0 || item.scheduled !== 0 || item.unenrolled !== 0)

  const options: Highcharts.Options = {
    title: { text: 'schedule graph' },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: rows.map((v) => dateFormat(Unix(v.date))),
    },

    yAxis: {
      min: 0,
      minPadding: 0,
      maxPadding: 0,
    },

    tooltip: {
      pointFormat: `{series.name}: {point.y}<br/>Total: {point.stackTotal}`,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [
      {
        name: 'scheduled',
        type: 'column',
        data: rows.map((v) => v.scheduled),
        color: '#168aef',
      },
      {
        name: 'unenrolled',
        type: 'column',
        data: rows.map((v) => v.unenrolled),
        color: '#ff7377',
      },
      {
        name: 'sent',
        type: 'column',
        data: rows.map((v) => v.done),
        color: '#66cc99',
      },
    ],
  }

  return (
    <>
      <ScheduleExamplesPanel data={data.data.scheduleExamples} />
      <div className="flex w-full justify-end">
        <div className="mb-4 flex w-72 items-center">
          <span className="mr-2 font-medium">Hide zeros:</span>
          <div>
            <Switch checked={hideZero} onChange={setHideZero} />
          </div>
        </div>
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <ScheduleTable data={curData} />
    </>
  )
}

type SummaryTableProps = {
  data: (Omit<CampaignScheduleItem, 'date'> & { date: string })[]
}

function ScheduleTable(props: SummaryTableProps) {
  const { data } = props

  const columnHelper = createColumnHelper<(typeof data)[number]>()
  const columns = useMemo(
    () => [
      columnHelper.accessor('date', {
        header: 'Date',
      }),
      columnHelper.accessor('done', {
        header: 'Sent',

        cell: (info) => {
          const value = info.getValue()
          return <>{value === 0 ? value : <span className="text-[#66cc99]">{value}</span>}</>
        },
      }),
      columnHelper.accessor('scheduled', {
        header: 'Scheduled',
        cell: (info) => {
          const value = info.getValue()
          return <>{value === 0 ? value : <span className="text-rift-blue-500">{value}</span>}</>
        },
      }),
      columnHelper.accessor('unenrolled', {
        header: 'Unenrolled',
        cell: (info) => {
          const value = info.getValue()
          return <>{value === 0 ? value : <span className="text-[#FF7377]">{value}</span>}</>
        },
      }),
    ],
    [],
  )

  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    columns,
    data,
  })
  return (
    <table className="border-gray w-full border-t-2">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id} className="w-16 py-4 font-medium">
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="pb-4 pt-4 text-center">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

type ScheduleExamplesPanelProps = {
  data: CampaignScheduleExampleItem[]
}

function ScheduleExamplesPanel(props: ScheduleExamplesPanelProps) {
  const { data } = props

  return (
    <div className="flex w-full flex-col pt-6">
      <div className="mb-4 text-lg font-medium">Schedule Examples</div>
      <div className="flex max-h-[196px] w-full flex-col  gap-4 overflow-auto">
        {data.map((item, i) => (
          <div key={i}>
            <div className="text-md font-medium">{new Date(item.date * 1000).toString()}</div>
            <p>
              <span className="text-sm">{item.from}</span> ⟶ <span className="text-sm">{item.to}</span>
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}
