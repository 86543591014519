import React from 'react'
import clsx from 'clsx'
import { Text } from '@/ui'

type KVListProps = {
  data: Record<string, string | React.ReactNode>
  errors?: string[]
}

export function KVList(props: KVListProps) {
  const errors = props.errors || ([] as string[])
  return (
    <div className="flex flex-col gap-y-4">
      {Object.entries(props.data).map(([key, value], idx) => {
        return (
          <div key={idx} className={clsx('flex items-center gap-2')}>
            <Text className={clsx(errors.includes(key) ? 'text-rift-red-800' : 'text-medium')}>{key}</Text>
            <Text className="ml-auto text-dusk">
              {typeof value === 'string' || value === null ? value || '—' : <>{value}</>}
            </Text>
          </div>
        )
      })}
    </div>
  )
}
