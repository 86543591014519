import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  ApiError,
  CompanyOffDays,
  DefaultService,
  PatchCompanyOffDay,
  PatchCompanyOffDayEnable,
  PostCompanyOffDays,
} from '@/api/core'

export const queryKey = {
  base: () => ['offdays'] as const,
}

export function useCompanyOffDays() {
  return useQuery<{ data: CompanyOffDays }, ApiError>({
    queryKey: queryKey.base(),
    queryFn: () => DefaultService.getCompanyOffDays(),
  })
}

export function useCompanyOffDayCreate() {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.base() })
    },
    mutationFn: (body: PostCompanyOffDays) => DefaultService.postCompanyOffDays(body),
  })
}

export function useCompanyOffDayUpdate(offDayId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.base() })
    },
    mutationFn: (body: PatchCompanyOffDay) => DefaultService.patchCompanyOffDays(offDayId, body),
  })
}

export function useCompanyOffDayDelete() {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.base() })
    },
    mutationFn: (offDayId: string) => DefaultService.deleteCompanyOffDays(offDayId),
  })
}

export function useCompanyOffDayEnable() {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.base() })
    },
    mutationFn: (body: PatchCompanyOffDayEnable & { id: string }) => {
      const { id, ...rest } = body
      return DefaultService.patchCompanyOffDaysEnable(id, rest)
    },
  })
}
