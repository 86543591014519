import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { PostPassword } from '@/api/core'
import { usePasswordCreate } from '@/api/vault'
import { totpSecretFromClipboard } from '@/lib/totp'
import { Button, ButtonLoading, Input, Textarea } from '@/ui'
import { InputPassword } from '@/ui/Input/v2'

type PasswordCreateProps = {
  onClose: () => void
}

export function PasswordCreate(props: PasswordCreateProps) {
  const { onClose } = props
  const create = usePasswordCreate()

  const schema: yup.ObjectSchema<PostPassword> = yup.object().shape({
    name: yup.string().required('required'),
    username: yup.string().required('required'),
    password: yup.string().required('required'),
    website: yup.string().required('required'),
    totpSecret: yup.string().nullable(),
    notes: yup.string().default(''),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<PostPassword>({
    resolver: yupResolver(schema),
  })
  const onSubmit = (data: PostPassword) =>
    create.mutate(data, {
      onSuccess: () => {
        reset()
        onClose()
      },
    })

  const onToptPaste = async (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const secret = await totpSecretFromClipboard<HTMLInputElement>(e)
    setValue('totpSecret', secret)
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex w-96 flex-col gap-4">
          <Input
            registration={register('name')}
            error={errors.name}
            label="Name"
            data-testid="password-vault-create-name-input"
          />
          <Input
            registration={register('username')}
            error={errors.username}
            label="Username"
            data-testid="password-vault-create-username-input"
          />
          <InputPassword
            registration={register('password')}
            error={errors.password}
            label="Password"
            data-testid="password-vault-create-password-input"
          />
          <Input
            registration={register('website')}
            error={errors.website}
            label="Website"
            data-testid="password-vault-create-website-input"
          />
          <InputPassword
            registration={register('totpSecret')}
            error={errors.totpSecret}
            label="TOTP Secret"
            onPaste={onToptPaste}
            data-testid="password-vault-create-totp-secret-input"
          />
          <Textarea
            rows={4}
            registration={register('notes')}
            error={errors.notes}
            label="Notes"
            data-testid="password-vault-create-notes-textarea"
          />
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button onClick={onClose} data-testid="password-vault-create-cancel-btn">
            Cancel
          </Button>

          <ButtonLoading
            type="submit"
            variant="accent"
            isLoading={create.isPending}
            data-testid="password-vault-create-save-btn"
          >
            Save
          </ButtonLoading>
        </div>
        <span className={clsx('text-alert', !create.isError && 'invisible')}>
          Failed to save {(create.error as any)?.body?.message}
        </span>
      </form>
    </div>
  )
}
