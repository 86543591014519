import { CampaignType } from '@/api/core'

export const CampaignTypeToText: Record<CampaignType, string> = {
  [CampaignType.COLD_OUTREACH]: 'Cold outreach',
  [CampaignType.REENGAGEMENT]: 'Re-engagement',
  [CampaignType.NURTURE]: 'Nurture',
  [CampaignType.EVENT]: 'Event',
}

export const TextToCampaignType: Record<string, CampaignType> = Object.fromEntries(
  Object.entries(CampaignTypeToText).map(([key, value]) => [value, key as CampaignType]),
)

// NOTE: skip event because it's not supported anymore as an option
export const CampaignTypeLabels: { label: string; value: CampaignType }[] = Object.entries(CampaignTypeToText)
  .filter(([key]) => key !== CampaignType.EVENT)
  .map(([value, label]) => ({ label, value: value as CampaignType }))

export type CampaignTypeLabel = (typeof CampaignTypeLabels)[number]
