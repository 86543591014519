/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PaymentPriceType {
    STARTER_MONTHLY = 'STARTER_MONTHLY',
    STARTER_YEARLY = 'STARTER_YEARLY',
    GROWTH_MONTHLY = 'GROWTH_MONTHLY',
    GROWTH_YEARLY = 'GROWTH_YEARLY',
    CUSTOM = 'CUSTOM',
}
