import { useEffect, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Input, Text } from '@/ui'
import { Button } from '@/ui/Button/v2'

export function ResetPasswordModal({
  isOpen,
  setIsOpen,
  savePassword,
}: {
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  savePassword: (val: string) => void
}) {
  const [newPassword, setNewPassword] = useState<string>()

  useEffect(() => {
    // Clearing password so it will be empty every time user opens modal
    setNewPassword('')
  }, [isOpen])

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
      <div className="fixed inset-0 bg-white/70" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="mx-auto w-full max-w-xs rounded-lg border border-light bg-white p-6 shadow-sm">
          <Dialog.Title as="div">
            <Text variant="subtitle">Change your password</Text>
          </Dialog.Title>

          <Input
            label="New password"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            containerClassName="mt-4 mb-6"
          />

          <div className="ml-auto flex">
            <Button variant="text" onClick={() => setIsOpen(false)} className="ml-auto">
              Cancel
            </Button>
            <Button variant="text" className="ml-4 p-0" onClick={() => newPassword && savePassword(newPassword)}>
              Save
            </Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
