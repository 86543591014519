import { HubspotIntegrationStatus } from '@/api/core'

export const HubspotIntegrationStatusToText: Record<HubspotIntegrationStatus, string> = {
  [HubspotIntegrationStatus.NOT_CONNECTED]: '',
  [HubspotIntegrationStatus.CONNECTED]: 'Connected',
  [HubspotIntegrationStatus.DISCONNECTED]: 'Disconnected',
  [HubspotIntegrationStatus.RECONNECT_NEEDED]: 'Reconnect',
}

export const HubspotIntegrationStatusToColor: Record<HubspotIntegrationStatus, string> = {
  [HubspotIntegrationStatus.NOT_CONNECTED]: '',
  [HubspotIntegrationStatus.CONNECTED]: 'text-[#0A904E]',
  [HubspotIntegrationStatus.DISCONNECTED]: 'text-[#AB0751]',
  [HubspotIntegrationStatus.RECONNECT_NEEDED]: 'text-[#FFCD7C]',
}

export const TextToHubspotIntegrationStatus: Record<string, HubspotIntegrationStatus> = Object.fromEntries(
  Object.entries(HubspotIntegrationStatusToText).map(([key, value]) => [value, key as HubspotIntegrationStatus]),
)
