import { Link } from 'react-router-dom'
import { ChartLineUp, Check } from '@phosphor-icons/react'
import { useManualStepRecommendations, useManualStepsRecommendationDone } from '@/api/manual_step'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Text } from '@/ui'
import { AllSteps } from '../AllSteps'
import { Finished } from '../Finished'
import { AllStepsSkeleton } from '../Skeletons'

export function Recommendations() {
  const toast = useToast()
  const { data, status } = useManualStepRecommendations()
  const doneReply = useManualStepsRecommendationDone()

  if (status === 'error' || status === 'pending') {
    return <AllStepsSkeleton />
  }

  const onDone = (id: string) => {
    doneReply.mutate(id, {
      onSuccess: () => {
        toast.createToast({ message: `Recommendation marked done` })
      },
      onError: (error) => {
        toast.createToast({
          message: (error as any)?.body?.message || 'Failed to mark recommendation done',
          error: true,
        })
      },
    })
  }
  return (
    <section>
      {data.data.length > 0 ? (
        <AllSteps>
          <AllSteps.Group className="py-2">
            <div className="px-4 py-4">
              <ChartLineUp className="h-6 w-6 text-success" />
              <div className="mt-4 font-medium">People are viewing your email, try reaching out to them</div>
            </div>
            {data?.data.map(({ contact: { name }, opens, emailSubject, id, hasDraft }) => (
              <AllSteps.Item key={id} className="group flex-col">
                <Link
                  to={`/inbox/recommendations/${id}`}
                  className="item-center grid w-full cursor-default grid-cols-[1fr_36px] gap-6"
                >
                  <div className="flex-col">
                    <Text variant="text" className="truncate font-medium text-dark">
                      {hasDraft && (
                        <>
                          <span className="font-medium text-success">Draft</span> to{' '}
                        </>
                      )}
                      {emailSubject || 'Email not found'}
                    </Text>
                    <Text variant="text" className="line-clamp-1 text-dusk">
                      viewed {opens} times by {name}
                    </Text>
                  </div>
                  <Button
                    variant="icon"
                    icon={Check}
                    iconclassname="m-0"
                    className="hidden group-hover:block"
                    onClick={(e) => {
                      onDone(id)
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                  />
                </Link>
              </AllSteps.Item>
            ))}
          </AllSteps.Group>
        </AllSteps>
      ) : (
        <Finished message="No recommendations to view" />
      )}
    </section>
  )
}
