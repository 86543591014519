import { useState } from 'react'
import { DateRange, DayPicker } from 'react-day-picker'
import clsx from 'clsx'
import { CalendarBlank } from '@phosphor-icons/react'
import { dateFormat } from '@/lib/date'
import { Popover } from '@/ui'

export type DateRangeClosed =
  // both from and to date are included in the range
  | {
      from: Date
      to: Date
    }
  // undefined means that the range is not selected
  | undefined

type RichDateRangePickerProps = {
  dataTestId?: string
  onRangeChange?: (range: DateRangeClosed) => void

  // options is an array of objects that represent a predefined date ranges
  // default:
  // [
  //   { label: 'All time' },
  //   { label: 'Last day', days: -1 },
  //   { label: 'Last 7 days', days: -7 },
  //   { label: 'Last 14 days', days: -14 },
  //   { label: 'Last 30 days', days: -30 },
  // ]
  options?: Array<
    {
      label: string
    } & (
      | { allTime: true }
      | { years: number }
      | { months: number }
      | { days: number }
      | { hours: number }
      | { minutes: number }
    )
  >

  // optionIndex is the index of the option that should be selected by default
  // default: 0 (or -1 if options is empty)
  optionIndex?: number

  // default is true
  disableFutureDates?: boolean

  // default is false
  disablePastDates?: boolean

  // default is false
  disableToday?: boolean

  startingRange?: DateRange
}

export function RichDateRangePicker(props: RichDateRangePickerProps) {
  const {
    onRangeChange,
    options = [
      { label: 'All time', allTime: true },
      { label: 'Last day', days: -1 },
      { label: 'Last 7 days', days: -7 },
      { label: 'Last 14 days', days: -14 },
      { label: 'Last 30 days', days: -30 },
    ],
    optionIndex = options.length ? 0 : -1,
    disableFutureDates = true,
    disablePastDates = false,
    disableToday = false,
    startingRange,
    dataTestId,
  } = props

  const [range, setRange] = useState<DateRange | undefined>(startingRange)
  const [optionSelected, setSelectedOption] = useState(optionIndex)
  const onChange = (range: DateRange | undefined) => {
    setRange(range)
    // only trigger setRange when either range is undefined or both from and to are defined.
    // This is to prevent triggering setRange when only one of the dates is selected
    if (range === undefined || (range.from && range.to)) {
      onRangeChange?.(range as DateRangeClosed)
    }
  }

  const setCustomRange = (range: DateRange | undefined) => {
    onChange(range)
    setSelectedOption(-1)
  }

  const setOptionRange = (index: number) => {
    // all time option
    if ('allTime' in options[index]) {
      onChange(undefined)
      setSelectedOption(index)
      return
    }

    const option = options[index]
    let inThePast = true
    const newDate = new Date()
    if ('years' in option) {
      newDate.setFullYear(newDate.getFullYear() + option.years)
      inThePast = option.years < 0
    } else if ('months' in option) {
      newDate.setMonth(newDate.getMonth() + option.months)
      inThePast = option.months < 0
    } else if ('days' in option) {
      newDate.setDate(newDate.getDate() + option.days)
      inThePast = option.days < 0
    } else if ('hours' in option) {
      newDate.setHours(newDate.getHours() + option.hours)
      inThePast = option.hours < 0
    } else if ('minutes' in option) {
      newDate.setMinutes(newDate.getMinutes() + option.minutes)
      inThePast = option.minutes < 0
    } else {
      throw new Error('Invalid option')
    }

    if (inThePast) {
      onChange({ from: newDate, to: new Date() })
    } else {
      onChange({ from: new Date(), to: newDate })
    }
    setSelectedOption(index)
  }

  const footer = (
    <p>
      {optionSelected >= 0
        ? options[optionSelected].label
        : range?.from
          ? range.to
            ? dateFormat(range.from) + ' — ' + dateFormat(range.to)
            : dateFormat(range.from)
          : 'Please pick the first day.'}
    </p>
  )

  return (
    <>
      <Popover>
        <Popover.Button className="flex gap-2" variant="basic" {...(dataTestId && { 'data-testid': dataTestId })}>
          <CalendarBlank className="size-5 text-medium" />
          {optionSelected === -1 ? footer : options[optionSelected].label}
        </Popover.Button>
        <Popover.Panel className="absolute right-0 z-10 mt-2 flex w-fit rounded-md border bg-extra-light p-3 text-sm shadow-sm">
          {({ close }) => (
            <>
              {/* max-width is set to 28rem to match the width of the date picker, which is the sibling element to this menu */}
              {options.length > 0 && (
                <div className="mr-6 flex max-h-[346px] w-28 flex-col overflow-y-auto">
                  {options.map((option, index) => (
                    <button
                      onClick={() => {
                        setOptionRange(index)
                        close()
                      }}
                      className={clsx(
                        'px-3 py-1.5 text-left hover:rounded-lg hover:bg-accent-light',
                        optionSelected === index && 'rounded-lg bg-light',
                      )}
                      key={option.label}
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              )}

              <DayPicker
                toDate={
                  disableFutureDates
                    ? disableToday
                      ? new Date(new Date().setDate(new Date().getDate() - 1))
                      : new Date()
                    : undefined
                }
                fromDate={disablePastDates ? new Date() : undefined}
                selected={range}
                onSelect={setCustomRange}
                footer={footer}
                fixedWeeks
                mode="range"
                className="!m-0 rounded-lg bg-extra-light"
                modifiersClassNames={{
                  // NOTE: to make selection styles work in Storybook
                  // this line should be commented out.
                  // It works in the app though, no idea why
                  selected: 'bg-accent-light text-black',
                }}
              />
            </>
          )}
        </Popover.Panel>
      </Popover>
    </>
  )
}
