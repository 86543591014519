import { RiftLogo } from '@/icons/RiftLogo'
import { Text } from '@/ui/Text'

export function ForgotPasswordConfirmation() {
  return (
    <div className="flex min-h-[655px] flex-1 flex-col justify-center self-center pt-12 sm:px-6 lg:px-8">
      <hgroup className="text-center sm:mx-auto sm:w-full sm:max-w-md">
        <RiftLogo className="m-auto" simple={false} width={60} />
        <Text variant="title">Reset your password</Text>
      </hgroup>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-8 shadow sm:rounded-lg sm:px-12">
          <hgroup className="text-center">
            <Text variant="subtitle">Email sent!</Text>
            <Text variant="text">
              You will soon receive an email containing further instructions. Please remember to check your spam folder
              if it doesn't appear in your inbox.
            </Text>
          </hgroup>
        </div>
      </div>
    </div>
  )
}
