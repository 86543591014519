import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'
import { Card, Text } from '@/ui'

export function AllSteps({ children }: PropsWithChildren) {
  return <div className="m-auto max-w-2xl">{children}</div>
}

function Group({ children, title, className }: PropsWithChildren<{ title?: string; className?: string }>) {
  return (
    <Card className={twMerge('mt-6 rounded-lg bg-white px-2', className)}>
      {title && (
        <Text variant="subtitle" className="px-4 pb-3 pt-1">
          {title}
        </Text>
      )}

      <ul>{children}</ul>
    </Card>
  )
}

function Item({ children, className }: PropsWithChildren<{ className?: string }>) {
  return <li className={twMerge('rounded-lg px-4 py-3 hover:bg-light-blue', className)}>{children}</li>
}

function NotSeenNotification() {
  return <div className="mr-4 h-1 w-1 rounded-full bg-accent" />
}

AllSteps.Group = Group
AllSteps.Item = Item
AllSteps.NotSeenNotification = NotSeenNotification
