import { useEffect, useId, useState } from 'react'
import { DotOutline, XCircle } from '@phosphor-icons/react'

type InputMultiTagProps = {
  // The selected tags.
  value: string[]

  // The function to call when a new tag is selected or unselected.
  onChange: (v: string[]) => void

  // A function to validate the single tag.
  // It should throw an error if the tag is invalid.
  validate?: (v: string) => void

  // A function to call when the input value changes.
  // It can be used to track current value of the input.
  onInputValueChange?: (v: string) => void
}

// InputMultiTag is a component that allows the user to input multiple tags.
export function InputMultiTag(props: InputMultiTagProps) {
  const { value, onChange, validate, onInputValueChange } = props

  const internalId = useId()
  const [currentInputValue, setCurrentInputValue] = useState<string>('')
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (onInputValueChange) onInputValueChange(currentInputValue)
  }, [currentInputValue])

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentInputValue(event.target.value)
    setError(null)
  }

  const onRemove = (index: number) => {
    const arr = [...value]
    arr.splice(index, 1)
    onChange(arr)
  }

  const inputSave = () => {
    if (currentInputValue === '') return
    try {
      if (validate) validate(currentInputValue)
      onChange([...value, currentInputValue])
      setCurrentInputValue('')
      setError(null)
    } catch (err: any) {
      if (err instanceof Error) {
        setError(err.message)
      } else {
        setError('Invalid value')
      }
    }
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      inputSave()
    } else if (event.key === 'Backspace' && currentInputValue === '') {
      onChange([...value].slice(0, value.length - 1))
    }
  }

  const handleOnBlur = () => {
    inputSave()
  }

  return (
    <div className="group/container inline-flex w-full rounded-sm p-1 text-sm hover:bg-[#F7F8FA]">
      {value.map((value, index) => (
        <div
          key={`ui_input_multi_tag${internalId}_${value}_${index}`}
          className="group flex items-center gap-2 rounded-sm px-2 py-1 text-dark hover:bg-light"
        >
          {value}
          <DotOutline className="size-4 group-hover:hidden" />
          <button className="hidden cursor-default group-hover:block" onClick={() => onRemove(index)}>
            <XCircle className="size-4 fill-medium text-medium" weight="fill" />
          </button>
        </div>
      ))}

      <input
        autoFocus
        onKeyDown={onKeyDown}
        onChange={onInputChange}
        onBlur={handleOnBlur}
        value={currentInputValue}
        className="w-full py-1 focus:outline-none group-hover/container:bg-[#F7F8FA]"
      />
      {error && <span className="right-0 whitespace-nowrap py-1 text-alert">{error}</span>}
    </div>
  )
}
