import { useContact } from '@/api/contacts'
import { Text } from '@/ui'

export function ContactInformation({ contactId }: { contactId: string }) {
  const { data: contact } = useContact(contactId)

  const { first_name: firstName, last_name: lastName, email, title, phone, company, linkedin } = contact?.data ?? {}

  return (
    <div className="flex w-full flex-col gap-6 pl-6">
      <section className="text-medium">
        <Text className="overflow-hidden text-ellipsis text-dusk" variant="subtitle">
          {firstName} {lastName}
        </Text>
        <Text className="overflow-hidden text-ellipsis">{email}</Text>
        <Text className="overflow-hidden text-ellipsis">
          {title} {title && company && 'at'} {company}
        </Text>
        {phone && (
          <Text className="overflow-hidden text-ellipsis">
            {phone.length === 10 ? phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : phone}
          </Text>
        )}
        {linkedin && (
          <a
            href={linkedin}
            className="overflow-hidden text-ellipsis text-sm underline"
            target="_blank"
            rel="noreferrer"
          >
            {linkedin.replace(/^https?:\/\//, '').replace(/^www\./, '')}
          </a>
        )}
      </section>
    </div>
  )
}
