import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowBendUpLeft, Check } from '@phosphor-icons/react'
import { ManualStepDraftProduct, ManualStepRecommendationInfo, ManualStepReplyInfoEmail } from '@/api/core'
import {
  useManualStepRecommendation,
  useManualStepsRecommendationDone,
  useManualStepsReplyDelete,
  useManualStepsReplySave,
  useManualStepsReplySend,
} from '@/api/manual_step'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Text } from '@/ui'
import { ContactInformation } from '../ContactInformation'
import { SingleStep } from '../SingleStep'
import { SingleStepSkeleton } from '../Skeletons'
import { Editor } from './Editor'
import { ReplyThread, ReplyThreadFocused } from './Threads'
import { getDisplayName } from './utilities'

export function SingleRecommendation() {
  const { recommendationId } = useParams() as { recommendationId: string }
  const { data, status } = useManualStepRecommendation(recommendationId)

  if (status === 'pending' || status === 'error' || !data) {
    return <SingleStepSkeleton />
  }

  return (
    <section key={recommendationId}>
      <SingleEmailHandler data={data.data} />
    </section>
  )
}

function toKey(email: ManualStepReplyInfoEmail) {
  return `${email.bodyText}--${email.sentAt}`
}

type SingleEmailHandlerProps = {
  data: ManualStepRecommendationInfo
}

function SingleEmailHandler({ data: { emails, draft, id, contact, next, prev, opens } }: SingleEmailHandlerProps) {
  const [focusedId, setFocusedId] = useState<string | null>(
    draft && !emails[emails.length - 1] ? null : toKey(emails[emails.length - 1]),
  )
  const navigate = useNavigate()
  const toast = useToast()
  const sendEmail = useManualStepsReplySend(id)
  const saveDraft = useManualStepsReplySave(id)
  const deleteDraft = useManualStepsReplyDelete(id)
  const doneEmail = useManualStepsRecommendationDone()
  const subject = emails[0].subject

  const onSend = () => {
    sendEmail.mutate(undefined, {
      onSuccess: () => {
        toast.createToast({ message: `Recommendation Reply Sent` })
        if (next) {
          navigate(`/inbox/recommendations/${next.id}`)
        } else if (prev) {
          navigate(`/inbox/recommendations/${prev.id}`)
        } else {
          navigate('/inbox/recommendations/')
        }
      },
      onError: (error) => {
        toast.createToast({ message: (error as any)?.body?.message || 'Failed to send reply', error: true })
      },
    })
  }
  const onDone = () => {
    doneEmail.mutate(id, {
      onSuccess: () => {
        toast.createToast({ message: `Recommendation Done` })
        if (next) {
          navigate(`/inbox/recommendations/${next.id}`)
        } else if (prev) {
          navigate(`/inbox/recommendations/${prev.id}`)
        } else {
          navigate('/inbox/recommendations/')
        }
      },
      onError: (error) => {
        toast.createToast({ message: (error as any)?.body?.message || 'Failed to mark reply done', error: true })
      },
    })
  }

  const createDraft = () => {
    saveDraft.mutate(
      {
        subject: subject,
        body: '',
        to: [],
        cc: [],
        bcc: [],
        product: ManualStepDraftProduct.RECOMMENDATIONS,
      },
      {
        onSuccess: () => {
          setFocusedId(null)
        },
        onError: (error) => {
          toast.createToast({ message: (error as any)?.body?.message || 'Failed to send email', error: true })
        },
      },
    )
  }

  const handleDeleteDraft = () => {
    deleteDraft.mutate(undefined, {
      onSuccess: () => {
        setFocusedId(toKey(emails[emails.length - 1]))
      },
      onError: (error) => {
        toast.createToast({ message: (error as any)?.body?.message || 'Failed to delete draft', error: true })
      },
    })
  }

  const handleReplyClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation()
    createDraft()
  }

  return (
    <SingleStep>
      <SingleStep.Navigation prev={prev?.id || null} next={next?.id || null} url="/inbox/recommendations/" />

      <SingleStep.Main>
        <div className="flex flex-row pb-6">
          <Text variant="title">
            {contact?.firstName} viewed this email {opens} times
          </Text>
          <Button
            onClick={onDone}
            variant="icon"
            icon={Check}
            className="ml-auto rounded-lg border"
            iconclassname="h-6 w-6 m-2"
          />
        </div>

        <div className="flex flex-col gap-4">
          {emails.map((message, index) =>
            toKey(message) === focusedId ? (
              <ReplyThreadFocused
                reply={message}
                key={toKey(message)}
                setFocused={() => setFocusedId(null)}
                getDisplayName={(email) => getDisplayName(email, contact)}
                {...(index === emails.length - 1 && !draft
                  ? {
                      draftBtn: (
                        <Button onClick={handleReplyClick} className="flex w-fit items-center gap-2 pt-2">
                          <ArrowBendUpLeft className="h-4 w-4 text-medium" />
                          Reply
                        </Button>
                      ),
                    }
                  : {})}
              />
            ) : (
              <ReplyThread
                reply={message}
                key={toKey(message)}
                setFocused={() => setFocusedId(toKey(message))}
                getDisplayName={(email) => getDisplayName(email, contact)}
              />
            ),
          )}
          {draft && (
            <Editor
              variables={{}}
              replyId={id}
              body={draft.body}
              onSend={onSend}
              onDelete={handleDeleteDraft}
              subject={subject}
              displayName={getDisplayName(contact?.email || '', contact)}
            />
          )}
        </div>
      </SingleStep.Main>
      {contact ? <ContactInformation contactId={contact?.id} /> : <div />}
    </SingleStep>
  )
}
