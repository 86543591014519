import { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

export function useSearchCampaigns(searchCampaigns: (s: string) => void) {
  const [searchText, setSearchText] = useState('')
  const [debouncedSearchText] = useDebounce(searchText, 250)

  useEffect(() => {
    searchCampaigns(debouncedSearchText)
  }, [debouncedSearchText])

  return { searchText, setSearchText }
}
