import { CampaignAnalytics } from '@/api/core'
import { SequenceAnalyticsContextType } from './types'

export type Action =
  | { type: 'change_from_date'; body: { date: number | undefined } }
  | { type: 'change_to_date'; body: { date: number | undefined } }
  | { type: 'update_analytics'; body: { analytics: CampaignAnalytics } }

export function reducer(state: SequenceAnalyticsContextType, action: Action) {
  switch (action.type) {
    case 'update_analytics': {
      return {
        ...state,
        analytics: action.body.analytics,
      }
    }
    case 'change_from_date': {
      return {
        ...state,
        from: action.body.date,
      }
    }
    case 'change_to_date': {
      return {
        ...state,
        to: action.body.date,
      }
    }
  }
}
