import clsx from 'clsx'
import { Check, Plus } from '@phosphor-icons/react'
import { FieldDefinition, FieldDefinitions, SingularMappings } from '@/api/core'
import { Dropdown } from '@/components/Dropdown'
import { ArrowRightIcon } from '@/icons/ArrowrightIcon'
import { Button, Text } from '@/ui'

export type Mapping = {
  leftId: string
  rightId: string
}

export function mappingsFromSingularMappings(mappings: SingularMappings, reverse: boolean): Mapping[] {
  return reverse
    ? mappings.map((m) => ({
        leftId: m.crm,
        rightId: m.rift,
      }))
    : mappings.map((m) => ({
        leftId: m.rift,
        rightId: m.crm,
      }))
}

export function singularMappingsFromMappings(mappings: Mapping[], reverse: boolean): SingularMappings {
  return reverse
    ? mappings.map((m) => ({
        crm: m.leftId,
        rift: m.rightId,
      }))
    : mappings.map((m) => ({
        crm: m.rightId,
        rift: m.leftId,
      }))
}

export function mappingSetterToSingularMappings(
  fn: (mappings: SingularMappings) => void,
  reverse: boolean,
): (mappings: Mapping[]) => void {
  return (mappings: Mapping[]) => {
    fn(singularMappingsFromMappings(mappings, reverse))
  }
}

export function SingularMappingSkeleton() {
  return (
    <div>
      <div className="skeleton m-auto h-[600px] w-full max-w-2xl"></div>
    </div>
  )
}

type SingularMappingProps = {
  title: string
  subtitle: string
  leftColumnTitle: string
  rightColumnTitle: string
  leftFields: FieldDefinitions
  rightFields: FieldDefinitions
  defaultFieldMapping: Mapping[]
  mapping: Mapping[]
  listAllLeftFields?: boolean
  multipleRightOptions?: boolean
  setMapping: (mapping: Mapping[]) => void
}

export function SingularMappingForm({
  title,
  subtitle,
  leftColumnTitle,
  rightColumnTitle,
  leftFields,
  rightFields,
  defaultFieldMapping,
  mapping,
  multipleRightOptions = false,
  setMapping,
}: SingularMappingProps) {
  return (
    <section>
      <hgroup className="pb-5">
        <Text variant="title" className="pb-2">
          {title}
        </Text>
        <Text variant="subtext" className="text-medium">
          {subtitle}
        </Text>
      </hgroup>
      <div className="grid grid-cols-[minmax(0,_1fr)_40px_minmax(0,_1fr)] gap-y-5 align-middle">
        <Text variant="subtitle">{leftColumnTitle}</Text>
        <div></div>
        <Text variant="subtitle">{rightColumnTitle}</Text>
        {leftFields.map((leftField, i) => (
          <SingularMappingRow
            key={i}
            leftField={leftField}
            rightFields={
              multipleRightOptions
                ? rightFields
                : rightFields.filter(
                    (field) => !mapping.find((m) => m.rightId === field.id && m.leftId !== leftField.id),
                  )
            }
            defaultMapping={
              defaultFieldMapping.find(
                (mapping) => mapping.leftId === leftField.id && rightFields.find((v) => v.id == mapping.rightId),
              ) || {
                leftId: leftField.id,
                rightId: '',
              }
            }
            selected={mapping.find((mapping) => mapping.leftId === leftField.id) || undefined}
            onSelect={(m: Mapping | undefined) => {
              const newMapping = multipleRightOptions
                ? mapping.filter((mapping) => mapping.leftId !== leftField.id)
                : mapping.filter((mapping) => mapping.leftId !== leftField.id && mapping.rightId !== m?.rightId)
              setMapping(m ? newMapping.concat(m) : newMapping)
            }}
          />
        ))}
      </div>
    </section>
  )
}

type SingularMappingRowProps = {
  leftField: FieldDefinition
  rightFields: FieldDefinitions
  defaultMapping: Mapping
  selected: Mapping | undefined
  onSelect: (field: Mapping | undefined) => void
}
function SingularMappingRow({ leftField, rightFields, defaultMapping, selected, onSelect }: SingularMappingRowProps) {
  return (
    <div className="col-span-3 grid h-12 grid-cols-[minmax(206px,_1fr)_40px_minmax(206px,_1fr)] gap-x-3 align-middle">
      <LeftMappingButton
        defaultMapping={defaultMapping}
        leftField={leftField}
        selected={selected}
        onSelect={onSelect}
      />
      <RowIcon selected={!!selected} />
      <RightMappingPicker selected={selected} options={rightFields} onSelect={onSelect} />
    </div>
  )
}

function RowIcon({ selected }: { selected: boolean }) {
  return !selected ? <span /> : <ArrowRightIcon width={20} className="self-center justify-self-center" />
}

function LeftMappingButton({
  defaultMapping,
  leftField,
  selected,
  onSelect,
}: {
  selected: Mapping | undefined
  defaultMapping: Mapping
  leftField: FieldDefinition
  onSelect: (field: Mapping | undefined) => void
}) {
  return (
    <Button
      variant="leading-icon"
      icon={selected ? Check : Plus}
      onClick={() => {
        onSelect(selected ? undefined : defaultMapping)
      }}
      className={clsx('w-full justify-start self-center', selected && 'border-accent')}
    >
      {leftField.label}
    </Button>
  )
}

function RightMappingPicker({
  selected,
  options,
  onSelect,
}: {
  selected: Mapping | undefined
  options: FieldDefinitions
  onSelect: (field: Mapping | undefined) => void
}) {
  return selected ? (
    <Dropdown
      options={options}
      selected={options.find((option) => option.id === selected.rightId) || null}
      handleSelection={(selection) => onSelect({ ...selected, rightId: selection.id as string })}
    />
  ) : (
    <Text variant="text" className="self-center pl-3 text-medium">
      Don't sync
    </Text>
  )
}
