import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { DefaultService, PostPhoneNumber } from '@/api/core'

export const queryKey = {
  base: () => ['phone_numbers'] as const,
  buyable: (filter: string) => ['phone_numbers', 'buyable', filter] as const,
}

export function usePhoneNumbers() {
  return useQuery({
    queryKey: queryKey.base(),
    queryFn: DefaultService.getPhoneNumbers,
  })
}

export function usePhoneNumbersBuyable(filter: string) {
  return useQuery({
    queryKey: queryKey.buyable(filter),
    enabled: filter.length > 0,
    queryFn: () => DefaultService.getPhoneNumbersBuyable(filter),
  })
}

export function usePhoneNumberBuy() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: PostPhoneNumber) => DefaultService.postPhoneNumbers(body),
  })
}

export function usePhoneNumberRelease() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (phoneNumberId: string) => DefaultService.deletePhoneNumbers(phoneNumberId),
  })
}

export function usePhoneNumberUpdate() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: Parameters<typeof DefaultService.patchPhoneNumbers>) => {
      const [phoneNumberId, rest] = body
      return DefaultService.patchPhoneNumbers(phoneNumberId, rest)
    },
  })
}
