import { useFlags, useHideFeatureFlag } from '@/containers/FeatureFlag/useFlags'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Switch, Text } from '@/ui'

export function FeatureFlagSettings() {
  const { showFeatureFlagRaw } = useFlags()
  const [hideFeatureFlag, setHideFeatureFlag] = useHideFeatureFlag()
  const toast = useToast()
  const onChange = (value: boolean) => {
    setHideFeatureFlag(value)
    toast.createToast({ message: `Feature flag is now ${value ? 'hidden' : 'visible'}` })
  }

  if (!showFeatureFlagRaw) {
    return null
  }

  return (
    <div className="label flex flex-row items-center justify-between gap-2">
      <div>
        <Text variant="text" className="font-medium text-dusk">
          Hide feature flag
        </Text>
        <Text className="text-dusk">Disable access to features by hiding ui elements from user sight</Text>
      </div>
      <Switch checked={hideFeatureFlag} onChange={onChange} />
    </div>
  )
}
