import { useFieldArray, useForm } from 'react-hook-form'
import clsx from 'clsx'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { OnboardingDetails, OnboardingDomains, PutOnboardingDomainsVerificationTXT } from '@/api/core'
import { useOnboardingDomains, useOnboardingPutDomainsVerificationTXT } from '@/api/onboarding'
import { useFormLocalStorage } from '@/hooks'
import { ButtonLoading, Input, Text } from '@/ui'
import { Step } from '@/ui/headless'

type DomainsVerificationTXTProps = {
  nextStep: () => void
  onboarding: OnboardingDetails
}

export function DomainsVerificationTXT(props: DomainsVerificationTXTProps) {
  const { nextStep } = props
  const { data: domains, status: domainsStatus } = useOnboardingDomains()

  if (domainsStatus === 'pending') {
    return <div className="skeleton h-12 w-full" />
  }
  if (domainsStatus === 'error') {
    return <div>Error ...</div>
  }
  return <DomainsVerificationTXTForm nextStep={nextStep} domains={domains.data} onboarding={props.onboarding} />
}

type DomainsVerificationTXTFormProps = {
  nextStep: () => void
  domains: OnboardingDomains
  onboarding: OnboardingDetails
}

function DomainsVerificationTXTForm(props: DomainsVerificationTXTFormProps) {
  const { nextStep, domains, onboarding } = props
  const putDomainsVerificationTXT = useOnboardingPutDomainsVerificationTXT()

  const schema: yup.ObjectSchema<PutOnboardingDomainsVerificationTXT> = yup.object().shape({
    domains: yup
      .array()
      .required()
      .of(
        yup.object().shape({
          id: yup.string().required('required'),
          txt: yup.string().default(''),
        }),
      ),
  })
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<PutOnboardingDomainsVerificationTXT>({
    defaultValues: {
      domains: domains.domains,
    },
    resolver: yupResolver(schema),
  })
  const { fields } = useFieldArray({
    control,
    name: 'domains',
  })

  const storageName = onboarding.id + '_form_domains_txt_records'
  const { clearFormLocalStorage } = useFormLocalStorage<PutOnboardingDomainsVerificationTXT>(storageName, {
    setValue,
    watch,
  })

  const onSubmit = (data: PutOnboardingDomainsVerificationTXT) => {
    putDomainsVerificationTXT.mutate(data, {
      onSuccess: () => {
        nextStep()
        clearFormLocalStorage()
      },
    })
  }

  return (
    <form>
      <div>
        <h1 className="mb-6 text-lg font-medium">Enter domains verification code</h1>
        <Text variant="subtitle">
          Enter TXT record for each domain or leave empty if verification happened automatically. It starts with
          google-site-verification=...
        </Text>
        {fields.map((field, index) => (
          <div key={field.id} className="flex w-full flex-row items-end gap-4">
            <Input disabled value={domains.domains[index].domain} label="Domain" />
            <Input
              registration={register(`domains.${index}.txt`)}
              error={errors.domains?.[index]?.txt}
              label="Code (TXT)"
            />
          </div>
        ))}
      </div>

      <span className={clsx('text-alert', !putDomainsVerificationTXT.isError && 'invisible')}>
        Failed to save {(putDomainsVerificationTXT.error as any)?.body?.message}
      </span>

      <div className="mt-8 flex justify-end">
        <Step.Next
          type="submit"
          as={ButtonLoading}
          variant="accent"
          isLoading={putDomainsVerificationTXT.isPending}
          onClick={(e) => {
            e.preventDefault()
            handleSubmit(onSubmit)()
          }}
        >
          Save & Finish
        </Step.Next>
      </div>
    </form>
  )
}
