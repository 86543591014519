import { useState } from 'react'
import clsx from 'clsx'
import { Button, Popover, PopoverButton, PopoverPanel, useClose } from '@headlessui/react'
import { useContactsOwnerChange, useContactsOwnerSplit } from '@/api/contacts'
import { Member, MemberRole } from '@/api/core'
import { useOutsideClick } from '@/hooks'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button as UIButton, Checkbox } from '@/ui'

type ContactsOwnerPopoverProps = {
  contactIds: string[]
  members: Member[]
}

export function ContactsOwnerPopover(props: ContactsOwnerPopoverProps) {
  const { members, contactIds } = props

  return (
    <Popover className="relative">
      <PopoverButton as={UIButton}>Owners...</PopoverButton>
      <Panel members={members} contactIds={contactIds} />
    </Popover>
  )
}

type PanelProps = {
  members: Member[]
  contactIds: string[]
}

function Panel(props: PanelProps) {
  const { members, contactIds } = props
  const [mode, setMode] = useState<'change' | 'split'>('change')

  const close = useClose()
  const outsideRef = useOutsideClick(() => {
    close()
    setMode('change')
  })

  return (
    <PopoverPanel
      anchor="top"
      className="min-w-40 divide-y rounded-lg border border-light bg-white shadow-rift [--anchor-gap:8px]"
      ref={outsideRef}
    >
      {mode === 'change' ? (
        <ChangePanel members={members} onSplitClick={() => setMode('split')} close={close} contactIds={contactIds} />
      ) : (
        mode === 'split' && <SplitPanel members={members} close={close} contactIds={contactIds} />
      )}
    </PopoverPanel>
  )
}

type ChangePanelProps = {
  members: Member[]
  onSplitClick: () => void
  close: () => void
  contactIds: string[]
}

function ChangePanel(props: ChangePanelProps) {
  const { members, onSplitClick, contactIds, close } = props
  const ownerChange = useContactsOwnerChange()
  const toast = useToast()

  const onChangeOnwer = (ownerId: string) => {
    ownerChange.mutate(
      {
        contactIds: contactIds,
        newOwnerId: ownerId,
      },
      {
        onSuccess: () => toast.createToast({ message: 'Owner changed' }),
        onError: () => toast.createToast({ message: 'Failed to change owner', error: true }),
        onSettled: () => close(),
      },
    )
  }

  return (
    <div className={clsx(ownerChange.isPending && 'pointer-events-none opacity-30')}>
      <div className="flex flex-col gap-1 p-1">
        <p className="px-3 py-2 text-sm font-medium leading-5 tracking-[0.28px] text-dark">Change owner</p>
        {members.map((owner) => (
          <Button
            key={owner.id}
            className={clsx(
              'cursor-default',
              owner.role === MemberRole.PHANTOM ? 'opacity-30' : 'hover:rounded hover:bg-accent-light',
            )}
            disabled={owner.role === MemberRole.PHANTOM}
            onClick={() => onChangeOnwer(owner.id)}
            title={owner.role === MemberRole.PHANTOM ? 'Can only assign rift member as owner' : undefined}
          >
            <p className="px-3 py-2 text-left text-sm leading-5 tracking-[0.28px] text-dark">
              {owner.name}
              {owner.role === MemberRole.PHANTOM && (
                <span className="block text-xs leading-4 tracking-[0.36px]">Not a member</span>
              )}
            </p>
          </Button>
        ))}
      </div>
      <div className="p-1">
        <Button
          className="w-full cursor-default px-3 py-2 text-left text-sm leading-5 tracking-[0.28px] text-dark hover:rounded hover:bg-accent-light"
          onClick={onSplitClick}
        >
          Split ownership...
        </Button>
      </div>
    </div>
  )
}

type SplitPanelProps = {
  members: Member[]
  close: () => void
  contactIds: string[]
}

function SplitPanel(props: SplitPanelProps) {
  const { members, close, contactIds } = props
  const toast = useToast()
  const [betweenOwnerIds, setBetweenOwnerIds] = useState<{ [key: string]: boolean }>({})
  const onOwnerToggle = (ownerId: string) => {
    setBetweenOwnerIds((prev) => ({ ...prev, [ownerId]: !prev[ownerId] }))
  }

  const ownerSplit = useContactsOwnerSplit()
  const onOwnerSplit = () => {
    ownerSplit.mutate(
      {
        contactIds: contactIds,
        betweenOwnerIds: Object.entries(betweenOwnerIds).reduce((acc, [ownerId, selected]) => {
          if (selected) acc.push(ownerId)
          return acc
        }, [] as string[]),
      },
      {
        onSuccess: () => toast.createToast({ message: 'Owners changed' }),
        onError: () => toast.createToast({ message: 'Failed to change owners', error: true }),
        onSettled: () => close(),
      },
    )
  }

  return (
    <div className={clsx('flex flex-col gap-1 p-1', ownerSplit.isPending && 'pointer-events-none opacity-30')}>
      <p className="px-3 py-2 text-sm font-medium leading-5 tracking-[0.28px] text-dark">Split ownership</p>
      {members.map((owner) => (
        <div
          key={owner.id}
          className={clsx(
            'flex cursor-default items-center justify-between gap-4 px-3 py-2',
            owner.role === MemberRole.PHANTOM && 'pointer-events-none opacity-30',
          )}
        >
          <label htmlFor={`checkbox_${owner.id}`}>
            <p className="text-left text-sm leading-5 tracking-[0.28px] text-dark">{owner.name}</p>
            {owner.role === MemberRole.PHANTOM && <p className="text-xs leading-4 tracking-[0.36px]">Not a member</p>}
          </label>
          {owner.role !== MemberRole.PHANTOM && (
            <Checkbox onClick={() => onOwnerToggle(owner.id)} id={`checkbox_${owner.id}`} className="cursor-default" />
          )}
        </div>
      ))}
      <UIButton
        variant="accent"
        className="cursor-default px-3 py-2 text-sm leading-5 tracking-[0.28px]"
        onClick={onOwnerSplit}
      >
        Save
      </UIButton>
    </div>
  )
}
