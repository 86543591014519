import { useState } from 'react'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import { Dialog } from '@headlessui/react'
import { Copy, WarningCircle, X } from '@phosphor-icons/react'
import { useCompanyAPIKeyCreate } from '@/api/api_keys'
import { ApiKeyType } from '@/api/core'
import { CompanyApiKeyCreate } from '@/api/core/models/CompanyApiKeyCreate'
import { copy } from '@/lib/utils'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button } from '@/ui/Button/v2'
import { Input } from '@/ui/Input/v2'
import { Text } from '@/ui/Text'

type CreateKeyProps = {
  type: ApiKeyType
  close: () => void
  companyName: string
}
export function CreateKey({ type, close, companyName }: CreateKeyProps) {
  const create = useCompanyAPIKeyCreate()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{ name: string }>()
  const toast = useToast()

  const [apiKey, setApiKey] = useState<string | null>(null)

  const onCreate = async (values: { name: string }) => {
    create.mutate(
      { name: values.name, type: type },
      {
        onSuccess: ({ data }: { data: CompanyApiKeyCreate }) => {
          setApiKey(data.api_key)
          reset()
        },
        onError: () => toast.createToast({ message: `Failed to create ${type} API key`, error: true }),
      },
    )
  }

  const onCopy = async () => {
    if (!apiKey) return

    const copied = await copy(apiKey)

    if (!copied) {
      toast.createToast({ message: `Failed to copy ${companyName} API key to clipboard`, error: true })
    } else {
      toast.createToast({ message: `Copied ${companyName} API key to clipboard` })
    }
  }

  return (
    <Dialog as="div" open={true} className="relative z-50" onClose={close}>
      <div className="fixed inset-0 bg-white/70" aria-hidden="true" />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Dialog.Panel
            className={clsx(
              'relative transform overflow-hidden rounded-lg border border-light bg-white p-6 text-left shadow-xl transition-all',
              !apiKey && ' max-w-sm ',
            )}
          >
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button type="button" className="rounded-md bg-white text-gray-400 hover:text-gray-500" onClick={close}>
                <span className="sr-only">Close</span>
                <X className="size-6" aria-hidden="true" />
              </button>
            </div>

            <ModalTitle apiKey={apiKey} companyName={companyName} />

            {apiKey ? (
              <div className="flex py-8">
                <code className="mx-auto inline-flex bg-gray-200 p-2 text-xs">
                  {apiKey}
                  <Copy onClick={onCopy} className="ml-2 h-6 w-6 hover:cursor-pointer hover:bg-gray-300" />
                </code>
              </div>
            ) : (
              <form onSubmit={handleSubmit(onCreate)}>
                <Input
                  name="name"
                  id="name"
                  type="text"
                  label="API Key Name"
                  placeholder="Enter key name"
                  autoFocus
                  required
                  error={errors['name']?.message}
                  registration={register('name', { required: 'Must have a name' })}
                />
                <Text>Make it easy to differentiate your keys with a unique name</Text>
                <div className="mt-5 flex justify-end">
                  <Button variant="text" onClick={close}>
                    Cancel
                  </Button>
                  <Button variant="text" title={'Name'} onClick={handleSubmit(onCreate)}>
                    Create Key
                  </Button>
                </div>
              </form>
            )}
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  )
}

function ModalTitle({ apiKey, companyName }: { apiKey: string | null; companyName: string }) {
  if (apiKey) {
    return (
      <div className="mt-3 flex sm:mt-5">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-[#fff9e6] sm:mx-0 sm:h-10 sm:w-10">
          <WarningCircle className="h-6 w-6 text-rift-yellow-500" aria-hidden="true" />
        </div>
        <Dialog.Title as="hgroup" className="ml-3">
          <Text variant="title">Your {companyName} API Key</Text>
          <Text variant="text">Make sure to copy your new API key. You won't be able to see it again!</Text>
        </Dialog.Title>
      </div>
    )
  }

  return (
    <div className="mt-3 sm:mt-5">
      <Dialog.Title as="hgroup" className="mb-4">
        <Text variant="subtitle">Create {companyName} API Key</Text>
      </Dialog.Title>
    </div>
  )
}
