import { CheckCircle } from '@phosphor-icons/react'
import { Button } from '@/ui'

export type SummaryProps = {
  onDone: () => void
  importedContacts: number
  inUseContactsIncluded: number
  notInUseContactsIncluded: number
  unsubscribedContacts: number
  notInterestedContacts: number
  duplicatedContacts: number
}

export function Summary(props: SummaryProps) {
  const {
    onDone,
    importedContacts,
    inUseContactsIncluded,
    notInUseContactsIncluded,
    notInterestedContacts,
    unsubscribedContacts,
    duplicatedContacts,
  } = props
  return (
    <>
      <div className="my-4 flex grow flex-col items-center justify-center border-y border-gray-500">
        <CheckCircle className="size-8" />
        <span className="my-2 text-lg">{importedContacts} contacts imported</span>
        {inUseContactsIncluded > 0 && (
          <span className="text-sm text-gray-400">{inUseContactsIncluded} contacts in use included</span>
        )}
        {notInUseContactsIncluded > 0 && inUseContactsIncluded === 0 && (
          <span className="text-sm text-gray-400">{notInUseContactsIncluded} in use contacts not included</span>
        )}
        {notInterestedContacts > 0 && (
          <span className="text-sm text-gray-400">{notInterestedContacts} not interested contacts removed</span>
        )}
        {unsubscribedContacts > 0 && (
          <span className="text-sm text-gray-400">{unsubscribedContacts} previously unsubscribed contacts removed</span>
        )}
        {duplicatedContacts > 0 && (
          <span className="text-sm text-gray-400">
            {duplicatedContacts} contacts updated because they already existed in list
          </span>
        )}
      </div>
      <div className="flex justify-end">
        <Button variant="basic" onClick={onDone}>
          Done
        </Button>
      </div>
    </>
  )
}
