import { DotsThree } from '@phosphor-icons/react'
import { DropdownMenu } from '@/ui'

type MeetingDropMenuProps = {
  videoPlayUrl?: string
  videoDownloadUrl?: string
  transcriptDownloadUrl?: string
  joinUrl?: string
}

export function MeetingDropMenu({
  videoPlayUrl,
  videoDownloadUrl,
  transcriptDownloadUrl,
  joinUrl,
}: MeetingDropMenuProps) {
  const onVideoPlay = () => {
    window.open(videoPlayUrl, '_blank')
  }
  const onVideoDownload = () => {
    window.open(videoDownloadUrl, '_blank')
  }
  const onJoinMeeting = () => {
    window.open(joinUrl, '_blank')
  }
  const onTranscriptDownload = () => {
    window.open(transcriptDownloadUrl, '_blank')
  }

  if (!!videoPlayUrl && !!videoDownloadUrl && !!transcriptDownloadUrl && !!joinUrl) {
    return null
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenu.Button>
          <DotsThree className="size-4.5" />
        </DropdownMenu.Button>
        <DropdownMenu.Items className="-left-1/2">
          {videoPlayUrl && <DropdownMenu.Item onClick={onVideoPlay}>Play Record</DropdownMenu.Item>}
          {videoDownloadUrl && <DropdownMenu.Item onClick={onVideoDownload}>Download Record</DropdownMenu.Item>}
          {transcriptDownloadUrl && (
            <DropdownMenu.Item onClick={onTranscriptDownload}>Download Transcript</DropdownMenu.Item>
          )}
          {joinUrl && <DropdownMenu.Item onClick={onJoinMeeting}>Join Meeting</DropdownMenu.Item>}
        </DropdownMenu.Items>
      </DropdownMenu>
    </>
  )
}
