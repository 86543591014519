import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ApiError, ApiKeyType, CompanyApiKeys, DefaultService, PatchCompanyApiKey } from '@/api/core'
import { errhandler } from './helpers'

const queryKey = ['api_keys']

export function useCompanyAPIKeys() {
  return useQuery<{ data: CompanyApiKeys }>({
    queryKey,
    queryFn: DefaultService.getCompanyApiKeys,
  })
}

export function useCompanyAPIKeyCreate() {
  const queryClient = useQueryClient()
  return useMutation({
    onError: (error: ApiError) => errhandler(error, "Can't create api key"),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey }),
    mutationFn: (data: { name: string; type: ApiKeyType }) => DefaultService.postCompanyApiKeys(data),
  })
}

export function useCompanyAPIKeyUpdate() {
  const queryClient = useQueryClient()

  return useMutation({
    onError: (error: ApiError) => errhandler(error, 'API key update failed.'),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey }),
    mutationFn: (data: PatchCompanyApiKey & { id: string }) => {
      const { id, ...body } = data
      return DefaultService.patchCompanyApiKeys(id, body)
    },
  })
}

export function useCompanyAPIKeyDelete() {
  const queryClient = useQueryClient()
  return useMutation({
    onError: (error: ApiError) => errhandler(error, "Can't delete api key."),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey }),
    mutationFn: (id: string) => DefaultService.deleteCompanyApiKeys(id),
  })
}
