import React, { useMemo, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { format } from 'date-fns-tz'
import { twMerge } from 'tailwind-merge'
import { FunnelSimple } from '@phosphor-icons/react'
import { createColumnHelper } from '@tanstack/react-table'
import { useCampaignCreate, useCampaignLaunch, useCampaigns, useCampaignStop } from '@/api/campaigns'
import { CampaignList, CampaignSortField, CampaignStatus } from '@/api/core'
import { formatInteger, formatPercent } from '@/pages/utils'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Card, Checkbox, MouseTrap, Popover, SearchBar, Text } from '@/ui'
import { Tooltip } from '@/ui/headless/Tooltip'
import { Tabs } from '@/ui/Tabs'
import { useSearchCampaigns } from '../hooks/useSearchCampaigns'
import { CampaignTypes, DisplayStatusName } from '../types'
import { ActionMenu } from './ActionMenu'
import { ContactsMetrics } from './ContactsMetrics'
import { EmailVolumeGraph } from './EmailVolumeGraph'
import { SequencesTable } from './SequencesTable'
import { Skeleton } from './Skeleton'

export function SequencesList() {
  const navigate = useNavigate()
  const [launchInProgress, setLaunchInProgress] = useState(false)
  const [campaignsSPF, setCampaignsSPF] = useState<{
    curPage: number
    q: string
    sortBy: CampaignSortField
    archived: boolean
  }>({ curPage: 1, q: '', sortBy: CampaignSortField._START_DATE, archived: false })

  const [access, setAccess] = useState<'ALL' | 'PRIVATE' | 'SHARED'>('ALL')

  const { data: campaignsData, status } = useCampaigns(
    campaignsSPF.curPage,
    campaignsSPF.sortBy,
    campaignsSPF.q,
    campaignsSPF.archived,
    '',
    access,
    false,
  )

  const { data: campaignsExtendedData, status: campaignsExtendedStatus } = useCampaigns(
    campaignsSPF.curPage,
    campaignsSPF.sortBy,
    campaignsSPF.q,
    campaignsSPF.archived,
    '',
    access,
    true,
  )

  const sequenceTableData = useMemo(() => {
    if (campaignsData && campaignsExtendedData) {
      return {
        data: campaignsData?.data.map((campaign) => {
          const extendedData = campaignsExtendedData?.data.find((extended) => extended.id === campaign.id)
          return { ...campaign, ...extendedData }
        }),
        metadata: campaignsData?.metadata,
      }
    }
    return campaignsData
  }, [campaignsData, status, campaignsExtendedData, campaignsExtendedStatus])
  const isFullyLoaded = campaignsExtendedStatus !== 'pending' && status !== 'pending'

  const searchCampaigns = (search: string) => setCampaignsSPF((m) => ({ ...m, curPage: 1, q: search }))

  const toggleArchived = (v: boolean) => setCampaignsSPF((m) => ({ ...m, archived: v }))

  const { searchText, setSearchText } = useSearchCampaigns(searchCampaigns)

  const campaignLaunch = useCampaignLaunch()
  const campaignStop = useCampaignStop()

  const onCampaignLaunch = (campaignId: string, launch: boolean) => {
    setLaunchInProgress(true)
    const action = launch ? campaignLaunch : campaignStop
    action.mutate({ campaignId }, { onSettled: () => setLaunchInProgress(false) })
  }

  const columnHelper = createColumnHelper<CampaignList>()
  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        meta: {
          checkboxRow: true,
        },
      }),
      columnHelper.accessor('status', {
        header: '',
        cell: (info) => (
          <div className="relative">
            <Tooltip placement={'top'}>
              <Tooltip.Trigger>
                <div
                  className={twMerge(
                    'my-auto h-fit w-fit rounded-lg border-[3px] border-transparent',
                    info.getValue() === CampaignStatus.LAUNCHED && 'border-light-blue',
                  )}
                >
                  <div
                    className={twMerge(
                      'h-2 w-2 rounded-lg bg-light',
                      info.getValue() === CampaignStatus.LAUNCHED && 'bg-accent',
                    )}
                  />
                </div>
              </Tooltip.Trigger>
              <Tooltip.Panel>
                <div className='text-white" -left-10 -top-6 flex h-[30px] w-[90px] rounded border border-light bg-white text-xs shadow-sm'>
                  <span className="m-auto align-middle tracking-wide"> {DisplayStatusName[info.getValue()]}</span>
                </div>
              </Tooltip.Panel>
            </Tooltip>
          </div>
        ),
        meta: {
          classname: 'w-8',
        },
      }),
      columnHelper.accessor('name', {
        header: 'Title',
        id: 'name',
        enableSorting: true,
        cell: (info) => {
          return info.row.original.archived_at ? info.getValue() + ' (archived)' : info.getValue()
        },
        meta: {
          classname: 'w-[280px] max-w-[280px] truncate',
          sortByFieldName: CampaignSortField.NAME,
        },
      }),
      columnHelper.accessor('startDate', {
        header: 'Start date',
        id: 'start_date',
        enableSorting: true,
        cell: (info) => {
          return (
            <div data-testid={`${info.row.original.status}-sequence-date`}>
              {format(new Date(info.getValue()), 'LL/dd/yy')}
            </div>
          )
        },
        meta: {
          classname: 'tracking-wide w-22',
          headingClassName: 'px-0',
          sortByFieldName: CampaignSortField.START_DATE,
        },
      }),
      columnHelper.accessor((row) => row.analytics?.audience.scheduled, {
        id: 'scheduled',
        cell: (info) => (isFullyLoaded ? formatInteger(info.getValue()) : <div className="skeleton h-5 w-full" />),
        header: () => <div className="text-end">Scheduled</div>,
        meta: {
          classname: 'w-30 text-end tabular-nums',
        },
      }),
      columnHelper.accessor((row) => row.analytics?.audience.contacted, {
        id: 'sent',
        cell: (info) => (isFullyLoaded ? formatInteger(info.getValue()) : <div className="skeleton h-5 w-full" />),
        header: () => <div className="text-end">Sent</div>,
        meta: {
          classname: 'w-22 text-end tabular-nums',
        },
      }),
      columnHelper.accessor((row) => row.analytics?.audience.opened_pct, {
        id: 'opened',
        cell: (info) => (isFullyLoaded ? formatPercent(info.getValue(), 0) : <div className="skeleton h-5 w-full" />),
        header: () => <div className="text-end">Opened</div>,
        meta: {
          classname: 'w-24 text-end tabular-nums',
        },
      }),
      columnHelper.accessor((row) => row.analytics?.audience.replied_pct, {
        id: 'replied',
        cell: (info) => (isFullyLoaded ? formatPercent(info.getValue(), 0) : <div className="skeleton h-5 w-full" />),
        header: () => <div className="text-end">Replied</div>,
        meta: {
          classname: 'w-24 text-end tabular-nums',
        },
      }),
      columnHelper.accessor((row) => row.analytics?.audience.meeting_booked_pct, {
        id: 'meeting_booked',
        cell: (info) => (isFullyLoaded ? formatPercent(info.getValue(), 0) : <div className="skeleton h-5 w-full" />),
        header: () => <div className="text-end">Meetings</div>,
        meta: {
          classname: 'w-24 text-end tabular-nums',
        },
      }),
      columnHelper.accessor((row) => row.analytics?.audience.bounced_pct, {
        id: 'bounced',
        cell: (info) => (isFullyLoaded ? formatPercent(info.getValue(), 0) : <div className="skeleton h-5 w-full" />),
        header: () => <div className="text-end">Bounced</div>,
        meta: {
          classname: 'w-24 text-end tabular-nums',
        },
      }),
      columnHelper.accessor((row) => row.analytics?.audience.unsubscribed_pct, {
        id: 'unsubscribed',
        header: () => <div className="text-end">Unsub</div>,
        cell: (info) => (isFullyLoaded ? formatPercent(info.getValue(), 0) : <div className="skeleton h-5 w-full" />),
        meta: {
          classname: 'w-24 text-end tabular-nums',
        },
      }),
      columnHelper.accessor((row) => row.analytics?.audience.unsubscribed_pct, {
        id: 'type',
        header: 'Type',
        cell: (info) => CampaignTypes[info.row.original.type],
        meta: {
          classname: 'w-80',
        },
      }),
      columnHelper.display({
        id: 'launch',
        cell: (info) => {
          const campaign = info.row.original
          if (campaign.status === CampaignStatus.LAUNCHED) {
            return (
              <MouseTrap preventDefault>
                <Button
                  variant="text"
                  onClick={() => onCampaignLaunch(campaign.id, false)}
                  className="cursor-default p-0"
                >
                  Pause
                </Button>
              </MouseTrap>
            )
          }
          return <div />
        },
        meta: {
          classname: 'w-7 px-0',
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: 'Actions',
        cell: (info) => {
          const unselect = () => info.row.getIsSelected() && info.row.toggleSelected()
          return <ActionMenu campaign={info.row.original} onDelete={unselect} onArchive={unselect} />
        },
        meta: {
          classname: 'w-2',
        },
      }),
    ],
    [sequenceTableData, isFullyLoaded, launchInProgress],
  )

  const onRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: CampaignList) => {
    if (event.button === 1 || event.ctrlKey || event.metaKey) {
      window.open(`/sequences/${row.id}`, '_blank')
    } else if (event.type === 'click' && event.button === 0) {
      navigate(`/sequences/${row.id}`)
    }
  }
  if (status === 'error') {
    return <Navigate to="/sequences" />
  }

  return (
    <div>
      <div className="mb-5 flex items-center justify-between">
        <div className="flex items-center">
          <Text variant="title">Sequences</Text>
          <CreateCampaignButton />
        </div>
      </div>
      <Card className="mb-5">
        <ContactsMetrics />
      </Card>

      <Card className="mb-5">
        <EmailVolumeGraph />
      </Card>

      {status === 'success' ? (
        <Card className="mb-5 py-6">
          <div className="flex justify-between">
            <AccessTabs setAccess={setAccess} />
            <div className="flex items-center gap-2">
              <SearchBar value={searchText} className="w-52" onChange={setSearchText} />
              <Popover>
                <Popover.Button variant="basic" className="relative p-2">
                  <FunnelSimple className="m-auto size-5 text-dark" />
                  {campaignsSPF.archived && (
                    <span className="absolute right-1 top-1 h-1.5 w-1.5 rounded-full bg-accent" />
                  )}
                </Popover.Button>
                <Popover.Panel className="absolute right-0 z-10 mt-2 w-fit whitespace-nowrap rounded-lg border border-light bg-white p-3 text-sm shadow-sm">
                  <div key="archived" className="my-1 flex items-center gap-2">
                    <Checkbox
                      className="size-4 focus:ring-0"
                      checked={campaignsSPF.archived}
                      onChange={() => toggleArchived(!campaignsSPF.archived)}
                    />
                    <Text>Show archive</Text>
                  </div>
                </Popover.Panel>
              </Popover>
            </div>
          </div>
          <div className="flex flex-col bg-white py-4">
            <div className="inline-block min-w-full align-middle">
              <SequencesTable
                data={sequenceTableData?.data || []}
                columns={columns}
                curPage={sequenceTableData?.metadata?.page ?? 1}
                totalPages={sequenceTableData?.metadata?.total_pages ?? 1}
                setNextPage={() =>
                  setCampaignsSPF((tc) => {
                    return { ...tc, curPage: Math.min(tc.curPage + 1, sequenceTableData?.metadata?.total_pages ?? 1) }
                  })
                }
                sortBy={campaignsSPF.sortBy}
                setSortBy={(sortBy: CampaignSortField) =>
                  setCampaignsSPF((tc) => {
                    return { ...tc, curPage: 1, sortBy: sortBy }
                  })
                }
                setPrevPage={() =>
                  setCampaignsSPF((tc) => {
                    return { ...tc, curPage: Math.max(tc.curPage - 1, 1) }
                  })
                }
                onRowClick={onRowClick}
              />
            </div>
          </div>
        </Card>
      ) : (
        <Skeleton />
      )}
    </div>
  )
}

function CreateCampaignButton() {
  const create = useCampaignCreate()
  const navigate = useNavigate()
  const toast = useToast()

  const onCampaignCreate = () => {
    create.mutate(undefined, {
      onSuccess: (data) => {
        navigate(`create/${data.data.id}`)
      },
      onError: (err: unknown) => {
        toast.createToast({ message: (err as any)?.body?.message || 'Failed to create sequence', error: true })
      },
    })
  }
  return !create.isPending ? (
    <Button
      variant="basic"
      onClick={() => {
        onCampaignCreate()
      }}
      className="ml-6"
      data-testid="create-btn"
    >
      Create new
    </Button>
  ) : null
}

function AccessTabs({ setAccess }: { setAccess: (access: 'ALL' | 'PRIVATE' | 'SHARED') => void }) {
  type Tab = 'All sequences' | 'Private' | 'Shared'
  enum tabsToAccess {
    'All sequences' = 'ALL',
    'Private' = 'PRIVATE',
    'Shared' = 'SHARED',
  }

  const tabs = Object.keys(tabsToAccess)

  const [selected, setSelected] = useState<Tab>('All sequences')

  function handleTabChange(tab: string) {
    setSelected(tab as Tab)
    setAccess(tabsToAccess[tab as Tab])
  }
  return <Tabs tabs={tabs} selected={selected} setSelected={handleTabChange} />
}
