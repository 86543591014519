import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { expireDuration } from '@/lib/date'
import { ApiError, DefaultService, PasswordOTPCode, Passwords, PatchPassword, PostPassword } from './core'

export const queryKey = {
  base: () => ['passwords'] as const,
  id: (id: string) => [...queryKey.base(), id] as const,
}

export function usePasswords() {
  return useQuery<{ data: Passwords }, ApiError>({
    queryKey: queryKey.base(),
    queryFn: () => DefaultService.getVaultPasswords(),
  })
}

export function usePasswordOTPCode(passwordId: string, { enabled = true } = {}) {
  return useQuery<{ data: PasswordOTPCode }, ApiError>({
    queryKey: queryKey.id(passwordId),
    queryFn: () => DefaultService.getVaultPasswordsOtpCode(passwordId),
    enabled: enabled,
    refetchInterval: (query) => {
      if (!query.state.data) return false
      return expireDuration(query.state.data.data.expiresAt)
    },
  })
}

export function usePasswordCreate() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: PostPassword) => DefaultService.postVaultPasswords(body),
  })
}

export function usePasswordsImport() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: Blob) => DefaultService.postVaultPasswordsImport(body),
  })
}

export function usePasswordUpdate(passwordId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: (body: PatchPassword) => DefaultService.patchVaultPasswords(passwordId, body),
  })
}

export function usePasswordDelete(passwordId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.base() }),
    mutationFn: () => DefaultService.deleteVaultPasswords(passwordId),
  })
}
