import { Permission } from '@/api/core'
import { Tooltip } from '@/ui/Tooltip'

type PermissionTooltipProps = {
  children: React.ReactNode
  permission?: Permission
  showOnAllow?: boolean
}

export function PermissionTooltip(props: PermissionTooltipProps) {
  const { children, permission, showOnAllow = true } = props

  if (!permission?.deny && !showOnAllow) return null
  return (
    <Tooltip hidden={!permission?.deny}>
      <Tooltip.Trigger>{children}</Tooltip.Trigger>
      <Tooltip.Panel>
        {permission?.reason}
        <br />
        {permission?.action}
      </Tooltip.Panel>
    </Tooltip>
  )
}
