/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OnboardingStatus {
    ERROR = 'ERROR',
    ACTION_REQUIRED = 'ACTION_REQUIRED',
    IN_PROGRESS = 'IN_PROGRESS',
    REVIEW = 'REVIEW',
    COMPLETED = 'COMPLETED',
}
