import { Menu } from '@headlessui/react'
import { useWorkspaceInvitationAccept, useWorkspaceInvitations } from '@/api/workspace'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Text } from '@/ui'
import { ErrorInfo } from '@/ui/ErrorInfo'

export function Invitations() {
  const { data, status } = useWorkspaceInvitations()
  if (status === 'error') {
    return <ErrorInfo />
  }

  if (!data?.data) {
    return null
  }

  return (
    <div className="flex w-full flex-col gap-2">
      <Text variant="subtitle">Invitations</Text>
      <div className="flex-col">
        {data.data.map((v) => (
          <InvitationRow companyName={v.company} invitationId={v.id} key={v.id} />
        ))}
      </div>
    </div>
  )
}

type InvitationRowProps = {
  companyName: string
  invitationId: string
}

function InvitationRow(props: InvitationRowProps) {
  const toast = useToast()
  const workspaceInvitationAccept = useWorkspaceInvitationAccept(props.invitationId)
  const onAccept = (keepInboxes: boolean) => {
    workspaceInvitationAccept.mutate(
      { keepInboxes },
      {
        onError: (err: unknown) => {
          toast.createToast({ message: (err as any)?.body?.message || 'Failed to accept invitation user', error: true })
        },
        onSuccess: () => window.location.reload(),
      },
    )
  }

  return (
    <div className="flex items-center justify-between">
      <span className="text-dusk">{props.companyName}</span>
      <Menu as="div" className="relative">
        <Menu.Button as={Button} variant="text">
          Migrate
        </Menu.Button>
        <Menu.Items className="absolute right-0 mt-2 whitespace-nowrap rounded-md bg-white">
          <div className="flex flex-col gap-2 rounded-md px-4 py-2 text-left shadow-lg">
            <Menu.Item>
              <button type="button" className="text-left hover:text-accent" onClick={() => onAccept(false)}>
                Without inboxes
              </button>
            </Menu.Item>
            <Menu.Item>
              <button type="button" className="text-left hover:text-accent" onClick={() => onAccept(true)}>
                With inboxes
              </button>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
    </div>
  )
}
