import { createContext, useContext } from 'react'
import { MemberInbox, TeamMember } from '@/api/core'
import { State } from './treeSelectedItemsReducer'

export const SelectItemsDataContext = createContext<State | null>(null)
SelectItemsDataContext.displayName = 'SelectItemsDataContext'

export const SelectItemsActionsContext = createContext<{
  onSelectInbox(e: React.MouseEvent, inbox: MemberInbox): void
  onSelectMember(e: React.MouseEvent, member: TeamMember): void
  unselect(): void
} | null>(null)
SelectItemsActionsContext.displayName = 'SelectItemsActionsContext'

export function useData() {
  const context = useContext(SelectItemsDataContext)
  if (context === null) {
    throw new Error(`component is missing a parent <SelectItemsDataContext /> component.`)
  }
  return context
}

export function useActions() {
  const context = useContext(SelectItemsActionsContext)
  if (context === null) {
    throw new Error(`component is missing a parent <SelectItemsActionsContext /> component.`)
  }
  return context
}
