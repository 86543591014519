import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCompany } from '@/api'
import {
  ConferencingPlatform,
  MeetingAvailability,
  MeetingCategory,
  MeetingMember,
  MeetingType,
  MeetingTypePermissions,
  PatchMeetingType,
  PostMeetingType,
} from '@/api/core'
import { useMeetingsTemplateReminder } from '@/api/meetings'
import { MeetingCategoryLabels } from '@/api/text/meetings'
import { yupValidate } from '@/lib/template'
import { Availability } from '@/pages/Meetings/Availability'
import { Button, Checkbox, DangerButton, Input, PermissionTooltip, RadioGroup, Text, TimezoneSelect } from '@/ui'
import { EmailEditor } from '@/ui/EmailEditor/EmailEditor'
import { Toolbar } from '@/ui/EmailEditor/styled'
import { ConferencingOptions } from './ConferencingOptions'
import { HorizontalRadioGroup } from './HorizontalRadioGroup'
import { Reminders } from './Reminders'
import { RoundRobinAssignment } from './RoundRobinAssignment'
import { UrlInput } from './UrlInput'

const meetingTypeCategoriesDescriptions = {
  [MeetingCategory.PERSONAL]: 'A meeting tied to you.',
  [MeetingCategory.ROUND_ROBIN]: 'A meeting where one or more users can be assigned using a round robin.',
}

const meetingTypeCategories = MeetingCategoryLabels.map((category) => ({
  value: category.value,
  label: category.label,
  description: meetingTypeCategoriesDescriptions[category.value],
}))

const meetingDurationOptions = [
  { value: 15, label: '15 min' },
  { value: 30, label: '30 min' },
  { value: 45, label: '45 min' },
  { value: 60, label: '60 min' },
]

type MeetingTypeFormProps = {
  permissions?: MeetingTypePermissions
  initialValues?: Partial<MeetingType>
  members: MeetingMember[]
  update: (meetingType: PatchMeetingType | PostMeetingType) => void
  delete?: () => void
  cancel: () => void
}

export function MeetingTypeForm(props: MeetingTypeFormProps) {
  const { data: reminderInfo } = useMeetingsTemplateReminder()
  const schema: yup.ObjectSchema<PostMeetingType | PatchMeetingType> = yup.object().shape({
    description: yup.string().required('Meeting name is required'),
    short_name: yup
      .string()
      .required('Short name is required')
      .matches(/^[a-zA-Z0-9-_]*$/, 'Only letters, numbers and dashes are allowed'),
    buffer: yup.number().integer('must be a number').default(0).required('required'),
    duration: yup.number().integer('must be a number').required(''),
    invite_description: yup.string().default(''),
    conferencingPlatform: yup
      .mixed<ConferencingPlatform>()
      .oneOf(Object.values(ConferencingPlatform))
      .required('required'),
    memberIds: yup
      .array()
      .of(yup.string().required(''))
      .required('')
      .when('category', {
        is: MeetingCategory.ROUND_ROBIN,
        then: (schema) => schema.min(1, 'Select at least one email'),
        otherwise: (schema) => schema.max(0),
      }),
    timezone: yup.string().required(''),
    category: yup.mixed<MeetingCategory>().oneOf(Object.values(MeetingCategory)).required(''),
    syncToContacts: yup.boolean().required(''),
    allowSameDayBooking: yup.boolean().required(''),
    availability: yup.mixed<MeetingAvailability>().required(''),
    reminders: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string().nullable(),
          sendBefore: yup
            .object()
            .shape({ days: yup.number().default(0).required(), hours: yup.number().default(0).required() })
            .test({
              test: (value) => {
                return value.days !== 0 || value.hours !== 0
              },
              message: 'must be not 0',
            })
            .required('required'),
          subject: yup.string().required('').test({ test: yupValidate }),
          body: yup.string().required('').test({ test: yupValidate }),
        }),
      )
      .default([])
      .required(),
  })
  const formMethods = useForm<PostMeetingType | PatchMeetingType>({
    defaultValues: props.initialValues,
    resolver: yupResolver(schema),
  })

  const { getValues, control, register, handleSubmit, formState, setValue } = formMethods

  const [companyShortName, setCompanyShortName] = useState('')
  const company = useCompany()
  useEffect(() => {
    if (company.data?.data) {
      setCompanyShortName(company.data.data.short_name ?? '')
    }
  }, [company.data?.data])

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(props.update)} className="space-y-4 pb-[96px]">
        <div className="space-y-4 rounded-lg border border-light p-6">
          <Text variant="subtitle">Details</Text>
          <Input
            name="description"
            placeholder="30 min intro call"
            label="Meeting name"
            registration={register('description', { required: 'Meeting name is required' })}
            error={formState.errors.description?.message}
          />
          <div>
            <Text className="mb-1.5 mt-4 font-medium">Meeting description</Text>
            <div className="w-full">
              <EmailEditor
                variables={['FirstName', 'LastName', 'Company']}
                as="div"
                className="flex min-h-48 flex-col justify-between gap-4 rounded-lg border border-light px-4 pb-2 pt-4"
              >
                <EmailEditor.Body
                  content={getValues('invite_description')}
                  onChange={(value) => setValue('invite_description', value)}
                />
                <Toolbar>
                  <Toolbar.Light />
                </Toolbar>
              </EmailEditor>
            </div>
          </div>
          <UrlInput control={control} companyShortName={companyShortName} />
          <Controller
            name="duration"
            rules={{ required: true }}
            control={control}
            render={({ field, fieldState }) => (
              <>
                <RadioGroup
                  label="Duration"
                  options={meetingDurationOptions}
                  value={field.value}
                  onChange={field.onChange}
                />
                {fieldState.error && <p className="label text-alert">{fieldState.error?.message}</p>}
              </>
            )}
          />
          <ConferencingOptions control={control} />
        </div>
        <div className="rounded-lg border border-light p-6">
          <Text variant="subtitle" className="mb-4">
            Type
          </Text>
          <Controller
            name="category"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <>
                <HorizontalRadioGroup options={meetingTypeCategories} value={field.value} onChange={field.onChange} />
                {fieldState.error && <p className="label text-alert">{fieldState.error?.message}</p>}
              </>
            )}
          />
          <RoundRobinAssignment
            members={props.members}
            control={control}
            clearUsersValue={() => setValue('memberIds', [])}
          />
          <Text className="mb-2 mt-4 font-medium">Data</Text>
          <Controller
            name="syncToContacts"
            control={control}
            render={({ field }) => (
              <label className="flex" htmlFor="syncToContactsCheckbox">
                <Checkbox
                  className="label"
                  checked={field.value}
                  id="syncToContactsCheckbox"
                  onChange={field.onChange}
                />
                <span className="label ml-3 block">Sync to Contacts</span>
              </label>
            )}
          />
        </div>

        <div className="space-y-4 rounded-lg border border-light p-6">
          <Text variant="subtitle">Schedule</Text>
          <div>
            <h2 className="label-medium mb-2">Team availability for this meeting</h2>
            <div className="w-full">
              <TimezoneSelect
                name={'timezone'}
                control={control}
                labelClassName="w-full"
                controlStyles={{
                  borderRadius: '8px',
                  borderColor: '#E5E7EC',
                  color: '#131619',
                  lineHeight: '20px',
                  letterSpacing: '0.28px',
                  fontSize: '14px',
                }}
              />
            </div>

            <p className="content-xs mb-4 mt-1">
              You can set your global availability in{' '}
              <NavLink to={'/settings/availability'} className="text-accent">
                Settings
              </NavLink>
            </p>

            <Availability register={register} availability={getValues('availability')} control={control} />

            <Text className="mt-6 font-medium">Buffer time</Text>
            <Input
              labelClassName="label-medium mb-1 mt-6"
              name="buffer"
              helperText="min"
              registration={register('buffer', { required: true })}
              className="w-32"
              containerClassName="flex items-baseline space-x-2"
            />
            <Controller
              name="allowSameDayBooking"
              control={control}
              render={({ field }) => (
                <label className="my-4 flex" htmlFor="allowSameDayBookingCheckbox">
                  <Checkbox
                    className="label"
                    checked={field.value}
                    id="allowSameDayBookingCheckbox"
                    onChange={field.onChange}
                  />
                  <span className="label ml-3 block">Allow same day booking</span>
                </label>
              )}
            />
          </div>
        </div>

        {reminderInfo && (
          <Reminders
            defaultSubject={reminderInfo.data.subject}
            defaultBody={reminderInfo.data.body}
            variables={reminderInfo.data.variables}
          />
        )}

        <div className="fixed bottom-0 right-0 flex pb-5 pr-10">
          {props.delete && (
            <PermissionTooltip permission={props.permissions?.edit}>
              <DangerButton
                variant="basic"
                type="button"
                onClick={() => props.delete?.()}
                disabled={!!props.permissions?.edit.deny}
              >
                Delete
              </DangerButton>
            </PermissionTooltip>
          )}
          <Button type="button" variant="text" onClick={props.cancel}>
            Cancel
          </Button>
          <PermissionTooltip permission={props.permissions?.edit}>
            <Button type="submit" variant="accent" disabled={!!props.permissions?.edit.deny}>
              Save
            </Button>
          </PermissionTooltip>
        </div>
      </form>
    </FormProvider>
  )
}
