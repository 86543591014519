import { useLayoutEffect, useState } from 'react'

const mobileMaxWidth = 768
export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileMaxWidth)
  const updateSize = () => {
    setIsMobile(window.innerWidth < mobileMaxWidth)
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize)
    return (): void => window.removeEventListener('resize', updateSize)
  }, [])

  return isMobile
}
