import { ElementType, Ref, useRef } from 'react'
import { Props } from '@headlessui/react/dist/types'
import { useEvent } from '@/hooks/useEvent'
import { useSyncRefs } from '@/hooks/useSyncRefs'
import { uirender } from '@/ui/render'
import { useActions, useData } from './Context'

export const DEFAULT_NEXT_TAG = 'button'
export interface NextRenderPropArg {}

export type StepNextProps<TTag extends ElementType> = Props<TTag, NextRenderPropArg>

export function Next<TTag extends ElementType = typeof DEFAULT_NEXT_TAG>(
  props: StepNextProps<TTag>,
  ref: Ref<HTMLElement>,
) {
  const { currentIndex } = useData('Step.Next')
  const actions = useActions('Step.Next')

  const internalNextRef = useRef<HTMLElement | null>(null)
  const nextRef = useSyncRefs(internalNextRef, ref)

  const onNextClick = useEvent(() => {
    if (internalNextRef.current?.hasAttribute('disabled')) return
    actions.change(currentIndex + 1)
  })

  const ourProps = {
    ref: nextRef,
    onClick: onNextClick,
  }

  return uirender({
    ourProps,
    theirProps: props,
    slot: { next: onNextClick },
    defaultTag: DEFAULT_NEXT_TAG,
  })
}
