import { useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { useDebounce } from 'use-debounce'
import { X } from '@phosphor-icons/react'
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { SalesforceConnectionSynchronizationContact } from '@/api/core'
import { useSalesforceDebugData } from '@/api/salesforce'
import { useObserveSticky } from '@/hooks'
import { useQueryParamState } from '@/hooks/useQueryParamState'
import { ContactDetails } from '@/pages/Contacts/ContactDetails'
import { Button, SearchBar, Text } from '@/ui'

export function SalesforceContactsSynchronizationDebug() {
  const [search, setSearch] = useState('')
  const [searchD] = useDebounce(search, 200)
  const { data, status } = useSalesforceDebugData()
  const [contactId, setContactId] = useQueryParamState('contactId')

  if (status === 'pending') {
    return <div className="skeleton m-auto h-[200px] w-full max-w-3xl" />
  }

  if (status === 'error') {
    return null
  }

  return (
    <section>
      <hgroup className="pb-5">
        <Text variant="title" className="pb-2">
          Contacts Synchronization Debug
        </Text>
        <Text variant="subtext" className="text-medium">
          Debug the synchronization of contacts between rift and Salesforce
        </Text>
      </hgroup>
      <Text variant="title" className="">
        Not synced to Salesforce
      </Text>
      {data.data.totalCrmContactDeleted > 0 && (
        <Text variant="subtext" className="text-medium">
          {data.data.totalCrmContactDeleted} contacts deleted in Salesforce
        </Text>
      )}
      {data.data.totalCrmLeadDeleted > 0 && (
        <Text variant="subtext" className="text-medium">
          {data.data.totalCrmLeadDeleted} leads deleted in Salesforce
        </Text>
      )}
      {data.data.totalStageNotMapped > 0 && (
        <Text variant="subtext" className="text-medium">
          {data.data.totalStageNotMapped} contacts with stage not mapped
        </Text>
      )}
      {data.data.totalStageSyncDisabled > 0 && (
        <Text variant="subtext" className="text-medium">
          {data.data.totalStageSyncDisabled} contacts with stage sync disabled
        </Text>
      )}
      {contactId && <ContactDetails contactId={contactId} onClose={() => setContactId(null)} />}
      <SearchBar value={search} onChange={setSearch} />
      <ContactsNotSyncedToCrmTable
        data={data.data.contactsMissingInCrm}
        onRowClick={setContactId}
        globalFilter={searchD}
        setGlobalFilter={setSearch}
      />
    </section>
  )
}

type ContactsNotSyncedToCrmTableProps = {
  data: SalesforceConnectionSynchronizationContact[]
  onRowClick: (id: string) => void
  globalFilter: string
  setGlobalFilter: (value: string) => void
}

function ContactsNotSyncedToCrmTable(props: ContactsNotSyncedToCrmTableProps) {
  const { data, onRowClick, globalFilter, setGlobalFilter } = props
  const [tableHeaderRef, tableHeaderColored] = useObserveSticky<HTMLTableRowElement>()
  const tableRef = useRef(null)
  const columnHelper = createColumnHelper<SalesforceConnectionSynchronizationContact>()
  const columns = useMemo<ColumnDef<SalesforceConnectionSynchronizationContact, any>[]>(
    () => [
      columnHelper.accessor((row) => row.email, {
        id: 'email',
        header: () => <span>Email</span>,
        enableColumnFilter: true,
        cell: (info) => (
          <button
            className="cursor-pointer text-left"
            onClick={() => info.row.original.riftId && onRowClick(info.row.original.riftId)}
            data-testid="contact-edit-btn"
          >
            <span className="flex max-w-52 overflow-hidden text-ellipsis text-nowrap text-dusk">
              {info.row.original.email}
            </span>
          </button>
        ),
      }),
      columnHelper.display({
        id: 'isCrmContactDeleted',
        header: () => <span className="flex text-center">SF contact deleted</span>,
        cell: (info) => (
          <span className="flex w-full items-center justify-center">
            {info.row.original.isCrmContactDeleted && <X />}
          </span>
        ),
      }),
      columnHelper.display({
        id: 'isCrmLeadDeleted',
        header: () => <span className="flex text-center">SF lead deleted</span>,
        cell: (info) => (
          <span className="flex w-full items-center justify-center">{info.row.original.isCrmLeadDeleted && <X />}</span>
        ),
      }),
      columnHelper.display({
        id: 'isCrmStageMapped',
        header: () => <span className="flex text-center">Stage not mapped</span>,
        cell: (info) => (
          <span className="flex w-full items-center justify-center">{info.row.original.isStageNotMapped && <X />}</span>
        ),
      }),
      columnHelper.display({
        id: 'isStageSyncDisabled',
        header: () => <span className="flex text-center">Stage sync disabled</span>,
        // align center
        // center item in span
        cell: (info) => (
          <span className="flex w-full items-center justify-center">
            {info.row.original.isStageSyncDisabled && <X />}
          </span>
        ),
      }),
    ],
    [],
  ) as ColumnDef<SalesforceConnectionSynchronizationContact, any>[]

  const table = useReactTable({
    data: data,
    columns: columns,
    state: {
      globalFilter: globalFilter,
    },

    initialState: {
      pagination: {
        pageSize: 15,
      },
    },

    onGlobalFilterChange: setGlobalFilter,
    enableFilters: true,

    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  return (
    <>
      {table.getPaginationRowModel().rows.length > 0 && (
        <div className="flex justify-end py-2">
          <Text variant="subtext" className="text-medium">
            <span data-testid="contacts-start-table-index">
              {table.getState().pagination.pageIndex * table.getState().pagination.pageSize}
            </span>{' '}
            —{' '}
            <span>
              {Math.min(
                (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize,
                data.length,
              )}
            </span>{' '}
            of <span data-testid="contacts-total-table-index">{data.length}</span>
          </Text>
          <div className="flex flex-1 items-center justify-end gap-2">
            <Button
              variant="basic"
              className={clsx(!table.getCanPreviousPage() && 'invisible')}
              onClick={() => table.previousPage()}
            >
              Previous
            </Button>
            <Button
              variant="basic"
              className={clsx(!table.getCanNextPage() && 'invisible')}
              onClick={() => table.nextPage()}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      <table className="min-w-full" ref={tableRef}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} ref={tableHeaderRef}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    scope="col"
                    className={clsx(
                      'sticky top-0 table-cell border-y border-gray-200 py-2 text-left text-sm font-semibold text-primary',
                      tableHeaderColored && 'bg-gray-100',
                    )}
                  >
                    <div className={clsx('relative', header.column.id === 'select' && 'w-full pr-2 text-center')}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="whitespace-wrap border-y border-gray-200 transition-colors hover:bg-gray-50"
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={clsx('py-3 text-sm font-medium', cell.column.id === 'select' && 'pr-2 text-center')}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              )
            })
          ) : (
            <tr className="justify-center border-gray-300">
              <td
                colSpan={table.getHeaderGroups()[0].headers.length}
                className="w-full py-4 text-center text-sm font-medium text-primary"
              >
                No contact with synchronization issues
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}
